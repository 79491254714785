import {CloseOutlined,DeleteOutlined,EditOutlined,SearchOutlined,CheckCircleOutlined} from '@ant-design/icons'
import { Button,Col,Form,Input,message,Popconfirm,Row,Table,Typography,} from 'antd'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import AdminTemplate from '@/components/layout/AdminTemplate'
import {CustomTopTitle,CustomContentAdminWrapper,HeaderAdmin,PaginationAdmin} from '../style'
import { CustomButton, CustomSearch, SearchProjectWrapper } from './style'
import axios from 'axios'
import { apiUrl } from '@/config'
import settings from '@/siteConfig'
var cancelToken
const { Text } = Typography;

const Users = props => {
  const { t } = useTranslation()
  const { commonStore, organizationStore, adminStore ,usersStore } = props
  moment.locale(`${commonStore.language}`)
  const [isLoadingTableUsers, setisLoadingTableUsers] = useState(
    organizationStore.isLoading
  )
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [form] = Form.useForm()
  const [searchValue, setSearchValue] = useState('')
  const [_limit, setLimit] = useState()
  const [_page, setPage] = useState()
  const history = useHistory()
  const { queryParam } = useParams()
  const [fetchURL, setFetchURL] = useState(true)
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

  useEffect(() => {
    commonStore.setCurrentPage('admin/users')
    adminStore.clearPaginationParams()
    adminStore.clearStatus()
    const queryStringParams = queryString.parse(queryParam)
    if (queryStringParams.search) {
      setSearchValue(queryStringParams.search)
      form.setFieldsValue({
        searchUser: queryStringParams.search,
      })
    }
  }, [])

  useEffect(() => {
    return () => {
      adminStore.clearPaginationParams()
    }
  })

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    setLimit(parseInt(queryStringParams._limit))
    setPage(parseInt(queryStringParams._page))
    if (fetchURL && _page && _limit) {
      setisLoadingTableUsers(true)
      adminStore.setCurrentPage(_page)
      adminStore.setCurrentLimit(_limit)
      adminStore
        .customGetUsers(
          _limit,
          _page,
          searchValue ? searchValue.trim() : searchValue
        )
        .then(() => {
          setisLoadingTableUsers(false)
        })
        .catch(() => {
          setisLoadingTableUsers(false)
          adminStore.clearCustomListUsers()
        })
    }
  }, [queryParam, _page, _limit, fetchURL])

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    if (!queryStringParams.search && !fetchURL) {
      setisLoadingTableUsers(true)
      setSearchValue('')
      form.setFieldsValue({
        searchUser: ''
      })
      handleSearchUsers('')
    }
  }, [queryParam])


  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const migrateProjectOrganization = () => {
    setisLoadingTableUsers(true)
    adminStore
      .migrateProjectOrganization({ type: 'user', list: selectedRowKeys })
      .then(res => {
        message.success(t('migrate-success'))
        setisLoadingTableUsers(false)
      })
      .catch(error => {
        console.log(error)
        message.error(t('migrate-failed-please-try-again'))
        setisLoadingTableUsers(false)
      })
  }

  const hasSelected = selectedRowKeys.length > 0

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const handleClicDeleteUsers = () => {
    adminStore.setLoadingProgress(true)
    let _arrUserId = {
      arrUserId: selectedRowKeys,
    }

    adminStore
      .deleteMultiUser(_arrUserId)
      .then(async res => {
        adminStore.customListUsers.data =
          adminStore.customListUsers.data.filter(el => {
            return selectedRowKeys.indexOf(el.id) < 0
          })
        message.success(t('user-deleted-successfully'))
        adminStore.setLoadingProgress(false)
        setSelectedRowKeys([])
      })
      .catch(error => {
        adminStore.setLoadingProgress(false)
        message.error(t('something-went-wrong'))
      })
  }

  const handleDeleteUser = record => {
    let _param = [record]
    adminStore.setLoadingProgress(true)
    let _arrUserId = {
      arrUserId: _param,
    }
    adminStore
      .deleteMultiUser(_arrUserId)
      .then(async res => {
        adminStore.customListUsers.data =
          adminStore.customListUsers.data.filter(el => el.id !== record)
        message.success(t('user-deleted-successfully'))
        adminStore.setLoadingProgress(false)
        setSelectedRowKeys([])
      })
      .catch(error => {
        adminStore.setLoadingProgress(false)
        message.error(t('something-went-wrong'))
      })
  }

  const handleEditUser = record => {
    adminStore.setCurrentPage(_page)
    adminStore.setCurrentLimit(_limit)
    return history.push(`/admin/user/${record}`)
  }

  const sortRealName = obj => {
    let _v = obj
    if (!_v) {
      return <span></span>
    } else {
      return _v && _v.firstName && _v.lastName
        ? `${_v.firstName} ${_v.lastName}`
        : _v.firstName && !_v.lastName
          ? _v.firstName
          : !_v.firstName && _v.lastName
            ? _v.lastName
            : obj.email
    }
  }

  const sortOrganizations = obj => {
    let _orgUserroles = Array.isArray(obj.organizationuserroles)
      ? obj.organizationuserroles
      : []
    let result = _orgUserroles.filter(c => c?.organization?.isActive)
    return result.length
  }

  const getTermsAndConditions = record => {
    let value = record ? record.split('/') : undefined
    return value && value.length > 0 ? value[value.length - 1] : ' '
  }

  const getLengthProject = obj => {
    let result = []
    let _prjInvite = obj.projectuserroles
    if (_prjInvite.length > 0) {
      _prjInvite.map(elm => {
        if (elm.project?.isDeleted) return
        result.push(elm)
      })
    }
    return result.length
  }

  const handleActivateUser = async (userId) => {
    setisLoadingTableUsers(true)
    await usersStore.verifyAccount(userId).then((res) => {
      if (res.status === 'blocked') {
        message.error(t(res.message))
      } else if (res.status === 'actived') {
        message.success(t(res.message))
      } else if (res.status === 'verify-success') {
        message.success(t('activate-account-success'))
      }
      const data = adminStore.customListUsers?.data || [];
      const newData = [...data]
      let index = data.findIndex(c => c.id ===userId);
      if(index !== -1){
        newData[index] = {
          ...data[index],
          isActive : true
        }
      }
      adminStore.setCustomListUsers({
        count : adminStore.customListUsers?.count || 0,
        data :newData
      })
      setisLoadingTableUsers(false)
    })
      .catch(error => {
        message.error(t('has-an-error'))
        setisLoadingTableUsers(false)
      })
  }

  const columns = [
    {
      title: t('username'),
      key: 'username',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.username.localeCompare(b.username),
      render: (record, obj) => {
        return record ? (
          <span style={{ cursor: "pointer" }} onClick={() => handleEditUser(record.id)}>{record.username}</span>
        ) : (
          <span style={{ color: '#ff0000' }}>{t('user-not-found')}</span>
        )
      },
    },
    {
      title: t('real-name'),
      dataIndex: 'user',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a && b ? sortRealName(a).localeCompare(sortRealName(b)) : true,
      render: (record, obj) => {
        return sortRealName(obj)
      },
    },
    {
      title: t('email'),
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (record, obj) => {
        return record && obj.user ? obj.user.email : <span>{record}</span>
      },
    },
    {
      title: t('created'),
      width: 150,
      key: 'createdAt',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = a.createdAt ? new Date(a.createdAt) : distantFuture
        let dateB = b.createdAt ? new Date(b.createdAt) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      render: record => {
        return (
          <div className={'tileset-name'}>
            {moment(record.createdAt).format('MMMM DD, YYYY')}
          </div>
        )
      },
    },
    {
      title: t('last-logged-in'),
      width: 150,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = a.lastLoggin ? new Date(a.lastLoggin) : distantFuture
        let dateB = b.lastLoggin ? new Date(b.lastLoggin) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      render: (record, obj) => {
        let _v = obj
        if (!_v) {
          return <span></span>
        } else {
          return _v.lastLoggin ? (
            <div className={'tileset-name'}>
              {moment(_v.lastLoggin).format('MMMM DD, YYYY')}
            </div>
          ) : (
            ''
          )
        }
      },
    },
    {
      title: t('organizations'),
      dataIndex: 'organizationuserroles',
      width: 150,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a && b ? sortOrganizations(a) - sortOrganizations(b) : true,
      render: (record, obj) => {
        return <span> {sortOrganizations(obj)}</span>
      },
    },
    {
      title: t('projects'),
      dataIndex: 'projects',
      key: 'projects',
      sortDirections: ['descend', 'ascend'],
      sorter: {
        compare: (a, b) =>
          a && b ? getLengthProject(a) - getLengthProject(b) : true,
      },
      render: (record, obj) => {
        return <span> {getLengthProject(obj)}</span>
      },
    },
    {
      title: t('privacy-policy'),
      dataIndex: 'privacyPolicy',
      key: 'privacyPolicy',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a && b
          ? getTermsAndConditions(a.privacyPolicy).localeCompare(
            getTermsAndConditions(b.privacyPolicy)
          )
          : true,
      render: record => {
        return getTermsAndConditions(record)
      },
    },
    {
      title: t('terms-and-conditions'),
      dataIndex: 'termsConditions',
      key: 'termsConditions',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a && b
          ? getTermsAndConditions(a.termsConditions).localeCompare(
            getTermsAndConditions(b.termsConditions)
          )
          : true,
      render: record => {
        return getTermsAndConditions(record)
      },
    },
    {
      title: t('activate'),
      dataIndex: 'isActive',
      width: 100,
      key: 'isActive',
      render: (record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Text strong type={record ? 'success' : 'danger'}>
              {record ? 'YES' : 'NO'}
            </Text>
          </div>
        )
      },
    },
    {
      title: t('action'),
      key: 'action',
      width: 150,
      align: 'center',
      render: record => {
        return (
          <>
            <Button
              onClick={() => handleEditUser(record.id)}
              icon={<EditOutlined />}
              style={{ margin: '3px' }}
            />
            {
              !record?.isActive && (
                <Popconfirm
                  title={t('confirm-activate-this-account')}
                  onConfirm={() => handleActivateUser(record.id)}
                  okText={t('commons.yes')}
                  cancelText={t('commons.no')}
                >
                  <Button style={{ background: "#52c41a", color: "white" }} icon={<CheckCircleOutlined />} />
                </Popconfirm>
              )
            }
            <Popconfirm
              title={t('are-you-sure-want-to-delete-this-user')}
              onConfirm={() => handleDeleteUser(record.id)}
              okText={t('commons.yes')}
              cancelText={t('commons.no')}
            >
              <Button style={{ margin: '3px' }} type="danger" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        )
      }
    },
  ]

  const handleSearchUsers = async e => {
    const search = e ? e.trim() : e
    adminStore.setCurrentPage(adminStore.currentPage)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    setFetchURL(false)
    if (search === '') {
      setSearchValue()
      history.push(`/admin/users/_limit=${_limit ? _limit : 10}&_page=${1}`)
    } else {
      setSearchValue(search)
      history.push(
        `/admin/users/_limit=${_limit ? _limit : 10}&_page=${adminStore.currentPage
        }&search=${encodeURIComponent(e)}`
      )
    }
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    cancelToken = axios.CancelToken.source()
    try {
      setisLoadingTableUsers(true)
      const response = await axios.get(
        `${apiUrl}/user/custom/paginate?_limit=${_limit}&_page=${1}${search && search !== '' ? '&search=' + search : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${commonStore.token}`,
          },
          cancelToken: cancelToken.token,
        }
      )
      if (response) {
        setisLoadingTableUsers(false)
        adminStore.setCustomListUsers(response.data)
      }
    } catch (error) {
      if (error) {
        setisLoadingTableUsers(false)
        adminStore.clearCustomListUsers()
        console.log(error)
      }
    }
  }

  const clearSearch = () => {
    setFetchURL(true)
    setSearchValue('')
    form.resetFields()
    setLimit(_limit ? _limit : 10)
    setPage(1)
    adminStore.setCurrentPage(1)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    return history.push(
      `/admin/users/_limit=${_limit ? _limit : 10}&_page=${1}`
    )
  }

  const debounceCalculate = debounce(function (value) {
    handleSearchUsers(value)
  }, 500)

  const onChange = e => {
    setFetchURL(false)
    debounceCalculate(e.target.value)
  }

  const handleChangePagination = (current, pageSize) => {
    setFetchURL(true)
    setLimit(pageSize)
    setPage(current)
    adminStore.setCurrentPage(current)
    adminStore.setCurrentLimit(pageSize)
    if (searchValue) {
      return history.push(
        `/admin/users/_limit=${pageSize}&_page=${current}&search=${searchValue.trim()}`
      )
    } else {
      return history.push(`/admin/users/_limit=${pageSize}&_page=${current}`)
    }
  }

  return (
    <AdminTemplate title={t('users')}>
      <CustomContentAdminWrapper
        nodata={adminStore.customListUsers?.count ? false : true}>
        <HeaderAdmin>
          <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Col xs={24} sm={12}>
              <CustomTopTitle>
                <div className="title">{t('users')}</div>
                <div className="count">
                  {t('total-records-found', {
                    length: adminStore.customListUsers?.count || 0,
                  })}
                </div>
              </CustomTopTitle>
            </Col>
            <Col span={24}>
              <Form form={form} layout="vertical">
                <SearchProjectWrapper>
                  <CustomSearch>
                    <Form.Item name="searchUser">
                      <Input
                        onChange={onChange}
                        placeholder={t('search-for-an-entry')}
                        prefix={<SearchOutlined />}></Input>
                    </Form.Item>
                  </CustomSearch>
                  {searchValue && (
                    <CustomButton onClick={() => clearSearch()}>
                      <CloseOutlined />
                    </CustomButton>
                  )}
                </SearchProjectWrapper>
                <Row>
                  <Col span={24}>
                    {hasSelected && (
                      <>
                        <Button
                          style={{ float: 'right' }}
                          type="primary"
                          onClick={() => handleClicDeleteUsers()}>
                          {t('commons.delete')}
                        </Button>
                        <Button
                          style={{ float: 'right', marginRight: '5px' }}
                          type="primary"
                          onClick={() => migrateProjectOrganization()}>
                          {t('migrate')}
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </HeaderAdmin>
        <PaginationAdmin>
          <Table
            rowKey={record => record.id}
            size="default"
            bordered
            scroll={{ x: 1000 }}
            loading={isLoadingTableUsers}
            columns={columns}
            dataSource={adminStore.customListUsers?.data || []}
            rowSelection={rowSelection}
            pagination={{
              defaultPageSize: _limit,
              showSizeChanger: true,
              current: _page,
              pageSizeOptions: settings.adminPageSizeOptions,
              onChange: (page, pageSize) =>
                handleChangePagination(page, pageSize),
              total: adminStore.customListUsers?.count || 1,
              locale: { items_per_page: '' },
              position: ['topRight'],
            }}
            locale={{ emptyText: t('no-data') }}
            onRow={record => ({
              onClick: event => { },
            })}
          />
        </PaginationAdmin>
      </CustomContentAdminWrapper>
    </AdminTemplate>
  )
}

export default inject(
  'organizationStore',
  'commonStore',
  'adminStore',
  'usersStore'
)(observer(Users))
