import { observable, action, decorate, computed } from 'mobx'
import { requests } from '@/requests'
import moment from 'moment'
import i18n from 'i18next';

class ARStore {
  isLoading = false
  filter = {
    startDate: moment().subtract(3, 'day'),
    endDate: moment(),
    projectId: false,
    calbId: false
  }
  currentProjectId = 0
  show3DModel = false
  arTestList = {
    projects: [],
    ar: []
  }
  currentTestData = false
  arPanel = 'default'
  openCVModal = false
  openAdArTestModal = false
  applyAngle = false

  openCV = {
    hessianThreshold: 400,
    nOctaves: 4,
    nOctaveLayers: 4,
    extended: 0,
    upright: 1,
    ratio_thresh: 0.8,
    angle_filter: 0,
    distance_filter: 80,
    pixel_size: 16,
    key_rotation: 80,
    pixel_diff: 50,
    fov: 60,
    fovy: 33.5,
    image_filter: 4
  }

  correctAngle = {
    v: '?',
    h: '?',
    t: '?',
    Points: [],
    apply: true
  }

  currentAngleApply = {
    v: 0,
    h: 0,
    t: 0,
    Points: []
  }

  canvasUrl = false

  calibrations = []
  currentCalibration = false
  currentCalbAr = null
  currentStatText = "no calibration"

  viewType = "default" // linematch , opacity

  setCanvasUrl(url) {
    this.canvasUrl = url
  }

  setCurrentCalibration(c) {
    this.currentCalibration = c
  }

  setCurrentStatText(t) {
    this.currentStatText = t
  }

  setLoading(b) {
    this.isLoading = b
  }

  setShow3DModel(b) {
    this.show3DModel = b
  }

  setArPanel(v) {
    this.arPanel = v
  }

  setOpenCVModel(b) {
    this.openCVModal = b
  }

  setOpenArTestModal(b) {
    this.openAdArTestModal = b
  }


  setCurrentTestData(data) {
    this.currentTestData = data
    if (!data) {
      this.currentStatText = "no calibration"
      this.currentCalbAr = null
    }
  }

  setCurrentCalbAr(c) {
    this.currentCalbAr = c
  }

  setViewType(v) {
    this.viewType = v
  }

  getAllArTest() {
    // var d = new Date(); // Today!
    // d.setDate(d.getDate() - this.filter.dayBefore);
    let url = '/artests?createdAt_gt=' + this.filter.startDate.toISOString() + '&createdAt_lt=' + this.filter.endDate.toISOString() + '&_sort=createdAt:desc'
    this.arTestList = {
      projects: [],
      ar: []
    }
    this.currentTestData = false
    this.currentProjectId = 0
    this.arPanel = 'default'
    return new Promise((resolve, reject) => {
      this.setLoading(true)
      requests.get(url, true).then((response) => {
        // console.log('response', response)
        this.arTestList = response.data
        if (!this.arTestList.ar) this.arTestList.ar = []
        resolve()
      }).catch(error => {
        console.log(error)
        // this.setLoadingProgress(false)
        reject()
      }).finally(() => {
        this.setLoading(false)
      })
    })
  }

  getAllCalibration() {
    return new Promise((resolve, reject) => {
      this.setLoading(true)
      requests.get('/calibrations', true).then((response) => {
        this.calibrations = response.data
        this.setLoading(false)
        resolve()
      }).catch(error => {
        console.log(error)
        this.setLoading(false)
        reject()
      }).finally(() => {
        this.setLoading(false)
      })
    })
  }

  get getCalByArTest() {
    var out = []
    this.calibrations.forEach(cal => {
      const index = cal.data.artests.findIndex(r => r.id === this.currentTestData.id)
      if (index > -1) out.push(cal.id)
    });
    return out
  }

  deleteCalibration(id) {
    return new Promise((resolve, reject) => {
      this.setLoading(true)
      requests.delete(`/calibrations/${id}`, true).then((response) => {
        const index = this.calibrations.findIndex(x => x.id === id)
        this.calibrations.splice(index, 1)
        if (this.calibrations.length > 0)
          this.setCurrentCalibration(this.calibrations[0])
        else {
          this.setCurrentCalibration(false)
        }
        resolve(response.data)
      }).catch(error => {
        console.log(error)
        reject(false)
      }).finally(() => {
        this.setLoading(false)
      })
    })
  }

  deleteArTestFromCalb(arId, calbId) {
    const cIndex = this.calibrations.findIndex(x => x.id === calbId)
    if (cIndex < 0) return
    var ar = this.calibrations[cIndex].data.artests
    if (!ar) ar = []
    const arIndex = ar.findIndex(x => x.id === arId)
    if (arIndex < 0) return
    ar.splice(arIndex, 1)
    let dataUpdate = {
      id: calbId,
      data: { artests: ar }
    }
    this.setLoading(true)
    return new Promise((resolve, reject) => {
      this.setCurrentCalibration(false)
      requests.put(`/calibrations/${calbId}`, dataUpdate, true)
        .then((res) => {
          this.calibrations[cIndex].data.artests = res.data.data.artests
          this.setCurrentCalibration(this.calibrations[cIndex])
          this.setLoading(false)
          resolve(true)
        })
        .catch(error => {
          console.log(error)
          this.setLoading(false)
          this.setCurrentCalibration(this.calibrations[cIndex])
          reject(false)
        })
    })
  }

  createCalibration(data) {
    return new Promise((resolve, reject) => {
      let body = {
        name: data.name,
        OpenCV: data.OpenCV,
        data: {
          artests: []
        }
      }
      this.setLoading(true)
      requests.post('/calibrations', body, true).then((response) => {
        this.calibrations.push(response.data)
        resolve(response.data)
      }).catch(error => {
        console.log(error)
        reject(false)
      }).finally(() => {
        this.setLoading(false)
      })
    })
  }

  updateCalibration(id, arTestId) {
    const index = this.calibrations.findIndex(x => x.id === id)
    if (index < 0) return
    var t = this.calibrations[index].data.artests
    if (!t) t = []
    t.push({ id: arTestId, result: false })
    let dataUpdate = {
      id: id,
      data: { artests: t }
    }
    this.setLoading(true)
    return new Promise((resolve, reject) => {
      requests.put(`/calibrations/${id}`, dataUpdate, true)
        .then((res) => {
          this.calibrations[index].data.artests = res.data.data.artests

          // const findArTest = this.arTestList.ar.findIndex(x => x.id === arTestId)
          // if (findArTest > -1) {
          //   if (!this.arTestList.ar[findArTest].cal) this.arTestList.ar[findArTest].cal = []
          //   this.arTestList.ar[findArTest].cal.push(id)
          // }
          // if (!this.currentTestData.cal) this.currentTestData.cal = []
          // this.currentTestData.cal.push(id)
          this.setLoading(false)
          resolve(true)
        })
        .catch(error => {
          console.log(error)
          this.setLoading(false)
          reject(false)
        })
    })
  }

  updateMassCalibration(id, arIds) {
    const index = this.calibrations.findIndex(x => x.id === id)
    if (index < 0) return
    var t = this.calibrations[index].data.artests
    if (!t) t = []
    arIds.forEach(arId => {
      t.push({ id: arId, result: false })
    });

    let dataUpdate = {
      id: id,
      aids: arIds,
      data: { artests: t }
    }
    this.setLoading(true)
    return new Promise((resolve, reject) => {
      requests.put(`/calibrations/${id}`, dataUpdate, true)
        .then((res) => {
          this.calibrations[index].data.artests = res.data.data.artests
          this.currentCalibration = this.calibrations[index]
          // const findArTest = this.arTestList.ar.findIndex(x => x.id === arTestId)
          // if (findArTest > -1) {
          //   if (!this.arTestList.ar[findArTest].cal) this.arTestList.ar[findArTest].cal = []
          //   this.arTestList.ar[findArTest].cal.push(id)
          // }
          // if (!this.currentTestData.cal) this.currentTestData.cal = []
          // this.currentTestData.cal.push(id)
          this.setLoading(false)
          resolve(true)
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('something-went-wrong'))
          this.setLoading(false)
          reject(false)
        })
    })
  }



  deleteArTest(id) {
    this.setLoading(true)
    return new Promise((resolve, reject) => {
      requests.delete(`/artests/${id}`, true)
        .then(() => {
          const index = this.arTestList.ar.findIndex(x => x.id === id)
          this.arTestList.ar.splice(index, 1)
          this.setLoading(false)
          resolve(true)
        })
        .catch(error => {
          console.log(error)
          this.setLoading(false)
          reject(false)
        })
    })
  }

  setCurrentProjectId(id) {
    this.currentProjectId = id
  }

  setOpenCV(data) {
    Object.assign(this.openCV, data)
  }

  setFilter(data) {
    Object.assign(this.filter, data)
  }

  setCorrectAngle(data) {
    Object.assign(this.correctAngle, data)
  }

  setCurrentAngleApply(data) {
    Object.assign(this.currentAngleApply, data)
  }

}

decorate(ARStore, {
  isLoading: observable,
  setLoading: action,

  currentTestData: observable,
  setCurrentTestData: action,

  filter: observable,
  setFilter: action,

  arTestList: observable,
  getAllArTest: action,

  currentProjectId: observable,
  setCurrentProjectId: action,

  show3DModel: observable,
  setShow3DModel: action,

  arPanel: observable,
  setArPanel: action,

  openCV: observable,
  setOpenCV: action,

  openCVModal: observable,
  setOpenCVModel: action,

  calibrations: observable,
  createCalibration: action,
  updateCalibration: action,
  updateMassCalibration: action,
  currentCalibration: observable,
  setCurrentCalibration: action,

  deleteArTest: action,
  applyAngle: observable,

  currentCalbAr: observable,
  setCurrentCalbAr: action,

  currentStatText: observable,
  setCurrentStatText: action,

  correctAngle: observable,
  setCorrectAngle: action,

  currentAngleApply: observable,
  setCurrentAngleApply: action,

  viewType: observable,
  setViewType: action,

  getCalByArTest: computed,
  deleteCalibration: action,
  deleteArTestFromCalb: action,

  canvasUrl: observable,
  setCanvasUrl: action,

  openAdArTestModal: observable,
  setOpenArTestModal: action
})
export default new ARStore()
