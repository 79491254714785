import React, { Fragment, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { Modal, Form, Input, Button, message, Row } from 'antd'
import validator from '@/validator'
import TreeUtils from '@/tree-utils'
import { Trans, useTranslation } from 'react-i18next';
import { toJS } from 'mobx'

const ModalEditFile = ({ projectStore, commonStore, usersStore }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const AddForm = () => {
    useEffect(() => {
      if (projectStore.fileEdit) {
        form.setFieldsValue({ title: projectStore.fileEdit.title });
      }
    }, [projectStore.fileEdit])
    return (
      <Form form={form} name="edit-file" layout="vertical">
        <Form.Item label={t('name')} name="title" rules={[
          {
            required: true,
            message: t('please-input-the-name-of-file'),
          },
          { validator: validator.validateEmptyString },
        ]}>
          <Input
            placeholder={t('input-file-name')} />
        </Form.Item>
      </Form>
    )
  }

  const onCancel = () => {
    projectStore.setFileEdit()
    form.resetFields();
    projectStore.setShowEditFileModal(false)
  }

  const onCreate = () => {
    form.validateFields().then(values => {
      const { treeData } = projectStore.projectDetail;
      const newNode = {
        title: values.title
      }
      editTreeNode(newNode, treeData);
    });
  }

  const editTreeNode = (child, data) => {
    const startTime = Date.now();
    let toNode;
    if (projectStore?.fileEdit?.parentKey) {
      const parrentNode = TreeUtils.searchTreeNode(data, 'key', projectStore?.fileEdit?.parentKey);
      toNode = parrentNode.children;
    } else {
      toNode = data;
    }
    const node = toNode.find(child => child.key === projectStore?.fileEdit?.key);
    node.title = child.title;
    if (node) {
      let modelList = projectStore.modelList.map(c => c);
      let temp = modelList.map(elm => {
        if (elm.id === node.modelId) {
          elm.name = node.title;
        }
        return { ...elm }
      })
      projectStore.setModelList(temp)
    }
    projectStore.setLoadingProgress(true);
    projectStore.updateProjectTreeData({ treeData: data, store: 'treedata' }).then((res) => {
      projectStore.projectDetail.treeData = res.treeData;
      form.resetFields()
      projectStore.setSelectedNode();
      projectStore.setShowEditFileModal(false)
      message.success(t('edit-file-successfully'))
      commonStore.loggingFunction('Edit file', 'success', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
    }).catch(err => {
      console.log(err)
      message.error(t('edit-file-failed'))
      commonStore.loggingFunction('Edit file', 'failed', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
    }).finally(() => {
      projectStore.setLoadingProgress(false);
    })
  }

  return (
    <Modal
      visible={projectStore.showEditFileModal}
      title={t('edit-file-name')}
      okText={t('commons.create')}
      cancelText={t('commons.cancel')}
      onCancel={onCancel}
      onOk={onCreate}
      forceRender={true}
      zIndex={10000}
      footer={
        <Row justify='end'>
          <Button key="back" onClick={onCancel}>
            {t('commons.cancel')}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={projectStore.isAppLoading}
            onClick={onCreate}>
            {t('edit-file-name')}
          </Button>
        </Row>
      }>
      <AddForm />
    </Modal>
  )
}

export default inject('projectStore', 'commonStore', 'usersStore')(observer(ModalEditFile))