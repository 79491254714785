import { CloseOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, InputNumber, message, Select } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import Axios from 'axios'
import { inject, observer } from 'mobx-react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import uuid from 'uuid'
import { getFeedbackIcon } from '@/lib/projectLib'
import validator from '@/validator'
import { UploadButton } from '@/components/elements'
import { apiUrl } from '@/config'
import { basicFields } from './ControlProperties'
import { FormPropertiesHeading, FormPropertiesTitle, FormPropertiesWrapper, } from './style'
const { TextArea } = Input
const { Option } = Select

const FormProperties = ({ feedbackStore, commonStore, history, match }) => {
    const { t } = useTranslation();
    const selectedElementIndex = feedbackStore.selectedElementIndex
    // const elemInfo = feedbackStore.submittedFormBuilder.elements
    const [elemInfo, setElemInfo] = useState([]);
    const [reloadForm, setReloadForm] = useState(false);


    useEffect(() => {
        if (feedbackStore.submittedFormBuilder.elements) {
            setElemInfo(feedbackStore.submittedFormBuilder.elements)
        }
    }, [reloadForm, feedbackStore.submittedFormBuilder.elements])
    const handleChange = (target, value) => {
        feedbackStore.updateElementProperties(target, value)
    }

    const onClickFormPropertiesHandler = () => {
        feedbackStore.clearSelectedElementIndex()
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length > 1) {
            e.fileList.shift()
        }
        return e && e.fileList;
    };
   
    const propsUpload = {
        name: 'file',
        multiple: false,
        listType: "picture",
        // fileList,
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error(t('you-can-only-upload-jpg-png-file'));
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error(t('image-must-smaller-than-2MB'));
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(t('the-file-upload-failed', {filename: info.file.name}));
            }
        },

        onRemove() {
            handleChange('uploadId', null)
            handleChange('value', null)
        }
        ,
        customRequest: ({ onSuccess, onError, file, onProgress }, event) => {
            const REACT_APP_ENDPOINT = 'http://localhost:8080'
            const formData = new FormData()
            formData.append('files', file, file.name);
            Axios({
                async: true,
                crossDomain: true,
                processData: false,
                contentType: false,
                mimeType: 'multipart/form-data',
                method: 'POST',
                url: `${apiUrl}/upload`,
                headers: {
                    Authorization: `Bearer ${commonStore.token}`,
                },
                onUploadProgress: event => {
                    onProgress({ percent: (event.loaded / event.total) * 100 }, file);
                },
                data: formData,

            }).then(res => {
                onSuccess(res => normFile(res.data))
                message.success(t('upload-feedback-image-successfull'));
                handleChange('uploadId', res.data[0].id)
                handleChange('value', res.data[0].url)
                setReloadForm(!reloadForm)
            })
                .catch(err => message.error(t('has-an-error-please-try-again')))
        }
        ,

    };
    const CustomForm = props => {
        const [form] = Form.useForm();
        const [tagInput, setTagInput] = useState('')
        return (
            <Form
                form={form}
                className={'properties-form'}
                layout="vertical"
                id={'properties-form'}>
                {
                    elemInfo.length > 0 && selectedElementIndex !== undefined && elemInfo[selectedElementIndex].isReadonly
                        ? null :
                        <Form.Item
                            name="isRequired"
                            valuePropName="checked"
                            initialValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].isRequired : false}>
                            <Checkbox onChange={e => handleChange('isRequired', e.target.checked)}>{t('required')}</Checkbox>
                        </Form.Item>
                }
                <Form.Item
                    label={t('field-label')}
                    name="title"
                    initialValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].title : ''}
                    rules={[
                        {
                            required: true,
                            message: t('do-not-leave-this-field-blank'),
                        },
                        { validator: validator.validateFormBuilderElementTitle },
                    ]}>
                    <Input placeholder="Field label" onChange={e => handleChange('title', e.target.value)} />
                </Form.Item>
                <Form.Item
                    label={t('description')}
                    name="description"
                    initialValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].description : ''}
                    rules={[
                        { validator: validator.validateEmptyString },
                    ]}>
                    <TextArea onChange={e => handleChange('description', e.target.value)} placeholder="Input description" rows={4} />
                </Form.Item>
                {
                    elemInfo.length > 0 && selectedElementIndex !== undefined && elemInfo[selectedElementIndex].className === 'urlReadonly' ?
                        <Fragment>
                            <Form.Item
                                label={t('text')}
                                name="text"
                                initialValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].text : ''}
                                rules={[
                                    { validator: validator.validateEmptyString },
                                ]}>
                                <TextArea onChange={e => handleChange('text', e.target.value)} placeholder="Input text url" rows={4} />
                            </Form.Item>
                            <Input
                                placeholder="Link Url"
                                onChange={e => handleChange('value', e.target.value)}
                                defaultValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].value : ''}
                            />
                        </Fragment>
                        : null
                }
                {
                    elemInfo.length > 0 && selectedElementIndex !== undefined && elemInfo[selectedElementIndex].className === 'Iframe' ?
                        <Fragment>
                            <Form.Item
                                label={t('HTTPS Link')}>
                                <Input
                                    placeholder="HTTPS Link"
                                    onChange={e => handleChange('value', e.target.value)}
                                    defaultValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].value : ''}
                                />
                            </Form.Item>                            
                        </Fragment>
                        : null
                }
                {
                    elemInfo.length > 0 && selectedElementIndex !== undefined && elemInfo[selectedElementIndex].className === 'image' ?
                        <Form.Item
                            label={t('image')}
                            name="dragger"
                            getValueFromEvent={normFile}
                            valuePropName='fileList'
                        >
                            <Dragger
                                {...propsUpload}
                                className='dragger-upload'
                            >
                                {
                                    selectedElementIndex !== undefined && elemInfo[selectedElementIndex]
                                        && elemInfo[selectedElementIndex].value ? (
                                        // eslint-disable-next-line jsx-a11y/alt-text
                                        <img
                                            src={elemInfo[selectedElementIndex].value}
                                            style={{
                                                minHeight: '150px',
                                                maxHeight: "200px",
                                                objectFit: "cover"
                                            }}
                                        />
                                    ) : <UploadButton />
                                }
                            </Dragger>
                        </Form.Item>
                        : null
                }
                {
                    elemInfo.length > 0 && selectedElementIndex !== undefined && (elemInfo[selectedElementIndex].className === 'radioButton'
                        || elemInfo[selectedElementIndex].className === 'radioGroup'
                        || elemInfo[selectedElementIndex].className === 'checkboxGroup'
                        || elemInfo[selectedElementIndex].className === 'smileyButton'
                        || elemInfo[selectedElementIndex].isReadonly)
                        ? null :
                        <Form.Item
                            label={t('instruction')}
                            name="placeHolder"
                            initialValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].placeHolder : ''}
                            rules={[
                                { validator: validator.validateEmptyString },
                            ]}>

                            <TextArea onChange={e => handleChange('placeHolder', e.target.value)}
                                placeholder={t('write-something')} rows={4} />
                        </Form.Item>
                }
                {
                    elemInfo.length > 0 && selectedElementIndex !== undefined && (elemInfo[selectedElementIndex].className !== 'singleLine'
                        && elemInfo[selectedElementIndex].className !== 'multiLine')
                        ? null :
                        <Fragment>
                            <Form.Item
                                label={t('min-character')}
                                name="minLength"
                                initialValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].minLength : 0}
                            >
                                <InputNumber
                                    onChange={e => handleChange('minLength', e)}
                                    className={'custom-input-number'}
                                    min={0} placeholder="Input min character" />
                            </Form.Item>
                            <Form.Item
                                label={t('max-character')}
                                name="maxLength"
                                initialValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].maxLength : 0}
                            >
                                <InputNumber
                                    onChange={e => handleChange('maxLength', e)}
                                    className={'custom-input-number'}
                                    min={0} placeholder="Input max character" />
                            </Form.Item>
                        </Fragment>
                }
                {
                    elemInfo.length > 0 && selectedElementIndex !== undefined && (elemInfo[selectedElementIndex].className !== 'dropdown'
                        && elemInfo[selectedElementIndex].className !== 'radioButton'
                        && elemInfo[selectedElementIndex].className !== 'radioGroup'
                        && elemInfo[selectedElementIndex].className !== 'checkboxGroup'
                        && elemInfo[selectedElementIndex].className !== 'smileyButton')
                        ? null :
                        <Fragment>
                            <Form.Item
                                label={t('choices')}
                                name="choices"
                                initialValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].className === 'smileyButton' ? getFeedbackIcon(elemInfo[selectedElementIndex].choices.slice(), basicFields.filter(item => item.className === 'smileyButton')[0].choices) : elemInfo[selectedElementIndex].choices.slice() : ''}>
                                <Select
                                    dropdownClassName={tagInput && elemInfo[selectedElementIndex].choices.indexOf(tagInput) === -1 ? 'hide-typing' : null}
                                    onSearch={val => setTagInput(val)}
                                    mode={'tags'} size={'large'}
                                    placeholder="Add or remove choices"
                                    onChange={e => {
                                        handleChange('choices', e)
                                        setTagInput('')
                                    }}
                                    dropdownRender={menu => (
                                        elemInfo.length > 0 && selectedElementIndex !== undefined && elemInfo[selectedElementIndex].className !== 'smileyButton' ?
                                            (<div onMouseDown={() => setTagInput('')}>
                                                {menu}
                                                {!tagInput ? null : elemInfo[selectedElementIndex].choices.indexOf(tagInput) !== -1 ? null :
                                                    <a style={{
                                                        padding: 15,
                                                        fontWeight: 500,
                                                        wordBreak: 'break-all',
                                                        color: commonStore.appTheme.solidColor,
                                                        display: 'block',
                                                    }}>{t('add-new-tags')} "{tagInput}" </a>
                                                }
                                            </div>) :
                                            (<div onMouseDown={() => setTagInput('')}>
                                                {menu}
                                            </div>
                                            )
                                    )}>
                                    {elemInfo.length > 0 && selectedElementIndex !== undefined && elemInfo[selectedElementIndex].className === 'smileyButton' ?
                                        basicFields.filter(item => item.className === 'smileyButton')[0].choices.map(choice => <Option key={uuid()} value={choice}><img src={choice} alt="" width="22" /></Option>)
                                        :
                                        elemInfo.length > 0 && selectedElementIndex !== undefined && elemInfo[selectedElementIndex].choices.map(choice => <Option key={uuid()} value={choice}>{choice}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Fragment>
                }
                {
                    elemInfo.length > 0 && selectedElementIndex !== undefined && (elemInfo[selectedElementIndex].className !== 'radioButton'
                        && elemInfo[selectedElementIndex].className !== 'radioGroup'
                        && elemInfo[selectedElementIndex].className !== 'checkboxGroup')
                        ? null :
                        <Fragment>
                            <Form.Item
                                label={t('direction')}
                                name="direction"
                                initialValue={elemInfo.length > 0 && selectedElementIndex !== undefined ? elemInfo[selectedElementIndex].direction : ''}>
                                <Select
                                    onChange={e => handleChange('direction', e)}
                                    placeholder="Display by row or col">
                                    <Option value={'horizontal'}>Horizontal</Option>
                                    <Option value={'vertical'}>Vertical</Option>
                                </Select>
                            </Form.Item>
                        </Fragment>
                }
            </Form>
        )
    }

    return (
        <Fragment>
            {
                feedbackStore.selectedElementIndex === undefined ? null :
                    <FormPropertiesWrapper>
                        <FormPropertiesHeading>
                            <FormPropertiesTitle>{t('properties')}</FormPropertiesTitle>
                            <Button type="text" shape="circle" icon={<CloseOutlined style={{ color: 'rgba(0,0,0,.45)' }} />} onClick={() => onClickFormPropertiesHandler()} />
                        </FormPropertiesHeading>
                        <CustomForm />
                    </FormPropertiesWrapper>
            }
        </Fragment>
    )
}

export default withRouter(inject('feedbackStore', 'commonStore')(observer(FormProperties)))