import { Button, Card, Col, Empty, Popconfirm, Row, Tooltip, message } from 'antd'
import { QuestionCircleOutlined, SettingOutlined, SnippetsOutlined, DeleteOutlined, CheckCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link, Redirect } from 'react-router-dom'
import { assetUrl } from '@/config'
import Container from '@/components/layout/Container'
import DefaultTemplate from '@/components/layout/DefaultTemplate'
import { ProjectHeading, ProjectWraper, ContentProjectInfo } from './CustomStyled'
import ModalAddProject from './ModalAddProject'
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import DuplicateProjectModal from './DuplicateProjectModal';
import moment from 'moment';
const { Meta } = Card

const ProjectListPage = props => {
  const { projectStore, commonStore, history, adminStore ,usersStore } = props
  const { t } = useTranslation();

  const handleDeleteProject = projectId => {
    projectStore.setLoadingProgress(true)
    projectStore.deleteProject(projectId).then(res => {
      projectStore.getAllProjects().then(() => {
        projectStore.setLoadingProgress(false)
        message.success(t('project-name-deleted-successfully', { name: res.data.name }))
      }).catch(error => {
        projectStore.setLoadingProgress(false)
      })
    }).catch(error => {
      projectStore.setLoadingProgress(false)
    })
  }

  const handleViewProjectTeams = projectId => {
    history.push(`/project-settings/${projectId}`)
  }

  const handleDuplicateProject = projectId => {
    projectStore.setShowDuplicateProjectModal(projectId)
  }

  const acceptInvite = projectId => {
    let submitValues = {
      inviteStatus: 'accepted',
      acceptedDate: Date.now()
    }
    projectStore.acceptProjectInvitation(projectId, submitValues)
  }
  const handleClickLeaveProject = (projectuserrolesId) => {
    adminStore.setLoadingProgress(true)
    adminStore.removeUserFromProject(projectuserrolesId).then(res => {
      adminStore.setLoadingProgress(false)
      const index = projectStore.projectList.findIndex(x => x._id === projectuserrolesId)
      const temp = [...projectStore.projectList]
      temp.splice(index, 1)
      projectStore.setProjects(temp)
      message.success(t('leave-project-success'))
    }).catch(error => {
      adminStore.setLoadingProgress(false)
      message.error(t('leave-project-fail'))
      return;
    })
  }
  const AllProject = ({ shareProject }) => {
    let projectList
    if (shareProject && shareProject !== 'template') {
      projectList = toJS(projectStore.projectList)
        .filter(item => item.project !== null)
        .filter(item => item?.projectrole?.role !== 'project_owner' || (item.projectrole?.role === 'project_owner' && item?.user?._id !== item?.project?.user))
        .filter(item => !((item?.project?.isOrganizationTemplate || item?.project?.isSystemTemplate) && item?.inviteStatus === 'accepted'))
    } else if (shareProject === 'template') {
      let _projects
      _projects = toJS(projectStore.projectList)
        .filter(item => item.project !== null)
        .filter(item => item?.projectrole?.role && item.inviteStatus === 'accepted')
        .filter(item => item.project?.isOrganizationTemplate || item.project?.isSystemTemplate)

      projectList = _projects.map(elm => {
        if (elm?.projectrole?.role === 'project_owner' && elm?.user?._id === elm?.project?.user) {
          return {
            ...elm,
            isMyProject: true
          }
        } else {
          return elm
        }
      })
    }
    else {
      projectList = toJS(projectStore.projectList)
        .filter(item => item.project !== null)
        .filter(item => item?.projectrole?.role === 'project_owner')
        .filter(item => item?.user?._id === item?.project?.user)
        .filter(item => (!item.project.isOrganizationTemplate && !item.project.isSystemTemplate))
    }

    if (projectList.length === 0) return <Empty description={<span>{t('no-data')}</span>} />
    return (
      <Fragment>
        {projectList.map((item, index) => {
          const invitedAction = [
            <Tooltip title={t('project-settings')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <SettingOutlined
                onClick={() => handleViewProjectTeams(item.project._id)}
                key="settings"
              />
            </Tooltip>,
            <Popconfirm
              title={t('confirm-leave-project')}
              onConfirm={() => handleClickLeaveProject(item._id)}
              okText={t('commons.yes')}
              cancelText={t('commons.no')}>
              <Tooltip title={t('leave')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <LogoutOutlined key="setting" />
              </Tooltip>
            </Popconfirm>,
          ]
          if(item && (item?.projectrole?.roleName === "Manager")){
            invitedAction.push(<Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() =>
                handleDeleteProject(item.project._id)
              }
              okButtonProps={{ danger: true }}
              okText={t('commons.delete')}
              cancelText={t('commons.cancel')}
              title={
                t('are-you-sure-delete-project')
              }>
              <Tooltip title={t('commons.delete')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <DeleteOutlined key="setting" />
              </Tooltip>
            </Popconfirm>)
          }
          if(item && item.email === "maintenance@xd-visuals.fi" && item.inviteStatus ==="accepted"){
            invitedAction.push(
              <Tooltip title={t('duplicate-project')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <SnippetsOutlined
                onClick={() => handleDuplicateProject(item.project)}
                key="duplicate"
              />
            </Tooltip>
            )
          } else if(item && (item.projectrole?.roleName === "Manager" || item.project?.organization?.organizationuserroles?.find(x=> x?.organizationrole?.type === "org_admin")) && item.inviteStatus ==="accepted" && shareProject){
            invitedAction.push(
              <Tooltip title={t('duplicate-project')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <SnippetsOutlined
                onClick={() => handleDuplicateProject(item.project)}
                key="duplicate"
              />
            </Tooltip>
            )
          }
          return (
            <Col key={index} xs={24} sm={12} md={6}>
              <Card
                className={`project-card ${item.inviteStatus === 'accepted'
                  ? 'accepted-card'
                  : 'action-card'
                  }`}
                cover={
                  item.inviteStatus === 'accepted' ? (
                    <Link to={`/project/${item.project._id}`}>
                      <img
                        style={{ width: '100%' }}
                        alt="example"
                        src={
                          item.project?.thumbnail 
                            ? (
                              item.project?.thumbnail?.url ? item.project?.thumbnail?.url
                              : '/project-noimage.jpg')
                            : '/project-noimage.jpg'
                        }
                      />
                    </Link>
                  ) : (
                    <img
                      style={{ width: '100%', opacity: 0.4 }}
                      src={
                        item.project?.thumbnail
                          ? assetUrl +
                          item.project.thumbnail.hash +
                          item.project.thumbnail.ext
                          : '/project-noimage.jpg'
                      }
                      alt=""
                    />
                  )
                }
                actions={
                  item.inviteStatus === 'accepted'
                    ? (shareProject && !item.isMyProject)
                      ? invitedAction
                      : [
                        <Tooltip title={t('project-settings')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                          <SettingOutlined
                            onClick={() =>
                              handleViewProjectTeams(item.project._id)
                            }
                            key="settings"
                          />
                        </Tooltip>,
                        <Tooltip title={t('duplicate-project')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                          <SnippetsOutlined
                            onClick={() =>
                              handleDuplicateProject(item.project)
                            }
                            key="duplicate"
                          />
                        </Tooltip>,
                        <Popconfirm
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() =>
                            handleDeleteProject(item.project._id)
                          }
                          okButtonProps={{ danger: true }}
                          okText={t('commons.delete')}
                          cancelText={t('commons.cancel')}
                          title={
                            t('are-you-sure-delete-project')
                          }>
                          <Tooltip title={t('commons.delete')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                            <DeleteOutlined key="setting" />
                          </Tooltip>
                        </Popconfirm>,
                      ]
                    : [
                      <Button
                        size={'small'}
                        type={'primary'}
                        onClick={() => {
                          acceptInvite(item._id)
                        }}>
                        <CheckCircleOutlined />
                        {t('commons.accept')}
                      </Button>,
                      <Button size={'small'} danger ghost>
                        <CheckCircleOutlined />
                        {t('commons.reject')}
                      </Button>,
                    ]
                }>
                <Tooltip placement={'topLeft'} title={item.project?.name} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                  <Meta 
                    title={item.project.name} 
                    description={
                    <div className="project-info">
                      <ContentProjectInfo>{item.project?.organization?.name}</ContentProjectInfo>
                      <ContentProjectInfo>{`${item.project?.tilesetData?.coordinateSystem?.code && `EPSG:${item.project.tilesetData.coordinateSystem.code}` || ''} ${item.project?.tilesetData?.coordinateSystem?.name && item.project?.tilesetData?.coordinateSystem?.name || ''}`}</ContentProjectInfo>
                      <ContentProjectInfo>{t('created')}: <span>{moment(item.project?.createdAt).format('DD.MM.YYYY')}</span></ContentProjectInfo>
                      <ContentProjectInfo>{t('last-used')}: <span>{moment(item.project?.updatedAt).format('DD.MM.YYYY')}</span></ContentProjectInfo>
                      {!item.project?.organization?.name && < br />}
                      {!item.project?.tilesetData?.coordinateSystem && < br />}
                      {!item?.project?.createdAt && < br />}
                      {!item?.project?.updatedAt && < br />}
                    </div>
                    }
                  />
                </Tooltip>
              </Card>
            </Col>
          )
        })}
      </Fragment>
    )
  }

  useEffect(() => {
    commonStore.setCurrentPage('projects')
    const startTime = Date.now();
    if(usersStore.currentUser?.id){
      commonStore.setLoadingProgress(true)
      projectStore.getAllProjects().then(res => {
        commonStore.setLoadingProgress(false)
        commonStore.loggingFunction('get project list','success',startTime,usersStore.currentUser )
      }).catch(err => {
        commonStore.setLoadingProgress(false)
        commonStore.loggingFunction('get project list','failed',startTime,usersStore.currentUser )
        console.log(err)
        if (err?.status === 401) {
          return <Redirect push to="/auth/login" />
        }
      })
    }
    return () => {
      projectStore.clearProjectList()
    }
  }, [])

  return (
    <HelmetProvider>
      <DefaultTemplate>
        <Helmet>
          <title>{t('project-list')}</title>
        </Helmet>
        <ModalAddProject />
        <DuplicateProjectModal />
        <ProjectWraper>
          <Container>
            <ProjectHeading>{t('my-projects')}</ProjectHeading>
            <Row gutter={16} style={{ marginBottom: 15 }}>
              {projectStore.projectList.length > 0 ? (
                <AllProject shareProject={false} />
              ) : (
                <Empty description={<span>{t('no-data')}</span>} />
              )}
            </Row>
            <ProjectHeading>{t('invited-projects')}</ProjectHeading>
            <Row gutter={16} style={{ marginBottom: 15 }}>
              {projectStore.projectList.length > 0 ? (
                <AllProject shareProject={true} />
              ) : (
                <Empty description={<span>{t('no-data')}</span>} />
              )}
            </Row>
            <ProjectHeading>{t('template-projects')}</ProjectHeading>
            <Row gutter={16} style={{ marginBottom: 15 }}>
              {projectStore.projectList.length > 0 ? (
                <AllProject shareProject='template' />
              ) : (
                <Empty description={<span>{t('no-data')}</span>} />
              )}
            </Row>
          </Container>
        </ProjectWraper>
      </DefaultTemplate>
    </HelmetProvider>
  )
}

export default inject(
  'projectStore',
  'commonStore',
  'adminStore',
  'usersStore'
)(observer(ProjectListPage))
