import {
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  UserOutlined,
  EditOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  Upload,
  Empty,
  Avatar,
} from 'antd'
import Axios from 'axios'
import { inject, observer } from 'mobx-react'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import countryList from 'react-select-country-list'
import AdminTemplate from '@/components/layout/AdminTemplate'
import '../style.css'
import { LoadingSpinner } from '@/components/elements'
import AddUser from './AddUser'
import AddLicense from './AddLicense'
import CustomProjectField from './CustomProjectField'
import CustomUserField from './CustomUserField'
import {
  EmailUser,
  ItemSetting,
  LogoWrapper,
  NameUser,
  SettingWrapper,
  StatusUser,
  TableLicenses,
} from './style'
import { toJS } from 'mobx'
import moment from 'moment'
import {  useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { CustomContentAdminWrapper, HeaderAdmin } from '../style'
import { apiUrl } from '@/config'
import 'moment/locale/en-gb'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/es'
import 'moment/locale/vi'
import EditLicenseModal from './EditLicenseModal'
import { isMobile, isTablet } from 'react-device-detect'
import { ReactComponent as ViewpointDelete } from '@/assets/svgs/viewpoint-delete.svg'
import { UploadButton, SVGIcon } from '@/components/elements'

const { Content } = Layout
const { Option } = Select
const { TextArea } = Input
const { Dragger } = Upload

const EditOrganization = ({
  usersStore,
  organizationStore,
  commonStore,
  adminStore,
}) => {
  const { t } = useTranslation()
  moment.locale(`${commonStore.language}`)
  const history = useHistory()
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [imageUrl, setimageUrl] = useState(null)
  const [isLoadingFormEdit, setisLoadingFormEdit] = useState(false)
  const [uploadInfo, setuploadInfo] = useState(null)
  const [inputFields, setInputFields] = useState([])
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleModalLicense, setVisibleModalLicense] = useState(false)
  const { currentOrganizationView } = adminStore
  const defaultOrganizationRole = organizationStore.organizationRoles.find(
    v => v.type === 'org_user'
  )
  const [selectedValues, setSelectedValues] = useState([])
  const [reload, setReload] = useState(false)
  const { organizationID } = useParams()
  const [defaultFileList, setDefaultFileList] = useState(null)
  const [listProjects, setListProjects] = useState([])
  const [valueProjectsPass, setValueProjectsPass] = useState([])
  const [listLicenses, setListLicenses] = useState([])
  const [listUsers, setListUsers] = useState([])
  const [selectedLicenses, setSelectedLicense] = useState([])

  const optionCountries = useMemo(() => countryList().getData(), [])
  const isTabletOrMobileDevice = useMediaQuery({ query: '(max-width: 995px)' })
  const isOpenSideNav = useMediaQuery({ query: '(max-width: 585px)' })
  const isTextButtonHeader = useMediaQuery({ query: '(max-width: 500px)' })

  useEffect(() => {
    if (usersStore.users) {
      let _valueUser = usersStore.users.map(elm => {
        return {
          id: elm.id,
          email: elm.email,
          username: elm.username,
        }
      })
      setListUsers(_valueUser)
    }
  }, [usersStore.users])

  useEffect(() => {
    const populateOrganizationFormData = async () => {
      adminStore.clearStatus()
      setReload(false)
      setisLoadingFormEdit(true)
      commonStore.setCurrentPage('admin/organization')
      setDefaultFileList(null)
      organizationStore.setCurrentLogoUpload({})
      adminStore.setRiderect(true)
      organizationStore.getOrganizationRoles()
      // adminStore.getLicense()
      adminStore.getLicenseTypes()
      if (organizationID !== 'new') {
        adminStore.setEditMode(true)
        let _projects = []
        await adminStore
          .getCurrentOrganizationDetail(organizationID)
          .then(async res => {
            let _orguserroles = res.data?.organizationuserroles
            if (_orguserroles) {
              let _userIDs = []
              _orguserroles.map(el => {
                if (el.user && el.user.id && el.email === el.user.email) {
                  _userIDs.push(el.user.id)
                }
              })
              setisLoadingFormEdit(true)
              await adminStore
                .customFindUser('list', _userIDs)
                .then(resp => {
                  let _valueUser = resp.data.map(elm => {
                    return {
                      id: elm.id,
                      email: elm.email,
                      username: elm.username,
                      firstName: elm.firstName,
                      lastName: elm.lastName,
                    }
                  })
                  setListUsers(_valueUser)
                  handleDefaultSelectUser(_orguserroles, resp.data)
                })
                .catch(err => {
                  setisLoadingFormEdit(false)
                })
              setisLoadingFormEdit(false)
            }
            let _li = res.data.licenses
            let _licenses = _li ? _li.map(v => v.id) : undefined
            setSelectedLicense(_licenses)
            for (let i = 0; i < _li.length; i++) {
              _li[i].expiration = _li[i]?.expiration
                ? _li[i].expiration
                : moment(_li[i].createdAt)
                    .add(_li[i].timeLimit, 'days')
                    .toString()
              _li[i].activated = _li[i]?.activated
                ? _li[i].activated
                : _li[i].createdAt
              _li[i].isActive = _li[i]?.isActive ? _li[i].isActive : false
            }
            setListLicenses(_li)
            let _isActive = organizationID === 'new' ? true : res.data?.isActive
            let _isAutomaticRenew =
              organizationID === 'new' ? false : res.data?.isAutomaticRenew

            if (res.data.projects && res.data.projects.length > 0) {
              _projects = res.data.projects.map(item => {
                if (item.id) {
                  return item.id
                } else {
                  return item
                }
              })
            }
            setValueProjectsPass(res.data.projects)
            setListProjects(
              res.data.projects
                ? res.data.projects.filter(c => !c.isDeleted)
                : []
            )
            form.setFieldsValue({
              name: res.data?.name,
              address: res.data?.address,
              country: res.data?.country,
              city: res.data?.city,
              zipCode: res.data?.zipCode,
              website: res.data?.website,
              isActive: _isActive,
              projects: _projects,
              isAutomaticRenew: _isAutomaticRenew,
            })
          })
        // setListProjects(projectStore.totalProject)
      } else {
        adminStore.setEditMode(false)
        adminStore.setCreateMode(true)
        adminStore.clearCurrentOrganizationDetail()
        form.setFieldsValue({ isActive: true })
        form.setFieldsValue({ isAutomaticRenew: false })
        setisLoadingFormEdit(true)
      }
      setisLoadingFormEdit(false)
    }
    if(usersStore?.currentUser?.id){
      populateOrganizationFormData()
    }
  }, [reload])

  useEffect(() => {
    if (
      adminStore.createMode ||
      adminStore.currentOrganizationView.logo === null
    ) {
      setDefaultFileList(null)
    } else if (adminStore.currentOrganizationView.logo) {
      setimageUrl(adminStore.currentOrganizationView.logo.url)
      setDefaultFileList([
        {
          uid: adminStore.currentOrganizationView.logo.id,
          name: adminStore.currentOrganizationView.logo.name,
          status: 'done',
          url: adminStore.currentOrganizationView.logo.url,
        },
      ])
    } else {
      setDefaultFileList(null)
    }
  }, [currentOrganizationView])

  //remove license list click icon
  const handleRemoveLicense = (_licen, index) => {
    setisLoadingFormEdit(true)
    adminStore
      .deleteLicense(_licen.id)
      .then(res => {
        setisLoadingFormEdit(false)
        message.success(t('delete-license-successfully'))
        let _listLicenses = listLicenses
        _listLicenses.splice(index, 1)
        setListLicenses(_listLicenses)
        let _listID = _listLicenses.map(v => v.id)
        setSelectedLicense(_listID)
      })
      .catch(err => {
        setisLoadingFormEdit(true)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  //edit license list click icon
  const handleEditLicense = (_licen, index) => {
    adminStore.setLicenseOrgEdit({ ..._licen, index })
  }

  //update license list from modal
  const handleUpdateLicense = (item, index) => {
    let _listLicenses = [...listLicenses]
    _listLicenses[index] = item
    setListLicenses(_listLicenses)
  }

  //add license list ui modal
  const handleSetFieldvalue = payload => {
    let _listLicenses = listLicenses
    _listLicenses.push(payload)
    setListLicenses(_listLicenses)

    let _selectedLicenses = selectedLicenses
    selectedLicenses.push(payload.id)
    setSelectedLicense(_selectedLicenses)
  }

  //add renew license list ui modal
  const handleRenewLicense = payload => {
    setListLicenses(payload)
    setSelectedLicense(payload.map(c => c.id))
  }

  // Add field when select option
  const handleAddFields = (v, listUserOption) => {
    //
    const values = [...inputFields]
    let value
    let isExistUser
    if (organizationID === 'new') {
      value = listUserOption.find(elm => elm.id === v.value)
      isExistUser = inputFields.find(element => element.user === v.value)
    } else {
      value = listUserOption.find(elm => elm.id === v)
      isExistUser = inputFields.find(element => element.user === v)
    }
    if (value) {
      // let isExistUser = inputFields.find(element => element.user === v.value)
      if (isExistUser) return
      values.push({
        user: value.id,
        username: value.username,
        userrole: defaultOrganizationRole?.id,
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
      })
    }
    setInputFields(values)
  }
  // Add field with payload at modal
  const handleAddFieldsFormModal = payload => {
    let values = [...inputFields]
    let itemSelected = [...selectedValues]
    let currentListUsers = [...listUsers]
    itemSelected.push(payload.id)
    currentListUsers.push({
      id: payload.id,
      email: payload.email,
      username: payload.username,
    })
    setListUsers(currentListUsers)
    setSelectedValues(itemSelected)
    values.push({
      user: payload.id,
      username: payload.username,
      email: payload.email,
      userrole: defaultOrganizationRole?.id,
      firstName: payload.firstName,
      lastName: payload.lastName,
    })
    setInputFields(values)
  }
  //
  const handleAddFieldsProject = (v, listProjectDisplay) => {
    //
    const values = [...valueProjectsPass]
    let value
    let isExistUser
    if (organizationID === 'new') {
      value = listProjectDisplay.find(elm => elm.id === v.value)
      isExistUser = valueProjectsPass.find(element => element.id === v.value)
    } else {
      value = listProjectDisplay.find(elm => elm.id === v)
      isExistUser = valueProjectsPass.find(element => element.id === v)
    }
    if (value) {
      // let isExistUser = inputFields.find(element => element.user === v.value)
      if (isExistUser) return
      values.push(value)
    }
    setValueProjectsPass(values)
  }
  const hanldeReloadFromModal = stt => {
    setVisibleModal(!stt)
    // setisLoadingFormEdit(true)
  }
  const hanldeReloadFromModalLicense = stt => {
    setVisibleModalLicense(!stt)
  }

  // delete a field user and role when click button
  const handleRemoveFields = async (inputField, index) => {
    let { organizationuserroleid } = inputField
    const values = [...inputFields]
    const itemSelected = [...selectedValues]

    if (organizationuserroleid) {
      adminStore.setLoadingProgress(true)
      organizationStore
        .deleteOrganizationUserRole(organizationuserroleid)
        .then(() => {
          itemSelected.splice(index, 1)
          values.splice(index, 1)
          setSelectedValues(itemSelected)
          setInputFields(values)
        })
        .catch(err => message.error(t('has-an-error-please-try-again')))
        .finally(() => {
          adminStore.setLoadingProgress(false)
        })
    } else {
      itemSelected.splice(index, 1)
      values.splice(index, 1)
      setSelectedValues(itemSelected)
      setInputFields(values)
      message.success(t('remove-user-successfully'))
    }
  }

  const handleRemoveFieldsProject = (inputField, index) => {
    let { id } = inputField
    const values = [...valueProjectsPass]
    if (id) {
      values.splice(index, 1)
      setValueProjectsPass(values)
      message.success(t('remove-project-successfully'))
    }
  }

  // Set value userrole at field user and role
  const handleSelectChange = (value, index) => {
    const values = [...inputFields]
    values[index].userrole = value
    // values[index].userrole = JSON.parse(value);
    setInputFields(values)
  }
  // Remote field when click at icon selected item
  const handleDeSelectUser = (value, index) => {
    const values = [...inputFields]
    const result = values.filter(item => item.user !== value)
    setInputFields(result)
  }

  const handleDefaultSelectUser = (organizationUserRoleList, users) => {
    //
    if (organizationUserRoleList && organizationUserRoleList.length > 0) {
      let itemSelected = []
      let fieldList = []
      users.map((v, i) => {
        organizationUserRoleList.map((value, index) => {
          if (v.email === value.email) {
            itemSelected.push(v.id)
            fieldList.push({
              user: v.id,
              username: v.username,
              email: v.email,
              userrole: value?.organizationrole?.id,
              organizationuserroleid: value.id,
              inviteStatus: value.inviteStatus,
              firstName: value?.firstName,
              lastName: value?.lastName,
              acceptedDate:
                value.inviteStatus === 'accepted'
                  ? value.acceptedDate
                    ? value.acceptedDate
                    : value.createdAt
                  : null,
            })
          } else if (!value.user) {
            let isExist = fieldList.find(
              elm => elm.organizationuserroleid === value.id
            )
            if (isExist) return
            fieldList.push({
              user: undefined,
              acceptedDate: null,
              username: value.email,
              email: value.email,
              userrole: value.organizationrole.id,
              organizationuserroleid: value.id,
              inviteStatus: value.inviteStatus,
            })
          }
        })
      })
      setInputFields(fieldList)
      setSelectedValues(itemSelected)
    }
  }

  const propsUpload = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    defaultFileList,
    fileList : [],
    // fileList,
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error(t('you-can-only-upload-jpg-png-file'))
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error(t('image-must-smaller-than-2MB'))
      }
      return isJpgOrPng && isLt2M
    },
    onChange(info) {
      const { status } = info.file
      if (status !== 'uploading') {
        // console.log("info.file", info.file);
        setuploadInfo(info.file)
      }
      if (status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(t('the-file-upload-failed', { name: info.file.name }))
      }
    },

    onRemove() {
      organizationStore.setCurrentLogoUpload({})
    },
    customRequest: ({ onSuccess, onError, file, onProgress }, event) => {
      const REACT_APP_ENDPOINT = apiUrl
      const formData = new FormData()
      formData.append('files', file, file.name)
      Axios({
        async: true,
        crossDomain: true,
        processData: false,
        contentType: false,
        mimeType: 'multipart/form-data',
        method: 'POST',
        url: `${REACT_APP_ENDPOINT}/upload`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
        onUploadProgress: event => {
          onProgress({ percent: (event.loaded / event.total) * 100 }, file)
        },
        data: formData,
      })
        .then(res => {
          onSuccess(res => normFile(res.data))
          // normFile(res.data);
          message.success(t('upload-image-successfully'))
          organizationStore.setCurrentLogoUpload(res.data)
          setimageUrl(res.data[0].url)
        })
        .catch(err => message.error(t('has-an-error-please-try-again')))
    },
  }

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const handleDeleteOrganization = async id => {
    setisLoadingFormEdit(true)
    adminStore
      .deleteOrganization(id)
      .then(() => {
        adminStore.setRiderect(false)
        message.success(t('delete-successfully'))
        history.push(
          `/admin/organizations/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
        )
      })
      .catch(() => {
        adminStore.setRiderect(false)
        message.error(t('delete-failed'))
      })
      .finally(() => {
        setisLoadingFormEdit(false)
        adminStore.setLoadingProgress(false)
      })
  }

  const createOrganization = info => {
    setisLoadingFormEdit(true)
    adminStore
      .createOrganization(info)
      .then(() => {
        message.success(t('create-new-organization-successfully'))
        history.push(
          `/admin/organizations/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
        )
      })
      .catch(() => {
        message.error(t('create-new-organization-failed'))
      })
      .finally(() => {
        adminStore.setRiderect(false)
        setisLoadingFormEdit(false)
        adminStore.setLoadingProgress(false)
      })
  }
  const updateOrganization = async (id, info) => {
    setisLoadingFormEdit(true)
    adminStore
      .customUpdateOrganization(id, info)
      .then(() => {
        message.success(t('organization-updated-successfully'))
        organizationStore.setRiderect(false)
        history.push(
          `/admin/organizations/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
        )
      })
      .catch(() => {
        message.error(t('an-error-occurred'))
      })
      .finally(() => {
        setisLoadingFormEdit(false)
        adminStore.setLoadingProgress(false)
      })
  }

  const handleButtonSaveOrganization = payload => {
    let _projects = []
    if (valueProjectsPass && valueProjectsPass.length > 0) {
      valueProjectsPass.map(el => {
        _projects.push(el.id)
      })
    }
    if (organizationID === 'new') {
      //  case create new organization
      let result = null
      let _isActive
      let _isAutomaticRenew
      if (Object.keys(organizationStore.logoUpload).length > 0) {
        result = organizationStore?.logoUpload[0].id
      } else {
        result = null
      }
      _isActive = payload.isActive ? payload.isActive : false
      _isAutomaticRenew = payload.isAutomaticRenew
        ? payload.isAutomaticRenew
        : false

      return createOrganization({
        ...payload,
        logo: result,
        user: usersStore.currentUser.id,
        users: inputFields,
        projects: _projects,
        isActive: _isActive,
        isAutomaticRenew: _isAutomaticRenew,
        licenses: selectedLicenses,
      })
    } else {
      // case update organization
      let currentOrganizationId = adminStore.currentOrganizationView.id
      let result = null
      if (adminStore.currentOrganizationView?.logo?.id) {
        result = adminStore.currentOrganizationView.logo.id
      }

      if (Object.keys(organizationStore.logoUpload).length > 0) {
        result = organizationStore.logoUpload[0].id
      }
      return updateOrganization(currentOrganizationId, {
        ...payload,
        logo: result,
        users: inputFields,
        licenses: selectedLicenses,
        projects: _projects,
      })
    }
  }

  const resetFormOrganization = () => {
    form.resetFields()
    history.push(
      `/admin/organizations/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
    )
  }

  const showConfirmDeleteOrganization = () => {
    Modal.confirm({
      title: t('do-you-want-to-delete-this-organizarion'),
      content: `${t('please-confirm-delete')} :  "${
        adminStore.currentOrganizationView.name
      }" `,
      icon: <ExclamationCircleOutlined />,
      okText: t('commons.delete'),
      cancelText: t('commons.cancel'),
      okButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      zIndex: 999999,
      onOk() {
        handleDeleteOrganization(adminStore.currentOrganizationView.id)
      },
      onCancel() {
        console.log('')
      },
    })
  }

  const customSpin = (
    <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
  )
  const checkTextButtonHeader = () => {
    if (isTextButtonHeader && adminStore.collapsed) {
      return true
    } else if (isTextButtonHeader && !adminStore.collapsed) {
      return true
    } else if (!isTextButtonHeader) {
      return false
    }
  }

  const removeLogo = () => {
    setuploadInfo(null)
    setDefaultFileList(null)
    if (organizationID === 'new') {
      setimageUrl(null)
      organizationStore.setCurrentLogoUpload({})
    }else{
      setimageUrl(null)
      organizationStore.setCurrentLogoUpload({})
      if(adminStore?.currentOrganizationView?.logo){
        delete adminStore.currentOrganizationView.logo
      }
    }
  }
  return (
    <AdminTemplate title={t('organization-detail')}>
      <Spin spinning={isLoadingFormEdit} indicator={customSpin}>
        <CustomContentAdminWrapper>
          <div style={{ background: '#fff', textAlign: 'center' }}>
            <Layout>
              <Form
                form={form}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={value => handleButtonSaveOrganization(value)}>
                <HeaderAdmin style={{ backgroundColor: '#f0f2f5' }}>
                  <Content>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      style={{ padding: '10px ' }}>
                      <Col>
                        <div style={{ float: 'left', fontSize: 20 }}>
                          {' '}
                          {adminStore.createMode
                            ? t('create-new-organization')
                            : t('edit-organization')}
                        </div>
                      </Col>
                      <Col>
                        <Row>
                          <Col span={24} className="control-btn-group">
                            <Button
                              style={{
                                float: 'right',
                                marginRight: 10,
                              }}
                              htmlType="submit"
                              type="primary">
                              <SaveOutlined />
                              {checkTextButtonHeader()
                                ? undefined
                                : t('commons.save')}
                            </Button>
                            <Button
                              style={{
                                float: 'right',
                                marginRight: 10,
                              }}
                              type="primary"
                              onClick={() => resetFormOrganization()}>
                              <CloseCircleOutlined />
                              {checkTextButtonHeader()
                                ? undefined
                                : t('commons.cancel')}
                            </Button>
                            {!adminStore.createMode ? (
                              <Button
                                style={{
                                  float: 'right',
                                  marginRight: 10,
                                }}
                                type="danger"
                                onClick={showConfirmDeleteOrganization}>
                                <DeleteOutlined />
                                {checkTextButtonHeader()
                                  ? undefined
                                  : t('commons.delete')}
                              </Button>
                            ) : null}
                            <Modal
                              title={t('please-confirm-delete')}
                              style={{ top: 120, textAlign: 'center' }}
                              visible={modalVisible}
                              onOk={() => setModalVisible(false)}
                              onCancel={() => setModalVisible(false)}>
                              <p>
                                <InfoCircleOutlined
                                  style={{ fontSize: '30px' }}
                                />
                              </p>
                              <p>
                                {t(
                                  'are-you-sure-you-want-to-delete-this-record'
                                )}
                              </p>
                            </Modal>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Content>
                </HeaderAdmin>
                <Content>
                  <div style={{ padding: '20px 0', background: '#fff' }}>
                    <Row type="flex">
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <div
                          className="content-left"
                          style={{
                            background: '#fff',
                            padding: isTabletOrMobileDevice ? '20px 0' : '0',
                            marginRight: isTabletOrMobileDevice ? '' : '30px',
                          }}>
                          <Row gutter={[24, 8]}>
                            <Col >
                              <div className="upload-image-organization">
                                <Form.Item
                                  label={t('logo')}
                                  name="dragger"
                                  getValueFromEvent={normFile}
                                  valuePropName="fileList">
                                  <LogoWrapper>
                                  {
                                    imageUrl && (
                                      <Popconfirm
                                      okText={t('commons.delete')}
                                      cancelText={t('commons.cancel')}
                                      onConfirm={removeLogo}
                                      okType={'danger'}
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{ color: 'red' }}
                                        />
                                      }
                                      title={t('are-you-sure')}>
                                      <Avatar
                                        className="delete-icon"
                                        key="delete"
                                        shape="square"
                                        icon={
                                          <SVGIcon
                                            color={'#F26524'}
                                            content={<ViewpointDelete />}
                                            width={18}
                                            height={18}
                                          />
                                        }
                                      />
                                    </Popconfirm>
                                    )
                                  }
                                    <Dragger
                                      {...propsUpload}
                                      style={{
                                        minHeight: '150px',
                                        width: '150px',
                                      }}>
                                      {imageUrl ? (
                                        <img
                                          src={imageUrl}
                                          alt={t('avatar')}
                                          className="input-upload-image-square"
                                        />
                                      ) : (
                                        <UploadButton />
                                      )}
                                    </Dragger>
                                  </LogoWrapper>
                                </Form.Item>
                              </div>
                            </Col>
                          </Row>
                          <Row gutter={[24, 8]}>
                            <Col span={24}>
                              <Form.Item
                                label={t('name')}
                                name="name"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'please-input-organization-name'
                                    ),
                                  },
                                ]}>
                                <Input
                                  placeholder={t('input-organization-name')}
                                  type="text"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('address')}
                                name="address"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'please-input-organization-address'
                                    ),
                                  },
                                ]}>
                                <TextArea
                                  rows={3}
                                  placeholder={t('enter-address')}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[24, 8]}>
                            <Col span={24}>
                              <Form.Item
                                label={t('city')}
                                name="city"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'please-input-organization-city'
                                    ),
                                  },
                                ]}>
                                <Input placeholder={t('enter-city')} />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('country')}
                                name="country"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'please-input-organization-country'
                                    ),
                                  },
                                ]}>
                                <Select
                                  placeholder={t('enter-country')}
                                  allowClear={true}
                                  showArrow={true}
                                  showSearch={true}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  optionFilterProp="children"
                                  align="left"
                                  notFoundContent={t('no-data')}>
                                  {optionCountries ? (
                                    optionCountries.map((v, i) => {
                                      return (
                                        <Option key={v.value} value={v.label}>
                                          {v.label}
                                        </Option>
                                      )
                                    })
                                  ) : (
                                    <Option>{t('no-country-found')}</Option>
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={[24, 8]}>
                            <Col span={24}>
                              <Form.Item
                                label={t('zip-code')}
                                name="zipCode"
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      'please-input-organization-zip-code'
                                    ),
                                  },
                                ]}>
                                <Input placeholder={t('enter-zip-code')} />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('active')}
                                name="isActive"
                                valuePropName="checked"
                                className="checkbox-status">
                                <Switch
                                  checkedChildren={t('active')}
                                  unCheckedChildren={t('non-active')}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('automatic-renew')}
                                name="isAutomaticRenew"
                                valuePropName="checked"
                                className="checkbox-status">
                                <Switch
                                  checkedChildren={t('active')}
                                  unCheckedChildren={t('non-active')}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <div>
                          <SettingWrapper style={{ paddingBottom: '10px' }}>
                            <Row
                              justify="space-between"
                              style={{ marginBottom: '10px' }}>
                              <Col>{`${t('licenses')} (${
                                listLicenses?.length || 0
                              })`}</Col>
                              <Col>
                                <Tooltip
                                  placement="top"
                                  title={t('add-new-license')}
                                  overlayStyle={
                                    isMobile || isTablet
                                      ? { display: 'none' }
                                      : undefined
                                  }>
                                  <Button
                                    onClick={() =>
                                      setVisibleModalLicense(true)
                                    }>
                                    <div>{t('add-license')}</div>
                                  </Button>
                                </Tooltip>
                              </Col>
                            </Row>
                            <Row className="license-group">
                              <Col style={{ flexGrow: 1 }}>
                                <Form.Item
                                  name="licenses"
                                  rules={[
                                    {
                                      required: false,
                                      message: t(
                                        'please-input-organization-licenses'
                                      ),
                                    },
                                  ]}>
                                  <ItemSetting>
                                    {listLicenses.length > 0 ? (
                                      <TableLicenses>
                                        <tbody>
                                          <tr>
                                            <th>{t('type')}</th>
                                            <th>{t('activation')}</th>
                                            <th>{t('expiration')}</th>
                                            <th>{t('status')}</th>
                                            <th>{t('action')}</th>
                                          </tr>
                                          {listLicenses
                                            .sort(function (a, b) {
                                              return b.isActive - a.isActive
                                            })
                                            .map((_licen, index) => (
                                              <tr key={index}>
                                                <td>
                                                  {' '}
                                                  {_licen.licensetype
                                                    ? _licen.licensetype.name
                                                    : _licen.licenceKey}
                                                </td>
                                                <td>
                                                  {moment(
                                                    _licen.activated
                                                  ).format('YYYY-MM-DD')}
                                                </td>
                                                <td>
                                                  {moment(
                                                    _licen.expiration
                                                  ).format('YYYY-MM-DD')}
                                                </td>
                                                <td>
                                                  {_licen?.isActive
                                                    ? 'Active'
                                                    : 'Non-active'}
                                                </td>
                                                <td>
                                                  <span className="action-tool-license">
                                                    <Tooltip
                                                      placement="top"
                                                      title={t('edit-license')}
                                                      overlayStyle={
                                                        isMobile || isTablet
                                                          ? { display: 'none' }
                                                          : undefined
                                                      }>
                                                      <Button
                                                        className="btn btn-link"
                                                        type="button"
                                                        style={{
                                                          float: 'right',
                                                        }}
                                                        onClick={() =>
                                                          handleEditLicense(
                                                            _licen,
                                                            index
                                                          )
                                                        }>
                                                        <EditOutlined />
                                                      </Button>
                                                    </Tooltip>
                                                    <Tooltip
                                                      placement="top"
                                                      title={t(
                                                        'remove-license'
                                                      )}
                                                      overlayStyle={
                                                        isMobile || isTablet
                                                          ? { display: 'none' }
                                                          : undefined
                                                      }>
                                                      <Popconfirm
                                                        placement="topRight"
                                                        title={t(
                                                          'are-you-sure-to-delete-this-license'
                                                        )}
                                                        onConfirm={() =>
                                                          handleRemoveLicense(
                                                            _licen,
                                                            index
                                                          )
                                                        }
                                                        okText={t(
                                                          'commons.yes'
                                                        )}
                                                        cancelText={t(
                                                          'commons.no'
                                                        )}>
                                                        <Button
                                                          className="btn btn-link"
                                                          type="button"
                                                          style={{
                                                            float: 'right',
                                                          }}>
                                                          <DeleteOutlined />
                                                        </Button>
                                                      </Popconfirm>
                                                    </Tooltip>
                                                  </span>
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </TableLicenses>
                                    ) : (
                                      <Empty
                                        description={
                                          <span>{t('no-data')}</span>
                                        }
                                      />
                                    )}
                                  </ItemSetting>
                                </Form.Item>
                                <Modal
                                  className="modal-add-license"
                                  title={`${
                                    adminStore.licenseOrgEdit
                                      ? adminStore.licenseOrgEdit?.licensetype
                                        ? adminStore.licenseOrgEdit?.licensetype
                                            ?.name
                                        : adminStore.licenseOrgEdit?.licenceKey
                                      : ''
                                  }`}
                                  zIndex={9999}
                                  visible={adminStore.licenseOrgEdit}
                                  onCancel={() =>
                                    adminStore.setLicenseOrgEdit()
                                  }
                                  maskClosable={false}
                                  footer={false}
                                  forceRender={true}>
                                  <EditLicenseModal
                                    handleUpdateLicense={(item, index) =>
                                      handleUpdateLicense(item, index)
                                    }
                                    handleCloseModal={() =>
                                      adminStore.setLicenseOrgEdit()
                                    }></EditLicenseModal>
                                </Modal>
                              </Col>

                              <Modal
                                className="modal-add-license"
                                title={t('add-new-license')}
                                zIndex={9999}
                                style={{ top: 50 }}
                                visible={visibleModalLicense}
                                onOk={() => setVisibleModalLicense(false)}
                                onCancel={() => setVisibleModalLicense(false)}
                                width={600}
                                maskClosable={false}
                                footer={false}>
                                <AddLicense
                                  handleCloseModal={stt =>
                                    setVisibleModalLicense(stt)
                                  }
                                  handleSetFieldvalue={(
                                    selectedLicense,
                                    listLicense
                                  ) =>
                                    handleSetFieldvalue(
                                      selectedLicense,
                                      listLicense
                                    )
                                  }
                                  handleRenewLicense={data =>
                                    handleRenewLicense(data)
                                  }
                                />
                              </Modal>
                            </Row>
                            <Row
                              style={{
                                paddingBottom: '10px',
                                paddingTop: '10px',
                              }}>{`${t('users')} (${inputFields?.length || 0})`}</Row>
                            <Row className="select-user-wrap">
                              <Col className="user-select">
                                <CustomUserField
                                  data={listUsers}
                                  valuePass={listUsers}
                                  onSelect={(v, listUserOption) =>
                                    handleAddFields(v, listUserOption)
                                  }
                                  // onDeselect={v => handleDeSelectUser(v)}
                                ></CustomUserField>
                              </Col>
                              <Col className="user-btn_add">
                                <Tooltip
                                  placement="top"
                                  title={t('add-new-user')}
                                  overlayStyle={
                                    isMobile || isTablet
                                      ? { display: 'none' }
                                      : undefined
                                  }>
                                  <Button onClick={() => setVisibleModal(true)}>
                                    <PlusOutlined />
                                  </Button>
                                </Tooltip>
                              </Col>
                              <Modal
                                className="modal-add-user"
                                title={t('add-new-user')}
                                zIndex={9999}
                                style={{ top: 20 }}
                                visible={visibleModal}
                                onOk={() => setVisibleModal(false)}
                                onCancel={() => setVisibleModal(false)}
                                width={600}
                                maskClosable={false}>
                                <AddUser
                                  handleAddFieldsFormModal={v =>
                                    handleAddFieldsFormModal(v)
                                  }
                                  hanldeReloadFromModal={status =>
                                    hanldeReloadFromModal(status)
                                  }
                                  handleCloseModal={stt => setVisibleModal(stt)}
                                />
                              </Modal>
                            </Row>
                            <Row
                              className="organization-user-list"
                              gutter={[4, 4]}>
                              <ItemSetting>
                                {inputFields.map(
                                  (inputField, index) =>
                                    inputField && (
                                      <Row
                                        justify="space-between"
                                        style={
                                          isTextButtonHeader
                                            ? { display: 'inline-block' }
                                            : {}
                                        }
                                        className="organization-user-item list-item-organization"
                                        key={`${inputField}~${index}`}>
                                        <Col
                                          span={isTextButtonHeader ? 24 : 16}
                                          className="form-group col-sm-6">
                                          <Tooltip
                                            title={
                                              inputField.user
                                                ? (inputField?.firstName &&
                                                  inputField?.lastName
                                                    ? inputField.firstName +
                                                      ' ' +
                                                      inputField.lastName
                                                    : !inputField?.firstName &&
                                                      inputField?.lastName
                                                    ? inputField.lastName
                                                    : inputField?.firstName &&
                                                      !inputField?.lastName
                                                    ? inputField.firstName
                                                    : inputField.username) +
                                                  ' (' +
                                                  inputField.email +
                                                  ')'
                                                : inputField.email
                                            }
                                            placement="left"
                                            overlayStyle={
                                              isMobile || isTablet
                                                ? { display: 'none' }
                                                : undefined
                                            }>
                                            <div style={{ display: 'flex' }}>
                                              <Col
                                                span={8}
                                                style={{ display: 'flex' }}>
                                                <UserOutlined className="site-form-item-icon" />
                                                <NameUser className="trim-word-accorddingly">
                                                  {inputField.username
                                                    ? inputField.username
                                                    : inputField.email}
                                                </NameUser>
                                              </Col>
                                              <Col span={16}>
                                                <EmailUser className="trim-word-accorddingly">
                                                  {inputField.email
                                                    ? inputField.email
                                                    : t('new-user')}
                                                </EmailUser>
                                              </Col>
                                            </div>
                                          </Tooltip>
                                        </Col>
                                        <Col
                                          span={isTextButtonHeader ? 24 : 8}
                                          className="form-group col-sm-4">
                                          <StatusUser>
                                            <Select
                                              defaultValue={inputField.userrole}
                                              onChange={value =>
                                                handleSelectChange(value, index)
                                              }
                                              //  onChange={value => handleInputChange(index, value)}
                                              style={{ width: '100%' }}>
                                              {organizationStore.organizationRoles.map(
                                                (item, index) => {
                                                  return (
                                                    <Option
                                                      key={index}
                                                      value={item.id}>
                                                      {' '}
                                                      {item.name}
                                                    </Option>
                                                  )
                                                }
                                              )}
                                            </Select>

                                            <Tooltip
                                              placement="top"
                                              title={t('remove-users')}
                                              overlayStyle={
                                                isMobile || isTablet
                                                  ? { display: 'none' }
                                                  : undefined
                                              }>
                                              <Popconfirm
                                                placement="topRight"
                                                title={t(
                                                  'are-you-sure-to-delete-this-user'
                                                )}
                                                onConfirm={() =>
                                                  handleRemoveFields(
                                                    inputField,
                                                    index
                                                  )
                                                }
                                                okText={t('commons.yes')}
                                                cancelText={t('commons.no')}>
                                                <Button
                                                  className="btn btn-link"
                                                  type="button">
                                                  <MinusCircleOutlined />
                                                </Button>
                                              </Popconfirm>
                                            </Tooltip>
                                          </StatusUser>
                                        </Col>
                                      </Row>
                                    )
                                )}
                              </ItemSetting>
                            </Row>

                            <Row
                              style={{
                                paddingBottom: '10px',
                                paddingTop: '10px',
                              }}>{`${t('projects')} (${
                              (valueProjectsPass?.length > 0
                                ? valueProjectsPass.filter(c => !c.isDeleted)
                                : []
                              ).length || 0
                            })`}</Row>
                            <Row className="select-user-wrap">
                              <Col className="user-select">
                                <CustomProjectField
                                  data={listProjects}
                                  valuePass={listProjects}
                                  onSelect={(v, listProjectDisplay) =>
                                    handleAddFieldsProject(
                                      v,
                                      listProjectDisplay
                                    )
                                  }></CustomProjectField>
                              </Col>
                            </Row>
                            <Row
                              className="organization-user-list"
                              gutter={[4, 4]}>
                              <ItemSetting>
                                {valueProjectsPass.map(
                                  (inputField, index) =>
                                    inputField &&
                                    !inputField?.isDeleted && (
                                      <div
                                        className="organization-user-item "
                                        key={`${inputField}~${index}`}>
                                        <div className="list-item-organization">
                                          <Col>
                                            <Row>
                                              <UserOutlined className="site-form-item-icon" />
                                              <Tooltip
                                                title={inputField?.name}
                                                placement="left"
                                                overlayStyle={
                                                  isMobile || isTablet
                                                    ? { display: 'none' }
                                                    : undefined
                                                }>
                                                <div> {inputField?.name}</div>
                                              </Tooltip>
                                            </Row>
                                          </Col>
                                          <Col>
                                            <Tooltip
                                              placement="top"
                                              title={t('remove-project')}
                                              overlayStyle={
                                                isMobile || isTablet
                                                  ? { display: 'none' }
                                                  : undefined
                                              }>
                                              <Popconfirm
                                                placement="topRight"
                                                title={t(
                                                  'are-you-sure-to-delete-this-project'
                                                )}
                                                onConfirm={() =>
                                                  handleRemoveFieldsProject(
                                                    inputField,
                                                    index
                                                  )
                                                }
                                                okText={t('commons.yes')}
                                                cancelText={t('commons.no')}>
                                                <Button
                                                  className="btn btn-link"
                                                  type="button">
                                                  <MinusCircleOutlined />
                                                </Button>
                                              </Popconfirm>
                                            </Tooltip>
                                          </Col>
                                        </div>
                                      </div>
                                    )
                                )}
                              </ItemSetting>
                            </Row>
                          </SettingWrapper>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Content>
              </Form>
            </Layout>
          </div>
        </CustomContentAdminWrapper>
      </Spin>
    </AdminTemplate>
  )
}

export default inject(
  'usersStore',
  'organizationStore',
  'projectStore',
  'commonStore',
  'adminStore'
)(observer(EditOrganization))
