import { message } from 'antd';
import { action, decorate, observable } from 'mobx';
import { LicenseRequest, OrganizationRequest, ProjectRequest, SketchElementRequest } from '@/requests';
import { t } from 'i18next';
import moment from 'moment';

class OrganizationStore {
    isLoading = false
    editMode = false
    projectList = []
    organizationActiveTab = '1'
    paramsOrganizationDetail = ""
    showOrganization = false
    modalCreateProject = false
    modalEditProject = false
    statusProject = ''
    forceReload = 0
    currentOrganizationView = {}
    orgBelongUserList = []
    projectData = {}
    showInviteUserToOrganizationModal = false
    showAddLicenseToOrganizationModal = false
    organizationRoles = []

    organizations = []
    currentOrganizations = {}
    isRiderect = false
    isLoadingOrganization = false
    organizationViewEditing = {}
    organizationUserRoleViewEditing = {}
    createMode = false
    organizationUserRoles = []
    licenses = []
    licenseSelected = []
    loadingTable = false
    isReload = false
    logoUpload = {}
    invitationDetail = {}
    orgUserBelongList = []
    organizationTemplates = []
    selectedNode = null
    settings = false
    currentSketchElement = {}
    folderEdit = null
    elementEdit = null
    showAddFolderModal = false
    showAddElement = false
    showAddResourceModel = false
    register = false
    orgLicenses = []

    setRegister = stt => {
        this.register = stt
    }

    setInvitationDetail = payload => {
        this.invitationDetail = payload
    }

    clearInvitationDetail = () => {
        this.invitationDetail = {}
    }

    setIsReload = (stt) => {
        this.isReload = stt;
    }
    setCurrentLogoUpload = (payload) => {
        this.logoUpload = payload;
    }
    setOrganizationUserRoleViewEditing = (payload) => {
        this.organizationUserRoleViewEditing = payload;
    }
    setLoadingTable = (status) => {
        this.loadingTable = status;
    }
    getLicenseById = (id) => {
        LicenseRequest.getLicenseById(id)
            .then(res => {
                this.licenseSelected = res.data
            })
    }

    resetStatus = () => {
        this.setLoadingProgress(false)
        this.setCreateMode(false)
        this.setEditMode(false)
        this.setRiderect(false)
    }


    getLicense = () => {
        LicenseRequest.getLicense()
            .then(res => {
                this.licenses = res.data.map(item => {
                    if (item.organization && item.organization.id) {
                        return {
                            ...item,
                            organization: item.organization.id,
                        }
                    } else {
                        return item;
                    }
                })
            })
            .catch(err => console.log("Lisence", err))
    }

    createLicense = body => {
        LicenseRequest.createLicense(body)
    }

    getOrganizationUserRole = () => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationUserRole()
                .then(res => {
                    this.organizationUserRoles = res.data.map(item => {
                        return {
                            ...item,
                            organization: item.organization && item.organization.id !== null ? item.organization.id : item.organization,
                            user: item.user && item.user.id !== null ? item.user.id : item.user,
                            organizationrole: item.organizationrole && item.organizationrole.id !== null ? item.organizationrole.id : item.organizationrole,
                        }
                    })
                })
                .then(res => {
                    resolve();
                })
                .catch(err => {
                    reject(err)
                })
        })

    }

    createOrganizationUserRole = (payload) => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.createOrganizationUserRole({
                ...payload,
                user: payload.user ? payload.user : null,
                organization: payload.organization ? payload.organization : null,
                organizationrole: payload.organizationrole ? payload.organizationrole : null
            }).then(res => {
                resolve();
            }).catch(err => {
                reject(err);
            })
        })


    }

    deleteOrganizationUserRole = (id) => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.deleteOrganizationUserRole(id)
                .then(response => {
                    message.success(t('delete-organizationuserrole-successfully'))
                    this.setLoadingTable(true);
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                    message.error(t('an-error-occurred'));
                })

        })

    }
    updateOrganizationUserRole = (id, body) => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.updateOrganizationUserRole(id, body).then((res) => {
                resolve();
            }).catch(err => {
                reject(err)
            })
        })
    }



    setCurrentOrganizations = (payload) => {
        this.currentOrganizations = payload;
    }
    setLoadingOrganization = (status) => {
        this.isLoadingOrganization = status
    }

    setRiderect = (status) => {
        this.isRiderect = status
    }

    setStatusProject = (status) => {
        this.statusProject = status
    }

    setForceReload = (number) => {
        this.forceReload = number + this.forceReload
    }

    async getOrganization() {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganization().then(res => {
                resolve(res.data);
                this.organizations = [...res.data]
            })
                .catch(err => {
                    reject(err)
                    console.log(err)
                })
        })


    }

    async sendWarningQuotaLicense(body) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.sendWarningQuotaLicense(body).then(res => {
                resolve(res.data);
            })
                .catch(err => {
                    reject(err)
                    console.log(err)
                })
        })
    }


    setCreateMode(state) {
        this.createMode = state
    }

    async createOrganization(info) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.createOrganization({
                name: info.name,
                phone: info.phone,
                address: info.address,
                email: info.email,
                website: info.website,
                isActive: info.isActive,
                user: info.user,
                users: info.users,
                projects: info.projects,
                licenses: info.licenses,
                logo: info.logo
            }).then(res => {
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    async createNormalOrganization(info) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.create(info).then(res => {
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    async customUpdateOrganization(id, info) {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            OrganizationRequest.customUpdateOrganization(id, info)
                .then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                })
        })


    }

    deleteOrganization = (id) => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.deleteOrganization(id)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }


    deleteOrganization = (id) => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.deleteOrganization(id)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    getOrganizationBelongUser = () => {
        return OrganizationRequest.getOrganizationBelongUser().then((response) => {
            this.orgBelongUserList = [...response.data];
        })
    }

    clearOrganizationBelongUser = () => {
        this.orgBelongUserList = []
    }

    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading;
    }

    setEditMode(state) {
        this.editMode = state
    }

    setModalCreateProject(state) {
        this.modalCreateProject = state
    }

    setModalEditProject(state) {
        this.modalEditProject = state
    }

    setActiveTab(key) {
        this.organizationActiveTab = key
    }

    setParamsOrganizationDetail(paramId) {
        this.paramsOrganizationDetail = paramId
    }

    setShowOrganization(isShow) {
        this.showOrganization = isShow
    }


    getCurrentOrganizationDetail(id) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationById(id)
                .then(response => {
                    this.currentOrganizationView = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }
    getCurrentOrganizationById(id, isForce) {
        return new Promise((resolve, reject) => {
            if (!isForce && this.currentOrganizations.id === id) {
                resolve(this.currentOrganizations)
            }
            OrganizationRequest.getOrganizationById(id)
                .then(response => {
                    this.currentOrganizations = response.data
                    resolve(this.currentOrganizations)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    setCurrentOrganizationView = (data) => {
        this.currentOrganizationView = data
    }

    async updateOrganizationInfo(id, info) {
        this.setLoadingProgress(true)
        OrganizationRequest.updateOrganization(id, info)
            .then((response) => {
                message.success(t('organization-updated-successfully'))
                this.setEditMode(false)
                this.currentOrganizationView = {
                    ...this.currentOrganizationView,
                    email: response.data.email,
                    name: response.data.name,
                    phone: response.data.phone,
                    website: response.data.website,
                    address: response.data.address,
                }
            })
            .catch(() => message.error(t('an-error-occurred')))
            .finally(() => this.setLoadingProgress(false))
    }
    async updateOrganizationQuota(organization, storageQuotaUsage, percentQuotaUsage,quotaUsageLeft) {
        const preStorageQuotaUsage = organization.percentQuotaUsage;
        // let preThreshold = organization?.emailwarning?.preThreshold ? Number(organization?.emailwarning?.preThreshold) : undefined;
        let preThreshold = (organization?.emailwarning?.preThreshold || organization?.emailwarning?.preThreshold ===0) ? Number(organization?.emailwarning?.preThreshold) : undefined;

        let lastTimeSendEmail = organization?.emailwarning?.lastTimeSendEmail;
        const checkingSameDay = (dateString) => {
            const today = moment();
            const dateCompare = dateString ? moment(dateString) : moment();
            const isSame = today.isSame(dateCompare, 'day')
            return isSame
        }
        return new Promise((resolve, reject) => {
            OrganizationRequest.updateOrganization(organization.id, {
                storageQuotaUsage: storageQuotaUsage,
                percentQuotaUsage: percentQuotaUsage
            }).then((response) => {
                this.currentOrganizations.storageQuotaUsage = storageQuotaUsage
                this.currentOrganizations.percentQuotaUsage = percentQuotaUsage
                // const thresholds = [70, 80, 90, 100, 110, 120]
                const newThresholds = [0.5, 0.25, 0.1, 0];
                for (let i = 0; i < newThresholds.length; i++) {
                    const threshold = newThresholds[i];
                    const nextThreshold = newThresholds[i + 1];
                    if (quotaUsageLeft <= threshold && (quotaUsageLeft > nextThreshold || (!nextThreshold && nextThreshold !==0)) && preThreshold !==0) {
                        if(((preThreshold > threshold) && (moment() > moment(lastTimeSendEmail) && !checkingSameDay(lastTimeSendEmail))) || !preThreshold){
                            resolve({
                                organization: organization,
                                warning: true,
                                threshold: threshold
                            })
                        }
                    }
                }
                resolve({
                    organization: organization,
                    warning: false,
                })
            }).catch((e) => console.log(e) && resolve({
                organization: organization,
                warning: false,
            }))
        })
    }
    async createProject(projectData) {
        let param = {
            tilesetData: {},
            metadata: {},
        }
        param = Object.assign(param, projectData)
        return new Promise((resolve, reject) => {
            ProjectRequest.createProject(param)
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })

    }

    async updateProject(projectId, bodyData) {
        return new Promise((resolve, reject) => {
            ProjectRequest.updateProject(projectId, bodyData).then((response) => {
                resolve(response);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    async getProjectById(projectId) {
        return new Promise((resolve, reject) => {
            ProjectRequest.getDetail(projectId).then((response) => {
                resolve(response);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    setProjectData = (data) => {
        this.projectData = data
    }

    clearProjectData = () => {
        this.projectData = {};
    }

    removeUserFromProject(projectuserrolesId) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.deleteUserFromProject(projectuserrolesId)
                .then((response) => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    deleteProject = projectId => {
        return new Promise((resolve, reject) => {
            ProjectRequest.deleteProject(projectId)
                .then((res) => {
                    resolve(res)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    toggleShowInviteUserToOrganizationModal = state => {
        this.showInviteUserToOrganizationModal = state
    }

    toggleShowAddLicenseToOrganizationModal = state => {
        this.showAddLicenseToOrganizationModal = state
    }

    getOrganizationRoles = () => {
        return OrganizationRequest.getOrganizationRoles()
            .then(response => {
                this.organizationRoles = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }

    clearOrganizationRoles = () => {
        this.organizationRoles = [];
    }

    sendInvite = (email, roleId, organizationId) => {
        let submitValues = {
            email: email.toLowerCase(),
            organizationrole: roleId,
            organization: organizationId,
            inviteStatus: 'waiting_accept',
        }
        return new Promise((resolve, reject) => {
            OrganizationRequest.sendUserInviteOrganization(submitValues)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    removeUserFromOrganization(organizationuserrolesId) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.deleteUserFromOrganization(organizationuserrolesId)
                .then((response) => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    acceptInviteToOrganization = (organizationuserrolesId, submitValues) => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.acceptInviteToOrganization(organizationuserrolesId, submitValues)
                .then((response) => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    checkInvitationOrganization = (id) => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.checkInvitationOrganization(id)
                .then((response) => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    async getOrganizationUserBelong() {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationUserBelong()
                .then(response => {
                    let v = response.data.filter(elm => elm.organization && elm.organization.isActive && elm.organizationrole && elm.inviteStatus === "accepted")
                    let res = []
                    if (v.length > 0) {
                        res = v.map(org => org.organization)
                    }
                    this.orgUserBelongList = res
                    resolve(res)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    async getOrganizationUserBelongCreateNewProject() {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationUserBelongCreateNewProject()
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    clearOrgUserBelongList = () => {
        this.orgUserBelongList = []
    }

    getOrganizationTemplates = payload => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationTemplates(payload)
                .then((res) => {
                    this.organizationTemplates = res.data.filter(c => !c.isDeleted)
                    resolve(res.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    getAllUserInOraganization(id) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getAllUserInOraganization(id)
                .then(response => {
                    resolve(response.data)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    clearOrganizationTemplate = () => {
        this.organizationTemplates = []
    }

    setOrganizationTemplate = (data) => {
        this.organizationTemplates = data
    }

    setCurrentSketchElement = (data) => {
        this.currentSketchElement = data
    }


    setSelectedNode = (node) => {
        this.selectedNode = node
    }

    setSettings = (stt) => {
        this.settings = stt
    }

    setElementEdit = (node) => {
        this.elementEdit = node
    }

    setFolderEdit = (node) => {
        this.folderEdit = node
    }

    setShowAddElement = (stt) => {
        this.showAddElement = stt
    }

    setShowAddFolderModal = (stt) => {
        this.showAddFolderModal = stt
    }

    setShowAddResourceModel = (stt) => {
        this.showAddResourceModel = stt
    }

    async getSketchElements(type, prj, org) {
        return new Promise((resolve, reject) => {
            SketchElementRequest.get(type, prj, org).then((response) => {
                resolve(response);
            }).catch(err => {
                reject(err)
            })
        })
    }

    async createSketchElements(body) {
        return new Promise((resolve, reject) => {
            SketchElementRequest.create(body).then((response) => {
                this.currentSketchElement = response.data
                resolve(response);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    async updateSketchElement(id, body) {
        return new Promise((resolve, reject) => {
            SketchElementRequest.update(id, body).then((response) => {
                this.currentSketchElement = response.data
                resolve(response);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    async getOrgLicenses(id) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrgLicenses(id).then((response) => {
                this.orgLicenses = response.data
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }


}

decorate(OrganizationStore, {
    isLoading: observable,
    setLoadingProgress: action,
    editMode: observable,
    setEditMode: action,
    projectList: observable,
    setActiveTab: action,
    organizationActiveTab: observable,
    currentOrganizationView: observable,
    getCurrentOrganizationDetail: action,
    setCurrentOrganizationView: action,
    orgBelongUserList: observable,
    getOrganizationBelongUser: action,
    clearOrganizationBelongUser: action,
    updateOrganizationInfo: action,
    updateOrganizationQuota: action,
    createProject: action,
    updateProject: action,
    getProjectById: action,
    projectData: observable,
    setProjectData: action,
    clearProjectData: action,
    removeUserFromProject: action,
    deleteProject: action,
    showInviteUserToOrganizationModal: observable,
    showAddLicenseToOrganizationModal: observable,
    toggleShowInviteUserToOrganizationModal: action,
    toggleShowAddLicenseToOrganizationModal: action,
    getOrganizationRoles: action,
    organizationRoles: observable,
    clearOrganizationRoles: action,
    sendInvite: action,
    removeUserFromOrganization: action,
    acceptInviteToOrganization: action,
    getOrganization: action,
    organizations: observable,
    setCurrentOrganizations: action,
    currentOrganizations: observable,
    customUpdateOrganization: action,
    isRiderect: observable,
    setRiderect: action,
    isLoadingOrganization: observable,
    setLoadingOrganization: action,
    createMode: observable,
    setCreateMode: action,
    getOrganizationUserRole: action,
    organizationUserRoles: observable,
    licenses: observable,
    getLicense: action,
    getLicenseById: action,
    licenseSelected: observable,
    setLoadingTable: action,
    loadingTable: observable,
    setOrganizationUserRoleViewEditing: action,
    organizationUserRoleViewEditing: observable,
    deleteOrganizationUserRole: action,
    setIsReload: action,
    isReload: observable,
    createOrganizationUserRole: action,
    createNormalOrganization: action,
    updateOrganizationUserRole: action,
    logoUpload: observable,
    setCurrentLogoUpload: action,
    getCurrentOrganizationById: action,
    resetStatus: action,

    paramsOrganizationDetail: observable,
    setParamsOrganizationDetail: action,

    showOrganization: observable,
    setShowOrganization: action,

    modalCreateProject: observable,
    setModalCreateProject: action,

    modalEditProject: observable,
    setModalEditProject: action,

    statusProject: observable,
    setStatusProject: action,

    forceReload: observable,
    setForceReload: action,

    checkInvitationOrganization: action,
    invitationDetail: observable,
    setInvitationDetail: action,
    clearInvitationDetail: action,
    getOrganizationUserBelong: action,
    orgUserBelongList: observable,
    clearOrgUserBelongList: action,
    sendWarningQuotaLicense: action,
    getOrganizationTemplates: action,
    organizationTemplates: observable,
    clearOrganizationTemplate: action,
    setOrganizationTemplate: action,

    setSettings: action,
    settings: observable,
    setSelectedNode: action,
    selectedNode: observable,
    currentSketchElement: observable,
    setCurrentSketchElement: action,
    getSketchElements: action,
    createSketchElements: action,
    updateSketchElement: action,
    setElementEdit: action,
    elementEdit: observable,
    setFolderEdit: action,
    folderEdit: observable,
    setShowAddElement: action,
    showAddElement: observable,
    setShowAddFolderModal: action,
    showAddFolderModal: observable,
    setShowAddResourceModel: action,
    showAddResourceModel: observable,
    setRegister: action,
    register: observable,
    getOrgLicenses: action,
    orgLicenses: observable,
    getOrganizationUserBelongCreateNewProject: action,
    getAllUserInOraganization : action

})

export default new OrganizationStore();