import { Button, Form, Input, message, Modal, Select, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import axios from 'axios'
import { CameraEventType } from 'cesium'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { withRouter } from 'react-router-dom'
import { apiUrl } from '@/config'
import { is6DPLANNER } from '@/lib/projectLib'
import validator from '@/validator'
import { LoadingSpinner, UploadButton } from '@/components/elements'
import { ButtonGroup, NavigationStyled } from './UserSettingsPageStyled'
const { Option } = Select;
const { confirm } = Modal;

const UserSettingsForm = props => {
  const { usersStore, commonStore, currentUser, userAvatar, history, projectStore, setShowModalChangePassword, paddingForm } = props
  const [uploadFile, setUploadFile] = useState(undefined)
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  moment.locale(`${commonStore.language}`)

  const CustomForm = props => {
    const [form] = Form.useForm();
    const [showCustom, setShowCustom] = useState(false);
    const [allowUnderground, setAllowUnderground] = useState(projectStore.navigationStyles?.allowUnderground ?? true);

    const isDesktopOrLaptop = useMediaQuery({
      query: '(min-device-width: 1224px)'
    })
    const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

    const showDeleteConfirm = () => {
      confirm({
        title: t('delete-my-account'),
        content: t('are-you-sure-delete-account'),
        onOk() {
          usersStore.confirmDeleteMyAccount({ email: usersStore.currentUser.email, username: usersStore.currentUser.username })
        },
        onCancel() {
          console.log('');
        },
        okText: t('commons.ok'),
        cancelText: t('commons.cancel'),
        zIndex: "9999"
      });
    }

    const handleSubmit = values => {
      let newDataMe = {
        username: values.username,
        email: values.email,
        language: values.language,
        firstName: values.firstName,
        lastName: values.lastName
      }
      if (paddingForm) {
        setIsLoading(true)
      }
      usersStore.setLoadingProgress(true)
      usersStore.updateUserMe(newDataMe).then(async (res) => {
        commonStore.setLanguage(res.language ? res.language : 'en')
        moment.locale(`${commonStore.language}`)
        if (paddingForm && !uploadFile) {
          await usersStore.setCurrentUser()
          setIsLoading(false)
        }
        // upload avatar
        if (uploadFile) {
          const formData = new FormData()
          formData.append('files', uploadFile)
          formData.append('ref', 'user')
          formData.append('source', 'users-permissions')
          formData.append('refId', res.id)
          formData.append('field', 'avatar')
          axios({
            method: 'POST',
            url: `${apiUrl}/upload`,
            headers: {
              Authorization: `Bearer ${commonStore.token}`,
            },
            data: formData,
          }).then(async () => {
            if (paddingForm) {
              await usersStore.setCurrentUser()
              setIsLoading(false)
            }
          }).catch(error => {
            console.log(error)
            message.error(t('upload-file-error'))
          })
        }
        if (projectStore.projectDetail?.id) {
          let _control = {}
          if (values.navigationstyle == 'custom') {
            _control = {
              pan: values.pan,
              zoom: values.zoom,
              tilt: values.tilt,
              rotate: values.rotate
            }
          }
          const navigationInstructions = projectStore?.projectDetail?.metadata?.navigationInstructions || []
           const resultNavigationInstructions = projectStore.navigationStyles ? projectStore.navigationStyles :  navigationInstructions[0];
           values.distanceLimit = resultNavigationInstructions?.distanceLimit || -1
           values.allowUnderground = resultNavigationInstructions?.allowUnderground ?? true
          let _navstyle = {
            userId: usersStore.currentUser.id,
            type: values.navigationstyle,
            control: _control,
            distanceLimit: values.distanceLimit,
            allowUnderground: values.allowUnderground
          }

          let _projectMetadata = projectStore.projectDetail.metadata
          if (values.navigationstyle) {
            if (_projectMetadata.navigationInstructions && _projectMetadata.navigationInstructions.length > 0) {
              if (_projectMetadata.navigationInstructions.length > 1) {
                _projectMetadata.navigationInstructions[0].type = values.navigationstyle
                _projectMetadata.navigationInstructions[0].control = _control
                _projectMetadata.navigationInstructions[0].distanceLimit = values.distanceLimit
                _projectMetadata.navigationInstructions[0].allowUnderground = resultNavigationInstructions?.distanceLimit || -1
              }
              const objIndex = _projectMetadata.navigationInstructions.findIndex(obj => obj.userId === usersStore.currentUser.id);
              if (objIndex > -1) {
                _projectMetadata.navigationInstructions[objIndex].type = values.navigationstyle
                _projectMetadata.navigationInstructions[objIndex].control = _control
                _projectMetadata.navigationInstructions[objIndex].distanceLimit = values.distanceLimit
                _projectMetadata.navigationInstructions[objIndex].allowUnderground = resultNavigationInstructions?.distanceLimit || -1
              } else {
                _projectMetadata.navigationInstructions.push(_navstyle)
              }
            } else {
              _projectMetadata.navigationInstructions = [_navstyle]
            }
          } else { //case null remove 
            _projectMetadata.navigationInstructions = _projectMetadata?.navigationInstructions?.filter(x => x.userId !== usersStore.currentUser.id)
          }

          let newProjectMetadataNavigation = {
            metadata: _projectMetadata
          }

          if (projectStore.projectDetail.projectuserroles && projectStore.projectDetail.projectuserroles.find(pur => pur.email === usersStore.currentUser.email && pur.inviteStatus === 'accepted')) {
            // update project metadata navigation        
            projectStore.updateProjectData(newProjectMetadataNavigation).then((res) => {
              projectStore.projectDetail.metadata = res.metadata
              // set effect in project detail page          
              // let prjNavigationInstructions = res.metadata.navigationInstructions.find(x => x.userId === usersStore.currentUser.id)
              let prjNavigationInstructions = res.metadata.navigationInstructions
              const resultNavigationInstructions = prjNavigationInstructions[0];
              if (resultNavigationInstructions) {
                projectStore.setNavigationStyles(resultNavigationInstructions)
              } else { // if not exist set default
                projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true })
              }
            }).catch(err => {
              console.log(err)
            })
          } else {
            projectStore.setNavigationStyles({ userId: usersStore.currentUser.id, type: values.navigationstyle, control: _control, distanceLimit: values.distanceLimit, allowUnderground : values.allowUnderground })
          }
        }

        usersStore.setLoadingProgress(false)
        hiddenDrawer()
        message.success(t('updated-successfully'))
        usersStore.setCurrentUser(history)
      }).catch(error => {
        usersStore.setLoadingProgress(false)
        if (error.data && error.data.message && Array.isArray(error.data.message)) {
          message.error(t(error.data.message[0].messages[0].id))
        } else if (error.data && error.data.message) {
          message.error(t(error.data.message))
        } else {
          message.error(t('username-email-taken'))
        }
      })
    }

    function handleChangeNavigation(value) {
      setShowCustom(value === "custom");
    }

    const handleUploadCover = file => {
      setUploadFile(file)
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        usersStore.updateUserAvatar(reader.result)
      });
      reader.readAsDataURL(file);
      return false
    }

    const beforeCrop = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error(t('you-can-only-upload-jpg-png-file'));
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(t('image-must-smaller-than-2MB'));
        return false
      }

      return isJpgOrPng && isLt2M;
    }

    const hiddenDrawer = () => {
      commonStore.setShowUserSettingPageDrawer(false)
      if(projectStore.projectDetail?.id)
        setAllowUnderground(projectStore.navigationStyles?.allowUnderground ?? true);
    };

    useEffect(() => {
      if (commonStore.showUserSettingPageDrawer) {
        const navigationInstructions = projectStore.projectDetail?.metadata?.navigationInstructions || []
        const resultNavigationInstructions = projectStore.navigationStyles ? projectStore.navigationStyles :  navigationInstructions[0];
        if (resultNavigationInstructions) {
          form.setFieldsValue({ navigationstyle: resultNavigationInstructions.type || 'xdTwin', distanceLimit: resultNavigationInstructions.distanceLimit || -1, allowUnderground: resultNavigationInstructions.allowUnderground ?? true })
          if (resultNavigationInstructions.type === 'custom') {
            setShowCustom(true)
          }
        } else {
          form.setFieldsValue({ navigationstyle: 'xdTwin', distanceLimit: -1, allowUnderground: true, })
        }

        if (!userAvatar && currentUser && currentUser.avatar) {
          usersStore.setUserAvatar(currentUser.avatar)
        }
        if(projectStore.projectDetail?.id)
          setAllowUnderground(projectStore.navigationStyles?.allowUnderground ?? true);
      }
    }, [commonStore.showUserSettingPageDrawer])

    return (
      <>
        <Form layout="vertical" onFinish={handleSubmit} form={form} style={paddingForm ? isMobile ? { padding: paddingForm } : {
          position: 'absolute',
          minWidth: 600,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        } : null}>
          <p>{t('profile-photo')}</p>
          <Form.Item name="avatar" valuePropName="fileList">
            <ImgCrop modalTitle={t('edit-image')} modalOk={t('commons.ok')} modalCancel={t('commons.cancel')} grid beforeCrop={file => beforeCrop(file)}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader input-upload-image-square"
                accept="image/png, image/jpeg"
                showUploadList={false}
                beforeUpload={file => handleUploadCover(file)}
              >
                {
                  userAvatar
                    ? userAvatar.base64
                      ? <img src={userAvatar.base64} alt="" />
                      : <img src={userAvatar.url} alt="" />
                    : <UploadButton />
                }
              </Upload>,
            </ImgCrop>
          </Form.Item>
          <Form.Item label={t('username')}
            name="username"
            initialValue={currentUser.username}
            rules={[
              {
                required: true,
                message: t('please-input-your-username'),
              },
              {
                validator: validator.validateEmptyString,
              },
            ]}
          >
            <Input placeholder={t('your-username')} />
          </Form.Item>
          <Form.Item
            label={t('email')}
            name="email"
            initialValue={currentUser.email}
            rules={[
              {
                required: true,
                message: t('please-input-your-email'),
              },
              {
                validator: validator.validateEmptyString,
              },
            ]}
          >
            <Input placeholder={t('your-email')} />
          </Form.Item>
          <Form.Item
            label={t('first-name')}
            name="firstName"
            initialValue={currentUser.firstName ? currentUser.firstName : ''}
            rules={[
              {
                required: false,
                message: t('please-input-first-name'),
              },
              {
                validator: validator.validateEmptyString,
              },
            ]}
          >
            <Input placeholder={t('input-first-name')} />
          </Form.Item>
          <Form.Item
            label={t('last-name')}
            name="lastName"
            initialValue={currentUser.lastName ? currentUser.lastName : ''}
            rules={[
              {
                required: false,
                message: t('please-input-last-name'),
              },
              {
                validator: validator.validateEmptyString,
              },
            ]}
          >
            <Input placeholder={t('input-last-name')} />
          </Form.Item>
          <Form.Item
            label={t('language')}
            name="language"
            initialValue={currentUser.language ? currentUser.language : "en"}
            rules={[
              { validator: validator.validateEmptyString },
            ]}
          >
            <Select className='selectdrp'>
              <Option value="en">English</Option>
              <Option value="fi">Finnish</Option>
              <Option value="fr">French</Option>
              <Option value="de">German</Option>
              <Option value="it">Italian</Option>
              <Option value="es">Spanish</Option>
              <Option value="sv">Swedish</Option>
              <Option value="vi">Vietnamese</Option>
            </Select>
          </Form.Item>
          {projectStore.projectDetail?.id &&
          <>
            <Form.Item
              label={t('navigation-style')}
              name="navigationstyle"
              rules={[
                { validator: validator.validateEmptyString },
              ]}
            >
              <Select className='selectdrp' onChange={handleChangeNavigation}>
                <Option value="xdTwin">{is6DPLANNER(window?.location?.origin) ? "6DPlanner": "xD Twin"}</Option>
                <Option value="cesium">Cesium</Option>
                <Option value="connect">Connect</Option>
                <Option value="custom">Custom</Option>
              </Select>
            </Form.Item>
          </>
            }
          {showCustom &&
            <NavigationStyled>
              <div className="code-box-title">
                {t('custom-navigation-style')}
              </div>
              <div className="code-box-description">
                <Form.Item
                  label={t('pan')}
                  name="pan"
                  initialValue={projectStore.navigationStyles?.control?.pan}
                  rules={[
                    {
                      required: true,
                      message: t('please-select-pan-events'),
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={t('please-select')}
                  >
                    {Object.keys(CameraEventType).map(key => (
                      <Option key={key} value={CameraEventType[key]}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('zoom')}
                  name="zoom"
                  initialValue={projectStore.navigationStyles?.control?.zoom}
                  rules={[
                    {
                      required: true,
                      message: t('please-select-zoom-events'),
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={t('please-select')}
                  >
                    {Object.keys(CameraEventType).map(key => (
                      <Option key={key} value={CameraEventType[key]}>
                        {key}
                      </Option>
                    ))}
                  </Select>

                </Form.Item>
                <Form.Item
                  label={t('tilt')}
                  name="tilt"
                  initialValue={projectStore.navigationStyles?.control?.tilt}
                  rules={[
                    {
                      required: true,
                      message: t('please-select-tilt-events'),
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={t('please-select')}
                  >
                    {Object.keys(CameraEventType).map(key => (
                      <Option key={key} value={CameraEventType[key]}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('rotate')}
                  name="rotate"
                  initialValue={projectStore.navigationStyles?.control?.rotate}
                  rules={[
                    {
                      required: true,
                      message: t('please-select-rotate-events'),
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={t('please-select')}
                  >
                    {Object.keys(CameraEventType).map(key => (
                      <Option key={key} value={CameraEventType[key]}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </NavigationStyled>
          }



          <ButtonGroup>
            <Button style={{ marginBottom: '5px', width: (isDesktopOrLaptop ? 'auto' : '100%') }} danger ghost onClick={showDeleteConfirm}>{t('delete-my-account')}</Button>
            <Button style={{ float: "right", marginBottom: '5px', width: (isDesktopOrLaptop ? 'auto' : '100%') }} type={'primary'} htmlType={'submit'} className="float-right">
              {t('commons.save')}
            </Button>
            <Button style={{ float: "right", marginBottom: '5px', marginRight: '5px', width: (isDesktopOrLaptop ? 'auto' : '100%') }} onClick={() => setShowModalChangePassword(true)} type={'primary'} >{t('change-password')}</Button>
            <Button style={{ float: "right", marginBottom: '5px', marginRight: '5px', width: (isDesktopOrLaptop ? 'auto' : '100%') }} type={'default'} onClick={() => hiddenDrawer()} className="float-right">
              {t('commons.cancel')}
            </Button>
          </ButtonGroup>
        </Form>
        {isLoading ? <LoadingSpinner theme={commonStore.appTheme} type={'page'} /> : <div></div>}
      </>
    )
  }


  return (
    <Fragment>
      <CustomForm />
    </Fragment>
  )

}

export default withRouter(inject('usersStore', 'commonStore', 'projectStore')(observer(UserSettingsForm)))
