import { Empty, Row, Spin } from 'antd'
import { isEmpty } from 'lodash'
import { inject, observer } from 'mobx-react'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TreeDataWrapper } from '../DrawerTilesetExplorer/CustomStyled'

const dhx = window.dhx

const TaskShortcut = ({ projectGanttStore, projectStore, sketchingStore }) => {
  const { t } = useTranslation()
  let treeContainer = useRef(null)
  const [ganttData, setGanttData] = useState()
  const [treeData, setTreeData] = useState([])
  const [treeDhx, setTreeDhx] = useState(null)
  const [isLoadInit, setLoadInit] = useState(false)
  const [keySelected, setKeySelected] = useState([])

  // handle add project gantt
  const handleCreateProject4dGantt = () => {
    const newGantt = {
      name: `project_gantt_${projectStore.projectDetail._id}`,
      project: projectStore.projectDetail._id,
    }
    projectGanttStore.createProject4dGantt(newGantt)
  }

  useEffect(() => {
    projectGanttStore
      .getProject4dGantt(projectStore.projectDetail.id)
      .then(res => {
        if (res && !res?.length) {
          handleCreateProject4dGantt()
        }
      })

    return () => {
      setGanttData()
      projectGanttStore.setProjectGanttData([])
    }
  }, [])

  function createTree(flatData = []) {
    const idToNodeMap = new Map()
    const rootNodes = []

    const currentEditSketchId = sketchingStore?.currentEditSketch?.id
    const defaultSketch =
      projectStore.projectDetail?.metadata?.sketchDefault?.find(
        item => item.userId === projectStore.projectDetail.currentUser._id
      )
    const existTaskDefault = defaultSketch?.keyTaskAddSketchDefault || []

    // Initialize all nodes and map them by ID
    flatData.forEach(node => {
      idToNodeMap.set(node.id, {
        ...node,
        value: node.text,
        items: [],
        opened: false,
      })
    })

    // Link children to their parents and find root nodes
    flatData.forEach(node => {
      const isOpened = !currentEditSketchId
        ? existTaskDefault.includes(node.id)
        : node?.dataTree?.includes(currentEditSketchId)

      const currentNode = idToNodeMap.get(node.id)
      currentNode.opened = isOpened // Set opened for the current node

      if (node.parent === '0') {
        // Root node
        const { parent, ...rest } = currentNode
        rootNodes.push({ ...rest })
      } else {
        // Add to parent's children
        const parentNode = idToNodeMap.get(node.parent)
        if (parentNode) {
          parentNode.items.push(currentNode)
        }
      }
    })

    // Ensure parents are opened if any child is opened
    const ensureParentOpened = node => {
      if (node.items && node.items.length > 0) {
        let hasOpenedChild = false

        node.items.forEach(child => {
          ensureParentOpened(child) // Recursive call for children
          if (child.opened) {
            hasOpenedChild = true
          }
        })

        if (hasOpenedChild) {
          node.opened = true // Open parent if any child is opened
        }
      }
    }

    // Apply to all root nodes
    rootNodes.forEach(rootNode => ensureParentOpened(rootNode))

    return rootNodes
  }

  const handlePreprocessProjectGanttData = () => {
    if (!projectGanttStore.projectGanttData?.length) {
      setGanttData()
      return
    }
    // current phase just use one gantt chart so we only need to element at the beginning
    const { data, links } = projectGanttStore.projectGanttData[0]

    if (data?.length) {
      data.forEach(element => {
        if (element?.start_date) {
          element.start_date = new Date(element.start_date) // parse date
        }
        if (element?.end_date) {
          element.end_date = new Date(element?.end_date) // parse date
        }
        if (element?.planned_start) {
          element.planned_start = new Date(element.planned_start) // parse date
        }
        if (element?.planned_end) {
          element.planned_end = new Date(element.planned_end) // parse date
        }
        element.color =
          element.colorTaskEnable === true
            ? element.highlightColor || '#20b56d'
            : element.type === 'project'
              ? '#20b56d'
              : '#537cfa'
      })
    }
    const normalData = JSON.parse(JSON.stringify(data || []))
    const treeData = createTree(normalData)
    setTreeData(treeData || [])
    setGanttData({ data: normalData, links })
  }

  useEffect(() => {
    handlePreprocessProjectGanttData()
  }, [projectGanttStore.projectGanttData])

  useEffect(() => {
    if (treeContainer && treeData?.length > 0) {
      if (!isLoadInit) {
        const tree = new dhx.Tree(treeContainer, {
          checkbox: true,
        })

        tree.events.on('afterCheck', function (index, id, value) {
          setKeySelected(tree.getChecked())
          sketchingStore.setListGanttNodeSaveSketch(tree.getChecked())
        })

        setTreeDhx(tree)
        setLoadInit(true)
      }
    }
  }, [treeContainer, treeData, isLoadInit])

  useEffect(() => {
    if (treeData?.length > 0 && isLoadInit && treeDhx) {
      treeDhx.data.parse(treeData || [])
    }
  }, [treeData, isLoadInit, treeDhx])

  useEffect(() => {
    if (treeData?.length > 0 && isLoadInit && treeDhx) {
      const defaultSketch =
        projectStore.projectDetail?.metadata?.sketchDefault?.find(
          item => item.userId === projectStore.projectDetail.currentUser._id
        )
      const existTaskDefault = defaultSketch?.keyTaskAddSketchDefault || []
      const currentSelectedTask = projectGanttStore.sketchDrawer.task.$new ? [] : [projectGanttStore.sketchDrawer.task.id]
      const selectedTaskIds = Array.from(new Set([...existTaskDefault, ...currentSelectedTask]));

      if (!sketchingStore?.currentEditSketch?.id && selectedTaskIds?.length > 0 && !isEmpty(treeDhx?.getState())) {
        selectedTaskIds.forEach((nodeId) => {
          if (nodeId) {
            treeDhx.checkItem(nodeId);
            setKeySelected((prev) => [...prev, nodeId]);
          }
        });
        sketchingStore.setListGanttNodeSaveSketch(treeDhx.getChecked())
        sketchingStore.setListOriginalGanttNodeSaveSketch(treeDhx.getChecked())
      }

      if (!sketchingStore?.currentEditSketch?.id || !treeData?.length || !ganttData?.data?.length || isEmpty(treeDhx?.getState())) return;

      ganttData.data.forEach(node => {
        if (node?.dataTree?.includes(sketchingStore.currentEditSketch.id)) {
          treeDhx.checkItem(node.id)
          setKeySelected(prev => [...prev, node.id])
        }
      })
      sketchingStore.setListGanttNodeSaveSketch(treeDhx.getChecked())
      sketchingStore.setListOriginalGanttNodeSaveSketch(treeDhx.getChecked())
    }
  }, [
    isLoadInit,
    treeDhx,
    treeData,
    ganttData,
    sketchingStore?.currentEditSketch?.id,
  ])

  return (
    <Spin spinning={projectGanttStore.isLoading} tip={t('loading-tree')}>
      <Row style={{ paddingTop: 8 }}>
        {treeData && treeData.length ? (
          <TreeDataWrapper width="100%">
            <div
              id="task-shortcut"
              style={{ minWidth: 270 }}
              ref={el => (treeContainer = el)}></div>
          </TreeDataWrapper>
        ) : (
          <Row justify="center">
            <Empty description={<span>{t('no-data')}</span>} />
          </Row>
        )}
      </Row>
    </Spin>
  )
}

export default inject(
  'commonStore',
  'projectGanttStore',
  'projectStore',
  'sketchingStore',
  'projectSettingStore',
  'usersStore'
)(observer(TaskShortcut))
