import { Modal, Row } from 'antd'
import styled from 'styled-components'

export const DHTMLXSpreadsheetWrapper = styled.div`
    .dhx_grid-content{
        .dhx_grid-body{
            height: 100%;
            //max-height : 700px;
        }
    }
`
export const CustomSplitMobile = styled.div`
    position : absolute;
    top : 50%;
    left : -16px;
    z-index: 999;
`

export const ActionToolDrawer = styled(Row)`
  button{
    margin: 5px 2.5px;
  }
  button:first-child {
    margin-left: 0;
  }
  width: 100%;
  padding: 0 0 12px 12px ;
  background-color: #FFF;
`

export const CheckBoxRules = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ActionDHXTool = styled(Row)`
  display: flex;
  button{
    margin: 5px 2.5px;
  }
  button:first-child {
    margin-left: 0;
  }
`

export const RuleContainer = styled.div`
  display: flex;
  padding : 2px 0;
  cursor: pointer;
  .rule-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props =>props.width && props.width +'px' };
    height: ${props =>props.height && props.height + 'px' };
    border: 1px solid ${props =>props.background && props.background };
    background:  ${props =>props.background && props.background };
    color:  ${props =>props.color && props.color };
  }
  .rule-title{
    ${props =>props.title && 'padding: 0 5px' };
    font-weight: bold;
  }
`

export const SheetReportTool = styled.div`
  /* padding : 20px 0; */
`
export const RuleWrapper = styled.div`
`

export const ModalAddAttribute = styled(Modal)`
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-modal-body {
    padding: 16px 24px;
  }
`