import { Avatar, Button, Popover, Tooltip } from 'antd';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { isMobile, isTablet, isMobileOnly } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { ReactComponent as Icon2D } from '../../../../assets/svgs/2D-s0.svg';
import { ReactComponent as Icon3D } from '../../../../assets/svgs/3D-s0.svg';
import { ReactComponent as AR } from '../../../../assets/svgs/AR-s0.svg';
import { ReactComponent as CA } from '../../../../assets/svgs/CA-s0.svg';
import { ReactComponent as Clipplane } from '../../../../assets/svgs/clipplane-b0.svg';
import { ReactComponent as Compass } from '../../../../assets/svgs/compass-s0.svg';
import { ReactComponent as ExitFullscreen } from '../../../../assets/svgs/exit-full-screen-s0.svg';
import { ReactComponent as Fullscreen } from '../../../../assets/svgs/full-screen-s0.svg';
import { ReactComponent as GPS } from '../../../../assets/svgs/GPS-s0.svg';
import { ReactComponent as MA } from '../../../../assets/svgs/MA-s0.svg';
import { ReactComponent as Schedule4D } from '../../../../assets/svgs/schedule4D-s0.svg';
import { ReactComponent as ViewPoint } from '../../../../assets/svgs/viewpoint-s0.svg';
import { ReactComponent as PositionGPS } from '../../../../assets/svgs/Position-GPS.svg';
import { ReactComponent as PositionCompass } from '../../../../assets/svgs/Position-Compass.svg';
import { ReactComponent as CVCalibration } from '../../../../assets/svgs/CV-Calibration.svg';
import { ReactComponent as ARManualPosition } from '../../../../assets/svgs/AR-Manual-position.svg';
import { ReactComponent as ARManualOrientation } from '../../../../assets/svgs/AR-Manual-orientation.svg';
import { ReactComponent as MeasureIcon } from '../../../../assets/svgs/measurement-icon.svg'
import { ReactComponent as MeasureDistance } from '../../../../assets/svgs/measure-distance-b0.svg';
import { ReactComponent as MeasurePoint } from '../../../../assets/svgs/measure-point-b0.svg';
import { ReactComponent as MeasureArea } from '../../../../assets/svgs/measure-area.svg'
import { ReactComponent as MeasurePolyline } from '../../../../assets/svgs/draw-polyline.svg'


import { VRButton, SVGIcon } from '@/components/elements';

import { ARMaskingToolbarBottomContainer, ButtonCustom, ToolbarBottomContainer, ToolbarBottomItem, ToolbarBottomHidenArea } from './CustomStyled';
import { Trans, useTranslation } from 'react-i18next';
import { ClippingPlaneTool, MeasureTool } from '@/helper'

const ToolbarBottom = props => {
  const { commonStore, projectStore, capturesStore, currentPage, schedulingStore, adminStore, usersStore, polylineCutToolStore, uiStore, viewer, alignmentStore, measureStore, sketchingStore } = props
  const { t } = useTranslation();
  const [fullScreen, setFullScreen] = useState(false)
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const [typeStatus, setTypeStatus] = useState(commonStore.useAbsoluteOrientation)
  useEffect(() => {
    setTypeStatus(commonStore.useAbsoluteOrientation)
  }, [commonStore.useAbsoluteOrientation])

  const setUseAbsoluteOrientation = () => {
    commonStore.setUseAbsoluteOrientation(!commonStore.useAbsoluteOrientation)
  }

  useEffect(() => {

  }, [commonStore.GPSUpdate, commonStore.manualGpsState, commonStore.manualOrientationState])

  useEffect(() => {
    let isMeasureToolsActive = measureStore.measureMode === 'point' || measureStore.measureMode === 'distance' || measureStore.measureMode === 'area' || measureStore.measureMode === 'polyline' || measureStore.measureToolVisible === true
    let isClipplaneToolsActive = projectStore.clippingMode === 'horizontal' || projectStore.clippingMode === 'vertical' || projectStore.clippingMode === 'alignment' || uiStore.clippingPlaneToolVisible
    let isSketchToolsActive = sketchingStore.drawMode || sketchingStore.sketchToolVisible
    let isGPSModeActive = projectStore.gpsMode != 'none' && projectStore.skyColor === 'none'
    let isARModeActive = projectStore.gpsMode === 'first_person'

    if (!isSketchToolsActive) projectStore.setCurrentHelpfeature('add_sketch', 1)
    if (!isMeasureToolsActive) projectStore.setCurrentHelpfeature('measurement', 1)
    if (!isClipplaneToolsActive) projectStore.setCurrentHelpfeature('clip_plane', 1)
    if (!schedulingStore.timeSliderVisible) projectStore.setCurrentHelpfeature('4d', 1)
    if (!isGPSModeActive) projectStore.setCurrentHelpfeature('gps', 1)
    if (!isARModeActive) projectStore.setCurrentHelpfeature('ar', 1)

    if (!schedulingStore.timeSliderVisible && !isMeasureToolsActive && !isClipplaneToolsActive && !isSketchToolsActive && !isGPSModeActive && !isARModeActive)
      projectStore.setCurrentHelpfeature('all')
  }, [measureStore.measureMode, projectStore.clippingMode, schedulingStore.timeSliderVisible, sketchingStore.drawMode, projectStore.gpsMode, projectStore.skyColor,
  measureStore.measureToolVisible, uiStore.clippingPlaneToolVisible, sketchingStore.sketchToolVisible
  ])


  const checkingFeatureRole = (type) => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
  }

  // --------------------------------------------- //
  const gpsModeIcons = {
    none: { icon: <GPS />, title: (isMobile && !isPortrait) ? "" : t('gps-positioning') },
    fix: { icon: <Icon3D />, title: (isMobile && !isPortrait) ? "" : t("normal-3d-mode") },
    free: { icon: <Compass />, title: (isMobile && !isPortrait) ? "" : t("compass-orientation") },
    first_person: { icon: <GPS />, title: (isMobile && !isPortrait) ? "" : t('gps-positioning') },
  }

  const secondGpsModeIcons = {
    none: { icon: <GPS />, title: (isMobile && !isPortrait) ? "" : t('gps-positioning') },
    free: { icon: <Icon3D />, title: (isMobile && !isPortrait) ? "" : t("normal-3d-mode") },
    first_person: { icon: <GPS />, title: (isMobile && !isPortrait) ? "" : t('gps-positioning') },
  }

  const thirdGpsModeIcons = {
    none: { icon: <Compass />, title: (isMobile && !isPortrait) ? "" : t('gps-positioning') },
    fix: { icon: <Icon3D />, title: (isMobile && !isPortrait) ? "" : t("normal-3d-mode") },
    first_person: { icon: <GPS />, title: (isMobile && !isPortrait) ? "" : t('gps-positioning') },
  }

  // --------------------------------------------- //
  const firstPersonModeIcons = {
    none: { icon: <AR />, title: (isMobile && !isPortrait) ? "" : t('ar-with-3d') },
    cesium: { icon: <CA />, title: (isMobile && !isPortrait) ? "" : t('ar-with-camera') },
    camera: { icon: <MA />, title: (isMobile && !isPortrait) ? "" : t("ar-with-masking") },
    masking: { icon: <Icon3D />, title: (isMobile && !isPortrait) ? "" : t("3d-navigation") },
  }
  const secondPersonModeIcons = {
    none: { icon: <CA />, title: (isMobile && !isPortrait) ? "" : t('ar-with-camera') },
    camera: { icon: <MA />, title: (isMobile && !isPortrait) ? "" : t("ar-with-masking") },
    masking: { icon: <Icon3D />, title: (isMobile && !isPortrait) ? "" : t("3d-navigation") },
  }
  const thirdPersonModeIcons = {
    none: { icon: <MA />, title: (isMobile && !isPortrait) ? "" : t("ar-with-masking") },
    masking: { icon: <Icon3D />, title: (isMobile && !isPortrait) ? "" : t("3d-navigation") },
  }

  // --------------------------------------------- //
  const firstPersonModeToolTip = {
    none: { icon: <AR />, title: (isMobile && !isPortrait) ? "" : t('ar-with-3d') },
    cesium: { icon: <CA />, title: (isMobile && !isPortrait) ? "" : t('ar-with-camera') },
    camera: { icon: <MA />, title: (isMobile && !isPortrait) ? "" : t("ar-with-mask") },
    masking: { icon: <AR />, title: (isMobile && !isPortrait) ? "" : t("normal-3d-mode") },
  }
  const secondPersonModeToolTip = {
    none: { icon: <CA />, title: (isMobile && !isPortrait) ? "" : t('ar-with-camera') },
    camera: { icon: <MA />, title: (isMobile && !isPortrait) ? "" : t("ar-with-mask") },
    masking: { icon: <AR />, title: (isMobile && !isPortrait) ? "" : t("normal-3d-mode") },
  }
  const thirdPersonModeToolTip = {
    none: { icon: <MA />, title: (isMobile && !isPortrait) ? "" : t("ar-with-mask") },
    masking: { icon: <AR />, title: (isMobile && !isPortrait) ? "" : t("normal-3d-mode") },
  }

  function onChange2D3D() {
    projectStore.set2D(!projectStore.is2D)
  }
  const handleTouchStart = e => {
    document.addEventListener("touchend", handleTouchEnd, { passive: false });
    document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    console.log("TouchStart")
  };

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd, { passive: false });
    document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
    console.log("TouchEnd")
  };

  const setGPSUpdate = () => {
    commonStore.setGPSUpdate(!commonStore.GPSUpdate)
  }

  function getVisibleTilesets(visibleTilesets, modelList, hide) {

    if (visibleTilesets && visibleTilesets.length > 0) {
      return modelList.filter(function (newData) {
        return projectStore.visibleTilesets.filter(function (oldData) {
          if (newData.modelId === oldData.modelId) {
            if (hide) {

              if (!oldData.isTempHidden) {
                if (oldData.isVisible === false) {
                  newData.isVisible = false
                  newData.isVisible4D = oldData.isVisible4D
                  newData.isVisibleClip = oldData.isVisibleClip
                }
                if (newData.isRender && oldData.isVisible) {
                  newData.isVisible = false
                  newData.isTempHidden = true
                  newData.isVisible4D = oldData.isVisible4D
                  newData.isVisibleClip = oldData.isVisibleClip
                }
              }
              else {
                if (newData.isRender) {
                  newData.isVisible = false
                  newData.isTempHidden = true
                  newData.isVisible4D = oldData.isVisible4D
                  newData.isVisibleClip = oldData.isVisibleClip
                }
              }
            }
            else {

              if (oldData.isTempHidden) {
                newData.isVisible4D = oldData.isVisible4D
                newData.isVisibleClip = oldData.isVisibleClip
                newData.isVisible = true
              }
              else
                newData.isVisible = oldData.isVisible
              newData.isVisible4D = oldData.isVisible4D
              newData.isVisibleClip = oldData.isVisibleClip
              newData.isTempHidden = undefined
            }
          }
        })
      });
    }
    else {
      return modelList.map(function (obj) {
        if (hide) {
          if (!obj.isTempHidden) {
            if (obj.isRender) {
              obj.isVisible = false;
              obj.isTempHidden = true
              obj.isVisible4D = false
              obj.isVisibleClip = false
            }
          }
        }
        else {
          if (obj.isTempHidden) {
            obj.isVisible = true
            obj.isVisible4D = true
            obj.isVisibleClip = true
          }
          obj.isTempHidden = undefined
        }
        return obj
      });
    }
  }
  var retrievedObject = JSON.parse(localStorage.getItem(`gotoviewer-${projectStore.projectDetail.id}-${usersStore.currentUser.id}`));

  const onClickManualGPS = () => {
    let temp = toJS(projectStore.modelList).map(item => {
      let visible4DModels = retrievedObject?.visible4DModels || []
      let visibleClipModels = retrievedObject?.visibleClipModels || []
      let isExist4D = visible4DModels && visible4DModels.length > 0 ? visible4DModels.find(c => c.type4D === 'model' && c.modelId === item._id) : false
      let isExistClip = visibleClipModels && visibleClipModels.length > 0 ? visibleClipModels.find(c => c.modelId === item._id) : false
      return { modelId: item._id, isVisible: true, isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceType, isVisible4D: (isExist4D ? isExist4D.isVisible4D : true), isVisibleClip: (isExistClip ? isExistClip.isVisibleClip : true), endDate: item.endDate, startDate: item.startDate }
    })

    if (commonStore.manualGpsState === 0) {
      commonStore.setManualGPS(1)
      commonStore.setGPSUpdate(false)

      if (projectStore.skyColor === 'masking' || projectStore.skyColor === 'camera') {
        //projectStore.setWebglContextAlpha(false)
        let _visibletileset = getVisibleTilesets(projectStore.visibleTilesets, temp, false)
        projectStore.setVisibleTilesets(_visibletileset)
        if (projectStore.skyColor === 'masking') projectStore.setMasking(false)
      }

      projectStore.set2D(true)

    } else if (commonStore.manualGpsState === 1) {
      if (projectStore.skyColor === 'masking' || projectStore.skyColor === 'camera') {
        //projectStore.setWebglContextAlpha(false)
        let _visibletileset = getVisibleTilesets(projectStore.visibleTilesets, temp, true)
        projectStore.setVisibleTilesets(_visibletileset)
        if (projectStore.skyColor === 'masking') projectStore.setMasking(true)
      }
      commonStore.setManualGPS(2)
      commonStore.setGPSUpdate(false)
      projectStore.set2D(false)
    } else {
      commonStore.setManualGPS(0)
      commonStore.setGPSUpdate(true)
      projectStore.set2D(false)
    }
  }
  const onClickManualOrientation = () => {
    let temp = toJS(projectStore.modelList).map(item => {
      let visible4DModels = retrievedObject?.visible4DModels || []
      let visibleClipModels = retrievedObject?.visibleClipModels || []
      let isExist4D = visible4DModels && visible4DModels.length > 0 ? visible4DModels.find(c => c.type4D === 'model' && c.modelId === item._id) : false
      let isExistClip = visibleClipModels && visibleClipModels.length > 0 ? visibleClipModels.find(c => c.modelId === item._id) : false
      return { modelId: item._id, isVisible: true, isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceType, isVisible4D: (isExist4D ? isExist4D.isVisible4D : true), isVisibleClip: (isExistClip ? isExistClip.isVisibleClip : true), endDate: item.endDate, startDate: item.startDate }
    })
    if (commonStore.manualOrientationState === 0) {
      commonStore.setManualOrientationState(1)
      setTypeStatus(false)
      if (projectStore.skyColor === 'masking' || projectStore.skyColor === 'camera') {
        //projectStore.setWebglContextAlpha(false)
        let _visibletileset = getVisibleTilesets(projectStore.visibleTilesets, temp, false)

        projectStore.setVisibleTilesets(_visibletileset)
        if (projectStore.skyColor === 'masking') projectStore.setMasking(false)
      }
    } else if (commonStore.manualOrientationState === 1) {

      if (projectStore.skyColor === 'masking' || projectStore.skyColor === 'camera') {
        //projectStore.setWebglContextAlpha(false)
        let _visibletileset = getVisibleTilesets(projectStore.visibleTilesets, temp, true)
        projectStore.setVisibleTilesets(_visibletileset)
        if (projectStore.skyColor === 'masking') projectStore.setMasking(true)
      }
      commonStore.setManualOrientationState(2)

    } else {
      commonStore.setManualOrientationState(0)
      setTypeStatus(commonStore.useAbsoluteOrientation)
    }
  }
  const ViewSendRender = () => {
    return (
      <Fragment>
        {
          checkingFeatureRole("feature_smart_orientation") && (
            <Tooltip
              placement="left"
              title={t('smart-orientation')}
              overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <ButtonCustom
                type="default"
                icon={<SVGIcon className='argui-icon' width={30} height={30} content={<CVCalibration />} />}
                size={commonStore.buttonSizeNormal}
                onClick={() => {
                  projectStore.setSendARInfo(true)
                }}
              />
            </Tooltip>
          )
        }
        <Tooltip placement="bottom" title={t('manual-orientation')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <ButtonCustom
            style={{ marginLeft: 5 }}
            type={commonStore.manualOrientationState === 0 ? 'default' : commonStore.manualOrientationState === 1 ? 'primary' : "danger"}
            icon={<SVGIcon className='argui-icon' width={30} height={30} content={<ARManualOrientation />} />}
            size={commonStore.buttonSizeNormal}
            onClick={onClickManualOrientation}
          />
        </Tooltip>
        <Tooltip placement="top" title={t('compass')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <ButtonCustom
            style={{ marginLeft: 5 }}
            type={typeStatus ? 'primary' : 'default'}
            icon={<SVGIcon className='argui-icon' width={30} height={30} content={<PositionCompass />} />}
            size={commonStore.buttonSizeNormal}
            onClick={setUseAbsoluteOrientation}
          //onTouchStart={() => handleTouchStart()}
          />
        </Tooltip>
        <Tooltip placement="bottom" title={t('manual-position')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <ButtonCustom
            style={{ marginLeft: 5 }}
            type={commonStore.manualGpsState === 0 ? 'default' : commonStore.manualGpsState === 1 ? 'primary' : "danger"}
            icon={<SVGIcon className='argui-icon' width={30} height={30} content={<ARManualPosition />} />}
            size={commonStore.buttonSizeNormal}
            onClick={onClickManualGPS}
          />
        </Tooltip>
        <Tooltip placement="bottom" title={t('gps')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <ButtonCustom
            style={{ marginLeft: 5 }}
            type={commonStore.GPSUpdate ? 'primary' : 'default'}
            icon={<SVGIcon className='argui-icon' width={30} height={30} content={<PositionGPS />} />}
            size={commonStore.buttonSizeNormal}
            onClick={setGPSUpdate}
          />
        </Tooltip>
      </Fragment>
    )
  }
  const onEndDrawHideArea = () => {
    if (polylineCutToolStore.drawEditor.currentEditEntity) {
      if (polylineCutToolStore.drawEditor.currentEditEntity.onFinish)
        polylineCutToolStore.drawEditor.currentEditEntity.onFinish()
    }
    projectStore.setShowHiddenDrawer(true)
  }
  
  const onCancelDrawHideArea = () => {
    if (polylineCutToolStore.drawEditor.currentEditEntity) {
      if (polylineCutToolStore.drawEditor.currentEditEntity.onFinish)
        polylineCutToolStore.drawEditor.currentEditEntity.onFinish('cancel')
    }
    projectStore.setShowHiddenDrawer(true)
  }

  function saveAlignmentClipStepSetting() {
    if (usersStore.currentUser.id) {
      let _projectSetting = {}
      _projectSetting.userid = usersStore.currentUser.id
      _projectSetting.projectSetting = {
        alignmentClipStepSetting: alignmentStore.step ? alignmentStore.step : 10
      }

      let _projectMetadata = projectStore.projectDetail.metadata
      if (_projectMetadata && _projectMetadata.projectSetting && _projectMetadata.projectSetting.length > 0) {
        const objIndex = _projectMetadata.projectSetting.findIndex(obj => obj.userid === usersStore.currentUser.id);
        if (objIndex > -1) {
          _projectMetadata.projectSetting[objIndex].projectSetting = { ..._projectMetadata.projectSetting[objIndex].projectSetting, alignmentClipStepSetting: alignmentStore.step ? alignmentStore.step : 10 }
        } else {
          _projectMetadata.projectSetting.push(_projectSetting)
        }
      } else {
        _projectMetadata.projectSetting = [_projectSetting]
      }
      const checkuserinproject = projectStore.projectDetail.projectuserroles.find(pur => pur.email === usersStore.currentUser.email && pur.inviteStatus === 'accepted')
      if (checkuserinproject) {
        projectStore.updateProjectMetadata({ metadata: _projectMetadata }).then((res) => {
          projectStore.projectDetail.metadata = res.metadata
        }).catch(err => {
          console.log(err)
        })
      } else {
        projectStore.updateProjectDataForGuest({ metadata: _projectMetadata }).then((res) => {
          projectStore.projectDetail.metadata = res.metadata
        }).catch(err => {
          console.log(err)
        })
      }
    } else {
      localStorage.setItem(`alignment_clip_step_setting-${projectStore.projectDetail.id}`, JSON.stringify({ alignmentClipStepSetting: alignmentStore.step ? alignmentStore.step : 10 }));
    }
  }

  const measureicon = {
    point: <MeasurePoint />,
    distance: <MeasureDistance />,
    area: <MeasureArea />,
    polyline: <MeasurePolyline />,
    undefined: <MeasureIcon />,
  }

  const viewByPage = () => {
    switch (currentPage) {
      case 'projectDetail':
      case 'projectShare':
        return (
          <Fragment>
            <Avatar.Group maxPopoverPlacement={'bottom'} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', marginLeft: 0, borderRadius: 5 }}>
              {/* <Tooltip title="Navigate and select" placement="top">
                <AvatarCustom
                  shape="square"
                  icon={<SVGIcon content={<NavigationSelect />}/>}
                  onClick={() => projectStore.setViewMode('Default mode')}
                />
              </Tooltip> */}
              <ToolbarBottomItem activated={(measureStore.measureMode === 'point' || measureStore.measureMode === 'distance' || measureStore.measureMode === 'area' || measureStore.measureMode === 'polyline') ? 1 : 0}>
                {
                  (checkingFeatureRole("feature_measure_point") || checkingFeatureRole("feature_measure_distance") || checkingFeatureRole("feature_measure_area") || checkingFeatureRole("feature_measure_polyline")) && (
                    <Tooltip title={t(measureStore.measureMode === undefined ? 'measurement' : 'end-measurement')} placement="top" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                      <Popover overlayClassName='popover-ar-mobile' visible={measureStore.measureToolVisible} placement='top' content={<MeasureTool />}>
                        <Button
                          style={{ verticalAlign: 0 }}
                          icon={<SVGIcon content={measureicon[measureStore.measureMode]} style={{ padding: "15px" }} />}
                          onClick={() => {
                            if (measureStore.measureMode) {
                              if (measureStore.measureMode === 'area' || measureStore.measureMode === 'polyline') {
                                measureStore.setRemovingShape(true)
                              }
                              measureStore.setMeasureToolVisible(false); //case if menu tool measure is opening then not show
                            } else {
                              measureStore.setMeasureToolVisible();
                              if (measureStore.measureToolVisible) {
                                projectStore.setCurrentHelpfeature('measurement')
                              } else projectStore.setCurrentHelpfeature('measurement', 1)
                            }
                            projectStore.setViewMode('default mode')
                            measureStore.setMeasureMode()
                            uiStore.setShowAttrPanel(false)
                            uiStore.setClippingPlaneToolVisible(false); //case if menu tool clipping is opening then not show
                          }}
                        />
                      </Popover>
                    </Tooltip>
                  )
                }
              </ToolbarBottomItem>
              <ToolbarBottomItem activated={(projectStore.clippingMode === 'horizontal' || projectStore.clippingMode === 'vertical' || projectStore.clippingMode === 'alignment') ? 1 : 0}>
                {
                  (checkingFeatureRole("feature_clip_plane")) && (
                    <Tooltip title={t('clip-plane')} placement="top" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                      <Popover overlayClassName='popover-ar-mobile' visible={uiStore.clippingPlaneToolVisible && !alignmentStore.alignmentTimeSliderVisible} placement='top' content={<ClippingPlaneTool />}>
                        <Button
                          style={{ verticalAlign: 0 }}
                          icon={<SVGIcon content={<Clipplane />} width={32} height={32} />}
                          onClick={() => {
                            if (projectStore.clippingMode) {
                              uiStore.setClippingPlaneToolVisible(false); //case if menu tool clipping is opening then not show
                              // recall hide area
                              if (projectStore.projectDetail.tilesetData?.hiddenArea && !polylineCutToolStore.showForm) {
                                polylineCutToolStore.setHideArea(projectStore.projectDetail.tilesetData?.hiddenArea)
                              }
                            } else {
                              uiStore.setClippingPlaneToolVisible();
                              if (uiStore.clippingPlaneToolVisible) {
                                projectStore.setCurrentHelpfeature('clip_plane')
                              } else projectStore.setCurrentHelpfeature('clip_plane', 1)
                            }
                            projectStore.setViewMode('default mode')
                            projectStore.setClippingMode(false)
                            if (alignmentStore.alignmentTimeSliderVisible) {
                              saveAlignmentClipStepSetting()
                              alignmentStore.toggleAlignmentTimeSliderVisible(false)
                            }
                            uiStore.setShowAttrPanel(false)
                            measureStore.setMeasureToolVisible(false); // hide menu measure if opening
                          }}
                        />
                      </Popover>
                    </Tooltip>
                  )
                }
              </ToolbarBottomItem>
              <ToolbarBottomItem activated={capturesStore.isDrawerCaptureVisible ? 1 : 0}>
                {
                  (checkingFeatureRole("feature_save_view_view") || checkingFeatureRole("feature_save_view_edit")) && (
                    <Tooltip title={t('saved-views')} placement="top" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                      <Button
                        style={{ verticalAlign: 0 }}
                        icon={<SVGIcon content={<ViewPoint />} width={32} height={32} />}
                        onClick={() => {
                          capturesStore.toggleDrawerCaptureVisible(true)
                        }}
                      />
                    </Tooltip>
                  )
                }
              </ToolbarBottomItem>
              <ToolbarBottomItem activated={schedulingStore.timeSliderVisible ? 1 : 0}>
                {
                  checkingFeatureRole("feature_4d") && (
                    <Tooltip title="4D" placement="top" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined} zIndex={9998} trigger={['click', 'hover']}>
                      <Button
                        style={{ verticalAlign: 0 }}
                        icon={<SVGIcon content={<Schedule4D />} width={32} height={32} />}
                        onClick={() => {
                          if (!schedulingStore.timeSliderVisible)
                            projectStore.setCurrentHelpfeature('4d')
                          schedulingStore.toggleTimeSliderVisible()
                          projectStore.setViewMode('default mode')
                          if (alignmentStore.alignmentTimeSliderVisible) {
                            saveAlignmentClipStepSetting()
                            alignmentStore.toggleAlignmentTimeSliderVisible(false)
                          }
                        }}
                      />
                    </Tooltip>
                  )
                }
              </ToolbarBottomItem>

            </Avatar.Group>
            {!projectStore.showHiddenDrawer && polylineCutToolStore.drawMode ? //hiden area case for on mobile then show two button
              (
                <ToolbarBottomHidenArea>
                  <Button
                    type="primary"
                    onClick={onEndDrawHideArea}
                  >{t('End draw')}</Button>
                  <Button
                    style={{ marginLeft: 5 }}
                    onClick={onCancelDrawHideArea}
                  >{t('commons.cancel')}</Button>
                </ToolbarBottomHidenArea>
              ) : null}
          </Fragment>
        )
      default:
        break
    }
  }
  return (
    <Fragment>
      <ToolbarBottomContainer
        alignment={alignmentStore.alignmentTimeSliderVisible ? true : undefined}
        style={{
          display: projectStore.cleanMode ? 'none' : 'block',
        }}
        isIpad={isTablet}
      >
        {currentPage ? viewByPage() : ''}
      </ToolbarBottomContainer>
      <ARMaskingToolbarBottomContainer style={{ display: projectStore.cleanMode ? 'none' : isDesktopOrLaptop ? 'grid' : (isPortrait ? 'grid' : 'block') }}>
        {currentPage === 'projectDetail' && projectStore.projectDetail?.id ?
          <>
            <ToolbarBottomItem >
              {
                checkingFeatureRole("feature_vr") && (
                  <VRButton viewer={viewer} />
                )
              }
            </ToolbarBottomItem>
            <ToolbarBottomItem activated={projectStore.gpsMode === 'first_person'}>
              <Tooltip
                placement={isDesktopOrLaptop ? 'right' : (isPortrait ? 'right' : 'top')}
                title={t((
                  checkingFeatureRole('feature_ar_3d') ? firstPersonModeToolTip[projectStore.arToolTip]?.title :
                    (!checkingFeatureRole('feature_ar_3d') && checkingFeatureRole('feature_ar_camera')) ? secondPersonModeToolTip[projectStore.arToolTip]?.title :
                      thirdPersonModeToolTip[projectStore.arToolTip]?.title))
                }
                overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined} zIndex={9998} trigger={['click', 'hover']}
              >
                <Popover placement={(isMobileOnly && !isPortrait) ? "topLeft" : isDesktopOrLaptop ? 'left' : (isPortrait ? 'left' : 'top')} overlayClassName={(isMobile && !isPortrait) ? 'popover-ar-mobile' : 'popover-ar'} visible={projectStore.gpsMode === 'first_person'} content={<ViewSendRender />}>
                  {
                    projectStore.currentUserRoleInProject && checkingFeatureRole('feature_ar_3d') ?
                      <Button
                        icon={
                          <SVGIcon content={firstPersonModeIcons[projectStore.skyColor].icon}
                            width={32} height={32} />}
                        className={'no-padding'}
                        onClick={() => {
                          commonStore.setCameraHeadingPitchRoll({})
                          projectStore.setEnableReCenterGPS(false);
                          projectStore.setCurrentHelpfeature('ar')

                          if (checkingFeatureRole('feature_ar_3d') &&
                            checkingFeatureRole('feature_ar_camera') &&
                            checkingFeatureRole('feature_ar_mask')) {
                            if (projectStore.gpsMode !== 'first_person') {
                              if(DeviceOrientationEvent.requestPermission)
                                DeviceOrientationEvent.requestPermission().then(response =>  console(response));
                              projectStore.setLoadingProgress(true)
                              projectStore.setGpsMode('first_person')
                              projectStore.setSkyColor('cesium')
                              projectStore.setArToolTip('cesium')
                              projectStore.setWebglContextAlpha(false)
                              projectStore.setMasking(false)
                            } else if (projectStore.gpsMode === 'first_person' && projectStore.skyColor === 'cesium') {

                              projectStore.setSkyColor('camera')
                              projectStore.setArToolTip('camera')
                              projectStore.setWebglContextAlpha(true)

                              projectStore.set2D(false)
                              commonStore.setGPSUpdate(true)
                              projectStore.setMasking(false)
                            } else if (projectStore.skyColor === 'camera' && !projectStore.isMasking) {
                              //AR with Masking
                              projectStore.setSkyColor('masking')
                              projectStore.setMasking(true)
                              projectStore.setArToolTip('masking')
                            } else {
                              projectStore.setGpsMode('none')
                              projectStore.setSkyColor('none')
                              projectStore.setArToolTip('none')
                              projectStore.setWebglContextAlpha(false)
                              projectStore.setMasking(false)
                              //commonStore.setManualGPS(0)
                              //commonStore.setManualOrientationState(0)
                              commonStore.setGPSUpdate(true)
                              window.location.reload();
                            }
                          } else if (checkingFeatureRole('feature_ar_3d') && !checkingFeatureRole('feature_ar_camera') && !checkingFeatureRole('feature_ar_mask')) {
                            if (projectStore.gpsMode !== 'first_person') {
                              projectStore.setLoadingProgress(true)
                              projectStore.setGpsMode('first_person')
                              projectStore.setSkyColor('masking')
                              projectStore.setArToolTip('masking')
                              projectStore.setWebglContextAlpha(false)
                              projectStore.setMasking(false)
                            } else {
                              projectStore.setGpsMode('none')
                              projectStore.setSkyColor('none')
                              projectStore.setArToolTip('none')
                              projectStore.setWebglContextAlpha(false)
                              projectStore.setMasking(false)
                              //commonStore.setManualGPS(0)
                              //commonStore.setManualOrientationState(0)
                              commonStore.setGPSUpdate(true)
                              window.location.reload();
                            }
                          } else if (checkingFeatureRole('feature_ar_3d') && checkingFeatureRole('feature_ar_camera') && !checkingFeatureRole('feature_ar_mask')) {
                            if (projectStore.gpsMode !== 'first_person') {
                              projectStore.setLoadingProgress(true)
                              projectStore.setGpsMode('first_person')
                              projectStore.setSkyColor('cesium')
                              projectStore.setArToolTip('cesium')
                              projectStore.setWebglContextAlpha(false)
                              projectStore.setMasking(false)
                            } else if (projectStore.gpsMode === 'first_person' && projectStore.skyColor === 'cesium') {
                              projectStore.setSkyColor('masking')
                              projectStore.setArToolTip('masking')
                              projectStore.setWebglContextAlpha(true)
                              projectStore.setMasking(false)
                            } else {
                              projectStore.setGpsMode('none')
                              projectStore.setSkyColor('none')
                              projectStore.setArToolTip('none')
                              projectStore.setWebglContextAlpha(false)
                              projectStore.setMasking(false)
                              //commonStore.setManualGPS(0)
                              //commonStore.setManualOrientationState(0)
                              commonStore.setGPSUpdate(true)
                              window.location.reload();
                            }
                          } else if (checkingFeatureRole('feature_ar_3d') && !checkingFeatureRole('feature_ar_camera') && checkingFeatureRole('feature_ar_mask')) {
                            if (projectStore.gpsMode !== 'first_person') {
                              projectStore.setLoadingProgress(true)
                              projectStore.setGpsMode('first_person')
                              projectStore.setSkyColor('camera')
                              projectStore.setArToolTip('camera')
                              projectStore.setWebglContextAlpha(false)
                              projectStore.setMasking(false)
                            } else if (projectStore.skyColor === 'camera' && !projectStore.isMasking) {
                              //AR with Masking
                              projectStore.setSkyColor('masking')
                              projectStore.setMasking(true)
                              projectStore.setArToolTip('masking')
                            } else {
                              projectStore.setGpsMode('none')
                              projectStore.setSkyColor('none')
                              projectStore.setArToolTip('none')
                              projectStore.setWebglContextAlpha(false)
                              projectStore.setMasking(false)
                              //commonStore.setManualGPS(0)
                              //commonStore.setManualOrientationState(0)
                              commonStore.setGPSUpdate(true)
                              window.location.reload();
                            }
                          }
                        }}
                      /> :
                      projectStore.currentUserRoleInProject && !checkingFeatureRole('feature_ar_3d') && checkingFeatureRole('feature_ar_camera') ?
                        <Button
                          icon={
                            <SVGIcon content={secondPersonModeIcons[projectStore.skyColor].icon}
                              width={32} height={32} />}
                          className={'no-padding'}
                          onClick={() => {
                            projectStore.setEnableReCenterGPS(false);
                            commonStore.setCameraHeadingPitchRoll({})
                            if (!checkingFeatureRole('feature_ar_3d') && checkingFeatureRole('feature_ar_camera') && !checkingFeatureRole('feature_ar_mask')) {
                              if (projectStore.gpsMode !== 'first_person') {
                                projectStore.setLoadingProgress(true)
                                projectStore.setGpsMode('first_person')
                                projectStore.setSkyColor('masking')
                                projectStore.setArToolTip('masking')
                                projectStore.setWebglContextAlpha(true)
                                projectStore.setMasking(false)
                              } else {
                                projectStore.setGpsMode('none')
                                projectStore.setSkyColor('none')
                                projectStore.setArToolTip('none')
                                projectStore.setWebglContextAlpha(false)
                                projectStore.setMasking(false)
                                window.location.reload();
                              }
                            } else if (!checkingFeatureRole('feature_ar_3d') && checkingFeatureRole('feature_ar_camera') && checkingFeatureRole('feature_ar_mask')) {
                              if (projectStore.gpsMode !== 'first_person') {
                                projectStore.setLoadingProgress(true)
                                projectStore.setGpsMode('first_person')
                                projectStore.setSkyColor('camera')
                                projectStore.setArToolTip('camera')
                                projectStore.setWebglContextAlpha(true)
                                projectStore.setMasking(false)
                              } else if (projectStore.skyColor === 'camera' && !projectStore.isMasking) {
                                //AR with Masking
                                projectStore.setSkyColor('masking')
                                projectStore.setMasking(true)
                                projectStore.setArToolTip('masking')
                              } else {
                                projectStore.setGpsMode('none')
                                projectStore.setSkyColor('none')
                                projectStore.setArToolTip('none')
                                projectStore.setWebglContextAlpha(false)
                                projectStore.setMasking(false)
                                window.location.reload();
                              }
                            }
                          }}
                        /> :
                        projectStore.currentUserRoleInProject && !checkingFeatureRole('feature_ar_3d') && !checkingFeatureRole('feature_ar_camera') && checkingFeatureRole('feature_ar_mask') ?
                          <Button
                            icon={
                              <SVGIcon content={thirdPersonModeIcons[projectStore.skyColor].icon}
                                width={32} height={32} />}
                            className={'no-padding'}
                            onClick={() => {
                              commonStore.setCameraHeadingPitchRoll({})
                              if (projectStore.gpsMode !== 'first_person') {
                                projectStore.setLoadingProgress(true)
                                projectStore.setGpsMode('first_person')
                                projectStore.setSkyColor('masking')
                                projectStore.setMasking(true)
                                projectStore.setArToolTip('masking')
                              } else {
                                projectStore.setGpsMode('none')
                                projectStore.setSkyColor('none')
                                projectStore.setArToolTip('none')
                                projectStore.setWebglContextAlpha(false)
                                projectStore.setMasking(false)
                                window.location.reload();
                              }
                            }}
                          /> : ''
                  }
                </Popover>
              </Tooltip>
            </ToolbarBottomItem>
            <ToolbarBottomItem activated={projectStore.gpsMode != 'none' && projectStore.skyColor === 'none'}>
              <Tooltip
                placement={'right'}
                title={
                  t((checkingFeatureRole('feature_gps') && checkingFeatureRole('feature_gps_compass')) ?
                    gpsModeIcons[projectStore.gpsMode]?.title
                    : (checkingFeatureRole('feature_gps') && !checkingFeatureRole('feature_gps_compass')) ?
                      secondGpsModeIcons[projectStore.gpsMode]?.title : thirdGpsModeIcons[projectStore.gpsMode]?.title)
                }
                overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined} zIndex={9998} trigger={['click', 'hover']}>
                {
                  projectStore.currentUserRoleInProject && (checkingFeatureRole('feature_gps') || checkingFeatureRole('feature_gps_compass')) &&
                  <Button
                    icon={<SVGIcon content={
                      (checkingFeatureRole('feature_gps') && checkingFeatureRole('feature_gps_compass')) ?
                        gpsModeIcons[projectStore.gpsMode]?.icon
                        : (checkingFeatureRole('feature_gps') && !checkingFeatureRole('feature_gps_compass')) ?
                          secondGpsModeIcons[projectStore.gpsMode]?.icon : thirdGpsModeIcons[projectStore.gpsMode]?.icon
                    } width={32} height={32} />}
                    onClick={() => {
                      projectStore.setMasking(false)
                      projectStore.set2D(false)
                      commonStore.setGPSUpdate(true)
                      commonStore.setManualGPS(0)
                      commonStore.setManualOrientationState(0)
                      projectStore.setEnableReCenterGPS(false);
                      projectStore.setCurrentHelpfeature('gps')
                      
                      if (checkingFeatureRole('feature_gps') && checkingFeatureRole('feature_gps_compass')) {
                        if (projectStore.gpsMode === 'none' || projectStore.gpsMode === 'first_person') {
                          projectStore.setGpsMode('free')
                          projectStore.setSkyColor('none')
                          projectStore.setArToolTip('masking')
                          projectStore.setWebglContextAlpha(false)
                          projectStore.setMasking(false)
                        }
                        else if (projectStore.gpsMode === 'free') {
                          projectStore.setGpsMode('fix')
                          projectStore.setSkyColor('none')
                          projectStore.setArToolTip('masking')
                        } else {
                          projectStore.setGpsMode('none')
                          projectStore.setSkyColor('none')
                        }
                      } else if (checkingFeatureRole('feature_gps') && !checkingFeatureRole('feature_gps_compass')) {
                        if (projectStore.gpsMode === 'none' || projectStore.gpsMode === 'first_person') {
                          projectStore.setGpsMode('free')
                          projectStore.setSkyColor('none')
                          projectStore.setArToolTip('masking')
                        } else {
                          projectStore.setGpsMode('none')
                          projectStore.setSkyColor('none')
                        }
                      } else {
                        if (projectStore.gpsMode === 'none') {
                          projectStore.setGpsMode('fix')
                          projectStore.setSkyColor('none')
                          projectStore.setArToolTip('masking')
                        } else {
                          projectStore.setGpsMode('none')
                          projectStore.setSkyColor('none')
                        }
                      }
                    }}
                  />
                }
              </Tooltip>
            </ToolbarBottomItem>
            <ToolbarBottomItem>
              {
                projectStore.currentUserRoleInProject && checkingFeatureRole("feature_2d") && (
                  <Tooltip title={projectStore.is2D == true ? '3D' : '2D'} placement={isDesktopOrLaptop || isPortrait ? "left" : 'top'}
                    overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined} zIndex={9998} trigger={['click', 'hover']}
                  >
                    <Button
                      className='no-padding'
                      onClick={onChange2D3D}
                      icon={<SVGIcon content={projectStore.is2D == true ? <Icon3D className="icon3D" /> : <Icon2D />} width={32} height={32} />} />
                  </Tooltip>
                )
              }
            </ToolbarBottomItem>
            <ToolbarBottomItem>
              {
                projectStore.currentUserRoleInProject && checkingFeatureRole("feature_fullscreen") && (
                  <Tooltip title={fullScreen ? t('exit-full-screen') : t('full-screen')} placement={isDesktopOrLaptop || isPortrait ? "left" : 'top'} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined} zIndex={9998} trigger={['click', 'hover']}>
                    <Button
                      onClick={() => {
                        if (!document.fullscreenElement && !document.webkitFullscreenElement) {
                          if ('requestFullScreen' in document.body) {
                            document.body.requestFullscreen();
                          } else if ('webkitRequestFullscreen' in document.body) {
                            document.body.webkitRequestFullscreen();
                          }
                          setFullScreen(true)
                        } else {
                          if ('exitFullscreen' in document) {
                            document.exitFullscreen();
                          } else if ('webkitExitFullscreen' in document) {
                            document.webkitExitFullscreen();
                          }
                          setFullScreen(false)
                        }
                      }}
                      icon={<SVGIcon content={fullScreen ? <ExitFullscreen /> : <Fullscreen />} width={32} height={32} />} />
                  </Tooltip>
                )
              }
            </ToolbarBottomItem>
          </>
          : ''}
      </ARMaskingToolbarBottomContainer>
    </Fragment>
  )
}

export default withRouter(
  inject(
    'commonStore',
    'projectStore',
    'capturesStore',
    'schedulingStore',
    'adminStore',
    'usersStore',
    'uiStore',
    'polylineCutToolStore',
    'alignmentStore',
    'measureStore',
    'sketchingStore'
  )(observer(ToolbarBottom))
)
