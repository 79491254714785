import { SendOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Spin,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { LoadingSpinner } from '@/components/elements'
import AdminTemplate from '../../../layout/AdminTemplate'
import { ContentAdmin, CustomContentAdminWrapper, HeaderAdmin } from '../style'
import '../style.css'
import { Trans, useTranslation } from 'react-i18next'
const { Content } = Layout
const { TextArea } = Input

const GenericEmailNotification = ({ commonStore, adminStore }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [sendAll, setSendAll] = useState(false)
  const isMobile = useMediaQuery({query: '(max-width: 600px)'})
  useEffect(() => {
    adminStore.clearStatus()
    commonStore.setCurrentPage('admin/generic-email-notification')
    return () => {
      adminStore.setCurrentUser({})
      adminStore.clearStatus()
    }
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
    setIsModalLoading(false)
    setSendAll(false)
    form.resetFields(['email'])
  }

  const handleOk = () => {
    form.validateFields().then(res => {
      setIsModalLoading(true)
      let _param = {
        subject: res.subject,
        email: res.email,
        allUser: sendAll,
        content: res.content,
      }
      adminStore
        .sendGenericEmailNotification(_param)
        .then(resp => {
          if (resp.statusCode === 400) {
            message.error(t(resp.message || 'an-error-occurred'))
            setIsModalLoading(false)
          } else {
            message.success(t('send-generic-email-notification-successfully'))
            setIsModalVisible(false)
          }
        })
        .catch(err => {
          setIsModalLoading(false)
          console.log(err)
        })
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsModalLoading(false)
  }

  const customSpin = (
    <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
  )
  return (
    <AdminTemplate title={t('generic-email-notification')}>
      <Spin spinning={false} indicator={customSpin}>
        <CustomContentAdminWrapper className="edit-user">
          <div style={{  background: '#fff', textAlign: 'center' }}>
            <Layout>
              <Form
                form={form}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <HeaderAdmin style={{backgroundColor: '#f0f2f5'}}> 
                <Content>
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    style={{ padding: '10px ' }}>
                    <Col xs={24} sm={12}>
                      <div style={{ float: 'left', fontSize: 20 }}>
                        {t('email-notification')}
                      </div>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Col span={24} className="control-btn-group">
                        <Button
                          style={{
                            float: isMobile?'left':'right',
                            
                          }}
                          type="danger"
                          onClick={showModal}>
                          <SendOutlined />
                          {t('send')}
                        </Button>
                        <Modal
                          zIndex={9999}
                          title={t('send-email-notification')}
                          visible={isModalVisible}
                          onOk={handleOk}
                          onCancel={handleCancel}
                          maskClosable={false}
                          className="modal-send-email-notification"
                          footer={
                            <div style={{ textAlign: 'right' }}>
                              <Button key="back" onClick={handleCancel}>
                                {t('commons.cancel')}
                              </Button>
                              <Button
                                key="submit"
                                type="primary"
                                loading={isModalLoading}
                                onClick={handleOk}>
                                {t('send')}
                              </Button>
                            </div>
                          }>
                          <Col span={24}>
                            <Form.Item
                              label={t('user')}
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: t('please-input-email-user'),
                                },
                              ]}>
                              <Input type="text" />
                            </Form.Item>
                            <Form.Item
                              name="allUser"
                              rules={[
                                {
                                  required: false,
                                  message: t('please-input-email-user'),
                                },
                              ]}>
                              <Checkbox
                                onChange={e => setSendAll(e.target.checked)}
                                checked={sendAll}>
                                {t('send-all-user')}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Modal>
                      </Col>
                    </Col>
                  </Row>
                </Content>
                </HeaderAdmin>
                <Content>
                  <div style={{ padding: '20px 0', background: '#fff' }}>
                    <Row type="flex">
                      <Col span={24}>
                        <div
                          className="content-left"
                          style={{
                            background: '#fff',
                            padding: '20px 0',
                          }}>
                          <Row gutter={[24, 8]}>
                            <Col span={24}>
                              <Form.Item
                                label={t('subject')}
                                name="subject"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-subject-email'),
                                  },
                                ]}>
                                <Input type="text" />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('content')}
                                name="content"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-content-email'),
                                  },
                                ]}>
                                <TextArea showCount maxLength={1000} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Content>
              </Form>
            </Layout>
          </div>
        </CustomContentAdminWrapper>
      </Spin>
    </AdminTemplate>
  )
}

export default inject(
  'commonStore',
  'adminStore'
)(observer(GenericEmailNotification))
