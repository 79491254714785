import {
  CloseOutlined, ColumnWidthOutlined, QuestionCircleOutlined, SaveOutlined
} from '@ant-design/icons'
import {
  Button, Checkbox, Col,
  Collapse,
  DatePicker, Divider, Drawer, Empty, Image, Input, InputNumber, message,
  Popconfirm, Row, Select, Slider as Slide, Spin, Switch, Tooltip, Tree
} from 'antd'
import en from "antd/lib/date-picker/locale/en_US"
import es from "antd/lib/date-picker/locale/es_ES"
import fi from "antd/lib/date-picker/locale/fi_FI"
import sv from "antd/lib/date-picker/locale/sv_SE"
import vi from "antd/lib/date-picker/locale/vi_VN"
import {
  Cartesian3, Cartographic,
  Math as CesiumMath,
} from 'cesium'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { useCallback, useEffect, useRef, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { Trans, useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { withRouter } from 'react-router-dom'
import uuid from 'uuid'
import { ReactComponent as ElementLibrary } from '@/assets/svgs/elementLibrary.svg'
import { ReactComponent as ResourceNewFolderIcon } from '@/assets/svgs/resourcefolder-b0.svg'
import { SketchRequest } from '@/requests'
import TreeUtils from '@/tree-utils'
import Utils from '@/utils'
import { HelpButton, SVGIcon } from '@/components/elements'
import { getVerticalPointClick } from '@/helper'
import CustomAttributes from '@/components/layout/CustomAttributes/CustomAttributes'
import { EditPosLeftPanel, LocationPanelHeader } from '../CustomStyled'
import { DrawmoreButton, SketchPanelWrapper, TreeDataWrapper, TreeNodeTitle } from './CustomStyled'
import TaskShortcut from '../TaskShortcut'
import FolderShortcut from '../FolderShortcut'
const { TextArea } = Input
const emptyUrl = "https://www.namepros.com/attachments/empty-png.89209/";
const { Panel } = Collapse;

const EditSketchPanel = ({ projectStore, adminStore, usersStore, sketchingStore, match, commonStore, projectSettingStore, viewer, syncLastSessionSettings, projectGanttStore, getCurrent3DViewSetting }) => {
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)
  const [loading, setLoading] = useState(false)
  const [sketchName, setSketchName] = useState('')
  const [sketchDescription, setSketchDescription] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [startString, setStartString] = useState(false)
  const [endDate, setEndDate] = useState(null)
  const [endString, setEndString] = useState(false)
  const [showName, setShowName] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const [treeData, setTreeData] = useState([]);
  const [treeLoading, setTreeLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedNode, setSelectedNode] = useState();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 342px)' })
  const [selectedKeyFilters, setSelectedKeyFilters] = useState([])
  const [selectedNodeFilter, setSelectedNodeFilter] = useState()
  const [expandedKeyFilters, setExpandedKeyFilters] = useState([]);
  const attributeRef = useRef();
  const { Option } = Select;

  const checkingFeatureRole = (type) => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
  }

  useEffect(() => {
    if (sketchingStore.isEndDrawing) {
      if ((sketchingStore.sketchFormVisible && !sketchingStore.multipleMode) || !sketchingStore.drawMode || sketchingStore.currentEditSketch) return
      const myTimeout = setTimeout(handleDrawMultiple, 1000);
    }
  }, [sketchingStore.isEndDrawing])

  useEffect(() => {
    if (sketchingStore.currentEditSketch) {
      if (usersStore.currentUser) {
        const populateData = async () => {
          projectStore.setLoadingProgress(true)
          projectStore.getProjectCustomAttributes(projectStore.projectDetail.id).then(res => {
            const result = res.filter(c => !c.isDeleted)
            const data = { fileLevel: result.filter(x => x.level === "file"), objectLevel: result?.filter(x => x.level === "object")?.length > 0 ? result.filter(x => x.level === "object") : result.filter(x => x.level === "object" && !x.guid) }
            sketchingStore.setObjectCustomAttributes(data)
          }).catch(err => {
            console.log(err)
          })
          const objectId = sketchingStore.currentEditSketch?.id
          projectStore.getModelAttributes(objectId, { project: projectStore.projectDetail.id }).then(res => {
            sketchingStore.setObjectCustomAttributes(res)
            projectStore.setLoadingProgress(false)
          })
            .catch(err => {
              console.log(err)
              projectStore.setLoadingProgress(false)
            })
        }
        populateData()
      }
    }
  }, [sketchingStore.currentEditSketch])

  useEffect(() => {
    if (sketchingStore.sketchFormVisible && !sketchingStore.currentEditSketch) {
      if (usersStore.currentUser) {
        const populateData = async () => {
          projectStore.setLoadingProgress(true)
          projectStore.getProjectCustomAttributes(projectStore.projectDetail.id).then(res => {
            const result = res.filter(c => !c.isDeleted)
            const data = { fileLevel: result.filter(x => x.level === "file"), objectLevel: result?.filter(x => x.level === "object")?.length > 0 ? result.filter(x => x.level === "object") : result.filter(x => x.level === "object" && !x.guid) }
            sketchingStore.setObjectCustomAttributes(data)
            projectStore.setLoadingProgress(false)
          }).catch(err => {
            console.log(err)
            projectStore.setLoadingProgress(false)
          })
        }
        populateData()
        if (getCurrent3DViewSetting) {
          getCurrent3DViewSetting(true)
        }

        // set default for sketch
        let defaulSketch = projectStore.projectDetail?.metadata?.sketchDefault?.find(item => item.userId === projectStore.projectDetail.currentUser._id);
        if (defaulSketch) {
          if (defaulSketch?.typeLibrary) {
            setExpandedKeys(defaulSketch.typeLibrary.checkedKeys)
            setCheckedKeys(defaulSketch.typeLibrary.checkedKeys)
            // setSelectedKeys(sketchingStore.currentEditSketch.typeLibrary.selectedKeys)
            if (defaulSketch.typeLibrary?.checkedKeys) {
              let _checkedKey = defaulSketch.typeLibrary.checkedKeys
              if (Array.isArray(_checkedKey) && _checkedKey.length > 0) {
                setSelectedKeys([_checkedKey[0]])
                if (sketchingStore.sketchLibraryElements?.length > 0) {
                  let data = sketchingStore.sketchLibraryElements
                  let node = TreeUtils.searchTreeNode(data, 'key', _checkedKey[0]);
                  if (node) {
                    setSelectedNode(node)
                  }
                }
              }
            }
          }
          
          const sketchProps = {
            rotation: defaulSketch?.rotation || 0
          };
          
          switch (sketchingStore.sketchMode) {
            case 'point':
              sketchProps.extrudedHeight = defaulSketch?.extrudedHeight || 1;
              sketchProps.width = defaulSketch?.width || 1;
              sketchProps.color = defaulSketch?.color || { color: '#ffffff', alpha: 0.5 };
              break;
            case 'line':
              sketchProps.extrudedHeight = defaulSketch?.extrudedHeight || 0.5;
              sketchProps.width = defaulSketch?.width || 0.5;
              sketchProps.color = defaulSketch?.color || { color: '#ffffff', alpha: 0.5 };
              break;
            case 'area':
              //sketchProps.height = defaulSketch?.height || 0;
              sketchProps.extrudedHeight = defaulSketch?.extrudedHeight || 0;
              sketchProps.color = defaulSketch?.color || { color: '#ffffff', alpha: 0.5 };
              break;
            default:
              break;
          }          
          sketchingStore.setSketchProps(sketchProps);

          setShowName(defaulSketch?.isShowName !== undefined ? defaulSketch.isShowName : false)
          setShowDescription(defaulSketch?.isShowDescription !== undefined ? defaulSketch.isShowDescription : false)
        }
      }
    }
  }, [sketchingStore.sketchFormVisible])

  /**
         * Save new project custom attribute
         * @param {*} data
         */
  const handleAddAttribute = (data) => {
    projectStore.setLoadingProgress(true)
    projectStore.createModelCustomAttributes(data).then(res => {
      sketchingStore.setObjectCustomAttributes([...sketchingStore.objectCustomAttributes, res])
      projectStore.setLoadingProgress(false)
      message.success(t('add-attribute-successfully'))
      sketchingStore.setVisibleModalObjectAtt(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  /**
  * Update project custom attribute
  * @param {*} attribute id, data
  */
  const handleUpdateAttribute = (id, data) => {
    projectStore.setLoadingProgress(true)
    projectStore.updateModelCustomAttributes(id, data).then(res => {
      projectStore.setLoadingProgress(false)
      message.success(t('update-attribute-successfully'))
      let index = sketchingStore.objectCustomAttributes.findIndex(c => c.id === res.id)
      if (index !== -1) {
        let _objectCustomAttributes = [...sketchingStore.objectCustomAttributes]
        _objectCustomAttributes[index] = res
        sketchingStore.setObjectCustomAttributes(_objectCustomAttributes)
      }
      sketchingStore.setVisibleModalObjectAtt(false)
      sketchingStore.setCurrentObjectAttribute(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  /**
  * Delete project custom attribute
  * @param {*} attribute id 
  */
  const handleDeleteAttribute = (id) => {
    projectStore.setLoadingProgress(true)
    projectStore.deleteModelCustomAttributes(id).then(res => {
      projectStore.setLoadingProgress(false)
      message.success(t('delete-attribute-successfully'))
      let index = sketchingStore.objectCustomAttributes.findIndex(c => c.id === res.id)
      if (index !== -1) {
        let _objectCustomAttributes = [...sketchingStore.objectCustomAttributes]
        _objectCustomAttributes.splice(index, 1)
        sketchingStore.setObjectCustomAttributes(_objectCustomAttributes)
      }
      sketchingStore.setVisibleModalObjectAtt(false)
      sketchingStore.setCurrentObjectAttribute(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  /**
  * Update model attribute table
  * @param {*} projectId,payload, customAttributeDatas
  */
  const updateModelAttributes = (data, isHideMessage) => {
    if (projectStore.prjCustomAttributeList?.filter(c => !c.isDeleted)?.length === 0) return
    projectStore.setLoadingProgress(true)
    projectStore.updateModelAttributes(data).then(res => {
      sketchingStore.setObjectCustomAttributes(res)
      projectStore.setLoadingProgress(false)
      if (!isHideMessage) {
        message.success(t('update-attribute-successfully'))
      }
      sketchingStore.setIsEditingModelAttribute(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  const filerHandle = (data, width, extrudedHeight) => {
    const result = [];
    while (data.length > 0) {
      const initialLibrary = data.shift()
      filterSketchLibrary(result, initialLibrary, width, extrudedHeight)
    }
    return result;
  }

  const filterSketchLibrary = (result, data, width, extrudedHeight) => {
    if (data) {
      if (data.type === 'FOLDER') {
        if (data.children && data.children.length > 0) {
          result.push({
            id: data.id,
            key: data.key,
            parentKey: data.parentKey,
            title: data.title,
            type: data.type,
            icon: data.icon,
            disableCheckbox: data.disableCheckbox,
            children: []
          })
          data.children.map((item, k) => filterSketchLibrary(result[result.length - 1].children, item, width, extrudedHeight))
          if ((!result[result.length - 1]?.children) || result[result.length - 1].children <= 0) {
            result.splice(result.length - 1, 1)
          }
        }
      } else {
        let c = checkAvaiableElement(data, width, extrudedHeight)
        if (c) result.push(toJS(data))
      }
    }
    return result
  }

  const checkZeroElement = (item) => {
    let defaultHeight = item.defaultHeight || 0
    let defaultWidth = item.defaultWidth || 0
    let maxHeight = item.maxHeight || 0
    let maxWidth = item.maxWidth || 0
    let minHeight = item.minHeight || 0
    let minWidth = item.minWidth || 0
    return (defaultHeight === 0 && defaultWidth === 0 && maxHeight === 0 && maxWidth === 0 && minHeight === 0 && minWidth === 0) ? true : false
  }
  const checkElement = (item, width, height) => {
    let avaiable = false
    let defaultHeight = item.defaultHeight || 0
    let defaultWidth = item.defaultWidth || 0
    let maxHeight = item.maxHeight || 0
    let maxWidth = item.maxWidth || 0
    let minHeight = item.minHeight || 0
    let minWidth = item.minWidth || 0
    const isHeightZero = defaultHeight === 0 && minHeight === 0 && maxHeight === 0
    const isWidthZero = defaultWidth === 0 && minWidth === 0 && maxWidth === 0
    if (width === 0 && height === 0) {
      return avaiable = true
    } else {
      if (width === 0) {
        if (isHeightZero) {
          return avaiable = true
        } else {
          if (height <= maxHeight && height >= minHeight) {
            return avaiable = true
          }
        }
      }
      else if (height === 0) {
        if (isWidthZero === 0) {
          return avaiable = true
        } else {
          if (width <= maxWidth && width >= minWidth) {
            return avaiable = true
          }
        }
      }
      else {
        if (isWidthZero === 0 && isWidthZero === 0) {
          return avaiable = true
        } else {
          if (isWidthZero === 0) {
            if (height <= maxHeight && height >= minHeight) {
              return avaiable = true
            }
          }
          else if (isHeightZero === 0) {
            if (width <= maxWidth && width >= minWidth) {
              return avaiable = true
            }
          } else {
            if (width <= maxWidth && width >= minWidth && height <= maxHeight && height >= minHeight) {
              return avaiable = true
            }
          }
        }
      }
    }
    return avaiable
  }
  const checkAvaiableElement = (item, width, height) => {
    let avaiable = false
    if (checkZeroElement(item) || checkElement(item, width, height)) {
      avaiable = true
    }
    return avaiable
  }

  const setFilterModeButton = (stt) => {
    sketchingStore.setFilterMode(stt)
    if (!stt) {
      setTreeData(sketchingStore.treeDataFilter)
      setAutoExpandParent(true)
      setSelectedKeys(selectedKeyFilters || [])
      setSelectedNode(selectedNodeFilter)
      setExpandedKeys(expandedKeyFilters || [])
      if (sketchingStore.currentEditSketch.typeLibrary) {
        setCheckedKeys(sketchingStore.currentEditSketch.typeLibrary.checkedKeys)
      } else {
        setExpandedKeys([])
        setCheckedKeys([])
      }
    } else {
      setSelectedNode()
      setCheckedKeys([])
      setSelectedKeys([])
      setExpandedKeys([])
      setSelectedNodeFilter(selectedNode)
      setSelectedKeyFilters(selectedKeys)
      setExpandedKeyFilters(expandedKeys)
    }
  }

  useEffect(() => {
    if (sketchingStore.filterMode) {
      setSelectedNode()
      setSelectedKeys([])
      setCheckedKeys([])
      let _treeData = filerHandle([...sketchingStore.treeDataFilter], sketchingStore.sketch?.width || 0, sketchingStore.sketch?.extrudedHeight || 0)
      setTreeData(_treeData)
    }
  }, [sketchingStore.sketch?.extrudedHeight, sketchingStore.sketch?.width, sketchingStore.filterMode])

  useEffect(() => {
    if (sketchingStore.currentEditSketch) {
      sketchingStore.setDrawMoreSketchMode(false)
      sketchingStore.setSketchMode(sketchingStore.currentEditSketch.type)
      setSketchName(sketchingStore.currentEditSketch.name ? sketchingStore.currentEditSketch.name.trim() : '')
      setSketchDescription(sketchingStore.currentEditSketch.description)
      if (sketchingStore.currentEditSketch.startDate) {
        const localStartDate = moment
          .utc(sketchingStore.currentEditSketch.startDate)
          .local()
          .format()
        setStartDate(moment(localStartDate, 'YYYY-MM-DD hh:mm:ss'))
      }
      if (sketchingStore.currentEditSketch.endDate) {
        const localEndDate = moment
          .utc(sketchingStore.currentEditSketch.endDate)
          .local()
          .format()
        setEndDate(moment(localEndDate, 'YYYY-MM-DD hh:mm:ss'))
      }
      if (sketchingStore.currentEditSketch.typeLibrary) {
        setExpandedKeys(sketchingStore.currentEditSketch.typeLibrary.checkedKeys)
        setCheckedKeys(sketchingStore.currentEditSketch.typeLibrary.checkedKeys)
        // setSelectedKeys(sketchingStore.currentEditSketch.typeLibrary.selectedKeys)
        if (sketchingStore.currentEditSketch?.typeLibrary?.checkedKeys) {
          let _checkedKey = sketchingStore.currentEditSketch.typeLibrary.checkedKeys
          if (Array.isArray(_checkedKey) && _checkedKey.length > 0) {
            setSelectedKeys([_checkedKey[0]])
            if (sketchingStore.sketchLibraryElements?.length > 0) {
              let data = sketchingStore.sketchLibraryElements
              let node = TreeUtils.searchTreeNode(data, 'key', _checkedKey[0]);
              if (node) {
                setSelectedNode(node)
              }
            }
          }
        }
      }
      setStartString(sketchingStore.currentEditSketch.startDate)
      setEndString(sketchingStore.currentEditSketch.endDate)
      sketchingStore.setSketchProps({
        name: sketchingStore.currentEditSketch.name,
        extrudedHeight: sketchingStore.currentEditSketch.extrudedHeight,
        height: sketchingStore.currentEditSketch.height,
        width: sketchingStore.currentEditSketch.width,
        color: sketchingStore.currentEditSketch.color,
        points: sketchingStore.currentEditSketch.positions,
        lineHorizontalPositions: sketchingStore.currentEditSketch.lineHorizontalPositions,
        rotation: sketchingStore.currentEditSketch.rotation || 0,
        readonlyHeight: sketchingStore.currentEditSketch?.readonlyHeight,
        font: sketchingStore.sketch.font ? sketchingStore.sketch.font : '',
        sizeLabel: sketchingStore.sketch.sizeLabel ? sketchingStore.sketch.sizeLabel : 0,
      })
      setShowName(sketchingStore.currentEditSketch.isShowName)
      setShowDescription(sketchingStore.currentEditSketch.isShowDescription ? sketchingStore.currentEditSketch.isShowDescription : false)
      return () => {
        setStartDate(null)
        setEndDate(null)
      }
    }
  }, [sketchingStore.currentEditSketch, sketchingStore.sketchLibraryElements])


  useEffect(() => {
    setSketchName(sketchingStore.sketch.name ? sketchingStore.sketch.name.trim() : '')
    let fetchData = async () => {
      setTreeLoading(true)
      await sketchingStore.mergeSketchLibraryElement(projectStore.projectDetail.id).then(res => {
        setTreeLoading(false)
        if (res.data) {
          const pTree = traversalTree(res.data, null);
          setTreeData(pTree)
          sketchingStore.setTreeDataFilter(pTree)
        }
      })
        .catch(err => {
          setTreeLoading(false)
        })
    }
    fetchData()
    return () => {
      sketchingStore.setDrawMore(false)
      sketchingStore.setSketchProps({
        readonlyHeight: false
      })
      sketchingStore.setFilterMode(false)
      sketchingStore.setTreeDataFilter(false)
      resetGlobeBehind()
      sketchingStore.setObjectCustomAttributes([])
      // clear gantt selected
      sketchingStore.setListGanttNodeSaveSketch([])
      sketchingStore.setListOriginalGanttNodeSaveSketch([])
    }
  }, [])

  const onClickSketchTool = modeId => {
    sketchingStore.setSketchMode(modeId)
    sketchingStore.setDrawMode(true)
  }

  const setMultipleMode = state => {
    if (state) {
      sketchingStore.setMultipleMode(true)
      if (!sketchingStore.currentEditSketch) {
        handleDrawMultiple(true)
      } else {
        onClickSketchTool(sketchingStore.sketchMode)
        resetDrawMultipleSketchProps()
        setSketchName(Utils.generateSketchName(sketchName))
      }
      clearDrawMultiple()
    } else {
      sketchingStore.setMultipleMode(state)
      onCancel()
      resetDrawMultipleSketchProps()
    }
  }

  const onChangeStartDate = (date, dateString) => {
    setStartDate(date)
    setStartString(dateString)
  }

  const onChangeEndDate = (date, dateString) => {
    setEndDate(date)
    setEndString(dateString)
  }


  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  const isSelectedFolder = (node) => {
    return node && (!node.type || node.type !== 'FOLDER')
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedNode()
    if (selectedKeysValue.length > 0 && isSelectedFolder(info.node)) {
      setSelectedNode(info.node)
    }
    setSelectedKeys(selectedKeysValue);
  };


  const traversalTree = (data, parentKey) => {
    return data.map(item => {
      if (item.children) {
        return {
          ...item,
          icon: ({ expanded }) => setNodeIcon(data, item, expanded),
          children: traversalTree(item.children, item.key),
          disableCheckbox: isFolder(item),
          parentKey,
          id: item.id ? item.id : uuid()

        };
      }
      return {
        ...item,
        icon: ({ expanded }) => setNodeIcon(data, item, expanded),
        parentKey,
        disableCheckbox: isFolder(item),
        id: item.id ? item.id : uuid()

      };
    });
  }
  const isFolder = (node) => {
    return node && node.type === 'FOLDER';
  }

  const isSketchType = (node) => {
    return node && node.model;
  }


  const setNodeIcon = (data, item, expanded) => {
    if (isFolder(item)) {
      return (
        <>
          <SVGIcon
            content={<ResourceNewFolderIcon />}
            width={20}
            height={20}
          />{' '}
        </>
      )
    }

    if (isSketchType(item)) {
      return (
        <>
          <SVGIcon
            content={<ElementLibrary />}
            width={20}
            height={20}
          />{' '}
        </>
      )
    }
  }

  const titleRender = (nodeData) => {
    return <TreeNodeTitle >{nodeData.title}</TreeNodeTitle>
  }

  const checkNull = (_points) => {
    return _points && _points.length > 0 ? _points.some(_point => _point === null || _point === undefined) : true
  }

  const handleDrawMultiple = (drawNew) => {
    projectStore.setLoadingProgress(false);
    if (endString && startString) {
      if (moment(endString).isBefore(startString)) {
        alert(t('you-have-to-set-start-date-before-end-date'))
        return
      }
    }

    let typeLibrary = {
      expandedKeys,
      checkedKeys,
      selectedKeys
    }
    if (checkNull(sketchingStore.sketch.points)) {
      sketchingStore.setMultipleMode(false)
      onCancel()
      return
    }
    projectStore.setLoadingProgress(true);

    if (!sketchingStore.currentEditSketch) {
      const data = {
        name: sketchName,
        description: sketchDescription,
        font: sketchingStore.sketch.font ? sketchingStore.sketch.font : '',
        startDate: startString ? startString : '',
        sizeLabel: sketchingStore.sketch.sizeLabel ? sketchingStore.sketch.sizeLabel : 0,
        endDate: endString ? endString : '',
        type: sketchingStore.sketchMode,
        height: sketchingStore.sketch.height,
        extrudedHeight: sketchingStore.sketch.extrudedHeight ? sketchingStore.sketch.extrudedHeight : 0,
        readonlyHeight: sketchingStore.sketch.readonlyHeight,
        width: sketchingStore.sketch.width ? sketchingStore.sketch.width : 0,
        color: sketchingStore.sketch.color,
        positions: sketchingStore.sketch.points,
        project: match.params.projectId,
        isShowName: sketchingStore.sketchMode === 'label' ? true : showName,
        isShowDescription: showDescription,
        lineHorizontalPositions: sketchingStore.sketch.lineHorizontalPositions,
        rotation: sketchingStore.sketch.rotation,
        typeLibrary,
        customAttributes: sketchingStore.objectCustomAttributes || [],
        ganttData: sketchingStore.listGanttNodeSaveSketch || [],
      }
      SketchRequest.createSketch(data)
        .then(async res => {
          message.success(t('sketch-saved-you-can-draw-next-sketch'))
          projectStore.getProjectCustomAttributes(projectStore.projectDetail.id).then(res => {
            const result = res.filter(c => !c.isDeleted)
            const data = { fileLevel: result.filter(x => x.level === "file"), objectLevel: result?.filter(x => x.level === "object")?.length > 0 ? result.filter(x => x.level === "object") : result.filter(x => x.level === "object" && !x.guid) }
            sketchingStore.setObjectCustomAttributes(data)
          })
          setSketchName(Utils.generateSketchName(sketchName))
          if (res.data) {
            const newSketch = res.data
            // Add to tree
            const newNode = {
              title: newSketch.name,
              key: uuid(),
              type: 'FILE',
              sketchId: newSketch.id,
              sketchType: newSketch.type,
              readonlyHeight: newSketch.readonlyHeight,
              endDate: newSketch.endDate,
              startDate: newSketch.startDate
            }
            await addTreeNode(newNode, projectStore.projectDetail.treeData, sketchingStore.keyTreeNodeSaveSketch)
            // End Add to tree
            sketchingStore.setSketches([...sketchingStore.arrSketches, newSketch])
            if (attributeRef.current) {
              const dataObjectAttribute = await attributeRef.current.saveObjectAttribute({
                modelId: newSketch.id,
                objectType: 'sketch',
              });
              const attribute = JSON.parse(JSON.stringify(dataObjectAttribute));
              await updateModelAttributes(attribute, true);
            }
            projectStore.setPrevious3DViewSetting({
              ...projectStore.previous3DViewSetting,
              visibleSketches: [
                ...projectStore.previous3DViewSetting?.visibleSketches,
                {
                  isVisible: true,
                  sketchId: res?.data?.id,
                },
              ],
            });
          }
          projectStore.setLoadingProgress(false);
          // resetDrawMultipleSketchProps()
          onClickSketchTool(sketchingStore.sketchMode)
          if (drawNew) {
            setSketchName(Utils.generateSketchName(sketchName))
            // sketchingStore.setSketchProps({ points: [] })
          }

          // Check if open sketch draw from gantt lightbox or click sketch on toolbar gantt
          if (projectGanttStore.sketchDrawer.open || projectGanttStore.sketchDrawer.drawSketchType) {
            const newSketchId = res?.data?.id;
            if (newSketchId) {
              projectGanttStore.setSketchDrawer({
                ...projectGanttStore.sketchDrawer,
                sketchIds: [...projectGanttStore.sketchDrawer.sketchIds, res?.data?.id],
              });
            }
          }
        })
        .catch(err => {
          console.log(err)
          message.error(t(err))
          // resetDrawMultipleSketchProps()
          projectStore.setLoadingProgress(false);
        })
      sketchingStore.setRemovingShape(true)
      sketchingStore.setDrawMode(false)
      sketchingStore.setEndDrawing(false)

      // save sketch default value
      const _defaultSketch = {
        userId: projectStore.projectDetail.currentUser._id,
        isShowName: data?.isShowName,
        isShowDescription: data?.isShowDescription,
        height: data?.height,
        extrudedHeight: data?.extrudedHeight,
        width: data?.width,
        rotation: data?.rotation,
        color: data?.color,
        typeLibrary: data?.typeLibrary,
        customAttributes: data?.customAttributes,
        keyFolderAddSketchDefault: sketchingStore.keyTreeNodeSaveSketch || '',
        keyTaskAddSketchDefault: sketchingStore.listGanttNodeSaveSketch || []
      }
      let _projectMetadata = projectStore.projectDetail.metadata
      if (_projectMetadata && _projectMetadata.sketchDefault && _projectMetadata.sketchDefault.length > 0) {
        let index = projectStore.projectDetail.metadata.sketchDefault.findIndex(item => item.userId === projectStore.projectDetail.currentUser._id)
        if (index > -1) {
          const temp = [..._projectMetadata.sketchDefault]
          temp[index] = _defaultSketch
          _projectMetadata.sketchDefault = temp
        } else {
          let _data = _defaultSketch
          _projectMetadata.sketchDefault.push(_data)
        }
      } else {
        _projectMetadata.sketchDefault = [_defaultSketch]
      }
      projectStore.updateProjectMetadata({ metadata: _projectMetadata }).then((res) => {
        projectStore.projectDetail.metadata = res.metadata
      }).catch(err => {
        console.log(err)
      });
    }
  }

  const resetGlobeBehind = () => {
    projectStore.setGlobeBehind(projectSettingStore.systemProjectSetting.globeBehind)
  }

  const getNewPosition = (newpointDragger, sketch) => {
    let cartesians = newpointDragger.map(
      position => new Cartesian3(position.x, position.y, position.z)
    )
    let _refcartographic = Cartographic.fromCartesian(cartesians[0])
    _refcartographic.height += sketch.extrudedHeight / 2

    let _positionEntity = Cartesian3.fromDegrees(_refcartographic.longitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.latitude * CesiumMath.DEGREES_PER_RADIAN, _refcartographic.height)
    return _positionEntity
  }

  const getOldPosition = (newpointDragger, sketch) => {
    let cartesians = newpointDragger.map(
      position => new Cartesian3(position.x, position.y, position.z)
    )
    let point0 = Cartographic.fromCartesian(cartesians[0].clone())
    let verticalP1P2 = 0
    if (cartesians.length > 1) {
      if (cartesians[0].z > cartesians[1].z) {
        let point1 = cartesians[0]
        let point2 = cartesians[1] || cartesians[0]
        let vertical = getVerticalPointClick(point1, point2)
        verticalP1P2 = vertical
        point0.height = point0.height - vertical + (sketch.extrudedHeight / 2)
      } else {
        point0.height += sketch.extrudedHeight / 2
      }
    } else {
      point0.height += sketch.extrudedHeight / 2
    }
    let _positionEntity = Cartesian3.fromDegrees(point0.longitude * CesiumMath.DEGREES_PER_RADIAN, point0.latitude * CesiumMath.DEGREES_PER_RADIAN, point0.height)
    return _positionEntity
  }

  const onSave = async () => {
    if (endString && startString) {
      if (moment(endString).isBefore(startString)) {
        alert(t('you-have-to-set-start-date-before-end-date'))
        return
      }
    }
    let typeLibrary = {
      expandedKeys,
      checkedKeys,
      selectedKeys
    }
    let data
    if (!sketchingStore.currentEditSketch) {
      data = {
        name: sketchName,
        description: sketchDescription,
        font: sketchingStore.sketch.font ? sketchingStore.sketch.font : '',
        startDate: startDate ? startDate : '',
        sizeLabel: sketchingStore.sketch.sizeLabel ? sketchingStore.sketch.sizeLabel : 0,
        endDate: endDate ? endDate : '',
        type: sketchingStore.sketchMode,
        height: sketchingStore.sketch.height,
        extrudedHeight: sketchingStore.sketch.extrudedHeight ? sketchingStore.sketch.extrudedHeight : 0,
        readonlyHeight: sketchingStore.sketch.readonlyHeight,
        width: sketchingStore.sketch.width ? sketchingStore.sketch.width : 0,
        color: sketchingStore.sketch.color,
        positions: sketchingStore.sketch.points,
        project: match.params.projectId,
        isShowName: sketchingStore.sketchMode === 'label' ? true : showName,
        isShowDescription: showDescription,
        lineHorizontalPositions: sketchingStore.sketch.lineHorizontalPositions,
        rotation: sketchingStore.sketch.rotation,
        typeLibrary,
        customAttributes: sketchingStore.objectCustomAttributes || [],
        ganttData: sketchingStore.listGanttNodeSaveSketch || [],
      }
      setLoading(true)
      SketchRequest.createSketch(data)
        .then(async res => {
          message.success(t('sketch-saved'))
          if (res.data) {
            const newSketch = res.data
            // Add to tree
            const newNode = {
              title: newSketch.name,
              key: uuid(),
              type: 'FILE',
              sketchId: newSketch.id,
              sketchType: newSketch.type,
              readonlyHeight: newSketch.readonlyHeight,
              endDate: newSketch.endDate,
              startDate: newSketch.startDate
            }
            await addTreeNode(newNode, projectStore.projectDetail.treeData, sketchingStore.keyTreeNodeSaveSketch)
            // End Add to tree
            sketchingStore.setSketches([...sketchingStore.arrSketches, newSketch])
            if (attributeRef.current) {
              const dataObjectAttribute = await attributeRef.current.saveObjectAttribute({
                modelId: newSketch.id,
                objectType: 'sketch',
              });
              const attribute = JSON.parse(JSON.stringify(dataObjectAttribute));
              await updateModelAttributes(attribute, true);
            }
            // End Add to tree
          }
          sketchingStore.setSketchFormVisible(false)
          sketchingStore.setObjectCustomAttributes([])
          resetSketchProps()
          resetGlobeBehind()
          // gotoUserViewpoint(projectStore.previousCameraPosition, usersStore.currentUser?._id, viewer) //Go to viewpoint before open form edit model 
          projectStore.setPrevious3DViewSetting({
            ...projectStore.previous3DViewSetting,
            visibleSketches: [
              ...projectStore.previous3DViewSetting?.visibleSketches,
              {
                isVisible: true,
                sketchId: res?.data?.id,
              },
            ],
          });

          syncLastSessionSettings({
            ...projectStore.previous3DViewSetting,
            visibleSketches: [
              ...projectStore.previous3DViewSetting?.visibleSketches,
              {
                isVisible: true,
                sketchId: res?.data?.id,
              },
            ],
          })

          // Check if open sketch draw from gantt lightbox or click sketch on toolbar gantt
          if (projectGanttStore.sketchDrawer.open || projectGanttStore.sketchDrawer.drawSketchType) {
            projectGanttStore.setSketchDrawer({ ...projectGanttStore.sketchDrawer, sketchIds: [res?.data?.id] })
            projectGanttStore.setIsShowGanttPanel(true)
          }
        })
        .catch(err => {
          console.log(err)
          message.error(t(err))
          sketchingStore.setSketchFormVisible(false)
          resetSketchProps()
          resetGlobeBehind()
        })
      sketchingStore.setRemovingShape(true)
      sketchingStore.setDrawMode(false)
      sketchingStore.setEndDrawing(false)
    } else {

      function calculateChanges(oldArray, newArray) {
        const changes = [];
        // Find items to delete (present in oldArray but not in newArray)
        oldArray.forEach(id => {
          if (!newArray.includes(id)) {
            changes.push({ id, action: 'delete' });
          }
        });
      
        // Find items to create (present in newArray but not in oldArray)
        newArray.forEach(id => {
          if (!oldArray.includes(id)) {
            changes.push({ id, action: 'create' });
          }
        });
      
        return changes;
      }

      data = {
        name: sketchName,
        description: sketchDescription,
        font: sketchingStore.sketch.font ? sketchingStore.sketch.font : '',
        startDate: startDate ? startDate : '',
        sizeLabel: sketchingStore.sketch.sizeLabel ? sketchingStore.sketch.sizeLabel : 0,
        endDate: endDate ? endDate : '',
        height: sketchingStore.sketch.height,
        extrudedHeight: sketchingStore.sketch.extrudedHeight ? sketchingStore.sketch.extrudedHeight : 0,
        width: sketchingStore.sketch.width ? sketchingStore.sketch.width : 0,
        color: sketchingStore.sketch.color,
        isShowName: sketchingStore.sketchMode === 'label' ? true : showName,
        isShowDescription: showDescription,
        lineHorizontalPositions: sketchingStore.sketch.width === 0 ? [] : sketchingStore.sketch.lineHorizontalPositions,
        rotation: sketchingStore.sketch.rotation,
        readonlyHeight: sketchingStore.sketch.readonlyHeight,
        typeLibrary,
        ganttData: calculateChanges(sketchingStore.listOriginalGanttNodeSaveSketch || [], sketchingStore.listGanttNodeSaveSketch || []),
      }
      if (sketchingStore.sketch.pointDragger?.length > 0 && sketchingStore.currentEditSketch.type === "point") {
        let newPoint = getNewPosition(sketchingStore.sketch.pointDragger, sketchingStore.currentEditSketch)
        let oldPoint = getOldPosition(sketchingStore.sketch.points, sketchingStore.currentEditSketch)
        var direction = new Cartesian3();
        let result = Cartesian3.subtract(newPoint, oldPoint, direction)
        let newPositions = sketchingStore.sketch.points.map(elm => {
          let sum = new Cartesian3();
          Cartesian3.add(elm, result, sum)
          return sum
        })
        data.positions = newPositions.map(position => {
          return { x: position.x, y: position.y, z: position.z }
        })
      } else {
        data.positions = sketchingStore.sketch.pointDragger && sketchingStore.sketch.pointDragger?.length > 0 ? toJS(sketchingStore.sketch.pointDragger) : toJS(sketchingStore.sketch.points)
      }
      setLoading(true)
      SketchRequest.updateSketch(sketchingStore.currentEditSketch.id, data)
        .then(async res => {
          setLoading(false)
          message.success(t('sketch-saved'))
          // gotoUserViewpoint(projectStore.previousCameraPosition, usersStore.currentUser?._id, viewer) //Go to viewpoint before open form edit model 
          syncLastSessionSettings(projectStore.previous3DViewSetting)
          if (res.data) {
            // Add to tree
            const newNode = {
              title: res.data.name,
              key: uuid(),
              type: 'FILE',
              sketchId: res.data.id,
              sketchType: res.data.type,
              readonlyHeight: res.data.readonlyHeight,
              endDate: res.data?.endDate,
              startDate: res.data?.startDate
            }
            await addTreeNode(newNode, projectStore.projectDetail.treeData, sketchingStore.keyTreeNodeSaveSketch)
            // End Add to tree
            sketchingStore.setSketches(sketchingStore.arrSketches.map((o) => {
              if (o.id === res.data.id) return res.data
              return o;
            }))
            if (attributeRef.current) {
              const dataObjectAttribute = await attributeRef.current.saveObjectAttribute({
                modelId: sketchingStore.currentEditSketch.id,
                objectType: 'sketch',
              });
              const attribute = JSON.parse(JSON.stringify(dataObjectAttribute));
              await updateModelAttributes(attribute, true);
            }
            sketchingStore.setCheckedKeysModel(checkedKeys);
          }
          sketchingStore.setSketchFormVisible(false)
          onCancel()
          // projectStore.setDisplayPanel(true)
        })
        .catch(err => {
          sketchingStore.setSketchFormVisible(false)
          console.log(err)
          message.error(t(err))
          setLoading(false)
          onCancel()
        })
    }

    // save sketch default value
    const _defaultSketch = {
      userId: projectStore.projectDetail.currentUser._id,
      isShowName: data?.isShowName,
      isShowDescription: data?.isShowDescription,
      height: data?.height,
      extrudedHeight: data?.extrudedHeight,
      width: data?.width,
      rotation: data?.rotation,
      color: data?.color,
      typeLibrary: data?.typeLibrary,
      customAttributes: data?.customAttributes,
      keyFolderAddSketchDefault: sketchingStore.keyTreeNodeSaveSketch || '',
      keyTaskAddSketchDefault: sketchingStore.listGanttNodeSaveSketch || []
    }
    let _projectMetadata = projectStore.projectDetail.metadata
    if (_projectMetadata && _projectMetadata.sketchDefault && _projectMetadata.sketchDefault.length > 0) {
      let index = projectStore.projectDetail.metadata.sketchDefault.findIndex(item => item.userId === projectStore.projectDetail.currentUser._id)
      if (index > -1) {
        const temp = [..._projectMetadata.sketchDefault]
        temp[index] = _defaultSketch
        _projectMetadata.sketchDefault = temp
      } else {
        let _data = _defaultSketch
        _projectMetadata.sketchDefault.push(_data)
      }
    } else {
      _projectMetadata.sketchDefault = [_defaultSketch]
    }
    projectStore.updateProjectMetadata({ metadata: _projectMetadata }).then((res) => {
      projectStore.projectDetail.metadata = res.metadata
    }).catch(err => {
      console.log(err)
    });    
    //===========
  }

  const addTreeNode = async (child, data, keyNodeAdd) => {
    if (data?.length > 0) {
      if (sketchingStore.currentEditSketch && projectStore.sketchEdit) {
        let toNode;
        let isNewLocation = false;
        let isLocationOldRoot = false;
        let parentKey = '';
        if (keyNodeAdd) {
          if (keyNodeAdd !== projectStore.sketchEdit.parentKey) {
            const parrentNodeOld = TreeUtils.searchTreeNode(data, 'key', projectStore.sketchEdit.parentKey);
            if (parrentNodeOld) {
              parrentNodeOld.children = parrentNodeOld?.children?.filter(x => x.key !== projectStore.sketchEdit.key) || null;
            } else {
              isLocationOldRoot = true;
            }
            const parrentNode = TreeUtils.searchTreeNode(data, 'key', keyNodeAdd);
            if (!parrentNode.children) {
              parrentNode.children = [];
            }
            toNode = parrentNode.children;
            isNewLocation = true;
            parentKey = parrentNode.key
          } else {
            const parrentNode = TreeUtils.searchTreeNode(data, 'key', projectStore.sketchEdit.parentKey);
            if (!parrentNode.children) {
              parrentNode.children = [];
            }
            toNode = parrentNode.children;
          }
        } else {
          toNode = data;
        }
        if (!isNewLocation) {
          const node = toNode.find(child => child.key === projectStore.sketchEdit.key);
          node.title = child.title;
          node.startDate = child.startDate;
          node.endDate = child.endDate;
        } else {
          child.parentKey = parentKey;
          toNode.push(child);
          if (isLocationOldRoot) {
            data = data.filter(x => x.key !== projectStore.sketchEdit.key);
          }
        }
      } else {
        let toNode;
        let toData = projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : keyNodeAdd;
        if (!toData) {
          const sketchFolder = TreeUtils.searchSketchFolder(data);
          if (sketchFolder) {
            toData = sketchFolder.key;
          } else {
            child = {
              title: 'Sketch',
              key: uuid(),
              type: 'FOLDER',
              children: [child]
            }
          }
        }
        if (toData) {
          const node = TreeUtils.searchTreeNode(data, 'key', toData);
          const accesscontrols = node?.accesscontrols || []
          if(node){
            node.children = node.children && node.children.length ? node.children : [];
            toNode = node.children;
          }else{
            const sketchFolder = TreeUtils.searchSketchFolder(data);
            if (sketchFolder) {
              toNode = sketchFolder.children && sketchFolder.children.length ? sketchFolder.children : [];
            } else {
              child = {  
                title: 'Sketch',
                key: uuid(),
                type: 'FOLDER',
                children: [child]
              }
              toNode = data;
            }
          } 
          //inherit access right from its parent
          // child.accesscontrols = accesscontrols;
        } else {
          toNode = data;
          child.accesscontrols = [];
        }
        toNode.push(child)
      }
    } else {
      data = []
      let toNode;
      let toData = projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : keyNodeAdd;
      if (!toData) {
        const sketchFolder = TreeUtils.searchSketchFolder(data);
        if (sketchFolder) {
          toData = sketchFolder.key;
        } else {
          child = {
            title: 'Sketch',
            key: uuid(),
            type: 'FOLDER',
            children: [child]
          }
        }
      }
      if (toData) {
        const node = TreeUtils.searchTreeNode(data, 'key', toData);
        if(node){
          node.children = node?.children && node?.children?.length ? node?.children : [];
          toNode = node.children;
        } else{
          child = {
            title: 'Sketch',
            key: uuid(),
            type: 'FOLDER',
            children: [child]
          };
          toNode = [];
        }
      } else {
        toNode = data;
      }
      toNode.push(child)
      data = toNode
    }
    projectStore.setLoadingProgress(true);
    await projectStore.updateProjectTreeData({ treeData: data, store: 'treedata' }).then(res => {
      projectStore.projectDetail.treeData = res.treeData;
    }).catch(err => {
      console.log(err)
      message.error(t('add-folder-failed'))
    }).finally(() => {
      projectStore.setLoadingProgress(false);
    })
  }

  const closeEditGeometrySketch = () => {

    if (sketchingStore.currentEditEntity?.currentEditEntity?.position && sketchingStore.currentEditSketch.type === "point") {
      sketchingStore.currentEditEntity.currentEditEntity.position.setCallback(sketchingStore.currentEditEntity.currentEditEntity.position._callback, true);
    }

    if (sketchingStore.currentEditEntity?.currentEditEntity?.polygon?.hierarchy && sketchingStore.currentEditSketch.type === "area") {
      sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy.setCallback(sketchingStore.currentEditEntity.currentEditEntity.polygon.hierarchy._callback, true);
    }

    if (sketchingStore.currentEditEntity?.currentEditEntity) {
      // if (sketchingStore.currentEditEntity?.currentEditEntity && sketchingStore.currentEditSketch.type === "line") {
      let entity = sketchingStore.currentEditEntity.currentEditEntity
      if (sketchingStore.currentEditEntity?.currentEditEntity?.polyline?.positions) {
        entity.polyline.positions.setCallback(entity.polyline.positions._callback, true);
      }
      if (sketchingStore.currentEditEntity?.currentEditEntity?.polylineVolume?.positions) {
        entity.polylineVolume.positions.setCallback(entity.polylineVolume.positions._callback, true);
        entity.polylineVolume.shape.setCallback(entity.polylineVolume.shape._callback, true);
      }
      if (sketchingStore.currentEditEntity?.currentEditEntity?.wall?.positions) {
        entity.wall.positions.setCallback(entity.wall.positions._callback, true);
        entity.wall.maximumHeights.setCallback(entity.wall.maximumHeights._callback, true);
        entity.wall.minimumHeights.setCallback(entity.wall.minimumHeights._callback, true);
      }
      if (sketchingStore.currentEditEntity?.currentEditEntity?.corridor?.positions) {
        entity.corridor.positions.setCallback(entity.corridor.positions._callback, true);
      }
    }
    if (sketchingStore?.currentEditEntity?.currentEditEntity?.editor) {
      sketchingStore.currentEditEntity.currentEditEntity.editor.destroy()
      sketchingStore.setCurrentEditEntity(false)
    }
    sketchingStore.setGeometrySketch(false)
    if (sketchingStore.currentEditSketch) {
      sketchingStore.setResetGeometrySketch(sketchingStore.arrSketches.find(sketch => sketch._id === sketchingStore.currentEditSketch?.id))
    }
    sketchingStore.setSketchProps({ pointDragger: [] })
    sketchingStore.setSketchProps({ extrudedHeight: 0 })
  }

  const onCancel = useCallback(() => {
    sketchingStore.setResetSketch(sketchingStore.currentSketchId)
    closeEditGeometrySketch()
    sketchingStore.setObjectCustomAttributes([])
    sketchingStore.setSketchFormVisible(false)
    sketchingStore.setRemovingShape(true)
    sketchingStore.setDrawMode(false)
    sketchingStore.setEndDrawing(false)
    sketchingStore.setCurrentEditSketch(false)
    sketchingStore.setCurrentSketchId(false)
    sketchingStore.setSketchMode('')
    sketchingStore.setDrawMoreSketchMode(false)
    sketchingStore.setDrawMore(false)
    sketchingStore.setMultipleMode(false)
    sketchingStore.setCheckedKeysModel(checkedKeys)
    resetGlobeBehind()
    // gotoUserViewpoint(projectStore.previousCameraPosition, usersStore.currentUser?._id, viewer) //Go to viewpoint before open form edit model 
    // projectStore.setDisplayPanel(true)
    syncLastSessionSettings(projectStore.previous3DViewSetting)

    // check if open edit sketch from gantt then reopen gantt drawer
    if (projectGanttStore.sketchDrawer.open || projectGanttStore.sketchDrawer.drawSketchType) {
      projectGanttStore.setIsShowGanttPanel(true)
    }
  }, [projectStore.previous3DViewSetting])

  const clearDrawMultiple = () => {
    projectStore.setViewMode('default mode');
    // sketchingStore.setSketchFormVisible(true)
    sketchingStore.setRemovingShape(true)
    sketchingStore.setEndDrawing(false)

    sketchingStore.setResetSketch(sketchingStore.currentSketchId)
    sketchingStore.setCurrentEditSketch(false)
    sketchingStore.setCurrentSketchId(false)
  }

  const resetDrawMultipleSketchProps = () => {
    sketchingStore.setSketchProps({
      height: 0,
      extrudedHeight: 0.5,
      points: [],
      width: 0.5,
      lineHorizontalPositions: [],
      rotation: 0
    })
    if (sketchingStore.sketchMode === 'point' || (sketchingStore.sketchMode === 'area' && sketchingStore.sketch.readonlyHeight)) {
      sketchingStore.setSketchProps({
        height: 0,
        extrudedHeight: 0,
        width: 0,
      })
    }
    setStartString(false)
    setEndString(false)
  }

  const resetSketchProps = () => {
    sketchingStore.setSketchProps({
      name: '',
      color: { color: '#ffffff', alpha: 1 },
      height: 0,
      extrudedHeight: 0,
      points: [],
      width: 0,
      lineHorizontalPositions: [],
      rotation: 0
    })
  }

  useEffect(() => {
    return () => {
      setLoading(false)
      sketchingStore.setSketchProps({
        points: []
      })
    }
  }, [])
  useEffect(() => {
    if (checkedKeys) {
      sketchingStore.setCheckedKeysModel(checkedKeys)
    }
  }, [checkedKeys])
  const onChangeColorAlpha = (value) => {
    sketchingStore.setSketchProps({ color: { color: sketchingStore.sketch?.color?.color || '#ffffff', alpha: value } })
  }
  const HeaderPanel = ({ text }) => (
    <LocationPanelHeader>{text}</LocationPanelHeader>
  )

  const isTabletOrMobileDevice = useMediaQuery({
      query: '(max-device-width: 1224px)',
    })
    const [drawerWidth, setDrawerWidth] = useState(
      isTabletOrMobileDevice ? 410 : 500
    )
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    //#region event for mouse and touche split panel
    const handleTouchStart = e => {
      e.preventDefault()
      if (e.touches.length !== 1) return null
      document.addEventListener('touchmove', handleTouchMove, { passive: false })
      document.addEventListener('touchend', handleTouchEnd, { passive: false })
      document.addEventListener('touchcancel', handleTouchEnd, { passive: false })
    }
  
    const handleTouchMove = useCallback(e => {
      const touch = e.touches[0] || e.changedTouches[0]
      const target = document.elementFromPoint(touch.clientX, touch.clientY)
      const newWidth =
        touch.clientX - (target && target.offsetRight ? target.offsetRight : 0)
      const minDrawerWidth = 360
      if (newWidth < 0) {
        setDrawerWidth(10)
      }
      if (newWidth > minDrawerWidth) {
        setDrawerWidth(newWidth)
      }
      if (newWidth > window.innerWidth) {
        setDrawerWidth(window.innerWidth)
      }
    }, [])
  
    const handleTouchEnd = () => {
      document.removeEventListener('touchend', handleTouchEnd, { passive: false })
      document.removeEventListener('touchcancel', handleTouchEnd, {
        passive: false,
      })
      document.removeEventListener('touchmove', handleTouchMove, {
        passive: false,
      })
    }
  
    const handleMouseDown = e => {
      e.preventDefault()
      document.addEventListener('mouseup', handleMouseUp, true)
      document.addEventListener('mousemove', handleMouseMove, true)
    }
  
    const handleMouseUp = () => {
      document.removeEventListener('mouseup', handleMouseUp, true)
      document.removeEventListener('mousemove', handleMouseMove, true)
    }
  
    const handleMouseMove = useCallback(e => {
      let offsetRight = e.clientX - document.body.offsetLeft
      let minDrawerWidth = 410
      if (offsetRight > minDrawerWidth) {
        setDrawerWidth(offsetRight)
      }
      if (offsetRight > window.innerWidth) {
        setDrawerWidth(window.innerWidth)
      }
    }, [])
    //#endregion
  
    const handleChangeScreenSize = () => {
      setScreenWidth(window.innerWidth)
    }
  
    window.addEventListener('resize', handleChangeScreenSize)
  
    useEffect(() => {
      if (drawerWidth > screenWidth) {
        setDrawerWidth(screenWidth)
      }
    }, [screenWidth])

    useEffect(() => {
      if(sketchingStore.sketchFormVisible){
        commonStore.setStickyLogoLeftDistance(drawerWidth)
      }
    }, [drawerWidth, sketchingStore.sketchFormVisible])

  return (
    <Drawer
      className={
        sketchingStore.sketchFormVisible
          ? 'project-setting-drawer custom-wraper-splitPanel'
          : 'project-setting-drawer'
      }
      bodyStyle={{ padding: '0', overflow: 'hidden' }}
      placement="left"
      width={drawerWidth}
      closable={false}
      visible={sketchingStore.sketchFormVisible}
      maskClosable={false}
      mask={false}
      destroyOnClose={true}
      forceRender>
      <SketchPanelWrapper>
        {isTabletOrMobileDevice ? (
          <div className="splitpanel-mobile-left">
            <Button
              onTouchStart={e => handleTouchStart(e)}
              type="dashed"
              shape="circle"
              className="btnSplitPanel">
              <ColumnWidthOutlined />
            </Button>
          </div>
        ) : (
          <div
            onMouseDown={e => handleMouseDown(e)}
            className="splitpanel-left"
          />
        )}
        <EditPosLeftPanel id="editSketchPanel" visible={true} maxWidth="98%">
          <Button
            // disabled={sketchingStore.drawMore}
            disabled={sketchingStore.multipleMode}
            loading={loading}
            type="primary"
            style={{ marginBottom: 10 }}
            icon={<SaveOutlined />}
            onClick={() => onSave()}>
            {t('commons.save')}
          </Button>
          <Tooltip
            title={t('commons.help')}
            overlayStyle={
              isMobile || isTablet ? { display: 'none' } : undefined
            }>
            <div className="help-btn-outer">
              <HelpButton helppage={'edit_sketch'} hovered={'black'} />
            </div>
          </Tooltip>
          {!sketchingStore.currentEditSketch && (
            <DrawmoreButton>
              <Switch
                onChange={v => setMultipleMode(v)}
                style={{
                  marginBottom: 10,
                  float: 'right',
                  width: 'auto',
                  right: '12px',
                }}
                checkedChildren={t('commons.off')}
                unCheckedChildren={t('commons.multiple')}
                defaultChecked={sketchingStore.multipleMode}
              />
            </DrawmoreButton>
          )}

          <Popconfirm
            disabled={sketchingStore.drawMore}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => onCancel()}
            placement="bottomLeft"
            okButtonProps={{ danger: true }}
            okText={t('commons.ok')}
            cancelText={t('commons.cancel')}
            title={
              <span>
                <Trans
                  i18nKey="are-you-sure-you-want-to-leave"
                  components={[<br />]}
                />
              </span>
            }>
            <Tooltip
              title={t('commons.exit')}
              overlayStyle={
                isMobile || isTablet ? { display: 'none' } : undefined
              }>
              <Button
                disabled={sketchingStore.drawMore}
                type="default"
                style={{ marginBottom: 10, marginLeft: 5 }}
                shape="circle"
                icon={<CloseOutlined />}></Button>
            </Tooltip>
          </Popconfirm>
          <>
            <Row>
              <Col span={8}>{t('name')}</Col>
              <Col span={16}>
                <Input
                  maxLength={100}
                  style={{ width: '100%' }}
                  onChange={e => setSketchName(e.target.value)}
                  value={sketchName}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <Col span={8}>{t('description')}</Col>
              <Col span={16}>
                <TextArea
                  row={4}
                  style={{ width: '100%' }}
                  onChange={e => setSketchDescription(e.target.value)}
                  value={sketchDescription}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <Col span={8}>{t('show')}</Col>
              <Col span={16}>
                {sketchingStore.sketchMode === 'label' ? (
                  ''
                ) : (
                  <Checkbox
                    onChange={e => setShowName(e.target.checked)}
                    checked={showName}>
                    {t('name')}
                  </Checkbox>
                )}
                <Checkbox
                  onChange={e => setShowDescription(e.target.checked)}
                  checked={showDescription}>
                  {t('description')}
                </Checkbox>
              </Col>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <Col span={8} style={{ paddingTop: 10 }}>
                {t('start-date')}
              </Col>
              <Col span={16}>
                <DatePicker
                  locale={{
                    ...(commonStore.language === 'fi'
                      ? fi
                      : commonStore.language === 'sv'
                      ? sv
                      : commonStore.language === 'es'
                      ? es
                      : commonStore.language === 'vi'
                      ? vi
                      : en),
                    lang: {
                      ...(commonStore.language === 'fi'
                        ? fi
                        : commonStore.language === 'sv'
                        ? sv
                        : commonStore.language === 'es'
                        ? es
                        : commonStore.language === 'vi'
                        ? vi
                        : en
                      ).lang,
                      now: t('now'),
                      ok: t('commons.ok'),
                    },
                  }}
                  style={{ width: '100%' }}
                  showTime
                  // inputReadOnly={true}
                  placeholder={t('select-time')}
                  onChange={onChangeStartDate}
                  value={startDate}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8} style={{ paddingTop: 10 }}>
                {t('end-date')}
              </Col>
              <Col span={16} style={{ paddingTop: 10 }}>
                <DatePicker
                  locale={{
                    ...(commonStore.language === 'fi'
                      ? fi
                      : commonStore.language === 'sv'
                      ? sv
                      : commonStore.language === 'es'
                      ? es
                      : commonStore.language === 'vi'
                      ? vi
                      : en),
                    lang: {
                      ...(commonStore.language === 'fi'
                        ? fi
                        : commonStore.language === 'sv'
                        ? sv
                        : commonStore.language === 'es'
                        ? es
                        : commonStore.language === 'vi'
                        ? vi
                        : en
                      ).lang,
                      now: t('now'),
                      ok: t('commons.ok'),
                    },
                  }}
                  style={{ width: '100%' }}
                  showTime
                  // inputReadOnly={true}
                  placeholder={t('select-time')}
                  onChange={onChangeEndDate}
                  value={endDate}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <Col span={8}>{t('color')}</Col>
              <Col span={16} style={{ display: 'flex' }}>
                <input
                  type="color"
                  value={sketchingStore.sketch?.color?.color || '#ffffff'}
                  onChange={e =>
                    sketchingStore.setSketchProps({
                      color: {
                        color: e.target.value,
                        alpha: sketchingStore.sketch?.color?.alpha,
                      },
                    })
                  }
                />
                <Slide
                  style={{ width: '100%', margin: '5px 5px 5px 15px' }}
                  defaultValue={0.5}
                  value={sketchingStore.sketch?.color?.alpha ?? 0.5}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={val => {
                    onChangeColorAlpha(val)
                  }}
                />
              </Col>
            </Row>
            {sketchingStore.sketchMode === 'label' ? (
              <Row style={{ paddingTop: 8 }}>
                <Col span={8}>{t('Font')}</Col>
                <Col span={16} style={{ display: 'flex' }}>
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    placeholder={sketchingStore.sketch.font}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onChange={value =>
                      sketchingStore.setSketchProps({ font: value })
                    }
                    notFoundContent={null}>
                    <Option value="arial">Arial</Option>
                    <Option value="calibri">Calibri</Option>
                    <Option value="helvetica">Helvetica</Option>
                    <Option value="times">Times</Option>
                    <Option value="monospace">Monospace</Option>
                  </Select>
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row style={{ paddingTop: 8 }}>
              {sketchingStore.sketchMode !== 'label' ? (
                <Col span={8}>
                  <Col style={{ paddingBottom: 8 }}>{t('height')}</Col>
                  <Col>
                    <InputNumber
                      //min={0}
                      max={1000000}
                      step={0.1}
                      onChange={value => {
                        sketchingStore.setSketchProps({ extrudedHeight: value })
                      }}
                      value={
                        typeof sketchingStore.sketch.extrudedHeight === 'number'
                          ? sketchingStore.sketch.extrudedHeight
                          : 0
                      }
                    />
                  </Col>
                </Col>
              ) : (
                <Col span={8}>
                  <Col style={{ paddingBottom: 8 }}>{t('Size')}</Col>
                  <Col>
                    <InputNumber
                      //min={0}
                      max={1000000}
                      step={0.1}
                      //change extrudedHeight -> sizeLabel
                      onChange={value =>
                        sketchingStore.setSketchProps({ sizeLabel: value })
                      }
                      value={
                        typeof sketchingStore.sketch.sizeLabel === 'number'
                          ? sketchingStore.sketch.sizeLabel
                          : 0
                      }
                    />
                  </Col>
                </Col>
              )}
              {sketchingStore.sketchMode === 'line' ||
              sketchingStore.sketchMode === 'point' ? (
                <Col span={8}>
                  <Col style={{ paddingBottom: 8 }}>{t('width')}</Col>
                  <Col>
                    <InputNumber
                      min={0}
                      max={1000000}
                      step={0.1}
                      onChange={value => {
                        sketchingStore.setSketchProps({ width: value })
                      }}
                      value={
                        typeof sketchingStore.sketch.width === 'number'
                          ? sketchingStore.sketch.width
                          : 0
                      }
                    />
                  </Col>
                </Col>
              ) : (
                ''
              )}
              {!['line', 'area'].includes(sketchingStore.sketchMode) && (
                <Col span={8}>
                  <Col style={{ paddingBottom: 8 }}>{t('rotation')}</Col>
                  <Col>
                    <InputNumber
                      min={-1000000}
                      max={1000000}
                      step={0.1}
                      onChange={value =>
                        sketchingStore.setSketchProps({ rotation: value })
                      }
                      value={
                        typeof sketchingStore.sketch.rotation === 'number'
                          ? sketchingStore.sketch.rotation
                          : 0
                      }
                    />
                  </Col>
                </Col>
              )}
            </Row>
            {sketchingStore.sketchMode === 'label' ? (
              ''
            ) : (
              <Collapse
                defaultActiveKey={[
                  'element-library',
                  'custom-attribute',
                  'tasks-shortcut',
                  'folder-shortcut',
                ]}
                expandIconPosition={'right'}
                style={{ marginTop: 10 }}>
                <Panel
                  header={<HeaderPanel text={t('element-library')} />}
                  key="element-library">
                  <>
                    <Spin tip={t('loading-tree')} spinning={treeLoading}>
                      <Row style={{ paddingTop: 8 }}>
                        <Row
                          justify="space-between"
                          style={{ padding: '0 0 10px 0' }}
                          span={24}>
                          <Col>{t('filter-element')}</Col>
                          <Col>
                            <Switch
                              onChange={v => setFilterModeButton(v)}
                              style={{
                                marginBottom: 10,
                                float: 'right',
                                width: 'auto',
                              }}
                              checkedChildren={t('filter')}
                              unCheckedChildren={t('commons.off')}
                              defaultChecked={sketchingStore.filterMode}
                            />
                          </Col>
                        </Row>
                        {treeData && treeData.length ? (
                          <TreeDataWrapper width="100%">
                            <Tree
                              className="element-library"
                              checkable
                              showIcon
                              blockNode
                              // switcherIcon={<DownOutlined />}
                              treeData={treeData}
                              onSelect={onSelect}
                              expandedKeys={expandedKeys}
                              selectedKeys={selectedKeys}
                              onExpand={onExpand}
                              autoExpandParent={autoExpandParent}
                              onCheck={onCheck}
                              checkedKeys={checkedKeys}
                              titleRender={nodeData => titleRender(nodeData)}
                            />
                          </TreeDataWrapper>
                        ) : (
                          <Row justify="center">
                            <Empty description={<span>{t('no-data')}</span>} />
                          </Row>
                        )}
                      </Row>
                      {selectedNode && (
                        <Row style={{ paddingTop: 8 }}>
                          <Divider style={{ margin: '5px 10px' }} />
                          <Row style={{ width: '100%' }}>
                            <Col
                              style={{ padding: '2px' }}
                              xs={isTabletOrMobile ? 24 : 11}
                              sm={8}>
                              <Image
                                className="selectedNode-img"
                                src={
                                  selectedNode?.image?.url
                                    ? selectedNode.image.url
                                    : emptyUrl
                                }
                              />
                            </Col>
                            <Col
                              style={{ padding: '5px' }}
                              xs={isTabletOrMobile ? 24 : 13}
                              sm={16}>
                              <div className="tile-info">
                                {t('element-info')}
                              </div>
                              <Row>
                                <Tooltip
                                  placement="right"
                                  title={selectedNode.type || t('no-type')}
                                  overlayStyle={
                                    isMobile || isTablet
                                      ? { display: 'none' }
                                      : undefined
                                  }>
                                  <Col span={24}>
                                    {selectedNode.type || t('no-type')}
                                  </Col>
                                </Tooltip>
                              </Row>
                              <Row>
                                <Tooltip
                                  placement="right"
                                  title={selectedNode.title || t('no-name')}
                                  overlayStyle={
                                    isMobile || isTablet
                                      ? { display: 'none' }
                                      : undefined
                                  }>
                                  <Col className="wrapper-content" span={24}>
                                    {selectedNode.title || t('no-name')}
                                  </Col>
                                </Tooltip>
                              </Row>
                              <Row>
                                <Tooltip
                                  placement="right"
                                  title={
                                    selectedNode.description
                                      ? selectedNode.description.trim()
                                      : t('no-description')
                                  }
                                  overlayStyle={
                                    isMobile || isTablet
                                      ? { display: 'none' }
                                      : undefined
                                  }>
                                  <Col className="wrapper-content" span={24}>
                                    {selectedNode.description
                                      ? selectedNode.description.trim()
                                      : t('no-description')}
                                  </Col>
                                </Tooltip>
                              </Row>
                            </Col>
                          </Row>
                        </Row>
                      )}
                    </Spin>
                  </>
                </Panel>
                <Panel header={<HeaderPanel text={t('folder')} />} key="folder-shortcut">
                  <FolderShortcut />
                </Panel>
                <Panel header={<HeaderPanel text={t('tasks')} />} key="tasks-shortcut">
                  <TaskShortcut />
                </Panel>
                {(checkingFeatureRole('feature_custom_attribute_view') ||
                  checkingFeatureRole('feature_custom_attribute_edit')) && (
                  <Panel
                    header={<HeaderPanel text={t('attributes')} />}
                    key="custom-attribute">
                    <>
                      <CustomAttributes
                        place="sketch-editpanel"
                        zIndex={9999}
                        customAttributeDatas={
                          sketchingStore.objectCustomAttributes &&
                          sketchingStore.objectCustomAttributes.fileLevel
                            ? sketchingStore.objectCustomAttributes?.fileLevel?.filter(
                                c => !c.isDeleted
                              )
                            : []
                        }
                        txtEditModal={t('edit-object-custom-attribute')}
                        txtAddMoal={t('add-new-object-custom-attribute')}
                        isProjectCustomAtt={false}
                        handleUpdateAttribute={handleUpdateAttribute}
                        handleAddAttribute={handleAddAttribute}
                        handleDeleteAttribute={handleDeleteAttribute}
                        visibleModal={sketchingStore.visibleModalObjectAtt}
                        setVisibleModalAttribute={e =>
                          sketchingStore.setVisibleModalObjectAtt(e)
                        }
                        setCurrentEditAttribute={e =>
                          sketchingStore.setCurrentObjectAttribute(e)
                        }
                        currentAttribute={sketchingStore.currentObjectAttribute}
                        prjAttributeDatas={projectStore.prjCustomAttributeList.filter(
                          c => !c.isDeleted
                        )}
                        isSketch={true}
                        setIsEditingModelAttribute={e =>
                          sketchingStore.setIsEditingModelAttribute(e)
                        }
                        isEditingModelAttribute={
                          sketchingStore.isEditingModelAttribute
                        }
                        updateModelAttributes={updateModelAttributes}
                        ref={attributeRef}></CustomAttributes>
                    </>
                  </Panel>
                )}
              </Collapse>
            )}
          </>
        </EditPosLeftPanel>
      </SketchPanelWrapper>
    </Drawer>
  )
}

export default withRouter(
  inject('projectStore', 'sketchingStore', 'commonStore', 'projectSettingStore', 'usersStore', 'adminStore', 'projectGanttStore')(observer(EditSketchPanel))
)


