import {
  CloseCircleOutlined,
  CloseOutlined, ColumnHeightOutlined, FileDoneOutlined,
  FilePptOutlined, FullscreenExitOutlined,
  FullscreenOutlined,
  ReloadOutlined,
  SaveOutlined
} from '@ant-design/icons'
import {
  Button, Checkbox, Col, Empty, Form, message, Row, Select,
  Spin,
  Tooltip,
  Input
} from 'antd'
import { toJS } from 'knockout'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import React, { useCallback, useEffect, useState } from 'react'
import { isIOS, isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
import {
  ActionToolBackGround, ActionToolNewTable, CheckBoxRules, DrawerWrapper, ModalAddAttribute, SheetReportWrapper
} from './style'
import SheetReportEditor from './SheetReportEditor'
import uuid from 'uuid';
import * as XLSX from "xlsx"
import axios from 'axios'
import { apiUrl } from '@/config'
import { HelpButton } from '@/components/elements'

function DrawerGenericReport(props) {
  const { t } = useTranslation()
  const { projectStore, commonStore, reportStore } = props
  moment.locale(`${commonStore.language}`)
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)',
  })
  const [drawerHeight, setDrawerHeight] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [screenHeight, setScreenHeight] = useState('0')
  const [exportModal, setExportModal] = useState(false)
  const [folderList, setFolderList] = useState([])
  const [sheetReports, setSheetReports] = useState([])
  const [addReportModal, setAddReportModal] = useState(false)
  const [sheetLoadingPercent, setSheetLoadingPercent] = useState([]);

  let location = useLocation()
  const { projectId } = useParams()
  const [formAddReport] = Form.useForm()
  const [formExportReport] = Form.useForm()

  const fetchFileLevel = async (projectId, element, table) => {
    const REACT_APP_ENDPOINT = apiUrl
    var config = {
      method: 'post',
      url: `${REACT_APP_ENDPOINT}/project/attributereport/${projectId}`,
      headers: {
        Authorization: `Bearer ${commonStore.token}`,
      },
      data: element,
      onDownloadProgress: event => {
        setSheetLoadingPercent(sheetLoadingPercents => [
          ...sheetLoadingPercents,
          { loading: Number(((100 * event.loaded) / event.total).toFixed(2)), index: element.fetchIndex }
        ]);
      }
    }
    const response = await axios(config)
      .then(async f => {
        if (f.status === 200) {
          return f.data
        }
      })
    return response
  }

  const fetchFileObject = async (projectId, object, table) => {
    const REACT_APP_ENDPOINT = apiUrl
    var config = {
      method: 'post',
      url: `${REACT_APP_ENDPOINT}/calculateObjectInfo/${projectId}`,
      headers: {
        Authorization: `Bearer ${commonStore.token}`,
      },
      data: object,
      onDownloadProgress: event => {
        setSheetLoadingPercent(sheetLoadingPercents => [
          ...sheetLoadingPercents,
          { loading: Number(((100 * event.loaded) / event.total).toFixed(2)), index: object.fetchIndex }
        ]);
      }
    }
    const response = await axios(config)
      .then(async f => {
        if (f.status === 200) {
          return f.data
        }
      })
    return response
  }

  const rememberReportSetting = async (promises, reportSettings) => {
    const temp = [...reportSettings]
    const reportDatas = await Promise.all(promises.map(async (element, index) => {
      try {
        let u
        if (element.level === "file") {
          u = await fetchFileLevel(projectStore.projectDetail?.id, element, reportSettings)
        }
        if (element.level === "object") {
          u = await fetchFileObject(projectStore.projectDetail?.id, element, reportSettings)
        }
        temp[element.fetchIndex].sheetdata = u
        temp[element.fetchIndex].isFetching = false
        setSheetReports([...temp])
        return {
          data: u,
          index: index
        }
      }
      catch {
        temp[element.fetchIndex].sheetdata = []
        temp[element.fetchIndex].isFetching = false
        setSheetReports([...temp])
        return {
          data: [],
          index: index
        }
      }
    }))
    return reportDatas
  }

  useEffect(() => {
    if (projectStore.isShowGenericReport) {
      const populateData = async () => {
        reportStore.setLoadingProgress(true)
        await projectStore.getProjectCustomAttributes(projectId)
        await reportStore.getCalculationSettings(projectId).then(async res => {
          const tableSettings = []
          const findIndexRule = (type, element) => {
            const isExist = element?.rules.findIndex(c => c.type === type)
            return isExist
          }
          let reportSetting = res.reportSetting ? res.reportSetting : []
          const reportSettings = reportSetting.map((element, index) => {
            let isFetching = false
            if (element?.rules) {
              // if (element?.rules && element?.rules?.length === 2 && element?.columns?.length > 0 && findIndexRule('folder', element) > -1 && findIndexRule("attribute", element) > -1) {
              const queryParams = {
                rowrules: element.rules,
                columnrules: element.columns,
                level: element?.level,
                fetchIndex: index,
                loading: (element.loaded / element.total) * 100
              }
              isFetching = true;
              tableSettings.push(queryParams)
            }
            return {
              ...element,
              isFetching
            }
          })
          setSheetReports(reportSettings)
          let u = await rememberReportSetting(tableSettings, reportSettings)
          reportStore.setLoadingProgress(false)
        })
        if (projectStore.projectDetail?.treeData) {
          const treeData = projectStore.projectDetail?.treeData || []
          const result = [
            {
              key: "mainfolder",
              title: "All",
              isDeleted: false,
              parentKey: undefined,
              type: "FOLDER",
              children: []
            }
          ]
          const getFolderTreeData = tree => {
            const stack = []
            tree.map(item => stack.push(item))
            while (stack.length > 0) {
              const node = stack.shift()
              if (node.type === 'FOLDER') {
                const u = {
                  key: node.key,
                  parentKey: node.parentKey,
                  title: node.title,
                  type: node.type,
                  isDeleted: node.isDeleted,
                }
                result.push(u)
                if (node.children && node.children) {
                  getFolderTreeData(node.children)
                }
              }
            }
            return result
          }
          const data = getFolderTreeData(treeData)
          setFolderList(data)
        }
      }
      populateData()
    }
  }, [
    projectStore.isShowGenericReport,
    projectStore.projectDetail,
    refresh
  ])

  useEffect(() => {
    if (projectStore.isShowGenericReport) {
      setSheetReports([])
    }
    return () => {
      setFolderList([])
      setRefresh(0)
      projectStore.setSelectedGenericTemplate(false)
      projectStore.setProjectGenericTemplates([])
      reportStore.setShowReportRules(false)
      setAddReportModal(false)
      setExportModal(false)
      setSheetReports([])
    }
  }, [projectStore.isShowGenericReport])

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
  }

  const exportExcel = (nameReport, sheetReports, isOneSheet) => {
    if (isOneSheet) {
      let tableArr = []
      sheetReports.length > 0 &&
        sheetReports.forEach(sheet => {
          let targetTable = document.getElementById(sheet?.key)
          let ws_temp = XLSX.utils.table_to_sheet(targetTable)
          let sheet_to_json = XLSX.utils.sheet_to_json(ws_temp, { header: 1 })
          tableArr.push(sheet_to_json)
        })
      if (tableArr.length > 0) {
        tableArr.forEach((el, index) => {
          if (index === 0) return
          tableArr[0] = tableArr[0].concat(['']).concat(tableArr[index])
        })
        let ws = XLSX.utils.json_to_sheet(tableArr[0], { skipHeader: true })
        const new_wb = XLSX.utils.book_new()
        var string = nameReport;
        var length = 30;
        var trimmedString = string.substring(0, length);
        XLSX.utils.book_append_sheet(new_wb, ws, trimmedString)
        const blob = new Blob([s2ab(XLSX.write(new_wb, {bookType: 'xlsx', type: 'binary'}))], {type: "application/octet-stream"})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = nameReport + '.xlsx'
        document.body.appendChild(link);
        link.click()
        document.body.removeChild(link);
        onCancelExportReport()
      }
    } else {
      const wb = { SheetNames: [], Sheets: {} }
      sheetReports?.length > 0 &&
        sheetReports.map(sheet => {
          let targetTable = document.getElementById(sheet?.key)
          let ws = XLSX.utils.table_to_book(targetTable).Sheets.Sheet1
          wb.SheetNames.push(sheet?.name)
          wb.Sheets[sheet?.name] = ws
        })
      const blob = new Blob([s2ab(XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }))], { type: "application/octet-stream" })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = nameReport + '.xlsx'
      document.body.appendChild(link);
      link.click()
      document.body.removeChild(link);
      onCancelExportReport()
    }
  }

  const handleExportReport = () => {
    formExportReport.validateFields().then(response => {
      exportExcel(response.name, sheetReports, true)
    })
  }

  // event for touche split panel
  const handleTouchStart = e => {
    if (e.touches.length !== 1) return
    if (e.cancelable) {
      e.preventDefault()
    }
    document.addEventListener('touchmove', handleTouchMove, { passive: false })
    document.addEventListener('touchend', handleTouchEnd, { passive: false })
    document.addEventListener('touchcancel', handleTouchEnd, { passive: false })
  }

  const handleRefresh = () => {
    setRefresh(refresh + 1);
    setSheetLoadingPercent([]);
  }

  const showExportModal = () => {
    setExportModal(true)
  }

  const handleTouchMove = useCallback(e => {
    let touch = e.touches[0] || e.changedTouches[0]
    let target = document.elementFromPoint(touch.clientX, touch.clientY)
    let newHeight =
      (document.body.offsetHeight ? document.body.offsetHeight : 0) -
      (touch.clientY - (target && target.offsetLeft ? target.offsetLeft : 0))
    let minDrawerHeight = 50
    if (newHeight < 0) {
      setDrawerHeight(10)
    }
    if (newHeight > minDrawerHeight) {
      setDrawerHeight(newHeight)
    }
    if (newHeight > window.innerHeight) {
      setDrawerHeight(window.innerHeight);
    }
  }, [])

  const handleTouchEnd = () => {
    document.removeEventListener('touchend', handleTouchEnd, { passive: false })
    document.removeEventListener('touchcancel', handleTouchEnd, {
      passive: false,
    })
    document.removeEventListener('touchmove', handleTouchMove, {
      passive: false,
    })
  }

  // event mouse for split panel
  const handleMouseDown = e => {
    e.preventDefault()
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback(e => {
    let newHeight =
      document.body.offsetHeight - (e.clientY - document.body.offsetLeft)
    let minDrawerHeight = 50
    if (newHeight > minDrawerHeight) {
      setDrawerHeight(newHeight)
    }
    if (newHeight > window.innerHeight) {
      setDrawerHeight(window.innerHeight);
    }
  }, [])

  const handleClickFullScreen = () => {
    if (drawerHeight === window.innerHeight) {
      setDrawerHeight(window.innerHeight / 2)
    } else {
      setDrawerHeight(window.innerHeight)
    }
  }

  // (changeSize) change drawerWidth when drawerWidth > screenWidth
  const handleChangeScreenSize = () => {
    setScreenHeight(window.innerHeight)
  }

  window.addEventListener('resize', handleChangeScreenSize) // force reload when screenWidth change

  useEffect(() => {
    if (drawerHeight > screenHeight) {
      setDrawerHeight(screenHeight)
    }
    if (projectStore.isShowGenericReport) {
      setDrawerHeight(window.innerHeight)
    }
  }, [screenHeight, window.innerHeight, projectStore.isShowGenericReport])
  // end changeSize

  const onClose = () => {
    projectStore.setShowGenericReport(false)
    setDrawerHeight(0)
    setRefresh(0)
    setSheetLoadingPercent([])
  }

  const setShowAddReportModal = () => {
    setAddReportModal(true)
  }

  const onChangeHideRules = (e) => {
    reportStore.setShowReportRules(e.target.checked);
  }

  const onCancelAddNewReport = () => {
    formAddReport.resetFields()
    setAddReportModal(false)
  }

  const onCancelExportReport = () => {
    formExportReport.resetFields()
    setExportModal(false)
  }

  /**
 * Add new empty sheet report
 * @param {*} rules list row rules sheet report
 * @param {*} columns list columns sheet report
 */
  const handleAddNewReport = () => {
    formAddReport.validateFields().then(response => {
      const newSheet = {
        name: response.name,
        level: response.level,
        isFetching: false,
        key: uuid(),
        rules: [],
        columns: [],
        sheetdata: []
      }
      setSheetReports(sheetReports=>[
        ...sheetReports,
        newSheet
      ])
      message.success(t("add-report-successfully"))
      onCancelAddNewReport()
    })
  }

  const CloseIcon = () => {
    return (
      <Row gutter={[8]}>
        <Col className="">
          {drawerHeight === window.innerHeight ? (
            <Tooltip title={(isMobile || isTablet) ? '' : t('out-full-screen')} placement="bottom">
              <div>
                <FullscreenExitOutlined
                  onClick={() => handleClickFullScreen()}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={(isMobile || isTablet) ? '' : t('full-screen')}>
              <FullscreenOutlined onClick={() => handleClickFullScreen()} />
            </Tooltip>
          )}
        </Col>
        <Col>
          <Tooltip title={(isMobile || isTablet) ? '' : t('commons.close')} placement="bottom">
            <div>
              <CloseOutlined onClick={() => onClose()} />
            </div>
          </Tooltip>
        </Col>
      </Row>
    )
  }

  return (
    <DrawerWrapper
      closeIcon={<CloseIcon />}
      place={location.search === '?genericreport' ? 1 : 0}
      title={
        <div className="genericReport-outer">
          <div className="help-btn-wrapper ">
              {t('calculations')}
              <Tooltip title={t('commons.help')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                  <div>
                      <HelpButton helppage={'generic_report'} hovered={'black'}/>
                  </div>
              </Tooltip>
          </div>
          <div className={`genericReport-btn-wrapper ${isIOS ? 'ios-device' : ''}`}>
            <div className={`genericReport-btn-outer ${(isMobile || isTablet) && 'minimize'}`}>
              <Button
                className={reportStore.isLoading ? 'readOnly' : ''}
                icon={(isMobile || isTablet) && <ReloadOutlined />}
                onClick={() => handleRefresh()}>
                {!(isMobile || isTablet) && t('commons.refresh')}
              </Button>
              {reportStore.showReportRules && (
                <Button
                  className={
                    reportStore.isLoading ? 'readOnly export-btn' : 'export-btn'
                  }
                  icon={(isMobile || isTablet) && <FileDoneOutlined />}
                  onClick={showExportModal}>
                  {!(isMobile || isTablet) && t('commons.export')}
                </Button>
              )}
              
            </div>
          </div>

        </div>
      }
      placement="bottom"
      closable={true}
      height={drawerHeight}
      visible={projectStore.isShowGenericReport}
      className="custom-wraper-splitPanel"
      mask={false}>
      {projectStore.isShowGenericReport &&
        (isTabletOrMobileDevice ? (
          <div className="splitpanel-horizontal-mobile">
            <Button
              onTouchStart={e => handleTouchStart(e)}
              type="dashed"
              shape="circle"
              className="btnSplitPanel">
              <ColumnHeightOutlined />
            </Button>
          </div>
        ) : (
          <div
            onMouseDown={e => handleMouseDown(e)}
            className="splitpanel-horizontal"
          />
        ))}
      <ActionToolBackGround>
        <ActionToolNewTable justify="end">
          <CheckBoxRules>
            <Checkbox
              checked={reportStore.showReportRules}
              onChange={onChangeHideRules}>
              {t('hide-rules')}
            </Checkbox>
          </CheckBoxRules>
          <Button
            className={reportStore.isLoading ? 'readOnly' : ''}
            onClick={setShowAddReportModal}
            type="primary">
            {t('commons.new')}
          </Button>
        </ActionToolNewTable>
      </ActionToolBackGround>
      <SheetReportWrapper>
        {sheetReports?.length > 0 ? (
          sheetReports.map((sheet, sheetIndex) => {
            return (
              <SheetReportEditor
                key={uuid()}
                sheet={sheet}
                sheetIndex={sheetIndex}
                sheetReports={sheetReports}
                setSheetReports={e => setSheetReports(e)}
                folderList={folderList}
                treeData={projectStore.projectDetail?.treeData || []}
                loadingPercent={sheetLoadingPercent}
              />
            )
          })
        ) : (
          <div className="sheet-empty">
            <Empty description={false} />
          </div>
        )}
      </SheetReportWrapper>
      <ModalAddAttribute
        className="Model"
        title={t('add-new-report')}
        visible={addReportModal}
        zIndex={9999}
        onCancel={onCancelAddNewReport}
        maskClosable={false}
        footer={
          <Row justify="end">
            <Button
              key="back"
              icon={<CloseCircleOutlined />}
              onClick={onCancelAddNewReport}>
              {t('commons.cancel')}
            </Button>
            <Button
              form="add-column"
              type="primary"
              key="save"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={handleAddNewReport}>
              {t('commons.save')}
            </Button>
          </Row>
        }>
        <Form form={formAddReport} name="add-new-report-form" layout="vertical">
          <Form.Item
            label={t('report-name')}
            name="name"
            rules={[
              {
                required: true,
                message: t('report-name-cannot-be-emptied'),
              },
            ]}>
            <Input placeholder={t('please-input-report-name')} />
          </Form.Item>
          <Form.Item
            label={t('level')}
            name="level"
            rules={[
              {
                required: true,
                message: t('cannot-be-emptied', { text: 'Level' }),
              },
            ]}>
            <Select
              showSearch
              placeholder={t("select-report-level")}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'file',
                  label: t('file'),
                },
                {
                  value: 'object',
                  label: t('object'),
                },
              ]}
            />
          </Form.Item>
        </Form>
      </ModalAddAttribute>
      <ModalAddAttribute
        className="Model"
        title={t('export-report')}
        visible={exportModal}
        zIndex={9999}
        onCancel={onCancelExportReport}
        maskClosable={false}
        footer={
          <Row justify="end">
            <Button
              key="back"
              icon={<CloseCircleOutlined />}
              onClick={onCancelExportReport}>
              {t('commons.cancel')}
            </Button>
            <Button
              form="add-column"
              type="primary"
              key="save"
              icon={<SaveOutlined />}
              htmlType="submit"
              onClick={() => handleExportReport()}>
              {t('commons.save')}
            </Button>
          </Row>
        }>
        <Form form={formExportReport} name="add-new-report-form" layout="vertical">
          <Form.Item
            label={t('report-name')}
            name="name"
            initialValue={''}
            rules={[
              {
                required: true,
                message: t('report-name-cannot-be-emptied'),
              },
            ]}>
            <Input placeholder={t('please-input-report-name')} />
          </Form.Item>
        </Form>
      </ModalAddAttribute>
      {reportStore.isLoading && (
        <Spin tip={`${t('fetching')}...`} size="large" className="genericReport-spiner" />
      )}
    </DrawerWrapper>
  )
}

export default inject(
  'projectStore',
  'commonStore',
  'reportStore'
)(observer(DrawerGenericReport))
