import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next'
import Icon, {
  BuildOutlined,
  PlusOutlined,
  MinusOutlined,
  ExpandOutlined,
  CompressOutlined,
} from '@ant-design/icons'
import { Modal, Button, Input, Tooltip } from 'antd'
import { Pannellum } from 'pannellum-react'
import { Photosphere, PhotoWrapper } from './CustomStyled'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useCallback } from 'react'
import { ReactComponent as Icon2DSvg } from '../../../../assets/svgs/Button-2D-image-mode.svg'
import { ReactComponent as Icon3DSvg } from '../../../../assets/svgs/Button-360-image-mode.svg'
import { HelpButton } from '@/components/elements'
import { isMobile, isTablet } from 'react-device-detect'

const Icon3D = props => <Icon component={Icon3DSvg} {...props} />
const Icon2D = props => <Icon component={Icon2DSvg} {...props} />

const PhotoViewer360 = ({ fileStore, projectStore, usersStore }) => {
  const [fullScreen, setFullScreen] = useState(false)
  const [viewMode, setViewMode] = useState('2D')
  const { t } = useTranslation()
  useEffect(() => {
    if (projectStore.projectDetail?.id && fileStore?.photoViewer360?.id) {
        if (usersStore.currentUser.id) {
            let prjProjectSetting = projectStore.projectDetail.metadata?.photoViewModeList?.find(x => x.photoId === fileStore?.photoViewer360?.id)
            if (prjProjectSetting && prjProjectSetting.viewMode) {
                if (prjProjectSetting.viewMode.photoViewModeSetting === undefined) setViewMode('2D')
                else setViewMode(prjProjectSetting.viewMode.photoViewModeSetting)
            }else setViewMode('2D')
        }else {
          let retrievedList = JSON.parse(localStorage.getItem(`photo_view_mode_setting-${projectStore.projectDetail.id}`));
          if (retrievedList?.length > 0) {
            let objIndex = retrievedList.findIndex(el => el.photoId === fileStore?.photoViewer360?.id)
            if(objIndex > -1)
              setViewMode(retrievedList[objIndex].photoViewModeSetting)
          }
        }
    }
  }, [projectStore.projectDetail, fileStore.photoViewer360])
  

  function savePhoto360Setting(){
    if (usersStore.currentUser.id && fileStore?.photoViewer360?.id) {
        let _photoViewModeSetting = {}
        _photoViewModeSetting.photoId = fileStore?.photoViewer360?.id
        _photoViewModeSetting.viewMode = {
          photoViewModeSetting: viewMode
        }

        let _projectMetadata = projectStore.projectDetail.metadata
        if (_projectMetadata && _projectMetadata.photoViewModeList && _projectMetadata.photoViewModeList.length > 0) {
            const objIndex = _projectMetadata.photoViewModeList.findIndex(obj => obj.photoId === fileStore?.photoViewer360?.id);
            if (objIndex > -1) {
                _projectMetadata.photoViewModeList[objIndex].viewMode = {photoViewModeSetting: viewMode }
            } else {
                _projectMetadata.photoViewModeList.push(_photoViewModeSetting)
            }
        } else {
            _projectMetadata.photoViewModeList = [_photoViewModeSetting]
        }
        let newData = {
            metadata: _projectMetadata
        }
        const checkuserinproject = projectStore.projectDetail.projectuserroles.find(pur => pur.email === usersStore.currentUser.email && pur.inviteStatus === 'accepted')
        if (checkuserinproject) {
            projectStore.updateProjectData(newData).then((res) => {
                projectStore.projectDetail.metadata = res.metadata
            }).catch(err => {
                console.log(err)
            })
        }else {
            projectStore.updateProjectDataForGuest(newData).then((res) => {
                projectStore.projectDetail.metadata = res.metadata
            }).catch(err => {
                console.log(err)
            })
        }
    }else {
      let retrievedList = JSON.parse(localStorage.getItem(`photo_view_mode_setting-${projectStore.projectDetail.id}`));
      if (retrievedList?.length > 0) {
        let objIndex = retrievedList.findIndex(el => el.photoId === fileStore?.photoViewer360?.id)
        if(objIndex > -1){
          retrievedList[objIndex].photoViewModeSetting = viewMode
          localStorage.setItem(`photo_view_mode_setting-${projectStore.projectDetail.id}`, JSON.stringify([...retrievedList]));
        }else{
          retrievedList.push({ photoId: fileStore?.photoViewer360?.id, photoViewModeSetting: viewMode })
          localStorage.setItem(`photo_view_mode_setting-${projectStore.projectDetail.id}`, JSON.stringify([...retrievedList]));
        }
      } else {
        localStorage.setItem(`photo_view_mode_setting-${projectStore.projectDetail.id}`, JSON.stringify([{ photoId: fileStore?.photoViewer360?.id, photoViewModeSetting: viewMode }]));
      }
    }
  }
  const onCancel = () => {
    savePhoto360Setting()
    fileStore.setPhotoViewer360(false)
  }
  const [isPortrait, setIsPortrait] = useState(false)
  useEffect(() => {
    if(!fileStore.photoViewer360?.imageSize) setIsPortrait(false)
    else{
      if (fileStore.photoViewer360?.imageSize?.width > fileStore.photoViewer360?.imageSize?.height){
        setIsPortrait(false)
      } else if (fileStore.photoViewer360?.imageSize?.width < fileStore.photoViewer360?.imageSize?.height){
        setIsPortrait(true)
      } else {
        setIsPortrait(false)
      }
    }
  }, [fileStore.photoViewer360])
  
  return (
    <Modal
      width={(isPortrait && viewMode === '2D')? 'max-content' : window.screen.width * 0.8}
      centered={true}
      onCancel={onCancel}
      visible={fileStore.photoViewer360}
      title={
        <div className="help-btn-wrapper">
          {t('image-viewer')}
          <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
            <div>
              <HelpButton helppage={'image_viewer'} hovered={'black'}/>
            </div>
          </Tooltip>
        </div>
      }
      icon={<BuildOutlined />}
      iconType="build"
      zIndex={10000}
      footer={false}
      maskClosable={false}
      destroyOnClose={true}
      >
      <Photosphere style={{ overflow: 'hidden' }}>
          <div className="photo-view-mode">
            <Button
              icon={
                viewMode === '3D' ? (
                  <Icon2D style={{ fontSize: '20px' }} />
                ) : (
                  <Icon3D style={{ fontSize: '20px' }} />
                )
              }
              onClick={() => {
                setViewMode(prev => (prev === '2D' ? '3D' : '2D'))
              }}
            />
          </div>
        { viewMode === '3D' ? (
          <Pannellum
            width="100%"
            height={`${window.screen.height * 0.6}px`}
            image={fileStore.photoViewer360.src}
            autoLoad
            showZoomCtrl={true}
            compass={true}
            author=""
            title={fileStore.photoViewer360.name}
            onLoad={() => {
              console.log('panorama loaded')
            }}></Pannellum>
        ) : (
          <TransformWrapper initialScale={1}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <PhotoWrapper className="photo-component" fullscreen={fullScreen}>
                <div className='photo-tools'>
                  <div className="btn-wapper">
                    <Button onClick={() => zoomIn()} icon={<PlusOutlined />} />
                    <Button
                      onClick={() => zoomOut()}
                      icon={<MinusOutlined />}
                    />
                  </div>
                  <div className="btn-wapper">
                    <Button
                      className="btn-fullscreen"
                      onClick={() => {
                        if (
                          !document.fullscreenElement &&
                          !document.webkitFullscreenElement
                        ) {
                          if ('requestFullScreen' in document.body) {
                            let fullScreemElement = document
                              .querySelector('.photo-component')
                              ?.requestFullscreen()
                          } else if (
                            'webkitRequestFullscreen' in document.body
                          ) {
                            let fullScreemElement = document
                              .querySelector('.photo-component')
                              ?.webkitRequestFullscreen()
                          }
                          setFullScreen(true)
                        } else {
                          if ('exitFullscreen' in document) {
                            document.exitFullscreen()
                          } else if ('webkitExitFullscreen' in document) {
                            document.webkitExitFullscreen()
                          }
                          setFullScreen(false)
                        }

                        document.addEventListener(
                          'fullscreenchange',
                          exitHandler
                        )
                        document.addEventListener(
                          'webkitfullscreenchange',
                          exitHandler
                        )

                        function exitHandler() {
                          if (
                            !document.fullscreenElement &&
                            !document.webkitIsFullScreen &&
                            !document.mozFullScreen &&
                            !document.msFullscreenElement
                          ) {
                            setFullScreen(false)
                          }
                        }
                      }}
                      icon={
                        !fullScreen ? <ExpandOutlined /> : <CompressOutlined />
                      }
                    />
                  </div>
                </div>
                <div className="photo-info">
                  <div className="photo-title-box">
                    {fileStore.photoViewer360.name}
                  </div>
                </div>
                <TransformComponent>
                  <img src={fileStore.photoViewer360.src} alt="test" style={ {width: isPortrait || fullScreen ? '100%' : window.screen.width * 0.8  , height: fullScreen? '100vh' :window.screen.height * 0.6}} />
                </TransformComponent>
              </PhotoWrapper>
            )}
          </TransformWrapper>
        )}
      </Photosphere>
    </Modal>
  )
}

export default inject('projectStore', 'fileStore', 'usersStore')(observer(PhotoViewer360))
