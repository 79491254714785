import React, { useCallback, useEffect, useState } from 'react'
import { ColumnWidthOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Drawer, Spin, Empty, message } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import Gantt from './Gantt'
import Toolbar from './Toolbar'
import { GanttPageWrapperLeft, GanttWrapper } from './styled'
import ModalLinkGanttToDataTree from './ModalLinkGanttToDataTree'
import ModalLinkGanttToSavedQuery from './ModalLinkGanttToSavedQuery'
import { checkIsValidId } from './Gantt/ganttUtils'

const DrawerGanttPanel = ({
  projectGanttStore,
  commonStore,
  projectStore,
  viewer,
}) => {
  const { t } = useTranslation()
  const isMobileOrTablet = useMediaQuery({ maxWidth: 1224 });
  const [drawerWidth, setDrawerWidth] = useState(isMobileOrTablet ? 410 : 950)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [ganttData, setGanttData] = useState()
  const [currentGanttId, setCurrentGanttId] = useState('')

  // Cài đặt locale cho moment dựa trên ngôn ngữ
  moment.locale(commonStore.language);

  //#region event for mouse and touche split panel
  const handleResize = useCallback(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (drawerWidth > screenWidth) setDrawerWidth(screenWidth);
  }, [screenWidth, drawerWidth]);

  const handleTouchStart = e => {
    e.preventDefault()
    if (e.touches.length !== 1) return null
    document.addEventListener('touchmove', handleTouchMove, { passive: false })
    document.addEventListener('touchend', handleTouchEnd, { passive: false })
    document.addEventListener('touchcancel', handleTouchEnd, { passive: false })
  }

  const handleTouchMove = useCallback((e) => {
    const touch = e.touches[0] || e.changedTouches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);
    const newWidth = touch.clientX - (target?.offsetRight || 0);
    const minWidth = 360;

    if (newWidth < 0) setDrawerWidth(10);
    else if (newWidth > minWidth && newWidth <= window.innerWidth) setDrawerWidth(newWidth);
    else if (newWidth > window.innerWidth) setDrawerWidth(window.innerWidth);
  }, []);

  const handleTouchEnd = () => {
    document.removeEventListener('touchmove', handleTouchMove, { passive: false });
    document.removeEventListener('touchend', handleTouchEnd, { passive: false });
    document.removeEventListener('touchcancel', handleTouchEnd, { passive: false });
  };

  const handleMouseDown = e => {
    e.preventDefault()
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback((e) => {
    const offsetRight = e.clientX - document.body.offsetLeft;
    const minWidth = 410;

    if (offsetRight > minWidth && offsetRight <= window.innerWidth) {
      setDrawerWidth(offsetRight);
    } else if (offsetRight > window.innerWidth) {
      setDrawerWidth(window.innerWidth);
    }
  }, []);
  //#endregion

  const resetLightboxUI = () => {
    projectGanttStore.setSelectedNodeInDataTree([])
    projectGanttStore.setSelectedSavedQuery([])
    projectGanttStore.setCurrentColorPicker('')
    projectGanttStore.setCurrentAlpha('0.5')
    projectGanttStore.setColorTaskEnable()
  }

  const showErrorMessage = useCallback((type, action) => {
    if (!type || !action) return;
    const errorMsg = t(`gantt.failed-to-${action}-${type}`);
    message.error(errorMsg);
    return errorMsg;
  }, [t]);

  const updateGanttData = useCallback(async (type, action, item, id, isDeletedOCreatedNew) => {
    const refreshGantt = res => {
      resetLightboxUI()
      projectGanttStore.getProject4dGantt(projectStore.projectDetail.id)
      return res
    }

    const handleValidateTask = (type, action) => {
      const errorMessage = showErrorMessage(type, action)
      return refreshGantt({ status: 'error', errorMessage })
    }

    if (type === 'task') {
      let newGanttTask = {
        text: item.text,
        description: item?.description || '',
        duration: item.duration,
        parent: item.parent + '',
        progress: item.progress || 0,
        gantt: currentGanttId,
        dataTree: projectGanttStore.isLightboxOpen ? projectGanttStore.selectedNodeInDataTree : item.dataTree,
        priority: item.priority || 'normal',
        taskType: item.taskType || 'new',
        highlightColor: (projectGanttStore.isLightboxOpen ? projectGanttStore.currentColorPicker : item.highlightColor) || '#ff0000',
        highlightAlpha: (projectGanttStore.isLightboxOpen ? (projectGanttStore.currentAlpha ?? 0.5).toString() : (item.highlightAlpha ?? 0.5).toString()) || '0.5',
        type: item.type || 'task',
        savedQuery: projectGanttStore.isLightboxOpen ? projectGanttStore.selectedSavedQuery : item.savedQuery,
        highlightEnable: projectGanttStore.isLightboxOpen ? (projectGanttStore.highlightEnable !== undefined ? projectGanttStore.highlightEnable : true) : item.highlightEnable,
        colorTaskEnable: projectGanttStore.isLightboxOpen ? (projectGanttStore.colorTaskEnable !== undefined ? projectGanttStore.colorTaskEnable : item.colorTaskEnable) : item.colorTaskEnable,
        render: Array.isArray(item?.render) ? (item.render.length > 0 ? item.render[0] : '') : item?.render ?? '',
        start_date: moment(item?.start_date || moment().format('DD-MM-YYYY HH:mm'), 'DD-MM-YYYY HH:mm'),
        end_date: moment(item?.end_date || moment().format('DD-MM-YYYY HH:mm'), 'DD-MM-YYYY HH:mm'),
        owner: item.owner || [],
      };

      if (item?.planned_start) {
        newGanttTask.planned_start = moment(item?.planned_start || moment().format('DD-MM-YYYY HH:mm'), 'DD-MM-YYYY HH:mm')
      }
      if (item?.planned_end) {
        newGanttTask.planned_end = moment(item?.planned_end || moment().format('DD-MM-YYYY HH:mm'), 'DD-MM-YYYY HH:mm')
      }

      // create thi kiem tra parent id
      // update thi kiem tra task id va parent id
      // delete thi kiem tra task id
      // eslint-disable-next-line default-case
      switch (action) {
        case 'create':
          if (Number(item.parent) !== 0 && !checkIsValidId(item.parent)) {
            return handleValidateTask(type, action);
          }
          break;
        case 'update':
          if (!checkIsValidId(item.id)) {
            return;
          }
          if ((Number(item.parent) !== 0 && !checkIsValidId(item.parent))) {
            return handleValidateTask(type, action);
          }
          break;
        case 'delete':
          if (!checkIsValidId(item.id)) {
            return handleValidateTask(type, action);
          }
          break;
        default:
          break; // Add a default case for clarity
      }

      // case if new task or delete and action is update then only change type
      if (isDeletedOCreatedNew && action === 'update') {
        newGanttTask = {
          type: item.type || 'task',
        }
      }

      const ganttAction = {
        create: () => projectGanttStore.createGanttTask(newGanttTask),
        update: () => projectGanttStore.updateGanttTask(item.id, newGanttTask),
        delete: () => projectGanttStore.deleteGanttTask(item.id),
      }

      return await ganttAction[action]?.().then(refreshGantt).catch(() => showErrorMessage(type, action))
    }

    if (type === 'link') {
      const newGanttLink = {
        source: item.source,
        target: item.target,
        type: item.type,
        gantt: currentGanttId,
      }

      if (!checkIsValidId(item.source) || !checkIsValidId(item.target)) {
        return handleValidateTask(type, action)
      }

      const ganttAction = {
        create: () => projectGanttStore.createGanttLink(newGanttLink),
        update: () => projectGanttStore.updateGanttLink(item.id, newGanttLink),
        delete: () => projectGanttStore.deleteGanttLink(item.id),
      }

      return await ganttAction[action]?.().then(refreshGantt).catch(() => showErrorMessage(type, action))
    }
  }, [projectGanttStore, projectStore, currentGanttId, showErrorMessage])

  // handle add project gantt
  const handleCreateProject4dGantt = () => {
    const newGantt = {
      name: `project_gantt_${projectStore.projectDetail._id}`,
      project: projectStore.projectDetail._id,
    }
    projectGanttStore.createProject4dGantt(newGantt)
  }

  useEffect(() => {
    if (projectGanttStore.isShowGanttPanel) {
      let defaulTask = projectGanttStore.ganttDefaults ? projectGanttStore.ganttDefaults : projectStore.projectDetail?.metadata?.ganttDefault?.find(item => item.userId === projectStore.projectDetail.currentUser._id);
      if (defaulTask) {
        projectGanttStore.setGanttDefaults(defaulTask)
        if (defaulTask.settings) {
          const { settings } = defaulTask;

          projectGanttStore.setIsShowGrid(settings.isShowGrid ?? true);
          projectGanttStore.setToggleChart(settings.showChart ?? true);
          projectGanttStore.setIsShowCriticalPath(settings.isShowCriticalPath ?? false);
          projectGanttStore.setIsShowBaseline(settings.isShowBaseline ?? false);
          projectGanttStore.setIsAutoScheduling(settings.isAutoScheduling ?? true);
          projectGanttStore.setShowColorTask(settings.showColorTask ?? true);
          projectGanttStore.setIsCondensed(settings.isCondensed ?? false);
          projectGanttStore.setPlayerMode(settings.playerMode ?? 'dragDate');
          projectGanttStore.setZoomScale({ status: settings.zoomScale?.status ?? 'Days', isUpdate: true });
          projectGanttStore.setZoomToFit({ status: settings.zoomToFit?.status ?? false, isUpdate: true });
          projectGanttStore.setIsPlay4d(settings.isPlay4d ?? false);
          projectGanttStore.setPlayerSpeed(settings.playerSpeed ?? 1);
          projectGanttStore.setGroupType(settings.groupType ?? 'task');
          projectGanttStore.setToggleResource(settings.showResource ?? false);
        }
      }
      projectGanttStore
        .getProject4dGantt(projectStore.projectDetail.id)
        .then(res => {
          if (res && !res?.length) {
            handleCreateProject4dGantt()
          }
        })
    }

    return () => {
      setGanttData()
    }
  }, [projectGanttStore.isShowGanttPanel])

  // effect on/off coloring task
  useEffect(() => {
    handlePreprocessProjectGanttData()
  }, [projectGanttStore.projectGanttData, projectGanttStore.showColorTask, projectGanttStore.showResource])


  const handlePreprocessProjectGanttData = useCallback(() => {
    if (!projectGanttStore.projectGanttData?.length) {
      setGanttData()
      return
    }

    let defaulTask = projectGanttStore.ganttDefaults || (projectStore.projectDetail?.metadata?.ganttDefault?.find(item => item.userId === projectStore.projectDetail?.currentUser?._id));
    const collapsedTaskSet = new Set(defaulTask?.settings?.collapseTasks || []);

    // current phase just use one gantt chart so we only need to element at the beginning
    let { data, links, _id, resources } = projectGanttStore.projectGanttData[0]
    setCurrentGanttId(_id ?? '')

    if (data?.length) {
      data.forEach(element => {
        if (element?.start_date) {
          element.start_date = new Date(element.start_date) // parse date
        }
        if (element?.end_date) {
          element.end_date = new Date(element?.end_date) // parse date
        }
        if (element?.planned_start) {
          element.planned_start = new Date(element.planned_start) // parse date
        }
        if (element?.planned_end) {
          element.planned_end = new Date(element.planned_end) // parse date
        }
        // Tasks Coloring
        element.color = projectGanttStore.showColorTask ? (element.colorTaskEnable === true ? (element.highlightColor || '#20b56d') : element.type === 'project' ? '#20b56d' : '#537cfa') : element.type === 'project' ? '#20b56d' : '#537cfa'

        element.open = !collapsedTaskSet.has(element.id);
      })
    }
    setGanttData({ data, links, resources })
  }, [projectGanttStore])

  const onCancel = () => {
    projectGanttStore.setIsShowGanttPanel(false)
  }

  useEffect(() => {
    if (projectGanttStore.isShowGanttPanel) {
      commonStore.setStickyLogoLeftDistance(drawerWidth)
    }
  }, [drawerWidth, projectGanttStore.isShowGanttPanel, commonStore])

  return (
    <Drawer
      className={
        projectGanttStore.isShowGanttPanel
          ? 'project-setting-drawer custom-wraper-splitPanel'
          : 'project-setting-drawer'
      }
      bodyStyle={{ padding: '0', overflow: 'hidden' }}
      placement="left"
      width={drawerWidth}
      closable={false}
      visible={projectGanttStore.isShowGanttPanel}
      maskClosable={false}
      mask={false}
      destroyOnClose={true}
      forceRender>
      <GanttPageWrapperLeft baseline={projectGanttStore.isShowBaseline}>
        {isMobileOrTablet ? (
          <div className="splitpanel-mobile-left">
            <Button
              onTouchStart={e => handleTouchStart(e)}
              type="dashed"
              shape="circle"
              className="btnSplitPanel">
              <ColumnWidthOutlined />
            </Button>
          </div>
        ) : (
          <div
            onMouseDown={e => handleMouseDown(e)}
            className="splitpanel-left"
          />
        )}
        <GanttWrapper>
          <Spin spinning={projectGanttStore.isLoading}>
            {ganttData ? (
              <>
                <Toolbar />
                <Gantt
                  viewer={viewer}
                  tasks={ganttData}
                  onDataUpdated={updateGanttData}
                />
              </>
            ) : (
              <>
                <CloseOutlined
                  className="close-gantt-icon"
                  style={{ fontSize: 16 }}
                  onClick={() => onCancel()}
                />
                <Empty />
              </>
            )}
          </Spin>
        </GanttWrapper>
      </GanttPageWrapperLeft>
      <ModalLinkGanttToDataTree />
      <ModalLinkGanttToSavedQuery viewer={viewer} />
    </Drawer>
  )
}

export default inject(
  'commonStore',
  'projectGanttStore',
  'projectStore'
)(observer(DrawerGanttPanel))
