import { Button, Tooltip, Avatar } from 'antd'
import { inject, observer } from "mobx-react"
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { ClipPlaneItem, AvatarCustom } from './CustomStyled'
import SVGIcon from '@/components/elements/SVGIcon';
import { ReactComponent as ClippingPlaneVertical } from '@/assets/svgs/Clip-plane-Vertical.svg';
import { ReactComponent as ClippingPlaneHorizontal } from '@/assets/svgs/Clip-plane-Horizontal.svg';
import { ReactComponent as ClippingPlaneAlignment } from '@/assets/svgs/Clip-plane-Alignment.svg';

import { Trans, useTranslation } from 'react-i18next';


const ClippingPlaneTool = props => {
  const { commonStore, projectStore, uiStore, schedulingStore } = props
  const { t } = useTranslation();
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  return (
    <>
      <ClipPlaneItem>
        <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('vertical-clip-plane')}>
          <AvatarCustom
            activated={projectStore.clippingMode === 'vertical' ? 1 : 0}
            shape="square"
            icon={<SVGIcon content={<ClippingPlaneVertical />} />}
            onClick={() => {
              projectStore.setCurrentHelpfeature('clip_plane')
              projectStore.setViewMode('default mode') // set default mode
              projectStore.setClippingMode('vertical')
              uiStore.setShowAttrPanel(false) // hide window properties
              uiStore.setClippingPlaneToolVisible(false); // hide menu toolbar cliping
            }}
          />
        </Tooltip>
      </ClipPlaneItem>
      <ClipPlaneItem>
        <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('horizontal-clip-plane')}>
          <AvatarCustom
            activated={projectStore.clippingMode === 'horizontal' ? 1 : 0}
            shape="square"
            icon={<SVGIcon content={<ClippingPlaneHorizontal />} />}
            onClick={() => {
              projectStore.setCurrentHelpfeature('clip_plane')
              projectStore.setViewMode('default mode') // set default mode
              projectStore.setClippingMode('horizontal')
              uiStore.setShowAttrPanel(false) // hide window properties
              uiStore.setClippingPlaneToolVisible(false); // hide menu toolbar cliping
            }}
          />
        </Tooltip>
      </ClipPlaneItem>
      <ClipPlaneItem>
        <Tooltip overlayStyle={isTabletOrMobileDevice ? { display: 'none' } : undefined} placement="top" title={t('alignment-clip-plane')}>
          <AvatarCustom
            activated={projectStore.clippingMode === 'alignment' ? 1 : 0}
            shape="square"
            icon={<SVGIcon content={<ClippingPlaneAlignment />} />}
            onClick={() => {
              projectStore.setCurrentHelpfeature('clip_plane')
              projectStore.setViewMode('default mode') // set default mode
              projectStore.setClippingMode('alignment')
              uiStore.setShowAttrPanel(false) // hide window properties
              uiStore.setClippingPlaneToolVisible(false); // hide menu toolbar cliping
              schedulingStore.toggleTimeSliderVisible(false)
            }}
          />
        </Tooltip>
      </ClipPlaneItem>
    </>
  )
}

export default inject('commonStore', 'projectStore', 'uiStore', 'schedulingStore')(observer(ClippingPlaneTool))