import { CloseOutlined } from '@ant-design/icons';
import { Col, Tooltip, Button, InputNumber, Row, Select, Slider } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { SVGIcon } from '@/components/elements';
import { ReactComponent as Icon4DBack } from '@/assets/svgs/4DBack-s0.svg';
import { ReactComponent as Icon4DForward } from '@/assets/svgs/4DForward-s0.svg';
import { ReactComponent as Icon4DPause } from '@/assets/svgs/4DPause-s0.svg';
import { ReactComponent as Icon4DPlay } from '@/assets/svgs/4DPlay-s0.svg';
import { ButtonCustom, CenteredContent, CenteredContentBigIcon, SliderContainer } from './CustomStyled';
import { Trans, useTranslation } from 'react-i18next';
import { Math as CesiumMath, HeadingPitchRange } from 'cesium'
const { Option } = Select;

const FollowAlignmentTool = props => {
    const { t } = useTranslation();
    const { projectStore, alignmentStore, viewer, usersStore, projectSettingStore } = props
    const [isPlayed, setPlayVideoState] = useState(false)
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    const handleChangeStepSize = value => {
        alignmentStore.setStep(Number(value))
        projectSettingStore.setAssignObjSystemProjectSetting('alignmentClipStepSetting', Number(value))
    }

    const handleChangeStationNumber = value => {
        if (value) {
            alignmentStore.setCurrentStep(parseFloat(value))
            alignmentStore.setChangeSlider(true)
            // tracking
            if (viewer.current.cesiumElement.entities && viewer.current.cesiumElement.entities.values.length > 0) {
                viewer.current.cesiumElement.entities.values.forEach(_planeview => {
                    if (_planeview.type && _planeview.type === 'clipping') {
                        viewer.current.cesiumElement.flyTo(_planeview, { duration: 0, offset: new HeadingPitchRange(0, -CesiumMath.PI_OVER_FOUR) })
                    }
                })
            }
        }
    }

    const tipFormatter = () => {
        if (alignmentStore.currentStep !== undefined) {
            return alignmentStore.currentStep
        }
    }

    const jumpForward = () => {
        if (alignmentStore.currentStep >= Math.min(...alignmentStore.alignmentTicks.map(({ value }) => value)) && alignmentStore.currentStep <= Math.max(...alignmentStore.alignmentTicks.map(({ value }) => value))) {
            alignmentStore.setCurrentStep(alignmentStore.currentStep + Number(alignmentStore.step))
            alignmentStore.setChangeSlider(true)
        } else {
            setPlayVideoState(false)
            if (alignmentStore.currentStep < Math.min(...alignmentStore.alignmentTicks.map(({ value }) => value))) {
                alignmentStore.setCurrentStep(alignmentStore.currentStep + Number(alignmentStore.step))
                alignmentStore.setChangeSlider(true)
            }
        }
    }

    const jumpBackward = () => {
        if (alignmentStore.currentStep >= Math.min(...alignmentStore.alignmentTicks.map(({ value }) => value)) && alignmentStore.currentStep <= Math.max(...alignmentStore.alignmentTicks.map(({ value }) => value))) {
            alignmentStore.setCurrentStep(alignmentStore.currentStep - Number(alignmentStore.step))
            alignmentStore.setChangeSlider(true)
        } else {
            setPlayVideoState(false)
            if (alignmentStore.currentStep > Math.max(...alignmentStore.alignmentTicks.map(({ value }) => value))) {
                alignmentStore.setCurrentStep(alignmentStore.currentStep - Number(alignmentStore.step))
                alignmentStore.setChangeSlider(true)
            }
        }
    }

    const playVideo = () => {
        setPlayVideoState(true)
    }

    function useInterval(callback, delay) {
        const savedCallback = useRef()

        // Remember the latest function.
        useEffect(() => {
            savedCallback.current = callback;
        });

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current()
            }
            if (delay !== null) {
                let id = setInterval(tick, delay)
                return () => clearInterval(id)
            }
        }, [delay])
    }

    useInterval(
        () => {
            jumpForward()
            // tracking
            if (viewer.current.cesiumElement.entities && viewer.current.cesiumElement.entities.values.length > 0) {
                viewer.current.cesiumElement.entities.values.forEach(_planeview => {
                    if (_planeview.type && _planeview.type === 'clipping') {
                        viewer.current.cesiumElement.flyTo(_planeview, { duration: 0, offset: new HeadingPitchRange(0, -CesiumMath.PI_OVER_FOUR, 200) })
                    }
                })
            }
        },
        isPlayed ? 0 : null
    )

    const pauseVideo = () => {
        setPlayVideoState(false)
    }

    const onChangeSlider = (value) => {
        alignmentStore.setCurrentStep(value)
        alignmentStore.setChangeSlider(true)
    }


    useEffect(() => {
        return () => {
            alignmentStore.setStep(10)
            alignmentStore.setAlignmentTicks([])
            alignmentStore.setCurrentStep(0)
            alignmentStore.setChangeSlider(false)
            alignmentStore.setOrientationClippingPlane(false)
            setPlayVideoState(false)
        }
    }, [])

    useEffect(() => {
        if (projectStore.projectDetail?.id) {
            if (usersStore.currentUser.id) {
                let prjProjectSetting = projectStore.projectDetail.metadata?.projectSetting?.find(x => x.userid === usersStore.currentUser.id)
                if (prjProjectSetting && prjProjectSetting.projectSetting) {
                    if (prjProjectSetting.projectSetting?.alignmentClipStepSetting === undefined) {
                        alignmentStore.setStep(10)
                    } else {
                        alignmentStore.setStep(prjProjectSetting.projectSetting?.alignmentClipStepSetting)
                    }
                } else {
                    alignmentStore.setStep(10)
                }
            }else {
                var retrievedObject = JSON.parse(localStorage.getItem(`alignment_clip_step_setting-${projectStore.projectDetail.id}`));
                if (retrievedObject) {
                    alignmentStore.setStep(retrievedObject.alignmentClipStepSetting)
                } else {
                    alignmentStore.setStep(10)
                }
            }
        }
    }, [projectStore.projectDetail])

    return (
        <SliderContainer>
            <Row type="flex" justify="center" align="middle">
                <Col lg={4} md={4} >
                    <CenteredContent>
                        <InputNumber
                            min={Math.min(...alignmentStore.alignmentTicks.map(({ value }) => value))}
                            max={Math.max(...alignmentStore.alignmentTicks.map(({ value }) => value))}
                            onChange={handleChangeStationNumber}
                            step={alignmentStore.step}
                            stringMode
                            placeholder="Station number"
                            style={{ width: 130 }}
                        />
                    </ CenteredContent>
                </Col>
                <Col lg={{ span: 1 }} md={2} xs={(isMobileOnly && isPortrait) && 3}>
                    <CenteredContentBigIcon>
                        <ButtonCustom icon={<SVGIcon content={<Icon4DBack />} />} onClick={jumpBackward} />
                    </CenteredContentBigIcon>
                </Col>
                <Col lg={14} md={12} xs={(isMobileOnly && isPortrait) && 18} style={(isMobileOnly && !isPortrait) && { width: '55%' }}>
                    <Slider
                        min={Math.min(...alignmentStore.alignmentTicks.map(({ value }) => value))}
                        max={Math.max(...alignmentStore.alignmentTicks.map(({ value }) => value))}
                        value={alignmentStore.currentStep}
                        tooltipVisible
                        tipFormatter={tipFormatter}
                        onChange={onChangeSlider}
                        step={alignmentStore.step}
                    />
                </Col>
                <Col lg={1} md={2} xs={(isMobileOnly && isPortrait) && 3}>
                    <CenteredContentBigIcon>
                        <ButtonCustom icon={<SVGIcon content={<Icon4DForward />} />} onClick={jumpForward} />
                    </CenteredContentBigIcon>
                </Col>
                <Col lg={1} md={2} >
                    <CenteredContentBigIcon>
                        {!isPlayed ?
                            <ButtonCustom icon={<SVGIcon content={<Icon4DPlay />} />} onClick={playVideo} />
                            :
                            <ButtonCustom icon={<SVGIcon content={<Icon4DPause />} />} onClick={pauseVideo} />
                        }
                    </CenteredContentBigIcon>
                </Col>
                <Col lg={3} md={2} style={(isMobileOnly && isPortrait) && { marginRight: 10, marginLeft: 10 }} >
                    <CenteredContent>
                        <Select value={alignmentStore.step} onChange={handleChangeStepSize} dropdownStyle={{ width: 100, zIndex: 9999 }}>
                            {alignmentStore.stepSizes.map(step => {
                                return <Option key={step.key} value={step.key}>{step.value.toString().toLowerCase()}</Option>
                            })}
                        </Select>
                    </CenteredContent>
                </Col>
            </Row>
        </SliderContainer >
    )
}

export default inject('projectStore', 'usersStore', 'alignmentStore', 'projectSettingStore')(observer(FollowAlignmentTool))