import { Button, Checkbox, Form, Input, Row } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { Link, withRouter,useLocation } from 'react-router-dom';
import { SocketContext } from '@/socket-context';
import authStore from '@/stores/authStore';
import { assetUrl } from '@/config';
import { NaviLink } from './CustomStyled';
import { Trans, useTranslation } from 'react-i18next';
import { renderTermsPolicyHref } from '@/lib/projectLib';

const LoginForm = ({ match, history }) => {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const [data, setData] = useState({})
  const location = useLocation();
  const { state } = location;
 
  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let publicKey = params.get('publicKey');
    let sesstionId = params.get('sesstionId');
    if (publicKey && sesstionId) {
      socket.emit("authenticatePending", {
        publicKey: publicKey,
        sesstionId: sesstionId
      });
      socket.on("login", data => {

        authStore.userLogin(data.username, data.password, true, history)
        authStore.setSessionId(sesstionId)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])
  const authType = match.params.authType
  
  const getData = () => {
    fetch(`${assetUrl}maintananceInfo.json`
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson)
      });
  }

  useEffect(() => {
    getData()
  }, [])


  const CustomForm = props => {
    const handleSubmit = values => {
      authStore.userLogin(values.username, values.password, values.remember, history,state)
    }
   
    return (
      <Form
        name="login-form"
        layout="vertical"
        className={`auth-form auth-form-${authType}`}
        onFinish={handleSubmit}>
        <Form.Item label={t('username')} name="username" rules={[
          {
            required: true,
            message: t('please-input-your-username'),
          },
        ]}>
          <Input placeholder={t('your-username')} />
        </Form.Item>
        <Form.Item label={t('password')} name="password" rules={[
          {
            required: true,
            message: t('please-input-your-password'),
          },
        ]}>
          <Input type='password' placeholder={t('your-password')} />
        </Form.Item>
        <div className={'remember'}>
          <Form.Item name="remember" valuePropName="checked" initialValue={true} noStyle >
            <Checkbox>{t('remember-me')}</Checkbox>
          </Form.Item>
          <Link className="login-form-forgot" to="/auth/forgot-password" style={{ color: '#ff8d4f' }}>
            {t('forgot-password')}
          </Link>
        </div>

        <Button block size={'large'} type={'primary'} htmlType={'submit'} style={{marginBottom: '10px'}}>
          {t('login')}
        </Button>
        <Button block size={'large'} type={'default'} className="ant-btn-default-blue" href={`${process.env.REACT_APP_API_URL}/connect/cognito`}>
          Connect with Cognito
        </Button>
        <NaviLink margin={'15px 0'}>
          <span style={{ marginRight: 10 }}>{t('do-not-have-an-account')}</span>
          <Link to={'/auth/register'} style={{ color: '#ff8d4f' }}>
            {t('register-account-here')}
          </Link>
        </NaviLink>
        <Row justify='center'>
          <a href={renderTermsPolicyHref(data,'policy')} target='_blank' style={{ color: '#ff8d4f' }}>{t('privacy-policy')}</a>
          <div style={{ padding: '0 5px' }}> | </div>
          <a href={renderTermsPolicyHref(data,'terms')} target='_blank' style={{ color: '#ff8d4f' }}>{t('terms-and-conditions')}</a>
        </Row>
      </Form>
    )
  }

  return <CustomForm />

}

export default withRouter(inject('authStore')(observer(LoginForm)))