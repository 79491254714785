import {
  ApartmentOutlined, DownloadOutlined,
  InfoCircleOutlined, UserAddOutlined
} from '@ant-design/icons';
import { Avatar, Button, Collapse, Dropdown, Form, Image, Spin, Switch, Tooltip } from 'antd';
import {
  Cartesian3,
  Cartographic
} from 'cesium';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { ReactComponent as AdminIcon } from '@/assets/svgs/administration-s0.svg';
import { ReactComponent as ProfileIcon } from '@/assets/svgs/profile-s0.svg';
import { ReactComponent as ProjectAdd } from '@/assets/svgs/project-add-s0.svg';
import { ReactComponent as ProjectSetting } from '@/assets/svgs/project-settings-s0.svg';
import { ReactComponent as ProjectsIcon } from '@/assets/svgs/projects-s0.svg';
import { ReactComponent as Settings } from '@/assets/svgs/settings-b0.svg';
import { ReactComponent as UsersIcon } from '@/assets/svgs/users-s0.svg';
import  AppLogo from '@/assets/icons/6DPlanner - SQ - 75px.png';
import  GoogleLogo from '@/assets/icons/google_on_non_white.png';
import { SocketContext } from '@/socket-context';
import { ToolbarTopContainer, ToolbarTopItem, ToolbarTopCustom, HelpButtonCustom, ProjectSettingGroup, ManagementGroup } from './CustomStyled';
import { useTranslation } from 'react-i18next';
import { HelpButton, SVGIcon } from '@/components/elements'

const { Panel } = Collapse

const ToolbarTop = props => {
  const {
    currentPage,
    history,
    commonStore,
    authStore,
    projectStore,
    projectTeamsStore,
    usersStore,
    schedulingStore,
    uiStore,
    projectSettingStore,
    adminStore,
    capturesStore,
    fileStore, sketchingStore
  } = props
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isSmallMonitor = useMediaQuery({ query: '(max-width: 536px)' })
  const socket = useContext(SocketContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const handleAPINotification = async function (msg) {
    const { type, data } = msg
    if (type === 'goto-project') {
      projectStore.setIsSocketOpenProjectError(false)
      schedulingStore.toggleTimeSliderVisible(false)
      await projectStore.checkProjectIsDeleted(data.id).then(res => {
        history.push('/projects')
        history.push(`/project/${data.id}`)
      }).catch(() => {
        projectStore.setIsSocketOpenProjectError(true)
        history.push('/projects')
        history.push(`/project/${data.id}`)
      })
    }
    if (type === 'goto-view') {

      projectStore.setcurrentCamera(data)
    }
    if (type === 'hide-object') {
      const newData = JSON.parse(JSON.stringify(projectStore.listObjectHide));
      const keyModels = Object.keys(data.groupGuid);
      for (let i = 0; i < keyModels.length; i++) {
        const modelId = keyModels[i]
        const model = data.groupGuid[modelId]
        const keyGuids = Object.keys(model);
        for (let j = 0; j < keyGuids.length; j++) {
          const guid = keyGuids[j]
          if (!newData[modelId]) {
            newData[modelId] = model
            break
          } else {
            newData[modelId][guid] = model[guid]
          }
        }
      }
      projectStore.setListObjectHide(JSON.parse(JSON.stringify(newData)))
    }
    if(type === 'showTwinGuideDialog'){
      if(data) projectStore.setHideTwinGuideDialog(data?.hideTwinGuideDialog)
    }
    if (type === 'read-visible') {
      let dataVisible = JSON.parse(localStorage.getItem(`gotoviewer-${data.projectId}-${data.userId}`))
      socket.emit("read-data", {
        apiKey: usersStore.currentUser.apiKey,
        data: dataVisible,
        type,
        sesstionId: authStore.sesstionId
      });
    }

    if (type === 'update-visible') {
      let hiddenData = data.modelHiden || []
      let visible4DModels = data.visible4DModels || []
      let visibleClipModels = data.visibleClipModels || []

      //model
      const modelList = projectStore.modelList.length ? projectStore.modelList : projectStore.projectDetail.model3DS
      let tempModel3D = toJS(modelList).map(item => {
        return { modelId: item._id, isVisible: true, isRender: item.isRender, style: item.style, type: item.type, sourceType: item.sourceType, data: item.data, isVisible4D: true, endDate: item.endDate, startDate: item.startDate, isVisibleClip: true, }
      })

      if ((hiddenData && hiddenData.length > 0) || (visible4DModels && visible4DModels.length > 0) || (visibleClipModels && visibleClipModels.length > 0)) {
        const _visibletileset = tempModel3D.map(function (newData) {
          let isExist = hiddenData.find(c => c.modelId === newData.modelId)
          let isExist4D = visible4DModels && visible4DModels.length > 0 ? visible4DModels.find(c => c.type4D === 'model' && c.modelId === newData.modelId) : false
          let isExistClip = visibleClipModels && visibleClipModels.length > 0 ? visibleClipModels.find(c => c.modelId === newData.modelId) : false
          if (isExist) {
            newData.isVisible = false
            newData.isTempHidden = isExist.isTempHidden
          }
          if (isExist4D) {
            newData.isVisible4D = false
          }
          if (isExistClip) {
            newData.isVisibleClip = false
          }
          return newData
        });
        projectStore.setVisibleTilesets(_visibletileset)
      } else if ((hiddenData && hiddenData.length === 0) && (visible4DModels && visible4DModels.length === 0) && (visibleClipModels && visibleClipModels.length === 0)) {
        projectStore.setVisibleTilesets(tempModel3D)
      }

      //sketch
      let tempSketch = toJS(sketchingStore.arrSketches).map(item => {
        return { sketchId: item._id, isVisible: true, isVisible4D: true, endDate: item.endDate, startDate: item.startDate }
      })
      if ((data.visibleSketches && data.visibleSketches?.length > 0) || (visible4DModels && visible4DModels.length > 0)) {
        let hiddenData = data?.visibleSketches || []
        const _visiblesketch = tempSketch.map(function (newData) {
          let isExist = hiddenData?.find(c => c.sketchId === newData.sketchId)
          let isExist4D = visible4DModels.find(c => c.type4D === 'sketch' && c.sketchId === newData.sketchId)
          if (isExist) {
            newData.isVisible = false
            newData.isTempHidden = isExist.isTempHidden
          }
          if (isExist4D) {
            newData.isVisible4D = false
          }
          return newData
        });
        sketchingStore.setVisibleSketches(_visiblesketch)
      } else {
        sketchingStore.setVisibleSketches(tempSketch)
      }

      // project link
      let listProjectLink = toJS(projectStore.listProjectLink)

      data.projectLinks.map(projectLink => {
        listProjectLink = listProjectLink.map(item => {
          if (item.id === projectLink?.projectLinkId) {
            item.visibleData = projectLink.visibleData
            const index = projectLink.visibleData?.findIndex(x => x.userId === usersStore.currentUser?.id)
            if (index > -1 && projectLink.visibleData[index]) {
              item.isVisible = projectLink.visibleData[index].isVisible
              item.isVisible4D = projectLink.visibleData[index].isVisible4D
              item.isVisibleClip = projectLink.visibleData[index].isVisibleClip
            }
          }
          return item
        })
      })
      projectStore.setListProjectLink(listProjectLink)
    }

    if (type === 'deleteViewpoint') {
      if (data.viewpointId) {
        if(!projectStore.projectDetail?.id) return
        capturesStore.toggleDrawerCaptureVisible(true)
        capturesStore.setUpdateDataFromSocket(data)
      }
    }

    if (type === 'updateViewpoint') {
      if (data.viewpointId) {
        if(!projectStore.projectDetail?.id) return
        capturesStore.toggleDrawerCaptureVisible(true)
        capturesStore.setUpdateDataFromSocket(data)
      }
    }

    if (type === 'activeViewpoint') {
      if (data.viewpointId) {
        if(!projectStore.projectDetail?.id) return
        capturesStore.toggleDrawerCaptureVisible(true)
        capturesStore.setUpdateDataFromSocket(data)
      }
    }

    if (type === "getSavedViews") {
      if (data?.id || data?.projectId) {
        await capturesStore.getCaptureList(data?.id || data?.projectId).then(() => {
          socket.emit("read-data", {
            apiKey: usersStore.currentUser.apiKey,
            data: { captureList: capturesStore.captureList },
            type: 'read-captureList',
            sesstionId: authStore.sesstionId
          });
        })
      }
    }
    if (type === 'request-camera' || type === 'request-screenShot') {

      if (commonStore.cameraHeadingPitchRoll && commonStore.cameraPosition) {
        const camdata = {
          "position": {
            // "lat": 2793673.7524389,
            // "lng": 1226739.9502562878,
            // "elevation": 5583139.635188798
          },
          "orientation": {
            // "heading ": -0.3974566481218389,
            // "pitch": -0.914086963592685,
            // "roll": -0.08045642207840209
          },
          "fov": {}
        }
        var x = new Cartesian3(
          commonStore.cameraPosition.x,
          commonStore.cameraPosition.y,
          commonStore.cameraPosition.z)
        const camPos = Cartographic.fromCartesian(x);
        camdata.position = {
          lat: camPos.latitude,
          lng: camPos.longitude,
          elevation: camPos.height
        }
        camdata.orientation = {
          heading: commonStore.cameraHeadingPitchRoll.heading,
          pitch: commonStore.cameraHeadingPitchRoll.pitch,
          roll: commonStore.cameraHeadingPitchRoll.roll
        }
        camdata.fov = { fov: commonStore.cameraFov }

        socket.emit("read-data", {
          apiKey: usersStore.currentUser.apiKey,
          data: camdata,
          type: 'read-camera',
          sesstionId: authStore.sesstionId
        });
      }
      if (type === 'request-screenShot') {
        onScreenShot()
      }
    }
    if (type === 'request-time') {
      socket.emit("read-data", {
        apiKey: usersStore.currentUser.apiKey,
        data: schedulingStore.currentViewingTime,
        type: 'read-time',
        sesstionId: authStore.sesstionId
      });

    }
    if (type === 'set-time') {
      if (!schedulingStore.timeSliderVisible) {
        schedulingStore.toggleTimeSliderVisible(true)
      }
      schedulingStore.setCurrentViewingTime(data)
      schedulingStore.setOriginViewingTime(data)
      schedulingStore.setBeforeTime(data)
      schedulingStore.setAfterTime(data)
    }
    // if (type === 'request-4d-time') {
    //   schedulingStore.toggleTimeSliderVisible(true)
    //   schedulingStore.setCurrentViewingTime(data.)
    // }
    if (type === 'toggle-shadow') {
      commonStore.setenableShadows(data)
      projectSettingStore.setAssignObjSystemProjectSetting("enableShadows", data)
      let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
      updateProjectSetting(metadata, 'socket')
      const switchElement = document.getElementById('switch-enableShadows');
      switchElement.click();
    }
  }
  const setupApiKeyConnect = async function () {
    if (!usersStore.currentUser.id && commonStore.checkToken()) {
      await usersStore.getCurrentUser()
    }
    if (usersStore.currentUser && usersStore.currentUser.id && usersStore.currentUser.apiKey) {
      if (!usersStore.joinAPIRoom) {
        usersStore.setjoinAPIRoom()
        try {
          socket.emit("read-data", {
            apiKey: usersStore.currentUser.apiKey,
            data: { isSocketReady: usersStore.currentUser.apiKey && true },
            type: 'socket-ready',
            sesstionId: authStore.sesstionId
          });
        } catch (error) {
        }
        // socket.emit("join", {
        //   apiKey: usersStore.currentUser.apiKey,
        //   token: commonStore.token,
        //   sesstionId: authStore.sesstionId
        // });
        socket.on("apiNotification", msg => {
          projectStore.setIsEditSessionVisibleData(true)
          handleAPINotification(msg)
        })
      }
    }
  }
  useEffect(() => {
    setupApiKeyConnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])

  const updateProjectSetting = async function (metadata, type) {
    if (usersStore.currentUser?.id) {
      if (currentPage === 'projectDetail' || type === 'socket') {
        projectStore.setLoadingProgress(true)
        projectStore.updateProjectMetadata({ metadata }).then(res => {
          projectStore.setLoadingProgress(false)
        })
          .catch(err => {
            projectStore.setLoadingProgress(false)
          })
      }
    }
  }

  useEffect(() => {
    if (currentPage === 'projectDetail' && usersStore.currentUser.id && !projectStore.dropDownMenuCameraSettings && !projectSettingStore.openCameraSetting && !projectSettingStore.openSensorSetting && !projectStore.renderResolutionControl && !uiStore.showIFCSetting && !uiStore.showAmbientOccSetting && !uiStore.showTransparentSetting && !uiStore.showFogSetting && !projectStore.showBackgroundSetting && !projectStore.showDrawerConnectNavigation && !uiStore.showLightSetting) {
      let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
      updateProjectSetting(metadata)
    } else if (form && projectStore.dropDownMenuCameraSettings) {
      let _ps = projectSettingStore.systemProjectSetting
      form.setFieldsValue({
        enableShadows: _ps?.enableShadows,
        requestRender: _ps?.requestRender,
        showAmbientOcclusion: _ps?.showAmbientOcclusion,
        showAntialiasing: _ps?.showAntialiasing,
        showRefPoint: _ps?.showRefPoint,
        showBoudingVolume: _ps?.showBoudingVolume,
        showHUD: _ps?.showHUD,
        showInspectorTool: _ps?.showInspectorTool,
        showMap: _ps?.showMap
      })
      if (projectStore.typeViewPoint) {
        form.setFieldsValue({
          enableShadows: commonStore.enableShadows,
          showMap: projectStore.showMap
        })
      }
    }
  }, [projectStore.dropDownMenuCameraSettings])

  useEffect(() => {
    if (projectStore.projectDetail?.id) {
      let _meta = toJS(projectStore.projectDetail.metadata?.projectSetting);
      let _metadata = toJS(projectStore.projectDetail?.metadata);
      let _ps
      if (_meta && _meta.length > 0 && usersStore.currentUser.id) {

        let _current = _meta.find(elm => elm.userid === usersStore.currentUser.id)
        if (_current) {
          _ps = _current.projectSetting
        } else {
          _ps = projectSettingStore.defaultSystemProjectSetting
        }
        if (projectStore.typeViewPoint) {
          _ps.nearValue = commonStore.nearValue
          _ps.nearDistance = commonStore.nearDistance
          _ps.farDistance = commonStore.farDistance
          _ps.enableShadows = commonStore.enableShadows
          _ps.showMap = projectStore.showMap
        }
        // console.log('_ps',toJS(_ps));
        if(_metadata.ifcSetting){
          _ps.ifcSetting = _metadata.ifcSetting
        }
        if(_metadata.sensorSetting){
          _ps.sensorFrequences = _metadata.sensorSetting?.sensorFrequences
          _ps.sampleRange = _metadata.sensorSetting?.sampleRange
          _ps.smoothFilterType = _metadata.sensorSetting?.smoothFilterType
        }
        projectSettingStore.setSystemProjectSetting(_ps)
      } else {
        _ps = projectSettingStore.defaultSystemProjectSetting
        if (projectStore.typeViewPoint) {
          _ps.nearValue = commonStore.nearValue
          _ps.nearDistance = commonStore.nearDistance
          _ps.farDistance = commonStore.farDistance
          _ps.enableShadows = commonStore.enableShadows
          _ps.showMap = projectStore.showMap
        }
        if(_metadata.ifcSetting){
          _ps.ifcSetting = _metadata.ifcSetting
        }
        if(_metadata.sensorSetting){
          _ps.sensorFrequences = _metadata.sensorSetting?.sensorFrequences
          _ps.sampleRange = _metadata.sensorSetting?.sampleRange
          _ps.smoothFilterType = _metadata.sensorSetting?.smoothFilterType
        }
        projectSettingStore.setSystemProjectSetting(_ps)
      }
      projectStore.setGlobeBehind(_ps?.globeBehind)
    }
  }, [projectStore.projectDetail, projectSettingStore.defaultSystemProjectSetting, projectStore.targetViewPoint])

  useEffect(() => {
    try {
      socket.emit("read-data", {
        apiKey: usersStore.currentUser.apiKey,
        data: { captureList: capturesStore.captureList },
        type: 'read-captureList',
        sesstionId: authStore.sesstionId
      });
    } catch (error) {
    }
  }, [capturesStore.captureList, capturesStore.clickAdd, capturesStore.isLoading])

  useEffect(() => {
    try {
      socket.emit("read-data", {
        apiKey: usersStore.currentUser.apiKey,
        data: schedulingStore.currentViewingTime,
        type: 'read-time',
        sesstionId: authStore.sesstionId
      });
    } catch (error) {
    }
  }, [schedulingStore.currentViewingTime])

  const clickShowExplorer = () => {
    projectStore.setDisplayPanel(true)
  }

  const clickAddProject = () => {
    projectStore.setShowAddProjectModal(true)
  }

  const showDataAttributeModal = () => {
    projectStore.setDataAttributeModal(!projectStore.dataAttributeModal)
  }
  const handleShowProcessInBackground = () => {

    if (projectStore.checkModalStatus) {
      projectStore.setShowProcessInBackground(false)
      projectStore.setShowAddResourceModel(true)

    } else {
      projectStore.setShowProcessInBackground(false)
      fileStore.setModalDrop3DView(true)
    }
  }

  const clickShowMainDrawer = () => {
    commonStore.setShowMainDrawer(true)
  }

  function onChangeBoudingVolume(checked) {
    projectStore.setShowBoudingVolume(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("showBoudingVolume", checked)
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    updateProjectSetting(metadata)
  }

  function onChangeFOV(checked) {
    projectStore.setRenderRecordDataControl(false)
    projectStore.setShowFov(checked)
    commonStore.setopenSensorSetting(false)
    projectSettingStore.setOpenCameraSetting(true)
  }
  function onChangeBackgroundColor(checked) {
    projectStore.setShowBackgroundSetting(checked)
    projectStore.setDropDownMenuCameraSettings(false)
    projectStore.setCleanMode(true)
  }
  function onOpenSensorSetting(checked) {
    projectStore.setRenderRecordDataControl(false)
    projectStore.setShowFov(checked)
    projectSettingStore.setOpenSensorSetting(true)
    commonStore.setopenSensorSetting(true)
  }
  function onChangeShowMap(checked) {
    projectStore.setShowMap(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("showMap", checked)
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    updateProjectSetting(metadata)
  }
  function onChangeShowAntialiasing(checked) {
    projectStore.setShowAntialiasing(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("showAntialiasing", checked)
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    updateProjectSetting(metadata)
  }
  function onChangeShowRefpoint(checked) {
    projectStore.setShowRefPoint(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("showRefPoint", checked)
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    updateProjectSetting(metadata)
  }
  function onChangeShadowOption(checked) {
    commonStore.setenableShadows(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("enableShadows", checked)
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    updateProjectSetting(metadata)
  }
  function onChangeShowHUD(checked) {
    projectStore.setShowHUD(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("showHUD", checked)
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    updateProjectSetting(metadata)
  }

  function onChangeRequestRender(checked) {
    uiStore.setRequestRender(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("requestRender", checked)
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    updateProjectSetting(metadata)
  }

  function onChangeShowAmbientOcclusion(checked) {
    projectStore.setShowAmbientOcclusion(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("showAmbientOcclusion", checked)
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    updateProjectSetting(metadata)
  }
  function onChangeShowInpectorTool(checked) {
    projectStore.setShowInspectorTool(checked)
    projectSettingStore.setAssignObjSystemProjectSetting("showInspectorTool", checked)
    let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
    updateProjectSetting(metadata)
  }

  function onScreenShot() {
    if(!projectStore.projectDetail?.id) return
    capturesStore.toggleDrawerCaptureVisible(true)
    capturesStore.setClickAdd(true)
  }

  useEffect(() => {
    try {
      if (projectStore.projectDetail?.id) {

        projectStore.setShowInspectorTool(projectSettingStore.systemProjectSetting['showInspectorTool'])
        projectStore.setShowAmbientOcclusion(projectSettingStore.systemProjectSetting['showAmbientOcclusion'])
        commonStore.setBackgroundColorSetting(projectSettingStore.systemProjectSetting['backgroundColorSetting'])
        uiStore.setRequestRender(projectSettingStore.systemProjectSetting['requestRender'])
        projectStore.setShowHUD(projectSettingStore.systemProjectSetting['showHUD'])

        const renderResolution = projectSettingStore.systemProjectSetting['renderResolution']
        // console.log('renderResolution',toJS(renderResolution));
        if (renderResolution) {
          renderResolution.maximumScreenSpaceError && projectStore.setMaximumScreenSpaceError(renderResolution.maximumScreenSpaceError)
          renderResolution.gmlmaximumScreenSpaceError && projectStore.setgmlMaximumScreenSpaceError(renderResolution.gmlmaximumScreenSpaceError)
          renderResolution.maxCountOfVisibleTiles && projectStore.setMaxCountOfVisibleTiles(renderResolution.maxCountOfVisibleTiles)
          renderResolution.maximumAttenuation && projectStore.setMaximumAttenuation(renderResolution.maximumAttenuation)
          renderResolution.geometricErrorScale && projectStore.setGeometricErrorScale(renderResolution.geometricErrorScale)
          renderResolution.eyeDomeLightingRadius && projectStore.setEyeDomeLightingRadius(renderResolution.eyeDomeLightingRadius)
          renderResolution.eyeDomeLightingStrength && projectStore.setEyeDomeLightingStrength(renderResolution.eyeDomeLightingStrength)
          renderResolution.shadowDarkness && projectStore.setShadowDarkness(renderResolution.shadowDarkness)
          renderResolution.shadowDistance && projectStore.setShadowDistance(renderResolution.shadowDistance)
          renderResolution.shadowAccuracy && projectStore.setShadowAccuracy(renderResolution.shadowAccuracy)
          renderResolution.softShadows && projectStore.setSoftShadows(renderResolution.softShadows)
        }
        projectStore.setShowAntialiasing(projectSettingStore.systemProjectSetting['showAntialiasing'])
        projectStore.setShowRefPoint(projectSettingStore.systemProjectSetting['showRefPoint'])

        projectStore.setShowBoudingVolume(projectSettingStore.systemProjectSetting['showBoudingVolume'])
        if (projectStore.currentViewpoint?.id) {

        }
        else {
          projectStore.setShowMap(projectSettingStore.systemProjectSetting['showMap'])
          commonStore.setenableShadows(projectSettingStore.systemProjectSetting['enableShadows'])
        }
      }
    } catch (error) {
      console.error(t('unable-to-read-system-setting'))
    }
  }, [projectSettingStore.systemProjectSetting])

  function onChangeConnectNavigation(checked) {
    projectStore.setDropDownMenuCameraSettings(false)
    projectStore.setRenderRecordDataControl(false)
    projectStore.setShowDrawerConnectNavigation(checked)
  }

  function onChangeRenderResolution() {
    projectStore.setDropDownMenuCameraSettings(false)
    projectStore.setRenderRecordDataControl(false)
    projectStore.setShowRenderResolutionControl(!projectStore.renderResolutionControl)
  }

  function onChangeRecordData() {
    projectStore.setDropDownMenuCameraSettings(false)
    projectStore.setShowRenderResolutionControl(false)
    projectStore.setRenderRecordDataControl(!projectStore.renderResolutionControl)
  }

  function onClickIFCSetting() {
    projectStore.setCleanMode(true)
    projectStore.setDropDownMenuCameraSettings(false)
    uiStore.setShowIFCSetting(true)
  }
  function onClickAmbientOccSetting() {
    uiStore.setShowAmbientOccSetting(true)
    projectStore.setDropDownMenuCameraSettings(false)
    projectStore.setCleanMode(true)
  }

  function onClickTransparentSetting() {
    uiStore.setShowTransparentSetting(true)
    projectStore.setDropDownMenuCameraSettings(false)
    projectStore.setCleanMode(true)
  }

  function onClickFogSetting() {
    uiStore.setShowFogSetting(true)
    projectStore.setDropDownMenuCameraSettings(false)
    projectStore.setCleanMode(true)
  }

  function onClickLightSetting() {
    uiStore.setShowLightSetting(true)
    projectStore.setDropDownMenuCameraSettings(false)
    projectStore.setCleanMode(true)
  }

  const checkingFeatureRole = (type) => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
}

  const  [isHover, setIsHover] = useState(false)
  const  [isFocused, setIsFocused] = useState(false);
  const  [distanceTop, setDistanceTop] = useState(0);
  const  [heightLogo, setHeightLogo] = useState(64);
  const  [widthLogo, setWidthLogo] = useState(64);
  const [searchValue, setSearchValue] = useState(false);

  useEffect(() => {

    const handleHover = () => {
      setIsHover(true)
    };
  
    const handleHoverLeave = () => {
      setIsHover(false)
    };
  
    const handleFocus = () => {
      setIsFocused(true);
    };
  
    const handleBlur = () => {
      setIsFocused(false);
    };

    const handleInput = (event) => {
      const userInputValue = event.target.value;
      setSearchValue(userInputValue)
    };
  
    const geocoderContainer = document.querySelector('.cesium-viewer-geocoderContainer');

    if (geocoderContainer) {
      geocoderContainer.addEventListener('mouseenter', handleHover);
      geocoderContainer.addEventListener('mouseleave', handleHoverLeave);
    }
  
    const inputField = document.querySelector('.cesium-viewer-geocoderContainer .cesium-geocoder-input');
  
      if (inputField) {
        inputField.addEventListener('focus', handleFocus);
        inputField.addEventListener('blur', handleBlur);
        inputField.addEventListener('input', handleInput);
      }
  
    return () => {
      if (geocoderContainer) {
        geocoderContainer.removeEventListener('mouseenter', handleHover);
        geocoderContainer.removeEventListener('mouseleave', handleHoverLeave);
      }
      if (inputField) {
        inputField.removeEventListener('focus', handleFocus);
        inputField.removeEventListener('blur', handleBlur);
        inputField.removeEventListener('input', handleInput);
        setSearchValue()
      }
    };
  }, []);

  const renderClass = () => {
    const modelList = projectStore.modelList.length ? projectStore.modelList : (projectStore?.projectDetail?.model3DS || []);
    const visibletilesets = modelList.filter(c => c?.data?.isPhotorealistic3DTile || (c?.data?.cesiumToken && c?.data?.ionAssetId === 2275207));
    if (!visibletilesets.length) return;
    return visibletilesets.some(model => projectStore?.visibleTilesets?.find(c => c?.modelId === model?._id && c?.isVisible));
  }

  const isMobileLayout = isPortrait || isMobileOnly;
  const helpButtonStyles = {
    className: isMobileLayout ? 'help-btn-outer-mb' : 'help-button-outer',
    fontSize: isMobileLayout ? 18 : 25,
    helppage : projectStore.currentHelpfeature[projectStore.currentHelpfeature?.length - 1] ,
    buttonStyles: {
      color: 'white',
      padding: !isMobileLayout && '5px', 
    },
    isResize : isHover || isFocused || searchValue
  };

  const maximumWidth = isSmallMonitor ? 75 : 150;
  const maximumHeight = isSmallMonitor ? 75 : 150;
  useEffect(() => {
    const logoPos = document.querySelector('.custom-logo-xdtwin');
    if (logoPos) {
      const {offsetTop ,offsetHeight } = logoPos;
      setDistanceTop(offsetTop + offsetHeight + 7);
    }
    if(projectStore?.isChangeLogoXDTwin?.logo){
      const {height,width} = projectStore?.isChangeLogoXDTwin?.logo;
      setHeightLogo(height > maximumHeight ? maximumHeight : height)
      setWidthLogo(width > maximumWidth ? maximumWidth : width)
    }
    return () => {
    };
  }, [projectStore.isChangeLogoXDTwin,widthLogo,maximumWidth]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let distanceLeft = widthLogo + 7 + 5;
  const isWrapManagementTop = useMemo(() => {
    let isExistBack = projectStore?.pastLocations?.length > 0 && projectStore.cameraLoadFinish;
    let tools = 0;
    if(usersStore.currentUser && usersStore.currentUser.role && usersStore.currentUser.role.type === 'web_admin') tools +=1;
    if(currentPage === 'projectDetail' && checkingFeatureRole("feature_organization") && (usersStore.currentUser) && usersStore.currentUser.organizationuserroles && usersStore.currentUser.organizationuserroles.length > 0) tools +=1;
    if(currentPage === 'projectDetail' && checkingFeatureRole("feature_profile") && usersStore?.currentUser?.id ) tools +=1;
    if(checkingFeatureRole("feature_projects") && (localStorage.getItem('jwt') || sessionStorage.getItem('jwt'))) tools +=1;
    const paddingLeft = 7;
    const margin = 5;
    const itemW = 37;
    const leftW = paddingLeft + widthLogo + margin + itemW*tools - 5;
    const rightFull = ((isPortrait || isMobileOnly) ? 175 : 266) + (isExistBack ? 37 : 0) ;
    const rightSearchFull = ((isPortrait || isMobileOnly) ? 400 : 370) + (isExistBack ? 37 : 0) ;
    let isWrap = windowWidth < (leftW + rightFull + 10) ? true :  false;
    if(!isHover && !isFocused && !searchValue && windowWidth < (leftW + rightFull + 10 ) ) isWrap =  true; 
    if((isHover || isFocused || searchValue) && windowWidth <(rightSearchFull + leftW + 10)  ) isWrap =  true;
    let element = document.querySelector('.projectSettingGroup');
    if(isWrap && element){
        const elementWidth = element.offsetWidth;
        distanceLeft = elementWidth + 7
    }
    return isWrap;
  }, [isHover,isFocused, windowWidth,projectStore?.pastLocations?.length,searchValue])

  const viewByPage = () => {
    const defaultButton = (
      <Fragment>
        {/* <Tooltip title="Ideas portal" placement="bottom">
          <AvatarCustom
            activated={1}
            shape="square"
            icon={<SVGIcon content={<IdeasPortal />} width={32} height={32} />}
            onClick={() => {
              window.open('http://xd-twin.ideas.aha.io/', '_blank')
              window.focus()
            }}
          />
        </Tooltip>
        <Tooltip title="Help" placement="bottom">
          <AvatarCustom
            activated={1}
            shape="square"
            icon={<SVGIcon content={<Help />} width={32} height={32} />}
          />
        </Tooltip> */}
        <ToolbarTopCustom activated={0}>
          {(usersStore.currentUser && usersStore.currentUser.role && usersStore.currentUser.role.type === 'web_admin') &&
            <Tooltip title={t('system-admin')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                style={{ verticalAlign: 0 }}
                icon={<SVGIcon content={<AdminIcon />} width={32} height={32} />}
                onClick={() => history.push(`/admin/organizations/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`)}
              />
            </Tooltip>
          }
        </ToolbarTopCustom>

        <ToolbarTopCustom activated={0}>
          {currentPage === 'projectDetail' ? 
          ((usersStore.currentUser) && usersStore.currentUser.organizationuserroles && usersStore.currentUser.organizationuserroles.length > 0) && checkingFeatureRole("feature_organization") &&
            <Tooltip title={t('organization')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                style={{ verticalAlign: 0 }}
                onClick={() => { commonStore.setShowOrganizationPageDrawer(true); sketchingStore.setSketchToolVisible(false); uiStore.setClippingPlaneToolVisible(false); }}
                icon={<SVGIcon content={<UsersIcon />} width={32} height={32} />}
              />
            </Tooltip>:
            ((usersStore.currentUser) && usersStore.currentUser.organizationuserroles && usersStore.currentUser.organizationuserroles.length > 0) &&
            <Tooltip title={t('organization')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                style={{ verticalAlign: 0 }}
                onClick={() => { commonStore.setShowOrganizationPageDrawer(true); sketchingStore.setSketchToolVisible(false); uiStore.setClippingPlaneToolVisible(false); }}
                icon={<SVGIcon content={<UsersIcon />} width={32} height={32} />}
              />
            </Tooltip>

          }
        </ToolbarTopCustom>

        <ToolbarTopCustom activated={0}>
          {
            currentPage === 'projectDetail' ? 
          ((usersStore.currentUser) && usersStore.currentUser.id) && checkingFeatureRole("feature_profile") &&
            <Tooltip title={t('profile')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                style={{ verticalAlign: 0 }}
                icon={<SVGIcon content={<ProfileIcon />} width={32} height={32} />}
                onClick={() => { commonStore.setShowUserSettingPageDrawer(true); sketchingStore.setSketchToolVisible(false) }}
              />
            </Tooltip>
            : ((usersStore.currentUser) && usersStore.currentUser.id) &&
            <Tooltip title={t('profile')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <Button
                style={{ verticalAlign: 0 }}
                icon={<SVGIcon content={<ProfileIcon />} width={32} height={32} />}
                onClick={() => { commonStore.setShowUserSettingPageDrawer(true); sketchingStore.setSketchToolVisible(false) }}
              />
            </Tooltip>
          }
        </ToolbarTopCustom>

      </Fragment>
    )

    switch (currentPage) {
      case 'cityDemo':
        return (
          <Fragment>
            <Avatar.Group maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              {defaultButton}
              <ToolbarTopItem>
                {' '}
                <Button icon={<DownloadOutlined />} size={commonStore.buttonSize} />
              </ToolbarTopItem>
            </Avatar.Group>
          </Fragment>
        )
      case 'projects':
        return (
          <Fragment>
            <Avatar.Group maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              {defaultButton}
              <ToolbarTopCustom>
                <Tooltip title={t('add-new-project')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                  <Button
                    style={{ verticalAlign: 0 }}
                    icon={<SVGIcon content={<ProjectAdd />} width={32} height={32} />}
                    onClick={clickAddProject}
                  />
                </Tooltip>
              </ToolbarTopCustom>
              {projectStore.showProcessInBackground &&
                <Tooltip placement="bottom" title={t('wait-for-the-server-to-process-the-file')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                  <Button shape="circle"><Spin onClick={handleShowProcessInBackground} /></Button>
                </Tooltip>
              }
            </Avatar.Group>

          </Fragment>
        )
      case 'projectDetail':
        return projectStore.projectDetail?.id ? (
          <Fragment>
            {usersStore.currentUser && usersStore.currentUser.id &&
              <ManagementGroup 
              // className='desktop-style'
              style={{ display: 'inline-flex' }}
              wrap={isWrapManagementTop || undefined}
              distanceLeft={distanceLeft}
              distanceTop = { distanceTop }
              >
                {defaultButton}
                <ToolbarTopCustom activated={0}>
                  {((!projectStore.visitedMode || currentPage !== 'projectDetail' || (localStorage.getItem('jwt') || sessionStorage.getItem('jwt'))) && checkingFeatureRole("feature_projects")) &&
                    <Tooltip title={t('projects')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                      <Button
                        style={{ verticalAlign: 0 }}
                        icon={<SVGIcon content={<ProjectsIcon />} width={32} height={32} />}
                        onClick={() => history.push('/projects')}
                      />
                    </Tooltip>
                  }
                </ToolbarTopCustom>

                {projectStore.showProcessInBackground &&
                  <Tooltip placement="bottom" title={t('wait-for-the-server-to-process-the-file')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                    <Button shape="circle"><Spin onClick={handleShowProcessInBackground} /></Button>
                  </Tooltip>
                }
              </ManagementGroup>
            }
            
            <Avatar.Group className={'data-attribtuon-outer'}>
              {
                renderClass() && <div style={{ display: "inline", marginRight: 5 }}><span><img src={GoogleLogo} alt="Google" /></span></div>
              }
              {
                (isPortrait || isMobileOnly) ?
                  <InfoCircleOutlined onClick={showDataAttributeModal} style={{ fontSize: 18 }} /> :
                  <span onClick={showDataAttributeModal}>{t('sources')}</span>
              }
            </Avatar.Group>

            {/* help setting */}
            <HelpButtonCustom isResize={helpButtonStyles.isResize}>
            <Avatar.Group className={helpButtonStyles.className} >
                <HelpButton fontsize={helpButtonStyles.fontSize} styles={helpButtonStyles.buttonStyles} helppage={helpButtonStyles.helppage}/>
            </Avatar.Group>
            
            </HelpButtonCustom>
            {/* project setting , system setting tool */}
            <ProjectSettingGroup
              className={(usersStore.currentUser && usersStore.currentUser.id) ? 'projectSettingGroup desktop-style' : 'projectSettingGroup desktop-style css-public custom-system-setting'}
              // style={ { display: 'inline-flex' }}
              style={(isMobileOnly && !isPortrait) ? { display: 'inline-flex' } : {}}
              distanceTop ={distanceTop}
            >
              {
                checkingFeatureRole("feature_system_setting") && (
                  <ToolbarTopCustom activated={0}>
                  <Tooltip title={t('settings')} placement='right' overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                    <Dropdown
                      onClick={() => {
                        capturesStore.toggleDrawerCaptureVisible(false)
                        projectStore.setDropDownMenuCameraSettings(true)
                        sketchingStore.setSketchToolVisible(false)
                        uiStore.setClippingPlaneToolVisible(false);
                      }}
                      visible={projectStore.dropDownMenuCameraSettings}
                      onVisibleChange={state => projectStore.setDropDownMenuCameraSettings(state)}
                      overlayClassName='custom-ant-dropdownmenu model-setting-dropdownmenu'
                      overlay={<div className={'menu-wrapper'} style={{ display: projectStore.cleanMode ? 'none' : 'block', }}>
                        <Collapse defaultActiveKey={['general']} accordion>
                          <Panel header={t('general')} key="general">
                            <Form form={form} layout="vertical" onFinish={(value) => console.log(value)}>
                              <div className={'setting-custom'}>
                                <span>{t('globe')}</span>
                                <Form.Item
                                  name="showMap"
                                  valuePropName="checked"
                                  className="checkbox-status"
                                >
                                  <Switch
                                    onChange={onChangeShowMap}
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    size={'small'}
                                  />
                                </Form.Item>

                              </div>
                              <div className={'setting-custom'}>
                                <span>{t('project-plane')}</span>
                                <Form.Item
                                  name="showRefPoint"
                                  valuePropName="checked"
                                  className="checkbox-status"
                                >
                                  <Switch
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    size={'small'}
                                    onChange={onChangeShowRefpoint}
                                  />
                                </Form.Item>

                              </div>
                              <div className={'setting-custom'}>
                                <span>{t('antialiasing')}</span>
                                <Form.Item
                                  name="showAntialiasing"
                                  valuePropName="checked"
                                  className="checkbox-status"
                                >
                                  <Switch
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    size={'small'}
                                    onChange={onChangeShowAntialiasing}
                                  />
                                </Form.Item>

                              </div>
                              <div className={'setting-custom'}>
                                <span>{t('shadows')}</span>
                                <Form.Item
                                  name="enableShadows"
                                  valuePropName="checked"
                                  className="checkbox-status"
                                >
                                  <Switch
                                    id='switch-enableShadows'
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    size={'small'}
                                    onChange={onChangeShadowOption}
                                  />
                                </Form.Item>

                              </div>
                              <div className={'setting-custom'}>
                                <span>HUD</span>
                                <Form.Item
                                  name="showHUD"
                                  valuePropName="checked"
                                  className="checkbox-status"
                                >
                                  <Switch
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    size={'small'}
                                    onChange={onChangeShowHUD}
                                  />
                                </Form.Item>

                              </div>
                              <div className={'setting-custom'}>
                                <span>{t('save-battery')}</span>
                                <Form.Item
                                  name="requestRender"
                                  valuePropName="checked"
                                  className="checkbox-status"
                                >
                                  <Switch
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    size={'small'}
                                    onChange={onChangeRequestRender}
                                  />
                                </Form.Item>
                              </div>
                              <div className={'setting-custom'}>
                                <span>{t('ambient-occlusion')}</span>
                                <Form.Item
                                  name="showAmbientOcclusion"
                                  valuePropName="checked"
                                  className="checkbox-status"
                                >
                                  <Switch
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    size={'small'}
                                    onChange={onChangeShowAmbientOcclusion}
                                  />
                                </Form.Item>
                              </div>
                              <div className={'setting-custom'}>
                                <span>{t('inspector-tool')}</span>
                                <Form.Item
                                  name="showInspectorTool"
                                  valuePropName="checked"
                                  className="checkbox-status"
                                >
                                  <Switch
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    size={'small'}
                                    onChange={onChangeShowInpectorTool}
                                  />
                                </Form.Item>
                              </div>
                              <div className={'setting-custom'}>
                                <span>{t('bounding-volume')}</span>
                                <Form.Item
                                  name="showBoudingVolume"
                                  valuePropName="checked"
                                  className="checkbox-status"
                                >
                                  <Switch
                                    checkedChildren={t('commons.on')}
                                    unCheckedChildren={t('commons.off')}
                                    size={'small'}
                                    onChange={onChangeBoudingVolume}
                                  />
                                </Form.Item>

                              </div>                              
                            </Form>
                          </Panel>
                          <Panel header={t('setting')} key="setting">
                            <div className={'setting-row'}>
                              <span>{t('background-color')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onChangeBackgroundColor}>
                                {t('settings')}
                              </Button>
                            </div>
                            <div className={'setting-row'}>
                              <span>{t('camera-setting')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onChangeFOV}>
                                {t('settings')}
                              </Button>
                            </div>
                            <div className={'setting-row'}>
                              <span>{t('sensor-setting')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onOpenSensorSetting}>
                                {t('settings')}
                              </Button>
                            </div>
                            <div className={'setting-row'}>
                              <span>{t('navigation')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onChangeConnectNavigation}>
                                {t('settings')}
                              </Button>
                            </div>
                            <div className={'setting-row'}>
                              <span>{t('render-settings')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onChangeRenderResolution}>
                                {t('settings')}
                              </Button>
                            </div>
                            <div className={'setting-row'}>
                              <span>{t('record-data')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onChangeRecordData}>
                                {t('settings')}
                              </Button>
                            </div>

                            <div className={'setting-row'}>
                              <span>{t('commons.import')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onClickIFCSetting}>
                                {t('settings')}
                              </Button>
                            </div>
                            <div className={'setting-row'}>
                              <span>{t('ambient-occlusion')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onClickAmbientOccSetting}>
                                {t('settings')}
                              </Button>
                            </div>
                            <div className={'setting-row'}>
                              <span>{t('globe')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onClickTransparentSetting}>
                                {t('settings')}
                              </Button>
                            </div>
                            <div className={'setting-row'}>
                              <span>{t('fog')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onClickFogSetting}>
                                {t('settings')}
                              </Button>
                            </div>
                            <div className={'setting-row'}>
                              <span>{t('lighting')}</span>
                              <Button
                                size={'small'}
                                type="primary"
                                onClick={onClickLightSetting}>
                                {t('settings')}
                              </Button>
                            </div>
                          </Panel>
                        </Collapse>
                      </div>}
                      trigger={['click']}
                    >
                      <Button
                      style={{ verticalAlign: 0 }}
                        icon={<SVGIcon content={<Settings />} width={32} height={32} />}
                        onClick={e => e.preventDefault()}
                      />
                    </Dropdown>
                  </Tooltip>
                  </ToolbarTopCustom>
                )
              }
              <ToolbarTopCustom>
                {(projectStore.currentUserRoleInProject === "project_owner" || (!projectStore.visitedMode && checkingFeatureRole("feature_project_setting"))) &&
                  <Tooltip title={t('project')} placement="right" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                    <Button
                      style={{ verticalAlign: 0 }}
                      icon={<SVGIcon content={<ProjectSetting />} width={32} height={32} />}
                      onClick={() => { projectSettingStore.toggleProjectSettingDrawer(true); sketchingStore.setSketchToolVisible(false); uiStore.setClippingPlaneToolVisible(false); }}
                    />
                  </Tooltip>
                }
              </ToolbarTopCustom>

            </ProjectSettingGroup>
          </Fragment >
        ) : ''
      case 'projectShare':
        return (
          <Fragment>
            <Avatar.Group maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              {defaultButton}
              <ToolbarTopItem>
                <Tooltip title={t('list-all-tilesets')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                  <Button
                    onClick={clickShowExplorer}
                    icon={<ApartmentOutlined />}
                    size={commonStore.buttonSize}
                  />
                </Tooltip>
              </ToolbarTopItem>
            </Avatar.Group>
          </Fragment>
        )
      case 'project-teams':
        return (
          <Fragment>
            <Avatar.Group maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              {defaultButton}
              {
                projectStore.currentUserRoleInProject === "project_owner" && (
                  <ToolbarTopItem>
                    <Tooltip title={t('add-new-member')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                      <Button
                        onClick={() =>
                          projectTeamsStore.toggleShowAddTeamMemberModal(true)
                        }
                        icon={<UserAddOutlined />}
                        size={commonStore.buttonSize}
                      />
                    </Tooltip>
                  </ToolbarTopItem>
                )
              }
            </Avatar.Group>
          </Fragment>
        )
      case 'project-settings':
      case 'user-settings':
      case 'organization':
      case 'home':
      case 'project-dashboard':
      case 'manage-organizations':
      case 'organization-detail':
        return (
          <Fragment>
            <Avatar.Group maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
              {defaultButton}
              <ToolbarTopCustom activated={0}>
                {(!projectStore.visitedMode || currentPage !== 'projectDetail' || (localStorage.getItem('jwt') || sessionStorage.getItem('jwt'))) &&
                  <Tooltip title={t('projects')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                    <Button
                      style={{ verticalAlign: 0 }}
                      icon={<SVGIcon content={<ProjectsIcon />} width={32} height={32} />}
                      onClick={() => history.push('/projects')}
                    />
                  </Tooltip>
                }
              </ToolbarTopCustom>

            </Avatar.Group>
          </Fragment>
        )
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <ToolbarTopContainer
        style={{ display: projectStore.cleanMode ? 'none' : 'block', }}
        >
        {currentPage !== 'maintanance' && (
          <ToolbarTopItem>
            {/* <MediaQuery query="(max-width: 768px)">
            <Button
              style={{ padding: '0 4px' }}
              type="primary"
              size={commonStore.buttonSize}
              onClick={clickShowMainDrawer}>
              <span
                id="xd-logo"
                style={{ fontWeight: 'bolder', marginRight: 0 }}>
                xD
                </span>
            </Button>
          </MediaQuery> */}
            {/* <MediaQuery query="(min-width: 769px)"> */}
            <div className='custom-logo-xdtwin'>
              {currentPage === 'projectDetail' ? (
                projectStore?.isChangeLogoXDTwin?.customOrganizationLogo && (
                  projectStore?.isChangeLogoXDTwin?.logo ? (
                    <Image
                      style={{ cursor: 'pointer' }}
                      onClick={clickShowMainDrawer}
                      width={widthLogo}
                      height={heightLogo}
                      src={projectStore?.isChangeLogoXDTwin?.logo?.url}
                      preview={false}
                    />
                  ) : (
                    // <Button
                    //   style={{ border: '1px solid #F26524' }}
                    //   type="primary"
                    //   size={commonStore.buttonSize}
                    //   onClick={clickShowMainDrawer}>
                    //   <span style={{ fontWeight: 'bolder', marginRight: 6 }}>
                    //     xD
                    //   </span>{' '}
                    //   Twin
                    // </Button>
                    <Image
                      style={{ cursor: 'pointer' }}
                      onClick={clickShowMainDrawer}
                      width={widthLogo}
                      height={heightLogo}
                      src={AppLogo}
                      preview={false}
                    />
                  )
                )
              ) : (
                // <Button
                //   style={{ border: '1px solid #F26524' }}
                //   type="primary"
                //   size={commonStore.buttonSize}
                //   onClick={clickShowMainDrawer}>
                //   <span style={{ fontWeight: 'bolder', marginRight: 6 }}>
                //     xD
                //   </span>{' '}
                //   Twin
                // </Button>
                <Image
                  style={{ cursor: 'pointer' }}
                  onClick={clickShowMainDrawer}
                  width={widthLogo}
                  height={heightLogo}
                  src={AppLogo}
                  preview={false}
                />
              )
              }
            </div>
            {/* </MediaQuery> */}
          </ToolbarTopItem>
        )}
        {currentPage ? viewByPage() : ''}
      </ToolbarTopContainer>
    </React.Fragment>
  )
}

export default withRouter(
  inject(
    'commonStore',
    'authStore',
    'projectStore',
    'usersStore',
    'projectTeamsStore',
    'schedulingStore',
    'uiStore',
    'projectSettingStore',
    'adminStore',
    'usersStore', 'capturesStore', 'sketchingStore',
    'fileStore'
  )(observer(ToolbarTop))
)
