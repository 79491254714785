import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd'
import _ from 'lodash'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import AdminTemplate from '../../../layout/AdminTemplate'
import '../style.css'
import { LoadingSpinner } from '@/components/elements'
import { ItemList, ItemSetting, SettingWrapper } from './style'
import {
  ContentAdmin,
  CustomContentAdmin,
  CustomContentAdminWrapper,
  HeaderAdmin,
} from '../style'
const { Content } = Layout
const { Option } = Select

const EditUser = ({ usersStore, commonStore, adminStore }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [isLoadingFormEdit, setisLoadingFormEdit] = useState(false)
  const [listUOrganizations, setListUOrganizations] = useState([])
  const [listProjects, setListProjects] = useState([])
  const { userId } = useParams()
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isTabletOrMobileDevice = useMediaQuery({ query: '(max-width: 995px)' })
  useEffect(() => {
    const populateOrganizationFormData = async () => {
      adminStore.clearStatus()
      setisLoadingFormEdit(true)
      commonStore.setCurrentPage('admin/users')
      adminStore.setRiderect(true)
      if (userId !== 'new') {
        adminStore.setEditMode(true)
        await adminStore
          .getUserInfo(userId)
          .then(async res => {
            if (res.projectuserroles.length > 0) {
              let result = []
              res.projectuserroles.map(elm => {
                if (elm.project?.isDeleted) return
                result.push({
                  id: elm.project.id,
                  name: elm.project.name,
                })
              })
              setListProjects(result)
            }
            if (res.organizationuserroles.length > 0) {
              let result = []
              res.organizationuserroles.map(elm => {
                if (elm.organization.isActive) {
                  result.push({
                    id: elm.organization.id,
                    name: elm.organization.name,
                  })
                }
              })
              setListUOrganizations(result)
            }
            form.setFieldsValue({
              email: res?.email,
              username: res?.username,
              publicKey: res?.publicKey,
              roles: res.role.type,
              termsConditions: res?.termsConditions,
              privacyPolicy: res?.privacyPolicy,
              firstName: res?.firstName,
              lastName: res?.lastName
            })
            setisLoadingFormEdit(false)
          })
          .catch(err => {
            setisLoadingFormEdit(false)
          })
      } else {
        form.resetFields()
        adminStore.setEditMode(false)
        adminStore.setCreateMode(true)
        setisLoadingFormEdit(false)
      }
    }
    populateOrganizationFormData()
    return () => {
      adminStore.setCurrentUser({})
      adminStore.clearStatus()
    }
  }, [])

  const createUser = info => {
    setisLoadingFormEdit(true)
    adminStore
      .createUser(info)
      .then(() => {
        message.success(t('create-new-user-success'))
        history.push(
          `/admin/users/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
        )
      })
      .catch(() => {
        message.error(t('create-new-user-failed'))
      })
      .finally(() => {
        adminStore.setRiderect(false)
        setisLoadingFormEdit(false)
      })
  }

  const updateUser = async (userId, payload) => {
    setisLoadingFormEdit(true)
    adminStore
      .updateUserInfo(userId, payload)
      .then(() => {
        message.success(t('user-updated-successfully'))
        adminStore.setRiderect(false)
        history.push(
          `/admin/users/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
        )
      })
      .catch(error => {
        setisLoadingFormEdit(false)
        if (error.data && error.data.message && Array.isArray(error.data.message)) {
          message.error(t(error.data.message[0].messages[0].id))
        } else if (error.data && error.data.message) {
          message.error(t(error.data.message))
        } else {
          message.error(t('username-email-taken'))
        }
      })
      .finally(() => {
        setisLoadingFormEdit(false)
      })
  }

  const handleButtonSaveUser = payload => {
    delete payload.project
    delete payload.organization
    delete payload.roles
    if (userId === 'new') {
      //  case create new organization
      // return createUser(payload)
    } else {
      // case update organization
      return updateUser(userId, payload)
    }
  }

  const listSystemRole = [
    {
      type: 'web_admin',
      name: 'Web admin',
    },
    {
      type: 'authenticated',
      name: 'Authenticated',
    },
    {
      type: 'public',
      name: 'Public',
    },
  ]

  const handleDeleteUser = record => {
    let _param = [record]
    adminStore.setLoadingProgress(true)
    let _arrUserId = {
      arrUserId: _param,
    }

    adminStore
      .deleteMultiUser(_arrUserId)
      .then(async res => {
        message.success(t('user-deleted-successfully'))
        adminStore.setLoadingProgress(false)
        history.push(
          `/admin/users/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`
        )
      })
      .catch(error => {
        adminStore.setLoadingProgress(false)
        message.error(t('something-went-wrong'))
      })
  }

  const showConfirmDeleteUser  = () => {
    Modal.confirm({
      title: t('do-you-want-to-delete-this-user'),
      zIndex: 999999,
      content: t('please-confirm-to-delete', {
        name: adminStore.currentUser.username,
      }),
      icon: <ExclamationCircleOutlined />,
      okText: t('commons.delete'),
      cancelText: t('commons.cancel'),
      okButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      onOk() {
        handleDeleteUser(adminStore.currentUser.id)
      },
      onCancel() {
        console.log('')
      },
    })
  }

  const customSpin = (
    <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
  )

  return (
    <AdminTemplate title={t('user-detail')}>
      <Spin spinning={isLoadingFormEdit} indicator={customSpin}>
        <CustomContentAdminWrapper className="edit-user">
          <div style={{ background: '#fff', textAlign: 'center' }}>
            <Layout>
              <Form
                form={form}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={value => handleButtonSaveUser(value)}>
                <HeaderAdmin transparent style={{ backgroundColor: '#f0f2f5' }}>
                  <Content>
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      >
                      <Col span={24}>
                        <Col span={24} className="control-btn-group">
                        {
                          userId !== 'new' && (
                            <Button
                            style={{
                              float: !isMobile && 'right',
                              margin: 5,
                            }}
                            htmlType="submit"
                            type="primary">
                            <SaveOutlined />
                            {!isMobile && t('commons.save')}
                          </Button>
                          )
                        }
                          {userId !== 'new' ? (
                            <Button
                              style={{
                                float: !isMobile && 'right',
                                margin: 5,
                              }}
                              type="danger"
                              onClick={showConfirmDeleteUser  }>
                              <DeleteOutlined />
                              {!isMobile && t('commons.delete')}
                            </Button>
                          ) : null}
                          <Modal
                            title={t('please-confirm-delete')}
                            style={{ top: 120, textAlign: 'center' }}
                            visible={modalVisible}
                            onOk={() => setModalVisible(false)}
                            onCancel={() => setModalVisible(false)}>
                            <p>
                              <InfoCircleOutlined
                                style={{ fontSize: '30px' }}
                              />
                            </p>
                            <p>
                              {t('are-you-sure-you-want-to-delete-this-record')}
                            </p>
                          </Modal>
                        </Col>
                      </Col>
                    </Row>
                  </Content>
                </HeaderAdmin>
                <Content>
                  <div style={{ padding: '20px 0', background: '#fff' }}>
                    <Row type="flex">
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <div
                          className="content-left"
                          style={{
                            background: '#fff',
                            padding: '20px 0',
                            marginRight: isTabletOrMobileDevice ? '0' : '30px',
                          }}>
                          <Row gutter={[24, 8]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                              <Form.Item
                                label={t('username')}
                                name="username"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-your-username'),
                                  },
                                ]}>
                                <Input
                                  placeholder={t('your-username')}
                                  type="text"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                              <Form.Item
                                label={t('email')}
                                name="email"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-your-email'),
                                  },
                                ]}>
                                <Input
                                  placeholder={t('your-email')}
                                  type="email"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                              <Form.Item
                                label={t('first-name')}
                                name="firstName"
                                rules={[
                                  {
                                    required: false,
                                    message: t('please-input-first-name'),
                                  },
                                ]}>
                                <Input placeholder={t('input-first-name')} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                              <Form.Item
                                label={t('last-name')}
                                name="lastName"
                                rules={[
                                  {
                                    required: false,
                                    message: t('please-input-last-name'),
                                  },
                                ]}>
                                <Input placeholder={t('input-last-name')} />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('public-key')}
                                name="publicKey"
                                rules={[
                                  {
                                    required: false,
                                    message: t('please-input-yout-public-key'),
                                  },
                                ]}>
                                <Input
                                  placeholder={t('enter-your-public-key')}
                                  type="text"
                                />
                              </Form.Item>
                            </Col>
                            {userId !== 'new' && (
                              <>
                                <Col span={24}>
                                  <Form.Item
                                    label={t('privacy-policy')}
                                    name="privacyPolicy"
                                    rules={[
                                      {
                                        required: false,
                                        message: t(
                                          'please-input-privacy-policy'
                                        ),
                                      },
                                    ]}>
                                    <Input className="readOnly" type="text" />
                                  </Form.Item>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    label={t('terms-and-conditions')}
                                    name="termsConditions"
                                    rules={[
                                      {
                                        required: false,
                                        message: t(
                                          'please-input-terms-and-conditions'
                                        ),
                                      },
                                    ]}>
                                    <Input className="readOnly" type="text" />
                                  </Form.Item>
                                </Col>
                              </>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <div className="right-setting">
                          <SettingWrapper style={{ paddingBottom: '10px' }}>
                            <Form.Item
                              label={t('role')}
                              name="roles"
                              rules={[
                                {
                                  required: false,
                                  message: t('please-select-role'),
                                },
                              ]}>
                              <Select
                                className="readOnly"
                                tokenSeparators={[',']}
                                showArrow={false}
                                placeholder={t('add-an-item')}
                                filterOption={false}
                                style={{ width: '100%' }}>
                                {listSystemRole &&
                                  listSystemRole.map(d => (
                                    <Option key={d.type} value={d.type}>
                                      {d.name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label={t('list-of-organizations', {
                                length: listUOrganizations.length,
                              })}
                              name="organization"
                              rules={[
                                {
                                  required: false,
                                  message: t('add-an-item'),
                                },
                              ]}>
                              <ItemSetting>
                                {listUOrganizations.length > 0 ? (
                                  listUOrganizations.map((_org, index) => (
                                    <ItemList
                                      justify="space-between"
                                      className="organization-user-item"
                                      key={`${_org}~${index}`}>
                                      {_org.name}
                                    </ItemList>
                                  ))
                                ) : (
                                  <Empty
                                    description={<span>{t('no-data')}</span>}
                                  />
                                )}
                              </ItemSetting>
                            </Form.Item>
                            <Form.Item
                              label={t('list-of-projects', {
                                length: listProjects.length,
                              })}
                              name="project"
                              rules={[
                                {
                                  required: false,
                                  message: t('add-an-item'),
                                },
                              ]}>
                              <ItemSetting>
                                {listProjects.length > 0 ? (
                                  listProjects.map((_prj, index) => (
                                    <ItemList
                                      justify="space-between"
                                      className="project-item"
                                      key={`${_prj}~${index}`}>
                                      {_prj.name}
                                    </ItemList>
                                  ))
                                ) : (
                                  <Empty
                                    description={<span>{t('no-data')}</span>}
                                  />
                                )}
                              </ItemSetting>
                            </Form.Item>
                          </SettingWrapper>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Content>
              </Form>
            </Layout>
          </div>
        </CustomContentAdminWrapper>
      </Spin>
    </AdminTemplate>
  )
}

export default inject(
  'usersStore',
  'commonStore',
  'adminStore'
)(observer(EditUser))
