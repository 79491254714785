import { Col, Row, Image } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import DefaultTemplate from '@/components/layout/DefaultTemplate';
import { CardFooter, PageWrapper, TableHeading, Container, CardWrapper } from './style';
import { assetUrl } from '@/config'
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import maintananceImage from '@/assets/imgs/maintenance-image.gif'

const EmailNotification = props => {
    const { t } = useTranslation();
    const { projectStore, commonStore } = props;
    moment.locale(`${commonStore.language}`)
    const history = useHistory();
    const dateFormat = "DD.MM-YYYY"
    const hoursFormat = "h:mm a"
    const getData = () => {

        fetch(`${assetUrl}maintananceInfo.json`
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                if (myJson.startDate && myJson.endDate) {
                    let startTime = new Date(parseInt(myJson.startDate))
                    let endTime = new Date(parseInt(myJson.endDate))

                    let isBetween = moment(Date.now()).isBetween(moment(startTime), moment(endTime))
                    const isActive = myJson.active === true || (myJson.active && myJson.active.toString().toLowerCase() === 'true')

                    if (isBetween && isActive) {
                        projectStore.setMaintenanceInfo({
                            ...myJson,
                            startDate: moment.utc(startTime).format(dateFormat),
                            startHour: moment.utc(startTime).format(hoursFormat),
                            endDate: moment.utc(endTime).format(dateFormat),
                            endHour: moment.utc(endTime).format(hoursFormat),
                        })
                    } else {
                        history.push(`/`)
                    }
                }
            }).catch(function () {

                history.push(`/`)
            });
    }
    useEffect(() => {
        commonStore.setCurrentPage('maintanance');
        getData()
        return () => {
            projectStore.setMaintenanceInfo({})
        }
    }, [])

    return (
        <HelmetProvider>
            <DefaultTemplate>
                <Helmet>
                    <title>{t('maintenance-scheduled')} | 6DPlanner</title>
                </Helmet>
                <PageWrapper>
                    <Container>
                        <CardWrapper>
                            <Image width={'70%'} preview={false} src={maintananceImage} alt='maintanance'/>
                            <TableHeading>
                                <div className="title" style={{ margin: 'auto' }}>{t('maintenance-scheduled')}</div>
                            </TableHeading>
                            <CardFooter>
                                <Row >
                                    <Col span={24} sm={24}>
                                        6DPlanner service will be under maintenance during  &nbsp;
                                        {projectStore.maintenanceInfo.startDate} &nbsp;{projectStore.maintenanceInfo.startHour} &nbsp;to &nbsp; {projectStore.maintenanceInfo.endDate} &nbsp;{projectStore.maintenanceInfo.endHour}  (time UTC times).&nbsp; During that time it may not be possible to log in or use the projects. Please contact <a>support@6dplanner.com</a> for more information or assistance.
                                    </Col>
                                    <Col span={24} sm={24}>
                                        <span>{projectStore.maintenanceInfo.description}</span>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </CardWrapper>
                    </Container>
                </PageWrapper>
            </DefaultTemplate>
        </HelmetProvider>
    )
}
export default inject('projectStore', 'commonStore')(observer(EmailNotification))