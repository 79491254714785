import { PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, message, Popconfirm, Select, Typography } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { withRouter } from 'react-router-dom'
import validator from '@/validator'
import Elements from './Elements'
import { EditorHeading, EditorMain, EditorWrapper, EmptyWrapper, ModalFeedbackName } from './style'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
const { Option } = Select
const { Text } = Typography
const { TextArea } = Input;

const Editor = ({ feedbackStore, projectStore, commonStore, usersStore, projectSettingStore, onDroped, feedbackEditorList, projectTeam, projectId, viewer, drawerWidth, setRefreshFeedbackEditorList, refreshFeedbackEditorList }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [elementList, setElementList] = useState([])
    const [visibleFeedbackModal, setVisibleFeedbackModal] = useState(false);
    const [currentElement, setCurrentElement] = useState({});
    const grid = 8;

    let options = feedbackEditorList.map((item) => {
        return {
            key: item.id,
            value: item.title,
            label: item.title
        }
    })

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        background: isDragging ? "lightgreen" : "",
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    /**
     * save feedback editor
     * @param {*} e 
     */
    const onSaveClickHandler = e => {
        form.validateFields().then((values) => {
            let _elements = elementList.map((item, index) => {
                item.order = index
                return toJS(item)
            })

            let _value = {
                ...values,
                formControlData: { elements: _elements }
            }

            if (!feedbackStore.selectedFeedbackEditor.id) {
                _value.isGenericFeedback = false
                _value.user = usersStore.currentUser.id
                _value.project = projectId
            }

            if (feedbackStore.selectedFeedbackEditor.viewLocation) {
                _value.viewLocation = feedbackStore.selectedFeedbackEditor.viewLocation
            }

            if (feedbackStore.selectedFeedbackEditor.id) {
                if (feedbackStore.selectedFeedbackEditor) {
                    _value.title = values.title === feedbackStore.selectedFeedbackEditor?.id ? feedbackStore.selectedFeedbackEditor?.title : values.title
                    feedbackStore.setLoadingProgress(true)
                    feedbackStore.updateFeedbackEditor(feedbackStore.selectedFeedbackEditor.id, _value).then(async (res) => {
                        message.success(t('feedback-update-successfull'))
                        feedbackStore.setLoadingProgress(false)
                        feedbackStore.setSelectedFeedbackEditor(res)
                        onCloseDrawerFeedbackEditor()
                        form.resetFields()
                    }).catch(() => {
                        message.error(t('update-failed'))
                        feedbackStore.setLoadingProgress(false)
                        form.resetFields()
                    })
                }
            }
        })
    };

    /**
     * Add new feedback editor
     * @param {} e 
     */
    const onNewClickHandler = e => {
        feedbackStore.setSelectedFeedbackEditor({
            ...feedbackStore.selectedFeedbackEditor,
            id: undefined,
            title: '',
            viewLocation: undefined
        });
    };

    /**
     * Delete feedback editor
     */
    const deleteFeedbackHandler = () => {
        if (feedbackStore.selectedFeedbackEditor) {
            feedbackStore.setLoadingProgress(true)
            feedbackStore.deleteFeedbackEditor(feedbackStore.selectedFeedbackEditor.id).then(async (res) => {
                onClickResetHandler()
                message.success(t('feedback-editor-deleted-successfully', { title: res.data.title }))
                await feedbackStore.getFeedbackformsList(projectId, 'all').then(res => {
                    feedbackStore.setFeedbackEditorList(res.data)
                })
            }).catch(() => {
                message.error(t('delete-feedback-editor-failed'))
                feedbackStore.setLoadingProgress(false)
            })
        }
    }

    /**
     * Reset feedback editor form
     */
    const onClickResetHandler = async () => {
        feedbackStore.setLoadingProgress(true)
        feedbackStore.clearSelectedElementIndex()
        form.resetFields()
        form.setFieldsValue({ isPublic: true });
        feedbackStore.clearSubmittedFormBuilder()
        setElementList([])
        feedbackStore.clearSelectedFeedbackEditor()
        feedbackStore.setLoadingProgress(false)
    }

    /**
     * Function change drop Autocomplete
     * @param {*} val 
     * @param {*} option 
     */
    const onSelectFeedback = (val) => {
        let _feedbackobj = feedbackEditorList.find(x => x.id === val)
        feedbackStore.setSelectedFeedbackEditor(_feedbackobj)
    };

    /**
     * Effect change selectedFeedback editor
     */
    useEffect(() => {
        if (feedbackStore.selectedFeedbackEditor) {
            // check feedback editor has view then go to camera view
            if (feedbackStore.selectedFeedbackEditor.viewLocation && feedbackStore.selectedFeedbackEditor.viewLocation.camData) {
                goViewpoint(feedbackStore.selectedFeedbackEditor.viewLocation.camData)
            }

            if (feedbackStore.selectedFeedbackEditor.formControlData) {
                let _element = feedbackStore.selectedFeedbackEditor.formControlData.elements.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
                _element.map(v => {
                    v.isDefault = true
                })
                feedbackStore.submittedFormBuilder.elements = _element
            }
            let lisrPersonBelongFeedback
            if (feedbackStore.selectedFeedbackEditor.assignTo && feedbackStore.selectedFeedbackEditor.assignTo.length > 0) {
                lisrPersonBelongFeedback = feedbackStore.selectedFeedbackEditor.assignTo.map(el => el.id ? el.id : el)
            } else {
                lisrPersonBelongFeedback = []
            }
            if (feedbackStore.selectedFeedbackEditor.newname) {
                form.setFieldsValue({ newname: feedbackStore.selectedFeedbackEditor.newname });
            }
            form.setFieldsValue({ title: feedbackStore.selectedFeedbackEditor.title });
            form.setFieldsValue({ description: feedbackStore.selectedFeedbackEditor.description });
            form.setFieldsValue({ isPublic: feedbackStore.selectedFeedbackEditor.isPublic });
            form.setFieldsValue({ assignTo: lisrPersonBelongFeedback });

        }
    }, [feedbackStore.selectedFeedbackEditor])

    /**
     * Effect change element
     */
    useEffect(() => {
        if (feedbackStore.submittedFormBuilder.elements) {
            setElementList(feedbackStore.submittedFormBuilder.elements)
        }
    }, [feedbackStore.submittedFormBuilder.elements, currentElement])


    /**
     * Go to viewer point
     * @param {*} camdata 
     */
    const goViewpoint = camdata => {
        if (!viewer.camera) return
        viewer.camera.setView({
            destination: camdata.position,
            orientation: {
                direction: camdata.direction,
                up: camdata.up
            }
        })
    }

    /**
     * drap end change order question
     * @param {*} result 
     */
    const handleDragEnd = (result) => {
        // if dropped outside the list then return false
        if (!result.destination) {
            return;
        }

        const items = reorder(
            elementList,
            result.source.index,
            result.destination.index
        );
        setElementList(items)
        feedbackStore.submittedFormBuilder.elements = items
    }

    /**
     * Function set show add feedback editor location
     */
    const onClickHandlerAddLocation = () => {
        form.validateFields().then(values => {
            // set selected feedback editor data because when back from add location control null

            if (!feedbackStore.selectedFeedbackEditor) {
                feedbackStore.setSelectedFeedbackEditor({
                    ...feedbackStore.selectedFeedbackEditor,
                    title: values.title,
                    newname: values.newname,
                    isGenericFeedback: values.isGenericFeedback ? values.isGenericFeedback : false,
                    isPublic: values.isPublic,
                    assignTo: values.assignTo && values.assignTo.length > 0 ? values.assignTo : []
                })
            } else {
                feedbackStore.setSelectedFeedbackEditor({
                    ...feedbackStore.selectedFeedbackEditor,
                    title: values.title,
                    isGenericFeedback: values.isGenericFeedback ? values.isGenericFeedback : false,
                    isPublic: values.isPublic,
                    assignTo: values.assignTo && values.assignTo.length > 0 ? values.assignTo : []
                })
            }


            projectStore.setCleanMode(true) // clear all control on 3D view
            feedbackStore.setShowAddLocationControl(true) // show add location control
            feedbackStore.setShowFeedbackEditorDrawer(false) // snooze feedback editor drawer
            feedbackStore.setShowFeedbackAnswer(false)
            feedbackStore.setIsEditingLocationControl(true)
        })
    }

    /**
     * Function close feedback editor
     */
    const onCloseDrawerFeedbackEditor = () => {
        feedbackStore.clearFeedbackEditorList()
        feedbackStore.clearSubmittedFormBuilder()
        // feedbackStore.setShowFeedbackEditorDrawer(false);
        feedbackStore.clearSelectedElementIndex()
        feedbackStore.clearSelectedFeedbackEditor()
        setRefreshFeedbackEditorList(!refreshFeedbackEditorList)
        //Show again feedback editor normal visualization
        feedbackStore.setLoadingProgress(true)
        feedbackStore.getFeedbackformsList(projectStore.projectDetail.id, 'normal').then(res => {
            feedbackStore.setFeedbackformNormalVisualization(res.data)
            feedbackStore.setLoadingProgress(false)
        }).catch(err => feedbackStore.setLoadingProgress(false))
        // projectSettingStore.toggleProjectSettingDrawer(true); //Show again project setting drawer    
    };

    const handleCheckDisable = (e) => {
        if (visibleFeedbackModal) {
            if ((e.target.value).trim() !== feedbackStore.selectedFeedbackEditor.title) {
            } else {
            }
        }
    }

    const handleShowModal = () => {
        setVisibleFeedbackModal(true)
        form.setFieldsValue({ rename: feedbackStore.selectedFeedbackEditor.title })
    }

    const handleCancel = () => {
        form.resetFields()
        onClickResetHandler()
    }

    // open modal to adjust feedback name, create new feedback
    const openModalFeedback = () => {
        setVisibleFeedbackModal(true)
        if(feedbackStore.selectedFeedbackEditor){
            form.setFieldsValue({ rename: feedbackStore.selectedFeedbackEditor.title })
        }
    }


    const handleSaveFeedback =() => {
        // update selected feedback
        if(feedbackStore?.selectedFeedbackEditor?.id){ // 
            form.validateFields().then((values) => {
                let _elements = elementList.map((item, index) => {
                    item.order = index
                    return toJS(item)
                })
    
                let _value = {
                    ...values,
                    formControlData: { elements: _elements },
                    title: values.rename
                }
    
                if (!feedbackStore.selectedFeedbackEditor.id) {
                    _value.isGenericFeedback = false
                    _value.user = usersStore.currentUser.id
                    _value.project = projectId
                }
    
                if (feedbackStore.selectedFeedbackEditor.viewLocation) {
                    _value.viewLocation = feedbackStore.selectedFeedbackEditor.viewLocation
                }
                if (feedbackStore.selectedFeedbackEditor.id) {
                    feedbackStore.setLoadingProgress(true)
                    feedbackStore.updateFeedbackEditor(feedbackStore.selectedFeedbackEditor.id, _value).then(async (res) => {
                        message.success(t('feedback-update-successfull'))
                        feedbackStore.setLoadingProgress(false)
                        feedbackStore.setSelectedFeedbackEditor(res)
                        onCloseDrawerFeedbackEditor()
                        form.resetFields()
                    }).catch(() => {
                        message.error(t('update-failed'))
                        feedbackStore.setLoadingProgress(false)
                        form.resetFields()
                    })
                }
            })
        }
        // create new feedback
        else{
            form.validateFields().then(values => {
                let _elements = elementList.map((item, index) => {
                    item.order = index
                    return toJS(item)
                })

                let _value = {
                    ...values,
                    title: values.rename,
                    formControlData: { elements: _elements }
                }
                _value.isGenericFeedback = false
                _value.user = usersStore.currentUser.id
                _value.project = projectId
                delete _value.rename
                if (feedbackStore.selectedFeedbackEditor.viewLocation) {
                    _value.viewLocation = feedbackStore.selectedFeedbackEditor.viewLocation
                }

                let isExistFeedbackTitle = feedbackEditorList.filter(x => x?.title.toLowerCase() === _value?.title.toLowerCase())

                if (isExistFeedbackTitle.length > 0) return message.error(t('feedback-title-already-exists-please-use-a-different-title')) ;
                feedbackStore.setLoadingProgress(true)
                feedbackStore.createFeedbackEditor(_value).then(async (res) => {
                    // if (projectStore.projectDetail.decidimConnector && projectStore.projectDetail.decidimConnector.projectId === projectStore.projectDetail._id && projectStore.projectDetail.decidimConnector.isActived) {
                    //     let content_answer = `Comment from 6DPlanner project: ${projectStore.projectDetail.name} ${moment(new Date()).format("DD.MM.YYYY HH:mm")}<br>`
                    //     let arrAnswers = res.data?.formControlData?.elements || []
                    //     arrAnswers.map(c => {
                    //         if (c.value !== null) {
                    //             if (c.className === 'smileyButton') {
                    //                 let _value = ''
                    //                 if (c.value.split('.')[0] === "/static/media/smiley1") {
                    //                     _value = "Very happy"
                    //                 } else if (c.value.split('.')[0] === "/static/media/smiley2") {
                    //                     _value = "Happy"
                    //                 } else if (c.value.split('.')[0] === "/static/media/smiley3") {
                    //                     _value = "Neutral"
                    //                 } else if (c.value.split('.')[0] === "/static/media/smiley4") {
                    //                     _value = "Unhappy"
                    //                 } else {
                    //                     _value = "Very unhappy"
                    //                 }
                    //                 content_answer += ` ${c.title}: ${_value} <br/>`
                    //             } else {
                    //                 if (!['textReadonly', 'urlReadonly', 'image'].includes(c.className)) {
                    //                     content_answer += ` ${c.title}: ${c.value} <br/>`
                    //                 }
                    //             }
                    //         }
                    //     })
    
                    //     await feedbackStore.decidimConnect({
                    //         url: projectStore.projectDetail.decidimConnector.url,
                    //         email: projectStore.projectDetail.decidimConnector.email,
                    //         password: projectStore.projectDetail.decidimConnector.password,
                    //         isDecrypt: true
                    //     }).then(async (response) => {
                    //         const data = {
                    //             url: projectStore.projectDetail.decidimConnector.url,
                    //             jwt_token: response.data.jwt_token,
                    //             content: content_answer
                    //         }
                    //         await feedbackStore.decidimAddComment(data).catch(err => {
                    //             message.warning(t('save-connector-failed'))
                    //         })
                    //     }).catch(err => {
                    //         message.warning(t('save-connector-failed'))
                    //     })
                    // }

                    message.success(t('feedback-insert-successfull'))
                    feedbackStore.setLoadingProgress(false)
                    onCloseDrawerFeedbackEditor()
                    form.resetFields()
                }).catch(() => {
                    message.error(t('create-feedback-editor-failed'))
                    feedbackStore.setLoadingProgress(false)
                    form.resetFields()
                })
            })
        }
    }

    return (
        <EditorWrapper minimal={drawerWidth <= 768 ? 1 : 0}>
            <EditorHeading theme={commonStore.appTheme}>
                <Form
                    form={form}
                    name="feedback-editor-form"
                    layout="vertical"
                >
                    <Form.Item
                                name="title"
                                label={t('load')}
                                labelAlign="left"
                                rules={[
                                    {
                                        required: false,
                                        message: t('feedback-title-cannot-be-emptied'),
                                    },
                                    {
                                        validator: validator.validateEmptyString
                                    }
                                ]}
                            >
                                <Select style={{ width: '100%' }}
                                    onChange={val => onSelectFeedback(val)}
                                    className="certain-category-search"
                                    dropdownClassName="certain-category-search-dropdown"
                                    notFoundContent={t('no-data')}
                                >
                                    {
                                        options ? options.map((item) => (
                                            <Option key={item.key}>{item.value}</Option>
                                        )) : <Option>{t('no-feedback-found')}</Option>
                                    }
                                </Select>
                            </Form.Item>
                    <Form.Item label={t('description')} name="description">
                        <TextArea placeholder={t('input-feedback-description')} rows={4} />
                    </Form.Item>

                    {/* action buttons */}
                    <Form.Item className="align-right">
                        {
                            projectStore.isExistLicenses && (
                                <Button style={{ marginBottom: '5px', marginRight: '0', marginLeft: '8px' }} type="primary"
                                    onClick={onClickResetHandler}
                                >
                                    {t('commons.new')}
                                </Button>
                            )
                        }
                        <Button style={{ marginBottom: '5px', marginRight: '0', marginLeft: '8px' }} type="primary" onClick={openModalFeedback}>
                        {t('commons.save')}
                        </Button>
                        {
                            feedbackStore?.selectedFeedbackEditor?.id && (
                                <Popconfirm
                                        placement={'topRight'}
                                        okText={t('commons.delete')} cancelText={t('commons.cancel')} okType={'danger'} onConfirm={deleteFeedbackHandler}
                                        title={t('are-you-sure-you-want-to-delete-this-feedback')}
                                        disabled={feedbackStore.selectedFeedbackEditor && feedbackStore.selectedFeedbackEditor.id ? (feedbackStore.selectedFeedbackEditor.title === 'Generic feedback' ? true : false) : true}
                                    >
                                        <Button style={{ marginBottom: '5px', marginRight: '0', marginLeft: '8px' }} type="primary" danger disabled={feedbackStore.selectedFeedbackEditor && feedbackStore.selectedFeedbackEditor.id ? (feedbackStore.selectedFeedbackEditor.title === 'Generic feedback' ? true : false) : true}>
                                            {t('commons.delete')}
                                        </Button>
                                </Popconfirm>
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        name="isPublic"
                        valuePropName="checked"
                        initialValue={feedbackStore.selectedFeedbackEditor ? feedbackStore.selectedFeedbackEditor.isPublic : true}>
                        <Checkbox> {t('active')}</Checkbox>
                    </Form.Item>
                    {!feedbackStore.selectedFeedbackEditor.isGenericFeedback ? (
                        <Form.Item
                            name="assignTo"
                            label={t('person-to-assign')}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                notFoundContent={t('no-result')}
                                mode='multiple'
                                placeholder={t('person-to-assign')}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }>
                                {projectTeam.map((item) => {
                                    return (item.inviteStatus === 'accepted' && (item.email !== "maintenance@xd-visuals.fi" || item?.user?.username !== "maintenance") && item.user ? < Option key={item.user?._id} value={item.user?._id}>{t(item.user?.username)}</Option> : '')
                                })}
                            </Select>
                        </Form.Item>
                    ) : null
                    }
                    {!feedbackStore.selectedFeedbackEditor.isGenericFeedback ? (
                        <>
                            <Button icon={<PlusOutlined />} onClick={() => onClickHandlerAddLocation()}>
                                {feedbackStore.selectedFeedbackEditor.viewLocation && feedbackStore.selectedFeedbackEditor.viewLocation.camData ? t('edit-location') : t('add-location')}
                            </Button>
                            {feedbackStore.selectedFeedbackEditor.viewLocation && feedbackStore.selectedFeedbackEditor.viewLocation.camData ? <Text mark>{t('location-is-set')}</Text> : <Text mark>{t('no-location-set')}</Text>}
                        </>
                    ) : null
                    }
                    {
                        visibleFeedbackModal ? (
                            <ModalFeedbackName
                                zIndex={99999}
                                title={feedbackStore?.selectedFeedbackEditor?.id ? t('update-feedback') : t('new-feedback')}
                                visible={visibleFeedbackModal}
                                onCancel={() => setVisibleFeedbackModal(false)}
                                okText={t('commons.save')}
                                cancelText={t('commons.cancel')}
                                footer={[
                                    <Button key="back" onClick={() => setVisibleFeedbackModal(false)}>
                                        {t('commons.cancel')}
                                    </Button>,
                                    <Button key="submit" type="primary" onClick={handleSaveFeedback}>
                                        {t('commons.save')}
                                    </Button>,
                                ]}
                            >
                                <Form.Item
                                    name="rename"
                                    labelAlign="left"
                                    onChange={e => handleCheckDisable(e)}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('feedback-title-cannot-be-emptied'),
                                        },
                                        {
                                            validator: validator.validateEmptyString
                                        }
                                    ]}
                                >
                                    <Input placeholder={t('input-new-feedback-title')} />
                                </Form.Item>
                            </ModalFeedbackName>
                        ) : ''
                    }
                </Form>
            </EditorHeading>
            <span>{t('drag-and-drop-questions')}</span>
            <EditorMain onDragOver={e => e.preventDefault()}
                onDrop={() => onDroped()}>
                {
                    elementList && elementList.length === 0
                        ? <EmptyWrapper>{drawerWidth <= 400 ? t('drag-here') : t('feedback-question-is-empty-drag-fields-from-the-left-pannel-and-drop-here')}</EmptyWrapper>
                        :
                        < DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {elementList.map((item, index) => (
                                            !item.isDelete && (
                                                <Draggable key={item.name + '-' + index} draggableId={item.name + '-' + index} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <Elements setCurrentElement={v => setCurrentElement(v)} key={index} elemIndex={index} type={item} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                }
            </EditorMain>
        </EditorWrapper >
    )
}

export default withRouter(inject('feedbackStore', 'projectStore', 'commonStore', 'usersStore', 'projectSettingStore')(observer(Editor)))
