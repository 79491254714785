import { CloseOutlined, SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Layout, message, Row, notification, Table, Typography } from 'antd';
import debounce from 'lodash/debounce';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import AdminTemplate from '@/components/layout/AdminTemplate'
import {
  CustomTopTitle,
  CustomContentAdminWrapper,
  PaginationAdmin,
  HeaderAdmin,
} from '../style'
import { CustomButton, CustomSearch, SearchProjectWrapper } from './styled'
import axios from 'axios'
import { apiUrl } from '@/config'
import { renderHTML } from '@/helper'
import settings from '@/siteConfig';
import { toJS } from 'mobx';
import Utils from '@/utils';
var cancelToken
const { Text } = Typography;

const Projects = (props) => {
    const { t } = useTranslation();
    const { commonStore, organizationStore, adminStore } = props;
    const [isLoadingTableUsers, setisLoadingTableUsers] = useState(organizationStore.isLoading);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [form] = Form.useForm();
    const [searchValue, setSearchValue] = useState();
    const [_limit, setLimit] = useState();
    const [_page, setPage] = useState();
    const history = useHistory()
    const { queryParam } = useParams()
    const [fetchURL, setFetchURL] = useState(true);
    moment.locale(`${commonStore.language}`)
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  useEffect(() => {
    commonStore.setCurrentPage('admin/projects')
    adminStore.clearStatus()
    const queryStringParams = queryString.parse(queryParam)
    if (queryStringParams.search) {
      setSearchValue(queryStringParams.search)
      form.setFieldsValue({
        searchProject: queryStringParams.search,
      })
    }
  }, [])

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    if (!queryStringParams.search && !fetchURL) {
      setisLoadingTableUsers(true)
      setSearchValue('')
      form.setFieldsValue({
        searchProject: ''
      })
      handleSearchUsers('')
    }
  }, [queryParam])

  useEffect(() => {
    return () => {
      adminStore.clearPaginationParams()
    }
  })

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    setLimit(parseInt(queryStringParams._limit))
    setPage(parseInt(queryStringParams._page))
    if (fetchURL && _page && _limit) {
      setisLoadingTableUsers(true)
      adminStore.setCurrentPage(_page)
      adminStore.setCurrentLimit(_limit)
      adminStore
        .customGetProjects(
          _limit,
          _page,
          searchValue ? searchValue.trim() : searchValue
        )
        .then(() => {
          setisLoadingTableUsers(false)
        })
        .catch(() => {
          setisLoadingTableUsers(false)
          adminStore.clearCustomListProjects()
        })
    }
  }, [queryParam, _page, _limit, fetchURL])

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const hasSelected = selectedRowKeys.length > 0

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  async function handleRebuildProjects() {
    setisLoadingTableUsers(true);
  
    try {
      const res = await adminStore.rebuildProjectModelV2({ type: 'project', list: selectedRowKeys });
  
      const rebuildMessages = res.map(project => {
        if (project?.model?.length > 0) {
          return `<b>${t('project')}: ${project?.project?.name}</b><br />` +
                 project.model.map(elm => (
                   `- ${t('model')}: ${elm.model} ${t('rebuild')}: ${elm.status === 'Success' ? `<span style="color:#07a907">${t('success') || elm.status}</span>` : `<span style="color:#ff0000">${t('error') || elm.status}</span>`}`
                 )).join("<br />") +
                 "<br />";
        } else {
          return `<b>${t('project-do-not-any-models', { name: project?.project?.name })}</b><br />`;
        }
      }).join('');
      const key = `open${Date.now()}`;
      notification.info({
        message: t('rebuild-model'),
        description: (
          <>
            {renderHTML(rebuildMessages)}
          </>
        ),
        duration: 0,
        placement: 'top',
        btn: (
          <Button type="primary" size="small" onClick={() => notification.close(key)}>
            OK
          </Button>
        ),
        key,
        closeIcon: false,
        icon: <InfoCircleOutlined style={{ color: '#4096ff' }} />,
        className: 'custom-notivication rebuild-modal'
      });
    } catch (error) {
      console.error(error);
      message.error(error ? t('rebuild-failed-please-try-again') : t('rebuild-error'));
    } finally {
      setisLoadingTableUsers(false);
    }
  }

  const columns = [
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t('storage'),
      dataIndex: 'storage',
      key: 'storage',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return (a.storage || 0) - (b.storage || 0)
      },
      render: (record, obj) => {
        let count = record ? record : 0
        return <span> {Utils.kbytesToSize(count)} </span>
      },
    },
    {
      title: t('users'),
      dataIndex: 'projectuserroles',
      key: 'user',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        let _userA = a.projectuserroles.filter(v => v.user && v.email) || []
        let _userB = b.projectuserroles.filter(v => v.user && v.email) || []
        return _userA.length - _userB.length
      },
      render: (record, obj) => {
        let _users
        if (record) _users = record.filter(v => v.user && v.email)
        return <span> {_users ? _users.length : 0}</span>
      },
    },
    {
      title: t('organization'),
      dataIndex: 'organization',
      key: 'organization',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a && a.organization && b && b.organization
          ? a.organization?.name ? a.organization.name.localeCompare(b.organization.name) : a.organization[0]?.name?.localeCompare(b.organization[0]?.name)
          : true,
      render: (record, obj) => {
        return <span> {record ? record.name ? record.name : record[0]?.name : ''}</span>
      },
    },
    {
      title: t('deleted'),
      dataIndex: 'isDeleted',
      width: 100,
      key: 'isDeleted',
      render: (record, obj) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Text strong type={record ? 'danger' : 'success'}>
              {record ? 'YES' : 'NO'}
            </Text>
          </div>
        )
      },
    },
    {
      title: t('created'),
      key: 'createdAt',
      width: 150,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = a.createdAt ? new Date(a.createdAt) : distantFuture
        let dateB = b.createdAt ? new Date(b.createdAt) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      render: record => {
        return <div>{moment(record.createdAt).format('MMM DD, YYYY')}</div>
      },
    },
    {
      title: t('last-logged-in'),
      key: 'updatedAt',
      width: 150,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const distantFuture = new Date(8640000000000000)
        let dateA = a.updatedAt ? new Date(a.updatedAt) : distantFuture
        let dateB = b.updatedAt ? new Date(b.updatedAt) : distantFuture
        return dateA.getTime() - dateB.getTime()
      },
      render: record => {
        return <div>{moment(record.updatedAt).format('MMM DD, YYYY')}</div>
      },
    },
  ]

  const handleSearchUsers = async e => {
    const search = e ? e.trim() : e
    setFetchURL(false)
    adminStore.setCurrentPage(1)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    if (search === '') {
      setSearchValue()
      history.push(`/admin/projects/_limit=${_limit ? _limit : 10}&_page=${1}`)
    } else {
      setSearchValue(search)
      history.push(
        `/admin/projects/_limit=${
          _limit ? _limit : 10
        }&_page=${1}&search=${encodeURIComponent(e)}`
      )
    }
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    cancelToken = axios.CancelToken.source()
    try {
      setisLoadingTableUsers(true)
      const response = await axios.get(
        `${apiUrl}/project/paginate?_limit=${_limit}&_page=${1}${
          search && search !== '' ? '&search=' + search : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${commonStore.token}`,
          },
          cancelToken: cancelToken.token,
        }
      )
      if (response) {
        setisLoadingTableUsers(false)
        adminStore.setCustomListProjects(response.data)
      }
    } catch (error) {
      if (error) {
        setisLoadingTableUsers(false)
        adminStore.clearCustomListProjects()
        console.log(error)
      }
    }
  }

  const clearSearch = () => {
    setSearchValue('')
    setFetchURL(true)
    form.resetFields()
    setLimit(_limit ? _limit : 10)
    setPage(1)
    adminStore.setCurrentPage(1)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    return history.push(
      `/admin/projects/_limit=${_limit ? _limit : 10}&_page=${1}`
    )
  }

  const debounceCalculate = debounce(function (value) {
    handleSearchUsers(value)
  }, 500)

  const onChange = e => {
    setFetchURL(false)
    debounceCalculate(e.target.value)
  }

  const handleChangePagination = (current, pageSize) => {
    setLimit(pageSize)
    setPage(current)
    setFetchURL(true)
    adminStore.setCurrentPage(current)
    adminStore.setCurrentLimit(pageSize)
    if (searchValue) {
      return history.push(
        `/admin/projects/_limit=${pageSize}&_page=${current}&search=${searchValue.trim()}`
      )
    } else {
      return history.push(`/admin/projects/_limit=${pageSize}&_page=${current}`)
    }
  }

  return (
    <AdminTemplate title={t('projects')}>
      <CustomContentAdminWrapper
        nodata={adminStore.customListProjects?.count ? false : true}>
        <HeaderAdmin
          style={{
            
            overflow: 'initial',
            backgroundColor: '#fff',
          }}>
          <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Col xs={24} sm={12}>
              <CustomTopTitle>
                <div className="title">{t('projects')}</div>
                <div className="count">
                  {t('total-records-found', {
                    length: adminStore.customListProjects?.count || 0,
                  })}
                </div>
              </CustomTopTitle>
            </Col>
            <Col xs={24} sm={12}>
              {hasSelected && (
                <Button
                  style={{ float: isMobile?'left':'right' }}
                  type="primary"
                  onClick={() => handleRebuildProjects()}>
                  {t('rebuild')}
                </Button>
              )}
            </Col>
            <Col span={24}>
              <Form form={form} layout="vertical">
                <SearchProjectWrapper>
                  <CustomSearch>
                    <Form.Item name="searchProject">
                      <Input
                        onChange={onChange}
                        placeholder={t('search-for-an-entry')}
                        prefix={<SearchOutlined />}></Input>
                    </Form.Item>
                  </CustomSearch>
                  {searchValue && (
                    <CustomButton onClick={() => clearSearch()}>
                      <CloseOutlined />
                    </CustomButton>
                  )}
                </SearchProjectWrapper>
              </Form>
            </Col>
          </Row>
        </HeaderAdmin>
        <PaginationAdmin>
          <Table
            rowKey={record => record._id}
            size="default"
            bordered
            scroll={{ x: 1000 }}
            loading={isLoadingTableUsers}
            columns={columns}
            dataSource={adminStore.customListProjects?.data || []}
            rowSelection={rowSelection}
            pagination={{
              defaultPageSize: _limit,
              showSizeChanger: true,
              current: _page,
              pageSizeOptions: settings.adminPageSizeOptions,
              onChange: (page, pageSize) =>
                handleChangePagination(page, pageSize),
              total: adminStore.customListProjects?.count || 1,
              locale: { items_per_page: '' },
              position: ['topRight'],
            }}
            locale={{ emptyText: t('no-data') }}
            onRow={record => ({
              onClick: event => {},
            })}
          />
        </PaginationAdmin>
      </CustomContentAdminWrapper>
    </AdminTemplate>
  )
}

export default inject(
  'organizationStore',
  'commonStore',
  'adminStore'
)(observer(Projects))
