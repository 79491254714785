import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CesiumContext } from 'resium'
import { useMediaQuery } from 'react-responsive';
import { isMobile, isTablet } from 'react-device-detect';
import { Button, Tooltip } from 'antd';
import SVGIcon from '../SVGIcon'
import { ReactComponent as VRIcon } from '../../../assets/svgs/VR-s0.svg'
import { ReactComponent as VREndIcon } from '../../../assets/svgs/VREnd-s0.svg'
import { VRContainer } from './style'
import { Trans, useTranslation } from 'react-i18next';

const VRButton = ({ projectStore, viewer }) => {
    const { t } = useTranslation();
    const [isVROn, setVROn] = useState(false)
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const cesium = React.useContext(CesiumContext);

    useEffect(() => {
        return () => {
            setVROn(false)
        }
    }, [])

    return (
        <VRContainer
            style={{ display: (projectStore.cleanMode) ? 'none' : 'block' }}
            isDesktopOrLaptop={isDesktopOrLaptop ? 1 : 0} isPortrait={isPortrait ? 1 : 0}
        >
            <Tooltip title={t('vr-mode')} placement="left" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                <Button
                    onClick={() => {
                        if (!viewer || !viewer.current || !viewer.current.cesiumElement || !viewer.current.cesiumElement.vrButton) return;
                        viewer.current.cesiumElement.vrButton.viewModel.command();
                        if(!isVROn) projectStore.setCurrentHelpfeature('vr')
                        else projectStore.setCurrentHelpfeature('vr', 1)
                        setVROn(!isVROn)
                    }}
                    icon={<SVGIcon content={isVROn ? <VREndIcon /> : <VRIcon />} width={32} height={32} />} />
            </Tooltip>
        </VRContainer>

    );
};

export default inject('projectStore')(observer(VRButton))