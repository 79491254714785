import { assetUrl } from './config'
import { IonResource } from 'cesium'
import { getModelUrBySrc } from '@/helper'
import { toJS } from 'mobx'
import TreeUtils from './tree-utils'

const Utils = {
  resolveUrl: (...paths) => {
    return paths.reduce((resolvedUrl, path) => {
      let urlPath = path.toString().trim()
      if (urlPath)
        resolvedUrl +=
          (resolvedUrl === '' ? '' : '/') + urlPath.replace(/^\/|\/$/g, '')
      return resolvedUrl
    }, '')
  },
  resolvePageUrl: (...path) => {
    let resolvedUrl = Utils.resolveUrl(...path)
    return resolvedUrl + '/'
  },
  async getTileSetUrlFromModel(model) {
    let tileSetUrl = '';
    let accessToken = ''
    switch (model.type) {
      case 'landxml':
      case 'landxmlBackground':
      case 'ifc':
        tileSetUrl = assetUrl + model.hash + '/tileset.json'
        break
      case 'cad':
        tileSetUrl = assetUrl + model.hash
        tileSetUrl += (model.data?.api_ver === 3) ? '/ModelTileset.json' : '/tileset.json'
        break
      case 'e57':
      case 'model3d':
      case 'kmz':
      case 'geotiff':
      case 'cloudpoint':
        if (model.data?.srcTileset) {
          tileSetUrl = model.data?.srcTileset
        }
        break
      default:
        break
    }
    if (model.sourceType === 'external') {
      var tUrl = getModelUrBySrc(model.src)
      if (model.data.ionAssetId) {
        await IonResource.fromAssetId(model.data.ionAssetId).then(x => {
          tileSetUrl = x._url
          accessToken = x._ionEndpoint.accessToken
        })
      } else {
        tileSetUrl = tUrl
      }
    }
    return {
      tileSetUrl,
      accessToken
    }
  },
  getAllUrlParams(url) {
    try {
      // get query string from url (optional) or window
      var queryString = url.split('?')[1]
      var baseUrl = url.split('?')[0]
      // we'll store the parameters here
      var obj = {};

      // if query string exists
      if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var arr = queryString.split('&');

        for (var i = 0; i < arr.length; i++) {
          // separate the keys and the values
          var a = arr[i].split('=');

          // set parameter name and value (use 'true' if empty)
          var paramName = a[0];
          var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

          // (optional) keep case consistent
          paramName = paramName.toLowerCase();
          if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

          // if the paramName ends with square brackets, e.g. colors[] or colors[2]
          if (paramName.match(/\[(\d+)?\]$/)) {

            // create key if it doesn't exist
            var key = paramName.replace(/\[(\d+)?\]/, '');
            if (!obj[key]) obj[key] = [];

            // if it's an indexed array e.g. colors[2]
            if (paramName.match(/\[\d+\]$/)) {
              // get the index value and add the entry at the appropriate position
              var index = /\[(\d+)\]/.exec(paramName)[1];
              obj[key][index] = paramValue;
            } else {
              // otherwise add the value to the end of the array
              obj[key].push(paramValue);
            }
          } else {
            // we're dealing with a string
            if (!obj[paramName]) {
              // if it doesn't exist, create property
              obj[paramName] = paramValue;
            } else if (obj[paramName] && typeof obj[paramName] === 'string') {
              // if property does exist and it's a string, convert it to an array
              obj[paramName] = [obj[paramName]];
              obj[paramName].push(paramValue);
            } else {
              // otherwise add the property
              obj[paramName].push(paramValue);
            }
          }
        }
      }

      return obj;
    } catch (error) {
      return {}
    }

  },
  getLinkTileset(domain, projectName, modelName) {
    return domain + '/' + projectName + '/' + modelName
  },
  unflatten(arr) {
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    for (var i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id]['children'] = [];
    }


    for (var id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        if (mappedElem.parentId) {
          // console.log(toJS(mappedArr[mappedElem['parentId']]['children']))
          if (mappedArr[mappedElem['parentId']]) {
            mappedArr[mappedElem['parentId']]['children'].push(mappedElem);
          }

        }
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  },
  getDomain(url, subdomain) {
    subdomain = subdomain || false;

    url = url.replace(/(https?:\/\/)?(www.)?/i, '');

    if (!subdomain) {
      url = url.split('.');

      url = url.slice(url.length - 2).join('.');
    }

    if (url.indexOf('/') !== -1) {
      return url.split('/')[0];
    }

    return url;
  },
  getParamString(obj, existingUrl, uppercase) {
    var params = [];
    for (var i in obj) {
      params.push(encodeURIComponent(uppercase ? i.toUpperCase() : i) + '=' + encodeURIComponent(obj[i]));
    }
    return ((!existingUrl || existingUrl.indexOf('?') === -1) ? '?' : '&') + params.join('&');
  },
  splitAtFirstSpecialCharacter(str, character) {
    if (!str) return [];
    var i = str.indexOf(character);
    if (i > 0) {
      return [str.substring(0, i), str.substring(i + 1)];
    }
    else return [str];
  },
  fragmentShaderSource() {
    const fragmentShaderSource = `
      float getDistance(sampler2D depthTexture, vec2 texCoords) 
      { 
          float depth = czm_unpackDepth(texture(depthTexture, texCoords)); 
          if (depth == 0.0) { 
              return czm_infinity; 
          } 
          vec4 eyeCoordinate = czm_windowToEyeCoordinates(gl_FragCoord.xy, depth); 
          return -eyeCoordinate.z / eyeCoordinate.w; 
      } 
      float interpolateByDistance(vec4 nearFarScalar, float distance) 
      { 
          float startDistance = nearFarScalar.x; 
          float startValue = nearFarScalar.y; 
          float endDistance = nearFarScalar.z; 
          float endValue = nearFarScalar.w; 
          float t = clamp((distance - startDistance) / (endDistance - startDistance), 0.0, 1.0); 
          return mix(startValue, endValue, t); 
      } 
      vec4 alphaBlend(vec4 sourceColor, vec4 destinationColor) 
      { 
          return sourceColor * vec4(sourceColor.aaa, 1.0) + destinationColor * (1.0 - sourceColor.a); 
      } 
      uniform sampler2D colorTexture; 
      uniform sampler2D depthTexture; 
      uniform vec4 fogByDistance; 
      uniform vec4 fogColor; 
      in vec2 v_textureCoordinates; 
      out vec4 vFragColor;
      void main(void) 
      { 
          float distance = getDistance(depthTexture, v_textureCoordinates); 
          vec4 sceneColor = texture(colorTexture, v_textureCoordinates); 
          float blendAmount = interpolateByDistance(fogByDistance, distance); 
          vec4 finalFogColor = vec4(fogColor.rgb, fogColor.a * blendAmount); 
          vFragColor = alphaBlend(finalFogColor, sceneColor); 
      }
      `;
    return fragmentShaderSource
  },
  checkPointsceneToken(data) {
    if (data) {
      if (data.expires_at < new Date().getTime()) {
        return false
      }
      return true
    }
    return false
  },
  mathRound(value, decimals) {
    return Number(value.toFixed(decimals));
  },
  flipInt(n) {
    var result = 0
    if (n == 0) result = 1
    if (n == 1) result = 0
    if (n == 0.9) result = 0.1
    if (n == 0.8) result = 0.2
    if (n == 0.7) result = 0.3
    if (n == 0.6) result = 0.4
    if (n == 0.5) result = 0.5
    if (n == 0.4) result = 0.6
    if (n == 0.3) result = 0.7
    if (n == 0.2) result = 0.8
    if (n == 0.1) result = 0.9

    return result
  },
  parseXml(xmlStr) {
    if (typeof window.DOMParser !== "undefined") {
      return new window.DOMParser().parseFromString(xmlStr, "text/xml");
    } else if (typeof window.ActiveXObject !== "undefined" && new window.ActiveXObject("Microsoft.XMLDOM")) {
      var xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
      xmlDoc.async = "false";
      xmlDoc.loadXML(xmlStr);
      return xmlDoc;
    } else {
      throw new Error("No XML parser found");
    }
  },
  getUrl(url, params) {
    var encodedParameters = Object.keys(params)
      .map(paramName => paramName + '=' + encodeURI(params[paramName]))
      .join('&');

    return url + '?' + encodedParameters;
  },
  XMLToString(oXML) {
    if (window.ActiveXObject) {//code for IE
      var oString = oXML.xml; return oString;
    } else {
      return (new XMLSerializer()).serializeToString(oXML);
    }
  },
  strip_html_tags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/<[^>]*>/g, '').trim();
  },
  getObjectInfoV2Label(inf) {
    let value = '';
    const regexA = /^(.*)=/; 
    const regexB = /"label":"(.*?)"/; 

    let match = inf.match(regexA);
    if (match && match[1]) {
      value = match[1].trim();
    } else {
      match = inf.match(regexB);
      if (match && match[1]) {
        value = match[1].trim();
      }
    }
    return value;
  },
  getSketchScale(defaultLength, defaultWidth, defaultHeight, width, extrudedHeight) {
    const X = defaultLength || 1;
    const Y = defaultWidth || 1;
    const Z = defaultHeight || 1;
    const Scale_Y = (width === 0 || extrudedHeight === 0) ? 0 : ((Y && Y !== 1) ? width / Y : (width || 1))
    const Scale_X = (width === 0 || extrudedHeight === 0) ? 0 : ((X && X !== 1) ? width / X : (width || 1))
    const Scale_Z = (width === 0 || extrudedHeight === 0) ? 0 : (Z ? (extrudedHeight || 1) / Z : (extrudedHeight || 1))
    return { Scale_X, Scale_Y, Scale_Z };
  },
  checkAlphaSketch(alpha){
    let _alpha = ((1 - alpha) || (1 - alpha) === 0) ? 1 - alpha : 1;
    return _alpha === 0 ? 0.005 : _alpha;
  },
  getSketchLibraryModel(sketchingStore, sketchId) {
    if (sketchId) {
      let hasSketchTypeModel = false
      let sketchesList = sketchingStore.arrSketches
      let model = sketchesList.find(item => item._id == sketchId)
      if (!model) return false
      if (sketchingStore.sketchLibraryElements && model.typeLibrary && model.typeLibrary.checkedKeys && model.typeLibrary.checkedKeys.length > 0) {
        if (sketchingStore.sketchLibraryElements) {
          let data = sketchingStore.sketchLibraryElements
          let result = []
          let _checkedKey = model.typeLibrary.checkedKeys
          _checkedKey.map(key => {
            let node = TreeUtils.searchTreeNode(data, 'key', key);
            if (node && node.model) {
              result.push(toJS(node))
            }
          })
          hasSketchTypeModel = result.length > 0 ? true : false
        }
      }
      return hasSketchTypeModel
    }
    return false
  },
  kbytesToSize(kb) {
    var sizes = ['KB', 'MB', 'GB', 'TB'];
    if (!kb) return '0 GB';
    var i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)));
    let _quota = Number((kb / Math.pow(1024, i)).toFixed(3))
    return _quota + ' ' + sizes[i];
  },
  generateSketchNameByType(arrSketches, sketchType, isVolume) {
    if (sketchType) {
      let sktArr = arrSketches.filter(c => c.type === sketchType)
      let count
      if (sketchType === 'area') {
        let arr
        if (isVolume) {
          arr = sktArr.filter(c => !c.readonlyHeight)
        } else {
          arr = sktArr.filter(c => c.readonlyHeight)
        }
        count = arr.length + 1
      } else {
        count = sktArr.length + 1
      }
      let countSkt = count >= 1000 ? count
        : count >= 100 ? ('0' + count)
          : count >= 10 ? ('00' + count)
            : ('000' + count)
      let type = isVolume ? 'Volume'
        : sketchType === 'point' ? 'Single'
          : (sketchType.charAt(0).toUpperCase() + sketchType.slice(1));
      return type + '.' + countSkt
    }
  },
  generateSketchName(sketchName){
    let arrSplitName = sketchName.split('.')
    let name
    if (arrSplitName.length > 1) {
      let endName = arrSplitName[arrSplitName.length - 1]
      let parseName = parseFloat(endName) + 1
      let dfParseName = parseFloat(endName)
      if (parseName && endName.length > 0 && endName.length <= 4) {
        let lastString = sketchName.lastIndexOf('.' + endName)
        let countSkt = parseName >= 1000 ? parseName
          : parseName >= 100 ? ('0' + parseName)
            : parseName >= 10 ? ('00' + parseName)
              : ('000' + parseName)
        name = sketchName.substr(0, lastString) + '.' + countSkt
      } else if (parseName && endName.length > 4 && endName.length === (dfParseName.toString()).length) {
        let lastString = sketchName.lastIndexOf('.' + endName)
        name = sketchName.substr(0, lastString) + '.' + parseName
      }
      else {
        name = sketchName + '.0001'
      }
    } else {
      name = sketchName + '.0002'
    }
    return name
  },
  convertLink(httpslink){
    if (!httpslink) {
      return;
    }

    let videoId;
    let type = null;
    let srclink = httpslink;

    // 1. YouTube - Regular link (e.g., https://www.youtube.com/watch?v=torqlZnu9Ag5...)
    let match = httpslink.match(/v=([a-zA-Z0-9-_]+)/);
    if (match) {
      videoId = match[1];
      type = 'youtube';
    }

    // 2. YouTube - Share link (e.g., https://www.youtube.com/watch?v=torqlZnu9Ag6...)
    if (!videoId) {
      match = httpslink.match(/youtu\.be\/([a-zA-Z0-9-_]+)/);
      if (match) {
        videoId = match[1];
        type = 'youtube';
      }
    }

    // 3. YouTube - Embed link (just extract the ID)
    if (!videoId) {
      match = httpslink.match(/\/embed\/([a-zA-Z0-9-_]+)/);
      if (match) {
        videoId = match[1];
        type = 'youtube';
      }
    }

    // 4. Vimeo - Regular link (e.g., https://vimeo.com/17...)
    if (!videoId) {
      match = httpslink.match(/vimeo\.com\/(\d+)/);
      if (match) {
        videoId = match[1];
        type = 'vimeo';
      }
    }

    // 5. Vimeo - Embed link (extract the ID)
    if (!videoId) {
        match = httpslink.match(/\/video\/(\d+)/)
        if (match){
            videoId = match[1];
            type = 'vimeo';
        }
    }


    if (videoId && type === 'youtube') {
      srclink = `https://www.youtube.com/embed/${videoId}`;
    } else if (videoId && type === 'vimeo') {
      srclink = `https://player.vimeo.com/video/${videoId}`;
    }
    
    return srclink
  }
}

export default Utils
