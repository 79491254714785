import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { SettingControlContainer } from './CustomStyled';
import { Trans, useTranslation } from 'react-i18next';
import { HelpButton } from '@/components/elements'
const Cesium = require('cesium')


const BackgroundColorSetting = ({
    projectStore,
    uiStore,
    viewer,
    commonStore,
    usersStore,
    projectSettingStore }) => {
    const [color, setColor] = useState(null);
    const { t } = useTranslation();
    const onChangeColor = (e) => {
        commonStore.setBackgroundColorSetting(e.target.value)
        projectSettingStore.setAssignObjSystemProjectSetting('backgroundColorSetting', e.target.value)
    }

    const onCancel = () => {
        commonStore.setBackgroundColorSetting(color)
        projectStore.setShowBackgroundSetting(false)
        projectStore.setCleanMode(false)
        projectSettingStore.setAssignObjSystemProjectSetting('backgroundColorSetting', color)
    }

    const onSaveBackgroundColor = () => {
        commonStore.setBackgroundColorSetting(commonStore.backgroundColorSetting)
        if (usersStore.currentUser?.id) {
            let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
            projectStore.setLoadingProgress(true)
            projectStore.updateProjectMetadata({ metadata}).then(res => {
                projectStore.setLoadingProgress(false)
                projectStore.setShowBackgroundSetting(false)
                projectStore.setCleanMode(false)
                message.success(t('updated-successfully'))
            })
                .catch(err => {
                    projectStore.setLoadingProgress(false)
                })
        } else {
            projectStore.setShowBackgroundSetting(false)
            projectStore.setCleanMode(false)
            message.success(t('updated-successfully'))
            if (viewer.current.cesiumElement && viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
    }

    useEffect(() => {
        let data = projectSettingStore.systemProjectSetting
        commonStore.setBackgroundColorSetting(data.backgroundColorSetting)
        setColor(data.backgroundColorSetting)

    }, [projectSettingStore.systemProjectSetting])


    return (
        <SettingControlContainer>
            <div className='help-btn-wrap'>
                <HelpButton helppage={'system_settings_background_color'}/>
            </div>
            <div style={{ padding: 20 }}>
                <p><strong>{t('background-color-picker')}</strong></p>
                <br />
                <input
                    style={{ width: '100%', height: '150px' }}
                    type="color"
                    value={projectSettingStore.systemProjectSetting.backgroundColorSetting}
                    onChange={onChangeColor} />

            </div>

            <br />
            <Button style={{ marginLeft: 5 }}
                type="default"
                icon={<CloseOutlined />}
                onClick={onCancel}>
                {t('commons.cancel')}
            </Button>
            <Button
                style={{ marginLeft: 5 }}
                type="primary"
                onClick={onSaveBackgroundColor}
                icon={<SaveOutlined />}
            >
                {t('commons.save')}
            </Button>
        </SettingControlContainer>
    )
}
export default inject('projectStore', 'uiStore', 'commonStore', 'projectSettingStore', 'usersStore')(observer(BackgroundColorSetting))
