import { CloseOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Col, Input, InputNumber, message, Row, Form, TreeSelect, Card, Switch } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import validator from '@/validator'
import { useState } from 'react'
import { HiddenAreaUtils } from './HiddenAreaUtils'

const DrawHiddenAreaForm = ({ projectStore, polylineCutToolStore, hideArea, viewer }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [hiddenAreaOld, setHiddenAreaOld] = useState(false)

  useEffect(() => {
    if (hideArea && hideArea.points.length > 0) {
      form.setFieldsValue({
        name: hideArea.name,
        visibilitySource: hideArea.visibilitySource,
        isCutTerrain: hideArea.isCutTerrain
      })

      return () => {
        form.resetFields();
      }
    }
  }, [hideArea.points, hideArea.isCutTerrain])

  useEffect(() =>{
    return () => {
      if(!polylineCutToolStore.showForm) onCancel()
    }
  }, [polylineCutToolStore.showForm])

  const onChangeColor = (e) => {
    polylineCutToolStore.setHideArea({ edgeColor: e.target.value })
  }
  useEffect(() => {
    if (projectStore.clippingMode) {
      form.validateFields().then(async res => {
        if (hideArea.points?.length > 0) {
          let resdata = {
            name: res.name,
            edgeColor: res.edgeColor,
            edgeWidth: res.edgeWidth,
            visibilitySource: res.visibilitySource,
            points: toJS(hideArea.points),
            isCutTerrain: hideArea.isCutTerrain,
          }
          polylineCutToolStore.setHideAreaTemp(resdata)
          HiddenAreaUtils.cancelState(viewer, polylineCutToolStore, false);
        }
      })
    } else if (!projectStore.clippingMode) {
      let hiddenAreaDraw = polylineCutToolStore.hideAreaTemp || hideArea
      if (viewer && hiddenAreaDraw?.points?.length > 0 && polylineCutToolStore.drawEditor) {
        HiddenAreaUtils.cancelState(viewer, polylineCutToolStore, true);
        HiddenAreaUtils.drawArea(viewer, hiddenAreaDraw, false);
      }
      //polylineCutToolStore.setHideAreaTemp(false)
    }
  }, [projectStore.clippingMode, polylineCutToolStore.hideArea])

  const onSave = async () => {
    try {
      await form.validateFields();
      const res = form.getFieldsValue();
      const resdata = {
        name: res.name,
        edgeColor: res.edgeColor,
        edgeWidth: res.edgeWidth,
        visibilitySource: res.visibilitySource,
        points: hideArea.points,
        isCutTerrain: hideArea.isCutTerrain,
      };

      if (!polylineCutToolStore.hideArea._id) {
        resdata._id = uuidv4();
      }

      const _tilesetData = projectStore.projectDetail.tilesetData;
      _tilesetData.hiddenArea = resdata;

      const newData = {
        tilesetData: _tilesetData,
      };

      await projectStore.updateProject(newData);
      if (polylineCutToolStore.drawEditor.currentEditEntity) {
        if (polylineCutToolStore.drawEditor.currentEditEntity.onCancel) {
          polylineCutToolStore.drawEditor.currentEditEntity.onCancel();
        }
        viewer.entities.remove(polylineCutToolStore.drawEditor.currentEditEntity);
      }
      polylineCutToolStore.setDrawEditor(false);
      polylineCutToolStore.setDrawMode(false);
      polylineCutToolStore.setHideArea(projectStore.projectDetail.tilesetData?.hiddenArea || false);
      polylineCutToolStore.setShowForm(false);
      polylineCutToolStore.setHideAreaTemp(false);
    } catch (error) {
      console.log(error);
    }
  };


  const onCancel = () => {
    if (polylineCutToolStore.drawEditor.currentEditEntity) {
      if (polylineCutToolStore.drawEditor.currentEditEntity.onCancel)
        polylineCutToolStore.drawEditor.currentEditEntity.onCancel();
      viewer.entities.remove(polylineCutToolStore.drawEditor.currentEditEntity);
    }
    if (projectStore.projectDetail.tilesetData?.hiddenArea) {
      polylineCutToolStore.setHideArea(projectStore.projectDetail.tilesetData?.hiddenArea)
    } else {
      //clear clipping 3dtile before call
      if (viewer.scene && !viewer.scene.isDestroyed() && viewer.scene.primitives) {
        for (var i = 0; i < viewer.scene.primitives.length; i++) {
          var primitive = viewer.scene.primitives.get(i)
          // remove clipping
          if (primitive.clippingPlanes) {
            primitive.clippingPlanes.removeAll();
          }
        }
      }
      //clear clipping terrain
      if (viewer.scene.globe.clippingPlanes) {
        viewer.scene.globe.clippingPlanes.enabled = !1
        viewer.scene.globe.clippingPlanes.removeAll()
      }

      polylineCutToolStore.setHideArea(projectStore.projectDetail.tilesetData?.hiddenArea ? projectStore.projectDetail.tilesetData.hiddenArea : false)
    }

    polylineCutToolStore.setDrawEditor(false)
    polylineCutToolStore.setDrawMode(false) // reset drawmode
    polylineCutToolStore.setShowForm(false)
    polylineCutToolStore.setHideAreaTemp(false)
  }

  const onChangeTree = (newValue) => {
    polylineCutToolStore.setHideArea({ visibilitySource: newValue })
  };

  const onChangeActive = (value) => {
    polylineCutToolStore.setHideArea({ isCutTerrain: value })
  };

  return (
    <Card bodyStyle={{ padding: '12px' }}>
      <Form form={form} layout="vertical">
        <Row align="middle">
          <Col span={8}>{t('name')}</Col>
          <Col span={16}>
            <Form.Item
              name="name"
              initialValue={hideArea.name}
              rules={[
                {
                  required: true,
                  message: t('name-is-required'),
                },
                {
                  validator: validator.validateEmptyString,
                },
              ]}
            >
              <Input placeholder={t('input-name')} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: 8 }} align="middle">
          <Col span={8}>{t('edge-color')}</Col>
          <Col span={16}>
            <Form.Item
              initialValue={hideArea.edgeColor}
              name="edgeColor">
              <input type="color" className='custom-input-color' onChange={onChangeColor} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: 8 }} align="middle">
          <Col span={8}>{t('edge-width')}</Col>
          <Col span={16}>
            <Form.Item
              name='edgeWidth'
              initialValue={hideArea.edgeWidth}
            >
              <InputNumber onChange={value => { polylineCutToolStore.setHideArea({ edgeWidth: value }) }} min={0} max={100} step={1.0} className='hw-full' style={{ marginBottom: 10 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: 8 }} align="middle">
          <Col span={8}>{t('data-sources')}</Col>
          <Col span={16}>
            <Form.Item
              name='visibilitySource'
            >
              <TreeSelect
                treeData={toJS(projectStore.modelList).map(item => {
                  return { title: item.name, value: item._id, key: item._id }
                })}
                onChange={onChangeTree}
                treeCheckable={true}
                placeholder={t('please-select')}
                maxTagPlaceholder={`+ ${hideArea.visibilitySource?.length} ${t('selected')}`}
                maxTagCount={0}
                allowClear={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: 8 }} align="middle">
          <Col span={8}>{t('cut-terrain')}</Col>
          <Col span={16}>
            <Form.Item
              valuePropName="checked"
              name="isCutTerrain">
              <Switch
                style={{ width: 'auto' }}
                checked={polylineCutToolStore.hideArea.isCutTerrain}
                checkedChildren={t('commons.on')}
                unCheckedChildren={t('commons.off')}
                onChange={onChangeActive}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingTop: 8 }} align="bottom">
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button
              type="default"
              icon={<CloseOutlined />}
              onClick={() => onCancel()}>
              {t('commons.cancel')}
            </Button>
            <Button
              icon={<SaveOutlined />}
              style={{ minWidth: '90px', marginLeft: 5 }}
              type="primary"
              onClick={() => onSave()}
            >
              {t('commons.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default withRouter(
  inject('projectStore', 'polylineCutToolStore')(observer(DrawHiddenAreaForm))
)


