
import { Button, Form, Input, message, Modal, Row } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'uuid'
import TreeUtils from '@/tree-utils'
import validator from '@/validator'
import _ from 'lodash';

const ModalAddElement = ({ organizationStore, expandedKeys, getParentKeys }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const AddForm = () => {
        useEffect(() => {
            if (organizationStore.elementEdit) {
                form.setFieldsValue({
                    title: organizationStore?.selectedNode?.title
                });
            } else {
                form.setFieldsValue({
                    title: '',
                    type: ''
                });
            }
        }, [organizationStore.selectedNode, organizationStore.showAddElement])
        return (
            <Form form={form} name="edit-element" layout="vertical">
                <Form.Item label={t('name')} name="title" rules={[
                    {
                        required: true,
                        message: t('please-input-the-title-of-element'),
                    },
                    { validator: validator.validateEmptyString },
                ]}>
                    <Input
                        placeholder={t('input-element-name')} />
                </Form.Item>
                {
                    !organizationStore.elementEdit && (
                        <Form.Item label={t('type')} name="type" rules={[
                            {
                                required: false,
                                message: t('please-input-the-type-of-element'),
                            },
                            { validator: validator.validateEmptyString },
                        ]}>
                            <Input
                                placeholder={t('input-elemnet-type')} />
                        </Form.Item>
                    )
                }
            </Form>
        )
    }
    const onCancelModal = () => {
        organizationStore.setShowAddElement(false);
        form.resetFields()
        organizationStore.setElementEdit();
    }

    const onSaveModal = () => {
        form.validateFields().then(values => {
            const { treeData } = organizationStore.currentSketchElement.data;
            const newNode = {
                title: values.title,
                id: organizationStore.elementEdit ? organizationStore.selectedNode?.id : uuid(),
                key: organizationStore.elementEdit ? organizationStore.selectedNode?.key : uuid(),
                type: organizationStore.elementEdit ? organizationStore.selectedNode?.type : values.type,
                parentKey: organizationStore.selectedNode?.type === 'FOLDER' ? organizationStore.selectedNode?.key : organizationStore.selectedNode?.type === 'tree' ? organizationStore.selectedNode?.parentKey : null,
                children: []
            }
            if (organizationStore.elementEdit) {
                addNewTreeNode(newNode, toJS(treeData));
            } else {
                addNewTreeNode(newNode, toJS(treeData), organizationStore.selectedNode?.type === 'FOLDER' ? organizationStore.selectedNode?.key : organizationStore.selectedNode?.type === 'tree' ? organizationStore.selectedNode?.parentKey : null);
            }
        })
    }

    const addNewTreeNode = (child, data, toData) => {
        if (data?.length) {
            if (organizationStore.elementEdit) {
                let toNode;
                if (organizationStore.elementEdit.parentKey) {
                    const parrentNode = TreeUtils.searchTreeNode(data, 'key', organizationStore.elementEdit.parentKey);
                    toNode = parrentNode.children;
                } else {
                    toNode = data;
                }
                const existed = toNode.find(item => item.title === child.title);
                if (existed && existed.key !== organizationStore.elementEdit.key) return message.error(t('element-already-existed'))
                const node = toNode.find(child => child.key === organizationStore.elementEdit.key);
                node.title = child.title;
                node.type = child.type;
            } else {
                let toNode;
                if (toData) {
                    const node = TreeUtils.searchTreeNode(data, 'key', toData);
                    node.children = node.children && node.children.length ? node.children : [];
                    toNode = node.children;
                } else {
                    toNode = data;
                }
                const existed = toNode.find(item => item.title === child.title);
                if (existed) return message.error(t('element-already-existed'))
                toNode.push(toJS(child))
            }
        } else {
            data = [child]
        }
        let _param = {
            ...organizationStore.currentSketchElement.data,
            treeData: data,
            expandedKeys: _.uniqBy([...expandedKeys, ...getParentKeys(data,child.key)])
        }
        if (!organizationStore.elementEdit) {
            _param.selectedKeys = [child.key]
            _param.selectedNode = child
        } else {
            _param.selectedNode = { ...organizationStore.selectedNode, title: child.title, type: child.type }
            _param.selectedKeys = [organizationStore.selectedNode.key]
        }
        organizationStore.setLoadingProgress(true)
        organizationStore.updateSketchElement(organizationStore.currentSketchElement.id, { data: _param }).then(res => {
            if (organizationStore.elementEdit) {
                organizationStore.setSelectedNode({ ...organizationStore.selectedNode, title: child.title, type: child.type })
            }
            form.resetFields()
            organizationStore.setShowAddElement(false);
            message.success(organizationStore.elementEdit ? t('rename-element-successfully') : t('add-element-successfully'))
            organizationStore.setElementEdit();
        }).catch(err => {
            console.log(err)
            message.error(organizationStore.elementEdit ? t('rename-element-failed') : t('add-element-failed'))
        }).finally(() => {
            organizationStore.setLoadingProgress(false)
        })
    }


    return (
        <Modal
            visible={organizationStore.showAddElement}
            title={organizationStore.elementEdit ? t('rename') : t('create-new-element')}
            onCancel={onCancelModal}
            onOk={onSaveModal}
            forceRender={true}
            zIndex={100000}
            maskClosable={false}
            footer={
                <Row justify='end'>
                    <Button key="back" onClick={onCancelModal}>
                        {t('commons.cancel')}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={onSaveModal}>
                        {organizationStore.elementEdit ? t('rename') : t('commons.create')}
                    </Button>
                </Row>
            }>
            <AddForm />
        </Modal>
    )
}

export default inject('adminStore', 'organizationStore')(observer(ModalAddElement))