import {
  CloseOutlined,
  InfoCircleOutlined,
  LoadingOutlined, QuestionCircleOutlined, ReloadOutlined, SaveOutlined
} from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Popconfirm,
  Row,
  Select,
  Slider,
  Tooltip,
  Spin,
  message,
  Typography,
  Switch,
  Divider
} from 'antd'
import { Cartesian3, Math as CesiumMath, Matrix4, Cartographic, Ellipsoid, GoogleMaps, createGooglePhotorealistic3DTileset } from 'cesium'
import { debounce } from "lodash"
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { default as React, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { ProjectRequest } from '@/requests'
import { pointCloudStyleType, setXMLModelOnPlane, getXYZDialog, transformECFModel, getCurrentModel, isCloudPointXDEnginePlane, isIFCEllipsoid, isCloudPoint, isXMLEllipsoid, isCloudPointXDEngineEllipsoid, isE57XDEngineEllipsoid, isIFCEllipsoidRealWorldCoordinates, isE57XDEnginePlane, isCloudPointCesiumION, isIFCPlane } from '@/helper'
import { EditPosLeftPanel, LocationPanelHeader } from '../CustomStyled'
import { getOriginPublicJsonLink, gotoUserViewpoint, processPublicLink } from '@/lib/projectLib'
import { isMobile, isTablet } from 'react-device-detect'
import { Trans, useTranslation } from 'react-i18next';
import { toJS } from 'mobx'
import "moment/locale/es";
import en from "antd/lib/date-picker/locale/en_US";
import fi from "antd/lib/date-picker/locale/fi_FI";
import sv from "antd/lib/date-picker/locale/sv_SE";
import es from "antd/lib/date-picker/locale/es_ES";
import vi from "antd/lib/date-picker/locale/vi_VN";
import TreeUtils from '@/tree-utils'
import uuid from 'uuid'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import CustomAttributes from '@/components/layout/CustomAttributes/CustomAttributes'
import Utils from '@/utils'
import { HelpButton } from '@/components/elements'
import { Buffer } from 'buffer';
const { Option } = Select
const { Panel } = Collapse
const Cesium = require('cesium')

const EditPositionPanel = ({ projectStore, adminStore, modelStore, commonStore, sketchingStore, capturesStore, usersStore, visible, viewer, model ,syncLastSessionSettings }) => {
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)
  const [resetState, setResetState] = useState({ loading: false, isDisabled: false });
  const [loading, setLoading] = useState(false) // loading used when click button save model
  const [loadingCloseDrawerEditModel, setLoadingCloseDrawerEditModel] = useState(false)  //loadingCloseDrawerEditModel used when click button close drawer edit model

  const [originalMatrix, setOriginalMatrix] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [style3D, setStyle3D] = useState(null)
  const [startString, setStartString] = useState(false)
  const [endDate, setEndDate] = useState(null)
  const [endString, setEndString] = useState(false)
  const [isRender, setIsRender] = useState(false)
  const [elevationSystem, setElevationSystem] = useState('None')
  const [EpsgCode, setEpsgCode] = useState(false)//900913
  const [EpsgName, setEpsgName] = useState('')
  const [updateNumber, setUpdateNumber] = useState(false) //disable input X, Y, Z when loading

  const [X, setX] = useState(0)
  const [Y, setY] = useState(0)
  const [Z, setZ] = useState(0)

  const [longitude, setLongitude] = useState(0)
  const [latitude, setLatitude] = useState(0)
  const [height, setHeight] = useState(0)
  const [scale, setScale] = useState(1)

  const [heading, setHeading] = useState(modelStore.hpr.h)
  const [pitch, setPitch] = useState(modelStore.hpr.p)
  const [roll, setRoll] = useState(modelStore.hpr.r)
  const [transparency, setTransparency] = useState(0)
  const [projectionList, setProjectionList] = useState([])
  const antIcon = <LoadingOutlined style={{ fontSize: 16, color: '#333' }} spin />
  const inputRef = useRef([]);
  const [isIdenticalMatrix, setIdenticalMatrix] = useState(false)
  const [form] = Form.useForm();
  const [validJson, setValidJson] = useState(false)
  const [isPointcloud, set3DtileIsPointcloud] = useState(false)
  const [isUseCredential, setUseCredentialization] = useState(false)

  let timeout;
  let currentValue;
  const checkingFeatureRole = (type) => {
    if (!type) return false
    return adminStore.checkingFeatureRole(projectStore, type)
  }

  const isUrl = urlString => {
    return (
      urlString &&
      (urlString.startsWith('https://') || urlString.startsWith('http://'))
    )
  }

  const getModelAttributes = async () => {
    const objectId = model._id
    await projectStore.getModelAttributes(objectId, { project: projectStore.projectDetail._id }).then(res => {
      sketchingStore.setObjectCustomAttributes(res)
    }).catch(err => {
      console.log(err)
    })
  }

  const getGeoHeight = async (model) => {
    if (model.crs && model.crs.RefPoint && !projectStore.projectDetail.tilesetData.RefPoint) {
      await projectStore.calculateHeighCenterModelFlowGeoid(model.crs.RefPoint[0], model.crs.RefPoint[1], model.crs.RefPoint[2], projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None').then(async response => {
        if (response.Status === "OK") {
          modelStore.setGeoHeight(response.Heigh - model.crs.RefPoint[2])
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }

  useEffect(() => {
    if (usersStore.currentUser) {
      if (model) {
        (async () => {
          projectStore.setLoadingProgress(true)
          await getModelAttributes()
          await getGeoHeight(model) // for case project only model point clound mouse move
          projectStore.setLoadingProgress(false)
        })()
      }
    }
  }, [model])

  const checkIsLonLatHeight = (position) => {
    //lon
    if (position[1] > 180 || position[1] < -180) return false;
    //lat
    if (position[0] > 90 || position[0] < -90) return false;
    return true
  }
  /** Effect listen change model(only call first when form edit model open) */
  useEffect(() => {
    if (!model) return
    if (model) {
      loadModelDetail(model)
      let cm = getCurrentModel(
        projectStore.currentModelId,
        toJS(projectStore.modelList),
        projectStore.tileViews
      )
      setTransparency(model.data.transparency || 0)
      modelStore.resetFromModel(model.data.saveMatrix)
      if (model?.name) {
        form.setFieldsValue({ name: model.name });
      }
      if (model.sourceType === 'external' && model.type === 'unknown') {
        var currentTranslation = new Cartesian3();
        let matrix4 = cm.tile && cm.tile._root && cm.tile._root.transform ? cm.tile.root.transform : new Matrix4()
        Matrix4.getTranslation(matrix4, currentTranslation);
        // modelStore.setxyzLocal([currentTranslation.x, currentTranslation.y, currentTranslation.z])
        // modelStore.setFileOrigo(currentTranslation)
        // modelStore.setScale(model.data?.saveMatrix?.scale)
        // modelStore.setHpr(model.data?.saveMatrix?.hpr.h, model.data?.saveMatrix?.hpr.p, model.data?.saveMatrix?.hpr.r)
        modelStore.setUpdateFromViewer(true)
        // setOriginalMatrix([currentTranslation.x, currentTranslation.y, currentTranslation.z])
        if (model.sourceType === 'external' && !model?.data?.cesiumToken) {
          if (model.data.credential) {
            setUseCredentialization(true)
            form.setFieldsValue({ username: model.data.credential });
            form.setFieldsValue({ password: model.data.credential });
          } else { // case for old model 
            if (isUrl(model.src)) {
              ProjectRequest.checkJSONLink({ url: model.src }).then(res => {
                if (res && (res.data.status === 401 || res.data.status === 403)) {
                  setUseCredentialization(true)
                }
              })
            }
          }
          projectStore.setDeleteIds([])
          setValidJson(true)
          form.setFieldsValue({ name: model.name });
          form.setFieldsValue({ src: `${model.src}` });
          if (model.src && model.src.includes("tile.googleapis.com")) {
            form.setFieldsValue({ src: `${model.src.split('?')[0]}` });
          }
          form.setFieldsValue({ detailLevel: model.data?.detailLevel || 16 });
          set3DtileIsPointcloud(model.data.is3DTilesPointcloud ? model.data.is3DTilesPointcloud : false)
        } else {
          form.setFieldsValue({ ionAssetId: model.data?.ionAssetId || '' });
          form.setFieldsValue({ cesiumToken: model.data?.cesiumToken || '' });
          form.setFieldsValue({ detailLevel: model.data?.detailLevel || 1 });
        }
      } else if (model.sourceType === 'local' && model.type === 'geojson') {
        form.setFieldsValue({
          clampToGround: model.data?.clampToGround
        });
        if (model.data.style && model.data.style.point) {
          form.setFieldsValue({
            isShowPointAs: model.data.style.point.isShowPointAs ? model.data.style.point.isShowPointAs : 'Billboard', //Billboard; Point
            pointColor: model.data.style.point.color ? model.data.style.point.color : '#ffffff',
            pointAlpha: model.data.style.point.alpha,
            pointPixelSize: model.data.style.point.pixelSize ? model.data.style.point.pixelSize : '',
            pointOutlineColor: model.data.style.point.outlineColor ? model.data.style.point.outlineColor : '#000000',
            pointOutlineWidth: model.data.style.point.outlineWidth ? model.data.style.point.outlineWidth : 0,
          })
        }
        if (model.data.style && model.data.style.line) {
          form.setFieldsValue({
            lineWidth: model.data.style.line.width ? model.data.style.line.width : 1,
            lineMaterial: model.data.style.line.color ? model.data.style.line.color : '#ffffff',
            lineAlpha: model.data.style.line.alpha
          })
        }
        if (model.data.style && model.data.style.polygon) {
          form.setFieldsValue({
            polygonMaterial: model.data.style.polygon.color ? model.data.style.polygon.color : '#ffffff',
            polygonAlpha: model.data.style.polygon.alpha,
            polygonHeight: model.data.style.polygon.height ? model.data.style.polygon.height : '',
            polygonOutlineColor: model.data.style.polygon.outlineColor ? model.data.style.polygon.outlineColor : '#000000',
            polygonExtrudedHeight: model.data.style.polygon.extrudedHeight ? model.data.style.polygon.extrudedHeight : ''
          })
        }
      } else if (model.sourceType === 'local' && model.type === 'geotiff') {
        form.setFieldsValue({
          alpha: model.data?.alpha || 1,
          classificationType: model.data?.classificationType || null
        });
      } else {
        if (model.data?.saveMatrix?.fileOrigo) {
          if (model.data?.saveMatrix?.xyzLocal && model.data?.saveMatrix?.xyzInput && model.data?.saveMatrix?.xyzLocal[0] === model.data?.saveMatrix?.xyzInput[0] && model.data?.saveMatrix?.xyzLocal[1] === model.data?.saveMatrix?.xyzInput[1]) {
            modelStore.setxyzLocal(model.data?.saveMatrix?.xyzInput)
          } else {
            var projectEpsgCode = projectStore.projectDetail?.tilesetData?.coordinateSystem?.code
            if ((['4326', '4756'].includes(projectEpsgCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
              setLatitude(modelStore.xyzLocal[0] || 0)
              setLongitude(modelStore.xyzLocal[1] || 0)
              setHeight(modelStore.xyzLocal[2] || 0)
            }
            else {
              modelStore.setxyzLocal(model.data?.saveMatrix?.xyzLocal ? model.data?.saveMatrix?.xyzLocal : [0, 0, 0])
            }
          }
          modelStore.setFileOrigo(model.data?.saveMatrix?.fileOrigo ? model.data?.saveMatrix?.fileOrigo : { x: 0, y: 0, z: 0 })
          modelStore.setScale(model.data?.saveMatrix?.scale)
          modelStore.setHpr(model.data?.saveMatrix?.hpr.h, model.data?.saveMatrix?.hpr.p, model.data?.saveMatrix?.hpr.r)
          if (model?.type === "cloudpoint" && model?.data?.ifcSetting?.importer === "ellipsoid") return;
          projectStore.setIsUpdateViewOrigo(true)
          modelStore.setUpdateFromViewer(true)
        }
      }
    }
    return () => {
      setStartDate(null)
      setEndDate(null)
      setX(0)
      setY(0)
      setZ(0)
      setLongitude(0)
      setLatitude(0)
      setHeight(0)
      setScale(1)
      setStyle3D(null)
      setProjectionList([])
      modelStore.setxyzLocal(false)
      modelStore.setProjectRefPointOnProjectionPlane(false)
      modelStore.setGeoHeight(false)
      setIdenticalMatrix(false)
      setOriginalMatrix(null)
    }
  }, [model])

  /**
   * Load model detail
   * @param {*} _model 
   */
  const loadModelDetail = async (_model) => {
    // XD generates JSON link in Data settings for model
    await processPublicLink(model)

    if (_model.startDate) {
      const localstartDate = moment.utc(_model.startDate).local().format()
      setStartDate(moment(localstartDate, 'YYYY-MM-DD hh:mm:ss'))
    }

    if (_model.endDate) {
      const localendDate = moment.utc(_model.endDate).local().format()
      setEndDate(moment(localendDate, 'YYYY-MM-DD hh:mm:ss'))
    }

    if (model.style) {
      setStyle3D(model.style.type)
    }
    setStartString(_model.startDate)
    setEndString(_model.endDate)
    setIsRender(_model.isRender !== undefined ? _model.isRender : false)

    var projectEpsgCode = projectStore.projectDetail?.tilesetData?.coordinateSystem?.code
    if (!projectEpsgCode)
      projectEpsgCode = projectStore.projectDetail?.coordinateSystem
    if (!projectEpsgCode)
      projectEpsgCode = '4326'

    if (_model.crs && _model.crs.epsgCode && _model.crs.epsgCode.slice(_model.crs.epsgCode.lastIndexOf(':') + 1).trim() !== '')
      projectEpsgCode = _model.crs.epsgCode.slice(_model.crs.epsgCode.lastIndexOf(':') + 1).trim();

    var projectElevation = projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'
    if (!projectElevation) projectElevation = 'None'

    setElevationSystem(projectElevation)
    setEpsgCode(projectEpsgCode)

    if (projectEpsgCode) {
      fetchSearchProjection(projectEpsgCode, data => {
        setProjectionList(data)
        if (data.length > 0) {
          setEpsgName(data[0].name)
        }
      });
    } else {
      setProjectionList([]);
    }
    if (!model.data.ext && model.type === 'unknown' && model.data.isIdenticalMatrix) {
      setIdenticalMatrix(true)
    }
    if (model.sourceType === 'external' && model.type === 'unknown') {
      setIdenticalMatrix(true)
    }
  }

  useEffect(() => {
    if (!modelStore.updateFromViewer) return
    let projectEpsgCode = projectStore.projectDetail?.tilesetData?.coordinateSystem?.code ? projectStore.projectDetail?.tilesetData?.coordinateSystem?.code : '4326'
    if (modelStore.xyzLocal) {
      if (isIFCEllipsoidRealWorldCoordinates(model)) {
        const fileorigocartographic = model?.crs?.initPos?.cartographic;
        if (!fileorigocartographic) return;
        if (model?.data?.saveMatrix.xyzLocal) {
          const xyzLocal = model?.data?.saveMatrix.xyzLocal || [];
          setX(xyzLocal[0] || 0)
          setY(xyzLocal[1] || 0)
          setZ(xyzLocal[2] || 0)
        } else {
          setX(0)
          setY(0)
          setZ(0)
        }
      } else {
        if ((['4326', '4756'].includes(projectEpsgCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
          setLatitude(modelStore.xyzLocal[0] || 0)
          setLongitude(modelStore.xyzLocal[1] || 0)
          setHeight(modelStore.xyzLocal[2] || 0)
        } else {
          if ((model.type === 'landxml') || ((model.crs.realWorldCoordinates && model.crs.realWorldCoordinates === 1) && !isIFCEllipsoid(model))) {
            let _car3 = getXYZDialog(
              Cartesian3.fromArray(projectStore.projectDetail.tilesetData.refLocalProject ? projectStore.projectDetail.tilesetData.refLocalProject : model.crs.LocalOrg),
              Cartesian3.fromArray(model.crs.LocalOrg),
              Cartesian3.fromArray(modelStore.xyzLocal)
            )
            setX(_car3.x)
            setY(_car3.y)
            setZ(_car3.z)
          } else {
            setX(modelStore.xyzLocal[0])
            setY(modelStore.xyzLocal[1])
            setZ(modelStore.xyzLocal[2])
          }
        }
      }
      modelStore.setUpdateFromViewer(false)
    }
  }, [modelStore.xyzLocal])

  /** Effect listen for change hpr */
  useEffect(() => {
    if (modelStore.hpr && modelStore.hpr.h <= 180) {
      setHeading(modelStore.hpr.h)
      setPitch(modelStore.hpr.p)
      setRoll(modelStore.hpr.r)
    }
  }, [modelStore.hpr.h, modelStore.hpr.p, modelStore.hpr.r])

  /** Effect listen for change scale */
  useEffect(() => {
    setScale(modelStore.scale)
  }, [modelStore.scale])

  //Event input value for hpr
  const debounceHPR = useRef(
    debounce((h, p, r) => {
      modelStore.setHpr(h, p, r)
    }, 50)
  );

  const goZoomToViewModel = async () => {
    setUpdateNumber(true)
    let result = true
    if (!model.data.ext && model.type === 'unknown' && model.data.isIdenticalMatrix && !model.data?.isI3sModel) {
      let car3xyzLocal = new Cartesian3()
      let vecDrag = new Cartesian3()

      if ((['4326', '4756'].includes(EpsgCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
        car3xyzLocal = new Cartesian3.fromDegrees(modelStore.xyzLocal[1], modelStore.xyzLocal[0], modelStore.xyzLocal[2])
        vecDrag = Cartesian3.fromDegrees(longitude, latitude, height);
      } else {
        car3xyzLocal = Cartesian3.fromArray(modelStore.xyzLocal)
        vecDrag = new Cartesian3.fromArray([parseFloat(X), parseFloat(Y), parseFloat(Z)]);
      }
      const _objResult = transformECFModel(modelStore.fileOrigo, car3xyzLocal, vecDrag);
      modelStore.setFileOrigo(false)
      modelStore.setFileOrigo(_objResult.fileOrigo)
      projectStore.setIsUpdateViewOrigo(true) // Effect fileOrigo change in MoveTile.js

      if ((['4326', '4756'].includes(EpsgCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
        var convertCatographic = Cartographic.fromCartesian(_objResult.xyzLocal, Ellipsoid.WGS84, convertCatographic);
        if (convertCatographic) {
          modelStore.setxyzLocal([convertCatographic.latitude * CesiumMath.DEGREES_PER_RADIAN, convertCatographic.longitude * CesiumMath.DEGREES_PER_RADIAN, convertCatographic.height])
        }
      } else {
        modelStore.setxyzLocal([_objResult.xyzLocal.x, _objResult.xyzLocal.y, _objResult.xyzLocal.z])
      }
    } else {
      if (model.sourceType === 'external' && model.type === 'unknown') {
        let cm = getCurrentModel(
          model._id,
          toJS(projectStore.modelList),
          projectStore.tileViews
        )
        if (originalMatrix && cm.tile && cm.tile._root) {
          cm.tile.root.transform[12] = originalMatrix[0]
          cm.tile.root.transform[13] = originalMatrix[1]
          cm.tile.root.transform[14] = originalMatrix[2]
        }
      } else if ((['4326', '4756'].includes(EpsgCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
        modelStore.setFileOrigo(false)
        modelStore.setFileOrigo(Cartesian3.fromDegrees(longitude, latitude, height))
        modelStore.setxyzLocal([latitude, longitude, height])
        projectStore.setIsUpdateViewOrigo(true) // Effect fileOrigo change in MoveTile.js
      } else {
        let xx = parseFloat(X)
        let yy = parseFloat(Y)
        let zz = parseFloat(Z)
        let vecInputFromDialog2 = new Cartesian3.fromArray([xx, yy, zz]);

        if (model.data && model.data.saveMatrix && model.data.saveMatrix.fileOrigo) {
          var refProject = projectStore.projectDetail.tilesetData.RefPoint && projectStore.projectDetail.tilesetData.RefPoint.length ? new Cartesian3.fromDegrees(projectStore.projectDetail.tilesetData.RefPoint[1], projectStore.projectDetail.tilesetData.RefPoint[0], projectStore.projectDetail.tilesetData.RefPoint[2]) : new Cartesian3()
          var refLocalProject = new Cartesian3.fromArray(projectStore.projectDetail.tilesetData.refLocalProject ? projectStore.projectDetail.tilesetData.refLocalProject : model.crs.LocalOrg)
          const coordinateSystemCode = (projectStore.projectDetail.tilesetData.coordinateSystem && projectStore.projectDetail.tilesetData.coordinateSystem.code) || projectStore.projectDetail.coordinateSystem

          var vecInput = new Cartesian3();
          vecInput.x = vecInputFromDialog2.x - model.crs.LocalOrg[0];
          vecInput.y = vecInputFromDialog2.y - model.crs.LocalOrg[1];
          vecInput.z = vecInputFromDialog2.z - model.crs.LocalOrg[2];

          if (model.type === 'landxml' || (model.crs.realWorldCoordinates && model.crs.realWorldCoordinates === 1) || (isCloudPointXDEnginePlane(model) && !projectStore.projectDetail.tilesetData.georeferenced) || model.type === 'e57') {
            vecInput = vecInputFromDialog2;

            let _XMLModelOnPlane = setXMLModelOnPlane(
              vecInput,
              refProject,
              refLocalProject,
              Cartesian3.fromArray(model.crs.LocalOrg),
              Cartesian3.fromArray(model.crs.ModelCenter),
              modelStore.hpr.h,
              modelStore.hpr.p,
              modelStore.hpr.r,
              modelStore.scale,
              true,
              projectStore.projectDetail.headingRotation ?? 0
            )
            modelStore.setFileOrigo(false)
            modelStore.setFileOrigo(_XMLModelOnPlane.fileOrigo)
            projectStore.setIsUpdateViewOrigo(true) // Effect fileOrigo change in MoveTile.js
            modelStore.setxyzLocal([
              _XMLModelOnPlane.xyzLocal.x,
              _XMLModelOnPlane.xyzLocal.y,
              _XMLModelOnPlane.xyzLocal.z
            ])
          } else {
            if (isCloudPoint(model)) {
              projectStore.setLoadingProgress(true)
              let _data = {
                initPos: [vecInputFromDialog2.x, vecInputFromDialog2.y, vecInputFromDialog2.z],
                epsg: coordinateSystemCode,
                elevationSystem: projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'
              }
              await projectStore.getProjectRefPointOnProjectionPlane(_data).then(async response => {
                if (response.Status === "OK") {
                  refProject = new Cartesian3.fromDegrees(response.GlobalPosition[1], response.GlobalPosition[0], response.GlobalPosition[2])
                  refLocalProject = vecInputFromDialog2
                  let _XMLModelOnPlane = setXMLModelOnPlane(
                    vecInput,
                    refProject,
                    refLocalProject,
                    Cartesian3.fromArray(model.crs.LocalOrg),
                    Cartesian3.fromArray(model.crs.ModelCenter),
                    modelStore.hpr.h,
                    modelStore.hpr.p,
                    modelStore.hpr.r,
                    modelStore.scale,
                    false,
                    projectStore.projectDetail.headingRotation ?? 0
                  )
                  modelStore.setFileOrigo(false)

                  modelStore.setFileOrigo(_XMLModelOnPlane.fileOrigo)
                  projectStore.setIsUpdateViewOrigo(true) // Effect fileOrigo change in MoveTile.js
                  if (!(_XMLModelOnPlane.xyzLocal.x === 0 && _XMLModelOnPlane.xyzLocal.y === 0 && _XMLModelOnPlane.xyzLocal.z === 0)) {
                    modelStore.setxyzLocal([
                      _XMLModelOnPlane.xyzLocal.x,
                      _XMLModelOnPlane.xyzLocal.y,
                      _XMLModelOnPlane.xyzLocal.z
                    ])
                  }
                  else {
                    modelStore.setxyzLocal([
                      refLocalProject.x,
                      refLocalProject.y,
                      refLocalProject.z
                    ])
                  }
                } else {
                  notification.open({
                    message: t('an-error-occurred'),
                    description: t('model-is-placed-outside'),
                    icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                  })
                  result = false;
                }
              }).catch(error => {
                console.log(error)
              }).finally(() => {
                projectStore.setLoadingProgress(false)
              })
            } else {
              if (projectStore.projectDetail.tilesetData.georeferenced) {
                let _XMLModelOnPlane = setXMLModelOnPlane(
                  vecInput,
                  refProject,
                  refLocalProject,
                  Cartesian3.fromArray(model.crs.LocalOrg),
                  Cartesian3.fromArray(model.crs.ModelCenter),
                  modelStore.hpr.h,
                  modelStore.hpr.p,
                  modelStore.hpr.r,
                  modelStore.scale,
                  ((model.type === 'landxml') || (model.crs.realWorldCoordinates && model.crs.realWorldCoordinates === 1)) ? true : false,
                  projectStore.projectDetail.headingRotation ?? 0
                )
                modelStore.setFileOrigo(false)
                modelStore.setFileOrigo(_XMLModelOnPlane.fileOrigo)
                projectStore.setIsUpdateViewOrigo(true) // Effect fileOrigo change in MoveTile.js
                modelStore.setxyzLocal([
                  _XMLModelOnPlane.xyzLocal.x + refLocalProject.x,
                  _XMLModelOnPlane.xyzLocal.y + refLocalProject.y,
                  _XMLModelOnPlane.xyzLocal.z + refLocalProject.z
                ])
              } else {
                if (!['kmz', 'geojson', 'geotiff'].includes(model.type)) {
                  // with ifc ko có tọa độ địa lý, hoặc mesh model khi kéo model vào thì điểm kéo vào đó được set cho project RefPoint
                  // Khi người dùng input xyz vào sẽ kiểm tra nếu project không có refLocalProject thì sẽ lấy data input xyz vào của user
                  // làm tọa độ thưc của model
                  // vi Project không có  georeferenced nen IFC, mesh model xu ly nhu la Mesh o 4326
                  projectStore.setLoadingProgress(true)
                  
                  if (!projectStore.projectDetail.tilesetData.refLocalProject) {
                    let _data = {
                      initPos: [vecInputFromDialog2.x, vecInputFromDialog2.y, vecInputFromDialog2.z],
                      epsg: coordinateSystemCode,
                      elevationSystem: projectStore.projectDetail.elevationSystem
                    }
                    await projectStore.getProjectRefPointOnProjectionPlane(_data).then(async response => {
                      if (response.Status === "OK") {
                        modelStore.setProjectRefPointOnProjectionPlane(response.GlobalPosition)

                        refProject = new Cartesian3.fromDegrees(response.GlobalPosition[1], response.GlobalPosition[0], response.GlobalPosition[2])
                        refLocalProject = vecInputFromDialog2
                      }
                    }).catch(error => {
                      console.log(error)
                    })
                  }

                  await projectStore.convertProjectPlaneToWGS84(vecInputFromDialog2.y, vecInputFromDialog2.x, vecInputFromDialog2.z, EpsgCode, elevationSystem).then(response => {
                    if (response.Status === "OK" && response.Point) {
                      modelStore.setFileOrigo(false)
                      let _fileorigo = Cartesian3.fromDegrees(response.Point[1], response.Point[0], response.Point[2])
                      modelStore.setFileOrigo(_fileorigo)
                      projectStore.setIsUpdateViewOrigo(true) // Effect fileOrigo change in MoveTile.js
                      modelStore.setxyzLocal([vecInputFromDialog2.x, vecInputFromDialog2.y, vecInputFromDialog2.z])
                    } else {
                      notification.open({
                        message: t('an-error-occurred'),
                        description: t('model-is-placed-outside'),
                        icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                      })
                      result = false;
                    }
                  }).catch(error => {
                    console.log("convertProjectPlaneToWGS84: " + error)
                  }).finally(() => {
                    projectStore.setLoadingProgress(false)
                  })
                }
              }
            }
          }
        }
      }
    }    
    setUpdateNumber(false)
    return result;
  }
  /**
   * Function Search Projection
   * @param {*} value 
   * @param {*} callback 
   */
  const fetchSearchProjection = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    const searchProjection = () => {
      projectStore.getSearchProjection(value)
        .then(() => {
          if (currentValue === value) {
            const  results = projectStore.projectionList;
            const data = [];
            if (typeof results !== 'undefined') {
              results.forEach(r => {
                data.push(r);
              });
            }
            callback(data);
          }
        });
    }
    timeout = setTimeout(searchProjection, 300);
  }

  /**
   * Function handle search projection
   * @param {*} value 
   */
  const handleSearch = value => {
    if (value) {
      fetchSearchProjection(value, data => {
        setProjectionList(data)
      });
    } else {
      setProjectionList([]);
    }
  }

  const handleChangeCrs = async (value) => {
    setEpsgCode(value)
    const pr = projectionList.filter(d => d.code === value);
    if (pr.length > 0) {
      try {
        setEpsgName(pr.name)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (projectionList.length > 0) {
      handleChangeCrs(EpsgCode)
    }
  }, [projectionList, EpsgCode])

  /**
   * Function handle Save form
   */
  const onSave = async () => {
    if (endString && startString) {
      if (moment(endString).isBefore(startString)) {
        alert(t('you-have-to-set-start-date-before-end-date'))
        return
      }
    }
    if (!model.crs || !model.data) return
    const startTime = Date.now();
    setLoading(true)

    // if (isXMLEllipsoid(model) || isIFCEllipsoid(model) || isCloudPointXDEngineEllipsoid(model) || isE57XDEngineEllipsoid(model)) {
    if (isXMLEllipsoid(model) || isIFCEllipsoid(model) || isE57XDEngineEllipsoid(model)) {
      let newData = {
        ...model,
        isRender: isRender,
      }
      newData.data.transparency = transparency
      newData.startDate = startDate
      newData.endDate = endDate
      let style = model.style
      if (model.type === 'cloudpoint' || model?.data?.is3DTilesPointcloud || model.type === 'e57') {
        style = {
          type: style3D
        }
      }
      newData.style = style 
      newData.isUpdate = true;
      if (isIFCEllipsoidRealWorldCoordinates(model)) {
        let xx = parseFloat(X)
        let yy = parseFloat(Y)
        let zz = parseFloat(Z)
        newData.data.saveMatrix.xyzLocal = [xx, yy, zz];
        newData.conversProjectionLocal = {
          x : xx,
          y : yy,
          z : zz
        };
        if(newData?.project?.id){
          newData.project = newData?.project?.id;
        }
        let _data = newData.data
        if (modelStore.hpr) _data.saveMatrix.hpr = modelStore.hpr
        projectStore.setLoadingProgress(true);
        return await projectStore.updateIFCEllipsoid(model._id,newData).then(async (res) => {
        if (res) {
          const listModelRebuild = [model._id]
          const fkeys = listModelRebuild.map(item => item + '-tile')
          listModelRebuild.map(item => {
            if (projectStore.modelFeatures?.[item])
              delete projectStore.modelFeatures[item]
          })
          const oldVisibleTilesets = toJS(projectStore.visibleTilesets).find(t => fkeys.includes(t.modelId + '-tile')) // track old state before rebuild
          projectStore.setStateBeforeRebuild(oldVisibleTilesets) // save them to store and call in attributePanel
  
          projectStore.setTileViews(projectStore.tileViews.filter(t => !fkeys.includes(t.key))) // remove models from viewer when rebuild
  
          const temp = [...projectStore.modelList.filter(t => !listModelRebuild.includes(t._id))]
          projectStore.setModelList(temp) // call useEffect projectStore.modelList in projectDetailPage reload TilesetByModel
  
          /**Update FE projectStore.ProjectDetail */
          await projectStore.updateProjectRefPoint(res.project)
          projectStore.setRebuildModel(true) //effect in project detail reload tileset
          setLoading(false)
          modelStore.setProjectRefPointOnProjectionPlane(false)
          // gotoUserViewpoint(projectStore.previousCameraPosition, usersStore.currentUser?._id, viewer)
          syncLastSessionSettings(projectStore.previous3DViewSetting)
          projectStore.setShowEditLocation(false, false)

          let name = form.getFieldValue('name')

          const newNode = {
            title: model.name, // name, 
            key: uuid(),
            type: 'FILE',
            modelId: model._id,
            sourceType: model.sourceType,
            modelType: model.type,
            useService: model.useService,
            hash: model.hash,
            src: model.src,
            startDate: startDate,
            endDate: endDate
          }
          if (projectStore.selectedNode) {
            await editTreeNode(newNode, projectStore.projectDetail.treeData);
          }
          projectStore.setDisplayPanel(true)
          projectStore.setChangeModelStyle()
          projectStore.setLoadingProgress(false)
          projectStore.setCloseEditModel(true) // Call effect projectStore.closeEditModel in component ProjectDetails.js
          modelStore.setUpdateFromViewer(false) // action effect input (X, Y, Z) disable when processing     
          modelStore.setFileOrigo(false) // action hide origo
          projectStore.setIsUpdateViewOrigo(false)
        } else {
          // message.error(t('something-went-wrong-when-update-data'))
          setLoading(false)
          commonStore.loggingFunction('Update model','failed',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
        }
        }).catch(err=>{
          setLoading(false)
          projectStore.setLoadingProgress(false)
        })
      } else {
        projectStore.setLoadingProgress(true);
        await projectStore.updateVersion(model._id, newData).then(() => {
          setLoading(false)
          projectStore.setReloadAlignment(true); // effect in project detail reload Alignment
          commonStore.loggingFunction('Edit data setting', 'success', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
        }).catch(err => {
          notification.open({
            message: t('an-error-occurred-when-saving-data'),
            description: t('something-went-wrong-when-update-data'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          })
          commonStore.loggingFunction('Edit data setting', 'failed', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
        }).finally(async () => {
          modelStore.setProjectRefPointOnProjectionPlane(false)
          // gotoUserViewpoint(projectStore.previousCameraPosition, usersStore.currentUser?._id, viewer)
          syncLastSessionSettings(projectStore.previous3DViewSetting)
          projectStore.setShowEditLocation(false, false)

          let name = form.getFieldValue('name')

          const newNode = {
            title: model.name, // name,
            key: uuid(),
            type: 'FILE',
            modelId: model._id,
            sourceType: model.sourceType,
            modelType: model.type,
            useService: model.useService,
            hash: model.hash,
            src: model.src,
            startDate: startDate,
            endDate: endDate
          }
          if (projectStore.selectedNode) {
            await editTreeNode(newNode, projectStore.projectDetail.treeData);
          }
          const index = projectStore.modelList.findIndex(x => x._id === model._id)
          const temp = [...projectStore.modelList]
          temp.map(item => {
            delete item.isUpdate
            return item
          })
          temp[index] = {
            ...model,
            ...newData
          };
          projectStore.setModelList(temp)
          projectStore.setDisplayPanel(true)
          projectStore.setChangeModelStyle()
          projectStore.setLoadingProgress(false)
          projectStore.setCloseEditModel(true) // Call effect projectStore.closeEditModel in component ProjectDetails.js
          modelStore.setUpdateFromViewer(false) // action effect input (X, Y, Z) disable when processing     
          modelStore.setFileOrigo(false) // action hide origo
          projectStore.setIsUpdateViewOrigo(false)
        })
      }
    } else {
      if (model?.type !== "cloudpoint" || (model?.type === "cloudpoint" && model?.data?.ifcSetting?.importer !== "ellipsoid")){
        const zoomResult = await goZoomToViewModel();
        if (zoomResult === false) {
          setLoading(false);
          return;
        }
      }

      let crs = model.crs
      let _data = model.data
      _data.saveMatrix = _data.saveMatrix;
      _data.transparency = transparency;
      if (modelStore.scale) _data.saveMatrix.scale = modelStore.scale
      if (modelStore.hpr) _data.saveMatrix.hpr = modelStore.hpr
      if (modelStore.fileOrigo) _data.saveMatrix.fileOrigo = modelStore.fileOrigo
      if ((['4326', '4756'].includes(EpsgCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree')) {
        _data.saveMatrix.xyzInput = [latitude, longitude, height]
      } else {
        _data.saveMatrix.xyzInput = [X, Y, Z]
      }
      if (modelStore.xyzLocal) _data.saveMatrix.xyzLocal = modelStore.xyzLocal


      if (model.sourceType === 'external' && model.type === 'unknown') {
        let cm = getCurrentModel(
          model._id,
          toJS(projectStore.modelList),
          projectStore.tileViews
        )
        var currentTranslation = new Cartesian3();
        let matrix4 = cm.tile && cm.tile._root && cm.tile._root.transform ? cm.tile.root.transform : new Matrix4()
        Matrix4.getTranslation(matrix4, currentTranslation);

      }
      let style = model.style

      let newData = {
        isRender: isRender,
      }
      newData.startDate = startDate
      newData.endDate = endDate

      if (model.type === 'cloudpoint' || model?.data?.is3DTilesPointcloud || model.type === 'e57') {
        style = {
          type: style3D
        }
      }
      crs.epsgCode = EpsgCode
      crs.epsgName = EpsgName
      newData.crs = toJS(crs)
      newData.data = toJS(_data)
      newData.style = style
      newData.isUpdate = true

      // update project: RefPoint, refLocalProject 
      // (case if edit ifc or mesh model project not georeferenced, refLocalProject thì sẽ lấy data input xyz vào của user
      // làm tọa độ thưc của model và cần update lại project: RefPoint, refLocalProject  )
      if (modelStore.projectRefPointOnProjectionPlane) {
        if (model.data?.saveMatrix?.xyzLocal && model.data.ext && !['.png', '.jpg', '.jpeg', '.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.txt'].includes(model.data.ext.toLowerCase()) && model.type !== 'landxmlBackground' && !isIFCEllipsoid(model) && !isCloudPointXDEngineEllipsoid(model) && !isE57XDEngineEllipsoid(model)) {
          newData.updateproject = {
            id: projectStore.projectDetail._id,
            RefPoint: modelStore.projectRefPointOnProjectionPlane,
            refLocalProject: modelStore.xyzLocal
          }
        }
      }

      const _getAllModelProject = projectStore.modelList.filter(x => !x.isDeleted);
      let countModelHasRefpoint = _getAllModelProject.reduce(function (r, a) {
        return r + +(a.type === 'landxml' || isIFCPlane(a) || a.type === 'cad' || a.type === 'model3d' || isCloudPointCesiumION(a) || isCloudPointXDEnginePlane(a) || isE57XDEnginePlane(a));
      }, 0);
      if (countModelHasRefpoint === 1) {
        const coordinateSystemCode = (projectStore.projectDetail.tilesetData.coordinateSystem && projectStore.projectDetail.tilesetData.coordinateSystem.code) || projectStore.projectDetail.coordinateSystem
        let vecInputFromDialog2 = new Cartesian3.fromArray([X, Y, Z]);
        let _data2 = {
          initPos: [vecInputFromDialog2.x, vecInputFromDialog2.y, vecInputFromDialog2.z],
          epsg: coordinateSystemCode,
          elevationSystem: projectStore.projectDetail.elevationSystem
        }
        await projectStore.getProjectRefPointOnProjectionPlane(_data2).then(async response => {
          if (response.Status === "OK") {
            modelStore.setProjectRefPointOnProjectionPlane(response.GlobalPosition)
            newData.updateproject = {
              id: projectStore.projectDetail._id,
              RefPoint: response.GlobalPosition,
              refLocalProject: modelStore.xyzLocal
            }
          }
        }).catch(error => {
          console.log(error)
        })
      }
      
      if (model.sourceType === 'external' && !model?.data?.cesiumToken) {
        setLoading(false)
        form.validateFields().then(async values => {
          setLoading(true)
          newData.project = projectStore?.projectDetail?._id;
          newData.name = values.name ? values.name.trim() : 'Unnamed';
          newData.data.detailLevel = values.detailLevel
          if (!model.data.isPhotorealistic3DTile) {
            newData.data.is3DTilesPointcloud = isPointcloud
          }

          if (!isUrl(values.src)) {
            newData.data.ionAssetId = parseInt(values.src)
          } else {
            if (values.src.includes('xd-visuals.com')) {
              newData.src = values.src.replace(/^https?\:\/\//i, '')
            } else if (values.src.includes('tile.googleapis.com')) {
              try {
                GoogleMaps.defaultApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
                GoogleMaps.mapTilesApiEndpoint = values.src.split('3dtiles/')[0];
                const googleTileset = await createGooglePhotorealistic3DTileset(process.env.REACT_APP_GOOGLE_API_KEY);
                newData.src = googleTileset.resource.url
              } catch (error) {
                setLoading(false)
                console.log(`Error loading Photorealistic 3D Tiles tileset.${error}`);
                notification.open({
                  message: `${t('error-google-3dtiles-maximum-load-amount')} ${t(error)}`,
                  icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                })
                return
              }
            } else if (Utils.getDomain(values.src, true) === window.location.host) {
              // case check if url same with domain (copy public json in edit model)
              let projectId = values.src.split('/')[3];
              let hash = values.src.split('/')[4];
              let modelname = values.src.split("/").pop();
              let jsonlink = await getOriginPublicJsonLink(projectStore, commonStore.token, projectId, hash, modelname)
              newData.src = jsonlink
            } else {
              newData.src = values.src
            }

            if (isUseCredential) {
              const { data } = await ProjectRequest.checkJSONLink({ url: newData.src, username: values.username, password: values.password })
              if (data && data.status === 401 || data.status === 403) {
                if (!model.data.credential) {
                  setLoading(false)
                  notification.open({
                    message: t('invalid-credentials'),
                    icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                    duration: 1.5,
                  })
                  return
                }
              } else {
                newData.data.credential = Buffer.from(values.username + ':' + values.password).toString('base64');
                newData.data.is3DTilesPointcloud = false
                let myJson = data.data;
                if (myJson.root && myJson.root.content) {
                  let key = Object.keys(myJson.root.content)[0];
                  let value = myJson.root.content[key];
                  if (value?.split) {
                    let ext = value.split('.').pop();
                    if (ext === 'pnts') {
                      newData.data.is3DTilesPointcloud = true
                    }
                  }
                }
              }
            } else {
              const { data } = await ProjectRequest.checkJSONLink({ url: newData.src })
              if (data.status === 200) {
                let myJson = data.data;
                if (myJson.root && myJson.root.content) {
                  let key = Object.keys(myJson.root.content)[0];
                  let value = myJson.root.content[key];
                  if (value?.split) {
                    let ext = value.split('.').pop();
                    if (ext === 'pnts') {
                      newData.data.is3DTilesPointcloud = true
                    }
                  }
                }
              }
            }
          }

          projectStore.setLoadingProgress(true)
          let _updateModel = projectStore.visibleTilesets.find(c => c.modelId === projectStore.selectedNode.modelId)        
          try {
            const model = await projectStore.updateVersion(projectStore.selectedNode.modelId, newData);

            let name = form.getFieldValue('name')

            const newNode = {
              title: model.name, // name,
              key: uuid(),
              type: 'FILE',
              modelId: model._id,
              sourceType: model.sourceType,
              modelType: model.type,
              useService: model.useService,
              hash: model.hash,
              src: model.src,
              isDeleted: false,
              endDate: model.endDate,
              startDate: model.startDate
            }
            await editTreeNode(newNode, projectStore.projectDetail.treeData);

            projectStore.setCurrentModelId(false)

            model.ref = false;
            model.isUpdate = true;
            model.isVisible = _updateModel.isVisible

            const index = projectStore.modelList.findIndex(x => x._id === model._id)
            const temp = [...projectStore.modelList]
            temp.map(item => {
              delete item.isUpdate
              return item
            })
            temp[index] = model;

            projectStore.setModelList(temp)
            projectStore.setNewModelId(model._id)
            projectStore.setSelectedModel(projectStore.modelList[projectStore.modelList.length - 1])

            message.success(t('tileset-uploaded-successfully'))
            projectStore.setShowEditLocation(false, false)
            projectStore.setDisplayPanel(true)
            projectStore.setChangeModelStyle()
            projectStore.setLoadingProgress(false)
            syncLastSessionSettings(projectStore.previous3DViewSetting)
            commonStore.loggingFunction('Edit data setting', 'success', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
          } catch (err) {
            projectStore.setLoadingProgress(false);
            notification.open({
              message: t('an-error-occurred-when-creating-model-data') + '(' + err?.data?.error + ')',
              description: t('something-went-wrong-when-creating-model'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
              duration: 0,
            });

            commonStore.loggingFunction('Edit data setting', 'failed', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name);
          } 

          // update location
          projectStore.setLoadingProgress(true)
          await projectStore.updateLocation(model._id, newData).then(() => {
            setLoading(false)
            projectStore.setReloadAlignment(true); // effect in project detail reload Alignment
          }).catch(err => {
            projectStore.setLoadingProgress(false)
            notification.open({
              message: t('an-error-occurred-when-saving-data'),
              description: t('something-went-wrong-when-update-data'),
              icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
            })
          })
        })
      } else {
        if (model.sourceType === 'external' && model?.data?.cesiumToken) {
          let _values = await form.validateFields().catch((err) => {
            console.log('err', err);
            setLoading(false)
          });
          if (!_values) return;
          newData.data.ionAssetId = parseInt(_values.ionAssetId)
          newData.data.cesiumToken = _values.cesiumToken
          newData.data.detailLevel = _values.detailLevel
        } else if (model.sourceType === 'local' && model.type === 'geojson') {
          let _values = await form.validateFields().catch((err) => {
            console.log('err', err);
            setLoading(false)
          });
          if (!_values) return;
          newData.data.clampToGround = _values.clampToGround;
          newData.data.style = {
            point: {
              isShowPointAs: _values.isShowPointAs, //Billboard; Point
              color: _values.pointColor,
              alpha: _values.pointAlpha,
              pixelSize: _values.pointPixelSize,
              outlineColor: _values.pointOutlineColor,
              outlineWidth: _values.pointOutlineWidth ?? 0,
            },
            line: {
              width: _values.lineWidth ?? 1,
              color: _values.lineMaterial,
              alpha: _values.lineAlpha,
            },
            polygon: {
              color: _values.polygonMaterial,
              alpha: _values.polygonAlpha,
              height: _values.polygonHeight,
              outlineColor: _values.polygonOutlineColor,
              extrudedHeight: _values.polygonExtrudedHeight,
            }
          }
        } else if (model.sourceType === 'local' && model.type === 'geotiff') {
          let _values = await form.validateFields().catch((err) => {
            console.log('err', err);
            setLoading(false)
          });
          if (!_values) return;
          newData.data.alpha = transparency;
          model.data.transparency = transparency;
          newData.data.classificationType = _values.classificationType;
        }

        projectStore.setLoadingProgress(true)
        await projectStore.updateLocation(model._id, newData, true).then(() => {
          setLoading(false)
          model.crs = crs
          model.data = _data
          model.style = style
          projectStore.setReloadAlignment(true); // effect in project detail reload Alignment
          commonStore.loggingFunction('Edit data setting', 'success', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
        }).catch(err => {
          notification.open({
            message: t('an-error-occurred-when-saving-data'),
            description: t('something-went-wrong-when-update-data'),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          })
          commonStore.loggingFunction('Edit data setting', 'failed', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
        }).finally(async () => {
          modelStore.setProjectRefPointOnProjectionPlane(false)
          // modelStore.setProjectRefPointOnProjectionPlane(false);
          // gotoUserViewpoint(projectStore.previousCameraPosition, usersStore.currentUser?._id, viewer)
          syncLastSessionSettings(projectStore.previous3DViewSetting)
          projectStore.setShowEditLocation(false, false)

          let name = form.getFieldValue('name')

          const newNode = {
            title: model.name, // name,
            key: uuid(),
            type: 'FILE',
            modelId: model._id,
            sourceType: model.sourceType,
            modelType: model.type,
            useService: model.useService,
            hash: model.hash,
            src: model.src,
            startDate: startDate,
            endDate: endDate
          }
          if (projectStore.selectedNode) {
            await editTreeNode(newNode, projectStore.projectDetail.treeData);
          }
          // projectStore.projectDetail.tilesetData.RefPoint = model?.crs?.RefPoint;
          projectStore.setDisplayPanel(true)
          projectStore.setChangeModelStyle()
          projectStore.setLoadingProgress(false)
          projectStore.setCloseEditModel(true) // Call effect projectStore.closeEditModel in component ProjectDetails.js
          modelStore.setUpdateFromViewer(false) // action effect input (X, Y, Z) disable when processing     
          modelStore.setFileOrigo(false) // action hide origo
          projectStore.setIsUpdateViewOrigo(false)
        })
      }
    }
  }

  const editTreeNode = async (child, data) => {
    let toNode;
    if (projectStore.selectedNode.parentKey) {
      const parrentNode = TreeUtils.searchTreeNode(data, 'key', projectStore.selectedNode.parentKey);
      toNode = parrentNode.children;
    } else {
      toNode = data;
    }
    // need for update treedata
    const node = toNode.find(child => child.key === projectStore.selectedNode.key);
    if (node) {
      node.title = child.title;
      node.key = child.key;
      node.type = child.type;
      node.modelId = child.modelId;
      node.sourceType = child.sourceType;
      node.modelType = child.modelType;
      node.useService = child.useService;
      node.hash = child.hash;
      node.endDate = child.endDate;
      node.startDate = child.startDate;
    }

    projectStore.setLoadingProgress(true);
    await projectStore.updateProjectTreeData({ treeData: data, store: 'treedata' }).then((res) => {
      projectStore.projectDetail.treeData = res.treeData;
    }).catch(err => {
      console.log(err)
      message.error(t('edit-file-failed'))
    }).finally(() => {
      projectStore.setLoadingProgress(false);
    })
  }


  /**
   * Function handle reset form edit model
   */
  const onReset = async () => {
    if (!model) return;
    setResetState({ loading: true, isDisabled: true }) // disable button reset
    await projectStore.getModel3DSById(model._id).then(async response => {
      if (model?.name) {
        form.setFieldsValue({ name: model.name });
      }
      model = response
      const foundIndex = projectStore.modelList.findIndex(x => x._id === response?._id)
      const temp = [...projectStore.modelList]
      temp[foundIndex] = response
      projectStore.setModelList(temp)
      let cm = getCurrentModel(
        model._id,
        toJS(projectStore.modelList),
        projectStore.tileViews
      )
      if (model.sourceType === 'external' && model.type === 'unknown') {
        if (model.data.isIdenticalMatrix) {
          modelStore.resetFromModel(response.data.saveMatrix)
        } else {
          var currentTranslation = new Cartesian3();
          let matrix4 = cm.tile && cm.tile._root && cm.tile._root.transform ? cm.tile.root.transform : new Matrix4()
          // Matrix4.getTranslation(matrix4, currentTranslation);
          // modelStore.setxyzLocal([currentTranslation.x, currentTranslation.y, currentTranslation.z])
          // modelStore.setFileOrigo(currentTranslation)
          // modelStore.setScale(model.data?.saveMatrix?.scale)
          // modelStore.setHpr(model.data?.saveMatrix?.hpr.h, model.data?.saveMatrix?.hpr.p, model.data?.saveMatrix?.hpr.r)
        }
        modelStore.setUpdateFromViewer(true)
      } else if (model.sourceType === 'local' && model.type === 'geojson') {
        form.setFieldsValue({
          clampToGround: model.data?.clampToGround
        });
        if (model.data.style && model.data.style.point) {
          form.setFieldsValue({
            isShowPointAs: model.data.style.point.isShowPointAs ? model.data.style.point.isShowPointAs : 'Billboard', //Billboard; Point
            pointColor: model.data.style.point.color ? model.data.style.point.color : '#ffffff',
            pointAlpha: model.data.style.point.alpha,
            pointPixelSize: model.data.style.point.pixelSize ? model.data.style.point.pixelSize : '',
            pointOutlineColor: model.data.style.point.outlineColor ? model.data.style.point.outlineColor : '#000000',
            pointOutlineWidth: model.data.style.point.outlineWidth ? model.data.style.point.outlineWidth : 0,
          })
        }
        if (model.data.style && model.data.style.line) {
          form.setFieldsValue({
            lineWidth: model.data.style.line.width ? model.data.style.line.width : 1,
            lineMaterial: model.data.style.line.color ? model.data.style.line.color : '#ffffff',
            lineAlpha: model.data.style.line.alpha
          })
        }
        if (model.data.style && model.data.style.polygon) {
          form.setFieldsValue({
            polygonMaterial: model.data.style.polygon.color ? model.data.style.polygon.color : '#ffffff',
            polygonAlpha: model.data.style.polygon.alpha,
            polygonHeight: model.data.style.polygon.height ? model.data.style.polygon.height : '',
            polygonOutlineColor: model.data.style.polygon.outlineColor ? model.data.style.polygon.outlineColor : '#000000',
            polygonExtrudedHeight: model.data.style.polygon.extrudedHeight ? model.data.style.polygon.extrudedHeight : ''
          })
        }
      } else {
        if (response.data?.saveMatrix) {
          modelStore.resetFromModel(response.data.saveMatrix)
        } else {
          modelStore.resetFromModel({ hpr: { h: 0, p: 0, r: 0 }, scale: 1, fileOrigo: false })
        }
      }
      projectStore.setIsUpdateViewOrigo(true)

      loadModelDetail(response)
      if (response && response.sourceType === 'external' && !response?.data?.cesiumToken) {
        form.setFieldsValue({ name: response.name });
        form.setFieldsValue({ src: `${response.src}` });
        if (model.src && model.src.includes("tile.googleapis.com")) {
          form.setFieldsValue({ src: `${model.src.split('?')[0]}` });
        }
        form.setFieldsValue({ detailLevel: response.data?.detailLevel || 16 });
        set3DtileIsPointcloud(response.data.is3DTilesPointcloud ? response.data.is3DTilesPointcloud : false)
      }
    })
    setResetState({ loading: false, isDisabled: false }) //enable button reset
    setValidJson(true)
  }

  function onChangeStart(date, dateString) {
    setStartDate(date)
    setStartString(dateString)
  }

  const handleChange = (target, value) => {
    setIsRender(value)
  }

  function onChangeEnd(date, dateString) {
    setEndDate(date)
    setEndString(dateString)
  }

  const publicJsonURL = () => {
    const ASSET_URL = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${model.hash}/external_tileset.json`
    return ASSET_URL
  }
  function onChangestyle(value, selectedOption) {
    setStyle3D(value)
  }

  const HeaderPanel = ({ text }) => (
    <LocationPanelHeader>{text}</LocationPanelHeader>
  )
  const options = projectionList.map(d => <Option key={d.code}>{'EPSG:' + d.name}</Option>);

  /**
         * Save new project custom attribute
         * @param {*} data
         */
  const handleAddAttribute = (data) => {
    projectStore.setLoadingProgress(true)
    projectStore.createModelCustomAttributes(data).then(res => {
      sketchingStore.setObjectCustomAttributes([...sketchingStore.objectCustomAttributes, res])
      projectStore.setLoadingProgress(false)
      message.success(t('add-attribute-successfully'))
      sketchingStore.setVisibleModalObjectAtt(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  /**
  * Update project custom attribute
  * @param {*} attribute id, data
  */
  const handleUpdateAttribute = (id, data) => {
    projectStore.setLoadingProgress(true)
    projectStore.updateModelCustomAttributes(id, data).then(res => {
      projectStore.setLoadingProgress(false)
      message.success(t('update-attribute-successfully'))
      let index = sketchingStore.objectCustomAttributes.findIndex(c => c._id === res._id)
      if (index !== -1) {
        let _objectCustomAttributes = [...sketchingStore.objectCustomAttributes]
        _objectCustomAttributes[index] = res
        sketchingStore.setObjectCustomAttributes(_objectCustomAttributes)
      }
      sketchingStore.setVisibleModalObjectAtt(false)
      sketchingStore.setCurrentObjectAttribute(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  /**
  * Delete project custom attribute
  * @param {*} attribute id 
  */
  const handleDeleteAttribute = (id) => {
    projectStore.setLoadingProgress(true)
    projectStore.deleteModelCustomAttributes(id).then(res => {
      projectStore.setLoadingProgress(false)
      message.success(t('delete-attribute-successfully'))
      let index = sketchingStore.objectCustomAttributes.findIndex(c => c.id === res.id)
      if (index !== -1) {
        let _objectCustomAttributes = [...sketchingStore.objectCustomAttributes]
        _objectCustomAttributes.splice(index, 1)
        sketchingStore.setObjectCustomAttributes(_objectCustomAttributes)
      }
      sketchingStore.setVisibleModalObjectAtt(false)
      sketchingStore.setCurrentObjectAttribute(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  /**
* Update model attribute table
* @param {*} projectId,payload, customAttributeDatas
*/
  const updateModelAttributes = (data) => {
    projectStore.setLoadingProgress(true)
    projectStore.updateModelAttributes(data).then(res => {
      sketchingStore.setObjectCustomAttributes(res)
      projectStore.setLoadingProgress(false)
      message.success(t('update-attribute-successfully'))
      sketchingStore.setIsEditingModelAttribute(false)
    })
      .catch(err => {
        projectStore.setLoadingProgress(false)
        message.error(t('has-an-error-please-try-again'))
      })
  }

  //
  function roundNumber(value) {
    if (!isNaN(value)) {
      return Math.round(value)
    }
  }

  const loadingIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />

  const validJsonLink = async (rule, value) => {
    if (!value) {
      setUseCredentialization(false)
      form.resetFields(["username", "password"]);
      return Promise.resolve();
    }
    if (!isUrl(value)) { //case ionAssetId
      setUseCredentialization(false)
      form.resetFields(["username", "password"]);
      return Promise.reject(t('invalid-url'));
    }
    if (value.includes("tile.googleapis.com")) {
      setUseCredentialization(false)
      form.resetFields(["username", "password"]);
      return Promise.resolve();
    } else {
      const { data } = await ProjectRequest.checkJSONLink({ url: value })
      if (data && data.status === 200) {
        setUseCredentialization(false)
        form.resetFields(["username", "password"]);
        return Promise.resolve();
      } else {
        if (data && (data.status === 401 || data.status === 403)) {
          setUseCredentialization(true)
          return Promise.resolve();
        } else {
          setUseCredentialization(false)
          form.resetFields(["username", "password"]);
          return Promise.resolve();
        }
      }
    }
  };

  return (
    <>
      <EditPosLeftPanel id="editModelPanel" visible={visible}>
        {projectStore.currentUserRoleInProject &&
          (projectStore.currentUserRoleInProject === 'project_owner' ||
            projectStore.currentUserRoleInProject === 'project_manager') && (
            <Button
              loading={loading}
              type="primary"
              style={{ marginBottom: 10 }}
              icon={<SaveOutlined />}
              disabled={
                (model &&
                model.sourceType === 'external' &&
                !model?.data?.cesiumToken &&
                !validJson
                  ? 1
                  : 0) || !EpsgCode
              }
              onClick={onSave}>
              {t('commons.save')}
            </Button>
          )}
        <Tooltip
          title={t('commons.refresh')}
          overlayStyle={isMobile || isTablet ? { display: 'none' } : undefined}>
          <Button
            loading={resetState.loading}
            disabled={resetState.isDisabled}
            type="default"
            style={{ marginBottom: 10, marginLeft: 5 }}
            shape="circle"
            onClick={onReset}
            icon={<ReloadOutlined />}></Button>
        </Tooltip>
        <Tooltip
          title={t('commons.help')}
          overlayStyle={isMobile || isTablet ? { display: 'none' } : undefined}>
          <Button
            style={{
              marginBottom: 10,
              float: 'right',
              width: 'auto',
              border: 'none',
              right: '5px',
            }}
            icon={
              <HelpButton helppage={'edit_model'} hovered={'black'} />
            }></Button>
        </Tooltip>
        <Popconfirm
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onConfirm={async () => {
            setLoadingCloseDrawerEditModel(true)
            //Call update again model because the model has been changed
            await projectStore
              .getModel3DSById(model._id)
              .then(async response => {
                model = response
                const foundIndex = projectStore.modelList.findIndex(
                  x => x._id === response?._id
                )
                const temp = [...projectStore.modelList]
                temp[foundIndex] = response
                projectStore.setModelList(temp)
                if (response.data?.saveMatrix) {
                  modelStore.resetFromModel(response.data.saveMatrix)
                } else {
                  modelStore.resetFromModel({
                    hpr: { h: 0, p: 0, r: 0 },
                    scale: 1,
                    fileOrigo: false,
                  })
                }
                setLoadingCloseDrawerEditModel(false)
              })
            // gotoUserViewpoint(projectStore.previousCameraPosition, usersStore.currentUser?._id, viewer)
            syncLastSessionSettings(projectStore.previous3DViewSetting) //Go to viewpoint before open form edit model
            projectStore.setCloseEditModel(true) // Call effect projectStore.closeEditModel in component ProjectDetails.js
            modelStore.setUpdateFromViewer(false) // action effect input (X, Y, Z) disable when processing
            modelStore.setFileOrigo(false) // action hide origo
            projectStore.setIsUpdateViewOrigo(false)
            projectStore.setShowEditLocation(false, false)
            projectStore.setDisplayPanel(true) //show again drawer List all tilesets
            setStartDate(null)
            setEndDate(null)
          }}
          placement="bottomLeft"
          okButtonProps={{ danger: true }}
          okText={t('commons.ok')}
          cancelText={t('commons.cancel')}
          title={
            <span>
              <Trans
                i18nKey="are-you-sure-you-want-to-leave"
                components={[<br />]}
              />
            </span>
          }>
          <Tooltip
            title={t('commons.exit')}
            overlayStyle={
              isMobile || isTablet ? { display: 'none' } : undefined
            }>
            <Button
              loading={loadingCloseDrawerEditModel}
              type="default"
              style={{ marginBottom: 10, marginLeft: 5 }}
              shape="circle"
              icon={<CloseOutlined />}></Button>
          </Tooltip>
        </Popconfirm>
        <Collapse
          defaultActiveKey={
            ['geotiff', 'geojson'].includes(model.type)
              ? ['data-source', '5', '6']
              : [
                  '1',
                  '2',
                  '4',
                  '5',
                  '6',
                  '7',
                  'custom-attribute',
                  'data-source',
                ]
          }
          expandIconPosition={'right'}>
          {model &&
          model.sourceType === 'external' &&
          !model?.data?.cesiumToken ? (
            <Panel
              header={<HeaderPanel text={t('data-source')} />}
              key="data-source">
              <Form
                labelCol={{ span: 6 }}
                layout="horizontal"
                labelAlign="left"
                form={form}
                wrapperCol={{ span: 18 }}>
                <Form.Item
                  label={
                    <span>
                      {t('name')}{' '}
                      {resetState.loading && <Spin indicator={loadingIcon} />}
                    </span>
                  }
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('please-input-name'),
                    },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      Link{' '}
                      {resetState.loading && <Spin indicator={loadingIcon} />}
                    </span>
                  }
                  name="src"
                  rules={[
                    {
                      required: true,
                      message: t('please-input-external-link'),
                    },
                    { validator: validJsonLink },
                  ]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('username')}
                  name="username"
                  autoComplete="off"
                  rules={[
                    {
                      required: isUseCredential,
                      message: t('please-input-your-username'),
                    },
                  ]}>
                  <Input.Password
                    autoComplete="username"
                    visibilityToggle={false}
                    placeholder={t('username')}
                  />
                </Form.Item>
                <Form.Item
                  label={t('password')}
                  name="password"
                  autoComplete="off"
                  rules={[
                    {
                      required: isUseCredential,
                      message: t('please-input-your-password'),
                    },
                  ]}>
                  <Input.Password
                    autoComplete="new-password"
                    visibilityToggle={false}
                    placeholder={t('password')}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <Typography.Text
                      ellipsis={{ tooltip: t('detail-level-multiplier') }}>
                      {t('detail-level-multiplier')}
                    </Typography.Text>
                  }
                  name="detailLevel">
                  <InputNumber
                    min={0.025}
                    max={128}
                    step={0.025}
                    placeholder={t('detail-level-multiplier')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Form>
            </Panel>
          ) : (
            <Panel
              header={<HeaderPanel text={t('data-source')} />}
              key="data-source">
              <Form
                labelCol={{ span: 6 }}
                layout="horizontal"
                labelAlign="left"
                form={form}
                wrapperCol={{ span: 18 }}>
                <Form.Item
                  label={
                    <span>
                      {t('name')}{' '}
                      {resetState.loading && <Spin indicator={loadingIcon} />}
                    </span>
                  }
                  name="name"
                  
                  rules={[
                    {
                      required: true,
                      message: t('please-input-name'),
                    },
                  ]}>
                  <Input readOnly/>
                </Form.Item>
              </Form>
            </Panel>
          )}
          {(model.type === 'cloudpoint' ||
            model.data.is3DTilesPointcloud ||
            model.type === 'e57') && (
            <Panel header={<HeaderPanel text={t('coloring')} />} key="7">
              <>
                <Row>
                  <Col span={6} style={{ paddingTop: 7 }}>
                    {t('type')}
                  </Col>
                  <Col span={18}>
                    <Select
                      style={{ width: '100%' }}
                      placeholder={t('select-style')}
                      defaultValue={
                        model.style && model?.style?.type
                          ? model.style.type
                          : pointCloudStyleType.RGB
                      }
                      onChange={onChangestyle}>
                      <Option value={pointCloudStyleType.RGB}>
                        {pointCloudStyleType.RGB}
                      </Option>
                      <Option value={pointCloudStyleType.Classification}>
                        {pointCloudStyleType.Classification}
                      </Option>
                      <Option value={pointCloudStyleType.Grey}>
                        {pointCloudStyleType.Grey}
                      </Option>
                    </Select>
                  </Col>
                </Row>
              </>
            </Panel>
          )}
          {model &&
            model.sourceType === 'external' &&
            model?.data?.cesiumToken && (
              <Panel
                header={<HeaderPanel text={t('data-source')} />}
                key="data-source">
                <Form
                  labelCol={{ span: 10 }}
                  layout="horizontal"
                  labelAlign="left"
                  form={form}
                  wrapperCol={{ span: 14 }}>
                  <Form.Item
                    label={t('cesium-ion-asset-id')}
                    name="ionAssetId"
                    rules={[
                      {
                        required: true,
                        message: t('please-input-ion-asset-id'),
                      },
                    ]}>
                    <Input.Password
                      visibilityToggle={false}
                      placeholder={t('cesium-ion-asset-id')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('cesium-ion-access-token')}
                    name="cesiumToken"
                    rules={[
                      {
                        required: true,
                        message: t('please-input-ion-access-token'),
                      },
                    ]}>
                    <Input.Password
                      visibilityToggle={false}
                      placeholder={t('cesium-ion-access-token')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('detail-level-multiplier')}
                    name="detailLevel">
                    <InputNumber
                      min={0.025}
                      max={128}
                      step={0.025}
                      placeholder={t('detail-level-multiplier')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Form>
              </Panel>
            )}
          {model &&
            model.sourceType === 'local' &&
            model.type === 'geojson' && (
              <Panel
                header={<HeaderPanel text={t('Vector GIS')} />}
                key="data-source">
                <Form layout="vertical" form={form}>
                  <Form.Item
                    label={t('clamp-to-ground')}
                    name="clampToGround"
                    valuePropName="checked"
                    className="form-horizontal">
                    <Switch
                      checkedChildren={t('commons.on')}
                      unCheckedChildren={t('commons.off')}
                      style={{ marginLeft: '5px' }}
                    />
                  </Form.Item>
                  <Divider orientation="left" orientationMargin="0">
                    {t('point-style')}
                  </Divider>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={24}>
                        <Form.Item
                          label={t('point-style')}
                          className="form-horizontal"
                          name="isShowPointAs"
                          initialValue={'Billboard'}>
                          <Select placeholder={t('select-filter')}>
                            <Option value="Billboard">
                              {t('shows-points-as-billboards')}
                            </Option>
                            <Option value="Point">
                              {t('shows-points-as-point-graphics')}
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label={t('color')}
                          className="form-horizontal"
                          name="pointColor"
                          initialValue={'#ffffff'}>
                          <input type="color" className="custom-input-color" />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label={t('outline-color')}
                          className="form-horizontal"
                          initialValue={'#ffffff'}
                          name="pointOutlineColor">
                          <input type="color" className="custom-input-color" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={24}>
                        <Form.Item label={t('transparency')} name="pointAlpha">
                          <Slider
                            style={{ marginRight: '7px' }}
                            min={0}
                            max={1}
                            step={0.1}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label={`${t('size')} (px)`}
                          className="form-horizontal"
                          name="pointPixelSize">
                          <InputNumber min={1} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label={t('outline-width')}
                          className="form-horizontal"
                          name="pointOutlineWidth">
                          <InputNumber min={0} step={1} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Divider orientation="left" orientationMargin="0">
                    {t('line-style')}
                  </Divider>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={24}>
                        <Form.Item
                          label={t('color')}
                          className="form-horizontal"
                          initialValue={'#ffffff'}
                          name="lineMaterial">
                          <input type="color" className="custom-input-color" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={24}>
                        <Form.Item label={t('transparency')} name="lineAlpha">
                          <Slider
                            style={{ marginRight: '7px' }}
                            min={0}
                            max={1}
                            step={0.1}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={24}>
                        <Form.Item
                          label={`${t('width')} (px)`}
                          className="form-horizontal"
                          name="lineWidth">
                          <InputNumber min={1} step={1} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Divider orientation="left" orientationMargin="0">
                    {t('polygon-style')}
                  </Divider>
                  <Form.Item>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label={t('color')}
                          className="form-horizontal"
                          initialValue={'#ffffff'}
                          name="polygonMaterial">
                          <input type="color" className="custom-input-color" />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label={t('outline-color')}
                          className="form-horizontal"
                          initialValue={'#ffffff'}
                          name="polygonOutlineColor">
                          <input type="color" className="custom-input-color" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={24}>
                        <Form.Item
                          label={t('transparency')}
                          name="polygonAlpha">
                          <Slider
                            style={{ marginRight: '7px' }}
                            min={0}
                            max={1}
                            step={0.1}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label={t('elevation')}
                          className="form-horizontal"
                          name="polygonHeight">
                          <InputNumber min={0} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Form.Item
                          label={t('extrusion')}
                          className="form-horizontal"
                          name="polygonExtrudedHeight">
                          <InputNumber min={0} step={1} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </Panel>
            )}
          {model &&
            model.sourceType === 'local' &&
            model.type === 'geotiff' && (
              <Panel
                header={<HeaderPanel text={t('Raster imagery')} />}
                key="data-source">
                <Form layout="vertical" form={form}>
                  <Form.Item
                    label={t('presentation-style')}
                    name="classificationType">
                    <Select allowClear placeholder={t('select-a-option')}>
                      <Option key="TERRAIN" value={'TERRAIN'}>
                        {t('background-map')}
                      </Option>
                      <Option key="CESIUM_3D_TILE" value={'CESIUM_3D_TILE'}>
                        {t('drape-to-models')}
                      </Option>
                      <Option key="BOTH" value={'BOTH'}>
                        {t('background-and-models')}
                      </Option>
                      <Option key="PLANE" value={'PLANE'}>
                        {t('plane-with-elevation')}
                      </Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Panel>
            )}
          <Panel
            className={`${
              ['kmz', 'geotiff', 'geojson'].includes(model.type) ||
              isXMLEllipsoid(model) ||
              isIFCEllipsoid(model) ||
              isCloudPointXDEngineEllipsoid(model) ||
              isE57XDEngineEllipsoid(model)
                ? 'd-none'
                : ''
            } ${model.sourceType === 'external' ? 'd-none' : ''}`}
            header={<HeaderPanel text={t('edit-position')} />}
            key="1">
            {['4326', '4756'].includes(EpsgCode) ||
            projectStore.projectDetail.tilesetData.coordinateSystem.unit ===
              'degree' ? (
              <>
                <Row>
                  <Col span={6}>{t('longitude')}</Col>
                  <Col span={18}>
                    <Spin spinning={updateNumber > 0} indicator={antIcon}>
                      <InputNumber
                        ref={el => (inputRef.current[0] = el)}
                        min={-180}
                        max={180}
                        step={0.00001}
                        style={{ width: '100%' }}
                        onChange={value => {
                          if (
                            typeof value === 'number' &&
                            parseFloat(longitude) !== parseFloat(value)
                          ) {
                            setLongitude(value)
                          }
                        }}
                        value={longitude ? longitude : 0}
                      />
                    </Spin>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 8 }}>
                  <Col span={6}>{t('latitude')}</Col>
                  <Col span={18}>
                    <Spin spinning={updateNumber > 0} indicator={antIcon}>
                      <InputNumber
                        ref={el => (inputRef.current[1] = el)}
                        min={-90}
                        max={90}
                        style={{ width: '100%' }}
                        step={0.00001}
                        onChange={value => {
                          if (
                            typeof value === 'number' &&
                            parseFloat(latitude) !== parseFloat(value)
                          ) {
                            setLatitude(value)
                          }
                        }}
                        value={latitude ? latitude : 0}
                      />
                    </Spin>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 8 }}>
                  <Col span={6}>{t('height')}</Col>
                  <Col span={18}>
                    <Spin spinning={updateNumber > 0} indicator={antIcon}>
                      <InputNumber
                        ref={el => (inputRef.current[2] = el)}
                        style={{ width: '100%' }}
                        step={0.1}
                        autoFocus={true}
                        onChange={value => {
                          if (
                            typeof value === 'number' &&
                            parseFloat(height) !== parseFloat(value)
                          ) {
                            setHeight(value)
                          }
                        }}
                        value={height ? height : 0}
                      />
                    </Spin>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col span={6}>X</Col>
                  <Col span={18}>
                    <Spin spinning={updateNumber > 0} indicator={antIcon}>
                      <InputNumber
                        ref={el => (inputRef.current[0] = el)}
                        step={1}
                        style={{ width: '100%' }}
                        onChange={value => {
                          if (
                            typeof value === 'number' &&
                            parseFloat(X) !== parseFloat(value)
                          ) {
                            //check input change value
                            setX(value)
                            //for case external tileset
                            if (
                              model.sourceType === 'external' &&
                              model.type === 'unknown'
                            ) {
                              const updatedOriginalMatrix = [...originalMatrix]
                              updatedOriginalMatrix[0] = value
                              setOriginalMatrix(updatedOriginalMatrix)
                            }
                          }
                        }}
                        value={X ? X : 0}
                      />
                    </Spin>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 8 }}>
                  <Col span={6}>Y</Col>
                  <Col span={18}>
                    <Spin spinning={updateNumber > 0} indicator={antIcon}>
                      <InputNumber
                        ref={el => (inputRef.current[1] = el)}
                        style={{ width: '100%' }}
                        step={1}
                        onChange={value => {
                          if (
                            typeof value === 'number' &&
                            parseFloat(Y) !== parseFloat(value)
                          ) {
                            //check input change value
                            setY(value)
                            //for case external tileset
                            if (
                              model.sourceType === 'external' &&
                              model.type === 'unknown'
                            ) {
                              const updatedOriginalMatrix = [...originalMatrix]
                              updatedOriginalMatrix[1] = value
                              setOriginalMatrix(updatedOriginalMatrix)
                            }
                          }
                        }}
                        value={Y ? Y : 0}
                      />
                    </Spin>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 8 }}>
                  <Col span={6}>Z</Col>
                  <Col span={18}>
                    <Spin spinning={updateNumber > 0} indicator={antIcon}>
                      <InputNumber
                        ref={el => (inputRef.current[2] = el)}
                        style={{ width: '100%' }}
                        step={1}
                        onChange={value => {
                          if (
                            typeof value === 'number' &&
                            parseFloat(Z) !== parseFloat(value)
                          ) {
                            //check input change value
                            setZ(value)
                            //for case external tileset
                            if (
                              model.sourceType === 'external' &&
                              model.type === 'unknown'
                            ) {
                              const updatedOriginalMatrix = [...originalMatrix]
                              updatedOriginalMatrix[2] = value
                              setOriginalMatrix(updatedOriginalMatrix)
                            }
                          }
                        }}
                        value={Z ? Z : 0}
                      />
                    </Spin>
                  </Col>
                </Row>
              </>
            )}
            <Row
              style={
                isIdenticalMatrix ? { display: 'none' } : { paddingTop: 8 }
              }>
              <Col span={6}>{t('scale')}</Col>
              <Col span={18}>
                <InputNumber
                  min={0}
                  max={1000}
                  step={0.1}
                  style={{ width: '100%' }}
                  onChange={value => {
                    if (typeof value === 'number') {
                      var s = parseFloat(value)
                      setScale(s)
                      modelStore.setScale(s)
                    }
                  }}
                  value={scale}
                />
              </Col>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <Col span={6}>CRS</Col>{' '}
              <Col span={18}>
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  placeholder={t(
                    'enter-country-code-or-name-coordinate-system'
                  )}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={val => handleSearch(val)}
                  onChange={val => handleChangeCrs(val)}
                  notFoundContent={null}
                  value={EpsgCode}
                  disabled>
                  {options}
                </Select>
              </Col>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <Col span={6} style={{ paddingTop: 7 }}>
                {t('elevation')}
              </Col>
              <Col span={18}>
                <Select
                  style={{ width: '100%' }}
                  // defaultValue={elevationSystem}
                  value={elevationSystem}
                  onChange={value => {
                    setElevationSystem(value)
                  }}
                  disabled>
                  <Option value="None">None</Option>
                  <Option value="N2000">N2000</Option>
                  <Option value="N60">N60</Option>
                  <Option value="EGM96">EGM96</Option>
                  <Option value="EGM2008">EGM2008</Option>
                  <Option value="NN2000">NN2000</Option>
                </Select>
              </Col>
            </Row>
            <Row style={{ paddingTop: 8 }}>
              <Col span={6}></Col>
              <Col span={18}>
                <Button
                  disabled={!EpsgCode}
                  type="primary"
                  onClick={async () => {
                    await goZoomToViewModel()
                  }}>
                  {t('commons.update')}
                </Button>
              </Col>
            </Row>
          </Panel>
          {isIFCEllipsoidRealWorldCoordinates(model) && (
            <>
              <Panel
                className={''}
                header={<HeaderPanel text={t('edit-position')} />}
                key="1">
                <Row>
                  <Col span={6}>X</Col>
                  <Col span={18}>
                    <Spin spinning={updateNumber > 0} indicator={antIcon}>
                      <InputNumber
                        ref={el => (inputRef.current[0] = el)}
                        step={1}
                        style={{ width: '100%' }}
                        onChange={value => {
                          if (
                            typeof value === 'number' &&
                            parseFloat(X) !== parseFloat(value)
                          ) {
                            //check input change value
                            setX(value)
                            //for case external tileset
                            if (
                              model.sourceType === 'external' &&
                              model.type === 'unknown'
                            ) {
                              const updatedOriginalMatrix = [...originalMatrix]
                              updatedOriginalMatrix[0] = value
                              setOriginalMatrix(updatedOriginalMatrix)
                            }
                          }
                        }}
                        value={X ? X : 0}
                      />
                    </Spin>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 8 }}>
                  <Col span={6}>Y</Col>
                  <Col span={18}>
                    <Spin spinning={updateNumber > 0} indicator={antIcon}>
                      <InputNumber
                        ref={el => (inputRef.current[1] = el)}
                        style={{ width: '100%' }}
                        step={1}
                        onChange={value => {
                          if (
                            typeof value === 'number' &&
                            parseFloat(Y) !== parseFloat(value)
                          ) {
                            //check input change value
                            setY(value)
                            //for case external tileset
                            if (
                              model.sourceType === 'external' &&
                              model.type === 'unknown'
                            ) {
                              const updatedOriginalMatrix = [...originalMatrix]
                              updatedOriginalMatrix[1] = value
                              setOriginalMatrix(updatedOriginalMatrix)
                            }
                          }
                        }}
                        value={Y ? Y : 0}
                      />
                    </Spin>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 8 }}>
                  <Col span={6}>Z</Col>
                  <Col span={18}>
                    <Spin spinning={updateNumber > 0} indicator={antIcon}>
                      <InputNumber
                        ref={el => (inputRef.current[2] = el)}
                        style={{ width: '100%' }}
                        step={1}
                        onChange={value => {
                          if (
                            typeof value === 'number' &&
                            parseFloat(Z) !== parseFloat(value)
                          ) {
                            //check input change value
                            setZ(value)
                            //for case external tileset
                            if (
                              model.sourceType === 'external' &&
                              model.type === 'unknown'
                            ) {
                              const updatedOriginalMatrix = [...originalMatrix]
                              updatedOriginalMatrix[2] = value
                              setOriginalMatrix(updatedOriginalMatrix)
                            }
                          }
                        }}
                        value={Z ? Z : 0}
                      />
                    </Spin>
                  </Col>
                </Row>
                <Row
                  style={
                    isIdenticalMatrix ? { display: 'none' } : { paddingTop: 8 }
                  }>
                  <Col span={6}>{t('scale')}</Col>
                  <Col span={18}>
                    <InputNumber
                      min={0}
                      max={1000}
                      step={0.1}
                      style={{ width: '100%' }}
                      onChange={value => {
                        if (typeof value === 'number') {
                          var s = parseFloat(value)
                          setScale(s)
                          modelStore.setScale(s)
                        }
                      }}
                      value={scale}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: 8 }}>
                  <Col span={6}>CRS</Col>{' '}
                  <Col span={18}>
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder={t(
                        'enter-country-code-or-name-coordinate-system'
                      )}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={val => handleSearch(val)}
                      onChange={val => handleChangeCrs(val)}
                      notFoundContent={null}
                      value={EpsgCode}
                      disabled>
                      {options}
                    </Select>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 8 }}>
                  <Col span={6} style={{ paddingTop: 7 }}>
                    {t('elevation')}
                  </Col>
                  <Col span={18}>
                    <Select
                      style={{ width: '100%' }}
                      // defaultValue={elevationSystem}
                      value={elevationSystem}
                      onChange={value => {
                        setElevationSystem(value)
                      }}
                      disabled>
                      <Option value="None">None</Option>
                      <Option value="N2000">N2000</Option>
                      <Option value="N60">N60</Option>
                      <Option value="EGM96">EGM96</Option>
                      <Option value="EGM2008">EGM2008</Option>
                      <Option value="NN2000">NN2000</Option>
                    </Select>
                  </Col>
                </Row>
              </Panel>
              <Panel
                className={''}
                id="rotationPanel"
                header={<HeaderPanel text={t('rotation')} />}
                key="2">
                <Row className="flex-center-global" style={{ marginTop: 10 }}>
                  <Col span={2}>Z</Col>
                  <Col span={16}>
                    <Slider
                      min={-180}
                      max={180}
                      tooltipVisible={false}
                      onChange={value => {
                        if (typeof value === 'number') {
                          setHeading(value)
                          debounceHPR.current(value, pitch, roll)
                        }
                      }}
                      step={0.1}
                      value={heading}
                    />
                  </Col>
                  <Col span={6}>
                    <InputNumber
                      min={-180}
                      max={180}
                      style={{ marginLeft: 10 }}
                      step={0.1}
                      onChange={value => {
                        if (typeof value === 'number') {
                          setHeading(value)
                          debounceHPR.current(value, pitch, roll)
                        }
                      }}
                      value={heading}
                    />
                  </Col>
                </Row>
                <Row className="flex-center-global" style={{ marginTop: 10 }}>
                  <Col span={2}>X</Col>
                  <Col span={16}>
                    <Slider
                      min={-180}
                      max={180}
                      step={0.1}
                      tooltipVisible={false}
                      onChange={value => {
                        // Object.assign(projectStore.modelEditPos, { pitch: value })
                        if (typeof value === 'number') {
                          setPitch(value)
                          debounceHPR.current(heading, value, roll)
                        }
                      }}
                      value={pitch}
                    />
                  </Col>
                  <Col span={6}>
                    <InputNumber
                      min={-180}
                      max={180}
                      step={0.1}
                      onChange={value => {
                        if (typeof value === 'number') {
                          setPitch(value)
                          debounceHPR.current(heading, value, roll)
                        }
                      }}
                      style={{ marginLeft: 10 }}
                      value={pitch}
                    />
                  </Col>
                </Row>
                <Row className="flex-center-global" style={{ marginTop: 10 }}>
                  <Col span={2}>Y</Col>
                  <Col span={16}>
                    <Slider
                      min={-180}
                      max={180}
                      step={0.1}
                      onChange={value => {
                        if (typeof value === 'number') {
                          setRoll(value)
                          debounceHPR.current(heading, pitch, value)
                        }
                      }}
                      value={roll}
                    />
                  </Col>
                  <Col span={6}>
                    <InputNumber
                      min={-180}
                      max={180}
                      step={0.1}
                      style={{ marginLeft: 10 }}
                      onChange={value => {
                        if (typeof value === 'number') {
                          setRoll(value)
                          debounceHPR.current(heading, pitch, value)
                        }
                      }}
                      value={roll}
                    />
                  </Col>
                </Row>
              </Panel>
            </>
          )}
          <Panel
            className={
              ['kmz', 'geotiff', 'geojson'].includes(model.type) ||
              isIdenticalMatrix ||
              isXMLEllipsoid(model) ||
              isIFCEllipsoid(model) ||
              isCloudPointXDEngineEllipsoid(model) ||
              isE57XDEngineEllipsoid(model)
                ? 'd-none'
                : ''
            }
            id="rotationPanel"
            header={<HeaderPanel text={t('rotation')} />}
            key="2">
            <Row className="flex-center-global" style={{ marginTop: 10 }}>
              <Col span={2}>Z</Col>
              <Col span={16}>
                <Slider
                  min={-180}
                  max={180}
                  tooltipVisible={false}
                  onChange={value => {
                    if (typeof value === 'number') {
                      setHeading(value)
                      debounceHPR.current(value, pitch, roll)
                    }
                  }}
                  step={0.1}
                  value={heading}
                />
              </Col>
              <Col span={6}>
                <InputNumber
                  min={-180}
                  max={180}
                  style={{ marginLeft: 10 }}
                  step={0.1}
                  onChange={value => {
                    if (typeof value === 'number') {
                      setHeading(value)
                      debounceHPR.current(value, pitch, roll)
                    }
                  }}
                  value={heading}
                />
              </Col>
            </Row>
            <Row className="flex-center-global" style={{ marginTop: 10 }}>
              <Col span={2}>X</Col>
              <Col span={16}>
                <Slider
                  min={-180}
                  max={180}
                  step={0.1}
                  tooltipVisible={false}
                  onChange={value => {
                    // Object.assign(projectStore.modelEditPos, { pitch: value })
                    if (typeof value === 'number') {
                      setPitch(value)
                      debounceHPR.current(heading, value, roll)
                    }
                  }}
                  value={pitch}
                />
              </Col>
              <Col span={6}>
                <InputNumber
                  min={-180}
                  max={180}
                  step={0.1}
                  onChange={value => {
                    if (typeof value === 'number') {
                      setPitch(value)
                      debounceHPR.current(heading, value, roll)
                    }
                  }}
                  style={{ marginLeft: 10 }}
                  value={pitch}
                />
              </Col>
            </Row>
            <Row className="flex-center-global" style={{ marginTop: 10 }}>
              <Col span={2}>Y</Col>
              <Col span={16}>
                <Slider
                  min={-180}
                  max={180}
                  step={0.1}
                  onChange={value => {
                    if (typeof value === 'number') {
                      setRoll(value)
                      debounceHPR.current(heading, pitch, value)
                    }
                  }}
                  value={roll}
                />
              </Col>
              <Col span={6}>
                <InputNumber
                  min={-180}
                  max={180}
                  step={0.1}
                  style={{ marginLeft: 10 }}
                  onChange={value => {
                    if (typeof value === 'number') {
                      setRoll(value)
                      debounceHPR.current(heading, pitch, value)
                    }
                  }}
                  value={roll}
                />
              </Col>
            </Row>
          </Panel>
          <Panel
            header={<HeaderPanel text={t('transparency')} />}
            key="8"
            className={['kmz', 'geojson'].includes(model.type) ? 'd-none' : ''}>
            <Row className="flex-center-global" style={{ marginTop: 10 }}>
              <Col span={18}>
                <Slider
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={value => {
                    if (typeof value === 'number') {
                      setTransparency(value)
                    }
                  }}
                  value={transparency}
                />
              </Col>
              <Col span={6}>
                <InputNumber
                  min={0}
                  max={1}
                  step={0.1}
                  style={{ marginLeft: 10 }}
                  onChange={value => {
                    if (typeof value === 'number') {
                      setTransparency(value)
                    }
                  }}
                  value={transparency}
                />
              </Col>
            </Row>
          </Panel>
          <Panel header={<HeaderPanel text={t('4d-scheduling')} />} key="5">
            <Row>
              <Col span={6} style={{ paddingTop: 10 }}>
                {t('start')}
              </Col>
              <Col span={18}>
                <DatePicker
                  locale={{
                    ...(commonStore.language === 'fi'
                      ? fi
                      : commonStore.language === 'sv'
                      ? sv
                      : commonStore.language === 'es'
                      ? es
                      : commonStore.language === 'vi'
                      ? vi
                      : en),
                    lang: {
                      ...(commonStore.language === 'fi'
                        ? fi
                        : commonStore.language === 'sv'
                        ? sv
                        : commonStore.language === 'es'
                        ? es
                        : commonStore.language === 'vi'
                        ? vi
                        : en
                      ).lang,
                      now: t('now'),
                      ok: t('commons.ok'),
                    },
                  }}
                  showTime
                  // inputReadOnly={true}
                  placeholder={t('select-start-time')}
                  value={startDate}
                  onChange={onChangeStart}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ paddingTop: 10 }}>
                {t('end')}
              </Col>
              <Col span={18} style={{ paddingTop: 10 }}>
                <DatePicker
                  locale={{
                    ...(commonStore.language === 'fi'
                      ? fi
                      : commonStore.language === 'sv'
                      ? sv
                      : commonStore.language === 'es'
                      ? es
                      : commonStore.language === 'vi'
                      ? vi
                      : en),
                    lang: {
                      ...(commonStore.language === 'fi'
                        ? fi
                        : commonStore.language === 'sv'
                        ? sv
                        : commonStore.language === 'es'
                        ? es
                        : commonStore.language === 'vi'
                        ? vi
                        : en
                      ).lang,
                      now: t('now'),
                      ok: t('commons.ok'),
                    },
                  }}
                  showTime
                  // inputReadOnly={true}
                  placeholder={t('select-end-time')}
                  value={endDate}
                  onChange={onChangeEnd}
                />
              </Col>
            </Row>
          </Panel>
          <Panel
            header={<HeaderPanel text={t('other')} />}
            key="6"
            className={['kmz'].includes(model.type) ? 'd-none' : ''}>
            {['geojson', 'geotiff'].includes(model.type) ? (
              <Row>
                <Col span={24}>
                  <Collapse defaultActiveKey="1">
                    <Panel
                      header={t('Server URL')}
                      key="1"
                      className="link-model-tileset">
                      {model.src}
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            ) : (
              ''
            )}
            {['model3d', 'model3d', 'kmz', 'geotiff', 'geojson'].includes(
              model.type
            ) ? (
              ''
            ) : (
              <Row>
                <Col style={{ height: '30px' }} span={24}>
                  <Checkbox
                    onChange={e => handleChange('isRender', e.target.checked)}
                    checked={isRender}>
                    {t('existing-data')}
                  </Checkbox>
                </Col>
              </Row>
            )}
            {[
              'model3d',
              'model3d',
              'unknown',
              'kmz',
              'geotiff',
              'geojson',
            ].includes(model.type) ? (
              ''
            ) : (
              <Row>
                <Col span={24}>
                  <Collapse defaultActiveKey="1">
                    <Panel
                      header={t('link-to-model-tileset')}
                      key="1"
                      className="link-model-tileset">
                      {publicJsonURL()}
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            )}
          </Panel>
          {(checkingFeatureRole('feature_custom_attribute_view') ||
            checkingFeatureRole('feature_custom_attribute_edit')) && (
            <Panel
              header={<HeaderPanel text={t('custom-attributes')} />}
              className={
                ['kmz', 'geotiff', 'geojson'].includes(model.type)
                  ? 'd-none'
                  : ''
              }
              key="custom-attribute">
              <>
                <CustomAttributes
                  place="edit-position-panel"
                  zIndex={99999}
                  customAttributeDatas={
                    sketchingStore.objectCustomAttributes &&
                    sketchingStore.objectCustomAttributes.fileLevel
                      ? sketchingStore.objectCustomAttributes?.fileLevel?.filter(
                          c => !c.isDeleted
                        )
                      : []
                  }
                  txtEditModal={'Edit object custom attribute'}
                  txtAddMoal={'Add new object custom attribute'}
                  isProjectCustomAtt={false}
                  handleUpdateAttribute={handleUpdateAttribute}
                  handleAddAttribute={handleAddAttribute}
                  handleDeleteAttribute={handleDeleteAttribute}
                  visibleModal={sketchingStore.visibleModalObjectAtt}
                  setVisibleModalAttribute={e =>
                    sketchingStore.setVisibleModalObjectAtt(e)
                  }
                  setCurrentEditAttribute={e =>
                    sketchingStore.setCurrentObjectAttribute(e)
                  }
                  currentAttribute={sketchingStore.currentObjectAttribute}
                  prjAttributeDatas={projectStore.prjCustomAttributeList.filter(
                    c => !c.isDeleted
                  )}
                  selectedAttrData={{
                    modelId: model?._id,
                    objectType: 'model',
                  }}
                  setIsEditingModelAttribute={e =>
                    sketchingStore.setIsEditingModelAttribute(e)
                  }
                  isEditingModelAttribute={
                    sketchingStore.isEditingModelAttribute
                  }
                  updateModelAttributes={
                    updateModelAttributes
                  }></CustomAttributes>
              </>
            </Panel>
          )}
        </Collapse>
      </EditPosLeftPanel>
    </>
  )
}

export default withRouter(
  inject('projectStore', 'adminStore', 'modelStore', 'capturesStore', 'usersStore', 'commonStore', 'sketchingStore')(observer(EditPositionPanel))
)
