import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, message, Row, Slider, Switch } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpButton } from '@/components/elements'
import { SettingControlContainer } from './CustomStyled';

const FogSetting = ({ projectStore, uiStore, viewer, commonStore, projectSettingStore, usersStore }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        let fetchData = async () => {
            let data = {}
            if (projectStore.projectDetail && usersStore.currentUser.id) {
                let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
                if (_systemProjectSetting.length > 0) {
                    let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser.id)
                    if (!_currentUserSystemSetting) return
                    data = Object.assign(projectSettingStore.systemProjectSetting, _currentUserSystemSetting.projectSetting)
                } else {
                    data = projectSettingStore.systemProjectSetting
                }
                form.setFieldsValue({
                    enabled: data.fogSetting?.enabled,
                    color: data.fogSetting?.color,
                    alpha: data.fogSetting?.alpha
                })
                form.setFieldsValue({ distances: [data.fogSetting?.distance[0], data.fogSetting?.distance[2]] })
                form.setFieldsValue({ fogValues: [data.fogSetting?.distance[1], data.fogSetting?.distance[3]] })
            } else {
                data = projectSettingStore.systemProjectSetting
                form.setFieldsValue({
                    enabled: data.fogSetting?.enabled,
                    color: data.fogSetting?.color,
                    alpha: data.fogSetting?.alpha
                })
                form.setFieldsValue({ distances: [data.fogSetting?.distance[0], data.fogSetting?.distance[2]] })
                form.setFieldsValue({ fogValues: [data.fogSetting?.distance[1], data.fogSetting?.distance[3]] })
            }
        }

        fetchData()
    }, [])

    const onSubmit = () => {
        form.validateFields().then(res => {
            let resdata = {
                enabled: res.enabled,
                color: res.color,
                alpha: res.alpha,
                distance: [res.distances[0], res.fogValues[0], res.distances[1], res.fogValues[1]],
            }
            projectSettingStore.setFogSetting(resdata)

            if (usersStore.currentUser?.id) {
                let metadata = projectSettingStore.getParamSystemSetting(projectStore, projectSettingStore, usersStore)
                setLoading(true)
                projectStore.setLoadingProgress(true)
                projectStore.updateProjectMetadata({ metadata }).then(res => {
                    projectStore.projectDetail.metadata = res.metadata
                    setLoading(false)
                    projectStore.setLoadingProgress(false)
                    uiStore.setShowFogSetting(false)
                    projectStore.setCleanMode(false)
                    uiStore.setRefreshViewer(true) //effect after updat fog setting refresh mainviewer
                    message.success(t('updated-successfully'))
                }).catch(err => {
                    setLoading(false)
                    projectStore.setLoadingProgress(false)
                    message.error(t('updated-failed'))
                    projectStore.setCleanMode(false)
                    uiStore.setShowFogSetting(false)
                })
            }
        })
    }

    const onCancel = () => {
        uiStore.setShowFogSetting(false)
        projectStore.setCleanMode(false)

        let _systemProjectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
        if (_systemProjectSetting.length > 0) {
            let _currentUserSystemSetting = _systemProjectSetting.find(elm => elm.userid === usersStore.currentUser.id)

            if (_currentUserSystemSetting?.projectSetting && _currentUserSystemSetting?.projectSetting.fogSetting) {
                projectSettingStore.setFogSetting(_currentUserSystemSetting.projectSetting.fogSetting)
            } else {
                projectSettingStore.setFogSetting({
                    enabled: false,
                    color: '#ffffff',
                    alpha: 0.6,
                    distance: [100.0, 0.0, 20000.0, 1.0]
                })
            }
        } else {
            projectSettingStore.setFogSetting({
                enabled: false,
                color: '#ffffff',
                alpha: 0.6,
                distance: [100.0, 0.0, 20000.0, 1.0]
            })
        }
        uiStore.setRefreshViewer(true)
    }

    const onChangeActive = (value) => {
        let _fog = projectSettingStore.systemProjectSetting.fogSetting
        _fog.enabled = value
        projectSettingStore.setFogSetting(_fog)
        uiStore.setRefreshViewer(true)
    };

    const onChangeColor = (e) => {
        let _fog = projectSettingStore.systemProjectSetting.fogSetting
        _fog.color = e.target.value
        projectSettingStore.setFogSetting(_fog)
        uiStore.setRefreshViewer(true)
    }

    const onChangeTransparency = (value) => {
        let _fog = projectSettingStore.systemProjectSetting.fogSetting
        _fog.alpha = value
        projectSettingStore.setFogSetting(_fog)
        uiStore.setRefreshViewer(true)
    };

    const onChangeDistances = (value) => {
        let _fog = projectSettingStore.systemProjectSetting.fogSetting
        _fog.distance[0] = value[0]
        _fog.distance[2] = value[1]
        projectSettingStore.setFogSetting(_fog)
        uiStore.setRefreshViewer(true)
    }

    const onChangeFogValues = (value) => {
        let _fog = projectSettingStore.systemProjectSetting.fogSetting
        _fog.distance[1] = value[0]
        _fog.distance[3] = value[1]
        projectSettingStore.setFogSetting(_fog)
        uiStore.setRefreshViewer(true)
    }

    return (
        <SettingControlContainer>
            <div className='help-btn-wrap'>
                <HelpButton helppage={"system_settings_fog"} />
            </div>
            <Form form={form} layout='horizontal' className='fogsetting'>
                <p><strong>{t('fog-settings')}</strong></p>
                <Form.Item
                    label={t('active')}
                    valuePropName="checked"
                    name="enabled">
                    <Switch
                        style={{ width: 'auto' }}
                        checkedChildren={t('commons.on')}
                        unCheckedChildren={t('commons.off')}
                        onChange={onChangeActive}
                    />
                </Form.Item>
                <Form.Item
                    label={t('color')}
                    initialValue={'#ffffff'}
                    name="color">
                    <input type="color" className='custom-input-color' onChange={onChangeColor} />
                </Form.Item>
                <Form.Item
                    label={t('transparency')}
                    className="form-vertical"
                    name="alpha">
                    <Slider
                        style={{ marginRight: '7px' }}
                        min={0.0}
                        max={1.0}
                        step={0.1}
                        onChange={onChangeTransparency}
                    />
                </Form.Item>
                <Form.Item
                    label={t('distances')}
                    className="form-vertical"
                    name="distances">
                    <Slider
                        min={0}
                        max={20000}
                        range
                        step={1}
                        onChange={onChangeDistances}
                    />
                </Form.Item>
                <Form.Item
                    label={t('fog-values')}
                    className="form-vertical"
                    name="fogValues">
                    <Slider
                        min={0.0}
                        max={1.0}
                        range
                        step={0.1}
                        onChange={onChangeFogValues}
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: 20, marginTop: 20 }}>
                    <Button style={{ marginRight: 5 }}
                        type="default"
                        icon={<CloseOutlined />}
                        onClick={onCancel}>
                        {t('commons.cancel')}
                    </Button>
                    <Button
                        loading={loading}
                        type="primary"
                        onClick={onSubmit}
                        icon={<SaveOutlined />}

                    >
                        {t('commons.save')}
                    </Button>
                </Form.Item>
            </Form>
        </SettingControlContainer >
    )
}
export default inject('projectStore', 'uiStore', 'commonStore', 'projectSettingStore', 'usersStore')(observer(FogSetting))
