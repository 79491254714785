import React from 'react'
import { Drawer, Icon, Tooltip, Popover, Button } from 'antd'
import { LinkOutlined, ShareAltOutlined, SettingOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import {
  DrawerHeaderWrapper,
  ProjectInfoTitle,
  SocialItem,
} from './CustomStyled'
// import { toJS } from 'mobx'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import buildingPlaceholder from '../../../../assets/imgs/building-placeholder.jpg'
// Icons
import { ReactComponent as FacebookIcon } from '../../../../assets/svgs/facebook-black.svg'
import { ReactComponent as TwitterIcon } from '../../../../assets/svgs/twitter-black.svg'
import { ReactComponent as LinkedInIcon } from '../../../../assets/svgs/linkedin-black.svg'
import { SVGIcon } from '@/components/elements';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect'

const DrawerProjectInfo = props => {
  const { t } = useTranslation();
  const { projectStore, history, match, commonStore } = props
  moment.locale(`${commonStore.language}`)

  const handleShareView = () => {
    window.open(`/view/${match.params.projectId}`, '_blank')
    window.focus()
  }

  const handleClickSetting = () => {
    history.push(`/project-settings/${match.params.projectId}`)
  }

  const ShareContent = () => {
    let shareUrl = window.location.protocol + '//' + window.location.hostname + `/view/${match.params.projectId}`
    return (
      <div style={{ width: 120 }}>
        <SocialItem>
          <FacebookShareButton url={shareUrl}>
            <SVGIcon
              color={'#395795'}
              width={25} height={25}
              content={<FacebookIcon />} />
            Facebook
          </FacebookShareButton>
        </SocialItem>
        <SocialItem>
          <TwitterShareButton url={shareUrl}>
            <SVGIcon
              color={'#1c9dec'}
              width={25} height={25}
              content={<TwitterIcon />} />
            Twitter
          </TwitterShareButton>
        </SocialItem>
        <SocialItem>
          <LinkedinShareButton url={shareUrl}>
            <SVGIcon
              color={'#1273a3'}
              width={25} height={25}
              content={<LinkedInIcon />} />
            LinkedIn
          </LinkedinShareButton>
        </SocialItem>
        <Button type="primary" icon={<LinkOutlined />} onClick={handleShareView}>
          {t('open-link')}
        </Button>
      </div>
    )
  }

  const DrawerHeader = () => {
    return (
      <DrawerHeaderWrapper>
        <span>{t('project-info')}</span>
        <Popover content={<ShareContent />} trigger="click">
          <ShareAltOutlined style={{ margin: '0 10px 0 auto' }} />
        </Popover>
        <Tooltip title={t('settings')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <SettingOutlined onClick={handleClickSetting} />
        </Tooltip>
      </DrawerHeaderWrapper>
    )
  }

  return (
    <Drawer
      className={'project-info-drawer'} id={'project-info-drawer'}
      width={360}
      placement={'left'}
      title={<DrawerHeader />}
      onClose={() => projectStore.toggleProjectInfoDrawer(false)}
      visible={projectStore.showProjectInfoDrawer}>
      {
        projectStore.projectDetail.thumbnail
          ? <img src={projectStore.projectDetail.thumbnail.url} alt={t('project-cover')} />
          : <img src={buildingPlaceholder} alt="Placeholder" />
      }
      <ProjectInfoTitle>{t('project-name')}:</ProjectInfoTitle>
      {projectStore.projectDetail.name ? projectStore.projectDetail.name : null}
      <ProjectInfoTitle>{t('project-description')}:</ProjectInfoTitle>
      {projectStore.projectDetail
        ? projectStore.projectDetail.desc !== '' &&
          projectStore.projectDetail.hasOwnProperty('desc')
          ? projectStore.projectDetail.desc
          : `(${t('no-description')})`
        : null}
      <ProjectInfoTitle>{t('created-date')}:</ProjectInfoTitle>
      {projectStore.projectDetail.createdAt
        ? moment(projectStore.projectDetail.createdAt).format('MMMM DD, YYYY')
        : null}
      <ProjectInfoTitle>{t('last-update')}:</ProjectInfoTitle>
      {projectStore.projectDetail.updatedAt
        ? moment(projectStore.projectDetail.updatedAt).format('MMMM DD, YYYY')
        : null}
      <ProjectInfoTitle>{t('members')}:</ProjectInfoTitle>
      {projectStore.projectDetail.projectuserroles
        ? projectStore.projectDetail.projectuserroles.length
        : null}
      <ProjectInfoTitle>{t('viewpoints')}:</ProjectInfoTitle>
      {projectStore.projectDetail.viewpoints
        ? projectStore.projectDetail.viewpoints.length
        : null}
    </Drawer>
  )
}

export default withRouter(inject('projectStore', 'commonStore')(observer(DrawerProjectInfo)))
