import { Button, Form, Input, message } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import validator from '@/validator';
import { NaviLink } from './CustomStyled';
import { useTranslation } from 'react-i18next';

function ForgotPasswordForm({ setisEmailSended, usersStore }) {
  const { t } = useTranslation();
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const sendForgotPasswordEmail = (value) => {
    usersStore.setLoadingProgress(true)
    usersStore.sendForgotPasswordEmail({
      email: value?.email.trim().toLowerCase()
    }).then(res => {
      usersStore.setLoadingProgress(false)
      if (res.data && res.data.message === "User existing") {
        message.success(t('an-email-has-been-send-to-email-address'))
        setisEmailSended(true)
      } else {
        message.error(t('user-not-existing-please-check-your-email-again'))
      }
    })
      .catch(error => {
        usersStore.setLoadingProgress(false)
        message.error(t('an-error-occurred'))
      })
  }

  return (
    <Form
      {...layout}
      name="forgotPassword-form"
      onFinish={sendForgotPasswordEmail}
    >
      <Form.Item
        label={t('email')}
        name="email"
        rules={
          [{
            required: true,
            type: 'email',
            message: t('please-input-your-email')
          },
          { validator: validator.validateEmail },
          ]
        }
      >
        <Input />
      </Form.Item>

      <Form.Item style={{ marginTop: 20 }} >
        <Button size={'large'} block type={'primary'} htmlType={'submit'}>
          {t('reset-password')}
          </Button>
      </Form.Item>

      <NaviLink margin={'10px 0 0'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to={'/auth/login'} style={{ color: '#ff8d4f' }}>
          {t('back-to-login-page')}
          </Link>
      </NaviLink>
    </Form >
  )
}
export default withRouter(inject('usersStore')(observer(ForgotPasswordForm)))

