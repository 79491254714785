import { CloseCircleOutlined, ColumnWidthOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Drawer, Skeleton, Checkbox, Divider, Row, Col, Input, Select, Spin, Empty } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import { ProjectLinkPageWrapper, SearchWrapper, CustomSearch, CustomButton, SpinDrawer } from './style'

import { SVGIcon } from '@/components/elements';
import { ReactComponent as ResourceIfcIcon } from '../../../assets/svgs/resource-ifc-v2.svg'
import { ReactComponent as ResourceXmlIcon } from '../../../assets/svgs/resource-xml-v2.svg'
import { ReactComponent as ResourceWmsIcon } from '../../../assets/svgs/resource-wms.svg'
import { ReactComponent as Resource3DIcon } from '../../../assets/svgs/resource-3d-v2.svg'
import { ReactComponent as Resource3DTilesIcon } from '../../../assets/svgs/resource-3dtiles.svg'
import { ReactComponent as ResourcePointCloudIcon } from '../../../assets/svgs/resource-point-cloud.svg'

import DocFile from '../../../assets/form-elems/Resource DOC.png'
import JPGFile from '../../../assets/form-elems/Resource JPG.png'
import PDFFile from '../../../assets/form-elems/Resource PDF.png'
import PNGFile from '../../../assets/form-elems/Resource PNG.png'
import PPTFile from '../../../assets/form-elems/Resource PPT.png'
import TIFFile from '../../../assets/form-elems/Resource TIFF.png'
import TXTFile from '../../../assets/form-elems/Resource TXT.png'
import XLSFile from '../../../assets/form-elems/Resource XLS.png'
import GenericFile from '../../../assets/form-elems/generic-doc.png'

import { ReactComponent as DWG } from '../../../assets/svgs/dwg.svg'
import { ReactComponent as PDF } from '../../../assets/svgs/pdf.svg'
import { ReactComponent as DXF } from '../../../assets/svgs/dxf.svg'
import { ReactComponent as IMG } from '../../../assets/svgs/img.svg'
import { ReactComponent as GENERIC } from '../../../assets/svgs/generic.svg'
import { isMobile, isTablet } from 'react-device-detect'

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const DrawerProjectLink = ({ projectStore, commonStore, usersStore }) => {
    const { t } = useTranslation();
    moment.locale(`${commonStore.language}`)
    const isTabletOrMobile = isMobile || isTablet
    const { projectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [checkedList, setCheckedList] = useState([]);
    const [listOption, setListOption] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [searchValue, setSearchValue] = useState('')
    const [listProjects, setListProjects] = useState([])
    const [prjSelected, setPrjSelected] = useState('');
    const [treeLoading, setTreeLoading] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (touch.clientX - (target && target.offsetRight ? target.offsetRight : 0));
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if(newWidth > window.innerWidth){
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize)

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let offsetRight = e.clientX - document.body.offsetLeft;
        let minDrawerWidth = 360;
        if (offsetRight > minDrawerWidth) {
            setDrawerWidth(offsetRight);
        }
        if(offsetRight > window.innerWidth){
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const setModelIcon = (item) => {
        switch (item.type) {
            case 'landxmlBackground':
            case 'landxml':
                return <ResourceXmlIcon />
            case 'ifc':
                return <ResourceIfcIcon />
            case 'model3d':
            case 'kmz':
                return <Resource3DIcon />
            case 'cloudpoint':
                return <ResourcePointCloudIcon />
            case 'e57':
                return <ResourcePointCloudIcon />
            case 'cad':
                return <Resource3DTilesIcon />
        }
        switch (item.sourceType) {
            case 'WMS':
            case 'WFS':
                return <ResourceWmsIcon />
            case 'City3DDB':
                return <ResourceWmsIcon />
            case 'external':
                return <Resource3DTilesIcon />
        }
        const ext = item && item.modelId && item.ext && item.ext.toLowerCase()

        switch (ext) {
            case '.doc':
            case '.docx':
                return <img src={DocFile} />;
            case '.jpg':
            case '.jpeg':
                return <img src={JPGFile} />;
            case '.pdf':
                return <img src={PDFFile} />
            case '.png':
                return <img src={PNGFile} />
            case '.ppt':
            case '.pptx':
                return <img src={PPTFile} />
            case '.tiff':
                return <img src={TIFFile} />
            case '.txt':
                return <img src={TXTFile} />
            case '.xls':
            case '.xlsx':
                return <img src={XLSFile} />
            default:
                return <img src={GenericFile} />
        }
        let isImage = false
        if (ext && ext.match(/.(jpg|jpeg|png|gif|svg)$/i))
            isImage = true
        if (isImage) {
            return <IMG />
        }
        switch (ext) {
            case '.DWG':
                return <DWG />
            case '.PDF':
                return <PDF />
            case '.DXF':
                return <DXF />
            default:
                return <GENERIC />
        }
    }
    useEffect(() => {
        if (projectStore.showProjectLinkDrawer) {
            setDrawerWidth(isTabletOrMobile ? 360 : 550)
            if (projectStore?.selectedNode?.link?.id) {
                setTreeLoading(true)
                projectStore.getListModelProject(projectStore?.selectedNode?.link?.id).then((res) => {
                    let listModelData = []
                    let modelCheck = []
                    res.map(item => {
                        if (item.id && !item.isDeleted) {
                            listModelData.push(item.id)
                        }
                    })
                    projectStore.selectedNode.model3ds.map(x => {
                        if (x.id && !x.isDeleted) {
                            modelCheck.push(x.id)
                        }
                    })
                    setCheckedList(modelCheck)
                    if (listModelData.length > 0 && listModelData.length === modelCheck.length) {
                        setCheckAll(true)
                        setIndeterminate(false)
                    } else if (modelCheck.length > 0) {
                        setCheckAll(false)
                        setIndeterminate(true)
                    } else {
                        setCheckAll(false)
                        setIndeterminate(false)
                    }
                    setTreeLoading(false)
                }).catch(() => setTreeLoading(false))
            }
        }
    }, [projectStore.showProjectLinkDrawer])
    const getListOption = () => {
        let listCheckBox = [];
        projectStore.listModelProject.map(item => {
            if (!item.isDeleted) {
                listCheckBox.push({
                    ...item,
                    label: item.title,
                    value: item.id
                })
            }
        })
        return listCheckBox
    }

    useEffect(() => {
        if (projectStore.listModelProject.length > 0) {
            let listCheckBox = getListOption();
            setListOption(listCheckBox)
        } else {
            setListOption([])
        }
    }, [projectStore.listModelProject])
    const onCloseProjectLink = () => {
        setLoading(false);
        setDrawerWidth(0);
        setLoading(false);
        setCheckedList([]);
        setListOption([]);
        setIndeterminate(false);
        setCheckAll(false);
        setSearchValue('');
        setListProjects([]);
        setPrjSelected('');
        setTreeLoading(false);
        projectStore.setShowProjectLinkDrawer(false);
        projectStore.setDisplayPanel(true);
        projectStore.setSelectedNode(null);
    };

    const onChangeCheckBoxGroup = (list) => {
        setIndeterminate(!!list.length && list.length < listOption.length);
        setCheckAll(list.length === listOption.length);
    };

    const onChangeCheckBox = (e) => {
        let checked = e.target.value;
        if (checkedList.includes(checked)) {
            setCheckedList(checkedList.filter(x => x !== checked))
        } else {
            setCheckedList([...checkedList, checked])
        }
    };


    const onCheckAllChange = (e) => {
        const values = listOption.map(record => record.value)
        setCheckedList(e.target.checked ? values : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const clearSearch = () => {
        setSearchValue('')
    }
    const onChangeSearch = (e) => {
        setSearchValue(e.target.value)
    }

    const getListModel = (id) => {
        if (!id) {
            setCheckedList([]);
            setCheckAll(false);
            setIndeterminate(false);
            setListOption([])
            return
        }
        setTreeLoading(true)
        projectStore.getListModelProject(id).then(() => {
            setTreeLoading(false)
        }).catch(() => setTreeLoading(false))
    }
    useEffect(() => {
        let listCheckBox = getListOption();
        if (listCheckBox.length === 0) return;
        setListOption(listCheckBox.filter(el => el?.label?.toLowerCase()?.includes(searchValue?.toLowerCase())));
        if (checkedList.length > 0) {
            setIndeterminate(true);
            setCheckAll(false)
        } else if (checkedList.length === listCheckBox.length) {
            setIndeterminate(false);
            setCheckAll(true)
        }
    }, [searchValue])
    useEffect(() => {
        const getListProjectOwner = () => {
            if (projectStore?.projectList?.length < 1) return;
            const values = projectStore.listProjectLink.map(record => record?.link?.id)
            let projectList = toJS(projectStore.projectList)
                .filter(item => item.project !== null)
                .filter(item => item?.projectrole?.role === 'project_owner')
                .filter(item => item?.user?._id === item?.project?.user)
                .filter(item => (!item.project.isOrganizationTemplate && !item.project.isSystemTemplate))
                .filter(item => !values.includes(item?.project?._id))
                .filter(item => projectId !== item?.project?._id)
            return projectList
        }
        setListProjects(getListProjectOwner())
    }, [projectStore.projectList])
    const hanldeSubmit = () => {
        if (projectStore?.selectedNode?.link?.id) {
            let params = {
                model3ds: checkedList
            }
            projectStore.updateProjectLink(projectStore?.selectedNode?._id, params).then(() => {
                onCloseProjectLink()
            })
        }

    }
    return (
        <Drawer
            className="pageDetailDrawer drawerProjectLink"
            mask={false}
            title={t('project-link')}
            id="pageDetailDrawer"
            width={drawerWidth}
            onClose={e => onCloseProjectLink()}
            visible={projectStore.showProjectLinkDrawer}
            footer={
                <div style={{ textAlign: 'right', marginRight: 10 }}>
                    <Skeleton loading={loading} active>
                        <>
                            <Button style={{ marginRight: 8 }} icon={<CloseCircleOutlined />} onClick={() => onCloseProjectLink()}>
                                {t('commons.cancel')}
                            </Button>
                            <Button style={{ marginRight: 8 }} icon={<EditOutlined />} type="primary" onClick={() => hanldeSubmit()}>
                                {t('commons.save')}
                            </Button>
                        </>
                    </Skeleton>
                </div>
            }
            placement="left">
            {isTabletOrMobile ? (
                projectStore.showProjectLinkDrawer ? (
                    <div className="splitpanel-mobile-left">
                        <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                            <ColumnWidthOutlined />
                        </Button>
                    </div>
                ) : " "

            ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-left" />)
            }
            <SpinDrawer >
                <Spin tip={t('loading-model')} spinning={treeLoading}>
                    <ProjectLinkPageWrapper>
                        <div>
                            <>
                                <SearchWrapper>
                                    <CustomSearch>
                                        <Input
                                            value={searchValue}
                                            onChange={onChangeSearch}
                                            placeholder={t('search-for-an-entry')}
                                            prefix={<SearchOutlined />}></Input>
                                    </CustomSearch>
                                    {searchValue && (
                                        <CustomButton onClick={() => clearSearch()}>
                                            <CloseOutlined />
                                        </CustomButton>
                                    )}
                                </SearchWrapper>
                                <Divider />
                                {(listOption && listOption.length) ? (<Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} style={{ fontWeight: "bold" }}>
                                    {t('check-all')}
                                </Checkbox>) : null}

                                {(!listOption || !listOption.length) ? (<Empty description={<span>{t('no-data')}</span>} />) : null}
                                <Row>
                                    <CheckboxGroup value={checkedList} onChange={onChangeCheckBoxGroup} style={{ marginLeft: 24 }} >
                                        {listOption?.map(item => {
                                            return <Col span={24} key={item.value}>
                                                <Checkbox value={item.value} onChange={onChangeCheckBox}> <SVGIcon
                                                    content={setModelIcon(item)}
                                                    width={20}
                                                    height={20}
                                                />{' '}{item.label}</Checkbox>
                                            </Col>
                                        })}
                                    </CheckboxGroup>
                                </Row>
                            </>
                        </div>
                    </ProjectLinkPageWrapper>
                </Spin>
                {isTabletOrMobile ? (
                    <div className="splitpanel-mobile">
                        <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                            <ColumnWidthOutlined />
                        </Button>
                    </div>
                ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel" />)
                }
            </SpinDrawer>
        </Drawer>
    )
}

export default inject(
    'projectStore',
    'usersStore',
    'projectSettingStore',
    'commonStore'
)(observer(DrawerProjectLink))
