import { message } from 'antd';
import { action, decorate, observable, toJS } from 'mobx';
import { FeatureandroleRequest, OrganizationRequest, ProjectRequest, FeatureRequest, LicenseRequest, SketchElementRequest, UserRequest, PreDefinedAccessRequest, IFCEngineRequest, HistoryPolicyRequest, LanguageRequest, GEOIDRequest } from '@/requests';
import { t } from 'i18next';
import uuid from 'uuid'
import moment from 'moment';
import { checkAvaiableLicenses } from '@/lib/projectLib';

class AdminStore {
    isRiderect = false
    isLoading = false
    editMode = false
    createMode = false
    showChangeRoleUserModal = false
    organizationRoles = []
    featureandroles = []
    currentOrganizationRoleView = {}
    currentFeatureandrolesView = {}
    orgBelongUserAdminList = []
    orgBelongUserManagerList = []
    orgUserBelongList = []
    currentOrganizationView = {}
    organizationActiveTab = '1'
    orgBelongUserList = []
    roleCurrentUserInOrganization = {}
    curentProjectInOrganizationDetail = {}
    teamList = []
    currentUserRoleDetailInProject = {}
    currentUserRoleDetailInOrganization = {}
    projectSearchList = []
    features = []
    licenses = []
    licenseSelected = {}
    totalProject = []
    licenseByUser = []
    availableLicenses = []
    listUserSearchs = []
    currentFeatureSetting = {}
    systemFeatureSetting = {}
    projectFeatureSetting = {}
    defaultFeatureSetting = {}
    currentPreDefinedAccess = {}
    listPreDefinedAccess = []
    customListProjects = []
    customListUsers = []
    currentPage = 1
    currentLimit = 10
    isTrigger = false
    currentUser = {}
    licenseTypes = []
    currentLicenseType = {}
    listProjectUsageLicense = 0
    listUserProjectUsageLicense = 0
    expireTime = ''
    storageQuotaUsage = 0
    licenseMaxUser = 0
    licenseMaxProject = 0
    licenseMaxQuota = 0
    greenColorSwitch = false
    IFCEngineUsages = []
    currentProjectRole = {}
    showAddResourceModel = false
    currentAddTab = 'externalLink'
    filterLicenseTypeOption = []
    selectedFilter = {}
    showAddFolderModal = false
    showAddElement = false
    selectedNode = null
    folderEdit = null
    elementEdit = null
    settings = false
    defaultTreeData = [
        {
            "title": "Vegetation",
            "key": uuid(),
            "type": "FOLDER",
            "isDeleted": false,
            "children": [
                {
                    "title": "Tree",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": [
                        {
                            "title": "Conifer",
                            "key": uuid(),
                            "type": "FOLDER",
                            "isDeleted": false,
                            "children": [
                                {
                                    "title": "Spruce",
                                    "key": uuid(),
                                    "type": "type",
                                    "isDeleted": false
                                },
                                {
                                    "title": "Pine",
                                    "key": uuid(),
                                    "type": "type",
                                    "isDeleted": false
                                }
                            ]
                        },
                        {
                            "title": "Broadleave",
                            "key": uuid(),
                            "type": "FOLDER",
                            "isDeleted": false,
                            "children": [
                                {
                                    "title": "Birch",
                                    "key": uuid(),
                                    "type": "type",
                                    "isDeleted": false
                                }
                            ]
                        }
                    ]
                },
                {
                    "title": "Bush",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": [
                        {
                            "title": "Conifer",
                            "key": uuid(),
                            "type": "type",
                            "isDeleted": false
                        },
                        {
                            "title": "Broadleave",
                            "key": uuid(),
                            "type": "type",
                            "isDeleted": false
                        }
                    ]
                },
                {
                    "title": "Flower",
                    "key": uuid(),
                    "type": "type",
                    "isDeleted": false
                },
                {
                    "title": "Hay",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                },
                {
                    "title": "Ground",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": [
                        {
                            "title": "Grass",
                            "key": uuid(),
                            "type": "type",
                            "isDeleted": false
                        },
                        {
                            "title": "Asphalt",
                            "key": uuid(),
                            "type": "type",
                            "isDeleted": false
                        },
                        {
                            "title": "Gravel",
                            "key": uuid(),
                            "type": "type",
                            "isDeleted": false
                        }
                    ]
                }
            ]
        },
        {
            "title": "Maisema BIM",
            "key": uuid(),
            "type": "FOLDER",
            "isDeleted": false,
            "children": []
        },
        {
            "title": "Lighting",
            "key": uuid(),
            "type": "FOLDER",
            "isDeleted": false,
            "children": []
        },
        {
            "title": "Traffic signs",
            "key": uuid(),
            "type": "FOLDER",
            "isDeleted": false,
            "children": []
        },
        {
            "title": "City furniture",
            "key": uuid(),
            "type": "FOLDER",
            "isDeleted": false,
            "children": []
        },
        {
            "title": "Rail 3D",
            "key": uuid(),
            "type": "FOLDER",
            "isDeleted": false,
            "children": [
                {
                    "title": "Electric",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                },
                {
                    "title": "Safety",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                },
                {
                    "title": "Noise",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                }
            ]
        },
        {
            "title": "Road 3D",
            "key": uuid(),
            "type": "FOLDER",
            "isDeleted": false,
            "children": [
                {
                    "title": "Portals",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                },
                {
                    "title": "Signs",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                },
                {
                    "title": "Lighting",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                },
                {
                    "title": "Noise",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                }
            ]
        },
        {
            "title": "Smart City",
            "key": uuid(),
            "type": "FOLDER",
            "isDeleted": false,
            "children": [
                {
                    "title": "IoT Sensors",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                },
                {
                    "title": "Solar Energy",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                },
                {
                    "title": "Wind Energy",
                    "key": uuid(),
                    "type": "FOLDER",
                    "isDeleted": false,
                    "children": []
                }
            ]
        }
    ]
    showAddResourceModel = false
    progressUploadFile = 0
    collapsed = false;
    // displayFeatureSetting = false
    currentSketchElement = {}
    showAddEditGEOID = false
    listGEOID = []
    currentGEOID = {}
    licenseOrgEdit = null
    customListLicenses = []
    customListLicenseTypes = []
    dataLibraryEdit = false

    setDataLibraryEdit = data => {
        this.dataLibraryEdit = data
    }

    setLicenseOrgEdit = data => {
        this.licenseOrgEdit = data
    }

    //#region GEOID
    setShowAddEditGEOID = (isShown, geoidRecord) => {
        this.showAddEditGEOID = isShown
        let shownGEOIDForm = {}
        if (geoidRecord) {
            shownGEOIDForm = {
                ...geoidRecord,
                headerTitle: 'edit-geoid-model',
                okText: 'commons.update',
                isEdit: true
            }
        } else {
            shownGEOIDForm = {
                headerTitle: 'create-geoid-model',
                okText: 'commons.save',
                isEdit: false
            }
        }
        this.currentGEOID = shownGEOIDForm
    }
    setListGEOID = data => {
        this.listGEOID = data
    }

    clearListGEOID = () => {
        this.listGEOID = []
    }

    async getListGEOID() {
        return new Promise((resolve, reject) => {
            GEOIDRequest.getListGEOID()
                .then(response => {
                    this.listGEOID = response.data
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async getGEOIDById(id) {
        return new Promise((resolve, reject) => {
            GEOIDRequest.getGEOIDById(id)
                .then(response => {
                    this.currentGEOID = response.data
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async createGEOID(body) {
        return new Promise((resolve, reject) => {
            GEOIDRequest.createGEOID(body)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async updateGEOID(id, body) {
        return new Promise((resolve, reject) => {
            GEOIDRequest.updateGEOID(id, body)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async deleteGEOID(id) {
        return new Promise((resolve, reject) => {
            GEOIDRequest.deleteGEOID(id)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async generateFileGeoIni() {
        return new Promise((resolve, reject) => {
            GEOIDRequest.generateFileGeoIni()
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    //#endregion

    organizations = {}
    isToggleMenuMobile = true
    setIsToggleMenuMobile = () => {
        this.isToggleMenuMobile = !this.isToggleMenuMobile
    }
    setIsToggleMenuMobileParam = (state) =>{
        this.isToggleMenuMobile = state
    }

    clearStatus = () => {
        this.setLoadingProgress(false)
        this.setCreateMode(false)
        this.setEditMode(false)
        this.setRiderect(false)
        this.selectedFilter = {}
    }

    clearLicenseQuota = () => {
        this.listProjectUsageLicense = 0
        this.listUserProjectUsageLicense = 0
        this.expireTime = ''
        this.storageQuotaUsage = 0
        this.licenseMaxUser = 0
        this.licenseMaxProject = 0
        this.licenseMaxQuota = 0
    }

    setProgressUploadFile = (count) => {
        this.progressUploadFile = count
    }
    setShowAddResourceModel = (stt) => {
        this.showAddResourceModel = stt
    }

    setElementEdit = (node) => {
        this.elementEdit = node
    }


    setSettings = (stt) => {
        this.settings = stt
    }

    setFolderEdit = (node) => {
        this.folderEdit = node
    }

    setSelectedNode = (node) => {
        this.selectedNode = node
    }

    setShowAddElement = (stt) => {
        this.showAddElement = stt
    }

    setShowAddFolderModal = (stt) => {
        this.showAddFolderModal = stt
    }

    setCurrentProjectRole = (data) => {
        this.currentProjectRole = data
    }

    setSelectedFilter = (key, value) => {
        this.selectedFilter[key] = value
    }

    clearSelectedFilter = () => {
        this.selectedFilter = {}
    }

    setFilterLicenseTypeOption = (data) => {
        this.filterLicenseTypeOption = data
    }

    filterLicenseTypeOption

    setGreenColorSwitch = (stt) => {
        this.greenColorSwitch = stt
    }

    setShowAddResourceModel = (stt) => {
        this.showAddResourceModel = stt
    }

    setCurrentAddTab(tabName) {
        this.currentAddTab = tabName
    }

    clearFeatureSetting = () => {
        this.currentFeatureSetting = {}
        this.systemFeatureSetting = {}
        this.projectFeatureSetting = {}
    }



    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading
    }

    setDisplayFeatureSetting = (stt) => {
        // this.displayFeatureSetting = stt
    }

    setRiderect = (state) => {
        this.isRiderect = state
    }

    setCreateMode(state) {
        this.createMode = state
    }

    setEditMode(state) {
        this.editMode = state
    }

    async getOrganizationRoles() {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationRoles()
                .then(response => {
                    this.organizationRoles = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    async getOrganizationRolesById(id) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationRolesById(id)
                .then(response => {
                    this.setCurrentOrganizationRoleView(response.data)
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    async createOrganizationRoles(body) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.createOrganizationRole(body)
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    /**
     * Get Project Detail
     * @param {*} organizationId  
     * @param {*} body array detail feature and permission corresponding
     */

    async updateOrganizationRoles(id, body) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.updateOrganizationRole(id, body)
                .then(response => {
                    this.currentOrganizationRoleView = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    async deleteOrganizationRoles(id) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.deleteOrganizationRole(id)
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }


    setCurrentOrganizationRoleView = (payload) => {
        this.currentOrganizationRoleView = payload
    }


    clearOrganizationRoles = () => {
        this.organizationRoles = [];
    }

    clearCurrentOrganizationRoleView = () => {
        this.currentOrganizationRoleView = {}
    }

    async getFeatureandroles() {
        return new Promise((resolve, reject) => {
            FeatureandroleRequest.getFeatureandrole()
                .then(response => {
                    this.featureandroles = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }


    async createFeatureandroles(body) {
        return new Promise((resolve, reject) => {
            FeatureandroleRequest.createFeatureandrole(body)
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })

    }

    async updateFeatureandroles(id, body) {
        return new Promise((resolve, reject) => {
            FeatureandroleRequest.updateFeatureandrole(id, body)
                .then(response => {
                    this.setCurrentFeatureandrolesView(response.data)
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })

    }

    async getSystemFeatureSetting(typeSystem, projectId) {
        return new Promise((resolve, reject) => {
            FeatureRequest.getFeatureSetting(typeSystem, projectId)
                .then(response => {
                    this.systemFeatureSetting = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    async getDefaultFeatureSetting(typeSystem) {
        return new Promise((resolve, reject) => {
            FeatureRequest.getFeatureSetting(typeSystem)
                .then(response => {
                    this.projectFeatureSetting = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    async getProjectFeatureSetting(type, projectId) {
        return new Promise((resolve, reject) => {
            FeatureRequest.getFeatureSetting(type, projectId)
                .then(response => {
                    this.projectFeatureSetting = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }


    setProjectFeatureSetting = (payload) => {
        this.projectFeatureSetting = payload
    }


    async createFeatureSetting(body) {
        return new Promise((resolve, reject) => {
            FeatureRequest.createFeatureSetting(body)
                .then(response => {
                    this.currentFeatureSetting = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })

    }

    async updateFeatureSetting(id, body) {
        return new Promise((resolve, reject) => {
            FeatureRequest.updateFeatureSetting(id, body)
                .then(response => {
                    this.currentFeatureSetting = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })

    }

    async deleteFeatureandroles(id) {
        return new Promise((resolve, reject) => {
            FeatureandroleRequest.deleteFeatureandrole(id)
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })

    }

    async getFeatureandrolesById(id) {
        return new Promise((resolve, reject) => {
            FeatureandroleRequest.getFeatureandroleById(id)
                .then(response => {
                    this.setCurrentFeatureandrolesView(response.data)
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })

    }



    setCurrentFeatureandrolesView = payload => {
        this.currentFeatureandrolesView = payload
    }

    clearFeatureandroles = () => {
        this.featureandroles = [];
    }

    clearCurrentFeatureandrolesView = () => {
        this.currentFeatureandrolesView = {}
    }

    async getOrganizationUserBelong() {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationUserBelong()
                .then(response => {
                    let v = response.data.filter(elm => elm.organization && elm.organization.isActive && elm.organizationrole)
                    this.orgUserBelongList = v
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    clearOrganizationUserBelong = () => {
        this.orgUserBelongList = []
    }

    acceptInviteToOrganization = (organizationuserrolesId, submitValues) => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.acceptInviteToOrganization(organizationuserrolesId, submitValues)
                .then((response) => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    getCurrentOrganizationDetail(id) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationById(id)
                .then(response => {
                    this.currentOrganizationView = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    setCurrentOrganizationDetail = (payload) => {
        this.currentOrganizationView = payload
    }

    clearCurrentOrganizationDetail = () => {
        this.currentOrganizationView = {}
    }

    getOrganizationBelongUser = () => {
        return OrganizationRequest.getOrganizationBelongUser().then((response) => {
            let _value = []
            for (let obj in response.data) {
                _value.push(response.data[obj])
            }
            this.orgBelongUserList = _value
        })
    }

    clearOrganizationBelongUser = () => {
        this.orgBelongUserList = []
    }

    async updateOrganizationInfo(id, info) {
        this.setLoadingProgress(true)
        return new Promise((resolve, reject) => {
            OrganizationRequest.updateOrganization(id, info)
                .then((response) => {
                    message.success(t('organization-updated-successfully'))
                    this.setEditMode(false)
                    this.currentOrganizationView = {
                        ...this.currentOrganizationView,
                        name: response.data.name,
                        website: response.data.website,
                        address: response.data.address,
                        licenses: response.data.licenses,
                        country: response.data.country,
                        zipCode: response.data.zipCode,
                        city: response.data.city,
                        isActive: response.data.isActive,
                        logo: response.data.logo,

                    }
                    resolve(response)
                })
                .catch((error) => {
                    message.error(t('an-error-occurred'))
                    reject(error)
                })
                .finally(() => this.setLoadingProgress(false))
        })

    }
    async createOrganization(info) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.createOrganization(info).then(res => {
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    async customUpdateOrganization(id, info) {
        this.setLoadingProgress(true);
        return new Promise((resolve, reject) => {
            OrganizationRequest.customUpdateOrganization(id, info)
                .then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                })
        })


    }

    deleteOrganization = (id) => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.deleteOrganization(id)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    setActiveTab(key) {
        this.organizationActiveTab = key
    }

    async getRoleCurrentUserInOrganization(organizationId) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getRoleCurrentUserInOrganization(organizationId)
                .then(response => {
                    this.roleCurrentUserInOrganization = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })

    }

    setRoleCurrentUserInOrganization = payload => {
        this.roleCurrentUserInOrganization = payload
    }
    clearRoleCurrentUserInOrganization = () => {
        this.roleCurrentUserInOrganization = {}
    }

    async getDetailProjectInOrganizationById(organizationId, projectId) {
        return new Promise((resolve, reject) => {
            ProjectRequest.getDetailProjectInOrganizationById(organizationId, projectId)
                .then(response => {
                    this.curentProjectInOrganizationDetail = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }
    clearCurentProjectInOrganizationDetail = () => {
        this.curentProjectInOrganizationDetail = {}
    }

    async updateProjectBelongOrganization(projectId, bodyData) {
        return new Promise((resolve, reject) => {
            ProjectRequest.updateProject(projectId, bodyData).then((response) => {
                resolve(response);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })

    }

    async deleteProjectBelongOrganization(projectId) {
        return new Promise((resolve, reject) => {
            ProjectRequest.deleteProject(projectId)
                .then((res) => {
                    resolve(res)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })

    }

    async getAllProjectTeamsInOrganization(organizationId, projectId) {
        return new Promise((resolve, reject) => {
            ProjectRequest.getAllProjectTeamsInOrganization(organizationId, projectId)
                .then(res => {
                    this.teamList = res.data.filter(c => c.email !== "maintenance@xd-visuals.fi" || c?.user?.username !== "maintenance")
                    resolve(res)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })

    }

    clearTeamList = () => {
        this.teamList = []
    }
    removeUserFromProject(projectuserrolesId) {
        return new Promise((resolve, reject) => {
            ProjectRequest.deleteUserFromProject(projectuserrolesId)
                .then((response) => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }
    sendInviteUserToProject = async (email, role, projectId, currentNumber) => {
        let submitValues = {
            email: email.toLowerCase(),
            projectrole: role,
            project: projectId,
            inviteStatus: 'waiting_accept',
            number: currentNumber
        }
        return new Promise((resolve, reject) => {
            ProjectRequest.sendInviteUserToProjectTeamsInOrganization(projectId, submitValues).then((res) => {
                resolve(res);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    setShowChangeRoleUserModal = state => {
        this.showChangeRoleUserModal = state
    }

    setCurrentUserRoleDetailInProject = payload => {
        this.currentUserRoleDetailInProject = payload
    }

    clearCurrentUserRoleDetailInProject = () => {
        this.currentUserRoleDetailInProject = {}
    }

    async updateProjectUserRole(id, body) {
        return new Promise((resolve, reject) => {
            ProjectRequest.updateProjectUserRole(id, body)
                .then((response) => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    setCurrentUserRoleDetailInOrganization = payload => {
        this.currentUserRoleDetailInOrganization = payload
    }

    clearCurrentUserRoleDetailInOrganization = () => {
        this.currentUserRoleDetailInOrganization = {}
    }

    deleteProjectInOrganization = (projectId, organizationId) => {
        return new Promise((resolve, reject) => {
            ProjectRequest.deleteProjectInOrg(projectId, organizationId)
                .then((res) => {
                    resolve(res)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async getSearchProjects(v) {
        return new Promise((resolve, reject) => {
            ProjectRequest.searchProjects(v)
                .then(res => {
                    this.projectSearchList = res.data.filter(c => !c.isDeleted)
                    resolve(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    async getFeatures() {
        return new Promise((resolve, reject) => {
            FeatureRequest.getFeature()
                .then(res => {
                    this.features = res.data
                    resolve(res)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    clearFeatures = () => {
        this.features = []
    }

    async getLicense() {
        return new Promise((resolve, reject) => {
            LicenseRequest.getLicense().then(res => {
                this.licenses = res.data
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    async getLicenseById(id) {
        return new Promise((resolve, reject) => {
            LicenseRequest.getLicenseById(id).then(res => {
                this.licenseSelected = res.data
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    async deleteLicense(id) {
        return new Promise((resolve, reject) => {
            LicenseRequest.deleteLicense(id).then(res => {
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    async updateLicense(id, body) {
        return new Promise((resolve, reject) => {
            LicenseRequest.updateLicense(id, body).then(res => {
                this.licenseSelected = res.data
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    async createLicense(info) {
        return new Promise((resolve, reject) => {
            LicenseRequest.createLicense(info).then(res => {
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    async renewLicense(info) {
        return new Promise((resolve, reject) => {
            LicenseRequest.renewLicense(info).then(res => {
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    setLicense = (v) => {
        this.licenses = v
    }

    clearLicense = () => {
        this.licenses = []
    }

    clearLicenseSelected = () => {
        this.licenseSelected = {}
    }

    async getTotalProjects() {
        this.setLoadingProgress(true)
        return new Promise((resolve, reject) => {
            ProjectRequest.getTotalProjects()
                .then(response => {
                    this.totalProject = response.data
                    this.setLoadingProgress(false)
                    resolve()
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject()
                })
        })
    }

    findLicenseByUser = id => {
        this.setLoadingProgress(true)
        LicenseRequest.findLicenseByUser(id).then(res => {
            this.licenseByUser = res.data
            this.setLoadingProgress(false)
        })
            .catch(error => console.log(error))
    }

    clearLicenseByUser = () => {
        this.licenseByUser = []
    }

    async getAvailableLicenses(body) {
        return new Promise((resolve, reject) => {
            LicenseRequest.getAvailableLicenses(body)
                .then(response => {
                    this.availableLicenses = response.data
                    this.setLoadingProgress(false)
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    clearAvailableLicenses = () => {
        this.availableLicenses = []
    }

    async customFindUser(type, body) {
        return new Promise((resolve, reject) => {
            UserRequest.customFindUser(type, body)
                .then(response => {
                    this.listUserSearchs = response.data
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    clearCustomFindUser = () => {
        this.listUserSearchs = []
    }

    async rebuildProjectModel(data) {
        return new Promise(async (resolve, reject) => {
            const waitcompleted = async () => {
                ProjectRequest.rebuildProjectModel(data).then((response) => {
                    resolve(response.data);
                }).catch(err => {
                    console.log(err)
                    reject(err)
                })
            }
            await waitcompleted()
        })
    }

    async waitUntilReady(listHashModel, maxRetry = 1000) {
        var numb = 0;
        return new Promise((resolve,reject) => {
            const waitUntilReady = async (i,listHashModel) => {
                await ProjectRequest.checkReadyRebuildProject(listHashModel).then(res => {
                    const isnotfinish =  res.data.some(item => item.status === 'waiting');
                    if (isnotfinish) {
                        i++;
                        if (i > maxRetry) {
                            console.log('Error maxRetry: ');
                            resolve(res?.data);
                        } else {
                            console.log(`waiting retry..${i}`, toJS(res?.data));
                            setTimeout(function () { waitUntilReady(i,res.data); }, 30000); // 5000 milliseconds = 5 second
                        }
                    } else {
                        resolve(res?.data);
                    }
                }).catch(err => {
                    reject(err)
                })
                
            };
            (async () => {
                await waitUntilReady(numb,listHashModel);
            })();
        });
    }

    async rebuildProjectModelV2(data) {
        return new Promise(async (resolve, reject) => {
            const waitcompleted = async () => {
                ProjectRequest.rebuildProjectModel(data).then(async (response) => {
                    let result = await this.waitUntilReady(response.data)
                    resolve(result)
                }).catch(err => {
                    console.log(err)
                    reject(err)
                })
            }
            await waitcompleted()
        })
    }

    async createPreDefinedAccess(data) {
        return new Promise((resolve, reject) => {
            PreDefinedAccessRequest.createPreDefinedAccess(data)
                .then(response => {
                    this.currentPreDefinedAccess = response.data
                    this.listPreDefinedAccess.push(response.data)
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async customGetProjects(_limit, _page, search) {
        return new Promise((resolve, reject) => {
            ProjectRequest.customGetProjects(_limit, _page, search)
                .then(response => {
                    this.customListProjects = response.data
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async customGetLicenses(_limit, _page, search) {
        return new Promise((resolve, reject) => {
            LicenseRequest.customGetLicenses(_limit, _page, search)
                .then(response => {
                    this.customListLicenses = response.data
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async customGetLicenseTypes(_limit, _page, search) {
        return new Promise((resolve, reject) => {
            LicenseRequest.customGetLicenseTypes(_limit, _page, search)
                .then(response => {
                    this.customListLicenseTypes = response.data
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async customGetUsers(_limit, _page, search) {
        return new Promise((resolve, reject) => {
            UserRequest.paginate(_limit, _page, search)
                .then(response => {
                    this.customListUsers = response.data
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    clearCustomListUsers = () => {
        this.customListUsers = []
    }

    setCustomListUsers = data => {
        this.customListUsers = data
    }

    async getIFCEngineUsage(data) {
        return new Promise((resolve, reject) => {
            IFCEngineRequest.getIFCEngines(data.startDate, data.endDate, data.type)
                .then(response => {
                    this.IFCEngineUsages = response.data.reverse()
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    setIFCEngineUsages = (data) => {
        this.IFCEngineUsages = data
    }

    async getPreDefinedAccesss() {
        return new Promise((resolve, reject) => {
            PreDefinedAccessRequest.getPreDefinedAccesss()
                .then(response => {
                    this.listPreDefinedAccess = response.data
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    async deletePreDefinedAccess(id) {
        return new Promise((resolve, reject) => {
            PreDefinedAccessRequest.deletePreDefinedAccess(id)
                .then(response => {
                    let data = this.listPreDefinedAccess.filter(elm => elm.id !== id)
                    this.listPreDefinedAccess = data
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    clearPreDefinedAccess = () => {
        this.listPreDefinedAccess = []
    }

    setCustomListProjects = data => {
        this.customListProjects = data
    }

    setCustomListLicenses = data => {
        this.customListLicenses = data
    }

    clearCustomListLicenses = () => {
        this.customListLicenses = []
    }

    setCustomListLicenseTypes = data => {
        this.customListLicenseTypes = data
    }

    clearCustomListLicenseTypes = () => {
        this.customListLicenseTypes = []
    }


    clearCustomListProjects = () => {
        this.customListProjects = []
    }

    clearPaginationParams = () => {
        this.currentPage = 1
        this.currentLimit = 10
    }

    setCurrentPage = (v) => {
        this.currentPage = v
    }

    setCurrentLimit = (v) => {
        this.currentLimit = v
    }

    triggerSider = (v) => {
        this.isTrigger = v
    }

    setcollapsed = (v) => {
        this.collapsed = v
    }

    async deleteMultiUser(body) {
        this.setLoadingProgress(true)
        return new Promise((resolve, reject) => {
            UserRequest.deleteMultiUser(body)
                .then((response) => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                })
        })
    }

    async getUserInfo(id) {
        return new Promise((resolve, reject) => {
            UserRequest.getUserInfo(id).then(res => {
                this.currentUser = res.data
                resolve(res.data)
            })
                .catch(err => reject(err))

        })

    }

    async updateUserInfo(id, body) {
        return new Promise((resolve, reject) => {
            UserRequest.updateUserInfo(id, body).then(res => {
                resolve(res.data)
            })
                .catch(err => reject(err))

        })

    }

    setCurrentUser = data => {
        this.currentUser = data
    }

    createUser = async (data) => {
        return new Promise((resolve, reject) => {
            UserRequest.createUser(data).then(res => {
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })

    }

    async getLicenseTypes() {
        return new Promise((resolve, reject) => {
            LicenseRequest.getLicenseType()
                .then(response => {
                    this.licenseTypes = response.data
                    this.setLoadingProgress(false)
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async getLicenseTypeById(id) {
        return new Promise((resolve, reject) => {
            LicenseRequest.getLicenseTypeById(id)
                .then(response => {
                    this.currentLicenseType = response.data
                    this.setLoadingProgress(false)
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async createLicenseType(body) {
        return new Promise((resolve, reject) => {
            LicenseRequest.createLicenseType(body)
                .then(response => {
                    this.setLoadingProgress(false)
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async updateLicenseType(id, body) {
        return new Promise((resolve, reject) => {
            LicenseRequest.updateLicenseType(id, body)
                .then(response => {
                    this.setLoadingProgress(false)
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async deleteLicenseType(id) {
        return new Promise((resolve, reject) => {
            LicenseRequest.deleteLicenseType(id)
                .then(response => {
                    this.setLoadingProgress(false)
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    setLicenseTypes = payload => {
        this.licenseTypes = payload
    }

    clearLicenseType = () => {
        this.licenseTypes = []
    }

    setListProjectUsageLicense = payload => {
        this.listProjectUsageLicense = payload
    }

    setListUserProjectUsageLicense = payload => {
        this.listUserProjectUsageLicense = payload
    }

    setexpireTime = payload => {
        this.expireTime = payload
    }

    setstorageQuotaUsage = payload => {
        this.storageQuotaUsage = payload
    }

    setLicenseMaxUser = payload => {
        this.licenseMaxUser = payload
    }

    setLicenseMaxProject = payload => {
        this.licenseMaxProject = payload
    }

    setLicenseMaxQuota = payload => {
        this.licenseMaxQuota = payload
    }

    async migrateProjectOrganization(data) {
        return new Promise(async (resolve, reject) => {
            const waitcompleted = async () => {
                ProjectRequest.migrateProjectOrganization(data).then((response) => {
                    resolve(response.data);
                }).catch(err => {
                    reject(err)
                })
            }
            await waitcompleted()
        })
    }

    checkingFeatureRole = (projectStore, feature) => {
        const systemSetting = this.systemFeatureSetting?.data
        const projectSetting = this.projectFeatureSetting?.data
        const currentUserRoleInProject = projectStore.currentUserRoleInProject
        let _role = false
        const roleStr = `${currentUserRoleInProject}-${feature}`;
        if (projectStore.projectDetail?._id) {
            const system = systemSetting ? systemSetting[roleStr] : false;
            const project = projectSetting ? projectSetting[roleStr] : false;
            const isDiff =  projectSetting ? projectSetting.hasOwnProperty(roleStr) : false
            _role = isDiff ? project: system
        }
        return _role ? _role : false
    }

    async sendEmailNotification(body) {
        return new Promise((resolve, reject) => {
            UserRequest.sendEmailNotification(body)
                .then(response => {
                    this.setLoadingProgress(false)
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async updateProjectRole(id, body) {
        return new Promise((resolve, reject) => {
            ProjectRequest.updateProjectRole(id, body).then((response) => {
                resolve(response.data);
            }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    }

    async sendEmailNotificationOver(body) {
        return new Promise((resolve, reject) => {
            UserRequest.sendEmailNotificationOver(body)
                .then(response => {
                    this.setLoadingProgress(false)
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async sendGenericEmailNotification(body) {
        return new Promise((resolve, reject) => {
            UserRequest.sendGenericEmailNotification(body)
                .then(response => {
                    this.setLoadingProgress(false)
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async createHistoryPolicy(body) {
        return new Promise((resolve, reject) => {
            HistoryPolicyRequest.create(body)
                .then(response => {
                    this.setLoadingProgress(false)
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async resetAcceptPolicyTermConditions(body) {
        return new Promise((resolve, reject) => {
            UserRequest.resetAcceptPolicyTermConditions(body)
                .then(response => {
                    this.setLoadingProgress(false)
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async updateLanguageEditor(body) {
        return new Promise((resolve, reject) => {
            LanguageRequest.updateLanguageEditor(body)
                .then(response => {
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    setCurrentSketchElement = (data) => {
        this.currentSketchElement = data
    }

    async getSketchElements(type, prj, org) {
        return new Promise((resolve, reject) => {
            SketchElementRequest.get(type, prj, org).then((response) => {
                resolve(response);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    async createSketchElements(body) {
        return new Promise((resolve, reject) => {
            SketchElementRequest.create(body).then((response) => {
                this.currentSketchElement = response.data
                resolve(response);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

    async updateSketchElement(id, body) {
        return new Promise((resolve, reject) => {
            SketchElementRequest.update(id, body).then((response) => {
                this.currentSketchElement = response.data
                resolve(response);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }
    async customGetOrganizations(_limit, _page, search) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.customGetOrganizations(_limit, _page, search)
                .then(response => {
                    let orgList = response.data.data
                    let result = orgList.map(element => {
                        let _totalLicenses = element.licenses
                        let _avaiableLicense = checkAvaiableLicenses(_totalLicenses)
                        return {
                            ...element,
                            avaiableLicense: _avaiableLicense
                        }
                    })
                    this.organizations = { data: result, count: response.data.count }
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }
    clearCustomListOrganization = () => {
        this.organizations = {}
    }

    setCustomListOrganization = data => {
        this.organizations = data
    }

}





decorate(AdminStore, {

    //observable
    isRiderect: observable,
    isLoading: observable,
    editMode: observable,
    createMode: observable,
    currentOrganizationRoleView: observable,
    organizationRoles: observable,
    currentFeatureandrolesView: observable,
    featureandroles: observable,
    orgBelongUserAdminList: observable,
    orgBelongUserManagerList: observable,
    orgUserBelongList: observable,
    currentOrganizationView: observable,
    organizationActiveTab: observable,
    orgBelongUserList: observable,
    roleCurrentUserInOrganization: observable,
    curentProjectInOrganizationDetail: observable,
    teamList: observable,
    showChangeRoleUserModal: observable,
    currentUserRoleDetailInProject: observable,
    currentUserRoleDetailInOrganization: observable,
    projectSearchList: observable,
    features: observable


    // action 
    ,
    clearStatus: action,
    setLoadingProgress: action,
    setRiderect: action,
    setCreateMode: action,
    setEditMode: action,
    setCurrentOrganizationRoleView: action,
    clearCurrentOrganizationRoleView: action,
    getOrganizationRoles: action,
    clearOrganizationRoles: action,
    updateOrganizationRoles: action,
    createOrganizationRoles: action,
    deleteOrganizationRoles: action,
    getOrganizationRolesById: action,
    updateFeatureandroles: action,
    deleteFeatureandroles: action,
    getFeatureandrolesById: action,
    setCurrentFeatureandrolesView: action,
    clearFeatureandroles: action,
    getFeatureandroles: action,
    createFeatureandroles: action,
    clearCurrentFeatureandrolesView: action,
    getOrganizationBelongUserAdmin: action,
    clearOrganizationBelongUserAdmin: action,
    getOrganizationBelongUserManager: action,
    clearOrganizationBelongUserManager: action,
    getOrganizationUserBelong: action,
    clearOrganizationUserBelong: action,
    acceptInviteToOrganization: action,
    getCurrentOrganizationDetail: action,
    clearCurrentOrganizationDetail: action,
    updateOrganizationInfo: action,
    setActiveTab: action,
    clearOrganizationBelongUser: action,
    getOrganizationBelongUser: action,
    setRoleCurrentUserInOrganization: action,
    clearRoleCurrentUserInOrganization: action,
    getRoleCurrentUserInOrganization: action,
    getRoleCurrentUserInOrganization: action,
    getDetailProjectInOrganizationById: action,
    clearCurentProjectInOrganizationDetail: action,
    getAllProjectTeamsInOrganization: action,
    clearTeamList: action,
    removeUserFromProject: action,
    sendInviteUserToProject: action,
    setShowChangeRoleUserModal: action,
    setCurrentUserRoleDetailInProject: action,
    clearCurrentUserRoleDetailInProject: action,
    updateProjectUserRole: action,
    setCurrentUserRoleDetailInOrganization: action,
    clearCurrentUserRoleDetailInOrganization: action,
    deleteProjectInOrganization: action,
    deleteOrganization: action,
    setCurrentOrganizationDetail: action,
    customUpdateOrganization: action,
    createOrganization: action,
    getFeatures: action,
    clearFeatures: action,
    getLicense: action,
    getLicenseById: action,
    createLicense: action,
    renewLicense: action,
    updateLicense: action,
    clearLicense: action,
    setLicense: action,
    licenseSelected: observable,
    licenses: observable,
    clearLicenseSelected: action,
    getTotalProjects: action,
    totalProject: observable,
    deleteLicense: action,
    licenseByUser: observable,
    findLicenseByUser: action,
    clearLicenseByUser: action,
    getAvailableLicenses: action,
    clearAvailableLicenses: action,
    availableLicenses: observable,
    listUserSearchs: observable,
    clearCustomFindUser: action,
    customFindUser: action,
    createFeatureSetting: action,
    updateFeatureSetting: action,
    getSystemFeatureSetting: action,
    getProjectFeatureSetting: action,
    currentFeatureSetting: observable,
    systemFeatureSetting: observable,
    projectFeatureSetting: observable,
    displayFeatureSetting: observable,
    setDisplayFeatureSetting: action,
    clearFeatureSetting: action,
    defaultFeatureSetting: observable,
    getDefaultFeatureSetting: action,
    rebuildProjectModel: action,
    rebuildProjectModelV2: action,
    createPreDefinedAccess: action,
    currentPreDefinedAccess: observable,
    getPreDefinedAccesss: action,
    listPreDefinedAccess: observable,
    clearPreDefinedAccess: action,
    customGetProjects: action,
    customListProjects: observable,
    clearCustomListProjects: action,
    setCustomListProjects: action,
    clearPaginationParams: action,
    currentPage: observable,
    currentLimit: observable,
    setCurrentPage: action,
    setCurrentLimit: action,
    triggerSider: action,
    isTrigger: observable,
    customGetUsers: action,
    customListUsers: observable,
    clearCustomListUsers: action,
    setCustomListUsers: action,
    deleteMultiUser: action,
    getUserInfo: action,
    setCurrentUser: action,
    currentUser: observable,
    updateUserInfo: action,
    createUser: action,
    getLicenseTypes: action,
    licenseTypes: observable,
    currentLicenseType: observable,
    setLicenseTypes: action,
    clearLicenseType: action,
    getLicenseTypeById: action,
    createLicenseType: action,
    updateLicenseType: action,
    deleteLicenseType: action,
    listProjectUsageLicense: observable,
    listUserProjectUsageLicense: observable,
    setListProjectUsageLicense: action,
    setListUserProjectUsageLicense: action,
    setexpireTime: action,
    expireTime: observable,
    setstorageQuotaUsage: action,
    setLicenseMaxUser: action,
    setLicenseMaxProject: action,
    setLicenseMaxQuota: action,
    storageQuotaUsage: observable,
    licenseMaxUser: observable,
    licenseMaxProject: observable,
    licenseMaxQuota: observable,
    migrateProjectOrganization: action,
    setProjectFeatureSetting: action,
    setGreenColorSwitch: action,
    greenColorSwitch: observable,
    checkingFeatureRole: action,
    sendEmailNotification: action,
    getIFCEngineUsage: action,
    IFCEngineUsages: observable,
    setIFCEngineUsages: action,
    clearLicenseQuota: action,
    setCurrentProjectRole: action,
    currentProjectRole: observable,
    updateProjectRole: action,
    sendEmailNotificationOver: action,
    setShowAddResourceModel: action,
    showAddResourceModel: observable,
    setCurrentAddTab: action,
    currentAddTab: observable,
    deletePreDefinedAccess: action,
    sendGenericEmailNotification: action,
    setFilterLicenseTypeOption: action,
    filterLicenseTypeOption: observable,
    setSelectedFilter: action,
    selectedFilter: observable,
    clearSelectedFilter: action,
    setShowAddFolderModal: action,
    showAddFolderModal: observable,
    setShowAddElement: action,
    showAddElement: observable,
    setSelectedNode: action,
    showAddElement: observable,
    setElementEdit: action,
    elementEdit: observable,
    setFolderEdit: action,
    folderEdit: observable,
    setSettings: action,
    settings: observable,
    defaultTreeData: observable,
    setShowAddResourceModel: action,
    showAddResourceModel: observable,
    progressUploadFile: observable,
    setProgressUploadFile: action,
    collapsed: observable,
    setcollapsed: action,
    currentSketchElement: observable,
    setCurrentSketchElement: action,
    getSketchElements: action,
    createSketchElements: action,
    updateSketchElement: action,
    showAddEditGEOID: observable,
    setShowAddEditGEOID: action,
    listGEOID: observable,
    setListGEOID: action,
    clearListGEOID: action,
    getListGEOID: action,
    currentGEOID: observable,
    getGEOIDById: action,
    createGEOID: action,
    updateGEOID: action,
    deleteGEOID: action,
    organizations: observable,
    customGetOrganizations: action,
    isToggleMenuMobile: observable,
    setIsToggleMenuMobileParam: action,
    setIsToggleMenuMobile: action,
    clearCustomListOrganization: action,
    setCustomListOrganization: action,
    licenseOrgEdit: observable,
    setLicenseOrgEdit: action,
    customListLicenses: observable,
    clearCustomListLicenses: action,
    setCustomListLicenses: action,
    customGetLicenses:action,
    clearCustomListLicenseTypes:action,
    setCustomListLicenseTypes:action,
    customGetLicenseTypes:action,
    customListLicenseTypes: observable,
    setDataLibraryEdit : action,
    dataLibraryEdit  : observable
})

export default new AdminStore();