import {
  DeleteOutlined
} from '@ant-design/icons'
import { Button, Empty, Form, message, Modal, Popconfirm, Row, Select, Table, Tooltip } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DataAddIcon } from '@/assets/svgs/data-add.svg'
import TreeUtils from '@/tree-utils'
import validator from '@/validator'
import { SVGIcon } from '@/components/elements';
import { AccessControlPanelActions, AccessControlWrapper } from './CustomStyled'
import ModalAddAccessControl from './ModalAddAccessControl'

const ModalEditAccessControl = ({ projectStore, userGroupStore, projectTeamsStore }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [usergroups, setUserGroups] = useState([]);
  const { processedUserGroups } = userGroupStore;

  useEffect(() => {
    if (userGroupStore.nodeEdit) {
      setUserGroups(checkAvaiableGroups(userGroupStore.nodeEdit, processedUserGroups))
    }
  }, [userGroupStore.nodeEdit, userGroupStore.showEditAccessControlModal])

  useEffect(() => {
    if (userGroupStore.showEditAccessControlModal) {
      for (let i = 0; i < usergroups.length; i++) {
        const element = usergroups[i];
        let newObject = {}
        newObject[element.id] = element.rights
        form.setFieldsValue(newObject)
      }
    }
  }, [usergroups])

  const checkAvaiableGroups = (nodeEdit, groups) => {
    const result = []
    let accesscontrols = nodeEdit.accesscontrols || []
    for (let i = 0; i < accesscontrols.length; i++) {
      const element = accesscontrols[i];
      let isExist = groups.find(c => c.id === element.id)
      if (isExist) {
        element.name = isExist?.name
        result.push(toJS(element))
      }
    }
    return result
  }

  const handleDeleteGroup = ( record) => {
    if (record) {
      setUserGroups(usergroups.filter(c => c.id !== record.id))
      message.success(t("remove-successfully"))
    }
  }

  const onCancel = () => {
    userGroupStore.setTreeNodeEdit()
    form.resetFields();
    userGroupStore.setEditAccessControl(false)
    projectStore.setSelectedNode();
    setUserGroups([])
  }

  const onUpdateAccessControl = () => {
    form.validateFields().then(values => {
      const { treeData } = projectStore.projectDetail;
      let newData = []
      const keys = Object.keys(values)
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];
        newData[i] = {
          name: usergroups[i].name,
          id: usergroups[i].id,
          rights: values[element]
        }
      }

      const newNode = {
        accesscontrols: newData
      }
      editTreeNode(newNode, treeData);
    });
  }


  const clickAddNewGroup = () => {
    userGroupStore.setShowAddNewGroupToTreeData(true)
  }

  const editTreeNode = (newData, data) => {
    let toNode;
    if (userGroupStore?.nodeEdit?.parentKey) {
      const parrentNode = TreeUtils.searchTreeNode(data, 'key', userGroupStore?.nodeEdit?.parentKey);
      toNode = parrentNode.children;
    } else {
      toNode = data;
    }
    const node = toNode.find(children => children.key === userGroupStore?.nodeEdit?.key);
    if (node) {
      node.accesscontrols = newData.accesscontrols ? newData.accesscontrols : [];
    }
    projectStore.setLoadingProgress(true);
    projectStore.updateProjectTreeData({ treeData: data, store: 'treedata' }).then((res) => {
      projectStore.projectDetail.treeData = res.treeData;
      form.resetFields()
      userGroupStore.setEditAccessControl(false)
      userGroupStore.setTreeNodeEdit()
      projectStore.setSelectedNode();
      setUserGroups([])
      message.success(t('edit-access-rights-successfully'))
    }).catch(err => {
      console.log(err)
      message.error(t('edit-access-rights-failed'))
    }).finally(() => {
      projectStore.setLoadingProgress(false);
    })
  }

  const columns = [
    {
      title: t('user-or-group'),
      key: 'title',
      render: (record, obj) => {
        return <span className='break-word'>{obj.name ? record.name : ''}</span>
      },
    },
    // {
    //   title: 'Type',
    //   key: 'type',
    //   render: (record, obj) => {
    //     return <span className='break-word'>{obj.name ? record.name : ''}</span>
    //   },
    // },
    {
      title: t('rights'),
      key: 'rights',
      render: (record, obj) => {
        return (
          <Form.Item
            name={obj.id}
            initialValue={obj.rights}
            rules={[
              {
                required: true,
                message: t('please-select-rights-access'),
              },
              { validator: validator.validateEmptyString },
            ]}>
            <Select
              placeholder={t("select-a-rights")}
              optionFilterProp="children"
              showSearch={true}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ width: '200px' }}
              options={userGroupStore.rightsOptions}
            />
          </Form.Item>
        )
      },
    },
    {
      title: t('action'),
      width: 80,
      key: 'action',
      render: (record, obj) => {
        return (
          <Row type="flex" justify="center" key="action">
            <Popconfirm
              placement="top"
              title={t('are-you-sure-delete')}
              onConfirm={() => handleDeleteGroup(obj)}
              okText={t('commons.yes')}
              cancelText={t('commons.no')}>
              <Button type="danger" style={{ margin: 2, padding: '3px 7px' }}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Row>
        )
      },
    },
  ]

  return (
    <Modal
      visible={userGroupStore.showEditAccessControlModal}
      title={t('access-rights')}
      okText={t('commons.create')}
      cancelText={t('commons.cancel')}
      onCancel={onCancel}
      onOk={onUpdateAccessControl}
      forceRender={true}
      maskClosable={false}
      destroyOnClose
      zIndex={9999}
      className="ant-modal-body-12"
      footer={
        <Row justify="end">
          <Button key="back" onClick={onCancel}>
            {t('commons.cancel')}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={userGroupStore.isAppLoading}
            onClick={onUpdateAccessControl}>
            {t('commons.ok')}
          </Button>
        </Row>
      }>
      <AccessControlWrapper>
        <AccessControlPanelActions justify="end">
          <Tooltip
            title={t('add-user-group')}
            placement='leftTop'
            overlayStyle={
              isMobile || isTablet ? { display: 'none' } : undefined
            }>
            <Button
              icon={
                <SVGIcon content={<DataAddIcon />} width={32} height={32} />
              }
              onClick={clickAddNewGroup}
            />
          </Tooltip>
        </AccessControlPanelActions>
        {usergroups && usergroups.length > 0 ? (
          <>
            <Form form={form} name="edit-access-control" layout="vertical">
              <Table className='group-wrapper' rowKey={c => c.id} pagination={false} dataSource={usergroups} columns={columns} />
            </Form>
          </>
        ) : (
          <Empty description={t('no-user-groups')} />
        )}
        <ModalAddAccessControl usergroups={usergroups} setUserGroups={setUserGroups} />
      </AccessControlWrapper>
    </Modal>
  )
}

export default inject('projectStore', 'userGroupStore', 'projectTeamsStore')(observer(ModalEditAccessControl))