import { ColumnHeightOutlined, FullscreenExitOutlined, FullscreenOutlined, CloseOutlined, ReloadOutlined, PrinterOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Descriptions, Drawer, Empty, Modal, Row, Select, Spin, Tooltip, Typography, Col } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import { Link, useHistory, useParams } from 'react-router-dom';
import FeedbackReportTable from './FeedbackReportTable/FeedbackReportTable';
import './style.css';
import { Trans, useTranslation } from 'react-i18next';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import { HelpButton } from '@/components/elements'
import * as XLSX from 'xlsx';
const { Option } = Select;
const { Text } = Typography;

function DrawerFeedbackReport(props) {
    const { t } = useTranslation();
    const { projectStore, usersStore, feedbackStore, commonStore } = props
    const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    const [drawerHeight, setDrawerHeight] = useState(0)
    const [refresh, setRefresh] = useState(0)
    const [screenHeight, setScreenHeight] = useState("0")
    const [time, setTime] = useState("5")
    const [exportModal, setExportModal] = useState(false)
    const timeRefresh = ["5", "10", "30", "60"]
    const [isLoading, setLoadingProgress] = useState(false)
    moment.locale(`${commonStore.language}`)

    let location = useLocation()
    const { projectId } = useParams();
    const history = useHistory()

    function renderColumnFeedbackForm(feedback) {
        let fbAnswers = feedback.feedbackAnswers
        let _fbForm = feedback?.feedbackform?.formControlData?.elements || []
        _fbForm = _fbForm.filter(c => c.className !== 'textReadonly' && c.className !== 'image')
        let elementDeleted = _fbForm.filter(c => c => c.className !== 'textReadonly' && c.className !== 'image' && c.isDelete)

        //func remove feedback form element not has value in all answer
        const removeElementNotAnswers = (result, object) => {
            fbAnswers.map(answer => {
                let arrAnswers = answer?.formControlData?.elements || []
                arrAnswers.map(c => {
                    let isExist = result.find(k => k.name === c.name)
                    if (c.name === object.name && !isExist) {
                        if (c.value) {
                            result.push(object)
                        }
                    }
                })
            })
            return result
        }
        let columnElements = _fbForm.reduce(removeElementNotAnswers, [])
        // func get old element deleted but  someone has answered to that
        const getElements = (result, object) => {
            let arrAnswers = object?.formControlData?.elements || []
            arrAnswers.map(c => {
                let isExistDeleted = elementDeleted.find(k => k.name === c.name)
                let isExistResult = result.find(k => k.name === c.name)
                if (!isExistResult && isExistDeleted && c.value) {
                    result.push(isExistDeleted)
                }
            })
            return result
        }
        return fbAnswers.reduce(getElements, columnElements)
    }

    const FeedbackFormData = ({ feedbackForm, feedbackAnswers }) => {
        let formData = { className: "empty" }
        let arrAnswers = feedbackAnswers?.formControlData?.elements || []
        let data = arrAnswers.find(c => c.name === feedbackForm.name)
        if (data) {
            formData = data
        }
        const elementSkeleton = () => {
            switch (formData.className) {
                case 'singleLine':
                    return <td>{formData.value ? formData.value : ''}</td>
                case 'multiLine':
                    return <td>{formData.value ? formData.value : ''}</td>
                case 'email':
                    return <td>{formData.value ? formData.value : ''}</td>
                case 'dropdown':
                    return <td>{formData.value ? formData.value : ''}</td>
                case 'empty':
                    return <td></td>
                case 'radioGroup':
                    return (
                        <td>
                            {
                                formData.value ? formData.value : ''
                            }
                        </td>
                    )
                case 'checkboxGroup':
                    return (
                        <td>
                            {
                                formData.value && formData.value.map(c => {
                                    return <div>{c}</div>
                                })
                            }
                        </td>
                    )
                case 'smileyButton':
                    let _indexSmiley = formData.choices.findIndex(c => c === formData.value)
                    return (
                        <td
                        >
                            {formData.value ? (_indexSmiley !== -1 ? _indexSmiley : '') : ''}
                        </td>
                    )
                case 'urlReadonly':
                    return (
                        <td>
                            {
                                formData.value && (
                                    <Descriptions.Item >
                                        <a href={formData.value || '#'} rel="noopener noreferrer" target="_blank">{formData.text || formData.value}</a>
                                    </Descriptions.Item>
                                )
                            }
                        </td>
                    )
                default:
                    break
            }
        }

        return (<>{elementSkeleton()}</>)
    }

    // event for touche split panel
    const handleTouchStart = e => {
        if (e.touches.length !== 1) return;
        if (e.cancelable) {
            e.preventDefault();
        }

        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    }

    const handleRefresh = () => {
        setRefresh(refresh + 1)
    }

    const showExportModal = () => {
        setExportModal(true)
    }

    const closeExportModal = () => {
        setExportModal(false)
    }

    const exportReport = () => {
        setExportModal(false)
    }

    const handleChangeTimeRefresh = (e) => {
        setTime(e)
    }

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newHeight = (document.body.offsetHeight ? document.body.offsetHeight : 0) - (touch.clientY - (target && target.offsetLeft ? target.offsetLeft : 0));
        let minDrawerHeight = 50;
        if (newHeight < 0) {
            setDrawerHeight(10);
        }
        if (newHeight > minDrawerHeight) {
            setDrawerHeight(newHeight);
        }
        if(newHeight > window.innerHeight){
            setDrawerHeight(window.innerHeight);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    // event mouse for split panel
    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let newHeight = document.body.offsetHeight - (e.clientY - document.body.offsetLeft);
        let minDrawerHeight = 50;
        if (newHeight > minDrawerHeight) {
            setDrawerHeight(newHeight);
        }
        if(newHeight > window.innerHeight){
            setDrawerHeight(window.innerHeight);
        }
    }, []);

    const handleClickFullScreen = () => {
        if (drawerHeight === window.innerHeight) {
            setDrawerHeight(window.innerHeight / 2);
        } else {
            setDrawerHeight(window.innerHeight);
        }
    }

    // (changeSize) change drawerWidth when drawerWidth > screenWidth
    const handleChangeScreenSize = () => {
        setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleChangeScreenSize) // force reload when screenWidth change

    useEffect(() => {
        if (drawerHeight > screenHeight) {
            setDrawerHeight(screenHeight)
        }
        if (feedbackStore.showDrawerFeedbackReport) {
            setDrawerHeight(window.innerHeight)
        }
    }, [screenHeight, window.innerHeight, feedbackStore.showDrawerFeedbackReport])
    // end changeSize

    useEffect(() => {
        if (feedbackStore.showDrawerFeedbackReport) {
            const populate = async () => {
                setLoadingProgress(true)
                await projectStore.getFeedbackReport(projectId).then(() => {
                    setLoadingProgress(false)
                }).catch(error => {
                    setLoadingProgress(false)
                })
            }
            populate()
        }
    }, [feedbackStore.showDrawerFeedbackReport, refresh, time])

    useEffect(() => {
        if (location.search === '?feedbackreport') {
            const feedbackReportAutoOpen = () => {
                setDrawerHeight(window.innerHeight)
                feedbackStore.setShowDrawerFeedbackReport(true)
            }
            feedbackReportAutoOpen()
        }
    }, [time])

    // auto refresh
    useEffect(() => {
        if (time === '5' && location.search === '?feedbackreport') {
            const interval = setInterval(() => {
                setRefresh(refresh => refresh + 1)
            }, (1000 * 60 * 5)); // 5 miniutes 
            return () => {
                clearInterval(interval)
            }

        }

        if (time === '10' && location.search === '?feedbackreport') {
            const interval = setInterval(() => {
                setRefresh(refresh => refresh + 1)
            }, (1000 * 60 * 10)); // 10 miniutes 
            return () => {
                clearInterval(interval)
            }
        }

        if (time === '30' && location.search === '?feedbackreport') {
            const interval = setInterval(() => {
                setRefresh(refresh => refresh + 1)
            }, (1000 * 60 * 30)); // 30 miniutes 
            return () => {
                clearInterval(interval)
            }
        }

        if (time === '60' && location.search === '?feedbackreport') {
            const interval = setInterval(() => {
                setRefresh(refresh => refresh + 1)
            }, (1000 * 60 * 60)); // 60 miniutes 
            return () => {
                clearInterval(interval)
            }
        }
    }, [time, refresh])



    const onClose = () => {
        feedbackStore.setShowDrawerFeedbackReport(false)
        setDrawerHeight(0)
        setRefresh(0)
    }

    const CloseIcon = () => {
        return (
          <Row gutter={[8]}>
            {location.search !== "?feedbackreport" && <Col className="">
              {drawerHeight === window.innerHeight ? (
                <Tooltip title={(isMobile || isTablet) ? '' : t('out-full-screen')} placement="bottom">
                  <div>
                    <FullscreenExitOutlined
                      onClick={() => handleClickFullScreen()}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={(isMobile || isTablet) ? '' : t('full-screen')}>
                  <FullscreenOutlined onClick={() => handleClickFullScreen()} />
                </Tooltip>
              )}
            </Col>}
            <Col>
              <Tooltip title={(isMobile || isTablet) ? '' : t('commons.close')} placement="bottom">
                <div>
                  <CloseOutlined onClick={() => onClose()} />
                </div>
              </Tooltip>
            </Col>
          </Row>
        )
      }

      const exportNew = ()=> {
        var tbl = document.getElementById('feedback-report');
        var wb = XLSX.utils.table_to_book(tbl);
        /* export to file */
        XLSX.writeFile(wb, "feedback-report.xlsx");
        setExportModal(false);
      }
    return (
        <Drawer
            closeIcon={<CloseIcon />}
            title={
                <div className="feedbackReport-outer">
                    <div className="help-btn-wrapper">
                        {t('feedback-report')}
                        <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                            <div>
                                <HelpButton helppage={'feedback_report'} hovered={'black'}/>
                            </div>
                        </Tooltip>
                    </div>
                    <div className={`feedbackReport-btn-wrapper ${isIOS ? 'ios-device' : ''}`}>
                        <div className={`feedbackReport-btn-outer ${(isMobile || isTablet) && 'minimize'}`}>
                            <Button disabled={isLoading} onClick={handleRefresh} icon={(isMobile || isTablet) && <ReloadOutlined />}>{!(isMobile || isTablet) && t('commons.refresh')}</Button>
                            <Button disabled={isLoading} className="print-btn" icon={(isMobile || isTablet) && <PrinterOutlined />}>{!(isMobile || isTablet) && t('commons.print')}</Button>
                            <Button disabled={isLoading} className="export-btn" onClick={showExportModal} icon={(isMobile || isTablet) && <FileTextOutlined />}>{!(isMobile || isTablet) && t('commons.export')}</Button>
                        </div>
                    </div>
                </div>
            }
            mask={false}
            placement="bottom"
            closable={location.search !== "?feedbackreport" ? true : false}
            height={drawerHeight}
            //onClose={location.search !== "?feedbackreport" ? onClose : false}
            visible={feedbackStore.showDrawerFeedbackReport}
            className="drawerfeedbackReport custom-wraper-splitPanel"
            //maskClosable={false}
        >
            {feedbackStore.showDrawerFeedbackReport && (isTabletOrMobileDevice ? (
                <div className="splitpanel-horizontal-mobile">
                    <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                        <ColumnHeightOutlined />
                    </Button>
                </div>
            ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-horizontal" />)
            )}
            {
                projectStore.feedbackReports.length > 0 ?
                    <div className="feedbackReport-header">
                        <Text className="feedbackReport-projectName"><span>{projectStore.projectDetail.name}</span> ({moment(projectStore.projectDetail.createdAt).format('DD/MM/YYYY')})</Text>
                        <div className="special-link">
                            {
                                location.search === '?feedbackreport' &&
                                <div>
                                    <Link to={location.pathname} target="_blank" className="link-to-project" >{t('link-to-project')}</Link>
                                </div>
                            }
                            {
                                location.search === '?feedbackreport' ?
                                    <div>
                                        <Text>{t('automatic-refresh')}: </Text>
                                        <Select
                                            defaultValue="5 minutes"
                                            style={{ width: 120 }}
                                            onChange={handleChangeTimeRefresh}
                                        >
                                            {
                                                timeRefresh.map((time, index) => {
                                                    return (
                                                        <Option key={index} value={time}>{time} {'minutes'}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </div>
                                    :
                                    <Link to={`${location.pathname}?feedbackreport`} target="_blank" className="link-to-project" >{t('open-this-report-as-html-page-without-xd-twin-3d-view')}</Link>
                            }
                        </div>
                    </div> : ""
            }
            {
                isLoading ?
                    <Spin size="large" className="feedbackReport-spiner" /> : <FeedbackReportTable dataTable={projectStore.feedbackReports} />
            }
            <Modal
                title={t('export-xlsx')}
                visible={exportModal}
                zIndex={9999}
                onOk={exportReport}
                onCancel={closeExportModal}
                footer={
                    <Row justify='end'>
                        <Button key="back" onClick={closeExportModal}>
                            {t('commons.cancel')}
                        </Button>
                        <Button type='primary' onClick={exportNew}>
                          {t('commons.export')}
                        </Button>
                    </Row>
                }
            >
                <p>{t('do-you-want-to-export-this-report-to-xlsx-file')}</p>
                <table style={{ display: 'none' }} id='feedback-report' className="feedBackReportTable" >
                    {
                        projectStore.feedbackReports.length > 0 ? (
                            projectStore.feedbackReports.map((feedback, index) => {
                                let fbAnswers = feedback.feedbackAnswers
                                let _fbForm = renderColumnFeedbackForm(feedback)
                                return (
                                    <table key={`${feedback?.feedbackform ? feedback.feedbackform.id : 'feedbackreport'}`} >
                                        <thead >
                                            <tr>
                                                <th style={{ backgroundColor: '#bdd7ee', textAlign: 'center', fontWeight: "bold", fontSize: '1.8rem' }} colSpan={_fbForm.length + 2}>{feedback?.feedbackform?.title}</th>
                                            </tr>
                                            <tr>
                                                <th style={{ backgroundColor: '#bdd7ee', textAlign: 'center', fontWeight: "bold", fontSize: '1.8rem' }} colSpan="2">{t('feedback')}</th>
                                                <th style={{ backgroundColor: '#bdd7ee', textAlign: 'center', fontWeight: "bold", fontSize: '1.8rem' }} colSpan={_fbForm.length}>{t('element')}</th>
                                            </tr>
                                            <tr>
                                                <th style={{ backgroundColor: '#bdd7ee', textAlign: 'center', fontWeight: "bold", fontSize: '1.8rem' }}>{t('user')}</th>
                                                <th style={{ backgroundColor: '#bdd7ee', textAlign: 'center', fontWeight: "bold", fontSize: '1.8rem' }}>{t('time')}</th>
                                                {
                                                    _fbForm && _fbForm.map(fbAnswer => {
                                                        return <th key={fbAnswer.name} style={{ backgroundColor: '#bdd7ee', textAlign: 'center', fontWeight: "bold", fontSize: '1.8rem' }}>{fbAnswer.title}</th>
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                fbAnswers.length > 0 ? (
                                                    fbAnswers.map((answer, indexx) => {
                                                        return (
                                                            <tr key={`${answer.name}-${indexx}`}>
                                                                <td className="feedback-item ">
                                                                    {answer?.user?.username ? answer.user.username : ''}
                                                                </td>
                                                                <td className="feedback-item">
                                                                    {moment(answer?.cloneData?.createdAt ? answer?.cloneData?.createdAt : answer?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                                                </td>
                                                                {
                                                                    _fbForm && _fbForm.map(_form => {
                                                                        return <FeedbackFormData key={`${_form.name}-answer`} feedbackForm={_form} feedbackAnswers={answer} formData={_form, answer} />
                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <tr>
                                                        <th className='no-data' colSpan={feedback.feedbackform.formControlData.elements?.length + 2}>{t('no-answer')}</th>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                        <tfoot>
                                            <tr></tr>
                                        </tfoot>
                                    </table>
                                )
                            })
                        ) : <Empty description={false} />
                    }
                </table>
            </Modal>
        </Drawer>
    );
}

export default inject(
    'projectStore',
    'usersStore',
    'feedbackStore',
    'commonStore'
)(observer(DrawerFeedbackReport))
