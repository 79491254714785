import React, { useEffect, useState, useRef, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, InputNumber, Cascader, Modal, Switch, Input } from 'antd'
import Konva from "konva"
import axios from 'axios'
import {
  calculateWH
} from '@/helper'

const AROpacity = ({ arStore, clientWidth, clientHeight }) => {

  // useEffect(() => {

  //   return () => {

  //   }
  // }, [arStore.currentTestData])

  useEffect(() => {
    // console.log('arStore.currentTestData', arStore.currentTestData)
    // arStore.currentTestData.modelRenderInfo.OpenCV.extra.fov
    // console.log('arStore.currentTestData', arStore.currentTestData.modelRenderInfo.calibration.Angle)
    var angle = arStore.currentTestData.modelRenderInfo.calibration.Angle
    if (arStore.currentCalbAr !== 'cal-0') {
      let findCalId = arStore.calibrations.findIndex(x => x.id === arStore.currentCalbAr)
      if (findCalId > -1) {
        let findArId = arStore.calibrations[findCalId].data.artests.findIndex(x => x.id === arStore.currentTestData.id)
        if (findArId > -1) {
          let result = arStore.calibrations[findCalId].data.artests[findArId].result
          if (result.Status === 'OK') {
            angle = result.Angle
          }
          else
            angle = [0, 0, 0]
        }
      }
    }

    var halfWidth = clientWidth / 2
    var imgPos = [0, 0, halfWidth, 0]
    if (arStore.currentTestData === false) return
    // var imgScaleX = [1,1]
    // var imgScaleY = [1,1]
    let csize = arStore.currentTestData.modelRenderInfo.CanvasSize
    var s = calculateWH(csize.width, csize.height, halfWidth, clientHeight)

    function addImage(url, imgLayer, index, opacity, draggable) {
      var imageObj = new Image();
      imageObj.src = url;
      imageObj.onload = function () {
        this.image = imageObj;
        var ratio = this.image.width / this.image.height
        var imgOptions = {
          image: imageObj,
          draggable,
          opacity
        }
        if (s.height > s.width) {
          imgOptions.height = s.height
          imgOptions.width = s.height * ratio
        }
        else {
          imgOptions.width = s.width
          imgOptions.height = s.width / ratio
        }
        // imgScaleX[index/2] = imgOptions.width/this.image.width
        // imgScaleY[index/2] = imgOptions.height/this.image.height

        imgPos[index] = (clientWidth - imgOptions.width) / 2
        imgPos[index + 1] = (clientHeight - imgOptions.height) / 2

        imgOptions.x = imgPos[index]
        imgOptions.y = imgPos[index + 1]

        // var imgScaleX = imgOptions.width / this.image.width
        // var imgScaleY = imgOptions.height / this.image.height

        if (index === 2) {
          imgOptions.rotation = angle[2]
          let fov1 = arStore.currentTestData.modelRenderInfo.OpenCV.extra.fov
          let fov2 = arStore.currentTestData.modelRenderInfo.OpenCV.extra.fovy
          if (ratio > 1) {
            fov2 = fov1
            fov1 = arStore.currentTestData.modelRenderInfo.OpenCV.extra.fovy
          }
          imgOptions.x += imgOptions.width / fov1 * angle[0]
          imgOptions.y += imgOptions.height / fov2 * angle[1]
        }
        // imgOptions.rotation = angle[2]*0.0174533
        var kImg = new Konva.Image(imgOptions);
        // add the shape to the layer
        imgLayer.add(kImg);
        if (draggable) {
          var tr = new Konva.Transformer();
          imgLayer.add(tr);
          tr.nodes([kImg]);
          tr.moveToTop();
          kImg.rotate(0.0174533 * 20)
          kImg.scale(2, 2)
          imgLayer.draw();
          console.log('angle', angle)
        } else {
          kImg.moveToBottom();
        }



        imgLayer.batchDraw();
      };

    }

    var stage = new Konva.Stage({
      container: "kv-canvas",
      width: clientWidth,
      height: clientHeight
    });

    var layer = new Konva.Layer();
    // var dragLayer = new Konva.Layer();
    stage.add(layer);
    addImage(arStore.currentTestData.photoDevice.url, layer, 0, 1, false)
    addImage(arStore.currentTestData.imageModelRender.url, layer, 2, 0.7, true)
    // Konva.Image.fromURL(arStore.currentTestData.photoDevice.url, function (img) {
    //   img.setAttrs({
    //     x: xImage1,
    //     y: yImage1,
    //     scaleX: 1,
    //     scaleY: 1,
    //   });
    //   img.crossOrigin = "*";
    //   layer.add(img);
    //   layer.batchDraw();
    // });
    // var imageObjCamera = new Image();
    // imageObjCamera.src = arStore.currentTestData.photoDevice.url;
    // imageObjCamera.onload = function () {
    //   this.image = imageObjCamera;
    //   var ratio = this.image.width / this.image.height
    //   var imgOptions = {
    //     scaleX: 1,
    //     scaleY: 1,
    //     image: imageObjCamera,
    //   }
    //   if (s.height > s.width) {
    //     imgOptions.height = s.height
    //     imgOptions.width = s.height * ratio
    //   }
    //   else {
    //     imgOptions.width = s.width
    //     imgOptions.height = s.width / ratio
    //   }

    //   xImage1 = (halftWidth - imgOptions.width) / 2
    //   yImage1 = (clientHeight - imgOptions.height) / 2

    //   imgOptions.x = xImage1
    //   imgOptions.y = yImage1
    //   var deviceImg = new Konva.Image(imgOptions);
    //   // add the shape to the layer
    //   layer.add(deviceImg);
    //   layer.batchDraw();
    // };

    return () => {
      stage.destroy()
    }
  }, [arStore.currentTestData, arStore.currentCalbAr])

  return (
    <div>
      <div id="kv-canvas" style={{ width: clientWidth, height: clientHeight }}>
      </div>
    </div>
  )
}
export default inject('arStore')(observer(AROpacity))
