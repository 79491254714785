import { InfoCircleOutlined, CloseOutlined, UpOutlined, DownOutlined, SyncOutlined } from '@ant-design/icons'
import { message, Modal, notification, Progress, Collapse, Button } from 'antd'
import { Cartesian2, Cartesian3, Cartographic } from 'cesium'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState, useRef } from 'react'
import uuid from 'uuid'
import { getMimeOfFile, bytesToSize, updateProjectModel, mergeAllModels, create_ifcengineusage, updateProjectTreeData, updateContenModelIon, convertExtToLowerCase, addNewEllipsoidModel } from '@/lib/projectLib'
import { clickPoint, conversProjectionLocal, isEllipsoid } from '@/helper'
import { CollapseBody } from './CustomStyled'
import MeshModal from '../MeshModal/MeshModal'
import HttpLinkNameModal from './HttpLinkNameModal'
import axios from 'axios'
import { useTranslation } from 'react-i18next';
import { SVGIcon } from '@/components/elements';
import { ReactComponent as CloudDone } from '@/assets/svgs/cloud-done.svg'
import { Scrollbars } from 'react-custom-scrollbars';
import * as PromiseBlue from "bluebird";

const ASSET_URL = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/`
const { Panel } = Collapse;
const CancelToken = axios.CancelToken;
const Cesium = require('cesium')

const DropMultipleFiles3DView = props => {
    const { t } = useTranslation();
    const { projectStore, viewer, dropArea, adminStore, fileStore, projectSettingStore, usersStore, commonStore, objectQueryStore } = props

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [validFiles, setValidFiles] = useState();
    const [showMeshModal, setShowMeshModal] = useState(false)
    const [uploadStatus, setUploadStatus] = useState(false)
    const [meshValue, setMeshValue] = useState()
    const [dracoValue, setDracoValue] = useState()
    const progressInfosRef = useRef({ val: [] })
    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const [cancelsRequest, setCancelsRequest] = useState([]);

    const [httpLinkName, setHttpLinkName] = useState()
    const [activeCollapse, setActiveCollapse] = useState(['1']);
    const [showHttpLinkModal, setShowHttpLinkModal] = useState(false)
    const [httpLinkUrl, setHttpLinkUrl] = useState()


    const checkingFeatureRole = (type) => {
        if (!type) return false
        return adminStore.checkingFeatureRole(projectStore, type)
    }

    useEffect(() => {
        if (!dropArea) return;
        dropArea.addEventListener('drop', fileDrop, false);
    }, [])

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const clearData = () => {
        progressInfosRef.current.val = []; // clear progressInfosRef
        setProgressInfos({ val: [] }); // clear progressInfos
        setSelectedFiles([]) // clear selected files
        fileStore.setModalDrop3DView(false) // close progress bar modal
        setUploadStatus(false) // set state uploadStatus false
        setMeshValue() // clear state meshValue
        setDracoValue() // clear state dracoValue
        cancelsRequest.forEach((c) => c()) // cancel all requests if it is still available
        setCancelsRequest([]) //clear cancel request
        fileStore.setFilesReadyProcess([])
        setHttpLinkName(false)
        setHttpLinkUrl(false)
    }

    // event drop file
    const fileDrop = (e) => {
        preventDefault(e);
        var mousePosition = new Cartesian2(e.clientX, e.clientY);
        var location = clickPoint(viewer.current.cesiumElement, mousePosition)
        var cartesian = location.position
        if (cartesian) {
            var cartographic = Cartographic.fromCartesian(cartesian)
            projectStore.setCenterData({ cartographic }) //specify (xác định) điểm đặt model
            if (e.dataTransfer.types.includes('text/uri-list')) {
                setHttpLinkUrl(e.dataTransfer.getData('URL'))
                setShowHttpLinkModal(true)
            } else {
                const files = e.dataTransfer.files;
                if (files.length) {
                    handleFiles(files);
                }
            }
        } else {
            message.error(t('drop-location-not-determined'));
        }
    }

    useEffect(() => {
        if (!httpLinkName || !httpLinkUrl) return;
        addDocumentsHttpLinks(httpLinkName, httpLinkUrl)
        setHttpLinkName()
        setHttpLinkUrl()

    }, [httpLinkName])


    // drag drop http links
    const addDocumentsHttpLinks = async (name, url) => {
        let _file = { _id: uuid(), name: name, url: url, hash: '', ext: 'httplinks' }
        let _progressInfos = [{ file: _file, percentage: 100, isComplete: false, isCancel: false, isFailed: false, isUnsupported: false }];
        progressInfosRef.current = { val: [...progressInfosRef.current.val, ..._progressInfos] }
        // set state progressInfos
        setProgressInfos({
            val: [
                ...progressInfos.val,
                ..._progressInfos //add new array file upload
            ]
        })

        // check linces and totalQuota remaining        
        if (projectStore.projectDetail.organization && projectStore.projectDetail.organization.licenses && projectStore.projectDetail.organization.licenses.length > 0) {
            const licenseInfos = projectStore.getCurrentProjectStorageQuota(projectStore.projectDetail.organization)
            if (licenseInfos.totalQuota === 0) {
                clearData()
                message.error(t('your-license-is-expried-please-update-your-license'))
                projectStore.setLoadingProgress(false)
                return;
            }
        } else {
            clearData()
            message.error(t('you-do-not-have-license-please-update-license'))
            projectStore.setLoadingProgress(false)
            return;
        }

        projectStore.setLoadingProgress(false)

        // set state show modal progress upload file            
        fileStore.setModalDrop3DView(true)
        if (fileStore.filesReadyProcess.length > 0) {
            await waitUntilPromiseFinish() // for case drop files when upload still in process
        }
        fileStore.setFilesReadyProcess([_file])

        let idx = progressInfosRef.current.val.findIndex(x => x.file._id === _file._id);
        const promisesUploadS3 = await createModelUploadIon(idx, _file, projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null)
        await PromiseBlue.each([promisesUploadS3], async (item) => {
            if (item) {
                await processModel(item.idx, item.model)
            }
        }).catch(function (rejection) {
            console.log("Catch: " + rejection);
        });
        fileStore.setFilesReadyProcess([])
    }

    const handleFiles = (files) => {
        let filelist = [];
        for (let i = 0; i < files.length; i++) {
            if (!validateFile(files[i])) {
                files[i]['invalid'] = true;
            }
            filelist.push(files[i])
        }
        setSelectedFiles(prevArray => [...prevArray, ...filelist]);
    }

    /**
     * Check valid file type drop
     * @param {*} file 
     * @returns 
     */
    const validateFile = (file) => {
        const acceptFileType = ['xml', 'ifc', 'gltf', 'glb', 'zip', 'obj', 'dae', 'laz', 'fbx', 'las', 'png', 'jpg', 'jpeg', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'tif', 'tiff', 'kmz', 'kml', 'geojson'];
        let _filetype = fileType(file.name).toString().toLowerCase();
        if (acceptFileType.indexOf(_filetype) === -1) {
            return false;
        }
        return true;
    }

    /**
     * Get file type
     * @param {*} fileName 
     * @returns 
     */
    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }

    // effect check duplicate file
    useEffect(() => {
        if (selectedFiles.length > 0) {
            let filteredArr = selectedFiles.reduce((file, current) => {
                const x = file.find(item => item.name === current.name);
                // if file has property hash (file was uploaded but canceled or invalid )
                if (current._id) {
                    return file; // not add validfiles
                } else {
                    if (!x) {
                        return file.concat([current]); // add to validfiles
                    } else {
                        return file; // not add validfiles
                    }
                }
            }, []);

            setValidFiles([...filteredArr]);
        }
    }, [selectedFiles])

    // effect check meshmodal file
    useEffect(() => {
        if (validFiles && validFiles.length > 0) {
            const outputFileType = [];
            for (var i = 0, f; f = validFiles[i]; i++) {
                let fileType = f.name.slice(f.name.length - 4)
                fileType = fileType ? fileType.toLowerCase() : ''
                outputFileType.push(fileType)
            }

            const fileTypeCheck = ['.obj', '.gltf', '.glb', '.zip', '.dae', '.fbx']
            const found = fileTypeCheck.some(r => outputFileType.indexOf(r) >= 0)
            //if mesh model
            if (found) {
                //set state show meshmodel dialog confirm some option
                setShowMeshModal(true)
            } else {
                // set state uploadStatus
                setUploadStatus(true)
            }
        }
    }, [validFiles])

    useEffect(() => {
        let height = fileStore.modalDrop3DView ? 55 : 0;
        if (selectedFiles?.length > 0 && activeCollapse?.length > 0) {
            height = height + 62 * (selectedFiles.length >= 4 ? 4 : selectedFiles.length);
        }
        fileStore.setHeightModal(height);
    }, [fileStore.modalDrop3DView, selectedFiles, activeCollapse]);

    // effect uploadStatus change
    useEffect(() => {
        if (validFiles && uploadStatus) {
            projectStore.setLoadingProgress(true)
            // "percentage" variable percent upload to s3, "isComplete" variable check create model and ready ion, "isCancel" cancel file, "isUnsupported" file not support 
            let _progressInfos = Array.from(validFiles).map(file => {
                return { file: file, percentage: 0, isComplete: false, isCancel: false, isFailed: false, isUnsupported: (file.invalid === true) }
            });

            progressInfosRef.current = { val: [...progressInfosRef.current.val, ..._progressInfos] }
            // set state progressInfos
            setProgressInfos({
                val: [
                    ...progressInfos.val,
                    ..._progressInfos //add new array file upload
                ]
            })

            // filter get only files valid
            let filterValidFile = Array.from(validFiles).map(file => {
                // add property _id for file, used to check if the file has been dragged and check the position of the file in the array
                file._id = uuid();
                return file;
            }).filter(item => {
                return !item.invalid
            });

            // call function generate presignedurl from s3
            generatePreSigned(filterValidFile)

            // reset state uploadStatus for continues drap drop other file
            setUploadStatus(false)
        }
    }, [uploadStatus]);

    /**
     * generate preSigned url from s3
     * @param {*} files array file need generate
     */
    const generatePreSigned = async (files) => {
        const startTime = Date.now();
        let fileUploadSize = 0;
        for (var i = 0; i < files.length; i++) {
            // calculate total size of all files        
            fileUploadSize += parseFloat(files[i].size) / 1024 / 1024;
        }

        if (fileUploadSize > Number.MAX_VALUE) {
            clearData()
            message.error(t('file-size-is-too-large'))
            projectStore.setLoadingProgress(false)
            return;
        }
        // check linces and totalQuota remaining        
        if (projectStore.projectDetail.organization && projectStore.projectDetail.organization.licenses && projectStore.projectDetail.organization.licenses.length > 0) {
            const licenseInfos = projectStore.getCurrentProjectStorageQuota(projectStore.projectDetail.organization)
            if (licenseInfos.totalQuota === 0) {
                clearData()
                message.error(t('your-license-is-expried-please-update-your-license'))
                projectStore.setLoadingProgress(false)
                return;
            }
        } else {
            clearData()
            message.error(t('you-do-not-have-license-please-update-license'))
            projectStore.setLoadingProgress(false)
            return;
        }

        // promise get presigned url from s3 return {file: filename, url: url signed, fileData: content file}
        const presignedS3Urls = await Promise.all(Array.from(files).map(async file => {
            let result = await projectStore.generatePreSignedPutUrl({ fileName: convertExtToLowerCase(file.name), projectId: projectStore.projectDetail._id })
            return {
                url: result.url,
                fileName: result.file,
                originalName: file.name,
                fileData: file,
                selectedNode: projectStore.selectedNode?.type === 'FOLDER' ? projectStore.selectedNode?.key : null
            }
        }))

        projectStore.setLoadingProgress(false)
        if (checkingFeatureRole("feature_data_tree_edit")) {
            fileStore.setModalDrop3DView(true);

            if (fileStore.filesReadyProcess.length > 0) {
                await waitUntilPromiseFinish();
            }

            fileStore.setFilesReadyProcess([presignedS3Urls]);

            const _sortpresigned = presignedS3Urls.sort((a, b) => parseFloat(a.fileData.size) - parseFloat(b.fileData.size));

            try {
                // Use Promise.allSettled to handle both successful and failed uploads concurrently
                const uploadResults = await Promise.allSettled(presignedS3Urls.map(async (filePresigned) => {
                    try {
                        const uploadedModel = await uploadToS3(filePresigned, startTime);
                        await processModel(uploadedModel.idx, uploadedModel.model);
                        return { success: true, data: uploadedModel };
                    } catch (error) {
                        return { success: false, error: error };
                    }
                }));

                // Check for any upload or processing failures
                const failedUploads = uploadResults.filter((result) => !result.status === 'fulfilled' || !result.value.success);

                if (failedUploads.length > 0) {
                    // Handle failed uploads (e.g., log errors)
                    console.error("Some uploads or processing tasks failed:", failedUploads);
                } else {
                    commonStore.loggingFunction(`All models uploaded and processed successfully`, '', new Date(), usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
                }

            } catch (error) {
                console.error('General error occurred:', error);
            } finally {
                fileStore.setFilesReadyProcess([]);
            }

        } else {
            clearData();
            message.error(t('you-do-not-have-permission'));
        }
    }

    async function waitUntilPromiseFinish() {
        return await new Promise(resolve => {
            const interval = setInterval(() => {
                if (fileStore.filesReadyProcess.length === 0) {
                    resolve();
                    clearInterval(interval);
                };
            }, 5000);
        });
    }

    /**
     * upload put file to s3 from presignedurl
     * @param {*} idx position file in array progressInfosRef.current.val
     * @param {*} presignedS3Url {file: filename, url: url signed, fileData: content file}
     */
    const uploadToS3 = async (presignedS3Url, startTime) => {
        // get state array files [{file: File, percentage: 0, isComplete: false, isCancel: false, isFailed: false, isUnsupport:},{...}]        
        let idx = progressInfosRef.current.val.findIndex(x => x.file._id === presignedS3Url.fileData._id);
        if (idx > -1) {
            progressInfosRef.current.val[idx].url = presignedS3Url.url;
            progressInfosRef.current.val[idx].fileName = presignedS3Url.fileName;
            progressInfosRef.current.val[idx].isCancel = false; // need init for case refresh upload
            progressInfosRef.current.val[idx].isFailed = false;

            var config = {
                method: 'put',
                url: presignedS3Url.url,
                headers: {
                    'Content-Type': getMimeOfFile(presignedS3Url.fileData.name),
                    'Access-Control-Allow-Origin': '*'
                },
                data: presignedS3Url.fileData,
                onUploadProgress: event => {
                    progressInfosRef.current.val[idx].percentage = Math.round(
                        (100 * event.loaded) / event.total
                    );
                    setProgressInfos({ val: progressInfosRef.current.val });
                },
                cancelToken: new CancelToken(function executor(c) {
                    setCancelsRequest(prev => [...prev, c]);  //set to array cancel token
                })
            };

            let file = presignedS3Url.fileData;
            let ext = presignedS3Url.fileName.split('.').pop().toLowerCase()
            file.hash = presignedS3Url.fileName.split('.').shift()
            file.ext = `.${ext}`
            file.url = `${ASSET_URL}${presignedS3Url.fileName}`
            file.id = `${ASSET_URL}${presignedS3Url.fileName}`
            commonStore.loggingFunction(`start upload model ${presignedS3Url.originalName} to s3`, 'start', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
            //put presignedurl
            return await axios(config)
                .then(async () => {
                    // after put completed file uploaded s3 then call create model and synce cesium ion
                    commonStore.loggingFunction(`finish upload model ${presignedS3Url.originalName} to s3`, 'finish', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
                    const lambdaStart = new Date()
                    commonStore.loggingFunction(`start create model ${presignedS3Url.originalName} `, 'start', lambdaStart, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
                    let _modelUpload = await createModelUploadIon(idx, file, presignedS3Url.selectedNode)
                    if (_modelUpload?.model?.name) {
                        commonStore.loggingFunction(`finish create model ${presignedS3Url.originalName} `, 'finish', lambdaStart, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
                    }
                    return _modelUpload;
                })
                .catch(error => {
                    if (progressInfosRef.current.val.length > 0) {
                        if (axios.isCancel(error) || error === undefined) {
                            progressInfosRef.current.val[idx].isCancel = true;
                        } else {
                            progressInfosRef.current.val[idx].isFailed = true;
                        }
                        progressInfosRef.current.val[idx].percentage = 0;
                        setProgressInfos({ val: progressInfosRef.current.val });
                        commonStore.loggingFunction('failed upload model', 'failed', startTime, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
                    }
                })
        }
    };

    /**
     * Create model and synce to ion
     * @param {*} idx position file in array progressInfosRef.current.val
     * @param {*} file 
     * @returns model
     */
    const createModelUploadIon = async (idx, file, selectedNode) => {
        // get progressInfosRef.current.val for upldate state file success or error
        let crs = {}
        let tilePosition = new Cartesian3.fromDegrees(0, 0)
        // neu co diem center man hinh                
        if (projectStore.centerData) {
            if (projectStore.centerData.cartographic.height < -1000) {
                crs.initPos = toJS(projectStore.centerData)
                crs.initPos.cartographic.height = 28
            } else
                crs.initPos = toJS(projectStore.centerData)
        } else {
            crs.initPos = {
                oldPos: tilePosition
            }
        }
        let newModel = {
            name: file.name ? file.name : 'Unnamed',
            src: file.url,
            hash: file.hash,
            modelType: meshValue,
            useDraco: dracoValue,
            data: {
                ext: file.ext,
                id: file.id,
                size: file.size,
            },
            project: projectStore.projectDetail._id,
            sourceType: 'local',
            crs,
            selectedKey: selectedNode
        }

        var pCrs = projectStore?.projectDetail?.tilesetData?.coordinateSystem ? projectStore?.projectDetail?.tilesetData?.coordinateSystem : '4326'
        var refPoint = projectStore?.projectDetail?.tilesetData?.RefPoint

        if (pCrs)
            newModel.data.refData = {
                epsg: pCrs.code,
                heightSystem: projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'
            }
        // we will use ifcSetting as tiling setting for both landxml and ifc        
        if (newModel.data.ext && ['.ifc', '.xml', '.las', '.laz'].includes(newModel.data.ext.toLowerCase())) {
            newModel.data.ifcSetting = projectSettingStore.systemProjectSetting.ifcSetting
        }

        // license type convert IFCEngineUsage
        if (projectStore.projectDetail.organization) {
            newModel.org = projectStore.projectDetail.organization._id
        }

        const refPointLat = newModel.crs.initPos.cartographic.latitude * Cesium.Math.DEGREES_PER_RADIAN;
        const refPointLng = newModel.crs.initPos.cartographic.longitude * Cesium.Math.DEGREES_PER_RADIAN;
        const _convert = conversProjectionLocal(refPointLng, refPointLat, "4326", pCrs?.code || '4326');
        // console.log('newModel.crs.initPos',toJS(newModel));
        if (_convert) {
            newModel.conversProjectionLocal = _convert;
            newModel.conversProjectionLocal.z = newModel.crs.initPos.cartographic.height;
        }
        return await projectStore
            .createModel(newModel)
            .then(async (model) => {
                if (model.crs['Use IFCEngine'] !== undefined && model.crs['Use IFCEngine'] === 'True') {
                    await create_ifcengineusage(projectStore.projectDetail.organization._id, projectStore.projectDetail._id, model, usersStore.currentUser._id)
                }
                if (model?.data?.ionAssetId) {
                    let isUpdateConten = await updateContenModelIon(model, file)
                    if (!isUpdateConten) {
                        progressInfosRef.current.val[idx].isFailed = true;
                        progressInfosRef.current.val[idx].percentage = 0;
                        setProgressInfos({ val: progressInfosRef.current.val });
                        notification.open({
                            message: t('an-error-occurred-when-creating-model-data'),
                            description: (
                                <>
                                    `${t('file')}`: <b>{newModel.name}</b><br />
                                    `${t('unable-update-ion-model-content')}`
                                </>
                            ),
                            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                            duration: 0,
                        })
                        return false
                    }
                }
                // update organization quota usage
                await projectStore.updateProjectStorage(projectStore.projectDetail._id)
                model.ref = false; //for case check load model (if true not reload)
                return { idx: idx, model: model }
            }).catch(err => {
                // update state progressInfos isFailed           
                progressInfosRef.current.val[idx].isFailed = true;
                progressInfosRef.current.val[idx].percentage = 0; //reset percentage
                setProgressInfos({ val: progressInfosRef.current.val });

                if (err && err.data && err.data.message) {
                    notification.open({
                        message: t('an-error-occurred-when-creating-model-data'),
                        description: (
                            <>
                                `${t('file')}`: <b>{newModel.name}</b><br />
                                {t(err.data.message)}
                            </>
                        ),
                        icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                        duration: 0,
                    })
                } else {
                    notification.open({
                        message: t('an-error-occurred-when-creating-model-data'),
                        description: (
                            <>
                                `${t('file')}`: <b>{newModel.name}</b><br />
                                {t('something-went-wrong-when-creating-model')}
                            </>
                        ),
                        icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                        duration: 0,
                    })
                }
            });
    };

    const processModel = async (idx, model) => {
        let _project = model.project
        const ellipsoidCheck = isEllipsoid(model);

        if (!ellipsoidCheck) {
            // Update Project tilesetdata and Model data return current model
            let addModelDate = new Date()
            commonStore.loggingFunction(`start add model ${model.name} to project`, 'start', addModelDate, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
            let rsp_model = await updateProjectModel(model.project, model)
            commonStore.loggingFunction(`finish add model ${model.name} to project`, 'finish', addModelDate, usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
            _project = rsp_model.project
        }

        // merge All model
        if (_project?.tilesetData?.RefPoint && !ellipsoidCheck && _project.tilesetData.coordinateSystem && (!['4326', '4756'].includes(_project.tilesetData.coordinateSystem.code) || _project.tilesetData.coordinateSystem.unit === 'metre')) {
            commonStore.loggingFunction(`start merge model ${model.name} to project`, 'start', new Date(), usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
            let _model3ds = _project.model3DS ? _project.model3DS.map(c => {
                let u = { ...c }
                if (c.id === model.id) {
                    u.newUpdate = true
                }
                return u;
            }) : []

            let modelmerge = await mergeAllModels(
                _model3ds,
                Cartesian3.fromDegrees(_project.tilesetData.RefPoint[1], _project.tilesetData.RefPoint[0], _project.tilesetData.RefPoint[2]),
                _project.tilesetData.refLocalProject ? Cartesian3.fromArray(_project.tilesetData.refLocalProject) : false,
                _project.tilesetData.georeferenced !== undefined ? _project.tilesetData.georeferenced : false,
                _project.headingRotation,
                _project.tilesetData.coordinateSystem.code,
                _project.elevationSystem ? _project.elevationSystem : 'None'
            );

            _project.model3DS = modelmerge // reassign list model to project
            commonStore.loggingFunction(`finish merge model ${model.name} to project`, 'finish', new Date(), usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
        }
        // update project TreeData
        commonStore.loggingFunction(`start add model ${model.name} to data tree`, 'start', new Date(), usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
        let _treeData = await updateProjectTreeData(_project._id, model, projectStore.projectDetail.treeData)
        commonStore.loggingFunction(`finish add model ${model.name} to data tree`, 'finish', new Date(), usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
        _project.treeData = _treeData

        if (ellipsoidCheck) {
            addNewEllipsoidModel(_treeData, projectStore.modelList, model);
        } else {
            /**Update FE projectStore.ProjectDetail */
            commonStore.loggingFunction(`start update project refPoint model ${model.name}`, 'finish', new Date(), usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
            await projectStore.updateProjectRefPoint(_project)
            commonStore.loggingFunction(`finish update project refPoint model ${model.name}`, 'finish', new Date(), usersStore.currentUser, projectStore?.projectDetail?.name, projectStore?.projectDetail?.organization?.name)
        }

        // effect projectStore.modelList only load model no model.ref => incorrect
        // after merge all models => position of model change => need set setNewModelId to effect updatePlaneMatix
        projectStore.setCurrentModelId(false)
        projectStore.setNewModelId(model._id)
        if (['ifc', 'landxml', 'landxmlBackground', 'cad'].includes(model.type)) {
            const { _id, hash, isObjectInforsReady, name } = model;
            const arrayModelProject = { _id, hash, isObjectInforsReady, name };
            objectQueryStore.checkObjectInforsReady(projectStore.projectDetail._id, { arrayModelProject })
        }

        // update status file uploaded
        progressInfosRef.current.val[idx].isComplete = true
        setProgressInfos({ val: progressInfosRef.current.val });
        if (model?.type === 'cloudpoint' && model?.status === "waitting_process") {
            message.success(t('data-process-background'));
        }
    }

    const genExtraCloseButtonProgressUpload = () => (
        <Button
            onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                clearData();
            }}
            type="text"
            shape="circle"
            icon={<CloseOutlined style={{ fontSize: '20px' }} />}
        />
    );

    const onChange = (key) => {
        setActiveCollapse(key);
    };

    return (
        <>
            <div className="modal-container" />
            <Modal
                className="antdModalProcessUploadFile"
                mask={false}
                maskClosable={false}
                title=""
                style={{ top: 'auto', bottom: 0, paddingBottom: '20px', right: 60, position: 'absolute' }}
                visible={fileStore.modalDrop3DView}
                closable={false}
                footer={null}
                width={400}
                getContainer={() => {
                    return document.getElementsByClassName("modal-container")[0];
                }}
            >
                <CollapseBody  >

                    <Collapse onChange={onChange} expandIcon={({ isActive }) => isActive ? <DownOutlined /> : <UpOutlined />} defaultActiveKey={['1']}>
                        <Panel
                            header={
                                progressInfos.val.some(file => file.isCancel === false && file.isFailed === false && file.isUnsupported === false && (file.percentage < 100 || file.isComplete === false)) ? (
                                    t('uploading-length-of-total-files', { length: progressInfos.val.filter(file => file.percentage === 100).length, total: progressInfos.val.length })
                                ) : !uploadStatus && progressInfos.val.some(file => file.isCancel === true || file.isFailed === true || file.isUnsupported === true) ? (
                                    t('length-of-total-files-uploaded', { length: progressInfos.val.filter(file => file.percentage === 100 && file.isComplete === true).length, total: progressInfos.val.length })
                                ) : !uploadStatus && progressInfos.val.filter(file => file.isComplete === true && !file.isCancel && !file.isFailed && !file.isUnsupported).length === progressInfos.val.length ? (
                                    t('upload-complete')
                                ) : null
                            }
                            key="1"
                            extra={genExtraCloseButtonProgressUpload()}
                        >
                            <Scrollbars autoHeight autoHeightMax={249}>
                                {progressInfos && progressInfos.val.length > 0 &&
                                    progressInfos.val.map((progressInfo, index) => (
                                        <div className='row-body' key={index} >
                                            <div className="row-center row-distribute">
                                                <div className="flex-row row-distribute flex-1">
                                                    <div className="flex-1 text-ellipsis">
                                                        <p className="text-ellipsis">{progressInfo.file.name}</p>
                                                        {
                                                            progressInfo.isCancel ? (
                                                                <div className="small text-ellipsis"><span className="text-bold">{t('canceled')}</span></div>
                                                            ) : progressInfo.percentage === 100 && !progressInfo.isComplete ? (
                                                                <div className="small text-ellipsis"><span className="text-bold">{t('syncing')}</span></div>
                                                            ) : progressInfo.isFailed ? (
                                                                <div className="small text-ellipsis"><span className="text-bold">{t('failed')}</span></div>
                                                            ) : progressInfo.isUnsupported ? (
                                                                <div className="small text-ellipsis"><span className="text-bold">{t('unsupported-format')}</span></div>
                                                            ) : null
                                                        }
                                                    </div>
                                                    {progressInfo.file.size ? <div className="text-muted">{bytesToSize(progressInfo.file.size)}</div> : ''}
                                                </div>
                                                {
                                                    progressInfo.isFailed ? (
                                                        <div className="flex-row"><InfoCircleOutlined style={{ minWidth: '40px', fontSize: '20px', color: '#c81922' }} /></div>
                                                    ) : progressInfo.isUnsupported ? (
                                                        <div className="flex-row"><InfoCircleOutlined style={{ minWidth: '40px', fontSize: '20px', color: '#c81922' }} /></div>
                                                    ) : progressInfo.percentage === 100 && progressInfo.isComplete ? (
                                                        <div className="flex-row"><span style={{ minWidth: '40px', textAlign: 'center' }}><SVGIcon content={<CloudDone />} width={22} height={22} color={'#72a544'} /></span></div>
                                                    ) : progressInfo.percentage === 100 && !progressInfo.isComplete ? (
                                                        <div className="flex-row"><SyncOutlined style={{ minWidth: '40px', fontSize: '18px', color: '#F26524' }} spin /></div>
                                                    ) : null
                                                }
                                            </div>
                                            {progressInfo.percentage === 100 || progressInfo.isCancel || progressInfo.isFailed || progressInfo.isUnsupported ? '' : <Progress trailColor={'#d9d9d9'} percent={progressInfo.percentage} showInfo={false} />}
                                        </div>
                                    ))}
                            </Scrollbars>
                        </Panel>
                    </Collapse>
                </CollapseBody>
            </Modal>
            <MeshModal
                setMeshValue={setMeshValue}
                setDracoValue={setDracoValue}
                setShowMeshModal={setShowMeshModal}
                setUploadStatus={setUploadStatus}
                showMeshModal={showMeshModal}
                setValidFiles={setValidFiles}
                setSelectedFiles={setSelectedFiles}
            />
            <HttpLinkNameModal
                setHttpLinkName={setHttpLinkName}
                setShowHttpLinkModal={setShowHttpLinkModal}
                showHttpLinkModal={showHttpLinkModal}
            />
        </>
    )
}

export default inject('projectStore', 'adminStore', 'fileStore', 'projectSettingStore', 'usersStore', 'commonStore', 'objectQueryStore')(observer(DropMultipleFiles3DView))