import { Color, PolylineDashMaterialProperty } from 'cesium'
import { InfoCircleOutlined } from '@ant-design/icons'
import { notification, message } from 'antd'
import { default as objectQueryStore } from '../../../../../stores/objectQueryStore'
import projectGanttStore from '../../../../../stores/projectGanttStore'
import Axios from 'axios'
import projectStore from '../../../../../stores/projectStore'
import { toJS } from 'mobx'
import { isEmpty } from 'lodash'
import { t } from 'i18next'

// check XDTWIN-3188 to see if remember caching queries is stable
let listAlignmentGuid = []
let alignmentCachingObject = []
let isObjectInforsReady = false

/**
 * Unhighlight the alignment and remove show alignment
 * @param {*} features: Array[]
 * @returns
 */
const unHighlighXMLAlignment = features => {
  if (!features || !features.length) {
    return
  }
  features.map(feature => {
    if (feature.polyline) {
      feature.polyline.material = getColorAlignment(
        feature,
        false,
        feature?.originalColor || '#ffffff'
      )
      feature.polyline.show = true
    }
  })
  listAlignmentGuid = []
}

/**
 * Clear all the features from the list and back color to the default
 * @param {*} viewer
 * @param {*} clearObject Array[]
 * @returns
 */
export const onClearAlignmentCachingObject = (viewer, clearObject) => {
  if (viewer) {
    if (!alignmentCachingObject || !alignmentCachingObject?.length) return
    alignmentCachingObject.forEach(
      features =>
        features?.listAlignmentfeatureResults &&
        unHighlighXMLAlignment(features?.listAlignmentfeatureResults)
    )
  }
  // comment this line for allow caching
  if (clearObject) {
    alignmentCachingObject = []
  }
  projectGanttStore.setSavedQueryCachingObject([])
}

/**
 * Set color for the alignment and return the material color
 * @param {*} feature Entity
 * @param {*} type String | Boolean
 * @param {*} originalColor String
 * @param {*} style Color Object {color: string, alpha: number}
 * @returns
 */
const getColorAlignment = (feature, type, originalColor, style) => {
  let color = null
  if (type) {
    const { color: colorQuery, alpha } = style
    color = Color.fromCssColorString(colorQuery || '#FFFFFF').withAlpha(alpha)
  } else {
    color = Color.fromCssColorString(originalColor)
  }
  const AlignmentHorCurve = new PolylineDashMaterialProperty({
    color: Color.clone(color, feature.color),
  })
  return AlignmentHorCurve
}

/**
 * Loop through features, change color and hide/show
 * @param {*} viewer
 * @param {*} feature Entity
 * @param {*} type String | Boolean
 * @param {*} style Color Object {color: string, alpha: number}
 * @param {*} isShow Boolean
 */
const highlightSingleXMLAlignment = (viewer, feature, type, style, isShow) => {
  const originalColor = !type ? feature?.originalColor || '#ffffff' : undefined
  if (feature?.polyline && feature?.polyline?.material) {
    feature.polyline.material = getColorAlignment(
      feature,
      type,
      originalColor,
      style
    )
  }
  if (feature?.polyline) {
    feature.polyline.show = isShow
  }
  if (viewer.scene.requestRenderMode) {
    viewer.scene.requestRender()
  }
}

/**
 *
 * @param {*} viewer
 * @param {*} features Array[]
 * @param {*} isShow Boolean
 * @returns
 */
const highlighXMLAlignment = (viewer, features, isShow) => {
  if (!features?.length) return
  features.forEach(feature => {
    const { _id, modelId } = feature
    const isExistFeature = alignmentCachingObject.filter(
      obj =>
        obj?.style !== '#ffffff' &&
        obj?.listAlignmentfeatureResults?.some(
          entity => entity?._id === _id && entity?.modelId === modelId
        )
    )[0]
    if (isExistFeature) {
      const { style, isHighlight, isShow } = isExistFeature
      highlightSingleXMLAlignment(viewer, feature, isHighlight, style, isShow)
    } else {
      highlightSingleXMLAlignment(viewer, feature, false, {}, isShow)
    }
  })
}

/**
 * Store highlightSaveQuery data to Store
 * @param {*} highlightSaveQuery Object
 * @param {*} color String
 * @param {*} alpha Number
 * @param {*} isShow Boolean
 */
const handleListHighlightSavedQueryObjectStyle = (
  highlightSaveQuery,
  color,
  alpha,
  isShow,
  isHighlight
) => {
  const styles = { color, alpha };
  const existingStyles = projectGanttStore.highlightSavedQueryObjectStyles || [];
  const existingIndex = existingStyles.findIndex(sq => sq?.id === highlightSaveQuery?.id);
  if (existingIndex !== -1) {
    // Update existing item
    existingStyles[existingIndex] = {
      ...highlightSaveQuery,
      style: styles,
      isShow,
      isHighlight,
    };
  } else {
    // Add new item
    existingStyles.push({
      ...highlightSaveQuery,
      style: styles,
      isShow,
      isHighlight,
    });
  }
  projectGanttStore.setHighlightSavedQueryObjectStyles(existingStyles);
}

/**
 * Store highlight alignment data to Store
 * @param {*} highlightSaveQuery Object
 * @param {*} color String
 * @param {*} alpha Number
 * @param {*} isShow Boolean
 * @param {*} isHighlight Boolean
 */
const handleAlignmentObjectStyle = (
  highlightSaveQuery,
  color,
  alpha,
  isShow,
  isHighlight
) => {
  if (alignmentCachingObject?.length > 0) {
    if (alignmentCachingObject.some(sq => sq?.id === highlightSaveQuery?.id)) {
      alignmentCachingObject = alignmentCachingObject.map(sq =>
        sq?.id === highlightSaveQuery?.id
          ? {
              ...highlightSaveQuery,
              style: { color, alpha },
              isShow,
              isHighlight,
            }
          : sq
      )
    } else {
      alignmentCachingObject = [
        ...alignmentCachingObject,
        {
          ...highlightSaveQuery,
          style: { color, alpha },
          isShow,
          isHighlight,
        },
      ]
    }
  } else {
    alignmentCachingObject = [
      {
        ...highlightSaveQuery,
        style: { color, alpha },
        isShow,
        isHighlight,
      },
    ]
  }
}

/**
 * Handle string JSON to Object
 * @param {*} value
 * @returns
 */
const getTreeInit = value => {
  if (!value) return {}
  try {
    if (typeof value === 'string') {
      value = value?.replace(/\\"/g, '"')
      return JSON.parse(value)
    } else {
      return JSON.parse(JSON.stringify(value))
    }
  } catch (error) {
    return {}
  }
}

/**
 * 
 * @param {*} id 
 * @param {*} cachingSaveQuery Object
 */
const handleCacheSavedQuery = (id, cachingSaveQuery) => {
  // Kiểm tra tham số đầu vào
  if (!id || !cachingSaveQuery) {
    console.warn('Missing required parameters');
    return;
  }

  const { savedQueryCachingObject = [] } = projectGanttStore;
  const idSet = new Set(savedQueryCachingObject.map(sq => sq.id));
  
  if (!idSet.has(id)) {
    projectGanttStore.setSavedQueryCachingObject([
      ...savedQueryCachingObject,
      cachingSaveQuery
    ]);
  }
};

/**
 * Handle highlighting all queries
 * @param {*} viewer
 * @param {*} savedQuery Original query
 * @param {*} color String
 * @param {*} alpha Number
 * @returns
 */
export const getListGuidHighlight = async (
  viewer,
  savedQuery,
  color,
  alpha
) => {
  if (!savedQuery) return
  const { id, src: srcQuery, isHighlight, isShow, queryParameters } = savedQuery
  const projectId = projectStore?.projectDetail?.id
  if (!projectId) return

  let isExistSaveQuery

  if (alignmentCachingObject?.length > 0) {
    const isExistAlignment = alignmentCachingObject.find(
      alignment => alignment.id === id
    )
    if (isExistAlignment) {
      handleAlignmentObjectStyle(
        isExistAlignment,
        color,
        alpha,
        isShow,
        isHighlight
      )
      const { listAlignmentfeatureResults } = isExistAlignment
      highlighXMLAlignment(viewer, listAlignmentfeatureResults, isShow)
    }
  }

  if (projectGanttStore.savedQueryCachingObject?.length > 0) {
    isExistSaveQuery = projectGanttStore.savedQueryCachingObject.find(
      sq => sq?.id === id && sq?.isShow === isShow
    )
  }

  if (viewer.scene?.primitives?._primitives?.length > 0) {
    if (!isObjectInforsReady) {
      let checkResult = await objectQueryStore
        .checkObjectInforsReady(projectId)
        .catch(err => {
          return err
        })
      if (checkResult?.status === undefined) {
        if (
          !checkResult ||
          checkResult?.filter(x => x?.isObjectInforsReady === false).length > 0
        ) {
          notification.open({
            message: t('database-indexing-is-still-running-for-new-objects'),
            icon: <InfoCircleOutlined style={{ color: '#faad14' }} />,
          })
          return
        }
      }
    }

    isObjectInforsReady = true

    if (isExistSaveQuery) {
      handleListHighlightSavedQueryObjectStyle(
        isExistSaveQuery,
        color,
        alpha,
        isShow,
        isHighlight
      )
      return isExistSaveQuery
    }

    let res = {}

    if (srcQuery) {
      const response = await Axios.get(srcQuery)
      res = response.data
    } else if (queryParameters?.queryExpression) {
      const treeJS = getTreeInit(queryParameters?.queryExpression)
      if(isEmpty(treeJS)) return
      const params = {
        queryExpression: treeJS,
      }
      res = await objectQueryStore
        .getListGuidHighlight(projectId, params)
        .catch(err => {
          const cachingSaveQuery = {
            id,
            listNewFeature: {},
            style: { color, alpha },
            isShow,
          }
          handleCacheSavedQuery(id, cachingSaveQuery)
        })
      objectQueryStore.update(id, { isReset: false, src: res?.src, type: 'multiple', 'queryParameters.queryExpression': JSON.stringify(treeJS) }).catch(err => console.log(err))
    }

    if (res?.listGuid) {
      if (viewer.scene.requestRenderMode) {
        viewer.scene.requestRender()
      }

      let listNewFeature = {}
      let listAlignmentfeature = []
      let listAlignmentfeatureResults = []
      res.listGuid.map(item => {
        if (item.guid && item.modelId) {
          if (!listNewFeature[item.modelId]) {
            listNewFeature[item.modelId] = {}
          }
          listNewFeature[item.modelId][item.guid] = 'hightlight'
          const _alignment = projectStore.alignmentFeatures.find(
            c => c.modelId === item.modelId && c._id === item?.guid
          )
          if (_alignment) {
            _alignment.guid = item.guid
            const originalColor = _alignment.polyline.material
              .getValue()
              .color.toCssHexString()
            if (!_alignment.originalColor) {
              _alignment.originalColor = originalColor
            }
            listAlignmentfeatureResults.push(_alignment)
            listAlignmentfeature.push(_alignment)
          }
        }
      })
      const cachingSaveQuery = {
        id,
        listNewFeature,
        style: { color, alpha },
        isShow,
      }

      handleCacheSavedQuery(id, cachingSaveQuery)
      highlighXMLAlignment(viewer, listAlignmentfeatureResults, isShow)
      const alignmentObject = {
        id,
        listAlignmentfeatureResults,
        style: { color, alpha },
        isShow,
        isHighlight,
      }

      handleAlignmentObjectStyle(
        alignmentObject,
        color,
        alpha,
        isShow,
        isHighlight
      )

      listAlignmentGuid = listAlignmentfeature
      handleListHighlightSavedQueryObjectStyle(
        cachingSaveQuery,
        color,
        alpha,
        isShow,
        isHighlight
      )
      return cachingSaveQuery
    }
  }
}
/**
 * Highlight query Wrapper
 * @param {*} savedQuery Original query
 * @param {*} tileViews
 * @param {*} color String
 * @param {*} alpha Number
 * @param {*} viewer
 */
export const applySavedQueryHighlight = async (
  savedQuery,
  color = '#ff0000',
  alpha = 0.5,
  viewer
) => {
  await getListGuidHighlight(viewer, savedQuery, color, alpha)
}
