import { AutoComplete, Button, Checkbox, Form, Input, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import countryList from 'react-select-country-list'
import validator from '@/validator'
import { assetUrl } from '@/config'
import { NaviLink } from './CustomStyled'
import { useTranslation } from 'react-i18next';
import { renderTermsPolicyHref } from '@/lib/projectLib'

const RegisterForm = ({ history, match, authStore, adminStore, organizationStore }) => {
  const { t } = useTranslation();
  const authType = match.params.authType

  const CustomForm = props => {
    const [form] = Form.useForm();
    const [confirmDirty, setConfirm] = useState(false)
    const optionCountries = useMemo(() => countryList().getData(), [])
    const countrys = useMemo(() => optionCountries.map(item => { return { value: item.label } }), [optionCountries])
    const [country, setCountry] = useState()
    const [data, setData] = useState({})

    useEffect(() => {
      if (organizationStore.invitationDetail.email) {
        let _invitation = organizationStore.invitationDetail
        form.setFieldsValue({ email: _invitation.email })
      }
      return () => {
        organizationStore.clearInvitationDetail()
      }
    }, [organizationStore.invitationDetail])

    const getData = () => {
      fetch(`${assetUrl}maintananceInfo.json`
        , {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          setData(myJson)
        });
    }
    useEffect(() => {
      getData()
    }, [])


    const handleSubmit = values => {
      if (!values.accept || !values.policyandterms) {
        message.error(t('please-read-and-accept-terms'))
        return
      }

      let submitValues = {
        username: values.username.trim().toLowerCase(),
        email: values.email.trim().toLowerCase(),
        password: values.password,
        confirmed: false,
        organizationName: values.organization,
        address: values.orgAdress,
        city: values.orgCity,
        country: values.orgCountry,
        zipCode: values.orgZIPCode,
        phone: values.orgPhone,
      }

      if (organizationStore.invitationDetail.email) {
        if (values.email !== organizationStore.invitationDetail.email) {
          message.error(t('email-not-same'))
          return
        } else {
          submitValues.invitationId = organizationStore.invitationDetail.id
        }
      }
      authStore.userRegister(submitValues, history)
    }

    const compareToFirstPassword = (rule, value, callback) => {
      if (value && value !== form.getFieldValue('password')) {
        callback(t('passwords-do-not-match'))
      } else {
        callback()
      }
    }

    const handleConfirmBlur = (e) => {
      const value = e.target.value
      setConfirm(confirmDirty || !!value)
    }


    const onChangeCountry = (data) => {
      setCountry(data)
    }

    return (
      <Form
        form={form}
        name="register-form"
        layout="vertical"
        className={`auth-form auth-form-${authType}`}
        onFinish={handleSubmit}
      >
        <Form.Item label={t('username')} name="username" rules={[
          {
            required: true,
            message: t('please-input-your-username'),
          },
          { validator: validator.validateEmptyString },
        ]}>
          <Input placeholder={t('enter-your-username')} />
        </Form.Item>
        <Form.Item label={t('email')}
          name="email"
          initialValue={organizationStore.invitationDetail && organizationStore.invitationDetail.email ? organizationStore.invitationDetail.email : ''}
          rules={[
            {
              required: true,
              message: t('please-input-your-email'),
            },
            { validator: validator.validateEmail },

          ]}>
          <Input disabled={organizationStore.invitationDetail && organizationStore.invitationDetail.email ? true : false} placeholder={t('enter-your-email')} />
        </Form.Item>
        <Form.Item label={t('password')} name="password" rules={[
          {
            required: true,
            message: t('please-input-your-password'),
          },
        ]}>
          <Input type="password" placeholder={t('enter-password')} />
        </Form.Item>
        <Form.Item label={t('confirm-password')} name="confirm" rules={[
          {
            required: true,
            message: t('please-re-type-your-password'),
          },
          { validator: compareToFirstPassword },
        ]}>
          <Input type="password" placeholder={t('re-type-password')} onBlur={handleConfirmBlur} />
        </Form.Item>
        <Form.Item label={t('organization')} name="organization" rules={[
          {
            required: true,
            message: t('please-input-an-organization'),
          },
        ]}>
          <Input placeholder={t('enter-an-organization')} />
        </Form.Item>
        <Form.Item
          label={t('address')}
          name="orgAdress"
          rules={[
            {
              required: true,
              message: t('please-input-organization-address'),
            },
          ]}
        >
          <Input placeholder={t('enter-address')} />
        </Form.Item>
        <Form.Item
          label={t('zip-code')}
          name="orgZIPCode"
          rules={[
            {
              required: true,
              message: t('please-input-organization-zip-code'),
            },
          ]}
        >
          <Input placeholder={t('enter-zip-code')} />
        </Form.Item>
        <Form.Item
          label={t('city')}
          name="orgCity"
          rules={[
            {
              required: true,
              message: t('please-input-organization-city'),
            },
          ]}
        >
          <Input placeholder={t('enter-city')} />
        </Form.Item>
        <Form.Item
          label={t('country')}
          name="orgCountry"

          rules={[
            {
              required: true,
              message: t('please-input-organization-country'),
            },
          ]}
        >
          <AutoComplete
            placeholder={t('enter-country')}
            value={country}
            showArrow={true}
            showSearch={true}
            filterOption={(input, option) =>
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={countrys}
            onChange={onChangeCountry}
          >

          </AutoComplete>
        </Form.Item>
        <Form.Item
          label={t('phone')}
          name="orgPhone"
          rules={[
            {
              required: false,
              message: t('please-input-organization-phone'),
            },
          ]}
        >
          <Input type="number" placeholder={t('enter-phone-number')} />
        </Form.Item>
        <Form.Item name="accept" valuePropName="checked" initialValue={false}>
          <Checkbox>{t('have-read')} <a target='_blank' href={renderTermsPolicyHref(data,'terms')} style={{ color: '#ff8d4f' }}>{t('terms-and-conditions')}</a></Checkbox>
        </Form.Item>
        <Form.Item name="policyandterms" valuePropName="checked" initialValue={false}>
          <Checkbox>{t('have-read')} <a target='_blank' href={renderTermsPolicyHref(data,'policy')} style={{ color: '#ff8d4f' }}>{t('privacy-policy')}</a></Checkbox>
        </Form.Item>
        <Button size={'large'} block type={'primary'} htmlType={'submit'}>
          {t('register')}
        </Button>
        <NaviLink margin={'10px 0 0'}>
          {t('already-have-an-account')}
          <Link to={'/auth/login'} style={{ color: '#ff8d4f' }}>
            {t('back-to-login-page')}
          </Link>
        </NaviLink>
      </Form>
    )
  }

  return <CustomForm />

}

export default withRouter(inject('authStore', 'adminStore', 'organizationStore')(observer(RegisterForm)))