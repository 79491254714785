import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Empty, Modal, Tree } from 'antd'
import { convertKeyToModel, convertModelToKey } from '../Gantt/ganttUtils'
import { useTranslation } from 'react-i18next'
const { DirectoryTree } = Tree

const isModel = (node) => {
  return node && node.modelId ? true : false;
};

const isFolder = (node) => {
  return node && node.type === 'FOLDER' ? true : false;
};

const is3DCityDB = (node) => {
  return isModel(node) && node.sourceType === 'City3DDB';
};

const isWMS = (node) => {
  return isModel(node) && (node.sourceType === 'WMS' || node.sourceType === 'WFS');
};

const isLeafNode = (node) => {
  if (!node) return true;
  return !(
    (isFolder(node) && node.children?.length) ||
    (isModel(node) &&
      (node.modelType === 'ifc' ||
        node.modelType === 'cad' ||
        node.modelType === 'landxml' ||
        node.modelType === 'landxmlBackground' ||
        node.modelType === 'external-plugin')) ||
    isWMS(node) ||
    is3DCityDB(node)
  );
};

const processTreeData = (data, parentKey = null) => {
  return data.map((item) => {
    let updatedItem = {
      ...item,
      parentKey,
      isLeaf: isLeafNode(item),
    };
    if (item.children) {
      updatedItem.children = processTreeData(item.children, item.key);
    }
    return updatedItem;
  });
};

const ModalLinkGanttToDataTree = ({ projectGanttStore, projectStore }) => {
  const { t } = useTranslation()
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [processedTreeData, setProcessedTreeData] = useState([]);

  useEffect(() => {
    const isModalOpen = projectGanttStore.isOpenModalSelectTreeData.open;
    if (isModalOpen && projectStore.projectDetail?.treeData?.length) {
      const restructuredData = processTreeData(projectStore.projectDetail.treeData);
      setProcessedTreeData(restructuredData);

      const initialSelectedKeys = convertModelToKey(
        projectStore.projectDetail.treeData,
        projectGanttStore.selectedNodeInDataTree
      );
      setSelectedKeys(initialSelectedKeys || []);
    }

    // Reset khi component unmount
    return () => setSelectedKeys([]);
  }, [
    projectGanttStore.isOpenModalSelectTreeData.open,
    projectGanttStore.selectedNodeInDataTree,
    projectStore.projectDetail?.treeData,
  ]);

  const handleCancel = () => {
    projectGanttStore.setIsOpenModalSelectTreeData({ open: false, taskId: '' });
  };

  const handleCheck = (checkedKeys) => {
    setSelectedKeys(checkedKeys);
  };

  const handleSave = () => {
    const selectedNodes = convertKeyToModel(
      projectStore.projectDetail?.treeData,
      selectedKeys
    );
    projectGanttStore.setSelectedNodeInDataTree(selectedNodes);
    projectGanttStore.setIsOpenModalSelectTreeData({ open: false, taskId: '' });
    setSelectedKeys([]);
  };


  return (
    <Modal
      title={t('gantt.select-4d-node-in-data-tree')}
      centered
      zIndex={10001}
      visible={projectGanttStore.isOpenModalSelectTreeData.open}
      okText={t('commons.save')}
      cancelText={t('commons.cancel')}
      onOk={handleSave}
      okButtonProps={{
        style: {
          display: projectGanttStore.isReadonlyMode ? 'none' : 'initial',
        },
      }}
      onCancel={handleCancel}
      style={{ maxHeight: 'calc(100vh - 20px)' }}
      bodyStyle={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}
    >
      {processedTreeData.length ? (
        <DirectoryTree
          multiple
          defaultExpandAll
          checkable
          checkedKeys={selectedKeys}
          treeData={processedTreeData}
          onCheck={handleCheck}
          disabled={projectGanttStore.isReadonlyMode}
        />
      ) : (
        <Empty />
      )}
    </Modal>
  );
};

export default inject(
  'projectGanttStore',
  'projectStore'
)(observer(ModalLinkGanttToDataTree))
