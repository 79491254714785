import { CloseOutlined, DeleteOutlined, EditOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Popconfirm, Row, Select, Skeleton, Table, Tooltip, message } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React,{ useEffect, useMemo, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { generateUsername } from '@/lib/projectLib'
import { CustomUserGroupModal, DrawerContentHeader, UserGroupWrapper } from './style'
import { toJS } from 'mobx'
const { Option } = Select

const DrawerUserGroup = ({ projectStore, projectTeamsStore, userGroupStore, drawerWidth, loading }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [formAddUser] = Form.useForm();
    const isTabletOrMobile = isMobile || isTablet
    const [loadingUserTable, setLoadingUserTable] = useState(false);
    const [loadingSelectionUser, setLoadingSelectionUser] = useState(false);
    const [userGroupDetail, setUserGroupDetail] = useState(false)
    const [opendModalAddGroup, setOpendModalAddGroup] = useState({open: false, mode: 0})
    const [opendModalAddUserToGroup, setOpendModalAddUserToGroup] = useState(false)
    const [editGroup, setEditGroup] = useState(false)

    const groupColumns = [
        {
            title: t('name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('joined'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (record) => (<span>{moment(record).format('MMMM Do YYYY, h:mm:ss a')}</span>)
        },
        {
            title: t('role'),
            dataIndex: 'role',
            key: 'role',
            render: (record)=> (<span>{record?.roleName}</span>)
        },
        {
            title: t('action'),
            dataIndex: 'action',
            key: 'action',
            width: '15%',
            align: 'center',
            render: (_, record) => (
                <div className='action-wapper'>
                    <Tooltip title={t('detail')} overlayStyle={ isTabletOrMobile ? { display: 'none' } : undefined} trigger={['click', 'hover']}>
                        <Button
                            icon={<FileSearchOutlined />}
                            onClick={() => handleOpenGroupDetail(record)}
                        />
                    </Tooltip>
                    {projectStore.currentUserRoleInProject === "project_owner" &&
                        <Tooltip title={t('commons.delete')} overlayStyle={ isTabletOrMobile ? { display: 'none' } : undefined} trigger={['click', 'hover']}>
                        <Popconfirm
                            title={t('confirm-delele-user-group')}
                            onConfirm={() => handleDeleteGroup(record?.id)}
                            okText={t('commons.yes')}
                            cancelText={t('commons.no')}>
                            <Button type="danger" icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Tooltip>}
                </div>
            )
        },
    ];

    ////
    const userInsideGroupColumns = [
        {
            title: t('user'),
            dataIndex: 'user',
            key: 'user',
            render: (record) =>{
                if(!record) return ''
                return record?.firstName && record?.lastName ?
                record.firstName + ' ' + record.lastName : !record?.firstName && record?.lastName ?
                    record.lastName : record?.firstName && !record?.lastName ?
                        record.firstName : record.username
            }
        },
        {
            title: t('email'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('action'),
            dataIndex: 'action',
            className: projectStore.currentUserRoleInProject !== "project_owner" && 'hide-comlumn',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                projectStore.currentUserRoleInProject === "project_owner" && 
                <Tooltip title={t('commons.delete')} overlayStyle={ isTabletOrMobile ? { display: 'none' } : undefined} trigger={['click', 'hover']}>
                    <Popconfirm
                        title={t('confirm-remove-user')}
                        onConfirm={() => handleDeleteUser(record?.id)}
                        okText={t('commons.yes')}
                        cancelText={t('commons.no')}>
                        <Button type="danger" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Tooltip>
            )
        },
    ]

    // open Group detail
    const handleOpenGroupDetail = async (groupDetail) =>{
        if(!groupDetail) return
        userGroupStore.setSelectedGroup(groupDetail)

        setUserGroupDetail(true)
        setLoadingUserTable(true)
        await userGroupStore.getAllMemberInGroup(groupDetail?.id).then(()=>{

        }).catch(err => {
                console.log(err)
                setLoadingUserTable(false)
            })
        setLoadingUserTable(false)
    }

    // delete user
    const handleDeleteUser = async (userId) =>{
        if(!userId){
            message.error(t('fail-to-remove-user'))
            return
        }
        projectStore.setLoadingProgress(true)
        await userGroupStore.deleteProjectUserGroupMember(userId).then(async () => {
            projectStore.setLoadingProgress(false)
            message.success(t('remove-user-success'))
        }).catch(error => {
            console.log(error)
            projectStore.setLoadingProgress(false)
            message.error(t('fail-to-remove-user'))
          })
    }

    useEffect(() => {
        return () => {
            onCloseUserGroup()
        }
    }, [projectTeamsStore.showProjectTeamsDrawer, projectStore.showUserGroupDrawer])

    useEffect(() => {
      if(!userGroupDetail) userGroupStore.setProjectMemberGroups([])
    }, [userGroupDetail])


    const userNotBelongGroups = useMemo(() => {
        let r = userGroupStore.listUserNotGroup.filter(item => item?.email !== "maintenance@xd-visuals.fi") || [];
        const users = userGroupStore?.selectedGroup?.users || [];
        const userIds = users.map(c => c.user);
        return r.filter(c => !userIds.includes(c?.user?._id)) ;
    }, [userGroupStore.selectedGroup ,userGroupStore.listUserNotGroup]
    );

    
    const closeGroupDetail = () => { 
        setLoadingUserTable(false)
        setUserGroupDetail(false)
        userGroupStore.setSelectedGroup({})
        userGroupStore.setProjectMemberGroups([])
    }

    const onCloseUserGroup = () => {
        setLoadingSelectionUser(false)
        setLoadingUserTable(false)
        setUserGroupDetail(false)
        setEditGroup(false)
        userGroupStore.setSelectedGroup({})  
    };

    const handleDeleteGroup = (id) => {
        if(!id){
            message.error(t("fail-to-delete-user-group")) 
            return 
        }
        projectStore.setLoadingProgress(true)
        userGroupStore.deleteProjectUserGroup(id).then(async () => {
            if(userGroupStore.selectedGroup?.id === id) closeGroupDetail()
            projectStore.setLoadingProgress(false)
            message.success(t('delete-user-group-success'))
        }).catch(error => {
            console.log(error)
            projectStore.setLoadingProgress(false)
            message.error(t("fail-to-delete-user-group"))
          })
    }

    const handleOnSubmitForm = () => {
        if (editGroup && opendModalAddGroup.mode === 1) {
            form.validateFields().then(values => {
                projectStore.setLoadingProgress(true)
                userGroupStore.updateProjectUserGroup(editGroup, {
                    name: values.groupName,
                    role: values.groupRole
                }).then(async () => {
                    if(userGroupStore.selectedGroup?.id){
                        if(userGroupStore.groups?.length) { 
                            userGroupStore.setSelectedGroup(userGroupStore.groups?.find(el => el?.id === editGroup))
                        }
                    }
                    projectStore.setLoadingProgress(false)
                    message.success(t('update-user-group-success'))
                    form.resetFields()
                    setOpendModalAddGroup({open: false, mode: 0})
                }).catch(error => {
                    console.log(error)
                    projectStore.setLoadingProgress(false)
                    message.error(t('fail-to-update-user-group'))
                  })
            })
        } else {
            form.validateFields().then(values => {
                projectStore.setLoadingProgress(true)
                userGroupStore.createProjectUserGroup({
                    name: values.groupName,
                    role: values.groupRole,
                    project: projectStore.projectDetail._id
                }).then(async () => {
                    projectStore.setLoadingProgress(false)
                    message.success(t('add-user-group-success'))
                    form.resetFields()
                    setOpendModalAddGroup({open: false, mode: 0})   
                }).catch(error => {
                    console.log(error)
                    projectStore.setLoadingProgress(false)
                    message.error(t('fail-to-create-user-group'))
                  })
                
            })
        }
    }


    const handleAddUserToGroup = () =>{
        if(!userGroupStore.selectedGroup?.id) {
            message.error(t('fail-to-add-user'))
            return}
        formAddUser.validateFields().then((values)=>{
            projectStore.setLoadingProgress(true)
            userGroupStore.createProjectUserGroupMember({
                user: values.user,
                email: userGroupStore.listUserNotGroup?.find(el => el?.user?._id === values.user).email || '',
                group: userGroupStore.selectedGroup?.id,
                project: projectStore.projectDetail._id
            }).then(async (res) => {
                projectStore.setLoadingProgress(false)
                if(res?.error === "Already invited") message.error(t('user-already-in-user-group'))               
                else message.success(t('add-user-success'))
                formAddUser.resetFields()
                setOpendModalAddUserToGroup(false)   
            }).catch(error => {
                console.log(error)
                projectStore.setLoadingProgress(false)
                message.error(t('fail-to-add-user'))
              })
        })
    }

    return (
        <DrawerContentHeader visible={projectStore.showUserGroupDrawer ? true : false}>
            <UserGroupWrapper>
                <Skeleton loading={loading} active>
                        {projectStore.currentUserRoleInProject === "project_owner" && 
                        <div className='user-group-header'>
                            <Button type='primary' icon={<PlusOutlined />} onClick={() => setOpendModalAddGroup({open: true, mode: 0})}>{t("add-user-group")}</Button>
                        </div>
                        }
                        <Row gutter={[16]}>
                            <Col xs={24} sm={24} md={drawerWidth <= 768 ? 24 : (userGroupDetail ? 12 : 24)} className='group-table-wrap'>
                                <Table scroll={{ x: 500, y: 750 }} pagination={false} columns={groupColumns} dataSource={userGroupStore.groups || []} rowKey={(record) => record.id}
                                    rowClassName={(record) => record?.id === userGroupStore.selectedGroup?.id ? 'active-row' : 'data-row'}
                                />
                            </Col>
                            {userGroupDetail && <Col xs={24} sm={24} md={drawerWidth <= 768 ? 24 : 12} className='group-detail'>
                                <div className='close-btn'>
                                    <span className='group-name'>{userGroupStore.selectedGroup?.name}</span>
                                    <CloseOutlined style={{ fontSize: 16 }} 
                                    onClick={() => closeGroupDetail()} />
                                </div>
                                {projectStore.currentUserRoleInProject === "project_owner" &&
                                    <div className='action-bar'>
                                        <Button
                                            icon={<EditOutlined />}
                                            onClick={() => {
                                                form.setFieldsValue({
                                                    groupName: userGroupStore.selectedGroup?.name,
                                                    groupRole: userGroupStore.selectedGroup?.role?.id || userGroupStore.selectedGroup?.role 
                                                })
                                                setEditGroup(userGroupStore.selectedGroup?.id)
                                                setOpendModalAddGroup({open: true, mode: 1})
                                            }}
                                            type='primary'>{t('commons.edit')}</Button>
                                        <Button type='primary' icon={<PlusOutlined />} onClick={() => {
                                            setLoadingSelectionUser(true)
                                            userGroupStore.getListUserNotGroup(projectStore.projectDetail.id).then(()=>{
                                                setLoadingSelectionUser(false)
                                            }).catch(err =>{
                                                console.log(err)
                                                setLoadingSelectionUser(false)
                                            })
                                            setOpendModalAddUserToGroup(true) 
                                            }}>{t('add-user')}</Button>
                                    </div>
                                }
                                <Table scroll={{ x: 400 }} loading={loadingUserTable} pagination={false} columns={userInsideGroupColumns} dataSource={userGroupStore.members || []} rowKey={(record) => record?.id} />
                            </Col>}
                        </Row>
                        <CustomUserGroupModal zIndex={9999} title={editGroup ? t("edit-user-group") : t("add-user-group")} visible={opendModalAddGroup.open}
                            onCancel={() => {
                                form.resetFields()
                                setOpendModalAddGroup({open: false, mode: 0})
                                setEditGroup(false)
                            }}
                            cancelText={t('commons.cancel')}
                            okText={t('commons.save')}
                            onOk={handleOnSubmitForm}
                        >
                            <Form
                                form={form}
                                layout="vertical"
                                autoComplete="off"
                            >
                                <Form.Item
                                    label={t('name')}
                                    name="groupName"
                                    style={{ marginBottom: 12 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('input-your-user-group-name'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={t("role")}
                                    name="groupRole"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('input-your-user-group-role'),
                                        },
                                    ]}
                                    style={{ marginBottom: 0 }}>
                                    <Select
                                       showSearch={true}
                                       optionFilterProp="children"
                                       filterOption={(input, option) =>
                                             (option?.label ?? '')
                                               .toLowerCase()
                                               .includes(input.toLowerCase())
                                           }
                                        options={
                                          Object.entries(projectTeamsStore.projectRoles)?.map(([key, val]) => 
                                            ({value: key, label: val?.roleName})) || []
                                        }
                                    />
                                </Form.Item>
                            </Form>
                        </CustomUserGroupModal>
                        <CustomUserGroupModal zIndex={9999} title={t('add-new-user')} visible={opendModalAddUserToGroup} okText={t('add-user')}
                            onCancel={() => {
                                formAddUser.resetFields()
                                setOpendModalAddUserToGroup(false)
                            }}
                            onOk={()=> handleAddUserToGroup()}
                        >
                            <Form
                                form={formAddUser}
                                layout="vertical"
                                autoComplete="off"
                            >
                                <Form.Item
                                    label={t("user")}
                                    name="user"
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('input-user'),
                                        },
                                    ]}
                                >
                                    <Select placeholder={'Select user'}
                                        loading={loadingSelectionUser}
                                    >
                                    {userNotBelongGroups.map((item) => {
                                            return (item.user ? < Option key={item.user?._id} value={item.user?._id}>
                                                {item.user ?generateUsername(item.user):''} </Option> : '')
                                        })}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </CustomUserGroupModal>
                </Skeleton>
            </UserGroupWrapper>
        </DrawerContentHeader>
    )
}

export default inject(
    'projectStore',
    'projectSettingStore',
    'projectTeamsStore',
    'userGroupStore'
)(observer(DrawerUserGroup))
