import { observable, action, decorate, toJS } from 'mobx'
import { Gannt4dRequest } from '@/requests'
import moment from 'moment'
class ProjectGanttStore {
  isLoading = false
  isShowGanttPanel = false
  projectGanttData = []
  isOpenModalSelectTreeData = {
    open: false,
    taskId: '',
  }
  selectedNodeInDataTree = []
  isLightboxOpen = false
  isGanttReady = false
  ganttMarkerList = []
  middleMarker = undefined
  todayMarker = []
  isShowCriticalPath = false
  isAutoScheduling = false
  undoCount = 0
  redoCount = 0
  isActiveUndo = false
  isActiveRedo = false
  isActive4dPlayer = true
  playerMode = 'dragDate'
  currentViewingTime = moment(new Date())
  currentColorPicker = '#ff0000'
  currentAlpha = '0.5'
  listHighlightSketch = []
  isPlay4d = false
  backOrForwardCount = 0
  isReadonlyMode = false
  gridColumnList = []
  isShowGrid = true
  isShowBaseline = false
  isOpenModalExportnImportProject = { type: 'import', open: false }
  importFile = undefined
  isUploading = false
  isOpenModalLinkSavedQuery = false
  savedQueryCachingObject = []
  selectedSavedQuery = []
  highlightSavedQueryObjectStyles = []
  showedModels = []
  highlightEnable = true
  playerSpeed = 1
  colorTaskEnable
  sketchDrawer = { task: '', isNewTask: true, sketchIds: [], open: false,  drawSketchType: '' }
  groupType = "task"
  filterTask = ''
  ganttDefaults
  showChart = true
  showColorTask = true
  isCondensed = false
  selectObjectModel = {task: '', type: '', listExist: [], open: false}
  zoomToFit = { status: false, isUpdate: true }
  zoomScale = { status: 'Days', isUpdate: true }
  showResource = false
  isCreateNewTask = false;

  setZoomScale = value => {
    this.zoomScale = value
  }

  setZoomToFit = value => {
    this.zoomToFit = value
  }

  // {task: taskId, type: "object", "model", listExist, open}
  setSelectObjectModel = value => {
    this.selectObjectModel = value
  }

  setShowedModels = payload => {
    this.showedModels = payload
  }

  setHighlightSavedQueryObjectStyles = payload => {
    this.highlightSavedQueryObjectStyles = payload
  }

  setSelectedSavedQuery = payload => {
    this.selectedSavedQuery = payload
  }

  setSavedQueryCachingObject = payload => {
    this.savedQueryCachingObject = payload
  }

  setIsOpenModalLinkSavedQuery = status => {
    this.isOpenModalLinkSavedQuery = status
  }

  setIsShowBaseline = status => {
    this.isShowBaseline = status
  }

  setIsShowGrid = status => {
    this.isShowGrid = status
  }

  setIsUploading = status => {
    this.isUploading = status
  }

  setImportFile = payload => {
    this.importFile = payload
  }

  setIsOpenModalExportnImportProject = status => {
    this.isOpenModalExportnImportProject = status
  }

  setIsReadonlyMode = status => {
    this.isReadonlyMode = status
  }

  setBackOrForwardCount = payload => {
    if (payload === 0 || payload) {
      this.backOrForwardCount = payload
      return
    }
    this.backOrForwardCount = this.backOrForwardCount + 1
  }

  setIsPlay4d = status => {
    this.isPlay4d = status
  }

  setGridColumnList = payload => {
    this.gridColumnList = payload
  }

  setCurrentAlpha = payload => {
    this.currentAlpha = payload
  }

  setListHighlightSketch = payload => {
    this.listHighlightSketch = payload
  }

  setCurrentColorPicker = payload => {
    this.currentColorPicker = payload
  }

  setCurrentViewingTime(value) {
    let date = moment(value)
    if (date.isValid()) {
      this.currentViewingTime = moment(value)
    } else {
      this.currentViewingTime = value
    }
  }
  setTodayMarker = payload => {
    this.todayMarker = payload
  }

  setMiddleMarker = payload => {
    this.middleMarker = payload
  }

  setPlayerMode = payload => {
    this.playerMode = payload
  }

  setIsActive4dPlayer = status => {
    this.isActive4dPlayer = status
  }

  setIsActiveUndo = status => {
    this.isActiveUndo = status
  }
  setIsActiveRedo = status => {
    this.isActiveRedo = status
  }

  setUndoCount = payload => {
    if (payload === 0 || payload) {
      this.undoCount = payload
      return
    }
    this.undoCount = this.undoCount + 1
  }
  setRedoCount = payload => {
    if (payload === 0 || payload) {
      this.redoCount = payload
      return
    }
    this.redoCount = this.redoCount + 1
  }

  setIsAutoScheduling = status => {
    this.isAutoScheduling = status
  }

  setIsShowCriticalPath = status => {
    this.isShowCriticalPath = status
  }

  setGanttMarkerList = ganttMarkerList => {
    this.ganttMarkerList = ganttMarkerList
  }

  setIsGanttReady = status => {
    this.isGanttReady = status
  }

  setIsLightboxOpen = status => {
    this.isLightboxOpen = status
  }

  setSelectedNodeInDataTree = payload => {
    this.selectedNodeInDataTree = payload
  }

  setIsOpenModalSelectTreeData = payload => {
    this.isOpenModalSelectTreeData = payload
  }

  setProjectGanttData(projectGanttData) {
    this.projectGanttData = projectGanttData
  }

  setIsShowGanttPanel = state => {
    this.isShowGanttPanel = state
  }

  setLoadingProgress = state => {
    this.isLoading = state
  }

  setHighlightEnable = status => {
    this.highlightEnable = status
  }

  setPlayerSpeed = value => {
    this.playerSpeed = value
  }

  setColorTaskEnable = status => {
    this.colorTaskEnable = status
  }

  //status: { task: '', isNewTask: true, sketchId: [], open: false, drawSketchType: '' }
  setSketchDrawer = status => {
    this.sketchDrawer = status
  }

  setGroupType = type => {
    this.groupType = type
  }

  setFilterTask = value => {
    this.filterTask = value
  } 

  setGanttDefaults = value => {
    this.ganttDefaults = value
  }

  setToggleChart = value => {
    this.showChart = value
  }

  setToggleResource = value => {
    this.showResource = value
  }

  setShowColorTask = status => {
    this.showColorTask = status
  }

  setIsCondensed = status => {
    this.isCondensed = status
  }

  setIsCreateNewTask = status => {
    this.isCreateNewTask = status
  }
  /**
   *
   * @param {*} ganttData
   * @returns
   */
  createProject4dGantt(ganttData) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.create(ganttData)
        .then(response => {
          this.setProjectGanttData([...this.projectGanttData, response.data])
          this.setLoadingProgress(false)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  /**
   *
   * @param {*} projectId
   * @returns
   */
  getProject4dGantt(projectId) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.getByProjectId(projectId)
        .then(response => {
          this.setProjectGanttData(response.data)
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  // ganttTask
  /**
   *
   * @param {*} ganttData
   * @returns
   */
  createGanttTask(ganttData) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.createGanttTask(ganttData)
        .then(response => {
          //this.setProjectGanttData([...this.projectGanttData, response.data])
          // we have two options here:
          // 1. sync directly to project gantt data by find in array: fast but more logic
          // 2. call apis to sync the project gantt data: slow but handleless
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  /**
   *
   * @param {*} ganttTaskId
   * @param {*} ganttData
   * @returns
   */
  updateGanttTask(ganttTaskId, ganttData) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.updateGantttask(ganttTaskId, ganttData)
        .then(response => {
          //this.setProjectGanttData([...this.projectGanttData, response.data])
          // we have two options here:
          // 1. sync directly to project gantt data by find in array: fast but more logic
          // 2. call apis to sync the project gantt data: slow but handleless
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  /**
   *
   * @param {*} ganttTaskId
   * @returns
   */
  deleteGanttTask(ganttTaskId) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.deleteGantttask(ganttTaskId)
        .then(response => {
          //this.setProjectGanttData([...this.projectGanttData, response.data])
          // we have two options here:
          // 1. sync directly to project gantt data by find in array: fast but more logic
          // 2. call apis to sync the project gantt data: slow but handleless
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  // gantt link
  /**
   *
   * @param {*} ganttData
   * @returns
   */
  createGanttLink(ganttData) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.createGanttLink(ganttData)
        .then(response => {
          //this.setProjectGanttData([...this.projectGanttData, response.data])
          // we have two options here:
          // 1. sync directly to project gantt data by find in array: fast but more logic
          // 2. call apis to sync the project gantt data: slow but handleless
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  /**
   *
   * @param {*} ganttLinkId
   * @param {*} ganttData
   * @returns
   */
  updateGanttLink(ganttLinkId, ganttData) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.updateGanttLink(ganttLinkId, ganttData)
        .then(response => {
          //this.setProjectGanttData([...this.projectGanttData, response.data])
          // we have two options here:
          // 1. sync directly to project gantt data by find in array: fast but more logic
          // 2. call apis to sync the project gantt data: slow but handleless
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  /**
   *
   * @param {*} ganttLinkId
   * @returns
   */
  deleteGanttLink(ganttLinkId) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.deleteGanttLink(ganttLinkId)
        .then(response => {
          //this.setProjectGanttData([...this.projectGanttData, response.data])
          // we have two options here:
          // 1. sync directly to project gantt data by find in array: fast but more logic
          // 2. call apis to sync the project gantt data: slow but handleless
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }
  /**
   *
   * @param {*} ganttData
   * @returns
   */
  baselineGenerator(ganttData) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.baselineGenerator(ganttData)
        .then(response => {
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  /**
   *
   * @param {*} ganttData
   * @returns
   */
  importProject(ganttData) {
    this.setLoadingProgress(true)

    return new Promise((resolve, reject) => {
      Gannt4dRequest.importProject(ganttData)
        .then(response => {
          this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  /**
   *  transformObjectInfoToTask
   * @param {*} bodyData  {projectId, fileids}
   * @returns {Promise}
   */
  transformObjectInfoToTask(bodyData) {
    return new Promise((resolve, reject) => {
      Gannt4dRequest.transformObjectInfoToTask(bodyData)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
}

decorate(ProjectGanttStore, {
  isLoading: observable,
  setLoadingProgress: action,
  isShowGanttPanel: observable,
  setIsShowGanttPanel: action,
  projectGanttData: observable,
  setProjectGanttData: action,
  createProject4dGantt: action,
  getProject4dGantt: action,
  createGanttTask: action,
  updateGanttTask: action,
  deleteGanttTask: action,
  createGanttLink: action,
  updateGanttLink: action,
  deleteGanttLink: action,
  isOpenModalSelectTreeData: observable,
  setIsOpenModalSelectTreeData: action,
  selectedNodeInDataTree: observable,
  setSelectedNodeInDataTree: action,
  isLightboxOpen: observable,
  setIsLightboxOpen: action,
  isGanttReady: observable,
  setIsGanttReady: action,
  ganttMarkerList: observable,
  setGanttMarkerList: action,
  isShowCriticalPath: observable,
  setIsShowCriticalPath: action,
  isAutoScheduling: observable,
  setIsAutoScheduling: action,
  undoCount: observable,
  redoCount: observable,
  setUndoCount: action,
  setRedoCount: action,
  isActiveUndo: observable,
  isActiveRedo: observable,
  setIsActiveUndo: action,
  setIsActiveRedo: action,
  isActive4dPlayer: observable,
  setIsActive4dPlayer: action,
  playerMode: observable,
  setPlayerMode: action,
  middleMarker: observable,
  setMiddleMarker: action,
  setTodayMarker: action,
  todayMarker: observable,
  currentViewingTime: observable,
  setCurrentViewingTime: action,
  currentColorPicker: observable,
  setCurrentColorPicker: action,
  listHighlightSketch: observable,
  setListHighlightSketch: action,
  currentAlpha: observable,
  setCurrentAlpha: action,
  isPlay4d: observable,
  setIsPlay4d: action,
  backOrForwardCount: observable,
  setBackOrForwardCount: action,
  isReadonlyMode: observable,
  setIsReadonlyMode: action,
  gridColumnList: observable,
  setGridColumnList: action,
  isShowGrid: observable,
  setIsShowGrid: action,
  baseLineGenerator: action,
  transformObjectInfoToTask: action,
  isShowBaseline: observable,
  setIsShowBaseline: action,
  isOpenModalExportnImportProject: observable,
  setIsOpenModalExportnImportProject: action,
  importFile: observable,
  setImportFile: action,
  isUploading: observable,
  setIsUploading: action,
  importProject: action,
  isOpenModalLinkSavedQuery: observable,
  setIsOpenModalLinkSavedQuery: action,
  savedQueryCachingObject: observable,
  setSavedQueryCachingObject: action,
  selectedSavedQuery: observable,
  setSelectedSavedQuery: action,
  highlightSavedQueryObjectStyles: observable,
  setHighlightSavedQueryObjectStyles: action,
  showedModels: observable,
  setShowedModels: action,
  highlightEnable: observable,
  setHighlightEnable: action,
  setPlayerSpeed: action,
  playerSpeed: observable,
  setColorTaskEnable: action,
  colorTaskEnable: observable,
  setSketchDrawer: action,
  sketchDrawer: observable,
  setGroupType: action,
  groupType: observable,
  setFilterTask: action,
  filterTask: observable,
  setGanttDefaults: action,
  ganttDefaults: observable,
  setToggleChart: action,
  showChart: observable,
  setShowColorTask: action,
  showColorTask: observable,
  isCondensed: observable,
  setIsCondensed: action,
  setSelectObjectModel: action,
  selectObjectModel: observable,
  setZoomToFit: action,
  zoomToFit: observable,
  setZoomScale: action,
  zoomScale: observable,
  setToggleResource: action,
  showResource: observable,
  setIsCreateNewTask: action,
  isCreateNewTask: observable,
})

export default new ProjectGanttStore()
