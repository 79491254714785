import { Button, Form, Input, message, Modal, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import validator from '@/validator'
import { Trans, useTranslation } from 'react-i18next';
const { Option } = Select

const InviteMemberToOganizationModal = props => {
  const { t } = useTranslation();
  const { organizationStore, organizationRoles, organizationId, adminStore } = props
  const CustomForm = props => {
    const handleSubmit = values => {
      adminStore.setLoadingProgress(true)
      organizationStore.sendInvite(values.email, values.role, organizationId).then(response => {
        if (response.data.error === t('already-invited')) {
          message.error(t('already-invited'))
          adminStore.setLoadingProgress(false)
          return
        }
        if (response.error === t('error')) {
          message.error(t('error-invited'))
          adminStore.setLoadingProgress(false)
          return
        }
        message.success(t('invitation-sent-successfully'))
        organizationStore.toggleShowInviteUserToOrganizationModal(false)
        adminStore.getCurrentOrganizationDetail(organizationId).then(() => {
          adminStore.setLoadingProgress(false)
          organizationStore.setForceReload(1)

        }).catch((error) => {
          console.log("")
          adminStore.setLoadingProgress(false)
        })
      }).catch(error => {
        console.log("error : ", error)
        adminStore.setLoadingProgress(false)
        message.error(t('error-send-invite'))
        return;
      })
    }

    return (
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        id={'invite-member-form'}>
        <Form.Item label={t('user-email')} name="email" rules={[
          {
            required: true,
            message: t('please-input-user-email'),
          },
          { validator: validator.validateEmail },
        ]}>
          <Input
            placeholder={t('input-user-email')}
            type={'email'} />
        </Form.Item>
        <Form.Item label={t('organization-role')} name="role" rules={[
          {
            required: true,
            message: t('please-select-role'),
          },
        ]}>
          <Select
            placeholder={t('select-role')}>
            {
              organizationRoles.map((r) => {
                return (
                  <Option key={r.id} value={r.id}>{r.name}</Option>
                )
              })
            }
          </Select>
        </Form.Item>
      </Form>
    )
  }

  return (
    <Modal
      title={t('invite-member-to-organization')}
      onCancel={() => organizationStore.toggleShowInviteUserToOrganizationModal(false)}
      okText={t('send-invite')}
      cancelText={t('commons.cancel')}
      zIndex={9999}
      visible={organizationStore.showInviteUserToOrganizationModal}
      className="my-modal-class"
      maskClosable={false}
      footer={[
        <Button
          key="cancel"
          onClick={() => organizationStore.toggleShowInviteUserToOrganizationModal(false)}>
          {t('commons.cancel')}
        </Button>,
        <Button
          htmlType={'submit'} form={'invite-member-form'}
          key="submit" type="primary">
          {t('send-invite')}
        </Button>
      ]}
    >
      <CustomForm />
    </Modal>
  )
}

export default withRouter(inject('organizationStore', 'adminStore')(observer(InviteMemberToOganizationModal)))