import { Button, Form, Input, message, Modal, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import validator from '@/validator';
import './style.css';
import { Trans, useTranslation } from 'react-i18next';
const { Option } = Select

const InviteUserToProject = props => {
  const { t } = useTranslation();
  const { projectTeamsStore, projectStore, adminStore, organizationStore } = props
  const [form] = Form.useForm();
  const roles = projectTeamsStore.projectRoles
  const { currentOrganizationView, curentProjectInOrganizationDetail } = adminStore



  useEffect(() => {
    if (projectTeamsStore.showAddTeamMemberModal) {
      form.resetFields();
    }
  }, [projectTeamsStore.showAddTeamMemberModal])

  const handleSubmit = () => {
    const currentLicenseNumber = adminStore.licenseMaxUser === 'UNLIMITTED' ? 999999 : adminStore.licenseMaxUser
    form.validateFields().then(async values => {
      projectStore.setLoadingProgress(true)
      await adminStore.sendInviteUserToProject(values.email, values.role, curentProjectInOrganizationDetail.id, currentLicenseNumber).then(async res => {
        projectTeamsStore.toggleShowAddTeamMemberModal(false)
        if (res?.data?.error) {
          projectStore.setLoadingProgress(false)
          message.error(t(res.data.error))
          return
        }
        message.success(t('invitation-sent-successfully'))
        await adminStore.getAllProjectTeamsInOrganization(currentOrganizationView.id, curentProjectInOrganizationDetail.id)
        projectStore.setLoadingProgress(false)
        organizationStore.setForceReload(1)
      }).catch(err => {
        projectStore.setLoadingProgress(false)
      })
    })
  }

  return (
    <Modal
      className="modal-addUser"
      title={t('invite-member')}
      onCancel={() => projectTeamsStore.toggleShowAddTeamMemberModal(false)}
      okText={t('send-invite')}
      cancelText={t('commons.cancel')}
      visible={projectTeamsStore.showAddTeamMemberModal}
      footer={[
        <Button key="back" onClick={() => projectTeamsStore.toggleShowAddTeamMemberModal(false)}>
          {t('commons.cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={e => handleSubmit()}>
          {t('send-invite')}
        </Button>
      ]}
      zIndex={9999}
    >
      <Form
        form={form}
        layout="vertical"
        id={'invite-member-form'}>
        <Form.Item
          label={t('user-email')}
          name="email"
          rules={[
            {
              required: true,
              message: t('please-input-user-email'),
            },
            { validator: validator.validateEmail },
          ]}
        >
          <Input
            placeholder={t('input-user-email')}
            type={'email'} />
        </Form.Item>
        <Form.Item
          label={t('role')}
          name="role"
          rules={[
            {
              required: true,
              message: t('please-select-role'),
            },
          ]}
        >
          <Select
            placeholder={t('select-role')}>
            {
              Object.keys(roles).map((key) => {
                return (
                  <Option key={key} value={key}>{roles[key].roleName}</Option>
                )
              })
            }
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default inject('projectTeamsStore', 'projectStore', 'adminStore', 'organizationStore')(observer(InviteUserToProject))