import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react'
import { Form, Input, Button, message, Select, Row, Col, Spin, Checkbox, Comment, Avatar, Drawer, Tooltip, Slider, Typography, Popconfirm, Table, Space, Empty } from 'antd'
import { ColumnWidthOutlined, FormOutlined, DeleteOutlined, EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import validator from '@/validator'
import { inject, observer } from 'mobx-react'
import { withRouter, useHistory } from 'react-router-dom'
import { FormStyled, ColStyled, CheckListStyled, ReplyHeaderStyle, CommentListStyle } from './style'
import defaultAvatar from '@/assets/imgs/no-avatar.png'
import Utils from '@/utils'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { useMediaQuery } from 'react-responsive'
import { Cartesian3 } from 'cesium'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './style.css'
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { HelpButton } from '@/components/elements'
import { addViewPointCameraData, getVisileDataTree } from '@/lib/projectLib';
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

const TopicEditorDrawer = ({ workflowStore, topicStore, usersStore, projectStore, schedulingStore, adminStore, viewer, location, sketchingStore,
    feedbackStore, projectTeamsStore, commonStore,projectSettingStore, polylineCutToolStore }) => {
    const { t } = useTranslation();
    const history = useHistory()
    let CustomForm = (props) => {
        const [form] = Form.useForm();
        const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
        const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
        const [phases, setPhases] = useState([]);
        const [selectedPhase, setSelectedPhase] = useState(undefined);
        const [percentCurrentPhaseCompleted, setPercentCurrentPhaseCompleted] = useState(0);
        const [commentList, setCommentList] = useState([]);
        const [replyingTo, setReplyingTo] = useState({});
        const areaCommentInput = useRef(null)
        const [drawerWidth, setDrawerWidth] = useState(0);
        const [topicPercentComleted, setTopicPercentCompleted] = useState(0)
        const [userTeamList, setUserTeamList] = useState([])
        const [fetching, setFetching] = useState(false)
        const [authorCreate, setAuthorCreate] = useState('')
        const [folderPaths, setFolderPaths] = useState([])
        const [userList, setUserList] = useState([])
        moment.locale(`${commonStore.language}`)
        const [screenWidth, setScreenWidth] = useState(window.innerWidth)  
        const responsiveCarousel = {
            desktop: {
                breakpoint: {
                    max: 3000,
                    min: 1024
                },
                items: 2,
                slidesToSlide: 2,
                partialVisibilityGutter: 40
            },
            mobile: {
                breakpoint: {
                    max: 464,
                    min: 0
                },
                items: 2,
                slidesToSlide: 2,
                partialVisibilityGutter: 30
            },
            tablet: {
                breakpoint: {
                    max: 1024,
                    min: 200
                },
                items: 1,
                slidesToSlide: 1,
                partialVisibilityGutter: 30
            }
        };

        const getCameraData = (timestamp4D) => {
            if (viewer) {
                let camera = viewer.camera
                let camData = {}
                camData.position = {
                    x: camera.position.x,
                    y: camera.position.y,
                    z: camera.position.z,
                }
                camData.direction = {
                    x: camera.direction.x,
                    y: camera.direction.y,
                    z: camera.direction.z,
                }
                camData.up = {
                    x: camera.up.x,
                    y: camera.up.y,
                    z: camera.up.z,
                }
                camData.positionCartographic = {
                    lng: camera.positionCartographic.longitude,
                    lat: camera.positionCartographic.latitude,
                    elevation: camera.positionCartographic.height
                  }
                  camData.orientation = {
                    heading: camera.heading,
                    pitch: camera.pitch,
                    roll: camera.roll
                  }
                if (timestamp4D) {
                    camData.timeSlider = schedulingStore.currentViewingTime || moment(new Date())
                }

                return camData
            }
            return false
        }

        //#region event for mouse and touche split panel
        const handleTouchStart = e => {
            e.preventDefault();
            if (e.touches.length !== 1) return null;
            document.addEventListener("touchmove", handleTouchMove, { passive: false });
            document.addEventListener("touchend", handleTouchEnd, { passive: false });
            document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
        };

        const handleTouchMove = useCallback(e => {
            let touch = e.touches[0] || e.changedTouches[0];
            let target = document.elementFromPoint(touch.clientX, touch.clientY);
            let newWidth = (document.body.offsetWidth ? document.body.offsetWidth : 0) - (touch.clientX - (target && target.offsetLeft ? target.offsetLeft : 0));
            let minDrawerWidth = 300;
            if (newWidth < 0) {
                setDrawerWidth(10);
            }
            if (newWidth > minDrawerWidth) {
                setDrawerWidth(newWidth);
            }
            if (newWidth > window.innerWidth) {
                setDrawerWidth(window.innerWidth);
            }
        }, []);

        const handleTouchEnd = () => {
            document.removeEventListener("touchend", handleTouchEnd, { passive: false });
            document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
            document.removeEventListener("touchmove", handleTouchMove, { passive: false });
        };

        const handleMouseDown = e => {
            e.preventDefault();
            document.addEventListener("mouseup", handleMouseUp, true);
            document.addEventListener("mousemove", handleMouseMove, true);
        };

        const handleMouseUp = () => {
            document.removeEventListener("mouseup", handleMouseUp, true);
            document.removeEventListener("mousemove", handleMouseMove, true);
        };

        const handleMouseMove = useCallback(e => {
            let newWidth = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
            let minDrawerWidth = 300;
            if (newWidth > minDrawerWidth) {
                setDrawerWidth(newWidth);
            }
            if (newWidth > window.innerWidth) {
                setDrawerWidth(window.innerWidth);
            }
        }, []);        

        const handleChangeScreenSize = () => {
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleChangeScreenSize)

        useEffect(() => {
            if (drawerWidth > screenWidth) {
                setDrawerWidth(screenWidth)
            }
        }, [screenWidth])
        //#endregion

        useEffect(() => {
            const params = new URLSearchParams(location.search);
            const _topicname = params.get('topic');
            if (_topicname && projectStore.projectDetail._id && Array.isArray(topicStore.topicList)) {
                let _topicrecord = topicStore.topicList.find(x => x.name.toLowerCase() === _topicname.toLowerCase())
                if (_topicrecord) {
                    topicStore.setShowTopicEditor(true, false, _topicrecord)
                } else {
                    history.push(location.pathname)
                }
            } else if (params.get('viewpoint')) {
                handleCloseTopicEditor(false)
            }
            topicStore.setSelectedWorkflowValue('')
            setPercentCurrentPhaseCompleted(0)
            return () => {
                topicStore.setIsClose(false)
                topicStore.setIsCheckSaveViewDone(true)
                topicStore.setIsShowLoading(true)
            }
        }, []);

        useEffect(() => {
            if (selectedPhase && selectedPhase.workflowsphasechecklists && selectedPhase.workflowsphasechecklists.length > 0) {
                let checked = selectedPhase.workflowsphasechecklists.filter(e => e.isUnCheck === false).length;
                setPercentCurrentPhaseCompleted((checked / selectedPhase.workflowsphasechecklists.length) * 100)
            }
        }, [selectedPhase]);

        useEffect(() => {
            if ((!workflowStore.workflowList?.length || !workflowStore.workflowList.some(el => el.id === topicStore.selectedWorkflowValue))) {
                form.setFieldsValue({ workflow: '', assigntopersons: undefined });
                setPhases([]);
                setSelectedPhase(undefined);
                setUserTeamList([])
                topicStore.setSelectedWorkflowValue('')
                setPercentCurrentPhaseCompleted(0)
                setTopicPercentCompleted(0)
            } else if (topicStore.selectedWorkflowValue && workflowStore.workflowList?.length && workflowStore.workflowList.some(el => el.id === topicStore.selectedWorkflowValue)) {
                let selectedWorkflow = { ...workflowStore.workflowList.find(w => w.id.toString() === topicStore.selectedWorkflowValue) };
                let cpPhases = [...selectedWorkflow.workflowsphase]
                cpPhases.sort((a, b) => a.order - b.order);
                let index = cpPhases.findIndex(x => x.id == selectedPhase?.id)
                let newArr = [...cpPhases].map(item => {
                    if (phases.find(x => x.id === item.id)) {
                        let phase = phases.find(x => x.id === item.id)
                        return {
                            ...item,
                            workflowsphasechecklists: item.workflowsphasechecklists.map(wfl => {
                                if (phase.workflowsphasechecklists.find(x => x.id === wfl.id)) {
                                    let workflowPhase = phase.workflowsphasechecklists.find(x => x.id === wfl.id);
                                    if (typeof workflowPhase.isUnCheck === 'boolean')
                                        wfl.isUnCheck = workflowPhase.isUnCheck
                                }
                                return wfl
                            })
                        }
                    }
                    return item
                })
                setPhases(newArr)
                if (index > -1) {
                    let arrUserAssign = cpPhases[index].assigntopersons ? cpPhases[index].assigntopersons.map(opt => opt && opt.id) : [];
                    let arrUserAssignFrom = form.getFieldValue('assigntopersons').filter(x => arrUserAssign.includes(x))
                    form.setFieldsValue({ assigntopersons: arrUserAssign.length > 0 ? arrUserAssignFrom : [] })
                    setSelectedPhase({ ...cpPhases[index] });
                    setUserTeamList(cpPhases[index].assigntopersons)
                } else {
                    setSelectedPhase({ ...cpPhases[0] });
                    setUserTeamList(cpPhases[0].assigntopersons)
                    form.setFieldsValue({ assigntopersons: cpPhases[0].assigntopersons ? cpPhases[0].assigntopersons.map(opt => opt && opt.id) : [] })
                }
            }
        }, [workflowStore.workflowList])

        const checkingFeatureRole = (type) => {
            if (!type) return false
            return adminStore.checkingFeatureRole(projectStore, type)
        }

        useEffect(() => {
            form.resetFields();
            const populateTopicFormData = async () => {
                topicStore.setLoadingProgress(true)
                await projectTeamsStore.getProjectTeams(projectStore.projectDetail._id);
                await workflowStore.getWorkflowList(projectStore.projectDetail.id);
                topicStore.setTopicTypes();
                topicStore.setTopicStatuses();
                topicStore.setTopicPriorities();
                await topicStore.getTopicTags(projectStore.projectDetail.id);
                if (topicStore.viewingTopicForm.isEdit) {
                    // check if topic 
                    if (topicStore.viewingTopicForm.timestamp4D) {
                        schedulingStore.setOriginViewingTime(moment(Number(topicStore.viewingTopicForm.timestamp4D)).format('YYYY-MM-DD HH:mm:ss'))
                        schedulingStore.setCurrentViewingTime(moment(Number(topicStore.viewingTopicForm.timestamp4D)).format('YYYY-MM-DD HH:mm:ss'))
                        schedulingStore.toggleTimeSliderVisible(true)
                    }
                    // check topic has view then go to first view
                    if (topicStore.viewingTopicForm.topic3Dviews && topicStore.viewingTopicForm.topic3Dviews.length > 0) {
                        let capture = topicStore.viewingTopicForm.topic3Dviews[0]
                        goViewpoint(capture, 'visit')
                        if (!projectStore.clippingMode && capture.treeData && capture.treeData.clippingData && typeof capture.treeData.clippingData === 'object') {
                            projectStore.setViewMode('default mode')
                            projectStore.setClippingViewPoint(capture.treeData.clippingData)
                            projectStore.setClippingMode(capture.treeData.clippingData.clippingMode)
                        }
                        if(!projectStore.clippingMode && capture.treeData && capture.treeData?.hideAreaData && typeof capture.treeData?.hideAreaData === 'object'){
                            const hideAreaDataFromTargetViewpoint = capture.treeData.hideAreaData;
                            polylineCutToolStore.setHideArea(hideAreaDataFromTargetViewpoint);
                            if(hideAreaDataFromTargetViewpoint?._id !== projectStore.projectDetail.tilesetData?.hiddenArea?._id)
                                projectStore.setProjectDetail({...projectStore.projectDetail, tilesetData: {...projectStore.projectDetail.tilesetData, hiddenArea: hideAreaDataFromTargetViewpoint}});
                            if(polylineCutToolStore.showForm) polylineCutToolStore.setShowForm(false)
                        }else{
                            polylineCutToolStore.setHideArea(false);
                        }
                        if(capture.treeData && capture.treeData?.navigationDistanceLimit){
                            if(projectStore.navigationStyles && typeof projectStore.navigationStyles === 'object')
                              projectStore.setNavigationStyles({ ...projectStore.navigationStyles, distanceLimit: capture.treeData?.navigationDistanceLimit })
                            else projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, distanceLimit: capture.treeData?.navigationDistanceLimit })
                          }
                        if(capture.treeData){
                            if(projectStore.navigationStyles && typeof projectStore.navigationStyles === 'object')
                              projectStore.setNavigationStyles({ ...projectStore.navigationStyles, allowUnderground: capture.treeData?.navigationAllowUnderground ?? true})
                            else projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, allowUnderground: capture.treeData?.navigationAllowUnderground ?? true})
                          }
                    } else {
                        projectStore.setTypeViewPoint('topic')
                        projectStore.setTargetViewPoint()
                    }
                    // set topic percent complete
                    setTopicPercentCompleted(topicStore.viewingTopicForm.percentCompleted)
                    let _commentlist = topicStore.viewingTopicForm?.topicdiscussions?.length > 0 ? Utils.unflatten(topicStore.viewingTopicForm?.topicdiscussions) : []
                    setCommentList(_commentlist)
                    topicStore.setList3DViewTopic(topicStore.viewingTopicForm.topic3Dviews)

                    // folder tree
                    let folders = projectStore.projectDetail.treeData
                    setFolderPaths([]) // 
                    let getNestedPath = (items, currentPath = '', store = [{ id: '', path: '' }]) => {
                        const isArray = Array.isArray(items);
                        if (isArray) {
                            items.forEach((item) => {
                                if (!item.children) { // return soon

                                    if (currentPath) {
                                        store.push(
                                            //   key, fileName, currentPath
                                            { id: item.modelId || item.sketchId, path: currentPath }
                                        );
                                    }
                                    return
                                }
                                let path = currentPath
                                path += '/' + item.title

                                getNestedPath(item.children, path, store)

                            });
                        }
                    }
                    getNestedPath(folders, '', folderPaths)

                    let _topic3Dobjects = topicStore.viewingTopicForm.topic3Dobjects
                    if (_topic3Dobjects) {
                        _topic3Dobjects = _topic3Dobjects.map(item => {
                            const folderTree = folderPaths?.find(f => f.id === item?.model3D?.id)?.path
                            const folderTreeSketch = folderPaths?.find(f => f.id === item?.sketch?.id)?.path
                            return {
                                id: item.id,
                                _id: item._id,
                                name: item.name,
                                GUID: item.GUID,
                                topic: item.topic,
                                type: item.type,
                                model3D: item?.model3D ? {
                                    id: item?.model3D?.id,
                                    name: item?.model3D?.name,
                                    project: item?.model3D?.project,
                                    sourceType: item?.model3D?.sourceType,
                                    src: item?.model3D?.src,
                                    type: item?.model3D?.type,
                                    user: item?.model3D?.user
                                } : undefined,
                                sketch :item?.sketch ? {
                                    id: item?.sketch?.id,
                                    name: item?.sketch?.name,
                                    project: item?.sketch?.project,
                                    type: item?.sketch?.type
                                } : undefined,
                                fileName: item?.model3D?.name || item?.sketch?.name,
                                folderTree : item?.type ==='sketch' ? folderTreeSketch : folderTree
                            }
                        })
                    }

                    topicStore.setList3DObjectsTopic(_topic3Dobjects)
                    if (topicStore.viewingTopicForm?.workflow && workflowStore.workflowList?.length > 0) {
                        if (workflowStore.workflowList.some(el => el.id === topicStore.viewingTopicForm?.workflow)) {
                            let _topicphase = topicStore.viewingTopicForm.topicphases //JSON.parse(JSON.stringify(topicStore.viewingTopicForm.topicphases))
                            if (_topicphase && _topicphase.length > 0) {
                                _topicphase = _topicphase.map((item) => {
                                    return {
                                        id: item.workflowsphase, //workflowphase id
                                        topicphaseId: item.id, //topicphase id
                                        name: item.name,
                                        order: item.order,
                                        createdAt: item.createdAt,
                                        isActive: item.isActive,
                                        workflowsphasechecklists: item.checklists && item.checklists.length > 0 ? item.checklists.map((c) => {
                                            return {
                                                id: c?.workflowsphasechecklist?.id,
                                                checklistId: c.id, // checklist id
                                                name: c.name,
                                                order: c.order,
                                                isUnCheck: c.isUnCheck,
                                                workflowsphasechecklist: c?.workflowsphasechecklist?.id
                                            }
                                        }) : [],
                                        assigntopersons: item.assigntopersons ? [...item.assigntopersons] : [],
                                        completedDate: item?.completedDate,
                                        startDate: item?.startDate,
                                        topic: item.topic
                                    }
                                })
                                _topicphase.sort((a, b) => a.order - b.order);
                                setPhases([..._topicphase]);
                                if(_topicphase.some(c => c.isActive)){
                                    _topicphase.forEach(phase => {
                                        if (phase.isActive) {
                                            setSelectedPhase({ ...phase });
                                            setUserTeamList(phase.assigntopersons)
                                            // init value                                
                                            form.setFieldsValue({ assigntopersons: phase.assigntopersons ? phase.assigntopersons.map(opt => opt && opt.id) : [] })
                                            form.setFieldsValue({ workflow: topicStore.viewingTopicForm.workflow })
                                            // TODO 
                                        }
                                    });
                                }else{
                                    let phase = _topicphase[0];
                                    setSelectedPhase({ ...phase });
                                    setUserTeamList(phase.assigntopersons)
                                    // init value                                
                                    form.setFieldsValue({ assigntopersons: phase.assigntopersons ? phase.assigntopersons.map(opt => opt && opt.id) : [] })
                                    form.setFieldsValue({ workflow: topicStore.viewingTopicForm.workflow })
                                }
                              
                            } else {
                                let _assigntopersons = topicStore.viewingTopicForm?.assigntopersons
                                if (_assigntopersons) {
                                    setUserTeamList(_assigntopersons)
                                    form.setFieldsValue({ assigntopersons: _assigntopersons ? _assigntopersons.map(opt => opt && opt.id) : [] })
                                }
                            }

                        } else {
                            setPhases([]);
                        }
                        topicStore.setLoadingProgress(false)
                    } else {
                        setPhases([]);
                    }
                    topicStore.setLoadingProgress(false)

                } else {
                    topicStore.setLoadingProgress(false)
                }
            }
            if (topicStore.isShowTopicEditor) {
                setDrawerWidth(isTabletOrMobile ? 300 : 900)
                populateTopicFormData()
            }
        }, [topicStore.viewingTopicForm])

        const goViewpoint = (capture, type) => {
            if (!viewer.camera || !capture.cameraData) return
            let destination = new Cartesian3(
                capture.cameraData.position.x,
                capture.cameraData.position.y,
                capture.cameraData.position.z
            )
            let direction = new Cartesian3(
                capture.cameraData.direction.x,
                capture.cameraData.direction.y,
                capture.cameraData.direction.z
            )
            let up = new Cartesian3(
                capture.cameraData.up.x,
                capture.cameraData.up.y,
                capture.cameraData.up.z
            )
            viewer.camera.flyTo({
                duration: 1.5,
                destination,
                orientation: {
                    direction,
                    up,
                },
            })

            if (type && type === 'visit') {
                if (capture?.cameraData?.timeSlider) {
                    let time = moment(capture.cameraData.timeSlider)
                    schedulingStore.setCurrentViewingTime(time)
                }
                topicStore.setCurrentTopic3DView(capture)
                projectStore.setTypeViewPoint('topic3DView')
                projectStore.setTargetViewPoint()
            }
        }


        // auto save view when open Create Topic Drawer
        useEffect(() => {
            if ((topicStore.list3DViewTopic?.length === 0) && !topicStore.viewingTopicForm?.isEdit && topicStore.isShowTopicEditor && !topicStore.isClose) {
                topicStore.setSave3DView(true)
                topicStore.setIsCheckSaveViewDone(false)
                topicStore.setIsShowLoading(false)
            }
        }, [topicStore.isShowTopicEditor])

        // delete topic view
        useEffect(() => {
            if (topicStore.isDrawer3DViewVisible && !topicStore.viewingTopicForm?.name && topicStore.list3DViewTopic?.length > 0) {
                topicStore.deleteTopic3DViews(topicStore.list3DViewTopic[0]?.id)
            }
        }, [topicStore.isDrawer3DViewVisible])


        const handleSubmit = async (e, isEdit) => {
            e.preventDefault()
            const startTime = Date.now();
            form.validateFields().then(async values => {
                topicStore.setLoadingProgress(true)
                values.phases = phases;
                values.selectedPhase = selectedPhase;
                values.phases.map((phase) => {
                    if (selectedPhase.id === phase.id && phase.isCompletedPhase) {
                        return phase.completedDate = Date.now()
                    }
                })
                values.project = projectStore.projectDetail.id;

                // When add or edit topic if 4D time active then current time in 4D slider is saved to the Topic
                if (schedulingStore.timeSliderVisible && schedulingStore.currentViewingTime && schedulingStore.currentViewingTime.toDate) {
                    values.timestamp4D = schedulingStore.currentViewingTime.toDate().getTime()
                }

                phases.map((el, ind) => {
                    let _topicphaseChecklist = el.workflowsphasechecklists
                    let found = _topicphaseChecklist.find(element => element.isUnCheck === true)
                    el.isCompletedPhase = found ? false : true
                })
                let data = getVisileDataTree(projectStore,topicStore,sketchingStore,feedbackStore, usersStore.currentUser?._id)
                data.cameraData = getCameraData(true);
                const metadata = projectSettingStore.systemProjectSetting;
                if (data.cameraData) {
                    data = addViewPointCameraData(projectStore,commonStore,data,metadata,viewer);
                }
                values.treeData = data

                // save topic default value
                const _defaultTopic = {
                    userId: projectStore.projectDetail.currentUser._id,
                    topictype: values.topictype,
                    topicpriority: values.topicpriority,
                    topicstatus: values.topicstatus,
                    workflow: values.workflow
                }
                let _projectMetadata = projectStore.projectDetail.metadata
                if (_projectMetadata && _projectMetadata.topicDefault && _projectMetadata.topicDefault.length > 0) {
                    let index = projectStore.projectDetail.metadata.topicDefault.findIndex(item => item.userId === projectStore.projectDetail.currentUser._id)
                    if (index > -1) {
                        const temp = [..._projectMetadata.topicDefault]
                        temp[index] = _defaultTopic
                        _projectMetadata.topicDefault = temp
                    } else {
                        let _data = _defaultTopic
                        _projectMetadata.topicDefault.push(_data)
                    }
                } else {
                    _projectMetadata.topicDefault = [_defaultTopic]
                }
                projectStore.updateProjectMetadata({ metadata: _projectMetadata }).then((res) => {
                    projectStore.projectDetail.metadata = res.metadata
                }).catch(err => {
                    console.log(err)
                });
                //===========

                if (isEdit) {
                    if (selectedPhase) {
                        let totalChecklist = phases.reduce(function (n, objphase) {
                            return n + objphase.workflowsphasechecklists.length;
                        }, 0);
                        let totalChecklistChecked = phases.reduce(function (n, objphase) {
                            objphase.workflowsphasechecklists.forEach(e => {
                                if (e.isUnCheck === false) {
                                    return n = n + 1;
                                }
                            });
                            return n;
                        }, 0);


                        values.percentCompleted = (totalChecklistChecked / totalChecklist) * 100;
                    } else {
                        values.percentCompleted = topicPercentComleted
                    }
                    if (topicStore.list3DViewTopic && topicStore.list3DViewTopic.length > 0) {
                        values.topic3Dviews = topicStore.list3DViewTopic.sort((a, b) => a.order - b.order).map(c => c.id)
                        values.firstTopic3Dviews = values.topic3Dviews[0]
                        // values.treeData = { ...topicStore.list3DViewTopic[0].treeData, clippingData: data.clippingData ? data.clippingData : null }
                    }
                    if (topicStore.viewingTopicForm?.cloneData) {
                        values.cloneData = {
                            ...topicStore.viewingTopicForm?.cloneData,
                            updatedAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                        }
                    }                    
                    await topicStore.updateTopic(topicStore.viewingTopicForm.id, values).then(async (res) => {
                        await topicStore.getLocationTopicsByProject(projectStore.projectDetail.id)
                        topicStore.setReloadDataTopic()
                        message.success(t('topic-updated-successfull'))
                        form.resetFields();
                        handleCloseTopicEditor(false)
                        topicStore.setLoadingProgress(false)
                        commonStore.loggingFunction('update topic','success',startTime,usersStore.currentUser ,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name )
                    }).catch(error => {
                        topicStore.setLoadingProgress(false)
                        message.error(t('update-topic-error'))
                        commonStore.loggingFunction('update topic','failed',startTime,usersStore.currentUser ,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name )
                    })
                } else {
                    values.user = usersStore.currentUser.id;
                    if (selectedPhase) {
                        let totalChecklist = phases.reduce(function (n, objphase) {
                            return n + objphase.workflowsphasechecklists.length;
                        }, 0);
                        let totalChecklistChecked = phases.reduce(function (n, objphase) {
                            objphase.workflowsphasechecklists.forEach(e => {
                                if (e.isUnCheck === false) {
                                    return n = n + 1;
                                }
                            });
                            return n;
                        }, 0);
                        values.percentCompleted = (totalChecklistChecked / totalChecklist) * 100;
                    } else {
                        values.percentCompleted = topicPercentComleted
                    }

                    if (topicStore.viewingTopicForm.location3D) {
                        values.location3D = topicStore.viewingTopicForm.location3D
                    }

                    if (topicStore.list3DViewTopic && topicStore.list3DViewTopic.length > 0) { 
                        values.topic3Dviews = topicStore.list3DViewTopic
                        // values.topic3Dviews[0].cameraData = getCameraData(true)
                        values.topic3Dviews[0].cameraData = data.cameraData;
                        values.topic3Dviews[0].treeData = { ...topicStore.list3DViewTopic[0].treeData, clippingData: data.clippingData ? data.clippingData : null, hideAreaData: data.hideAreaData ? data.hideAreaData : false, navigationDistanceLimit: data.navigationDistanceLimit ? data.navigationDistanceLimit : -1, navigationAllowUnderground: data.navigationAllowUnderground ?? true}
                        values.topic3Dviews = values.topic3Dviews.map(c => toJS(c))
                    }
                    if (topicStore.list3DObjectsTopic && topicStore.list3DObjectsTopic.length) {
                        values.topic3Dobjects = topicStore.list3DObjectsTopic
                    }
                    topicStore.createTopic(values).then(async () => {
                        await topicStore.getLocationTopicsByProject(projectStore.projectDetail.id)
                        topicStore.setReloadDataTopic()
                        message.success(t('topic-add-successfull'))
                        commonStore.loggingFunction('create new topic','success',startTime,usersStore.currentUser ,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name )
                        form.resetFields();
                        handleCloseTopicEditor(false)
                        topicStore.setLoadingProgress(false)
                    }).catch(error => {
                        topicStore.setLoadingProgress(false)
                        commonStore.loggingFunction('create new topic','failed',startTime,usersStore.currentUser ,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name )
                        if (error.data) message.error(t(error.data.message))
                    })
                }
            })
        }

        const handleCloseTopicEditor = (isShowTopicList) => {
            if (isShowTopicList) {
                topicStore.setShownListTopic(true);
            }
            topicStore.setShowTopicEditor(false, false);
            if (!workflowStore.showWorkflowDrawer){
                projectTeamsStore.clearTeamList();
                workflowStore.clearWorkflowList();
            }
            topicStore.clearTopicStatuses();
            topicStore.clearTopicPriorities();
            topicStore.clearTopicTypes();
            topicStore.clearTopicTags();
            topicStore.clearList3DViewTopic();
            topicStore.setList3DObjectsTopic([]);
            topicStore.setIsClose(false)
            topicStore.setGetTopicForm('')
            setPhases([]);
            setSelectedPhase(undefined);
            setPercentCurrentPhaseCompleted(0)
            setCommentList([])
            setReplyingTo({})
            setDrawerWidth(0)
            setTopicPercentCompleted(0)
            setUserTeamList([])
            topicStore.setSelectedWorkflowValue('')
            setUserList()
            topicStore.setIsCheckSaveViewDone(true)
            topicStore.setIsShowLoading(true)
            topicStore.setIsShowTopicEditor(false)

            const params = new URLSearchParams(location.search);
            const _topicname = params.get('topic');
            if (_topicname) {
                history.push(location.pathname)
            }
        }

        const handleClickShow3DView = (e) => {
            e.preventDefault()
            if (topicStore.viewingTopicForm?.isEdit) {
                topicStore.setViewingTopicForm({ ...topicStore.viewingTopicForm, topic3Dobjects: topicStore.list3DObjectsTopic })
            }
            form.validateFields().then(values => {
                topicStore.setShowTopicEditor(false, true)
                topicStore.toggleDrawer3DViewVisible(true)
                topicStore.setGetTopicForm(topicStore.viewingTopicForm)
                topicStore.setEditTopicForm(topicStore.viewingTopicForm?.isEdit)
                if (topicStore.viewingTopicForm.isEdit) {
                    topicStore.setViewingTopicForm({
                        ...topicStore.viewingTopicForm,
                        name: values.name,
                        description: values.description,
                        topictype: values.topictype,
                        topicpriority: values.topicpriority,
                        topicstatus: values.topicstatus,
                        topictags: values.topictags,
                        workflow: values.workflow,
                        // topicphases: phases, // phải chuyển đổi workflowsphasechecklists lại thành checklists
                        // selectedPhase: selectedPhase, // phải chuyển đổi workflowsphasechecklists lại thành checklists
                        assigntopersons: userTeamList
                    })
                } else {
                    topicStore.setViewingTopicForm({
                        ...topicStore.viewingTopicForm,
                        name: values.name,
                        description: values.description,
                        topictype: values.topictype,
                        topicpriority: values.topicpriority,
                        topicstatus: values.topicstatus,
                        topictags: values.topictags,
                        workflow: values.workflow,
                        topicphases: phases,
                        selectedPhase: selectedPhase,
                        assigntopersons: userTeamList
                    })
                }
            })
        }

        const handleClickShow3DLocation = (e) => {
            e.preventDefault()
            form.validateFields().then(values => {
                projectStore.setCleanMode(true)
                topicStore.setShowTopicEditor(false, true)
                topicStore.toggleTopic3DLocationVisible(true)
                topicStore.setViewingTopicForm({
                    ...topicStore.viewingTopicForm,
                    name: values.name,
                    description: values.description,
                    topictype: values.topictype,
                    topicpriority: values.topicpriority,
                    topicstatus: values.topicstatus,
                    topictags: values.topictags,
                    workflow: values.workflow,
                    topicphases: phases,
                    selectedPhase: selectedPhase,
                    assigntopersons: values.assigntopersons
                })
            })
        }

        const handleClickShow3DObjects = (e) => {
            e.preventDefault()
            form.validateFields().then(values => {
                projectStore.setCleanMode(true)
                topicStore.setShowTopicEditor(false, true)
                topicStore.toggleTopic3DObjectsVisible(true)
                topicStore.setViewingTopicForm({
                    ...topicStore.viewingTopicForm,
                    name: values.name,
                    description: values.description,
                    topictype: values.topictype,
                    topicpriority: values.topicpriority,
                    topicstatus: values.topicstatus,
                    topictags: values.topictags,
                    workflow: values.workflow,
                    topicphases: phases,
                    selectedPhase: selectedPhase,
                    assigntopersons: values.assigntopersons
                })
            })
        }

        // const zoomTo3DTileFeature = (item) => {
        //     const feature = projectStore.modelFeatures[item?.model3D?.id][item.GUID];
        // }

        const PopulateDropdown = ({ data, label, fieldName, message, initialValue, isDisable, isRequired }) => (
            <Form.Item label={`${label}`}
                name={`${fieldName}`}
                initialValue={initialValue}
                rules={[
                    {
                        required: isRequired,
                        message: message,
                    },
                    { validator: validator.validateEmptyString },
                ]}>
                <Select
                    notFoundContent={<Spin size="small" />}
                    disabled={isDisable}
                >
                    {data.map((item, index) => {
                        return (
                            <Option key={item.name}>{t(item.name)}</Option>
                        );
                    })}
                </Select>
            </Form.Item>
        )

        const PopulateTag = ({ data = [], initialValue = [], isDisable }) => {
            let _initialValue = []
            if (initialValue && initialValue.length > 0) {
                _initialValue = initialValue.map(item => {
                    if (item.name) {
                        return item.name
                    } else {
                        return item
                    }
                })
            }
            return (
                <Form.Item label={t('tags')} name="topictags" initialValue={_initialValue}>
                    <Select
                        mode="tags"
                        tokenSeparators={[',']}
                        disabled={isDisable}
                        notFoundContent=""
                    >
                        {data.map((item, index) => {
                            return (
                                <Option key={item.name}>{item.name}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            )
        }

        const PopulateWorkflow = ({ workflowData }) => {
            let initWorkflowValue = workflowData.find(flow => flow.id === topicStore.viewingTopicForm.workflow)
            if (initWorkflowValue) {
                topicStore.setSelectedWorkflowValue(initWorkflowValue.id)
            } 
            const handleWorkflowSelect = (value, selectedOption) => {
                form.resetFields(['selectedPhase', 'assigntopersons']);
                form.setFieldsValue({
                    assigntopersons: undefined
                })
                if (value) {
                    setTopicPercentCompleted(topicStore.viewingTopicForm.percentCompleted === undefined ? 0 : topicStore.viewingTopicForm.percentCompleted)
                } else {
                    setTopicPercentCompleted(0)
                }

                if (value) {
                    topicStore.setSelectedWorkflowValue(selectedOption.key)
                    let selectedWorkflow = { ...workflowData.find(w => w.id.toString() === value) };
                    if (topicStore.viewingTopicForm.isEdit && topicStore.viewingTopicForm.workflow === value) {
                        let _topicphase = topicStore.viewingTopicForm?.topicphases?.map((item) => {
                            return {
                                id: item.workflowsphase, //workflowphase id,
                                topicphaseId: item.id, //topicphase id
                                name: item.name,
                                order: item.order,
                                isActive: item.isActive,
                                workflowsphasechecklists: item.checklists?.map((c) => {
                                    return {
                                        id: c?.workflowsphasechecklist?.id,
                                        checklistId: c.id, // checklist id
                                        name: c.name,
                                        order: c.order,
                                        isUnCheck: c.isUnCheck,
                                        workflowsphasechecklist: c?.workflowsphasechecklist?.id
                                    }
                                }),
                                assigntopersons: [...item.assigntopersons],
                                topic: item.topic,
                                completedDate: item?.completedDate,
                                startDate: item?.startDate,
                            }
                        })
                        _topicphase.sort((a, b) => a.order - b.order);
                        setPhases([..._topicphase]);

                        _topicphase.forEach(phase => {
                            if (phase.isActive) {
                                setSelectedPhase({ ...phase });
                                setUserTeamList(phase.assigntopersons)
                                form.setFieldsValue({ assigntopersons: phase.assigntopersons ? phase.assigntopersons.map(opt => opt && opt.id) : [] })
                            }
                        });

                    } else {
                        let cpPhases = [...selectedWorkflow.workflowsphase]
                        cpPhases.sort((a, b) => a.order - b.order);
                        setPhases([...cpPhases]);
                        setSelectedPhase({ ...cpPhases[0] });
                        setUserTeamList(cpPhases[0].assigntopersons)
                        form.setFieldsValue({ assigntopersons: cpPhases[0].assigntopersons ? cpPhases[0].assigntopersons.map(opt => opt && opt.id) : [] })
                    }

                } else {
                    setPhases([]);
                    setSelectedPhase(undefined);
                    setUserTeamList([])
                    topicStore.setSelectedWorkflowValue('')
                }
            }

            return (
                <Form.Item
                    label={t('workflow')}
                    name="workflow"
                    rules={[
                        {
                            required: false,
                            message: t('no-workflow-selected')
                        }
                    ]}
                    initialValue={topicStore.selectedWorkflowValue}
                >

                    <Select
                        onChange={handleWorkflowSelect}
                        allowClear={true}
                        onDeselect={() => {
                            // console.log('clear')
                        }}
                        notFoundContent={<Empty description={t('no-workflow-selected')} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    >
                        {/* <Option value={''} selected>&nbsp;</Option> */}
                        {workflowData.map((item) => (
                            <Option
                                key={item.id}
                            >
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )
        }

        const PopulatePhase = () => {
            const handlePhaseSelect = (value) => {
                form.resetFields(['selectedPhase', 'assigntopersons']);
                let chosenPhase = phases.find(p => p?.id?.toString() === value?.toString());
                chosenPhase ? setSelectedPhase({ ...chosenPhase }) : console.log()
                setUserTeamList(chosenPhase?.assigntopersons)
                form.setFieldsValue({ assigntopersons: chosenPhase?.assigntopersons ? chosenPhase?.assigntopersons?.map(opt => opt && opt.id) : [] })
            }

            return (
                <Form.Item label={t('active-phase')}>
                    <Select
                        onSelect={handlePhaseSelect}
                        value={selectedPhase && selectedPhase.name ? `${selectedPhase.order + 1}. ${selectedPhase.name}` : ''}
                        notFoundContent={<Empty description={t('no-phase-found')} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    >
                        {phases ? phases.map((item) => (
                            <Option key={item.id}>{`${item.order + 1}. ${item.name}`}</Option>
                        )) : <Option>{t('no-phase-found')}</Option>}
                    </Select>
                </Form.Item>
            )
        }

        const PopulateChecklist = () => {
            let checklists = topicStore.viewingTopicForm?.selectedPhase?.workflowsphasechecklists ? topicStore.viewingTopicForm?.selectedPhase?.workflowsphasechecklists : (selectedPhase && selectedPhase.workflowsphasechecklists ? selectedPhase.workflowsphasechecklists : [])
            if (checklists.length > 0) {
                // var checked = checklists.filter(e => e.isUnCheck === false).length;
                // setPercentCurrentPhaseCompleted((checked / checklists.length) * 100)

                let tempCheckLists;
                if (topicStore.viewingTopicForm.isEdit) {
                    let isAllowed = false;
                    //check if user assigned equal to login user then enabale checklist else disable
                    if (selectedPhase && selectedPhase.assigntopersons.find(u => u.id && u.id.toString() === usersStore.currentUser.id.toString())) {
                        isAllowed = true;
                    }
                    tempCheckLists = checklists.map((item) => {
                        return {
                            ...item,
                            disabled: false //selectedPhase.isActive ? !isAllowed : true
                        }
                    })
                } else {
                    tempCheckLists = checklists.map((item) => {
                        return {
                            ...item,
                            disabled: false
                        }
                    })
                }

                checklists = tempCheckLists.filter(c => c.name !== "")
            }

            const handleCheckChange = (e, id) => {
                // find update array phase
                let newArr = [...phases];
                let findSelectedPhaseIndex = newArr.findIndex(x => x.id === selectedPhase.id)
                if (findSelectedPhaseIndex > -1) {
                    newArr[findSelectedPhaseIndex].workflowsphasechecklists = newArr[findSelectedPhaseIndex].workflowsphasechecklists.map(item => item.id.toString() === id ? { ...item, isUnCheck: !e.target.checked } : item)
                }
                setPhases(newArr)
                //=========

                // update again selected phase
                setSelectedPhase(selectedPhase => ({
                    ...selectedPhase,
                    workflowsphasechecklists: selectedPhase.workflowsphasechecklists.map(item => item.id.toString() === id ? { ...item, isUnCheck: !e.target.checked } : item)
                }));
            }
            if (checklists.length > 0) {
                return (
                    <Form.Item label={t('checklists')}>
                        <CheckListStyled>
                            {
                                checklists.map((item) => (
                                    <Fragment key={item.id}>
                                        <Checkbox
                                            disabled={item.disabled}
                                            checked={item.isUnCheck === undefined ? false : !item.isUnCheck}
                                            onChange={(e) => handleCheckChange(e, item.id)}
                                        >
                                            {item.name}
                                        </Checkbox>
                                        <br />
                                    </Fragment>
                                ))
                            }
                        </CheckListStyled>
                    </Form.Item>
                )

            } else return <div style={{ paddingTop: 40 }}></div>
        }



        let timeout;
        let currentValue;
        const fetchSearchProjectTeam = (value, callback) => {
            setFetching(true)
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            currentValue = value;

            const searchUserTeam = () => {
                topicStore.getSearchUserTeam(projectStore.projectDetail.id, value)
                    .then(() => {
                        if (currentValue === value) {
                            const results = topicStore.userTeamList;
                            const data = [];
                            if (typeof results !== 'undefined') {
                                results.forEach(r => {
                                    data.push(r);
                                });
                            }
                            callback(data);
                        }
                    });
            }
            timeout = setTimeout(searchUserTeam, 300);
        }

        const handleSearchProjectTeam = (value) => {
            setUserTeamList([]);
            if (value) {
                fetchSearchProjectTeam(value, data => {
                    setUserTeamList(data)
                    setFetching(false)
                });
            }
        }

        const handleChangeAssignTo = (value) => {
            if (selectedPhase && selectedPhase.id) {
                // find update array phase
                let newArr = [...phases];
                let findSelectedPhaseIndex = newArr.findIndex(x => x.id === selectedPhase.id)
                if (findSelectedPhaseIndex > -1) {
                    let uassign = []
                    projectTeamsStore.teamList.filter(item => {
                        if (item.inviteStatus === 'accepted' && item.user && value.includes(item.user.id)) {
                            uassign.push(item.user)
                        }
                    })
                    newArr[findSelectedPhaseIndex].assigntopersons = uassign
                }
                setPhases(newArr)
            }
            setFetching(false)
        }

        useEffect(() => {
            if (userTeamList) {
                let users = userTeamList.map(d => d.id)
                setUserList(users)
            }
        }, [userTeamList])

        const UpdateCompletedPhase = (e) => {
            e.preventDefault()
            form.validateFields().then(async values => {
                let _completedphaseparam = {
                    topic: topicStore.viewingTopicForm.id,
                    phases: phases,
                    selectedPhase: selectedPhase,
                    assigntopersons: values.assigntopersons,
                    workflow: values.workflow
                }
                await topicStore.completeTopicPhase(_completedphaseparam).then((res) => {
                    form.resetFields(['assigntopersons']);
                    let _newtopicphase = res.map((item) => {
                        return {
                            id: item.workflowsphase, //workflowphase id
                            topicphaseId: item.id, //topicphase id
                            name: item.name,
                            order: item.order,
                            isActive: item.isActive,
                            workflowsphasechecklists: item.checklists.map((c) => {
                                return {
                                    id: c?.workflowsphasechecklist?.id,
                                    checklistId: c.id, // checklist id
                                    name: c.name,
                                    order: c.order,
                                    isUnCheck: c.isUnCheck,
                                    workflowsphasechecklist: c?.workflowsphasechecklist?.id

                                }
                            }),
                            assigntopersons: [...item.assigntopersons],
                            topic: item.topic,
                            completedDate: item?.completedDate,
                            startDate: item?.startDate,
                        }
                    })
                    _newtopicphase.sort((a, b) => a.order - b.order);
                    setPhases([..._newtopicphase]);
                    _newtopicphase.forEach(phase => {
                        if (phase.isActive) {
                            setSelectedPhase({ ...phase });
                            setUserTeamList(phase.assigntopersons)
                            let users = phase.assigntopersons && phase.assigntopersons?.length > 0 ? phase.assigntopersons?.map(d => d.id) : []
                            form.setFieldsValue({ 'assigntopersons': users })
                        }
                    });
                }).catch(error => {
                    if (error.data) message.error(t(error.data.message))
                })
            })
        }

        const handleChangeSliderComplete = (value) => {
            setTopicPercentCompleted(value)
        }

        const handleDeleteComment = (comment) => {
            let _discussions = {
                ...replyingTo,
                body: '',
                topic: topicStore.viewingTopicForm.id,
                statusChange: 'Deleted'
            }
            topicStore.editComment(comment._id, _discussions).then((res) => {
                if (topicStore.viewingTopicForm.topicdiscussions === undefined) {
                    topicStore.viewingTopicForm.topicdiscussions = []
                }

                topicStore.viewingTopicForm.topicdiscussions = topicStore.viewingTopicForm.topicdiscussions.map((x) => {
                    return x._id == res._id ? res : x
                })
                // topicStore.viewingTopicForm.topicdiscussions.push(res)

                let _commentlist = Utils.unflatten(topicStore.viewingTopicForm.topicdiscussions)
                setCommentList(_commentlist)
                setReplyingTo({})

            }).catch(error => {
                console.log(error)
            })
        }

        const AddReplyComment = () => {
            const [submittingComment, setSubmittingComment] = useState(false);
            const [commentValue, setCommentValue] = useState('')
            const [submittingEdit, setSubmittingEdit] = useState(false)

            useEffect(() => {
                return () => {
                    setSubmittingComment(false)
                    setCommentValue('')
                };
            }, [])
            useEffect(() => {
                if (replyingTo.isReply || replyingTo.isEdit) {
                    areaCommentInput.current.focus()

                }
            }, [replyingTo])


            const handleEditComment = () => {
                if (!commentValue) {
                    areaCommentInput.current.focus();
                    return;
                }
                setSubmittingEdit(true)

                let _discussions = {
                    ...replyingTo,
                    body: commentValue,
                    topic: topicStore.viewingTopicForm.id,
                    statusChange: 'Editted'
                }
                if (_discussions?.cloneData) {
                    _discussions.cloneData = {
                        ..._discussions?.cloneData,
                        updatedAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                    }
                }

                topicStore.editComment(replyingTo._id, _discussions).then((res) => {
                    if (topicStore.viewingTopicForm.topicdiscussions === undefined) {
                        topicStore.viewingTopicForm.topicdiscussions = []
                    }

                    topicStore.viewingTopicForm.topicdiscussions = topicStore.viewingTopicForm.topicdiscussions.map((x) => {
                        return x._id == res._id ? res : x
                    })

                    let _commentlist = Utils.unflatten(topicStore.viewingTopicForm.topicdiscussions)
                    setCommentList(_commentlist)
                    setReplyingTo({})
                }).catch(error => {
                    console.log(error)
                    setSubmittingEdit(false)
                })
            }

            const handleAddComment = () => {
                if (!commentValue) {
                    areaCommentInput.current.focus();
                    return;
                }
                setSubmittingComment(true)
                let _discussions = {
                    ...replyingTo,
                    body: commentValue,
                    topic: topicStore.viewingTopicForm.id,
                    statusChange: ''
                }

                topicStore.createComment(_discussions).then((res) => {
                    if (topicStore.viewingTopicForm.topicdiscussions === undefined) {
                        topicStore.viewingTopicForm.topicdiscussions = []
                    }
                    topicStore.viewingTopicForm.topicdiscussions.push(res)
                    let _commentlist = Utils.unflatten(topicStore.viewingTopicForm.topicdiscussions)
                    setCommentList(_commentlist)
                    setReplyingTo({})
                    setAuthorCreate(res.user.username)
                    console.log(authorCreate)
                }).catch(error => {
                    setSubmittingComment(false)
                    setCommentValue('')
                })
            };

            const handleChange = e => {
                e.preventDefault();
                setCommentValue(e.target.value)
            };

            const handleClickCancelReply = (e) => {
                e.preventDefault();
                setReplyingTo({})
            }

            useEffect(() => {
                if (replyingTo.isEdit && !replyingTo.isReply)
                    setCommentValue(replyingTo.body)
            }, [replyingTo.isEdit])
            return (
                <>
                    {replyingTo && replyingTo.isReply ? (
                        <ReplyHeaderStyle>
                            <Button className="cancel-right" onClick={(e) => handleClickCancelReply(e)}>{t('commons.cancel')}</Button>
                            {t('replying-to')} <b>{replyingTo.user.username}'s</b> {t('comment')} - <span className="reply-date">{moment(replyingTo.createdAt).format('MMM DD, YYYY HH:mm')}</span>
                        </ReplyHeaderStyle>) : ''
                    }
                    <Comment
                        avatar={
                            <Avatar
                                src={usersStore.currentUser.avatar ? usersStore.currentUser.avatar.url : defaultAvatar}
                                alt=""
                            />
                        }
                        content={replyingTo && replyingTo.isEdit ?
                            (
                                <>
                                    <TextArea ref={areaCommentInput} rows={4} onChange={(e) => { handleChange(e) }} value={commentValue} />
                                    <Form.Item>
                                        <Button style={{ marginTop: '5px' }} htmlType="submit" loading={submittingEdit} onClick={handleEditComment} type="primary"><FormOutlined />{t('edit-comment')}</Button>
                                    </Form.Item>
                                </>
                            ) : (
                                <>
                                    <TextArea ref={areaCommentInput} rows={4} onChange={(e) => { handleChange(e) }} value={commentValue} />
                                    <Form.Item>
                                        {
                                            checkingFeatureRole("feature_topic_list_edit") && (
                                                <Button style={{ marginTop: '5px' }} htmlType="submit" loading={submittingComment} onClick={handleAddComment} type="primary"><FormOutlined />{replyingTo && replyingTo.isReply ? t('add-reply') : t('add-discussions')}</Button>
                                            )
                                        }
                                    </Form.Item>
                                </>
                            )
                        }
                    />
                </>
            )
        }

        const MyCommentList = ({ comment, allComments, user_id, post_id }) => {
            const childComments = () => allComments.filter(c => c.parentId === comment.id)

            const handleClickReply = (comment) => {
                setReplyingTo({ ...comment, isReply: true })
            }

            const handleClickEdit = (comment) => {
                setReplyingTo({ ...comment, isEdit: true })
            }
            const timeCRComment = comment?.cloneData?.createdAt ? comment?.cloneData?.createdAt : comment?.createdAt;
            const timeUPComment = comment?.cloneData?.updateAt ? comment?.cloneData?.updateAt : comment?.updateAt;

            return (
                <Comment
                    author={
                        comment.user.username
                    }
                    avatar={
                        <Avatar
                            src={comment.user.avatar ? comment.user.avatar.url : defaultAvatar}
                        />
                    }
                    datetime={
                        comment.statusChange ? (<Tooltip title={moment(timeUPComment).format('MM-DD HH:mm')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                            <span>{moment(timeUPComment).format('MM-DD HH:mm')}  <Text type="warning"> {usersStore.currentUser.username === comment.user.username ? comment.statusChange : ''} </Text></span>
                        </Tooltip>) :
                            (<Tooltip title={moment(timeCRComment).format('MM-DD HH:mm')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                <span>{moment(timeCRComment).format('MM-DD HH:mm')}  <Text type="warning">{usersStore.currentUser.username === comment.user.username ? comment.statusChange : ''} </Text></span>
                            </Tooltip>)
                    }
                    content={comment.statusChange == 'Deleted' ? '' :
                        (usersStore.currentUser.username !== comment.user.username ?
                            (<>
                                <p style={{ display: 'inline', textAlign: 'justify' }}>{comment.body} </p>
                                <span key="comment-nested-reply-to" onClick={() => handleClickReply(comment)} className="replyto">{t('reply')}</span>
                            </>) : (<>
                                <p style={{ display: 'inline', textAlign: 'justify' }}>{comment.body} </p>
                                <span key="comment-nested-delete-to" className="replyto">
                                    <Popconfirm
                                        icon={
                                            <DeleteOutlined style={{ color: 'red' }} />

                                        }
                                        onConfirm={() =>
                                            handleDeleteComment(comment)
                                        }
                                        okType={'danger'}
                                        okText={t('commons.delete')}
                                        cancelText={t('commons.cancel')}
                                        title={t('are-you-sure-you-want-to-delete-this-comment')}>
                                        <Tooltip title={t('commons.delete')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>

                                            <DeleteOutlined key="delete" style={{ color: 'red' }} />
                                        </Tooltip>
                                    </Popconfirm>
                                </span>
                                <span style={{ padding: '0 5px' }} key="comment-nested-edit-to" className="replyto">
                                    <Popconfirm
                                        icon={
                                            <EditOutlined style={{ color: 'green' }} />

                                        }
                                        onClick={() => handleClickEdit(comment)}
                                        okType={'danger'}
                                        // okText={'Delete'}
                                        title={t('edit-comment')}>
                                        <Tooltip title={t('commons.edit')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                            <EditOutlined key="setting" />
                                        </Tooltip>
                                    </Popconfirm>
                                </span>
                                <span key="comment-nested-reply-to" onClick={() => handleClickReply(comment)} className="replyto">{t('reply')}</span>
                            </>)

                        )
                    }
                >
                    {childComments().map(c => {
                        if (c.children.length > 0) {
                            return (<MyCommentList
                                key={c.id}
                                comment={c}
                                allComments={c.children}
                                user_id={user_id}
                                post_id={post_id}
                            />)
                        } else {
                            return (<MyCommentList
                                key={c.id}
                                comment={c}
                                allComments={allComments}
                                user_id={user_id}
                                post_id={post_id}
                            />)
                        }

                    })
                    }
                </Comment>
            )
        }

        const clickGotoView = (res) => {
            goViewpoint(res, 'visit')
            if (res.treeData && res.treeData.clippingData && typeof res.treeData.clippingData === 'object') {
                projectStore.setViewMode('default mode')
                projectStore.setClippingViewPoint(res.treeData.clippingData)
                projectStore.setClippingMode(res.treeData.clippingData.clippingMode)
            } else {
                projectStore.setClippingMode(false)
                projectStore.setClippingViewPoint(false)
            }

            if(res.treeData && res.treeData?.hideAreaData && typeof res.treeData?.hideAreaData === 'object'){
                polylineCutToolStore.setHideArea(res.treeData.hideAreaData);
                projectStore.setProjectDetail({...projectStore.projectDetail, tilesetData: {...projectStore.projectDetail.tilesetData, hiddenArea: res.treeData.hideAreaData}});
                if(polylineCutToolStore.showForm) polylineCutToolStore.setShowForm(false)
            }else{
            polylineCutToolStore.setHideArea(false);
            }

            if(res.treeData && res.treeData?.navigationDistanceLimit){
                if(projectStore.navigationStyles && typeof projectStore.navigationStyles === 'object')
                  projectStore.setNavigationStyles({ ...projectStore.navigationStyles, distanceLimit: res.treeData?.navigationDistanceLimit })
                else projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, distanceLimit: res.treeData?.navigationDistanceLimit })
              }
            if(res.treeData){
                if(projectStore.navigationStyles && typeof projectStore.navigationStyles === 'object')
                  projectStore.setNavigationStyles({ ...projectStore.navigationStyles, allowUnderground: res.treeData?.navigationAllowUnderground ?? true})
                else projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, allowUnderground: res.treeData?.navigationAllowUnderground ?? true})
              }
        }

        return (
            <Drawer
                title={
                    <div className="help-btn-wrapper">
                        {topicStore.viewingTopicForm.label}
                        <Tooltip title={t('commons.help')} placement="bottom" overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                            <div>
                                <HelpButton helppage={'new_topic'} hovered={'black'}/>
                            </div> 
                        </Tooltip>
                    </div>
                }
                placement="right"
                width={drawerWidth}
                zIndex={99998}
                closable={true}
                onClose={e => handleCloseTopicEditor(false)}
                visible={topicStore.isShowTopicEditor}
                maskClosable={false}
                mask={false}
                destroyOnClose={true}
                forceRender
                className={topicStore.isShowTopicEditor ? "topicEditorDrawer custom-wraper-splitPanel" : ""}
            >
                <FormStyled>
                    {isTabletOrMobileDevice ? (
                        <div className="splitpanel-mobile">
                            <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                                <ColumnWidthOutlined />
                            </Button>
                        </div>
                    ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel" />)
                    }
                    <Spin spinning={topicStore.isLoading}>
                        <Form form={form} layout="vertical">
                            <Row gutter={32}>
                                <Col className="gutter-row" xs={24} md={topicStore.viewingTopicForm.isEdit ? (drawerWidth < 500 ? 24 : 8) : (drawerWidth < 500 ? 24 : 12)}>
                                    <Fragment>
                                        <Form.Item
                                            label={t('name')}
                                            name="name"
                                            initialValue={topicStore.viewingTopicForm.name}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('name-cannot-be-emptied'),
                                                },
                                                {
                                                    validator: validator.validateEmptyString,
                                                }
                                            ]}>
                                            <Input placeholder={t('input-topic-name')} />
                                        </Form.Item>
                                        <Form.Item label={t('description')} name="description" initialValue={topicStore.viewingTopicForm.description}>
                                            <TextArea placeholder={t('input-description')} rows={8} />
                                        </Form.Item>
                                        <Row gutter={16}>
                                            <Col span={12} className="gutter-row">
                                                <PopulateDropdown
                                                    data={topicStore.topicTypes}
                                                    label={t('type')}
                                                    fieldName={'topictype'}
                                                    message={t('type-cannot-be-emptied')}
                                                    initialValue={topicStore.viewingTopicForm.topictype}
                                                    isRequired={false}
                                                />
                                            </Col>
                                            <Col span={12} className="gutter-row">
                                                <PopulateDropdown
                                                    data={topicStore.topicPriorities}
                                                    label={t('priority')}
                                                    fieldName={'topicpriority'}
                                                    message={t('priority-cannot-be-emptied')}
                                                    initialValue={topicStore.viewingTopicForm.topicpriority}
                                                    isRequired={false}
                                                />
                                            </Col>
                                        </Row>
                                        <PopulateDropdown
                                            data={topicStore.topicStatuses}
                                            label={t('status')}
                                            fieldName={'topicstatus'}
                                            message={t('status-cannot-be-emptied')}
                                            initialValue={topicStore.viewingTopicForm?.topicstatus}
                                            isRequired={false}
                                        />
                                        <PopulateTag
                                            data={topicStore.topicTags || []}
                                            initialValue={topicStore.viewingTopicForm?.topictags}
                                        />
                                        <>
                                            <div>{t('views')}:</div>
                                            <Row gutter={16} style={{ marginBottom: '10px' }}>
                                                <Col span={24}>
                                                    {(topicStore.list3DViewTopic?.length > 0 && topicStore.viewingTopicForm?.name) ? (
                                                        <Carousel responsive={responsiveCarousel}>
                                                            {topicStore.list3DViewTopic
                                                                .sort((a, b) => a.order - b.order)
                                                                .map((res, index) => {
                                                                    return (
                                                                        <div key={res.id} onClick={() => clickGotoView(res)} >
                                                                            <img
                                                                                src={`${res?.image?.url}`}
                                                                                className="location-image-carousel"
                                                                                alt={res.name}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                        </Carousel>
                                                    ) : !topicStore.viewingTopicForm.isEdit && !topicStore.isCheckSaveViewDone ? <Carousel responsive={responsiveCarousel}>
                                                        <div style={{textAlign: 'center'}}>
                                                            <Spin className="location-image-carousel" />
                                                            Generating HD Image
                                                        </div>
                                                    </Carousel> : ''}
                                                </Col>
                                            </Row>
                                        </>
                                    </Fragment>
                                </Col>
                                <Col className="gutter-row" xs={24} md={topicStore.viewingTopicForm.isEdit ? (drawerWidth < 500 ? 24 : 8) : (drawerWidth < 500 ? 24 : 12)}>
                                    <ColStyled>
                                        <PopulateWorkflow workflowData={workflowStore.workflowList} />
                                        <PopulatePhase />
                                        <Form.Item
                                            label={t('assign-to-persons')}
                                            name="assigntopersons"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: t('no-assign-to-persons-selected'),
                                                }
                                            ]}
                                            initialValue={userList}
                                        >
                                            <Select
                                                mode="multiple"
                                                showSearch
                                                placeholder={t('person-to-assign')}
                                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                                filterOption={false}
                                                onSearch={val => handleSearchProjectTeam(val)}
                                                onChange={value => handleChangeAssignTo(value)}
                                            >
                                                {userTeamList?.length > 0 && userTeamList?.map(d => {
                                                    if (typeof d === 'object') {
                                                        return (<Option key={d.id}>
                                                            <Tooltip title={(d?.firstName && d?.lastName ?
                                                                d.firstName + ' ' + d.lastName : !d?.firstName && d?.lastName ?
                                                                    d.lastName : d?.firstName && !d?.lastName ?
                                                                        d.firstName : d.username) + " (" + d.email + ")"}
                                                                placement="right"
                                                                overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                <div style={{ width: "100%" }}>
                                                                    <div
                                                                    >
                                                                        {
                                                                            d?.firstName && d?.lastName ?
                                                                                d.firstName + ' ' + d.lastName : !d?.firstName && d?.lastName ?
                                                                                    d.lastName : d?.firstName && !d?.lastName ?
                                                                                        d.firstName : d.username
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Tooltip>

                                                        </Option>)
                                                    }
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <PopulateChecklist />
                                        <span className="label">{selectedPhase ? t('phase-completeness') : t('completeness')}</span>
                                        {selectedPhase ?
                                            <Slider className="custom-slider" tooltipVisible getTooltipPopupContainer={() => document.querySelector(".ant-slider-step")} value={percentCurrentPhaseCompleted} />
                                            :
                                            <Slider className="custom-slider" step={25} defaultValue={0} tooltipVisible getTooltipPopupContainer={() => document.querySelector(".ant-slider-step")} onChange={val => handleChangeSliderComplete(val)} value={topicPercentComleted} />
                                        }
                                        {topicStore.viewingTopicForm.isEdit && percentCurrentPhaseCompleted === 100 ? <Button type="primary" onClick={(e) => { UpdateCompletedPhase(e) }}>{t('complete-phase')}</Button> : ''}
                                    </ColStyled>
                                </Col>
                                {
                                    topicStore.viewingTopicForm.isEdit ? (
                                        <Col className="gutter-row" xs={24} md={drawerWidth < 500 ? 24 : 8}>
                                            <Form.Item label={t('discussions')}>
                                                <CommentListStyle>
                                                    {commentList.map(c => {
                                                        return (<MyCommentList
                                                            key={c.id}
                                                            comment={c}
                                                            allComments={c.children}
                                                            user_id={usersStore.currentUser.id}
                                                            post_id={topicStore.viewingTopicForm.id}
                                                        />)
                                                    })}
                                                </CommentListStyle>
                                                <AddReplyComment />
                                            </Form.Item>
                                        </Col>
                                    ) : ''
                                }
                            </Row>
                            <Row>
                                <Col style={{ marginBottom: '10px' }} xs={24}>
                                    {topicStore.list3DObjectsTopic?.length ? (
                                        <>
                                            <div>{t('objects')}:</div>
                                            <Table
                                                rowKey="id"
                                                columns={[
                                                    {
                                                        title: t('folder'),
                                                        dataIndex: 'folderTree',
                                                        key: 'folderTree'
                                                    }, {
                                                        title: t('file'),
                                                        dataIndex: 'model3DName',
                                                        key: 'model3DName',
                                                        render: (text, item) => item?.fileName
                                                    },
                                                    {
                                                        title: t('object-type'),
                                                        dataIndex: 'name',
                                                        key: 'objectType',
                                                        render: (text) => {
                                                            const textSplit = text.split("'")
                                                            return textSplit?.length > 1 ? textSplit[0] : text;
                                                        }
                                                    },
                                                    {
                                                        title: t('object-name'),
                                                        dataIndex: 'name',
                                                        key: 'objectName',
                                                        render: (text) => {
                                                            const textSplit = text.split("'")
                                                            return textSplit?.length > 1 ? textSplit[1] : '';
                                                        }
                                                    },
                                                    {
                                                        title: t('action'),
                                                        dataIndex: 'Action',
                                                        key: 'action',
                                                        render: (text, item) => {
                                                            return (
                                                                <Space>
                                                                    <Popconfirm
                                                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                                        onConfirm={() => topicStore.deleteTopic3DObject(item.id)}
                                                                        okButtonProps={{ danger: true }}
                                                                        okText={t('commons.delete')}
                                                                        cancelText={t('commons.cancel')}
                                                                        title={t('are-you-sure-you-want-to-delete-this-topic-object')}>
                                                                        <Tooltip title={t('commons.delete')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                            <DeleteOutlined />
                                                                        </Tooltip>
                                                                    </Popconfirm>
                                                                    {/* <Tooltip title={'Zoom to object'}>
                                                                    <ZoomInOutlined onClick={() => zoomTo3DTileFeature(item)} style={{ cursor: 'pointer' }} />
                                                                </Tooltip> */}
                                                                </Space>
                                                            )
                                                        }
                                                    }
                                                ]}
                                                dataSource={topicStore.list3DObjectsTopic}
                                                pagination={false}
                                                scroll={{ x: 600, y: 400 }}
                                                locale={{ emptyText: t('no-data') }}
                                            />
                                        </>
                                    ) : ''}
                                </Col>
                            </Row>
                        </Form>
                    </Spin>
                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            zIndex: 9999,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                        }}
                    >
                        {
                            checkingFeatureRole("feature_topic_list_edit") && (
                                <>
                                    <Button disabled={!topicStore.viewingTopicForm.isEdit && !topicStore.isCheckSaveViewDone ? true : false} type="primary" onClick={(e) => handleClickShow3DView(e)}>{t('view')}</Button>
                                    <Button type="primary" onClick={(e) => handleClickShow3DLocation(e)}>{t('location')}</Button>
                                    <Button type="primary" className="lastbtn" onClick={(e) => handleClickShow3DObjects(e)}>{t('link-to-objects')}</Button>
                                </>
                            )
                        }

                        <div style={{ float: 'right' }}>
                            {
                                checkingFeatureRole("feature_topic_list_edit") && (
                                    <Button onClick={(e) => handleSubmit(e, topicStore.viewingTopicForm.isEdit)} type="primary" disabled={!topicStore.viewingTopicForm.isEdit && !topicStore.isCheckSaveViewDone ? true : topicStore.isLoading ? true : false}>
                                        {t('commons.save')}
                                    </Button>
                                )
                            }
                            <Button onClick={e => handleCloseTopicEditor(false)} style={{ marginLeft: 8 }}>{t('commons.cancel')}</Button>
                        </div>
                    </div>
                </FormStyled>
            </Drawer>
        )
    }

    CustomForm = inject('topicStore')(observer((CustomForm)))

    return (
        <Fragment>
            <CustomForm />
        </Fragment>
    )
}

export default withRouter(inject(
    'topicStore',
    'workflowStore',
    'usersStore',
    'projectStore',
    'schedulingStore',
    'adminStore',
    'sketchingStore',
    'feedbackStore',
    'projectTeamsStore',
    'commonStore',
    'projectSettingStore',
    'polylineCutToolStore'
)(observer(TopicEditorDrawer)))
