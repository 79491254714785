import React, { useState } from 'react';
import { Row, Modal, Button, Checkbox, Col, message } from 'antd';
import { inject, observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next';
import { renderTermsPolicyHref } from '@/lib/projectLib';

const Termandconditions = ({ acceptTerm, data, showPolicyTermAndConditions, usersStore, adminStore }) => {
    const { t } = useTranslation();
    const [policy, setPolicy] = useState(false);
    const [termConditions, setTermConditions] = useState(false);
    const [loading, setLoadingProgress] = useState(false);

    const acceptTermAndConditions = async () => {
        let value = {}
        value.data = data
        value.privacyPolicy = data.policy
        value.termsConditions = data.terms
        let historyPolicy = {
            user: usersStore.currentUser.id,
            policy: data.policy,
            terms: data.terms
        }
        adminStore.createHistoryPolicy(historyPolicy)
        setLoadingProgress(true)
        usersStore.updateUser(usersStore.currentUser.id, value).then(res => {
            setLoadingProgress(false)
            acceptTerm()
        })
            .catch(err => {
                message.error(t('an-error-occurred'))
            })
    }
    return (
        <Modal
            className='terms-conditions'
            zIndex={9999}
            title={t('updated-terms-and-conditions-and-privacy-policy')}
            visible={showPolicyTermAndConditions}
            maskClosable={false}
            footer={false}
        >
            <p>{t('we-have-updated-our-terms-and-Conditions-and-privacy-policy')}:</p>
            <div><Checkbox onChange={e => setPolicy(e.target.checked)}><a target='_blank' href={renderTermsPolicyHref(data,'policy')} style={{ color: '#ff8d4f' }}>{t('i-accept-the-privacy-policy')}</a></Checkbox></div>
            <div style={{ marginBottom: '10px' }}><Checkbox onChange={e => setTermConditions(e.target.checked)}><a target='_blank' href={renderTermsPolicyHref(data,'terms')} style={{ color: '#ff8d4f' }}>{t('i-accept-the-terms-and-conditions')}</a></Checkbox></div>
            <Row gutter={[8, 8]}>
                <Col> <Button ><a style={{ textDecoration: "none", color: '#000000' }} href='https://www.xd-twin.io/terms/decline-info'>{t('decline')}</a> </Button> </Col>
                <Col> <Button loading={loading} onClick={acceptTermAndConditions} disabled={!policy || !termConditions} type='primary'>{t('commons.accept')}</Button></Col>
            </Row>
        </Modal>
    );
}

export default inject('usersStore', 'adminStore')(observer(Termandconditions))
