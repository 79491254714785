import React, { useEffect, useState, useRef } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Upload, Radio, Space, Divider, Tree } from 'antd';
import { inject, observer } from 'mobx-react';
import { isFileTypeAllowed, convertKeyToFileid, convertModelToKey } from '../../Gantt/ganttUtils';
import { useTranslation } from 'react-i18next';

const { DirectoryTree } = Tree

// Định nghĩa các loại file được chấp nhận
const SUPPORTED_FILE_TYPES = {
  MS_PROJECT:
    '.mpp,.xml,text/xml,application/xml,application/vnd.ms-project,application/msproj,application/msproject,application/x-msproject,application/x-ms-project,application/x-dos_ms_project,application/mpp,zz-application/zz-winassoc-mpp',
  PRIMAVERA: '.xer,.xml,text/xml,application/xml,application/xer',
};

// Component Modal xử lý import/export Gantt
const GanttImportExportModal = ({ projectGanttStore, projectStore }) => {
  const { t } = useTranslation();
  const {
    isOpenModalExportnImportProject,
    setIsOpenModalExportnImportProject,
    setImportFile,
    isUploading,
    setIsUploading,
  } = projectGanttStore;

  const [fileList, setFileList] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState('ms_project');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [processedTreeData, setProcessedTreeData] = useState([]);

  // Reset trạng thái khi modal đóng hoặc mở lại
  useEffect(() => {
    return () => {
      setFileList([]);
      setSelectedProjectType('ms_project');
      setSelectedKeys([]);
      setProcessedTreeData([]);
    };
  }, [isOpenModalExportnImportProject]);

  // Xử lý thay đổi loại project
  const handleProjectTypeChange = (e) => {
    const newType = e.target.value;
    setSelectedProjectType(newType);
    setFileList([]); // Reset fileList khi thay đổi loại project
    setSelectedKeys([]); // Reset selectedKeys khi thay đổi loại project

    if (newType === 'treedata') {
      const treeData = projectStore.projectDetail?.treeData;
      if (treeData?.length) {
        const filteredTree = filterDataTreeByExt(projectStore.projectDetail.treeData, '.ifc');
        const restructuredData = processTreeData(filteredTree);
        setProcessedTreeData(restructuredData);

        const initialSelectedKeys = convertModelToKey(projectStore.projectDetail.treeData, projectGanttStore.selectedNodeInDataTree);
        setSelectedKeys(initialSelectedKeys || []);
      }
    } else {
      setProcessedTreeData([]); // Reset tree data khi không chọn treedata
    }
  };

  // Xử lý import file hoặc tree data
  const handleImport = async () => {
    if (selectedProjectType === 'treedata') {
      const selectedFileIds = convertKeyToFileid(projectStore.projectDetail?.treeData, selectedKeys);
  
      if (selectedFileIds.length > 0) {
        try {
          projectStore.setLoadingProgress(true)
          await projectGanttStore.transformObjectInfoToTask({
            projectId: projectStore.projectDetail?.id,
            fileids: selectedFileIds,
          });
          message.success(t('import-project-data-success'));
        } catch (error) {
          message.error('Failed to import tree data');
        } finally {
          projectStore.setLoadingProgress(false)
        }
      } else {
        console.warn('No valid file IDs selected for import');
      }
  
      // Đóng modal và reset selectedKeys
      setIsOpenModalExportnImportProject({ type: 'import', open: false });
      setSelectedKeys([]);
      await projectGanttStore.getProject4dGantt(projectStore.projectDetail.id);
    } else if (fileList.length > 0) {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('file', fileList[0]);
  
      try {
        await setImportFile({ file: formData, type: selectedProjectType });
        console.log('File import initiated successfully');
      } catch (error) {
        console.error('Failed to initiate file import:', error);
      } finally {
        setIsUploading(false); // Đảm bảo trạng thái uploading được reset
      }
    } else {
      console.warn('No file selected for import');
    }
  };

  // Xử lý export file
  const handleExport = () => {
    const payload = {
      skip_circular_links: false,
      tasks: {
        Finish: (task) => task.end_date,
      },
    };
    const exportMethod =
      selectedProjectType === 'ms_project' ? 'exportToMSProject' : 'exportToPrimaveraP6';
    window.gantt[exportMethod](payload);
  };

  // Cấu hình props cho Upload component
  const uploadProps = {
    maxCount: 1,
    multiple: false,
    accept:
      selectedProjectType === 'ms_project'
        ? SUPPORTED_FILE_TYPES.MS_PROJECT
        : SUPPORTED_FILE_TYPES.PRIMAVERA,
    fileList,
    onRemove: (file) => {
      setFileList((prev) => prev.filter((f) => f !== file));
    },
    beforeUpload: (file) => {
      const isAllowed = isFileTypeAllowed(file, selectedProjectType);
      if (!isAllowed) {
        message.error(
          t(
            `gantt.you-can-only-upload-${selectedProjectType === 'ms_project' ? 'ms-project' : 'primavera-file'
            }`
          )
        );
        return false;
      }
      setFileList([file]);
      return false; // Ngăn upload tự động
    },
  };


  // #region Process tree data
  const filterDataTreeByExt = (data, targetExt = '.ifc') => {
    const filterNode = (node) => {
      if (node.ext === targetExt) return { ...node };
      if (node.children?.length) {
        const filteredChildren = node.children.map(filterNode).filter(Boolean);
        if (filteredChildren.length) return { ...node, children: filteredChildren };
      }
      return null;
    };
    return data.map(filterNode).filter(Boolean);
  };

  const isModel = (node) => node?.modelId || false;
  const isFolder = (node) => node?.type === 'FOLDER' || false;
  const is3DCityDB = (node) => isModel(node) && node.sourceType === 'City3DDB';
  const isWMS = (node) => isModel(node) && (node.sourceType === 'WMS' || node.sourceType === 'WFS');
  const isLeafNode = (node) =>
    !node ||
    !(
      (isFolder(node) && node.children?.length) ||
      (isModel(node) &&
        ['ifc', 'cad', 'landxml', 'landxmlBackground', 'external-plugin'].includes(node.modelType)) ||
      isWMS(node) ||
      is3DCityDB(node)
    );

  const processTreeData = (data, parentKey = null) => {
    return data.map((item) => {
      const updatedItem = { ...item, parentKey, isLeaf: isLeafNode(item) };
      if (item.children) updatedItem.children = processTreeData(item.children, item.key);
      return updatedItem;
    });
  };

  const handleCheck = (checkedKeys) => setSelectedKeys(checkedKeys);
  // #endregion

  // Đóng modal
  const handleClose = () => {
    setIsOpenModalExportnImportProject({ type: 'import', open: false });
  };


  const isImportMode = isOpenModalExportnImportProject.type === 'import';
  const modalTitle = `${isImportMode ? t('commons.import') : t('commons.export')} ${t('project')}`;
  const isTreeDataMode = isImportMode && selectedProjectType === 'treedata';

  return (
    <Modal
      title={modalTitle}
      visible={isOpenModalExportnImportProject.open}
      footer={null}
      zIndex={10001}
      onCancel={handleClose}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Radio.Group value={selectedProjectType} onChange={handleProjectTypeChange}>
          <Radio value="ms_project" style={{ marginRight: 10 }}>
            MS Project
          </Radio>
          <Radio value="primavera" style={{ marginRight: 10 }}>
            Primavera
          </Radio>
          <Radio value="treedata">Import from IFC</Radio>
        </Radio.Group>

        <Divider />

        {isImportMode ?
          isTreeDataMode ? (
            <>
              <DirectoryTree
                multiple
                defaultExpandAll
                checkable
                checkedKeys={selectedKeys}
                treeData={processedTreeData}
                onCheck={handleCheck}
                disabled={projectGanttStore.isReadonlyMode}
                style={{ maxHeight: 'calc(100vh - 500px)', overflowY: 'auto' }}
              />
              <Divider />
              <Button type="primary" onClick={handleImport} disabled={!selectedKeys.length}>
                {t('commons.import')}
              </Button>
            </>
          ) : (
            <>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>{t('gantt.select-file')}</Button>
              </Upload>
              <Button
                type="primary"
                onClick={handleImport}
                disabled={!fileList.length}
                loading={isUploading}
                style={{ marginTop: 16 }}
              >
                {isUploading ? t('importing') : t('commons.import')}
              </Button>
            </>
          ) : (
            <Button type="primary" onClick={handleExport}>
              {t('commons.export')}
            </Button>
          )}
      </Space >
    </Modal >
  );
};

export default inject('projectGanttStore', 'projectStore')(observer(GanttImportExportModal));