import { addAllVirtualEditPoints, getMidpoint, clickPoint } from '@/helper'
import confirmDelete from './ConfirmDelete';
import polylineCutToolStore from '../../../../stores/polylineCutToolStore';
import { toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

const CesiumDrawing = {};
const Cesium = window.Cesium;

/**
 * Extends an entity with events and functions to enable editing.
 */
CesiumDrawing.extendEntity = function (entity) {
  entity.startEdit = new Cesium.Event();
  entity.stopEdit = new Cesium.Event();
  entity.edited = new Cesium.Event();
  entity.onPreviewed = new Cesium.Event();
  entity.startEditing = function () {
    entity.startEdit.raiseEvent(entity);
  };

  entity.stopEditing = function () {
    entity.stopEdit.raiseEvent(entity);
  };

  entity.onEdit = function (editPoint) {
    entity.edited.raiseEvent(entity, editPoint);
  };
  entity.onPreview = function (editPoint) {
    entity.onPreviewed.raiseEvent(entity, editPoint);
  };
};
CesiumDrawing.computeRectanglePoints = function (entity) {
  try {
    var scratchRectanglePoints = [
      new Cesium.Cartesian3(),
      new Cesium.Cartesian3(),
      new Cesium.Cartesian3(),
      new Cesium.Cartesian3(),
    ];

    var obj = entity.rectangle.coordinates.getValue();
    if (obj) {
      const rotation = entity.rectangle.rotation
        ? entity.rectangle.rotation
        : 0;
      var rectangleScratch = new Cesium.Rectangle();
      var nwScratch = new Cesium.Cartographic();
      var computedOptions = Cesium.RectangleGeometryLibrary.computeOptions(
        obj,
        Cesium.Math.RADIANS_PER_DEGREE,
        rotation,
        0,
        rectangleScratch,
        nwScratch,
      );

      var height = computedOptions.height;
      var width = computedOptions.width;

      var recPositions = scratchRectanglePoints;
      Cesium.RectangleGeometryLibrary.computePosition(
        computedOptions,
        Cesium.Ellipsoid.WGS84,
        false,
        0,
        0,
        recPositions[0],
      );
      Cesium.RectangleGeometryLibrary.computePosition(
        computedOptions,
        Cesium.Ellipsoid.WGS84,
        false,
        0,
        width - 1,
        recPositions[1],
      );
      Cesium.RectangleGeometryLibrary.computePosition(
        computedOptions,
        Cesium.Ellipsoid.WGS84,
        false,
        height - 1,
        0,
        recPositions[2],
      );
      Cesium.RectangleGeometryLibrary.computePosition(
        computedOptions,
        Cesium.Ellipsoid.WGS84,
        false,
        height - 1,
        width - 1,
        recPositions[3],
      );
      if (recPositions.flatMap(p => [p.x, p.y]).filter(x => isNaN(x)).length)
        return [];
      return [
        recPositions[0],
        recPositions[1],
        recPositions[3],
        recPositions[2],
        recPositions[0],
      ];
    }
  } catch (error) {
    return [];
  }
};
/**
 * Create a new editor that manages overall drawing and editing capabilities for a Viewer.
 */
CesiumDrawing.Editor = function (viewer) {
  this.viewer = viewer;
  this.initializeDraggerHandler();
};

CesiumDrawing.Editor.prototype.startEditing = function (entity, mode, collections, pushSnapPoint, entities, wireframeStructure, pushEntity) {
  if (!entity)
    return
  entity.startEditing();
  if (entity.polygon && entity.drawData !== 'draw-square') {
    if (mode === 'wireframe') {
      entity.editor = new CesiumDrawing.PolygonEditor(this, entity, mode, collections, pushSnapPoint, entities, pushEntity);
    }
    else {
      entity.editor = new CesiumDrawing.PolygonEditor(this, entity);
    }
  }
  if (entity.polygon && entity.drawData === 'draw-square') {
    entity.editor = new CesiumDrawing.TestSquareEditor(this, entity);
  }
  if (entity.rectangle) {
    entity.editor = new CesiumDrawing.RectangleEditor(this, entity);
  }
  this.currentEditEntity = entity;
};

CesiumDrawing.Editor.prototype.stopEditing = function (entity, onStopEdit) {
  entity.stopEditing();

  if (entity.editor) {
    entity.editor.destroy();
    entity.editor = null;
  }

  // Mark the position properties as being constant since we are done editing.
  // You will see a flash as the geometry rebuilds, but rendering performance of the static geometries will
  // be faster.
  if (entity.polyline) {
    entity.polyline.positions.isConstant = true;
  } else if (entity.polygon) {
    entity.polygon.hierarchy.isConstant = true;
  } else if (entity.corridor) {
    entity.corridor.positions.isConstant = true;
  }
  if (onStopEdit) {
    onStopEdit(entity);
  }
};

/**
 * Initialize the utility handler that will assist in selecting and manipulating Dragger billboards.
 */
CesiumDrawing.Editor.prototype.initializeDraggerHandler = function () {
  const self = this;
  // Create the handler.
  var draggerHandler = new Cesium.ScreenSpaceEventHandler(this.viewer.canvas);

  // Initialize the active dragger to null;
  draggerHandler.dragger = null;

  // Left down selects a dragger
  draggerHandler.setInputAction(function (click) {
    try {
      var pickedObjects = self.viewer.scene.drillPick(click.position);
      if (Cesium.defined(pickedObjects)) {
        const getDragger = function (pickedObjects) {
          for (let i = 0; i < pickedObjects.length; i++) {
            const pickedObject = pickedObjects[i];
            if (!pickedObject?.id || pickedObject?.id._isDragger)
              return pickedObject;
          }
        };
        const pickedObject = pickedObjects
          ? getDragger(pickedObjects)
          : undefined;
        if (Cesium.defined(pickedObject)) {
          var entity = pickedObject.id;
          if (entity && Cesium.defaultValue(entity._isDragger, false)) {
            draggerHandler.dragger = entity;
            self.viewer.scene.screenSpaceCameraController.enableRotate = false;
            self.viewer.scene.screenSpaceCameraController.enableTilt = false;
            self.viewer.scene.screenSpaceCameraController.enableTranslate = false;
          }
        }
      }
    } catch (error) { }
  }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

  // Mouse move drags the draggers and calls their onDrag callback.
  draggerHandler.setInputAction(function (movement) {
    if (draggerHandler.dragger) {
      self.viewer.scene.screenSpaceCameraController.enableTilt = false;
      if (draggerHandler.dragger.horizontal) {
        let isClickObject = false
        toJS(polylineCutToolStore.hideArea.points).map((item, index) => {
          if (item) {
            if (Cesium.Cartographic.fromCartesian({ x: item[0], y: item[1], z: item[2] }).height > 5) {
              isClickObject = true
            }
          }
        })
        let resultClick = clickPoint(self.viewer, movement.endPosition)
        if (resultClick && resultClick.position) {
          let pointCartographic = Cesium.Cartographic.fromCartesian(resultClick.position)
          var hit = resultClick.position
          if (!isClickObject) {
            draggerHandler.dragger.position = hit;
            if (draggerHandler.dragger.onDrag) {
              draggerHandler.dragger.onDrag(draggerHandler.dragger, hit);
            }
          } else if (pointCartographic.height > 0) {
            draggerHandler.dragger.position = hit;
            if (draggerHandler.dragger.onDrag) {
              draggerHandler.dragger.onDrag(draggerHandler.dragger, hit);
            }
          }
        }
      }

      if (draggerHandler.dragger.vertical) {
        var dy = movement.endPosition.y - movement.startPosition.y;
        var position = draggerHandler.dragger.position._value;
        var tangentPlane = new Cesium.EllipsoidTangentPlane(position);

        scratchBoundingSphere.center = position;
        scratchBoundingSphere.radius = 1;

        var metersPerPixel = self.viewer.scene.frameState.camera.getPixelSize(
          scratchBoundingSphere,
          self.viewer.scene.frameState.context.drawingBufferWidth,
          self.viewer.scene.frameState.context.drawingBufferHeight,
        );

        var zOffset = new Cesium.Cartesian3();

        Cesium.Cartesian3.multiplyByScalar(
          tangentPlane.zAxis,
          -dy * metersPerPixel,
          zOffset,
        );
        var newPosition = Cesium.Cartesian3.clone(position);
        Cesium.Cartesian3.add(position, zOffset, newPosition);

        draggerHandler.dragger.position = newPosition;
        if (draggerHandler.dragger.onDrag) {
          draggerHandler.dragger.onDrag(draggerHandler.dragger, newPosition);
        }
      }
    }
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

  var scratchBoundingSphere = new Cesium.BoundingSphere();

  // Mouse move drags the draggers and calls their onDrag callback.
  draggerHandler.setInputAction(
    function (movement) {
      if (draggerHandler.dragger && draggerHandler.dragger.verticalCtrl) {
        var dy = movement.endPosition.y - movement.startPosition.y;
        var position = draggerHandler.dragger.position._value;
        var tangentPlane = new Cesium.EllipsoidTangentPlane(position);

        scratchBoundingSphere.center = position;
        scratchBoundingSphere.radius = 1;

        var metersPerPixel = self.viewer.scene.frameState.camera.getPixelSize(
          scratchBoundingSphere,
          self.viewer.scene.frameState.context.drawingBufferWidth,
          self.viewer.scene.frameState.context.drawingBufferHeight,
        );

        var zOffset = new Cesium.Cartesian3();

        Cesium.Cartesian3.multiplyByScalar(
          tangentPlane.zAxis,
          -dy * metersPerPixel,
          zOffset,
        );
        var newPosition = Cesium.Cartesian3.clone(position);
        Cesium.Cartesian3.add(position, zOffset, newPosition);

        draggerHandler.dragger.position = newPosition;
        if (draggerHandler.dragger.onDrag) {
          draggerHandler.dragger.onDrag(draggerHandler.dragger, newPosition);
        }
      }
    },
    Cesium.ScreenSpaceEventType.MOUSE_MOVE,
    Cesium.KeyboardEventModifier.CTRL,
  );

  // Left up stops dragging.
  draggerHandler.setInputAction(function () {
    if (draggerHandler.dragger) {
      draggerHandler.dragger = null;
      self.viewer.scene.screenSpaceCameraController.enableRotate = true;
      self.viewer.scene.screenSpaceCameraController.enableTilt = true;
      self.viewer.scene.screenSpaceCameraController.enableTranslate = true;
    }
  }, Cesium.ScreenSpaceEventType.LEFT_UP);

  // Left up stops dragging.
  draggerHandler.setInputAction(
    function () {
      if (draggerHandler.dragger) {
        draggerHandler.dragger = null;
        self.viewer.scene.screenSpaceCameraController.enableRotate = true;
        self.viewer.scene.screenSpaceCameraController.enableTilt = true;
        self.viewer.scene.screenSpaceCameraController.enableTranslate = true;
      }
    },
    Cesium.ScreenSpaceEventType.LEFT_UP,
    Cesium.KeyboardEventModifier.CTRL,
  );

  this.draggerHandler = draggerHandler;
};

/**
 * Creates a Dragger
 */
CesiumDrawing.Editor.prototype.createDragger = function (options) {
  const self = this;
  let dragger = null;

  var position = Cesium.defaultValue(
    options.position,
    Cesium.Cartesian3.ZERO,
  ).clone();

  var onDrag = Cesium.defaultValue(options.onDrag, null);
  if (options.typePoint == "midPoint") {
    dragger = self.viewer.entities.add({
      position: position,
      pointMid: options.pointData.pointMid,
      pst: position,
      typePoint: "midPoint",
      namePoint: options.pointData.namePoint,
      point: {
        pixelSize: 6,
        color: Cesium.Color.BLUE.withAlpha(0.4),
        outlineColor: Cesium.Color.WHITE.withAlpha(0.4),
        outlineWidth: 2,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        heightReference: Cesium.HeightReference.NONE
      },
    });
  } else {
    if (options.listPointEdit && options.listLinePolygonEdit) {
      let listPointData = []
      let point = null
      point = options.listPointEdit.filter(el => el.position.equals(options.position))
      for (var j = 0; j < options.listLinePolygonEdit.length; j++) {
        let item = options.listLinePolygonEdit[j]
        const pos = item[0];
        const cat3 = Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, pos.height)
        const pos1 = item[1];
        const cat31 = Cesium.Cartesian3.fromDegrees(pos1.lng, pos1.lat, pos1.height)
        if (item[0].PointId !== point[0]?.id && item[1].PointId === point[0]?.id) {
          listPointData.push({ position: cat3, id: item[0].PointId })
        }
        if (item[1].PointId !== point[0]?.id && item[0].PointId === point[0]?.id) {
          listPointData.push({ position: cat31, id: item[1].PointId })
        }
      }

      dragger = self.viewer.entities.add({
        position: position,
        pointId: point[0]?.id,
        listPointData: listPointData,
        point: {
          pixelSize: 8,
          color: Cesium.Color.RED,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 2,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          heightReference: Cesium.HeightReference.NONE
        },
        key:"dragger-" + uuidv4()
      });

    } else {
      dragger = self.viewer.entities.add({
        position: position,
        point: {
          pixelSize: 8,
          color: Cesium.Color.RED,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 2,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          heightReference: Cesium.HeightReference.NONE
        },
        key:"dragger-" + uuidv4()
      });
    }
  }
  dragger._isDragger = true;
  dragger.onDrag = onDrag;
  dragger.horizontal = Cesium.defaultValue(options.horizontal, true);
  dragger.vertical = Cesium.defaultValue(options.vertical, false);
  dragger.verticalCtrl = Cesium.defaultValue(options.vertical, false);

  return dragger;
};

/**
 * Creates a handler that lets you modify a list of positions.
 */
CesiumDrawing.Editor.prototype.createPositionsHandler = function (
  entity,
  positions,
  isMultiple,
  handle,
  collections,
  pushSnapPoint
) {
  var handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
  const self = this;
  let draggers = [];
  let snapPoint = null;
  const onPickposition = function (loc, i) {
    var dragger = self.createDragger({
      position: loc,
    });
    dragger.index = i;
    dragger.positions = positions;
    draggers.push(dragger);
  };
  if (!isMultiple) {
    const onCancel = function () {
      if (!handler.isDestroyed) handler.destroy();
      self.clearDragger();
    };
    const onFinish = function (mode) {
      if (mode === 'cancel') {
        entity.isCancel = true
      }
      entity.inProgress = false;
      if (handler.lastPointTemporary) {
        positions.pop();
      }
      polylineCutToolStore.setCancelDrawArea(true)
      handler.destroy();
      entity._originalPoints = draggers.map(x => x.position.getValue(self.viewer.clock.currentTime).clone());
      self.clearDragger();
      entity.onFinish = undefined;
      entity.stopEditing();
    };
    self.clearDragger = function () {
      for (let i = 0; i < draggers.length; i++) {
        self.viewer.entities.remove(draggers[i]);
      }
      draggers = [];
    };
    // Adds a point to the positions list.
    handler.lastPointTemporary = false;

    handler.setInputAction(function (movement) {
      if (movement.endPosition) {
        // var ray = self.viewer.camera.getPickRay(movement.endPosition);
        let resultClick = clickPoint(self.viewer, movement.endPosition)
        if (resultClick && resultClick.position) {
          var cartesian = resultClick.position
          if (handler.lastPointTemporary) {
            positions.pop();
          }
          positions.push(cartesian);
          handler.lastPointTemporary = true;
          entity.onPreview();
        }
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    handler.setInputAction(function (movement) {
      onFinish();
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);

    handler.setInputAction(function (movement) {
      var _cartesian = clickPoint(self.viewer, movement.position)
      var cartesian = _cartesian.position
      // if(!polylineCutToolStore.drawEditor)return
      try {
        var pickedObjects = self.viewer.scene.drillPick(movement.position);
        const getDragger = function (pickedObjects) {
          for (let i = 0; i < pickedObjects.length; i++) {
            const pickedObject = pickedObjects[i];
            if (!pickedObject?.id || pickedObject?.id._isDragger)
              return pickedObject;
          }
        };
        const pickedObject = getDragger(pickedObjects);
        if (Cesium.defined(pickedObject) && Cesium.defaultValue(pickedObject.id?._isDragger, false)) {
          if (pickedObject.id.index === 0) {
            onFinish();
          }
        } else {
          if (cartesian) {
            if (handler.lastPointTemporary) {
              positions.pop();
            }
            var index = positions.length;
            positions.push(cartesian);

            if (positions[positions.length - 2] && positions[positions.length - 2].x === positions[positions.length - 1].x && positions[positions.length - 2].y === positions[positions.length - 1].y) {
              positions.pop();
              return
            }
            onPickposition(cartesian, index)
            handler.lastPointTemporary = false;
            if (positions.length > 1) {

            }
            entity.onEdit(cartesian);
          }
        }
      } catch (error) { }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    // Replaces the last point in the list with the point under the mouse.
    return { onFinish, onCancel };
  } else {
    const onCancel = function () {
      handler.destroy();
      self.clearDragger();
      if (entity._polygon._hierarchy._value.positions.length > 2) {
        handle(entity);
      }
    };
    const onFinish = function () {
      entity.inProgress = false;
      if (handler.lastPointTemporary) {
        positions.pop();
      }
      handler.destroy();
      self.clearDragger();
      entity.onFinish = undefined;
      entity.stopEditing();
    };
    self.clearDragger = function () {
      for (let i = 0; i < draggers.length; i++) {
        self.viewer.entities.remove(draggers[i]);
      }
      draggers = [];
    };
    // Adds a point to the positions list.
    handler.lastPointTemporary = false;

    handler.setInputAction(function (movement) {
      let resultClick = clickPoint(self.viewer, movement.endPosition)
      if (resultClick && resultClick.position) {
        var cartesian = resultClick.position
        const minDistToSnap = 0.2;
        if (snapPoint && Cesium.Cartesian3.distance(snapPoint.point, cartesian) < minDistToSnap) {
          cartesian = snapPoint.point;
          pushSnapPoint(snapPoint);
        }
        try {
          var pickedObjects = self.viewer.scene.drillPick(movement.position);
          const getDragger = function (pickedObjects) {
            for (let i = 0; i < pickedObjects.length; i++) {
              const pickedObject = pickedObjects[i];
              if (!pickedObject?.id || pickedObject?.id._isDragger)
                return pickedObject;
            }
          };
          const pickedObject = getDragger(pickedObjects);
          if (
            Cesium.defined(pickedObject) &&
            Cesium.defaultValue(pickedObject.id?._isDragger, false)
          ) {
            if (pickedObject.id.index === 0) {
              onFinish();
            }
          } else {
            if (cartesian) {
              if (collections) {
                const minDistToSnap = 0.2;
                const length = Object.keys(collections).length;
                for (var i = 0; i < length; i++) {
                  if (collections[`C${i}`] && cartesian) {
                    let pos = collections[`C${i}`];
                    let cat3 = Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, pos.height)
                    if (Cesium.Cartesian3.distance(cat3, cartesian) < minDistToSnap) {
                      cartesian = cat3;
                      snapPoint = {
                        id: collections[`C${i}`].PointId,
                        point: cat3,
                      }
                    }
                    else {
                      snapPoint = null;
                    }
                  }
                }
              }
              if (handler.lastPointTemporary) {
                positions.pop();
              }
              var index = positions.length;
              if (snapPoint) {

                positions.push(snapPoint.point);
              }
              else {
                positions.push(cartesian);
              }

              if (
                positions[positions.length - 2] &&
                positions[positions.length - 2].x ===
                positions[positions.length - 1].x &&
                positions[positions.length - 2].y ===
                positions[positions.length - 1].y
              ) {
                positions.pop();
                return;
              }
              onPickposition(cartesian, index);
              handler.lastPointTemporary = false;
              if (positions.length > 1) {
              }
              entity.onEdit(cartesian);
              if (snapPoint) {
                snapPoint = null;
              }
            }
          }
        } catch (error) { }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    // Replaces the last point in the list with the point under the mouse.
    handler.setInputAction(function (movement) {
      if (movement.endPosition) {
        // var ray = self.viewer.camera.getPickRay(movement.endPosition);
        if (collections) {
          let resultClick = clickPoint(self.viewer, movement.endPosition)
          if (resultClick && resultClick.position) {
            var cartesian = resultClick.position
            const minDistToSnap = 0.2;
            const length = Object.keys(collections).length;
            for (var i = 0; i < length; i++) {
              if (collections[`C${i}`] && cartesian) {
                let pos = collections[`C${i}`];
                let cat3 = Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, pos.height)
                if (Cesium.Cartesian3.distance(cat3, cartesian) < minDistToSnap) {
                  cartesian = cat3;
                  snapPoint = {
                    id: collections[`C${i}`].PointId,
                    point: cat3,
                  }
                }
                else {
                  snapPoint = null;
                }
              }
            }
            if (cartesian) {
              if (handler.lastPointTemporary) {
                positions.pop();
              }
              positions.push(cartesian);
              handler.lastPointTemporary = true;
              entity.onPreview();
            }
          }
        }
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    handler.setInputAction(function (movement) {
      onFinish();
    }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    handler.setInputAction(click => {
      const self = this;
      let pickedObjects1 = self.viewer.scene.drillPick(click.position);
      const pickedObject1 = pickedObjects1.filter(el => el.id && el.id.point && el.id.index > -1)[0] || pickedObjects1[0];
      let pointDelete = pickedObject1?.id;
      if (pointDelete && pointDelete.index > -1 && entity) {

        // portalModalConfirm(
        //   'Warning',
        //   'Deletion point!',
        //   'Do you want to delete this point?',
        //   () => {
        //     let pointIndex = pointDelete.index;
        //     self.viewer.entities.remove(pointDelete);
        //     entity.polygon.hierarchy._value.positions.splice(pointIndex,1);
        //   },
        //   false,
        // );
      }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
    return { onFinish, onCancel };
  }
};

/**
 * Rectangle editor.
 */
CesiumDrawing.RectangleEditor = function (editor, entity) {
  const self = this;
  this.editor = editor;
  this.entity = entity;
  this.draggers = [];
  // const virtualPositions = entity.rectangle.coordinates.getValue()

  const originalPos = entity._originalPoints.map(x => x.clone());

  //var entityPositions = entity.rectangle.coordinates.getValue()
  const onCancel = function () {
    for (let i = 0; i < originalPos.length; i++) {
      const loc = originalPos[i];

      entity._currentPoints[i] = loc;
    }
    entity.onEdit();
    self.destroy();
  };
  entity.onCancel = onCancel;
  entity.rectangle.isConstant = false;
  entity.rectangle.coordinates.setCallback(
    entity.rectangle.coordinates._callback,
    false,
  );
  entity.polyline.positions.setCallback(
    entity.polyline.positions._callback,
    false,
  );
  for (let i = 0; i < originalPos.length; i++) {
    var loc = originalPos[i];

    var dragger = editor.createDragger({
      position: loc,
      onDrag: function (dragger, position) {
        dragger.positions[dragger.index] = position;
        entity._currentPoints = dragger.positions;
        entity.onEdit();
      },
    });
    dragger.index = i;
    dragger.positions = entity._currentPoints;
    self.draggers.push(dragger);
  }
};

CesiumDrawing.RectangleEditor.prototype.destroy = function () {
  for (var i = 0; i < this.draggers.length; i++) {
    this.editor.viewer.entities.remove(this.draggers[i]);
  }
  this.draggers = [];
};

/**
 * Polygon editor.
 */
CesiumDrawing.PolygonEditor = function (editor, entity, mode, collections, pushSnapPoint, entities, pushEntity) {
  const self = this;
  this.editor = editor;
  this.entity = entity;
  this.draggers = [];
  this.draggersMid = [];
  this.snapPoint = [];
  this.pointEdit = null;
  let sharedFace = [];
  let positionData = entity.polygon._hierarchy._value.positions.map(x => x.clone());
  var handler = new Cesium.ScreenSpaceEventHandler(this.editor.viewer.canvas);
  handler.setInputAction(function (click) {
    if (self.pointEdit && self.pointEdit.positions) {
      let positions = self.pointEdit.positions.map(item => [item.x, item.y, item.z])
      polylineCutToolStore.setHideArea({ points: positions })
      polylineCutToolStore.setHideArea({ visibilitySource: polylineCutToolStore.hideAreaOld.visibilitySource })
      polylineCutToolStore.setHideArea({ isCutTerrain: polylineCutToolStore.hideAreaOld.isCutTerrain })
    }
    self.pointEdit = null;
  }, Cesium.ScreenSpaceEventType.LEFT_UP);
  handler.setInputAction(function (movement) {
    if (movement.endPosition) {
      let resultClick = clickPoint(self.editor.viewer, movement.endPosition)
      if (resultClick && resultClick.position && self.pointEdit) {
        var cartesian = resultClick.position
        positionData[self.pointEdit.index] = cartesian;
        self.editor.viewer.entities._entities._array.map(item => {
          if (item.label && item.labelId && item.labelId.includes(self.pointEdit?.pointId)) {
            self.pointEdit.listPointData.map(pos => {
              if (item.labelId.includes(pos.id)) {
                let midPoint = getMidpoint(cartesian, pos.position)
                item.position._value = midPoint
              }
            })
          }
          if (item.point && item?.namePoint?.includes(self.pointEdit?.index) && item?.typePoint == "midPoint") {
            let pointIndex = item.namePoint.filter(x => x !== self.pointEdit.index)
            self.draggers.map(data => {
              if (data.index == pointIndex[0]) {
                let midPoint = getMidpoint(cartesian, data.position._value);
                item.position._value = midPoint;
              }
            })
          }
        })
      }
    }
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  if (mode == 'wireframe') {
    handler.setInputAction(function (click) {
      let pickedObjects1 = self.editor.viewer.scene.drillPick(click.position);
      const pickedObject1 = pickedObjects1.filter(el => el.id && el.id.point)[0] || pickedObjects1[0];
      if (pickedObject1?.id?.pointId || pickedObject1?.id?.index > -1) {
        self.pointEdit = pickedObject1?.id;
      }

      const midPointEdit = pickedObjects1.filter(el => el.id && el.id.namePoint?.length > 0)[0];
      if (midPointEdit?.id?.typePoint == "midPoint") {
        self.pointEdit = midPointEdit?.id;
        midPointEdit.id.point.color = Cesium.Color.RED;
        midPointEdit.id.point.outlineColor = Cesium.Color.WHITE;
        midPointEdit.id.point.pixelSize = 8;
        midPointEdit.id.typePoint = "point";
        midPointEdit.id.namePoint = null;
        let pointNew = midPointEdit.id.pointMid;
        positionData.splice(pointNew + 1, 0, midPointEdit.id.pst.clone());
        entity.polygon.hierarchy._value.positions.splice(pointNew + 1, 0, midPointEdit.id.position._value.clone());
        midPointEdit.id.positions = entity.polygon.hierarchy._value.positions;
        self.draggers.splice(pointNew + 1, 0, midPointEdit.id);
        midPointEdit.id.index = pointNew + 1;
        midPointEdit.id.pointMid = pointNew + 1;
        for (var i = 0; i < self.draggers.length; i++) {
          self.draggers[i].positions = entity.polygon.hierarchy._value.positions;
        }
        for (var i = 0; i < self.draggersMid.length; i++) {
          if (self.draggersMid[i].typePoint == "midPoint") {
            self.editor.viewer.entities.remove(self.draggersMid[i]);
          }
        }
        let listMidPoint = addAllVirtualEditPoints(positionData, self.editor.viewer);
        const minDistToSnap = 0.5;
        if (mode == 'wireframe' && listMidPoint.length >= self.draggers.length) {
          for (let i = 0; i < listMidPoint.length; i++) {
            var loc = listMidPoint[i].position;
            let dragger = editor.createDragger({
              position: loc,
              onDrag: function (dragger, position) {
                if (collections) {
                  const length = Object.keys(collections).length;
                  for (let i = 0; i < length; i++) {
                    if (collections[`C${i}`] && position) {
                      const pos = collections[`C${i}`];
                      const cat3 = Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, pos.height)
                      if (
                        Cesium.Cartesian3.distance(cat3, position) < minDistToSnap &&
                        !Cesium.Cartesian3.equals(cat3, self.pointEdit.position._value)
                        && !self.snapPoint.find(el => Cesium.Cartesian3.equals(cat3, el.point))
                      ) {
                        position = cat3.clone();
                        const snapP = {
                          id: collections[`C${i}`].PointId,
                          point: position,
                          pointPos: dragger.index,
                          face: entity.refId,
                        }
                        if (self.snapPoint.findIndex(el => el.id === snapP.id) === -1) {
                          self.snapPoint.push(snapP);
                        }
                      }
                    }
                  }
                }
                sharedFace.map(item => {
                  if (item?.polygon?.hierarchy?._value?.positions && item.pointShare.length > 0) {
                    let index = item.pointShare.filter(x => x.pointEdit == self.pointEdit.index)[0];
                    if (index?.index > -1) {
                      item.polygon.hierarchy._value.positions[index.index] = self.pointEdit.position._value;
                    }
                  }
                })
                const entitiesLength = Object.keys(entities).length;
                for (let j = 0; j < entitiesLength; j++) {
                  if (entities[`F${j}`]?.cat3 && entities[`F${j}`]?.geodata) {
                    if (entities[`F${j}`]?.cat3?.findIndex(el => Cesium.Cartesian3.distance(el, self.pointEdit.position._value) < minDistToSnap) !== -1 && entities[`F${j}`]?.geodata.refId !== entity.refId) {
                      const idx = entities[`F${j}`]?.cat3?.findIndex(el => Cesium.Cartesian3.distance(el, self.pointEdit.position._value) < minDistToSnap);
                      for (let m = 0; m < entitiesCollection.length; m++) {
                        if (entitiesCollection[m].refId === j) {
                          if (sharedFace.findIndex(el => el.refId === entitiesCollection[m].refId) === -1) {
                            entitiesCollection[m].pointShare = [{
                              index: idx,
                              pointEdit: self.pointEdit.index
                            }];
                            sharedFace.push(entitiesCollection[m]);
                            pushEntity(entitiesCollection[m]);
                          }
                          if (entitiesCollection[m].pointShare?.length > 0) {
                            if (entitiesCollection[m].pointShare?.length > 0) {
                              if (entitiesCollection[m].pointShare.filter(x => x.index === idx && x.pointEdit === self.pointEdit.index).length == 0) {
                                entitiesCollection[m].pointShare.push({
                                  index: idx,
                                  pointEdit: self.pointEdit.index
                                });
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                dragger.position._value = position;
                dragger.positions[dragger.index] = position;

                entity.onEdit();
              },
              pointData: listMidPoint[i],
              positions: listMidPoint[i].positions,
              typePoint: "midPoint"
            });
            dragger.positions = listMidPoint[i].positions;
            self.draggersMid.push(dragger);
          }
        }
        for (var i = 0; i < self.draggers.length; i++) {
          sharedFace.map(item => {
            item.pointShare.map(point => {
              if (point.pointEdit == self.draggers[i].index) {
                point.pointEdit = i;
              }
            })
          })
          self.draggers[i].index = i;
          self.draggers[i].pointMid = i;
          self.draggers[i].typePoint = "point";
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
    handler.setInputAction(click => {
      let pickedObjects1 = self.editor.viewer.scene.drillPick(click.position);
      const pickedObject1 = pickedObjects1.filter(el => el.id && el.id.point && el.id.index > -1)[0] || pickedObjects1[0];
      let pointDelete = pickedObject1?.id;
      if (pointDelete && pointDelete.index > -1 && entity) {

        // portalModalConfirm(
        //   'Warning',
        //   'Deletion point!',
        //   'Do you want to delete this point?',
        //   () => {
        //     let pointIndex = pointDelete.index;
        //     let pointLength = entity.polygon.hierarchy._value.positions.length;
        //     if(pointIndex == 0){
        //       positionData.shift();
        //       entity.polygon.hierarchy._value.positions.shift();
        //     }else{
        //       positionData.splice(pointIndex,1);
        //       entity.polygon.hierarchy._value.positions.splice(pointIndex,1);
        //     }
        //     self.draggersMid = [];
        //     self.editor.viewer.entities.remove(pointDelete);
        //     sharedFace.map(item =>{
        //       let indexReset
        //       let indexDelete
        //       item.pointShare.map((point , index) =>{
        //         if(point.pointEdit == pointIndex){
        //           indexReset = point.index;
        //           indexDelete = index;
        //         }
        //       })
        //       if(indexDelete > -1){
        //         item.pointShare.splice(indexDelete,1);
        //       }
        //       if(indexReset > -1){
        //         item.polygon.hierarchy._value.positions[indexReset] =  item.polygonData.positions[indexReset];
        //       }
        //     })
        //     sharedFace = sharedFace.filter(x => x.pointShare.length > 0);
        //     for(var i = pointIndex ; i <= pointLength ; i++){
        //       sharedFace.map(item =>{
        //         item.pointShare.map(point =>{
        //           if(point.pointEdit == i){
        //             point.pointEdit = i - 1;
        //           }
        //         })
        //       })
        //       self.editor.viewer.entities._entities._array.map(item => {
        //         if(item.point && item.index == i){
        //           item.index = item.index - 1;
        //         }
        //       })
        //     }

        //     if(pointIndex == 0){
        //       self.draggers.shift();
        //     }else{
        //       self.draggers.splice(pointIndex,1);
        //     }
        //     let arrayDelete = [];
        //     self.editor.viewer.entities._entities._array.map(item => {
        //       if(item.typePoint == "midPoint"){
        //         arrayDelete.push(item)
        //       }
        //     })
        //     let listMidPoint = addAllVirtualEditPoints(positionData);
        //     const minDistToSnap = 0.5;
        //     if (mode == 'wireframe' && listMidPoint.length > 0) {
        //       for (let i = 0; i < listMidPoint.length; i++) {
        //         var loc = listMidPoint[i].position;
        //         let dragger = editor.createDragger({
        //           position: loc,
        //           onDrag: function (dragger, position) {
        //             if (collections) {
        //               const length = Object.keys(collections).length;
        //               for (let i = 0; i < length; i++) {
        //                 if (collections[`C${i}`] && position) {
        //                   const pos = collections[`C${i}`];
        //                   const cat3 = Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, pos.height)
        //                   if (
        //                     Cesium.Cartesian3.distance(cat3, position) < minDistToSnap &&
        //                     !Cesium.Cartesian3.equals(cat3, self.pointEdit.position._value)
        //                     && !self.snapPoint.find(el => Cesium.Cartesian3.equals(cat3, el.point))
        //                   ) {
        //                     position = cat3.clone();
        //                     const snapP = {
        //                       id: collections[`C${i}`].PointId,
        //                       point: position,
        //                       pointPos: dragger.index,
        //                       face: entity.refId,
        //                     }
        //                     if (self.snapPoint.findIndex(el => el.id === snapP.id) === -1) {
        //                       self.snapPoint.push(snapP);
        //                     }
        //                   }
        //                 }
        //               }
        //             }
        //             sharedFace.map(item => {
        //               if(item?.polygon?.hierarchy?._value?.positions && item.pointShare.length > 0){
        //                 let index = item.pointShare.filter(x => x.pointEdit == self.pointEdit.index)[0];
        //                 if(index?.index > -1){
        //                   item.polygon.hierarchy._value.positions[index.index] = self.pointEdit.position._value;
        //                 }
        //               }
        //             })
        //             const entitiesLength = Object.keys(entities).length;
        //             for (let j = 0; j < entitiesLength; j++) {
        //               if (entities[`F${j}`]?.cat3 && entities[`F${j}`]?.geodata) {
        //                 if (entities[`F${j}`]?.cat3?.findIndex(el => Cesium.Cartesian3.distance(el, self.pointEdit.position._value) < minDistToSnap) !== -1 && entities[`F${j}`]?.geodata.refId !== entity.refId) {
        //                   const idx = entities[`F${j}`]?.cat3?.findIndex(el => Cesium.Cartesian3.distance(el, self.pointEdit.position._value) < minDistToSnap);
        //                   for (let m = 0; m < entitiesCollection.length; m++) {
        //                     if (entitiesCollection[m].refId === j) {
        //                       if (sharedFace.findIndex(el => el.refId === entitiesCollection[m].refId) === -1) {
        //                         entitiesCollection[m].pointShare = [{
        //                           index: idx,
        //                           pointEdit: self.pointEdit.index
        //                         }];
        //                         sharedFace.push(entitiesCollection[m]);
        //                         pushEntity(entitiesCollection[m]);
        //                       }
        //                       if(entitiesCollection[m].pointShare?.length > 0){
        //                         if(entitiesCollection[m].pointShare?.length > 0){
        //                           if(entitiesCollection[m].pointShare.filter( x => x.index === idx && x.pointEdit === self.pointEdit.index).length == 0){
        //                             entitiesCollection[m].pointShare.push({
        //                               index: idx,
        //                               pointEdit: self.pointEdit.index
        //                             });
        //                           }
        //                         }
        //                       }
        //                     }
        //                   }
        //                 }
        //               }
        //             }
        //             dragger.position._value = position;
        //             dragger.positions[dragger.index] = position;

        //             entity.onEdit();
        //           },
        //           pointData: listMidPoint[i],
        //           positions: listMidPoint[i].positions,
        //           typePoint: "midPoint"
        //         });
        //         dragger.positions = listMidPoint[i].positions;
        //         self.draggersMid.push(dragger);
        //       }
        //     }
        //     for (var i = 0; i < arrayDelete.length; i++) {
        //       self.editor.viewer.entities.remove(arrayDelete[i]);
        //     }
        //   },
        //   false,
        // );
      }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }
  if (mode !== 'wireframe') {
    handler.setInputAction(function (click) {
      let pickedObjects1 = self.editor.viewer.scene.drillPick(click.position);
      const pickedObject1 = pickedObjects1.filter(el => el.id && el.id.point)[0] || pickedObjects1[0];
      let data = { ...polylineCutToolStore.hideArea }
      polylineCutToolStore.setHideAreaOld(data)
      if (pickedObject1?.id?.pointId || pickedObject1?.id?.index > -1) {
        self.pointEdit = pickedObject1?.id;
        polylineCutToolStore.setHideArea({ visibilitySource: [] })
        polylineCutToolStore.setHideArea({ isCutTerrain: false })
      }
      const midPointEdit = pickedObjects1.filter(el => el.id && el.id.namePoint?.length > 0)[0];
      if (midPointEdit?.id?.typePoint == "midPoint") {
        polylineCutToolStore.setHideArea({ visibilitySource: [] })
        polylineCutToolStore.setHideArea({ isCutTerrain: false })
        self.pointEdit = midPointEdit?.id;
        midPointEdit.id.point.color = Cesium.Color.RED;
        midPointEdit.id.point.outlineColor = Cesium.Color.WHITE;
        midPointEdit.id.point.pixelSize = 8;
        midPointEdit.id.typePoint = "point";
        midPointEdit.id.namePoint = null;
        let pointNew = midPointEdit.id.pointMid;
        positionData.splice(pointNew + 1, 0, midPointEdit.id.pst.clone());
        entity.polygon.hierarchy._value.positions.splice(pointNew + 1, 0, midPointEdit.id.position._value.clone());
        midPointEdit.id.positions = entity.polygon.hierarchy._value.positions;
        self.draggers.splice(pointNew + 1, 0, midPointEdit.id);
        midPointEdit.id.index = pointNew + 1;
        midPointEdit.id.pointMid = pointNew + 1;
        for (var i = 0; i < self.draggers.length; i++) {
          self.draggers[i].positions = entity.polygon.hierarchy._value.positions;
        }
        for (var i = 0; i < self.draggersMid.length; i++) {
          if (self.draggersMid[i].typePoint == "midPoint") {
            self.editor.viewer.entities.remove(self.draggersMid[i]);
          }
        }
        let listMidPoint = addAllVirtualEditPoints(positionData, self.editor.viewer);
        if (mode !== 'wireframe' && listMidPoint.length >= self.draggers.length) {
          for (let i = 0; i < listMidPoint.length; i++) {
            var loc = listMidPoint[i].position;
            let dragger = editor.createDragger({
              position: loc,
              onDrag: function (dragger, position) {
                dragger.positions[dragger.pointMid] = position;
                entity.onEdit();
              },
              pointData: listMidPoint[i],
              positions: listMidPoint[i].positions,
              typePoint: "midPoint"
            });
            dragger.positions = listMidPoint[i].positions;
            self.draggersMid.push(dragger);
          }
        }
        for (var i = 0; i < self.draggers.length; i++) {
          self.draggers[i].index = i;
          self.draggers[i].pointMid = i;
          self.draggers[i].typePoint = "point";
        }
      }
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
    handler.setInputAction(click => {
      let pickedObjects1 = self.editor.viewer.scene.drillPick(click.position);
      const pickedObject1 = pickedObjects1.filter(el => el.id && el.id.point && el.id.index > -1)[0] || pickedObjects1[0];
      let pointDelete = pickedObject1?.id;
      if (pointDelete && pointDelete.index > -1 && entity && entity.polygon.hierarchy._value.positions.length > 3) {
        confirmDelete(
          'Deletion point!',
          'Do you want to delete this point?',
          () => {
            let pointIndex = pointDelete.index;
            let pointLength = entity.polygon.hierarchy._value.positions.length;
            if (pointIndex == 0) {
              positionData.shift();
              entity.polygon.hierarchy._value.positions.shift();
            } else {
              positionData.splice(pointIndex, 1);
              entity.polygon.hierarchy._value.positions.splice(pointIndex, 1);
              polylineCutToolStore.setHideArea({ points: entity.polygon.hierarchy._value.positions.map(item => [item.x, item.y, item.z]) })
            }
            self.draggersMid = [];
            self.editor.viewer.entities.remove(pointDelete);
            for (var i = pointIndex; i <= pointLength; i++) {
              self.editor.viewer.entities._entities._array.map(item => {
                if (item.point && item.index == i) {
                  item.index = item.index - 1;
                }
              })
            }

            if (pointIndex == 0) {
              self.draggers.shift();
            } else {
              self.draggers.splice(pointIndex, 1);
            }
            let arrayDelete = [];
            self.editor.viewer.entities._entities._array.map(item => {
              if (item.typePoint == "midPoint") {
                arrayDelete.push(item)
              }
            })
            let listMidPointUpdate = addAllVirtualEditPoints(positionData);
            if (mode !== 'wireframe' && listMidPointUpdate.length > 0) {
              for (let i = 0; i < listMidPointUpdate.length; i++) {
                var loc = listMidPointUpdate[i].position;
                let dragger = editor.createDragger({
                  position: loc,
                  onDrag: function (dragger, position) {
                    dragger.positions[dragger.pointMid] = position;
                    entity.onEdit();
                  },
                  pointData: listMidPointUpdate[i],
                  positions: listMidPointUpdate[i].positions,
                  typePoint: "midPoint"
                });
                dragger.positions = listMidPointUpdate[i].positions;
                this.draggersMid.push(dragger);
              }
            }
            for (var i = 0; i < arrayDelete.length; i++) {
              self.editor.viewer.entities.remove(arrayDelete[i]);
            }
          },
          () => { },
        );
      }
    }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
  }

  const entitiesCollection = entity.entityCollection._entities._array.filter(el => el.isWhiteFrame);

  const originalPos = entity.polygon.hierarchy.getValue().positions.map(x => x.clone());

  var virtualPositions =
    entity.polygon.hierarchy.getValue().positions;
  var entitiesOriginalPos = entitiesCollection.map(el => el.polygon._hierarchy._value.positions.map(x => x.clone()));

  var entityPositions = entity.polygon.hierarchy._value.positions;
  let listIdPoint = [];
  let listPointEdit = [];
  let listLinePolygonEdit = null;
  let onCancel = null;
  let onFinishOne = null;

  //Create Cancel function
  if (mode === 'wireframe') {
    listIdPoint = []
    onCancel = function () {
      for (let i = 0; i < originalPos.length; i++) {
        const loc = originalPos[i];
        if (mode !== 'wireframe') {
          entity._originalPoints[i] = loc;
        }
        entity.polygon.hierarchy._value.positions[i] = loc;
      }
      for (let j = 0; j < sharedFace.length; j++) {
        for (let k = 0; k < sharedFace[j].polygon.hierarchy._value.positions.length; k++) {
          for (var m = 0; m < entitiesOriginalPos[sharedFace[j].refId].length; m++) {
            sharedFace[j].polygon.hierarchy._value.positions[m] = entitiesOriginalPos[sharedFace[j].refId][m];
          }
        }
      }
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      entity.onEdit();
      self.snapPoint = [];
      self.destroy();
    };

    onFinishOne = function () {
      for (let i = 0; i < self.draggers.length; i++) {
        entity.polygon.hierarchy._value.positions[i] = self.draggers[i].position._value;
      }
      if (self.snapPoint.length > 0) {
        pushSnapPoint(self.snapPoint);
      }
      self.snapPoint = [];
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      entity.onEdit();
      self.destroy();
    }
    entity.polygon.hierarchy.isConstant = false;
    for (var i = 0; i < Object.keys(entities).length; i++) {
      if (entity.refId === i) {
        listLinePolygonEdit = entities[`F${i}`].lines
        // eslint-disable-next-line no-loop-func
        entities[`F${i}`].lines.forEach(item => {
          const pos = item[0];
          const cat3 = Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, pos.height)
          const pos1 = item[1];
          const cat31 = Cesium.Cartesian3.fromDegrees(pos1.lng, pos1.lat, pos1.height)
          if (!listIdPoint.includes(item[0].PointId)) {
            listPointEdit.push({ id: item[0].PointId, position: cat3, position1: cat31 })
            listIdPoint.push(item[0].PointId)
          }
          if (!listIdPoint.includes(item[1].PointId)) {
            listPointEdit.push({ id: item[1].PointId, position: cat31, position1: cat3 })
            listIdPoint.push(item[1].PointId)
          }
        })
      }
    }
  }
  else {
    onCancel = function () {
      for (let i = 0; i < originalPos.length; i++) {
        const loc = originalPos[i];
        entity._originalPoints[i] = loc
        entity.polygon.hierarchy._value.positions[i] = loc;
      }
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      entity.polygon.hierarchy.getValue().positions = entity._originalPoints;
      entity.onEdit();
      self.destroy();
    }
    onFinishOne = function () {
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      entity.onEdit();
      self.destroy();
    }
  }

  entity.onCancel = onCancel;
  entity.onFinishOne = onFinishOne;

  //Create Drag function
  if (mode !== 'wireframe') {
    entity.polygon.hierarchy.isConstant = false;
    entity.polyline.positions.setCallback(
      entity.polyline.positions._callback,
      false,
    );
    let listMidPoint = addAllVirtualEditPoints(positionData);
    if (listMidPoint.length > 0) {
      for (let i = 0; i < listMidPoint.length; i++) {
        var loc = listMidPoint[i].position;
        let dragger = editor.createDragger({
          position: loc,
          onDrag: function (dragger, position) {
            dragger.positions[dragger.pointMid] = position;
            entity.onEdit();
          },
          pointData: listMidPoint[i],
          positions: listMidPoint[i].positions,
          typePoint: "midPoint"
        });
        dragger.positions = listMidPoint[i].positions;
        this.draggersMid.push(dragger);
      }
    }
    for (let i = 0; i < positionData.length; i++) {
      var loc = positionData[i];
      let dragger = editor.createDragger({
        position: loc,
        onDrag: function (dragger, position) {
          dragger.positions[dragger.index] = position;

          entity.onEdit();
        },
        positions: positionData,
      });
      dragger.index = i;
      dragger.positions = entityPositions;
      this.draggers.push(dragger);
    }
  }
  else {
    const minDistToSnap = 0.5;
    let listMidPoint = addAllVirtualEditPoints(positionData);
    if (listMidPoint.length > 0) {
      for (let i = 0; i < listMidPoint.length; i++) {
        var loc = listMidPoint[i].position;
        let dragger = editor.createDragger({
          position: loc,
          onDrag: function (dragger, position) {
            if (collections) {
              const length = Object.keys(collections).length;
              for (let i = 0; i < length; i++) {
                if (collections[`C${i}`] && position) {
                  const pos = collections[`C${i}`];
                  const cat3 = Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, pos.height)
                  if (
                    Cesium.Cartesian3.distance(cat3, position) < minDistToSnap &&
                    !Cesium.Cartesian3.equals(cat3, self.pointEdit.position._value)
                    && !self.snapPoint.find(el => Cesium.Cartesian3.equals(cat3, el.point))
                  ) {
                    position = cat3.clone();
                    const snapP = {
                      id: collections[`C${i}`].PointId,
                      point: position,
                      pointPos: dragger.index,
                      face: entity.refId,
                    }
                    if (self.snapPoint.findIndex(el => el.id === snapP.id) === -1) {
                      self.snapPoint.push(snapP);
                    }
                  }
                }
              }
            }
            sharedFace.map(item => {
              if (item?.polygon?.hierarchy?._value?.positions && item.pointShare.length > 0) {
                let index = item.pointShare.filter(x => x.pointEdit == self.pointEdit.index)[0];
                if (index?.index > -1) {
                  item.polygon.hierarchy._value.positions[index.index] = self.pointEdit.position._value;
                }
              }
            })
            const entitiesLength = Object.keys(entities).length;
            for (let j = 0; j < entitiesLength; j++) {
              if (entities[`F${j}`]?.cat3 && entities[`F${j}`]?.geodata) {
                if (entities[`F${j}`]?.cat3?.findIndex(el => Cesium.Cartesian3.distance(el, self.pointEdit.position._value) < minDistToSnap) !== -1 && entities[`F${j}`]?.geodata.refId !== entity.refId) {
                  const idx = entities[`F${j}`]?.cat3?.findIndex(el => Cesium.Cartesian3.distance(el, self.pointEdit.position._value) < minDistToSnap);
                  for (let m = 0; m < entitiesCollection.length; m++) {
                    if (entitiesCollection[m].refId === j) {
                      if (sharedFace.findIndex(el => el.refId === entitiesCollection[m].refId) === -1) {
                        entitiesCollection[m].pointShare = [{
                          index: idx,
                          pointEdit: self.pointEdit.index
                        }];
                        sharedFace.push(entitiesCollection[m]);
                        pushEntity(entitiesCollection[m]);
                      }
                      if (entitiesCollection[m].pointShare?.length > 0) {
                        if (entitiesCollection[m].pointShare?.length > 0) {
                          if (entitiesCollection[m].pointShare.filter(x => x.index === idx && x.pointEdit === self.pointEdit.index).length == 0) {
                            entitiesCollection[m].pointShare.push({
                              index: idx,
                              pointEdit: self.pointEdit.index
                            });
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            dragger.position._value = position;
            dragger.positions[dragger.index] = position;

            entity.onEdit();
          },
          pointData: listMidPoint[i],
          positions: listMidPoint[i].positions,
          typePoint: "midPoint"
        });
        dragger.positions = listMidPoint[i].positions;
        this.draggersMid.push(dragger);
      }
    }
    for (let i = 0; i < virtualPositions.length; i++) {
      let loc = virtualPositions[i];
      let dragger = editor.createDragger({
        position: loc,
        onDrag: function (dragger, position) {
          if (collections) {
            const length = Object.keys(collections).length;
            for (let i = 0; i < length; i++) {
              if (collections[`C${i}`] && position) {
                const pos = collections[`C${i}`];
                const cat3 = Cesium.Cartesian3.fromDegrees(pos.lng, pos.lat, pos.height)
                if (
                  Cesium.Cartesian3.distance(cat3, position) < minDistToSnap &&
                  !Cesium.Cartesian3.equals(cat3, self.pointEdit.position._value)
                  && !self.snapPoint.find(el => Cesium.Cartesian3.equals(cat3, el.point))
                ) {
                  position = cat3.clone();
                  const snapP = {
                    id: collections[`C${i}`].PointId,
                    point: position,
                    pointPos: dragger.index,
                    face: entity.refId,
                  }
                  if (self.snapPoint.findIndex(el => el.id === snapP.id) === -1) {
                    self.snapPoint.push(snapP);
                  }
                }
              }
            }
          }
          sharedFace.map(item => {
            if (item?.polygon?.hierarchy?._value?.positions && item.pointShare.length > 0) {
              let index = item.pointShare.filter(x => x.pointEdit == self.pointEdit.index)[0];
              if (index?.index > -1) {
                item.polygon.hierarchy._value.positions[index.index] = self.pointEdit.position._value;
              }
            }
          })
          const entitiesLength = Object.keys(entities).length;
          for (let j = 0; j < entitiesLength; j++) {
            if (entities[`F${j}`]?.cat3 && entities[`F${j}`]?.geodata) {
              if (entities[`F${j}`]?.cat3?.findIndex(el => Cesium.Cartesian3.distance(el, self.pointEdit.position._value) < minDistToSnap) !== -1 && entities[`F${j}`]?.geodata.refId !== entity.refId) {
                const idx = entities[`F${j}`]?.cat3?.findIndex(el => Cesium.Cartesian3.distance(el, self.pointEdit.position._value) < minDistToSnap);
                for (let m = 0; m < entitiesCollection.length; m++) {
                  if (entitiesCollection[m].refId === j) {
                    if (sharedFace.findIndex(el => el.refId === entitiesCollection[m].refId) === -1) {
                      entitiesCollection[m].pointShare = [{
                        index: idx,
                        pointEdit: self.pointEdit.index
                      }];
                      sharedFace.push(entitiesCollection[m]);
                      pushEntity(entitiesCollection[m]);
                    }
                    if (entitiesCollection[m].pointShare?.length > 0) {
                      if (entitiesCollection[m].pointShare.filter(x => x.index == idx && x.pointEdit == self.pointEdit.index).length == 0) {
                        entitiesCollection[m].pointShare.push({
                          index: idx,
                          pointEdit: self.pointEdit.index
                        });
                      }
                    }
                  }
                }
              }
            }
          }
          dragger.position._value = position;
          dragger.positions[dragger.index] = position;

          entity.onEdit();
        },
        listPointEdit,
        listLinePolygonEdit
      });
      dragger.index = i;
      dragger.positions = entityPositions;
      this.draggers.push(dragger);
    }
  }
};

CesiumDrawing.PolygonEditor.prototype.destroy = function () {
  let listPointDelete = [];
  this.editor.viewer.entities._entities._array.map(item => {
    if (item.point) {
      listPointDelete.push(item);
    }
  })
  for (var i = 0; i < listPointDelete.length; i++) {
    this.editor.viewer.entities.remove(listPointDelete[i]);
  }
  this.draggers = [];
  this.draggersMid = [];
};
/**
 * Test square editor.
 */
CesiumDrawing.TestSquareEditor = function (editor, entity) {
  const self = this;
  this.editor = editor;
  this.entity = entity;
  this.draggers = [];

  const originalPos = entity._originalPoints.map(x => x.clone());

  const onCancel = function () {
    for (let i = 0; i < originalPos.length; i++) {
      const loc = originalPos[i];

      entity._currentPoints[i] = loc;
    }
    entity.onEdit();
    self.destroy();
  };
  entity.onCancel = onCancel;

  entity.polygon.hierarchy.setCallback(
    entity.polygon.hierarchy._callback,
    false,
  );
  entity.polyline.positions.setCallback(
    entity.polyline.positions._callback,
    false,
  );
  var loc = originalPos[0];

  var dragger = editor.createDragger({
    position: loc,
    onDrag: function (dragger, position) {
      dragger.positions[0] = position;
      entity._currentPoints = dragger.positions;
      entity.onEdit();
    },
  });
  dragger.positions = entity._currentPoints;
  self.draggers.push(dragger);
};

CesiumDrawing.TestSquareEditor.prototype.destroy = function () {
  let listPointDelete = [];
  this.editor.viewer.entities._entities._array.map(item => {
    if (item.point) {
      listPointDelete.push(item);
    }
  })
  for (var i = 0; i < listPointDelete.length; i++) {
    this.editor.viewer.entities.remove(listPointDelete[i]);
  }
  this.draggers = [];
  this.draggersMid = [];
};
// eslint-disable-next-line import/no-anonymous-default-export
export default CesiumDrawing;
