import React, { useEffect, useState } from 'react'
import { Progress, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { waitUntilIonReady } from '@/lib/projectLib'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next';

function ModelStatusComponent({modelInfo, projectStore}) {
    const { t } = useTranslation();
    const [modelStatus, setModelStatus] = useState(false);
    const [cancelProcessing, setCancelProcessing] = useState(false);
    const [processingId, setProcessingId] = useState();

    useEffect(() => {
        const loadModel = async () => await waitUntilIonReady(processingId, setProcessingId, modelInfo, setModelStatus, projectStore, cancelProcessing)
        loadModel()
      return () => {}
    }, [modelInfo])

    useEffect(() => {
      if(modelStatus?.message && modelStatus?.status && ['DATA_ERROR', 'ERROR'].includes(modelStatus?.status)){
        notification.open({
          message: t('an-error-occurred-when-creating-model-data'),
          description: (
              <>
                  `${t('file')}`: <b>{modelInfo.name}</b><br />
                  {t(modelStatus?.message)}
              </>
          ),
          icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          duration: 0,
        })
      }     
    }, [modelStatus])
    
    const handleCancelProcessingModel = (itemId) =>{
      clearTimeout(processingId);
      setCancelProcessing(true)
      projectStore.setLoadingProgress(true)
      projectStore.deleteModel({ projectId: projectStore.projectDetail._id, modelId: modelInfo?._id }).then(() => {
        projectStore.setLoadingProgress(false)
        //message.success(t('tileset-deleted-successfully'))
      }).catch(error => {
        console.error(error);
        projectStore.setLoadingProgress(false)
      })
    }
    
  return (
    <div className='row-body'>
      <div className='row-content'>
        <span className="text-bold">{modelInfo.name}</span>
        {modelStatus?.done !== 100 && !cancelProcessing ? <span className="text-bold cancel-btn" onClick={handleCancelProcessingModel}>Cancel</span> : ""}
        {cancelProcessing ? <span className="text-bold text-canceled">Canceled</span> : ""}
      </div>
      {!cancelProcessing ? <Progress percent={modelStatus?.done || 0} status={modelStatus?.status === "IN_PROGRESS" ? 'active' : modelStatus?.status === "COMPLETE" ? '': 'exception' } /> : "" }
    </div>
  )
}

export default inject('projectStore')(observer(ModelStatusComponent))