import { observable, action, decorate } from 'mobx'
import { TriggerconvertlandxmlifcsRequest } from '@/requests'

class FileStore {
    filesReadyProcess = [] // arr files ready for create model
    modalDrop3DView = false
    photoViewer360 = false
    heightModal= 55

    setHeightModal = (value) => {
        this.heightModal = value
    }

    setFilesReadyProcess = (value) => {
        this.filesReadyProcess = value
    }

    setModalDrop3DView(status) {
        this.modalDrop3DView = status
    }

    setPhotoViewer360 = (value) => {
        this.photoViewer360 = value
    }

    /**
     * get Triggerconvertlandxmlifc By Hash
     * @param {*} data {hash: hash}
     * @returns 
     */
    async getTriggerconvertlandxmlifcByHash(data) {
        return new Promise((resolve, reject) => {
            TriggerconvertlandxmlifcsRequest.getDataByHash(data).then((response) => {
                resolve(response.data);
            }).catch(err => {
                console.log(err)
                reject(err)
            })
        })
    }

}

decorate(FileStore, {
    setFilesReadyProcess: action,
    filesReadyProcess: observable,
    modalDrop3DView: observable,
    setModalDrop3DView: action,
    setPhotoViewer360: action,
    photoViewer360: observable,
    getTriggerconvertlandxmlifcByHash: action,
    setHeightModal : action,
    heightModal : observable
})

export default new FileStore()
