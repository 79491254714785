import { observable, action, decorate } from 'mobx';
import { ProjectRequest, TopicRequest, WorkflowRequest, HistoryTopicRequest } from '@/requests'
import settings from '@/siteConfig'
import { message } from 'antd'
import uuid from 'uuid'
import i18n from 'i18next';
class DashboardStore {
    isLoading = false
    projectDetail = {}
    curentTypeTopicPie = "topicstatus"
    curentTimeScale = "year"
    topicListBelongProject = []
    listTopicFilterByType = []
    topicTypes = []
    topicStatuses = []
    topicPriorities = []
    topicTags = []
    listTopicTypeSelected = []
    dataTopicTimeLineChart = []
    dataWorkflowChart = []
    curentQueryDate = []
    curentCategories = []
    modelDetail = {}
    
    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading
    }

    /**
     * Get Project Detail
     * @param {*} projectId 
     */
    async getProjectById(projectId) {
        this.setLoadingProgress(true)
        return ProjectRequest.getDetail(projectId)
            .then(response => {
                this.projectDetail = response.data
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => this.setLoadingProgress(false))
    }

    /**
     * Clear project detail
     */
    clearProjectDetail = () => {
        this.projectDetail = {}
    }

    setCurentTypeTopicPie = (status) => {
        this.curentTypeTopicPie = status
    }

    /**
     * Get List Topic Belong Project
     * @param {*} projectId projectId
     * @param {*} params  can has any other params
     */

    async getTopicListBelongProject(projectId, params) {
        this.setLoadingProgress(true)
        return TopicRequest.getTopicList(projectId, params)
            .then((res) => {
                this.topicListBelongProject = res.data
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => this.setLoadingProgress(false))

    }

    clearTopicListBelongProject = () => {
        this.topicListBelongProject = []
    }

    setListTopicFilterByType = (payload) => {
        this.listTopicFilterByType = payload
    }
    clearListTopicFilterByType = () => {
        this.listTopicFilterByType = []
    }

    setTopicTypes = () => {
        this.topicTypes = Object.keys(settings.commonConfig.TOPICS.TYPE).map(key => ({ id: Number(key), name: settings.commonConfig.TOPICS.TYPE[key] }));
    }

    setTopicStatuses = () => {
        this.topicStatuses = Object.keys(settings.commonConfig.TOPICS.STATUS).map(key => ({ id: Number(key), name: settings.commonConfig.TOPICS.STATUS[key] }));
    }

    setTopicPriorities = () => {
        this.topicPriorities = Object.keys(settings.commonConfig.TOPICS.PRIORITY).map(key => ({ id: Number(key), name: settings.commonConfig.TOPICS.PRIORITY[key] }));
    }

    async getTopicTags(projectId) {
        this.setLoadingProgress(true)
        return TopicRequest.getTopicTags(projectId)
            .then((res) => {
                this.topicTags = res.data;
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => this.setLoadingProgress(false))
    }

    clearTopicStatuses = () => {
        this.topicStatuses = []
    }

    clearTopicPriorities = () => {
        this.topicPriorities = []
    }

    clearTopicTypes = () => {
        this.topicTypes = []
    }

    clearTopicTags = () => {
        this.topicTags = []
    }

    setListTopicTypeSelected = (payload) => {
        this.listTopicTypeSelected = payload
    }
    clearListTopicTypeSelected = () => {
        this.listTopicTypeSelected = []
    }

    setCurentTimeScale = payload => {
        this.curentTimeScale = payload
    }

    /**
     * Get data  topic column chart, topic burndown chart with timescale 
     * @param {*} params  
     */

    async getDataTopicTimeLineChart(body) {
        this.setLoadingProgress(true)
        return HistoryTopicRequest.getDataTopicTimeLineChart(body)
            .then((res) => {
                this.dataTopicTimeLineChart = res.data
                
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => this.setLoadingProgress(false))

    }

    clearDataTopicTimeLineChart = () => {
        this.dataTopicTimeLineChart = []
    }

    /**
     * Get data  column topic chart with timescale 
     * @param {*} params  
     */

    async getWorkflowDataChart(body) {
        this.setLoadingProgress(true)
        return HistoryTopicRequest.getWorkflowDataChart(body)
            .then((res) => {
                this.dataWorkflowChart = res.data
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => this.setLoadingProgress(false))

    }

    clearWorkflowDataChart = () => {
        this.dataWorkflowChart = []
    }

    setCurentCategories = payload => {
        this.curentCategories = payload
    }

    setCurentQueryDate = payload => {
        this.curentQueryDate = payload
    }

    async updateProjectData(projectId, locationData) {
        return ProjectRequest.updateProject(projectId, locationData)
            .then((response) => {
                this.projectDetail = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }

    async updateModel(modelId, payload) {
        return ProjectRequest.updateModel(modelId, payload)
            .then((response) => {
                this.modelDetail = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }





}

decorate(DashboardStore, {

    //observable
    isLoading: observable,
    projectDetail: observable,
    curentTypeTopicPie: observable,
    topicListBelongProject: observable,
    listTopicFilterByType: observable,
    topicTypes: observable,
    topicStatuses: observable,
    topicPriorities: observable,
    topicTags: observable,
    listTopicTypeSelected: observable,
    curentTimeScale: observable,
    dataTopicTimeLineChart: observable,
    dataWorkflowChart: observable,
    curentCategories: observable,
    curentQueryDate: observable,
    modelDetail : observable,


    // action
    clearProjectDetail: action,
    setLoadingProgress: action,
    getProjectById: action,
    setCurentTypeTopicPie: action,
    getTopicListBelongProject: action,
    setListTopicFilterByType: action,
    clearListTopicFilterByType: action,
    setTopicTypes: action,
    setTopicStatuses: action,
    setTopicPriorities: action,
    getTopicTags: action,
    clearTopicStatuses: action,
    clearTopicPriorities: action,
    clearTopicTypes: action,
    clearTopicTags: action,
    clearTopicListBelongProject: action,
    setListTopicTypeSelected: action,
    clearListTopicTypeSelected: action,
    setCurentTimeScale: action,
    getDataTopicTimeLineChart: action,
    clearDataTopicTimeLineChart: action,
    getWorkflowDataChart: action,
    clearWorkflowDataChart: action,
    setCurentQueryDate: action,
    setCurentCategories: action,
    updateProjectData: action,
    updateModel : action
})

export default new DashboardStore();