import { inject, observer } from 'mobx-react'
import { useEffect, useState } from 'react'

import AppLogo from '../../../assets/icons/6DPlanner - SQ - 75px.png'
import { Image } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { StickyLogoContainer } from './styled'

const StickyLogo = ({ projectStore, commonStore, sketchingStore, projectGanttStore }) => {
  const { stickyLogoLeftDistance, stickyLogoShow } = commonStore
  const isSmallMonitor = useMediaQuery({ query: '(max-width: 536px)' })
  const [heightLogo, setHeightLogo] = useState(64)
  const [widthLogo, setWidthLogo] = useState(64)
  const [distanceTop, setDistanceTop] = useState(0)

  useEffect(() => {
    commonStore.setStickyLogoShow(
      projectStore.displayPanel ||
        sketchingStore.sketchFormVisible ||
        projectStore.showUpdateResourceModel ||
        projectGanttStore.isShowGanttPanel
    )
  }, [sketchingStore.sketchFormVisible, projectStore.displayPanel, projectGanttStore.isShowGanttPanel, projectStore.showUpdateResourceModel])

  const clickShowMainDrawer = () => {
    commonStore.setShowMainDrawer(true)
  }

  const maximumWidth = isSmallMonitor ? 75 : 150
  const maximumHeight = isSmallMonitor ? 75 : 150
  useEffect(() => {
    const logoPos = document.querySelector('.custom-logo-xdtwin')
    if (logoPos) {
      const { offsetTop, offsetHeight } = logoPos
      setDistanceTop(offsetTop + offsetHeight + 7)
    }
    if (projectStore?.isChangeLogoXDTwin?.logo) {
      const { height, width } = projectStore?.isChangeLogoXDTwin?.logo
      setHeightLogo(height > maximumHeight ? maximumHeight : height)
      setWidthLogo(width > maximumWidth ? maximumWidth : width)
    }
    return () => {}
  }, [projectStore.isChangeLogoXDTwin, widthLogo, maximumWidth])

  return (
    <StickyLogoContainer
      className="custom-logo-xdtwin"
      left={stickyLogoLeftDistance}
      show={stickyLogoShow}>
      {commonStore.currentPage === 'projectDetail' ? (
        projectStore?.isChangeLogoXDTwin?.customOrganizationLogo &&
        (projectStore?.isChangeLogoXDTwin?.logo ? (
          <Image
            style={{ cursor: 'pointer' }}
            onClick={clickShowMainDrawer}
            width={widthLogo}
            height={heightLogo}
            src={projectStore?.isChangeLogoXDTwin?.logo?.url}
            preview={false}
          />
        ) : (
          <Image
            style={{ cursor: 'pointer' }}
            onClick={clickShowMainDrawer}
            width={widthLogo}
            height={heightLogo}
            src={AppLogo}
            preview={false}
          />
        ))
      ) : (
        <Image
          style={{ cursor: 'pointer' }}
          onClick={clickShowMainDrawer}
          width={widthLogo}
          height={heightLogo}
          src={AppLogo}
          preview={false}
        />
      )}
    </StickyLogoContainer>
  )
}

export default inject(
  'commonStore',
  'projectStore',
  'sketchingStore',
  'projectGanttStore'
)(observer(StickyLogo))
