import React, { useEffect, useState, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import Toolsbox from './Toolsbox'
import Editor from './Editor'
import FormProperties from './FormProperties'
import { FormCreatorWrapper, HeaderFeedbackDrawerWrapper } from './style'
import { Row, Col, Button, Drawer, Skeleton, Tooltip } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { ColumnWidthOutlined } from '@ant-design/icons';
import './style.css'
import { toJS } from 'mobx'
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect'
import { HelpButton } from '@/components/elements'

const DrawerFeedbackEditor = ({ projectStore, feedbackStore, projectTeamsStore, projectSettingStore, usersStore, viewer }) => {
    const { t } = useTranslation();
    const isTabletOrMobile = isMobile || isTablet
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [screenWidth, setScreenWidth] = useState(0)
    const [refreshFeedbackEditorList, setRefreshFeedbackEditorList] = useState(false)
    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (touch.clientX - (target && target.offsetRight ? target.offsetRight : 0));
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if (newWidth > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let offsetRight = e.clientX - document.body.offsetLeft;
        let minDrawerWidth = 360;
        if (offsetRight > minDrawerWidth) {
            setDrawerWidth(offsetRight);
        }
        if (offsetRight > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);
    //#endregion

    // (changeSize) change drawerWidth when drawerWidth > screenWidth
    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize) // force reload when screenWidth change

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])
    // end changeSize

    /**
     * Effect show feedback editor
     */
    useEffect(() => {
        if (feedbackStore.showFeedbackEditorDrawer) {
            setDrawerWidth(isTabletOrMobile ? 360 : window.innerWidth < 360 ? window.innerWidth : (window.innerWidth / 2) < 360 ? 360 : window.innerWidth / 2)

            const populateData = async () => {
                setLoading(true)
                await projectTeamsStore.getProjectTeams(projectStore.projectDetail.id)
                await feedbackStore.getFeedbackformsList(projectStore.projectDetail.id, 'all').then(async (res) => {
                    feedbackStore.setFeedbackEditorList(res.data)
                    if (res.data && res.data.length === 0) {
                        //auto general geniric feedback
                        let _value = {
                            title: "Generic feedback",
                            isGenericFeedback: true,
                            formControlData: feedbackStore.submittedFormBuilder,
                            isPublic: true,
                            user: usersStore.currentUser.id,
                            project: projectStore.projectDetail.id,
                        }
                        await feedbackStore.createAutoGenericFeedback(_value)
                    }
                    setLoading(false)
                }).catch(error => {
                    setLoading(false)
                })
            }
            // check if feedbackStore.feedbackEditorList > 0 not load used for case snooze to add location
            if (feedbackStore.feedbackEditorList.length === 0) {
                populateData()
                feedbackStore.clearFeedbackformNormalVisualization() // clear icon feedback normal visualization
            }
        }
    }, [feedbackStore.showFeedbackEditorDrawer, refreshFeedbackEditorList])

    /**
     * drop control question
     * @param {*} droppedItem 
     */
    const onDropItemHandler = droppedItem => {
        if (droppedItem.hasOwnProperty('title')) {
            if (droppedItem.title !== 'Page break') {
                feedbackStore.addItemToFormPage(droppedItem)
            } else {
                feedbackStore.addPage()
            }
        }
    }

    const onCloseDrawerFeedbackEditor = () => {
        setLoading(false)
        setDrawerWidth(0)
        feedbackStore.clearFeedbackEditorList()
        feedbackStore.clearSubmittedFormBuilder()
        feedbackStore.setShowFeedbackEditorDrawer(false);
        feedbackStore.clearSelectedElementIndex()
        feedbackStore.clearSelectedFeedbackEditor()

        //Show again feedback editor normal visualization
        feedbackStore.setLoadingProgress(true)
        feedbackStore.getFeedbackformsList(projectStore.projectDetail.id, 'normal').then(res => {
            feedbackStore.setFeedbackformNormalVisualization(res.data)
            feedbackStore.setLoadingProgress(false)
        }).catch(err => feedbackStore.setLoadingProgress(false))
        projectSettingStore.toggleProjectSettingDrawer(true); //Show again project setting drawer    
    };

    return (
        <Drawer
            title={
                <div className="help-btn-wrapper">
                    {t('feedback-editor')}
                    <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div>
                            <HelpButton helppage={"feedback_editor"} />
                        </div>
                    </Tooltip>
                </div>
            }
            placement="left"
            width={drawerWidth}
            closable={true}
            onClose={e => onCloseDrawerFeedbackEditor()}
            visible={feedbackStore.showFeedbackEditorDrawer}
            maskClosable={false}
            mask={false}
            destroyOnClose={true}
            className={`${drawerWidth <= 400 ? 'feedback-editor-drawer' : ''} ${feedbackStore.showFeedbackEditorDrawer ? "feedback-editor-drawer custom-wraper-splitPanel" : "feedback-editor-drawer "}`}
            bodyStyle={{ marginRight: 10 }}
        >
            <FormCreatorWrapper>
                {isTabletOrMobile ? (
                    feedbackStore.showFeedbackEditorDrawer ? (
                        <div className="splitpanel-mobile-left">
                            <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                                <ColumnWidthOutlined />
                            </Button>
                        </div>
                    ) : ""

                ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-left" />)
                }
                <Skeleton loading={loading} active>
                    <Row style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={5} className={drawerWidth <= 768 ? 'w100' : ''}>
                            <Toolsbox onTouch={item => feedbackStore.selectItemFromBasicFields(item)} onDrag={item => feedbackStore.selectItemFromBasicFields(item)} onClick={() => onDropItemHandler(feedbackStore.draggedItemFromBasicFields)} />
                        </Col>
                        <Col className={drawerWidth <= 768 ? 'w100' : ''} xs={24} sm={24} md={feedbackStore.selectedElementIndex !== undefined ? 13 : 19}>
                            <Editor
                                onDroped={() => onDropItemHandler(feedbackStore.draggedItemFromBasicFields)}
                                feedbackEditorList={feedbackStore.feedbackEditorList}
                                projectTeam={projectTeamsStore.teamList}
                                projectId={projectStore.projectDetail.id}
                                viewer={viewer}
                                drawerWidth={drawerWidth}
                                setRefreshFeedbackEditorList={setRefreshFeedbackEditorList}
                                refreshFeedbackEditorList={refreshFeedbackEditorList}
                            />
                        </Col>
                        {feedbackStore.selectedElementIndex !== undefined ?
                            <Col xs={24} sm={24} md={drawerWidth <= 768 ? 24 : 6}>
                                <FormProperties minimal={drawerWidth <= 768 ? 1 : 0} />
                            </Col> : ''
                        }
                    </Row>
                </Skeleton>
            </FormCreatorWrapper>
        </Drawer>
    )
}

export default inject(
    'projectStore',
    'feedbackStore',
    'projectTeamsStore',
    'usersStore',
    'projectSettingStore'
)(observer(DrawerFeedbackEditor));