
import { Button, Form, Input, message, Modal, Row } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import uuid from 'uuid'
import TreeUtils from '@/tree-utils'
import validator from '@/validator'
import { Trans, useTranslation } from 'react-i18next';
import _ from 'lodash';
const ModalAddElement = ({ adminStore, sketchingStore, expandedKeys, getParentKeys }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const AddForm = () => {
        useEffect(() => {
            if (adminStore.elementEdit) {
                form.setFieldsValue({
                    title: sketchingStore.selectedNode.title
                });
            } else {
                form.setFieldsValue({
                    title: '',
                    type: ''
                });
            }
        }, [sketchingStore.selectedNode, adminStore.showAddElement])
        return (
            <Form form={form} name="edit-element" layout="vertical">
                <Form.Item label={t('name')} name="title" rules={[
                    {
                        required: true,
                        message: t('please-input-the-title-of-element'),
                    },
                    { validator: validator.validateEmptyString },
                ]}>
                    <Input
                        placeholder={t('input-element-name')} />
                </Form.Item>
                {
                    !adminStore.elementEdit && (
                        <Form.Item label={t('type')} name="type" rules={[
                            {
                                required: false,
                                message: t('please-input-the-type-of-element'),
                            },
                            { validator: validator.validateEmptyString },
                        ]}>
                            <Input
                                placeholder={t('input-elemnet-type')} />
                        </Form.Item>
                    )
                }
            </Form>
        )
    }
    const onCancelModal = () => {
        adminStore.setShowAddElement(false);
        form.resetFields()
        adminStore.setElementEdit();
    }

    const onSaveModal = () => {
        form.validateFields().then(values => {
            const { treeData } = sketchingStore.currentSketchElement.data;
            const newNode = {
                title: values.title,
                id: adminStore.elementEdit ? sketchingStore.selectedNode?.id : uuid(),
                key: adminStore.elementEdit ? sketchingStore.selectedNode?.key : uuid(),
                type: adminStore.elementEdit ? sketchingStore.selectedNode?.type : values.type,
                parentKey: sketchingStore.selectedNode?.type === 'FOLDER' ? sketchingStore.selectedNode?.key : sketchingStore.selectedNode?.type === 'tree' ? sketchingStore.selectedNode?.parentKey : null,
                children: []
            }
            if (adminStore.elementEdit) {
                addNewTreeNode(newNode, toJS(treeData));
            } else {
                addNewTreeNode(newNode, toJS(treeData), sketchingStore.selectedNode?.type === 'FOLDER' ? sketchingStore.selectedNode?.key : sketchingStore.selectedNode?.type === 'tree' ? sketchingStore.selectedNode?.parentKey : null);
            }
        })
    }

    const addNewTreeNode = (child, data, toData) => {
        if (data?.length) {
            if (adminStore.elementEdit) {
                let toNode;
                if (adminStore.elementEdit.parentKey) {
                    const parrentNode = TreeUtils.searchTreeNode(data, 'key', adminStore.elementEdit.parentKey);
                    toNode = parrentNode.children;
                } else {
                    toNode = data;
                }
                const existed = toNode.find(item => item.title === child.title);
                if (existed && existed.key !== adminStore.elementEdit.key) return message.error(t('element-already-existed'))
                const node = toNode.find(child => child.key === adminStore.elementEdit.key);
                node.title = child.title;
                node.type = child.type;
            } else {
                let toNode;
                if (toData) {
                    const node = TreeUtils.searchTreeNode(data, 'key', toData);
                    node.children = node.children && node.children.length ? node.children : [];
                    toNode = node.children;
                } else {
                    toNode = data;
                }
                const existed = toNode.find(item => item.title === child.title);
                if (existed) return message.error(t('element-already-existed'))
                toNode.push(toJS(child))
            }
        } else {
            data = [child]
        }
        let _param = {
            ...sketchingStore.currentSketchElement.data,
            treeData: data,
            expandedKeys: _.uniqBy([...expandedKeys, ...getParentKeys(data,child.key)])
        }
        if (!adminStore.elementEdit) {
            _param.selectedKeys = [child.key]
            _param.selectedNode = child
        } else {
            _param.selectedNode = { ...sketchingStore.selectedNode, title: child.title, type: child.type }
            _param.selectedKeys = [sketchingStore.selectedNode.key]
        }
        adminStore.setLoadingProgress(true)
        sketchingStore.updateSketchElement(sketchingStore.currentSketchElement.id, { data: _param }).then(res => {
            if (adminStore.elementEdit) {
                sketchingStore.setSelectedNode({ ...sketchingStore.selectedNode, title: child.title, type: child.type })
            }
            form.resetFields()
            adminStore.setShowAddElement(false);
            message.success(adminStore.elementEdit ? t('rename-element-successfully') : t('add-element-successfully'))
            adminStore.setElementEdit();
        }).catch(err => {
            console.log(err)
            message.error(adminStore.elementEdit ? t('rename-element-failed') : t('add-element-failed'))
        }).finally(() => {
            adminStore.setLoadingProgress(false)
        })
    }


    return (
        <Modal
            visible={adminStore.showAddElement}
            title={adminStore.elementEdit ? t('rename') : t('create-new-element')}
            onCancel={onCancelModal}
            onOk={onSaveModal}
            forceRender={true}
            zIndex={100000}
            maskClosable={false}
            footer={
                <Row justify='end'>
                    <Button key="back" onClick={onCancelModal}>
                        {t('commons.cancel')}
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={onSaveModal}>
                        {adminStore.elementEdit ? t('rename') : t('commons.create')}
                    </Button>
                </Row>
            }>
            <AddForm />
        </Modal>
    )
}

export default inject('adminStore', 'sketchingStore')(observer(ModalAddElement))