import { Button, message, Spin } from 'antd'
import { SaveOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react';
import { SelectObjectModelFlatDialogStyle } from './style'
import { useTranslation } from 'react-i18next';
import {
    Color,
    ScreenSpaceEventType,
    ScreenSpaceEventHandler,
    Cesium3DTileFeature,
    Cesium3DTileStyle,
    Color as CesiumColor,
} from 'cesium'
import { checkSketchMeshModel, clickPoint } from '@/helper'
import { Gannt4dRequest } from '@/requests'

const SelectObjectModelControl = props => {
    const { projectStore, viewer, sketchingStore, projectGanttStore } = props
    const { t } = useTranslation();
    const [folderPaths] = useState([])
    const [loading, setLoading] = useState(false)
    let folders = projectStore.projectDetail.treeData


    useEffect(() => {
        const handler = new ScreenSpaceEventHandler(
            viewer.current.cesiumElement.canvas
        )
        if (projectGanttStore.selectObjectModel.open) {
            if (projectGanttStore.selectObjectModel.listExist?.length) {
                if (projectGanttStore.selectObjectModel.type === 'model') {
                    projectStore.tileViews.forEach((tileView) => {
                        const tileElement = tileView.ref.current?.cesiumElement;
                        const idModel = tileElement?.id || tileView?.props?.id;
                        const existingItem = projectGanttStore.selectObjectModel.listExist.find(
                            (x) => x.GUID === idModel && x.model3D === idModel && x.type === 'model'
                        );

                        if (existingItem && tileElement) {
                            highlightModel(tileElement);
                        }
                    });
                } else { // type object
                    projectGanttStore.selectObjectModel.listExist.map(item => {
                        const modelId = item?.model3D
                        if (item.GUID && projectStore.modelFeatures[modelId]) {
                            const features = projectStore.modelFeatures[modelId][item.GUID];
                            let arraySelect = []
                            if (Array.isArray(features)) {
                                features.map(feature => {
                                    if (feature && feature instanceof Cesium3DTileFeature) {
                                        feature.isColorEntitiesValue = false
                                        arraySelect.push(feature)
                                    }
                                })
                            } else {
                                if (features && features instanceof Cesium3DTileFeature) {
                                    features.isColorEntitiesValue = false
                                    arraySelect.push(features)
                                }
                            }
                            setFeaturesColor(arraySelect, Color.RED)
                        }
                        if (item.objectType === 'file') {
                            let cesium3dtileset = findTile(item.GUID, projectStore.tileViews)
                            if (!cesium3dtileset) return;
                            cesium3dtileset.modelId = item?.model3D?.id
                            cesium3dtileset.isColorEntitiesValue = false
                            cesium3dtileset.objectType = 'file'
                            if (cesium3dtileset?.entities?.values) {
                                setColorEntitiesValue([cesium3dtileset])
                            } else {
                                setTilesetColor([cesium3dtileset])
                            }
                        }
                    })
                }
            }
            handler.setInputAction(function (movement) {
                if (!viewer.current.cesiumElement.scene) return
                const resultClick = clickPoint(viewer.current.cesiumElement, movement.position)
                if (!resultClick || !resultClick.pickedObject) return
                const { pickedObject } = resultClick;
                const primitiveId = pickedObject?.primitive?.id
                let cesium3dtileset = findTile(primitiveId, projectStore.tileViews)

                if (projectGanttStore.selectObjectModel.type === 'model') {
                    let modelId;
                    if (pickedObject instanceof Cesium3DTileFeature) {
                        modelId = pickedObject?.tileset?.id;
                    } else if (primitiveId && cesium3dtileset) {
                        modelId = primitiveId
                    } else if (pickedObject?.id?.modelType === 'kml' || pickedObject?.id?.modelType === 'geojson') {
                        modelId = pickedObject?.id?.modelId;
                        cesium3dtileset = findTile(modelId, projectStore.tileViews)
                        if (!cesium3dtileset) {
                            return
                        }
                    } else {
                        console.warn('Unsupported model type for selection');
                        return;
                    }

                    if (!modelId || !cesium3dtileset) return;

                    const model = projectStore.modelList.find(c => c.id === modelId);
                    if (!model) return;
                    const folderTree = getNestedPath(folders, '', folderPaths).find(
                        (f) => f.id === model.id && f.path
                    )?.path || '/';
                    const existingItem = projectGanttStore.selectObjectModel.listExist.find(
                        (x) => x.GUID === modelId && x.model3D === modelId && x.type === 'model'
                    );

                    const updateSelectObject = (newListExist) => {
                        projectGanttStore.setSelectObjectModel({
                            ...projectGanttStore.selectObjectModel,
                            listExist: newListExist,
                        });
                    };

                    if (!existingItem) {
                        const newListItem = {
                            GUID: modelId,
                            model3D: model.id,
                            sketch: undefined,
                            fileName: model.name,
                            folderTree,
                            task: projectGanttStore.selectObjectModel.task.id,
                            objectType: 'file',
                            name: '',
                            type: projectGanttStore.selectObjectModel.type
                        };
                        updateSelectObject([...projectGanttStore.selectObjectModel.listExist, newListItem]);
                        highlightModel(cesium3dtileset);
                    } else {
                        const newListExist = projectGanttStore.selectObjectModel.listExist.filter(
                            (x) => x.GUID !== modelId && x.model3D !== modelId && x.type === 'model'
                        );
                        updateSelectObject(newListExist);
                        unHighlightModel(cesium3dtileset)
                    }
                } else { // type object
                    if (resultClick.pickedObject instanceof Cesium3DTileFeature) { // model3d (xml, ifc)
                        resultClick.pickedObject.isColorEntitiesValue = false
                        const modelId = resultClick.pickedObject?.tileset?.id
                        if (modelId) {
                            const obj = resultClick.pickedObject
                            const GUID = obj?.getProperty('GUID');
                            const features = projectStore.modelFeatures[modelId][GUID];
                            let arraySelect = []
                            if (Array.isArray(features)) {
                                features.map(feature => {
                                    if (feature && feature instanceof Cesium3DTileFeature) {
                                        feature.isColorEntitiesValue = false
                                        arraySelect.push(feature)
                                    }
                                })
                            } else {
                                if (features && features instanceof Cesium3DTileFeature) {
                                    features.isColorEntitiesValue = false
                                    arraySelect.push(features)
                                }
                            }
                            selectFeatures(arraySelect);
                        } else {
                            selectFeatures([resultClick.pickedObject])
                        }
                    } else if (primitiveId && cesium3dtileset) {
                        cesium3dtileset.objectType = 'file'
                        cesium3dtileset.isColorEntitiesValue = false
                        if (cesium3dtileset?.entities?.values) {
                            cesium3dtileset.isColorEntitiesValue = true
                        }
                        selectFeatures([cesium3dtileset]);
                    } else if (pickedObject?.id?.modelType === 'kml' || pickedObject?.id?.modelType === 'geojson') {
                        const modelId = pickedObject?.id?.modelId;
                        let cesiumkml = findTile(modelId, projectStore.tileViews)
                        if (cesiumkml) {
                            cesiumkml.objectType = 'file'
                            cesiumkml.modelId = modelId
                            cesiumkml.isColorEntitiesValue = true
                            processFeature([cesiumkml])
                        }
                    } else { // click on sketch
                        const pickedObjectID = checkSketchMeshModel(pickedObject) ? pickedObject.id : pickedObject.id.id
                        const sketch = sketchingStore.arrSketches.find(x => x._id === pickedObject.id._id || pickedObjectID.indexOf(x.id) === 0);
                        if (!sketch) return;

                        let findObj = projectGanttStore.selectObjectModel.listExist?.find(x => x.sketch === sketch.id)
                        if (!findObj) {
                            const folderTree = getNestedPath(folders, '', folderPaths).find(
                                (f) => f.id === sketch.id && f.path
                            )?.path || '/';
                            const newListItem = {
                                GUID: pickedObjectID,
                                sketch: sketch._id,
                                fileName: sketch.name,
                                folderTree,
                                task: projectGanttStore.selectObjectModel.task.id,
                                objectType: 'sketch',
                                name: sketch.name,
                                type: projectGanttStore.selectObjectModel.type
                            };
                            projectGanttStore.setSelectObjectModel({
                                ...projectGanttStore.selectObjectModel,
                                listExist: [...projectGanttStore.selectObjectModel.listExist, newListItem],
                            });
                        } else {
                            removeObjectHightLight(findObj)
                        }
                    }
                }
            }, ScreenSpaceEventType.LEFT_CLICK)
        }
        return () => {
            if (handler) handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
        }
    }, [projectGanttStore.selectObjectModel.open])

    const highlightModel = (tileElement) => {
        if (!tileElement) return;

        if (tileElement?.entities?.values) {
            const tileView = tileElement.entities.values
            if (tileView) {
                tileView.map(feature => {
                    if (feature?.polygon?.material) {
                        feature.polygon.material = Color.clone(Color.RED, feature.color);
                    } else if (feature?.polyline?.material) {
                        feature.polyline.material = Color.clone(Color.RED, feature.color);
                    } else if (feature?.point?.color) {
                        feature.point.color = Color.clone(Color.RED, feature.color);
                    } else if (feature?.billboard?.color) {
                        feature.billboard.color = Color.clone(Color.RED, feature.color);
                    }
                })
            }
        } else {
            tileElement.style = new Cesium3DTileStyle({
                color: {
                    conditions: [
                        ['true', 'color("red")'],
                    ],
                },
            });
        }

        viewer.current.cesiumElement.scene.requestRender();
    }

    const unHighlightModel = (tileElement) => {
        if (!tileElement) return;
        if (tileElement?.entities?.values) {
            const tileView = tileElement.entities.values
            tileView.map(feature => {
                if (feature?.polygon?.material) {
                    feature.polygon.material = Color.clone(Color.WHITE, feature.color);
                } else if (feature?.polyline?.material) {
                    feature.polyline.material = Color.clone(Color.WHITE, feature.color);
                } else if (feature?.point?.color) {
                    feature.point.color = Color.clone(Color.WHITE, feature.color);
                } else if (feature?.billboard?.color) {
                    feature.billboard.color = Color.clone(Color.WHITE, feature.color);
                }
            })
        } else {
            tileElement.style = new Cesium3DTileStyle();
        }
        viewer.current.cesiumElement.scene.requestRender();
    }

    const getNestedPath = (items, currentPath = '', store = []) => {
        if (!Array.isArray(items)) return; // Exit if not an array

        items.forEach((item) => {
            if (!item.children) {
                if (currentPath) {
                    store.push({ id: item.modelId || item.sketchId, path: currentPath });
                }
                return; // Skip processing children for leaf nodes
            }

            const nextPath = `${currentPath}/${item.title}`;
            getNestedPath(item.children, nextPath, store);
        });

        return store;
    }

    const processFeature = async (features) => {
        projectStore.setLoadingProgress(true)

        features.map(async feature => {
            if (feature?.objectType === 'file') {
                let model = projectStore.modelList.find(c => c.id === feature.id || c.id === feature.modelId)
                if (!model) return;
                const modelID = model.id;
                const folderTree = getNestedPath(folders, '', folderPaths).find(
                    (f) => f.id === model.id && f.path
                )?.path || '/';;
                let findObj = projectGanttStore.selectObjectModel.listExist?.find(x => x.GUID === modelID && x.model3D === modelID && x.type === 'object')
                if (!findObj) {
                    if (feature?.entities?.values) {
                        setColorEntitiesValue([feature])
                    } else {
                        setTilesetColor([feature])
                    }
                    const newListItem = {
                        GUID: modelID,
                        model3D: model.id,
                        sketch: undefined,
                        fileName: model.name,
                        folderTree,
                        task: projectGanttStore.selectObjectModel.task.id,
                        objectType: 'file',
                        name: '',
                        type: projectGanttStore.selectObjectModel.type
                    };
                    setLoading(true)
                    const { data } = await Gannt4dRequest.getGantt3DObjectsName({ ganttObjects: [newListItem] })
                    projectGanttStore.setSelectObjectModel({
                        ...projectGanttStore.selectObjectModel,
                        listExist: [...projectGanttStore.selectObjectModel.listExist, data[0]],
                    });
                    setLoading(false)
                } else {
                    removeObjectHightLight(findObj)
                }
            } else {
                if (!feature || !feature.content || !feature.content.tileset) return
                const model = projectStore.findModelByUrl(feature.content.tileset.url || feature.content.tileset._url)
                const GUID = feature?.getProperty('GUID');
                if (!model || !GUID) return
                let findObj = projectGanttStore.selectObjectModel.listExist?.find(x => x.GUID === GUID && x.model3D === model.id && x.type === 'object')
                if (!findObj) {
                    const folderTree = getNestedPath(folders, '', folderPaths).find(
                        (f) => f.id === model.id && f.path
                    )?.path || '/';;
                    const newListItem = {
                        GUID,
                        model3D: model.id,
                        sketch: undefined,
                        fileName: model.name,
                        folderTree,
                        task: projectGanttStore.selectObjectModel.task.id,
                        objectType: 'object',
                        name: '',
                        type: projectGanttStore.selectObjectModel.type
                    };
                    setLoading(true)
                    const { data } = await Gannt4dRequest.getGantt3DObjectsName({ ganttObjects: [newListItem] })
                    projectGanttStore.setSelectObjectModel({
                        ...projectGanttStore.selectObjectModel,
                        listExist: [...projectGanttStore.selectObjectModel.listExist, data[0]],
                    });
                    setLoading(false)
                } else {
                    removeObjectHightLight(findObj)
                }
            }
        })
        projectStore.setLoadingProgress(false)
    }

    const removeObjectHightLight = (obj) => {
        if (obj?.objectType === 'object' && obj.GUID && projectStore.modelFeatures[obj?.model3D]) {
            const features = projectStore.modelFeatures[obj.model3D][obj.GUID];
            let arraySelect = []
            if (Array.isArray(features)) {
                features.map(feature => {
                    if (feature && feature instanceof Cesium3DTileFeature) {
                        feature.isColorEntitiesValue = false
                        arraySelect.push(feature)
                    }
                })
            } else {
                if (features && features instanceof Cesium3DTileFeature) {
                    features.isColorEntitiesValue = false
                    arraySelect.push(features)
                }
            }

            const newListExist = projectGanttStore.selectObjectModel.listExist.filter(item => item.GUID !== obj?.GUID);
            projectGanttStore.setSelectObjectModel({
                ...projectGanttStore.selectObjectModel,
                listExist: newListExist,
            });
            // remove hightlight feature
            unselectFeatures(arraySelect)
        }

        // remove objectType sketch
        if (obj?.objectType === 'sketch') {
            const newListExist = projectGanttStore.selectObjectModel.listExist.filter(item => item.sketch !== obj.sketch);
            projectGanttStore.setSelectObjectModel({
                ...projectGanttStore.selectObjectModel,
                listExist: newListExist,
            });
        }

        // remove objecttType file
        if (obj?.objectType === 'file') {
            const newListExist = projectGanttStore.selectObjectModel.listExist.filter(item => item.GUID !== obj?.GUID && item.model3D !== obj.model3D);
            projectGanttStore.setSelectObjectModel({
                ...projectGanttStore.selectObjectModel,
                listExist: newListExist,
            });
            let _model = findTile(obj.GUID, projectStore.tileViews)
            unselectFeatures([_model])
        }
    }

    const unselectFeatures = features => {
        if (!features || !features.length) {
            return;
        }
        resetTilesetColor(features)
        setFeaturesColor(features, Color.WHITE)
        setColorEntitiesValue(features, true)
    }

    const selectFeatures = features => {
        setFeaturesColor(features, Color.RED)
        processFeature(features)
    }

    const setFeaturesColor = (features, color) => {
        features.forEach(feature => {
            if (!feature?.isColorEntitiesValue) {
                feature.color = Color.clone(color, feature?.color || feature?._color);
            }
        });
        const scene = viewer.current.cesiumElement.scene;
        if (scene.requestRenderMode) {
            scene.requestRender();
        }
    };

    const resetTilesetColor = (tilesets) => {
        if (tilesets?.length > 0) {
            tilesets.map(tileset => {
                if (tileset?.objectType === 'file' && !tileset?.isColorEntitiesValue) {
                    tileset.style = new Cesium3DTileStyle();
                }
            })
            if (viewer.current.cesiumElement.scene.requestRenderMode) {
                viewer.current.cesiumElement.scene.requestRender();
            }
        }
    }

    const setTilesetColor = (tilesets) => {
        if (tilesets?.length > 0) {
            tilesets.map(tileset => {
                if (tileset?.objectType === 'file' && !tileset?.isColorEntitiesValue) {
                    tileset.style = new Cesium3DTileStyle({
                        color: {
                            conditions: [
                                ['true', 'color("red")'],
                            ],
                        },
                    });
                }
            })
            if (viewer.current.cesiumElement.scene.requestRenderMode) {
                viewer.current.cesiumElement.scene.requestRender();
            }
        }
    }

    const setColorEntitiesValue = (features, isReset) => {
        if (!isReset) {
            features.map(feature => {
                const tileView = feature?.entities?.values
                if (tileView) {
                    tileView.map(feature => {
                        if (feature?.polygon?.material) {
                            feature.polygon.material = Color.clone(Color.RED, feature.color);
                        } else if (feature?.polyline?.material) {
                            feature.polyline.material = Color.clone(Color.RED, feature.color);
                        } else if (feature?.point?.color) {
                            feature.point.color = Color.clone(Color.RED, feature.color);
                        } else if (feature?.billboard?.color) {
                            feature.billboard.color = Color.clone(Color.RED, feature.color);
                        }
                    })
                }
            })
        } else {
            features.map(feature => {
                const tileView = feature?.entities?.values
                if (tileView && feature?.isColorEntitiesValue) {
                    tileView.map(feature => {
                        if (feature?.polygon?.material) {
                            feature.polygon.material = Color.clone(Color.WHITE, feature.color);
                        } else if (feature?.polyline?.material) {
                            feature.polyline.material = Color.clone(Color.WHITE, feature.color);
                        } else if (feature?.point?.color) {
                            feature.point.color = Color.clone(Color.WHITE, feature.color);
                        } else if (feature?.billboard?.color) {
                            feature.billboard.color = Color.clone(Color.WHITE, feature.color);
                        }
                    })
                }
            })
        }
        if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
    }

    const findTile = (tileId, tileViews) => {
        if (!tileId) return false
        let fkey = tileId + '-tile'
        let tile = tileViews.find(t => t.key == fkey)
        if (!tile) return false
        if (!tile.ref) return false
        if (!tile.ref.current) return false
        return tile.ref.current.cesiumElement
    }

    const handleClickSave = async (e) => {
        e.preventDefault()
        projectStore.setLoadingProgress(true)
        await Gannt4dRequest.creatGanttTask3DObjects({ taskObjects: projectGanttStore.selectObjectModel.listExist, task: projectGanttStore.selectObjectModel.task.id, type: projectGanttStore.selectObjectModel.type })
            .then(response => {
                handleCloseSelectObjectModel()
            }).catch(error => {
                console.log(error)
                message.error(t('update-failed'))
            }).finally(() => {
                projectStore.setLoadingProgress(false)
            })
    }

    const handleCloseSelectObjectModel = () => {
        projectStore.setCleanMode(false)

        // reopen gantt drawer
        if (projectGanttStore.selectObjectModel.open) {
            projectGanttStore.setSelectObjectModel({ task: '', type: '', listExist: [], open: false })
            projectGanttStore.setIsShowGanttPanel(true)
        }
    }

    return (
        <SelectObjectModelFlatDialogStyle>
            <Spin spinning={loading || projectStore.isLoading}>
                <div>{`${t('commons.select')} ${t(`${projectGanttStore.selectObjectModel.type}`)}`}</div>
            </Spin>
            <div>
                <Button
                    onClick={(e) => handleClickSave(e)}
                    type={'primary'}
                    disabled={loading || projectStore.isLoading}>
                    <SaveOutlined />{t('commons.save')}
                </Button>
                <Button color="default" variant="outlined" onClick={handleCloseSelectObjectModel} style={{ marginLeft: 8 }} disabled={loading || projectStore.isLoading}>{t('commons.cancel')}</Button>
            </div>
        </SelectObjectModelFlatDialogStyle>
    )
}

export default inject(
    'projectStore',
    'sketchingStore',
    'projectGanttStore'
)(observer(SelectObjectModelControl))
