import {
  Rectangle,
  Math as CesiumMath,
  ClassificationType,
  ImageMaterialProperty, Color,
  WebMapServiceImageryProvider,
  defined, Request, RequestState, RequestType, TileProviderError,
  ImageryState, ImageryLayer, Resource, IonImageryProvider,
  TileMapServiceImageryProvider
} from 'cesium'
import { Entity, RectangleGraphics, ImageryLayer as RImageryLayer } from 'resium'
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { apiUrl } from '@/config'
import i18n from 'i18next';
import TreeUtils from '@/tree-utils'
import { notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';

ImageryLayer.prototype._requestImagery = function (imagery) {
  var imageryProvider = this._imageryProvider;

  var that = this;

  function success(image) {
    if (!defined(image)) {
      return failure();
    }
    if (imageryProvider.imageProcess) {
      imagery.state = undefined;
      imagery.request = undefined;
      imageryProvider.imageProcess(imagery, imageryProvider._pickFeaturesResource?._queryParameters?.version)
      TileProviderError.reportSuccess(that._requestImageError);
    }
    else {
      imagery.image = image;
      imagery.state = ImageryState.RECEIVED;
      imagery.request = undefined;

      TileProviderError.reportSuccess(that._requestImageError);
    }
  }

  function failure(e) {
    if (imagery.request.state === RequestState.CANCELLED) {
      // Cancelled due to low priority - try again later.
      imagery.state = ImageryState.UNLOADED;
      imagery.request = undefined;
      return;
    }

    // Initially assume failure.  handleError may retry, in which case the state will
    // change to TRANSITIONING.
    imagery.state = ImageryState.FAILED;
    imagery.request = undefined;

    var message =
      `${i18n.t('failed-to-obtain-image-tile')} X: ` +
      imagery.x +
      " Y: " +
      imagery.y +
      ` ${i18n.t('level')}: ` +
      imagery.level +
      ".";
    that._requestImageError = TileProviderError.reportError(
      that._requestImageError,
      imageryProvider,
      imageryProvider.errorEvent,
      message,
      imagery.x,
      imagery.y,
      imagery.level,
      doRequest,
      e
    );
  }

  function doRequest() {
    var request = new Request({
      throttle: false,
      throttleByServer: true,
      type: RequestType.IMAGERY,
    });
    imagery.request = request;
    imagery.state = ImageryState.TRANSITIONING;

    var imagePromise = imageryProvider.requestImage(
      imagery.x,
      imagery.y,
      imagery.level,
      request
    );

    if (!defined(imagePromise)) {
      // Too many parallel requests, so postpone loading tile.
      imagery.state = ImageryState.UNLOADED;
      imagery.request = undefined;
      return;
    }

    if (defined(imageryProvider.getTileCredits)) {
      imagery.credits = imageryProvider.getTileCredits(
        imagery.x,
        imagery.y,
        imagery.level
      );
    }
    imagePromise
      .then(function (image) {
        success(image);
      })
      .catch(function (e) {
        failure(e);
      });
  }

  doRequest();
};
const ImageryView = ({ projectStore, viewer, modelList = [], imageryViewLink, userGroupStore }) => {
  const { t } = useTranslation();
  const [imageryViews, setImageryViews] = useState([]);
  let loadedImagery = {};
  const { rerenderWMS } = projectStore;
  const checkNeedUsedProxy = async (model) => {
    let requestOption = {}
    const username = model.data.username
    const password = model.data.password
    if (username && password && model.data.isUseCredential) {
      const Authorization = btoa(`${username.trim()}:${password}`)
      requestOption.headers = {
        Authorization: `Basic ${Authorization}`
      }
    }
    return await fetch(`${model.src}?request=GetCapabilities&service=WMS`, requestOption).then(function (response) {
      if (!response.ok) {
        return true // if error then need used proxy
      } else {
        return false
      }
    }).catch(error => {
      return true; // if error then need used proxy
    })
  }

  // Sort array of objects based on another array
  function mapOrder(array, order, key) {
    const c = order.map((i) => array.find((j) => j.id === i.modelId));
    return toJS(c)
  }

  useEffect(() => {
    if (projectStore.projectDetail?.treeData && viewer && viewer?.current?.cesiumElement?.clock && viewer?.current?.cesiumElement?.camera && modelList.length) {
      (async () => {
        const listOuterWMS = TreeUtils.findAllNode(projectStore.projectDetail.treeData) || [];
        const listAllModel3DSLink = TreeUtils.findAllProjectLinkNode(projectStore?.listAllModel3DSLink || []);
        const mergeOuterWMS = listOuterWMS.concat(listAllModel3DSLink)
        let ordered_array = mapOrder(modelList, mergeOuterWMS, 'id').filter(function (element) {
          return element !== undefined;
        });
        let reverseModelList = ordered_array.slice().reverse();
        projectStore.setWMSOrders(reverseModelList);
        let arrImageryViews = []
        for (let [index, model] of reverseModelList.entries()) {
          if (model.data.presentation !== 'PLANE' && model.data.classificationType === 'TERRAIN') {
            model.data.useWMSProvider = true
          }
          const ref = React.createRef()
          let isShow = true

          let defaultAlpha = 0
          if (isNaN(model.data.alpha)) {
            defaultAlpha = 1
          } else {
            if (model.data.alpha <= 1 && model.data.alpha >= 0) {
              defaultAlpha = 1 - model.data.alpha
            } else {
              defaultAlpha = 1
            }
          }
          if (model.data.bbox && model.type !== 'geotiff') {
            let modelSrc = model.src;

            if (model.data.useProxy) {
              let _checkNeedUsedProxy = await checkNeedUsedProxy(model);
              if (_checkNeedUsedProxy) {
                modelSrc = `${apiUrl}/wms/image/${encodeURIComponent(modelSrc)}`
              }
            }
            // eslint-disable-next-line no-loop-func
            function imageProcess(tile, version) {
              try {

                const intersection = Rectangle.intersection(Rectangle.fromDegrees(...model.data.bbox), tile.rectangle)
                if (intersection) {

                  const quadbbox = [
                    CesiumMath.toDegrees(intersection.west),
                    CesiumMath.toDegrees(intersection.south),
                    CesiumMath.toDegrees(intersection.east),
                    CesiumMath.toDegrees(intersection.north)
                  ];
                  const imageryKey = `${model.id}-tile-${tile.x}-${tile.y}-${tile.level}`;
                  const image = new Image();
                  image.width = 256;
                  image.height = 256;

                  image.crossOrigin = 'Anonymous';
                  const params = {
                    transparent: true,
                    format: 'image/png',
                    service: 'WMS',
                    request: 'GetMap',
                    styles: '',
                    layers: model.data.layers,
                    bbox: quadbbox.join(','),
                    width: image.width,
                    height: image.height,
                    ...model.data.isNcWms && {
                      styles: `default-scalar/${model.data.palette}`,
                      COLORSCALERANGE: `${model.data.scaleRange.min},${model.data.scaleRange.max}`,
                      TIME: model.data.time
                    }
                  }
                  let _version = version ? version : model.data.version
                  if (_version) {
                    if (['1.0.0', '1.1.0', '1.1.1'].includes(_version)) {
                      params.srs = 'EPSG:4326';
                    } else {
                      params.crs = 'EPSG:4326';
                    }
                    params.version = _version
                  } else {
                    params.srs = 'EPSG:4326';
                  }
                  let imageUrl = `${modelSrc}?${new URLSearchParams(params)}`;
                  if (modelSrc.indexOf('?') > -1) {
                    imageUrl = `${modelSrc}&${new URLSearchParams(params)}`;
                  }
                  let elevation = 0
                  if (model.data.presentation === 'PLANE' && model.data.classificationType === "TERRAIN") {
                    elevation = model.data.height
                  }

                  const imageLoaded = () => {
                    try {
                      const rectangleProps = {
                        coordinates: Rectangle.fromDegrees(...quadbbox),
                        allowPicking: false,
                        material: new ImageMaterialProperty({
                          image: image,
                          transparent: true,
                          color: Color.WHITE.withAlpha(defaultAlpha),
                        }),
                        classificationType: ClassificationType[model.data.classificationType],
                        zIndex: index,
                        ...model.data.height && { height: elevation }
                      }
                      loadedImagery[imageryKey] =
                        <Entity level={tile.level} bbox={quadbbox} key={imageryKey} ref={React.createRef()}>
                          <RectangleGraphics  {...rectangleProps} />
                        </Entity>
                      processRemoveAndAddImagery(model, tile, imageryKey);

                    } catch (error) {
                      console.log(error)
                    }
                  }

                  if (!loadedImagery[imageryKey]) {
                    image.addEventListener("load", imageLoaded);
                    image.src = imageUrl;
                  } else {
                    processRemoveAndAddImagery(model, tile, imageryKey);
                  }
                }
              } catch (error) {
                console.log(error)
              }
            }
            let resourceUri = modelSrc

            if (model.data.username && model.data.password && model.data.isUseCredential) {
              const Authorization = btoa(`${model.data.username.trim()}:${model.data.password}`)

              resourceUri = new Resource({
                url: modelSrc,
                headers: {
                  'Authorization': `Basic ${Authorization}`,
                  'Access-Control-Allow-Origin': 'https://localhost:3000',
                }
              })
            }

            const provider = new WebMapServiceImageryProvider({
              url: resourceUri,
              layers: model.data.layers,
              parameters: {
                ...model.data.parameters,
                ...model.data.isNcWms && {
                  styles: `default-scalar/${model.data.palette}`,
                  COLORSCALERANGE: `${model.data.scaleRange.min},${model.data.scaleRange.max}`,
                  TIME: model.data.time,
                  tiled: true,
                  defaultAlpha: defaultAlpha
                }
              },
              rectangle: Rectangle.fromDegrees(...model.data.bbox),
            })
            if (!model.data.useWMSProvider) {
              provider.imageProcess = imageProcess
            }

            let checkImageProvider = await provider.requestImage().catch((error) => {
              return error;
            })

            if (checkImageProvider && checkImageProvider.statusCode && checkImageProvider.statusCode === 401) {
              notification.open({
                message: t('error'),
                description: `${model.name} ${t('access-denied-invalid-credentials')}`,
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 5,
              })
            }

            if (imageryViewLink) {
              let visibleTileViewIds = projectStore.getVisibleTileViewLinkIds();
              if (!visibleTileViewIds.includes(model.id)) {
                isShow = false
              }
              if (projectStore.hiddenModelLinkIDs.includes(model.id)) {
                isShow = false
              }
            } else {
              const isIdInList = (id, list) => {
                return list.some(obj => obj.modelId === id);
              }
              if (isIdInList(model.id, userGroupStore.nodeNoAccessRights)) {
                isShow = false;
              } else {
                let isExist = projectStore.visibleTilesets.find(c => c.modelId === model.id)
                if (isExist) {
                  isShow = isExist.isVisible
                }
              }
            }

            let _imagelayer = <RImageryLayer
              key={imageryViewLink ? model.id + '-tile' + '-link' : model.id + '-tile'}
              show={isShow}
              ref={ref}
              alpha={defaultAlpha}
              imageryProvider={
                provider
              }
              index={index + 1}
            />

            const findIndex = arrImageryViews.findIndex((e) => e.key === _imagelayer.key);
            if (findIndex === -1) {
              arrImageryViews.push(_imagelayer);
            } else {
              arrImageryViews[index] = _imagelayer;
            }

          } else {
            const isIdInList = (id, list) => {
              return list.some(obj => obj.modelId === id);
            }
            if (isIdInList(model.id, userGroupStore.nodeNoAccessRights)) {
              isShow = false;
            } else {
              let isExist = projectStore.visibleTilesets.find(c => c.modelId === model.id)
              if (isExist) {
                isShow = isExist.isVisible
              }
            }
            let imagertProvider = null
            if (model.data.srcTileset) {
              imagertProvider = await TileMapServiceImageryProvider.fromUrl(model.data.srcTileset)
            }
            if (imagertProvider) {
              let _imagelayer = <RImageryLayer
                key={imageryViewLink ? model.id + '-tile' + '-link' : model.id + '-tile'}
                show={isShow}
                ref={ref}
                alpha={defaultAlpha}
                imageryProvider={imagertProvider}
                index={index + 1}
              />
              if (!model.data.bbox) {
                try {
                  let _bbox = Object.keys(_imagelayer.props.imageryProvider.rectangle).map((k) => _imagelayer.props.imageryProvider.rectangle[k])
                  updateModel(model, _bbox)
                } catch (error) {
                  console.log('error', error)
                }
              }

              const findIndex = arrImageryViews.findIndex((e) => e.key === _imagelayer.key);
              if (findIndex === -1) {
                arrImageryViews.push(_imagelayer);
              } else {
                arrImageryViews[index] = _imagelayer;
              }
            }
          }
        }
        setImageryViews(arrImageryViews)
      })()
    }
    return () => {
      setImageryViews([]);
    }
  }, [modelList.length, projectStore.webglContextAlpha, rerenderWMS])
  // }, [projectStore.projectDetail.treeData, modelList, projectStore.webglContextAlpha, rerenderWMS])


  const updateModel = async (model, bbox) => {
    let _newData = model.data;
    _newData.bbox = bbox
    await projectStore.update3DMODELS(model._id, { data: _newData })
  }

  useEffect(() => {
    if (!imageryViewLink) {
      if (projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0) {
        const visibleTileViewIds = projectStore.visibleTilesets.map(item => {
          if (item.isVisible) {
            return item.modelId + '-tile'
          }
        })
        imageryViews.forEach(imageryView => {
          const imageryElement = imageryView.ref.current.cesiumElement
          const splitKey = imageryView.key.split('-');
          if (!(visibleTileViewIds.indexOf(splitKey[0] + '-' + splitKey[1]) > -1)) {
            imageryElement.show = false;
          } else {
            imageryElement.show = true;
            if (imageryElement.imageryProvider && imageryElement.imageryProvider.hasOwnProperty('requestImage')) {
              imageryElement.imageryProvider.requestImage().catch((error) => {
                if (error && error.statusCode && error.statusCode === 401) {
                  notification.open({
                    message: t('error'),
                    description: t('access-denied-invalid-credentials'),
                    icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                    duration: 5,
                  })
                }
              })
            }
          }
        })
      }
    }
    if (viewer.current.cesiumElement?.scene?.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }, [projectStore.visibleTilesets])

  useEffect(() => {
    if (imageryViewLink) {
      if (imageryViews && imageryViews.length > 0) {
        let visibleTileViewIds = projectStore.getVisibleTileViewLinkIds(true)
        imageryViews.map(imageryView => {
          const imageryElement = imageryView?.ref?.current?.cesiumElement
          const splitKey = imageryView.key.split('-');
          let idElement = splitKey[0] || imageryView?.props?.id
          const model = projectStore.listAllModel3DSLink.find(c => c.id === idElement);
          if (imageryElement && model && model?.isVisible4D) {
            let isShow = true;
            if (!visibleTileViewIds.includes(imageryView.key)) {
              isShow = false
            }
            if (projectStore.hiddenModelLinkIDs.includes(idElement)) {
              isShow = false
            }
            imageryElement.show = isShow;
          }
        })
      }
    }
    if (viewer.current.cesiumElement?.scene?.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }, [projectStore.listProjectLink, imageryViews.length, projectStore.hiddenModelLinkIDs])

  useEffect(() => {
    if (projectStore.deleteIds && projectStore.deleteIds.length > 0) {
      setImageryViews(imageryViews.filter(t => !projectStore.deleteIds.includes(t.key.split('-')[0])))
      // eslint-disable-next-line react-hooks/exhaustive-deps
      loadedImagery = Object.keys(loadedImagery)
        .filter(key => !projectStore.deleteIds.includes(key.split('-')[0]))
        .reduce((res, key) => (res[key] = loadedImagery[key], res), {});
    }
  }, [projectStore.deleteIds])

  const addImageryView = (imageryViews, imageryKey) => {
    if (!imageryViews.find(imagery => imagery.key === imageryKey)) {
      return [
        ...imageryViews,
        loadedImagery[imageryKey]
      ]
    }
    return imageryViews
  }

  const removeImageryViews = (imageryViews, removeImageryKeys) => {
    const results = []
    let maxLevel = 0
    for (const key in loadedImagery) {
      const view = loadedImagery[key];
      const level = view.props.level
      if (maxLevel < level) maxLevel = level
    }
    for (let i = 0; i < imageryViews.length; i++) {
      const view = imageryViews[i];
      const key = view.key;

      if (!results.find(x => x.key === view.key) && !removeImageryKeys.includes(view.key)) {
        if (!loadedImagery[key]) results.push(view)
        else {
          const level = view.props.level
          if ((maxLevel - 3) <= level) {

            results.push(view)
          }
        }

      }
    }
    return results
  }

  const processRemoveAndAddImagery = (model, tile, imageryKey) => {
    if (projectStore.visibleTilesets && projectStore.visibleTilesets.length > 0) {
      const visibleTileViewIds = projectStore.visibleTilesets.map(item => {
        if (item.isVisible) {
          return item.modelId + '-tile'
        }
      });
      if (visibleTileViewIds.indexOf(`${model.id}-tile`) > -1) {
        const removeImageryKeys = [];
        let parent = tile.parent;
        while (parent) {
          removeImageryKeys.push(`${model.id}-tile-${parent.x}-${parent.y}-${parent.level}`);
          parent = parent.parent
        }
        if (tile.northeastChild) {
          removeImageryKeys.push(`${model.id}-tile-${tile.northeastChild.x}-${tile.northeastChild.y}-${tile.northeastChild.level}`);
        }
        if (tile.northwestChild) {
          removeImageryKeys.push(`${model.id}-tile-${tile.northwestChild.x}-${tile.northwestChild.y}-${tile.northwestChild.level}`);
        }
        if (tile.southeastChild) {
          removeImageryKeys.push(`${model.id}-tile-${tile.southeastChild.x}-${tile.southeastChild.y}-${tile.southeastChild.level}`);
        }
        if (tile.southwestChild) {
          removeImageryKeys.push(`${model.id}-tile-${tile.southwestChild.x}-${tile.southwestChild.y}-${tile.southwestChild.level}`);
        }
        setImageryViews(origin => addImageryView(origin, imageryKey))
        setImageryViews(origin => removeImageryViews(origin, removeImageryKeys))
        if (viewer?.scene?.requestRenderMode) {
          viewer.scene.requestRender();
        }
      }
    }
  }

  return <>{imageryViews.map(imageryView => imageryView)}</>
}

export default inject('projectStore', "userGroupStore")(observer(ImageryView))
