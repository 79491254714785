import React, { Fragment } from 'react'
import { ElemWrapper, ElemButton, ElemButtonGroup, ElemElement, ElemTableSkeleton } from './style'
import { Popconfirm, Icon, Checkbox, Radio, Input } from 'antd'
import { inject, observer } from 'mobx-react'
import deleteIcon from '@/assets/icons/trash-icn@2x.png'
import editIcon from '@/assets/icons/pencil-icn@2x.png'
import { basicFields } from './ControlProperties'
import { Trans, useTranslation } from 'react-i18next';
import { getFeedbackIcon } from '@/lib/projectLib';
import Utils from '@/utils'

const Elements = ({ type, elemIndex, feedbackStore, commonStore, setCurrentElement }) => {
    const { t } = useTranslation();
    const deleteBtnStyle = { background: 'linear-gradient(328.13deg, #FF0844 15.19%, #F44336 87.08%)' }
    const editBtnStyle = { background: 'linear-gradient(149.04deg, #2ECF94 8.75%, #3DBEA3 86.67%)' }

    const deleteHandler = () => {
        feedbackStore.deleteElementFromPage(elemIndex)
        if (type.isDefault) {
            setCurrentElement(type)
        }
    }

    const editHandler = () => {
        feedbackStore.showElementProperties(elemIndex)
    }
    const resolveIcon = (type) => {
        const icon = basicFields.filter(basicField => basicField.className === type).map(x => x.icon);
        return icon.length > 0 ? icon[0] : undefined;
    }
    const elementSkeleton = () => {
        switch (type.className) {
            case 'table':
                const renderRow = () => {
                    let rowArr = []
                    for (let i = 0; i < type.rows; i++) {
                        rowArr.push(i + 1)
                    }
                    return rowArr
                }
                const renderColumn = () => {
                    let columnArr = []
                    for (let i = 0; i < type.columns; i++) {
                        columnArr.push(i + 1)
                    }
                    return columnArr
                }
                if (type.tableData.length < type.rows) {
                    for (let i = type.tableData.length; i < type.rows; i++) {
                        type.tableData.push([])
                    }
                }
                for (let i = 0; i < type.tableData.length; i++) {
                    if (type.tableData[i].length < type.columns) {
                        type.tableData[i].push('')
                    }
                }
                const handleUpdateTableData = (rowIndex, columnIndex, data) => {
                    type.tableData[rowIndex][columnIndex] = data
                }
                return (
                    <Fragment>
                        <label>{type.title}</label>
                        {
                            type.description === '' ? null :
                                <p>{type.description}</p>
                        }
                        <ElemTableSkeleton>
                            <table>
                                <tbody>
                                    {
                                        renderRow().map((row, rowIndex) => {
                                            return (
                                                <tr key={rowIndex}>
                                                    {
                                                        renderColumn().map((column, columnIndex) => {
                                                            return (
                                                                <td key={columnIndex}>
                                                                    {
                                                                        type.tableData.length >= type.rows
                                                                            ? <Input
                                                                                onChange={e => handleUpdateTableData(rowIndex, columnIndex, e.target.value)}
                                                                                value={type.tableData[rowIndex][columnIndex]} />
                                                                            : null
                                                                    }
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </ElemTableSkeleton>
                    </Fragment>
                )
            case 'radioButton':
                return (
                    <Fragment>
                        <label>
                            {
                                type.isRequired
                                    ? <span style={{
                                        color: '#f5222d',
                                        fontSize: 12,
                                        marginRight: 4,
                                        fontFamily: 'SimSun, sans-serif',
                                    }}>*</span>
                                    : null
                            }
                            {type.title}
                        </label>
                        {
                            type.description === '' ? null :
                                <p>{type.description}</p>
                        }
                        <Radio.Group
                            style={
                                type.direction === 'horizontal'
                                    ? null : {
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }
                            }>
                            {type.choices.map(choice =>
                                <Radio key={choice} value={choice}>{choice}</Radio>,
                            )}
                        </Radio.Group>
                    </Fragment>
                )
            case 'radioGroup':
                return (
                    <Fragment>
                        <label>
                            {
                                type.isRequired
                                    ? <span style={{
                                        color: '#f5222d',
                                        fontSize: 12,
                                        marginRight: 4,
                                        fontFamily: 'SimSun, sans-serif',
                                    }}>*</span>
                                    : null
                            }
                            {type.title}
                        </label>
                        {
                            type.description === '' ? null :
                                <p>{type.description}</p>
                        }
                        <Radio.Group
                            style={
                                type.direction === 'horizontal'
                                    ? null : {
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }
                            }>
                            {type.choices.map(choice =>
                                <Radio key={choice} value={choice}>{choice}</Radio>,
                            )}
                        </Radio.Group>
                    </Fragment>
                )
            case 'checkboxGroup':
                return (
                    <Fragment>
                        <label>
                            {
                                type.isRequired
                                    ? <span style={{
                                        color: '#f5222d',
                                        fontSize: 12,
                                        marginRight: 4,
                                        fontFamily: 'SimSun, sans-serif',
                                    }}>*</span>
                                    : null
                            }
                            {type.title}
                        </label>
                        {
                            type.description === '' ? null :
                                <p>{type.description}</p>
                        }
                        <Checkbox.Group
                            style={
                                type.direction === 'horizontal'
                                    ? null : {
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }
                            }
                            options={type.choices.slice()} />
                    </Fragment>
                )
            case 'smileyButton':
                return (
                    <Fragment>
                        <label>
                            {
                                type.isRequired
                                    ? <span style={{
                                        color: '#f5222d',
                                        fontSize: 12,
                                        marginRight: 4,
                                        fontFamily: 'SimSun, sans-serif',
                                    }}>*</span>
                                    : null
                            }
                            {type.title}
                        </label>
                        {
                            type.description === '' ? null :
                                <p>{type.description}</p>
                        }
                        <Radio.Group
                            style={
                                type.direction === 'horizontal'
                                    ? null : {
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }
                            }
                            optionType="button"
                            buttonStyle="solid"
                            className="smiley-button"
                        >
                            {getFeedbackIcon(type?.choices, basicFields.filter(item => item.className === 'smileyButton')[0].choices).map(choice =>
                                // <Radio.Button key={choice} value={choice}>{choice}</Radio.Button>,
                                <img style={{ padding: '0 10px' }} key={choice} src={choice} />
                            )}
                        </Radio.Group>
                    </Fragment>
                )
            case 'textReadonly':
                return (
                    <Fragment>
                        <label>
                            {
                                type.isRequired
                                    ? <span style={{
                                        color: '#f5222d',
                                        fontSize: 12,
                                        marginRight: 4,
                                        fontFamily: 'SimSun, sans-serif',
                                    }}>*</span>
                                    : null
                            }
                            {type.title}
                        </label>
                        {
                            type.description === '' ? null :
                                <p>{type.description}</p>
                        }
                    </Fragment>
                )
            case 'urlReadonly':
                return (
                    <Fragment>
                        <label>
                            {
                                type.isRequired
                                    ? <span style={{
                                        color: '#f5222d',
                                        fontSize: 12,
                                        marginRight: 4,
                                        fontFamily: 'SimSun, sans-serif',
                                    }}>*</span>
                                    : null
                            }
                            {type.title}
                        </label>
                        {
                            type.description === '' ? null :
                                <p>{type.description}</p>
                        }
                        {
                            <a href={type.value || '#'} rel="noopener noreferrer" target="_blank">{type.text}</a>
                        }
                    </Fragment>
                )
            case 'image':
                return (
                    <Fragment>
                        <label>
                            {
                                type.isRequired
                                    ? <span style={{
                                        color: '#f5222d',
                                        fontSize: 12,
                                        marginRight: 4,
                                        fontFamily: 'SimSun, sans-serif',
                                    }}>*</span>
                                    : null
                            }
                            {type.title}
                        </label>
                        {
                            type.description === '' ? null :
                                <p>{type.description}</p>
                        }
                        <img
                            src={type.value}
                            style={{
                                minHeight: '150px',
                                objectFit: "cover"
                            }}
                        ></img>
                    </Fragment>
                )
                case 'Iframe':
                    return (
                        <Fragment>
                            <label>
                                {
                                    type.isRequired
                                        ? <span style={{
                                            color: '#f5222d',
                                            fontSize: 12,
                                            marginRight: 4,
                                            fontFamily: 'SimSun, sans-serif',
                                        }}>*</span>
                                        : null
                                }
                                {type.title}
                            </label>
                            {
                                type.description === '' ? null :
                                    <p>{type.description}</p>
                            }
                            <iframe
                                width="100%"
                                height="250"
                                src={Utils.convertLink(type?.value)}
                                title={`iframe`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                sandbox="allow-scripts allow-same-origin allow-presentation"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            />
                        </Fragment>
                    )
            default:
                return (
                    <Fragment>
                        <label>
                            {
                                type.isRequired
                                    ? <span style={{
                                        color: '#f5222d',
                                        fontSize: 12,
                                        marginRight: 4,
                                        fontFamily: 'SimSun, sans-serif',
                                    }}>*</span>
                                    : null
                            }
                            {type.title}
                        </label>
                        {
                            type.description === '' ? null :
                                <p>{type.description}</p>
                        }
                        <ElemElement className={type.className}>
                            {type.placeHolder}
                            <div className="img">
                                {
                                    resolveIcon(type.className)
                                        ? <img src={resolveIcon(type.className).component} alt="" width={resolveIcon(type.className).width} height={resolveIcon(type.className).height} />
                                        : null
                                }
                            </div>
                        </ElemElement>
                    </Fragment>
                )
        }
    }

    return (
        <ElemWrapper theme={commonStore.appTheme}>
            {elementSkeleton()}
            <ElemButtonGroup>
                <Popconfirm
                    placement={'topRight'}
                    okText={t('commons.delete')} cancelText={t('commons.cancel')} okType={'danger'} onConfirm={deleteHandler}
                    title={t('are-you-sure-you-want-to-delete-this-input')}
                >
                    <ElemButton
                        style={deleteBtnStyle}>
                        <img src={deleteIcon} alt={t('commons.delete')} />
                    </ElemButton>
                </Popconfirm>
                <ElemButton
                    onClick={editHandler}
                    style={editBtnStyle}>
                    <img src={editIcon} alt={t('commons.edit')} />
                </ElemButton>
            </ElemButtonGroup>
        </ElemWrapper>
    )
}

export default inject('feedbackStore', 'commonStore')(observer(Elements))