import { Button, Form, Input, message, Modal, Select,Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { checkAvaiableLicenses } from '@/lib/projectLib'
import validator from '@/validator'
import { HelpButton } from '@/components/elements'
const { TextArea } = Input
const { Option } = Select;

const DuplicateProjectModal = ({ projectStore, organizationStore, history, commonStore, adminStore }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setLoadingProgress] = useState(false)
  const [listOrganizations, setListOrganizations] = useState([])
  moment.locale(`${commonStore.language}`)

  useEffect(() => {
    if (projectStore.showDuplicateProjectModal) {
      form.resetFields()
      projectStore.setLoadingProgress(true)
      organizationStore.getOrganizationUserBelongCreateNewProject().then(res => {
        setListOrganizations(res)
        form.setFieldsValue({
          name: `${projectStore.showDuplicateProjectModal?.name ? (projectStore.showDuplicateProjectModal?.name + " (Copy)") : 'Project duplicate'}`
        })
        projectStore.setLoadingProgress(false)
      })
        .catch(err => {
          projectStore.setLoadingProgress(false)
          console.log(err)
          if (err.status === 401) {
            return history.push("/auth/login")
          }
        })
    }
    return () => {
    }
  }, [projectStore.showDuplicateProjectModal])

  const onCancel = () => {
    projectStore.setShowDuplicateProjectModal(false)
    setLoadingProgress(false)
  }

  const onCreate = async () => {
    try {
      const values = await form.validateFields();
      setLoadingProgress(true);
      const res = await projectStore.duplicateProject(projectStore.showDuplicateProjectModal._id, { ...values, project: projectStore.showDuplicateProjectModal._id });
  
      if (res.error) {
        message.error(t(res.error));
      } else {
        await adminStore.rebuildProjectModelV2({ type: 'project', list: [res.id] });
        message.success(t('duplicate-project-successfully'));
        projectStore.setShowDuplicateProjectModal(false);
        window.location.replace(`/project/${res.id}`);
      }
    } catch (err) {
      message.error(t('an-error-occurred'));
    } finally {
      setLoadingProgress(false);
    }
  };

  return (
    <Modal
      visible={projectStore.showDuplicateProjectModal}
      title={
        <div className="help-btn-wrapper">
          {t('duplicate-project')}
          <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
            <div>
              <HelpButton helppage={'duplicate_project'} hovered={'black'}/>
            </div>
          </Tooltip>
        </div>
      }
      cancelText={t('commons.cancel')}
      onCancel={onCancel}
      onOk={onCreate}
      maskClosable={false}
      footer={
        <div style={{ textAlign: 'right' }}  >
          <Button disabled={isLoading} key="back" onClick={onCancel}>
            {t('commons.cancel')}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={onCreate}>
            {t('duplicate')}
          </Button>
        </div>
      }>
      <Form form={form} name="add-project" layout="vertical">
        <Form.Item label={t('title')} name="name" rules={[
          {
            required: true,
            message: t('please-input-the-title-of-project'),
          },
          { validator: validator.validateEmptyString },
        ]}>
          <Input
            placeholder={t('input-project-title')} />
        </Form.Item>
        <Form.Item label={t('organization')} name="organization" rules={[
          {
            required: true,
            message: t('please-select-an-organization-of-project'),
          },
        ]}
        >
          <Select
            placeholder={t('please-select-an-organization')}
            allowClear={true}
          >
            {
              listOrganizations ? listOrganizations.map((v, i) => {
                return (
                  <Option key={v.id} value={v.id} style={{ textTransform: 'uppercase' }} >{v.name}</Option>
                )
              }) : <Option>{t('no-organization-found')}</Option>
            }
          </Select>
        </Form.Item>
        <Form.Item label={t('description')} name="desc" rules={[
          { validator: validator.validateEmptyString },
        ]} >
          <TextArea
            rows={5}
            placeholder={t('input-description')}
            type="textarea" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default withRouter(inject('projectStore', 'organizationStore', 'commonStore', 'adminStore')(observer(DuplicateProjectModal)))
