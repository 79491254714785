import styled from 'styled-components'

export const GanttPageWrapper = styled.div`
  .ant-tag {
    margin-bottom: 5px;
    cursor: pointer;
  }
  .ant-tag:last-child {
    margin-right: 0;
  }

  .ant-typography {
    cursor: pointer;
    &.selected {
      cursor: pointer;
      color: #fff;
      background-color: #f26524;
      padding: 4px 7px;
      font-size: 12px;
      line-height: 20px;
      border-radius: 4px;
    }
  }
  .ant-table-body {
    overflow-x: auto !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 10px 10px;
  }
  .ant-select-dropdown {
    z-index: 999999;
  }
`
const baselineCss = `
  /* baseline */
  .baseline {
    position: absolute;
    border-radius: 2px;
    opacity: 0.6;
    margin-top: -7px;
    height: 12px;
    background: #ffd180;
    border: 1px solid rgb(255, 153, 0);
  }

  /* move task lines upper */
  .gantt_task_line, .gantt_line_wrapper {
    margin-top: -9px;
  }

  .gantt_side_content {
    margin-bottom: 7px;
  }

  .gantt_task_link .gantt_link_arrow {
    margin-top: -12px
  }

  .gantt_side_content.gantt_right {
    bottom: 0;
  }
`
export const GanttPageWrapperLeft = styled.div`
  .splitpanel-left {
    top: 0;
    right: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
  }

  .splitpanel-mobile-left {
    top: 0;
    right: 0;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    .btnSplitPanel {
      position: absolute;
      top: 50%;
      margin-left: -15px;
      padding: 0 !important;
    }
  }

  ${({baseline}) => (baseline ? baselineCss : '')}
`

export const GanttWrapper = styled.div`
  position: relative;

  .close-gantt-icon{
    position: absolute;
    top: 10px;
    right: 20px;
  }
`

export const GanttContainer = styled.div`
  padding: 10px 20px 10px 10px;
`