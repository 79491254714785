import { Avatar, Button, Card, Drawer, message, Popconfirm, Spin } from 'antd'
import { QuestionCircleOutlined, PlusOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { Cartesian3 } from 'cesium'
import { inject, observer } from 'mobx-react'
import React, { Fragment } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
// Slick
import 'slick-carousel/slick/slick.css'
import { ReactComponent as ViewpointDelete } from '@/assets/svgs/viewpoint-delete.svg'
import { ReactComponent as ViewpointUpdate } from '@/assets/svgs/viewpoint-update.svg'
import {
  CardViewWrapper,
  DrawerHeading,
  ViewPointImage,
  ViewPointImageContainer
} from './style'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import { Trans, useTranslation } from 'react-i18next';
import { createImageFileFromBase64 } from '@/helper'
import { ProjectRequest } from '@/requests'
import { SVGIcon } from '@/components/elements';
import { toJS } from 'mobx';
import { addViewPointCameraData, getVisileDataTree } from '@/lib/projectLib';

const Drawer3DView = props => {
  const { t } = useTranslation();
  const { topicStore, projectStore, schedulingStore, viewer, viewerRef, feedbackStore, sketchingStore, commonStore,projectSettingStore, polylineCutToolStore, usersStore} = props
  const { list3DViewTopic } = topicStore
  moment.locale(`${commonStore.language}`)

  const handleClickSaveView = (e) => {
    e.preventDefault()
    topicStore.setSave3DView(true)
    topicStore.setIsClose(true)
  }

  const drawerTitle = () => {
    return (
      <DrawerHeading>
        <span>{t('topic-3d-views')}</span>
        {
          projectStore.isExistLicenses && (
            <Button
              onClick={(e) => handleClickSaveView(e)}
              size="small"
              type={'primary'}
              loading={topicStore.isLoading}>
              <PlusOutlined />{t('save-view')}
            </Button>
          )
        }
      </DrawerHeading>
    )
  }

  const goViewpoint = capture => {
    if (!viewer.camera || !capture.cameraData) return
    let destination = new Cartesian3(
      capture.cameraData.position.x,
      capture.cameraData.position.y,
      capture.cameraData.position.z
    )
    let direction = new Cartesian3(
      capture.cameraData.direction.x,
      capture.cameraData.direction.y,
      capture.cameraData.direction.z
    )
    let up = new Cartesian3(
      capture.cameraData.up.x,
      capture.cameraData.up.y,
      capture.cameraData.up.z
    )
    viewer.camera.flyTo({
      duration: 1.5,
      destination,
      orientation: {
        direction,
        up,
      },
    })
    topicStore.setCurrentTopic3DView(capture)
    projectStore.setTypeViewPoint('topic3DView')
    projectStore.setTargetViewPoint()

    if (capture?.cameraData?.timeSlider) {
      let time = moment(capture.cameraData.timeSlider)
      schedulingStore.setCurrentViewingTime(time)
    }
  }

  const sliderSetting = {
    dots: false,
    arrows: true,
    slidesToShow: 12,
    slidesToScroll: 12,
    infinite: false,
    centerPadding: 10,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const handleClose3Dview = () => {
    if (topicStore.editTopicForm) {
      topicStore.setShowTopicEditor(true, false, topicStore.getTopicForm);
      topicStore.setGetTopicForm('')
    } else {
      topicStore.setIsShowTopicEditor(true);
    }
    topicStore.toggleDrawer3DViewVisible(false)
    topicStore.setIsClose(true)
    topicStore.setCurrentTopic3DView({})
  }
  function startReRender() {
    if (viewerRef.current && viewerRef.current.cesiumElement && viewerRef.current.cesiumElement._cesiumWidget) {
      if (viewerRef.current.cesiumElement.scene.requestRenderMode) { viewerRef.current.cesiumElement.scene.requestRender(); }
    }
  }
  const getImageFromCanvas = async filename => {
    if (viewerRef.current !== null) {
      startReRender()
      viewerRef.current.cesiumElement.scene.postProcessStages.ambientOcclusion.enabled = false
      viewerRef.current.cesiumElement.render()
      const canvas = viewerRef.current.cesiumElement.canvas
      const base64Image = canvas.toDataURL('image/jpeg', 0.1)
      const imageFile = await createImageFileFromBase64(base64Image, filename)
      const formData = new FormData()
      formData.append('files', imageFile)
      viewerRef.current.cesiumElement.scene.postProcessStages.ambientOcclusion.enabled = projectStore.showAmbientOcclusion
      return ProjectRequest.uploadModel(formData)
    }
    return Promise.reject()
  }

  const getCameraData = async (treeData, updateImage, nameImage) => {
    if (viewerRef.current != null) {
      let camera = viewerRef.current.cesiumElement.camera
      let camData = {}
      camData.position = {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
      }
      camData.direction = {
        x: camera.direction.x,
        y: camera.direction.y,
        z: camera.direction.z,
      }
      camData.up = {
        x: camera.up.x,
        y: camera.up.y,
        z: camera.up.z,
      }
      camData.positionCartographic = {
        lng: camera.positionCartographic.longitude,
        lat: camera.positionCartographic.latitude,
        elevation: camera.positionCartographic.height
      }
      camData.orientation = {
        heading: camera.heading,
        pitch: camera.pitch,
        roll: camera.roll
      }
      camData.timeSlider = schedulingStore.currentViewingTime || moment(new Date())
      let newCapture = {
        cameraData: camData,
        treeData
      }
      if (updateImage && nameImage) {
        let name = (nameImage.split(' ')).reduce((a, b) => a + b)
        projectStore.setLoadingProgress(true)
        const files = await getImageFromCanvas(
          `${name}.jpeg`
        )
        projectStore.setLoadingProgress(false)
        const file = files.data[0]
        newCapture.image = file
      }

      return newCapture
    }
    return false
  }
  const updateTopic3Dviews = async (capture) => {
    const startTime = Date.now();
    let data = getVisileDataTree(projectStore,topicStore,sketchingStore,feedbackStore,usersStore.currentUser?._id)
    let cam = await getCameraData(data, true, capture.name)
    const metadata = projectSettingStore.systemProjectSetting
    if (cam) {
      if(cam.cameraData){
        cam = addViewPointCameraData(projectStore,commonStore,cam,metadata,viewer);
      }
      if (topicStore.viewingTopicForm.isEdit) {
        topicStore
          .updateTopic3Dviews(capture.id, cam)
          .then(() => {
            projectStore.setCurrentViewpoint({ ...capture, ...cam })
            commonStore.loggingFunction('update topic viewpoint','success',startTime,usersStore.currentUser  ,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
          })
      } else {
        let index = list3DViewTopic.findIndex(c => c.id === capture.id)
        if (index >= 0) {
          topicStore.setLoadingProgress(true)
          list3DViewTopic[index] = { ...capture, ...cam }
          topicStore.setList3DViewTopic(list3DViewTopic)
          projectStore.setCurrentViewpoint({ ...capture, ...cam })
          message.success(t('capture-success'))
          topicStore.setLoadingProgress(false)
          commonStore.loggingFunction('update topic viewpoint','success',startTime,usersStore.currentUser  ,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
        }
      }
    }
  }
  const hanldeGoViewpoint = (capture) => {
    goViewpoint(capture)
    // active clipping plane if it is saved in   
    if (capture.treeData && capture.treeData.clippingData && typeof capture.treeData.clippingData === 'object') {
      projectStore.setViewMode('default mode')
      projectStore.setClippingViewPoint(capture.treeData.clippingData)
      projectStore.setClippingMode(capture.treeData.clippingData.clippingMode)
    } else {
      projectStore.setClippingMode(false)
      projectStore.setClippingViewPoint(false)
    }
    if(capture.treeData && capture.treeData?.hideAreaData && typeof capture.treeData?.hideAreaData === 'object'){
      polylineCutToolStore.setHideArea(capture.treeData.hideAreaData);
      projectStore.setProjectDetail({...projectStore.projectDetail, tilesetData: {...projectStore.projectDetail.tilesetData, hiddenArea: capture.treeData.hideAreaData}});
      if(polylineCutToolStore.showForm) polylineCutToolStore.setShowForm(false)
    }else{
      polylineCutToolStore.setHideArea(false);
    }
    if(capture.treeData && capture.treeData?.navigationDistanceLimit){
      if(projectStore.navigationStyles && typeof projectStore.navigationStyles === 'object')
        projectStore.setNavigationStyles({ ...projectStore.navigationStyles, distanceLimit: capture.treeData?.navigationDistanceLimit })
      else projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, distanceLimit: capture.treeData?.navigationDistanceLimit })
    }
    if(capture.treeData){
      if(projectStore.navigationStyles && typeof projectStore.navigationStyles === 'object')
        projectStore.setNavigationStyles({ ...projectStore.navigationStyles, allowUnderground: capture.treeData?.navigationAllowUnderground ?? true})
      else projectStore.setNavigationStyles({ type: 'xdTwin', control: {}, allowUnderground: capture.treeData?.navigationAllowUnderground ?? true})
    }
  }
  return (
    <Drawer
      closable={true}
      maskClosable={false}
      destroyOnClose={true}
      mask={false}
      className={'views-drawer'}
      onClose={handleClose3Dview}
      title={!projectStore.visitedMode && drawerTitle()}
      placement={'bottom'}
      keyboard={true}
      visible={topicStore.isDrawer3DViewVisible}
      zIndex={99999}
    >
      <Spin spinning={topicStore.isLoading}>
        <CardViewWrapper>
          {list3DViewTopic && list3DViewTopic.length === 0 ? (
            t('no-view')
          ) : (
            <Slider {...sliderSetting}>
              {list3DViewTopic ? list3DViewTopic
                .sort((a, b) => a.order - b.order)
                .map((capture, index) => (
                  <Card
                    key={index}
                    cover={
                      <Fragment>
                        <ViewPointImageContainer>
                          <ViewPointImage
                            alt={capture.image ? capture.image.name : ''}
                            src={
                              capture.image
                                ? capture.image.url
                                : '/viewpoint-noimage.jpg'
                            }
                            onClick={() => hanldeGoViewpoint(capture)}
                          />
                        </ViewPointImageContainer>
                        {!projectStore.visitedMode && (
                          <>
                            <Popconfirm
                              onConfirm={() =>
                                topicStore.deleteTopic3DViews(capture.id,{
                                  user : usersStore.currentUser,
                                  project : projectStore?.projectDetail?.name,
                                  organization : projectStore?.projectDetail?.organization?.name
                                })
                              }
                              okText={t('commons.delete')}
                              cancelText={t('commons.cancel')}
                              okButtonProps={{ danger: true }}
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              title={t('are-you-sure')}>
                              <Avatar
                                className='anticon'
                                key="delete"
                                shape="square"
                                icon={<SVGIcon color={'#F26524'} content={<ViewpointDelete />} width={18} height={18} />}
                              />
                            </Popconfirm>
                            <Popconfirm
                              onConfirm={() => updateTopic3Dviews(capture)}
                              okText={t('commons.update')}
                              cancelText={t('commons.cancel')}
                              okType={'primary'}
                              icon={<QuestionCircleOutlined style={{ color: '#F26524' }} />}
                              title={t('confirm-update-topic-viewpoint')}>
                              <Avatar className='save-action'
                                key="update"
                                shape="square"
                                icon={<SVGIcon color={'#F26524'} content={<ViewpointUpdate />} width={20} height={20} />}
                              />
                            </Popconfirm>
                          </>
                        )}
                      </Fragment>
                    }>
                    <div className="ant-card-meta">
                      <div className="ant-card-meta-detail">
                        <div className="ant-card-meta-title">
                          {capture.name}
                        </div>
                      </div>
                    </div>
                  </Card>
                )) : ''}
            </Slider>
          )}
        </CardViewWrapper>
      </Spin>
    </Drawer>
  )
}

export default inject(
  'topicStore',
  'projectStore',
  'schedulingStore',
  'feedbackStore',
  'sketchingStore',
  'commonStore',
  'projectSettingStore',
  'polylineCutToolStore',
  'usersStore'
)(observer(Drawer3DView))
