import React from 'react'
import { inject, observer } from 'mobx-react'
import { Spin, Alert, Switch, Button } from 'antd'
import { RecordDataControlStyle } from './CustomStyled'
import { Trans, useTranslation } from 'react-i18next';
import { HelpButton } from '@/components/elements'

const RecordDataControl = ({ projectStore }) => {
    const { t } = useTranslation();
    const onRecordData = async (checked) => {
        projectStore.setStartRecodData(checked)
        if (!checked) {
            projectStore.setLoadingProgress(true)
            await downloadFile(projectStore.recordData)
            projectStore.setLoadingProgress(false)
            projectStore.setRenderRecordDataControl(false)
            projectStore.setRecordData({
                position: [],
                sensorRawData: [],
                dataAfterSmoothingFiltering: []
            })
        }
    }

    const downloadFile = async (dataJson) => {
        const myData = dataJson
        var d = new Date();
        const fileName = 'RecordData-' + d.toDateString() + '-' + d.getHours() + 'h' + d.getMinutes()
        const json = JSON.stringify(myData);
        const blob = new Blob([json], { type: 'application/json' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const onCancel = () => {
        projectStore.setRenderRecordDataControl(false)
        projectStore.setLoadingProgress(false)
        projectStore.setRecordData({
            position: [],
            sensorRawData: [],
            dataAfterSmoothingFiltering: []
        })
    }

    const container = (
        <Alert
            // message=""
            description={t('records-all-sensor-related-data')}
            type="info"
        />
    );

    return (
        <RecordDataControlStyle>
            <div>
                <div className='help-btn-wrap record-data'>
                    <HelpButton helppage={"system_settings_record_data"}/>
                </div>
                <Spin spinning={projectStore.startRecordData}>
                    {container}
                </Spin>
                <div style={{ marginTop: 16 }}>
                    <span>{t('record-state')}</span>
                    <Switch
                        checkedChildren={t('start')}
                        unCheckedChildren={t('stop')}
                        defaultChecked={projectStore.startRecordData}
                        onChange={onRecordData}
                    />
                    <Button
                        type="default"
                        style={{ marginLeft: 5 }}
                        onClick={onCancel}>
                        {t('commons.cancel')}
                    </Button>
                </div>
            </div>
        </RecordDataControlStyle>
    )
}
export default inject('projectStore', 'commonStore')(observer(RecordDataControl))