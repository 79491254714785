import { ArrowLeftOutlined, CloseOutlined, ColumnWidthOutlined } from '@ant-design/icons';
import { Button, Drawer, message, Popconfirm, Table, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import ManagerOrganization from './ManagerOrganization';
import { OrganizationWraper, HeaderOrganizationWrapper } from './style';
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { HelpButton } from '@/components/elements'
import './style.css';
import settings from '@/siteConfig';

const ManageOrganizationPage = (props) => {
    const { t } = useTranslation();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isTabletOrMobileDevice = useMediaQuery({ query: '(max-device-width: 1224px)' })
    const [screenWidth, setScreenWidth] = useState(0)

    const {
        commonStore,
        adminStore,
        organizationStore,
        history,
        sketchingStore
    } = props;

    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (touch.clientX - (target && target.offsetLeft ? target.offsetLeft : 0));
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            sketchingStore.setWidthSketchOrganzation(10);
        }
        if (newWidth > minDrawerWidth) {
            sketchingStore.setWidthSketchOrganzation(newWidth);
        }
        if (newWidth > window.innerWidth) {
            sketchingStore.setWidthSketchOrganzation(window.innerWidth);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let offsetRight = e.clientX - document.body.offsetLeft;
        let minDrawerWidth = 360;
        if (offsetRight > minDrawerWidth) {
            sketchingStore.setWidthSketchOrganzation(offsetRight);
        }
    }, []);


    // (changeSize) change drawerWidth when drawerWidth > screenWidth
    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize) // force reload when screenWidth change

    useEffect(() => {
        if (sketchingStore.widthSketchOrganzation > screenWidth) {
            sketchingStore.setWidthSketchOrganzation(screenWidth)
        }
    }, [screenWidth])

    //#endregion

    useEffect(() => {
        if (commonStore.showOrganizationPageDrawer) {
            sketchingStore.setWidthSketchOrganzation(isTabletOrMobile ? 320 : 800)
            adminStore.setLoadingProgress(true)
            adminStore.getOrganizationUserBelong().then(() => {
                adminStore.setLoadingProgress(false)
            }).catch(error => {
                adminStore.setLoadingProgress(false)
                message.error(t('an-error-occurred'))
                if (error?.status === 401) {
                    return history.push("/auth/login")
                }
            })
        }
        return () => {
            adminStore.clearOrganizationUserBelong();
        }
    }, [commonStore.showOrganizationPageDrawer, organizationStore.forceReload])

    const onCloseOrganizationPageDrawer = () => {
        adminStore.setEditMode(false)
        adminStore.setActiveTab(1)
        commonStore.setShowOrganizationPageDrawer(false)
        organizationStore.setShowOrganization(false)
    }

    const handleClickAcceptInvite = id => {
        let submitValues = {
            inviteStatus: 'accepted',
            acceptedDate: Date.now()
        }
        adminStore.setLoadingProgress(true)
        adminStore.acceptInviteToOrganization(id, submitValues).then((res) => {
            adminStore.getOrganizationUserBelong().then(() => {
                adminStore.setLoadingProgress(false)
            }).catch(error => {
                adminStore.setLoadingProgress(false)
                message.error(t('an-error-occurred'))
            })
        }).catch(error => {
            adminStore.setLoadingProgress(false)
            message.error(t('an-error-occurred'))
        })
    }

    const handleClickOrganizationDetail = (record) => {
        adminStore.setRoleCurrentUserInOrganization({
            role: record.organizationrole.name
        })

        organizationStore.setShowOrganization(true)
        organizationStore.setParamsOrganizationDetail(record.organization.id)
    }

    const columns = [
        {
            title: t('name'),
            dataIndex: 'organization',
            key: "name",
            render: record => {
                return (
                    <div className={'tileset-name'}>{record && record.name ? record.name : t('not-found-organization')}</div>
                )
            }
        },
        {
            title: t('current-organization-role'),
            dataIndex: 'organizationrole',
            render: record => {
                return (record && record.name ? record.name : t('not-found-role'))
            }
        },
        {
            title: t('action'),
            key: 'action',
            width: 150,
            fixed: 'right',
            align: 'center',
            render: (record) => (record && record.organization && (record.organizationrole?.type === 'org_admin' || record.organizationrole?.type === 'org_manager') && record.inviteStatus === 'accepted' ?
                <Button
                    style={{ marginRight: '5px' }}
                    onClick={() => handleClickOrganizationDetail(record)}
                >
                    {t('management')}
                </Button>
                :
                (
                    record.inviteStatus === 'waiting_accept' ? (
                        <Popconfirm
                            placement="topRight"
                            title={t('are-you-sure-accept-invite-to-this-organization')}
                            onConfirm={() => handleClickAcceptInvite(record.id)}
                            okText={t('commons.yes')}
                            cancelText={t('commons.no')}
                        >
                            <a>{t('commons.accept')}</a>
                        </Popconfirm>
                    ) : ""
                )
            ),
        }


    ];


    return (
        <Drawer
            title={organizationStore.showOrganization ?
                <HeaderOrganizationWrapper>
                    <div className="help-btn-wrapper">
                        <ArrowLeftOutlined className="arrow-back" onClick={() => organizationStore.setShowOrganization(false)} />
                        {t('organization-detail')}
                        <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                            <div>
                                <HelpButton helppage={'organization_detail'} />
                            </div>
                        </Tooltip>
                    </div>
                    <CloseOutlined
                        style={{ top: "19px" }}
                        onClick={onCloseOrganizationPageDrawer}
                        className="closeOrganizationButton"
                    />
                </HeaderOrganizationWrapper>
                :
                <HeaderOrganizationWrapper>
                    <div className="help-btn-wrapper">
                        {t('organization')}
                        <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                            <div>
                                <HelpButton helppage={'organization'} />
                            </div>
                        </Tooltip>
                    </div>
                    <CloseOutlined
                        style={{ top: "19px", paddingRight: "10px" }}
                        onClick={onCloseOrganizationPageDrawer}
                        className="closeOrganizationButton"
                    />
                </HeaderOrganizationWrapper>
            }
            width={sketchingStore.widthSketchOrganzation}
            placement="left"
            closable={false}
            visible={commonStore.showOrganizationPageDrawer}
            destroyOnClose={true}
            className={
                organizationStore.showOrganization ?
                    "organization-drawer organization-detail-custom custom-wraper-splitPanel"
                    : "organization-drawer custom-wraper-splitPanel"
            }
        >
            <>
                {isTabletOrMobileDevice ? (

                    <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnOrganizationPanel btnSplitPanel">
                        <ColumnWidthOutlined />
                    </Button>

                ) : (<div onMouseDown={e => handleMouseDown(e)} className="organizationPanel" />)
                }
                {
                    organizationStore.showOrganization ? <ManagerOrganization drawerWidth={sketchingStore.widthSketchOrganzation} />
                        : <OrganizationWraper>
                            <Table
                                loading={adminStore.isLoading}
                                bordered
                                rowKey={record => record.id}
                                columns={columns}
                                dataSource={adminStore.orgUserBelongList}
                                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: settings.adminPageSizeOptions, locale: { items_per_page: "" } }}
                                locale={{ emptyText: t('no-data') }}
                            />
                        </OrganizationWraper>
                }
            </>
        </Drawer>
    );
}
export default withRouter(
    inject(
        'commonStore',
        'projectStore',
        'usersStore',
        'organizationStore',
        "adminStore",
        'sketchingStore'
    )(observer(ManageOrganizationPage))
)
