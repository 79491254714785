import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { inject, observer } from 'mobx-react'
import './skins/dhtmlxgantt.css'
import './Gantt.css'
import {
  ACTUAL_DATE_ZOOMINGS,
  COLUMNS,
  DateShow,
  applyColorChanges,
  handleAddDateTime,
  onComponentDidMount,
  preProcessingData,
  removeDuplicateText,
  simplifiedGanttDataToGetSavedQuery,
} from './ganttUtils'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { message, Modal } from 'antd'
import { uniqBy } from 'lodash'
import { applySavedQueryHighlight, onClearAlignmentCachingObject } from '../ModalLinkGanttToSavedQuery/utils'
import { useTranslation } from 'react-i18next'
import { vi } from './locales/vi'
import Utils from '@/utils';
import {
  GanttContainer
} from '../styled'

const gantt = window.gantt
const dhx = window.dhx

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame

const cancelAnimationFrame =
  window.cancelAnimationFrame || window.mozCancelAnimationFrame

let cachedSettings = {}

let dateShow = new DateShow()

let isPaused = false

let previousUndoStack = []
let previousRedoStack = []

let lastMiddlePosition = 0

const Gantt = ({
  tasks,
  onDataUpdated,
  projectGanttStore,
  viewer,
  projectStore,
  sketchingStore,
  adminStore,
  objectQueryStore,
  commonStore,
}) => {
  const { t } = useTranslation();
  let intervalID = 0
  let intervalID2
  let ganttContainer = useRef(null)
  let dataProcessor = useRef(null)
  let isResizeRow = useRef(false)
  const [ganttReady, setGanttReady] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  let isDeletedOCreatedNew = false;
  const time_steps = useMemo(() => ({
    Hours: 'hour',
    Days: 'day',
    Weeks: 'week',
    Months: 'month',
    Quarters: 'quarter',
    Years: 'year'
  }), []);

  const checkingFeatureRole = useCallback((type) => {
    if (!type) return false;
    return adminStore.checkingFeatureRole(projectStore, type);
  }, [adminStore, projectStore]);

  const quarterConfig = useCallback(() => {
    gantt.date.year_quarter_start = function (date) {
      return date;
    };

    gantt.date.add_year_quarter = function (date, inc) {
      const temp_date = new Date(date);
      gantt.date.year_start(temp_date);
      gantt.date.month_start(temp_date);
      gantt.date.day_start(temp_date);

      const q1 = new Date(temp_date);
      const q2 = gantt.date.add(q1, 3, 'month');
      const q3 = gantt.date.add(q2, 3, 'month');
      const q4 = gantt.date.add(q3, 3, 'month');
      const next_year = gantt.date.add(q4, 3, 'month');

      if (+q1 <= +date && +date < +q2) return q2;
      if (+q2 <= +date && +date < +q3) return q3;
      if (+q3 <= +date && +date < +q4) return q4;
      if (+q4 <= +date && +next_year) return next_year;
    };
  }, []);

  const initZoom = useCallback(() => {
    quarterConfig();
    const quarter_template = function (date) {
      return 'Q' + (Math.floor(date.getMonth() / 3) + 1);
    };
    gantt.ext.zoom.init({
      levels: [
        {
          name: 'Hours',
          scale_height: 60,
          min_column_width: 80,
          scales: [
            {
              unit: 'week',
              step: 1,
              format: function (date) {
                const dateToStr = gantt.date.date_to_str('%d %M')
                const endDate = gantt.date.add(date, 6, 'day')
                const weekNum = gantt.date.date_to_str('%W')(date)
                return (
                  '#' +
                  weekNum +
                  ', ' +
                  dateToStr(date) +
                  ' - ' +
                  dateToStr(endDate)
                )
              },
            },
            { unit: 'day', step: 1, format: '%d %M' },
            { unit: 'hour', step: 1, format: '%H' },
          ],
        },
        {
          name: 'Days',
          scale_height: 60,
          min_column_width: 80,
          scales: [
            { unit: 'month', step: 1, format: '%M' },
            { unit: 'week', step: 1, format: 'Week #%W' },
            { unit: 'day', step: 1, format: '%d %M' },
          ],
        },
        {
          name: 'Weeks',
          scale_height: 60,
          min_column_width: 80,
          scales: [
            { unit: 'year_quarter', step: 1, template: quarter_template },
            { unit: 'month', step: 1, format: '%M' },
            {
              unit: 'week',
              step: 1,
              format: function (date) {
                const weekNum = gantt.date.date_to_str('%W')(date)
                return '#' + weekNum
              },
            },
          ],
        },
        {
          name: 'Months',
          scale_height: 60,
          min_column_width: 80,
          scales: [
            { unit: 'year', step: 1, format: '%Y' },
            { unit: 'year_quarter', step: 1, template: quarter_template },
            { unit: 'month', step: 1, format: '%M' },
          ],
        },
        {
          name: 'Quarters',
          height: 50,
          min_column_width: 80,
          scales: [
            { unit: 'year', step: 1, format: '%Y' },
            { unit: 'year_quarter', step: 1, template: quarter_template },
          ],
        },
        {
          name: 'Years',
          scale_height: 60,
          min_column_width: 80,
          scales: [{ unit: 'year', step: 1, format: '%Y' }],
        },
      ],
      useKey: 'shiftKey',
      trigger: 'wheel',
      element: function () {
        return gantt.$root.querySelector('.gantt_task');
      },
    });

    gantt.ext.zoom.attachEvent(
      'onAfterZoom',
      function (level, config) {
        projectGanttStore.setZoomScale({ status: config.name, isUpdate: false });
        updateMarkerTime(false);
      },
      { id: 'afterZoom' }
    );
  }, [quarterConfig, projectGanttStore]);

  function toggleMode(toggle) {
    if (toggle) {
      //Saving previous scale state for future restore
      saveConfig()
      setZoomToFit()
    } else {
      //Restore previous scale state
      restoreConfig()
      gantt.render()
    }
  }

  function saveConfig() {
    const config = gantt.config
    const scaleConfigs = gantt.ext.zoom.getLevels()
    const currentScale = gantt.ext.zoom.getCurrentLevel()

    cachedSettings = {}
    cachedSettings.scales = config.scales
    cachedSettings.start_date = config.start_date
    cachedSettings.end_date = config.end_date
    cachedSettings.scroll_position = gantt.getScrollState()
    if (currentScale !== undefined) cachedSettings.name = scaleConfigs[currentScale]?.name
  }

  function restoreConfig() {
    applyConfig(cachedSettings)
  }

  function applyConfig(config, dates) {
    if (config.scales) gantt.config.scales = config.scales
    if (config.name) projectGanttStore.setZoomScale({ status: config.name, isUpdate: false })

    // restore the previous scroll position
    if (config.scroll_position) {
      setTimeout(function () {
        gantt.scrollTo(config.scroll_position.x, config.scroll_position.y)
      }, 4)
    }
  }

  function setZoomToFit() {
    const project = gantt.getSubtaskDates();
    const areaWidth = gantt.$task?.offsetWidth || 0;
    const scaleConfigs = gantt.ext.zoom.getLevels();

    if (scaleConfigs?.length) {
      let i;
      for (i = 0; i < scaleConfigs.length; i++) {
        const lastScale = scaleConfigs[i].scales[scaleConfigs[i].scales.length - 1];
        const columnCount = getUnitsBetween(
          project.start_date,
          project.end_date,
          lastScale.unit,
          scaleConfigs[i].scales[0].step
        );
        if ((columnCount + 2) * gantt.config.min_column_width <= areaWidth) {
          break;
        }
      }

      if (i === scaleConfigs.length) {
        i--;
      }

      gantt.ext.zoom.setLevel(scaleConfigs[i].name);
      applyConfig(scaleConfigs[i], project);
    }
  }

  // get number of columns in timeline
  function getUnitsBetween(from, to, unit, step) {
    var start = new Date(from),
      end = new Date(to)
    var units = 0
    while (start.valueOf() < end.valueOf()) {
      units++
      start = gantt.date.add(start, step, unit)
    }
    return units
  }

  const setZoom = useCallback((value) => {
    updateMarkerTime(false);
    if (!gantt.ext.zoom.getLevels()) {
      initZoom();
    }
    gantt.ext.zoom.setLevel(value);
    if (projectGanttStore.zoomToFit) projectGanttStore.setZoomToFit({ status: false, isUpdate: false });
  }, [initZoom, projectGanttStore]);

  useEffect(() => {
    if (projectGanttStore.zoomScale.status && projectGanttStore.zoomScale.isUpdate) {
      setZoom(projectGanttStore.zoomScale.status)
    }
  }, [projectGanttStore.zoomScale])

  useEffect(() => {
    if (ganttContainer) {
      projectGanttStore.zoomToFit.isUpdate && toggleMode(projectGanttStore.zoomToFit.status)
    }
  }, [ganttContainer, projectGanttStore.zoomToFit])

  useEffect(() => {
    let layout;
    gantt.config.show_grid = true;
    gantt.config.show_chart = true
    if (projectGanttStore.isShowGrid && !projectGanttStore.showChart && !projectGanttStore.showResource) {
      layout = onlyGridLayout;
    } else if (!projectGanttStore.isShowGrid && projectGanttStore.showChart && !projectGanttStore.showResource) {
      layout = onlyChartLayout;
    } else if (projectGanttStore.showResource) {
      layout = gridAndChartAndResourceLayout_histogram;
    } else if (!projectGanttStore.isShowGrid && !projectGanttStore.showChart && !projectGanttStore.showResource) {
      layout = gridAndChartLayout;
      gantt.config.show_grid = false;
      gantt.config.show_chart = false;
    } else if (!projectGanttStore.isShowGrid && !projectGanttStore.showChart && projectGanttStore.showResource) {
      layout = gridAndChartAndResourceLayout_histogram;
      gantt.config.show_grid = false;
      gantt.config.show_chart = false;
    } else {
      layout = gridAndChartLayout;
    }

    if (layout) {
      gantt.config.layout = layout;
    }

    gantt.init("gantt_here");
  }, [projectGanttStore.isShowGrid, projectGanttStore.showChart, projectGanttStore.showResource]);

  const initGanttDataProcessor = () => {
    /**
     * type: "task"|"link"
     * action: "create"|"update"|"delete"
     * item: data object object
     */
    //const onDataUpdated = onDataUpdated;
    dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
      return new Promise(async (resolve, reject) => {
        console.log(type, action)
        if (action === 'create') {
          projectGanttStore.setGanttDefaults({ ...projectGanttStore.ganttDefaults, settings: { ...projectGanttStore.ganttDefaults?.settings, scrollPosition: null } })
        }

        let _resTask
        if (onDataUpdated && !isResizeRow?.current) {
          _resTask = await onDataUpdated(type, action, item, id, isDeletedOCreatedNew)
          isDeletedOCreatedNew = false
        }

        if (_resTask?.status === 'error') {
          return reject()
        }

        if (_resTask?.id && action !== 'delete') {
          // save new task before activate Sketch tool, so that new task is shown in the Sketch task list (XDTWIN-3615)
          if (projectGanttStore.sketchDrawer.open && action === 'create') {
            projectGanttStore.setSketchDrawer({ ...projectGanttStore.sketchDrawer, task: { ...projectGanttStore.sketchDrawer.task, id: _resTask.id } })
          }

          // set gantt default it saved in closed drawer gantt
          const _defaultGantt = {
            ...projectGanttStore.ganttDefaults,
            userId: projectStore.projectDetail.currentUser._id,
            type: _resTask.type,
            taskType: _resTask.taskType,
            priority: _resTask.priority,
            progress: Number(_resTask?.progress) || 0,
            highlightEnable: _resTask.highlightEnable,
            colorTaskEnable: _resTask.colorTaskEnable,
            highlightColor: _resTask.highlightColor,
            highlightAlpha: _resTask.highlightAlpha,
            selectedTaskId: _resTask.id
          }
          let _projectMetadata = projectStore.projectDetail.metadata
          if (_projectMetadata && _projectMetadata.ganttDefault && _projectMetadata.ganttDefault.length > 0) {
            let index = projectStore.projectDetail.metadata.ganttDefault.findIndex(item => item.userId === projectStore.projectDetail.currentUser._id)
            if (index > -1) {
              const temp = [..._projectMetadata.ganttDefault]
              temp[index] = _defaultGantt
              _projectMetadata.ganttDefault = temp
            } else {
              let _data = _defaultGantt
              _projectMetadata.ganttDefault.push(_data)
            }
          } else {
            _projectMetadata.ganttDefault = [_defaultGantt]
          }
          projectGanttStore.setGanttDefaults(_defaultGantt)
          projectStore.updateProjectMetadata({ metadata: _projectMetadata }).then((res) => {
            projectStore.projectDetail.metadata = res.metadata
          }).catch(err => {
            console.log(err)
          });
        }
        return _resTask ? resolve({ id: _resTask.id }) : resolve()
      })
    })
  }

  /**
   * custom layout
   */
  const onlyGridLayout = {
    css: "gantt_container",
    cols: [
      {
        rows: [
          { view: "grid", scrollX: "gridScroll", scrollY: "scrollVer" },
        ]
      },
      { view: "scrollbar", id: "gridScroll", group: "horizontal" }
    ]
  };

  const onlyChartLayout = {
    css: "gantt_container",
    cols: [
      {
        rows: [
          { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
          { view: "scrollbar", id: "scrollHor", group: "horizontal" }
        ]
      },
      { view: "scrollbar", id: "scrollVer" }
    ]
  };

  const gridAndChartLayout = {
    css: "gantt_container",
    cols: [
      {
        width: 300,
        minWidth: 300,
        maxWidth: 600,
        rows: [
          { view: "grid", scrollX: "gridScroll", scrollY: "scrollVer" },

          // horizontal scrollbar for the grid
          { view: "scrollbar", id: "gridScroll", group: "horizontal" }
        ]
      },
      { resizer: true, width: 1 },
      {
        rows: [
          { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },

          // horizontal scrollbar for the timeline
          { view: "scrollbar", id: "scrollHor", group: "horizontal" }
        ]
      },
      { view: "scrollbar", id: "scrollVer" }
    ]
  };

  //#region config for resource histogram
  function shouldHighlightTask(task) {
    const store = gantt.$resourcesStore;
    const taskResource = task[gantt.config.resource_property];
    const selectedResource = store.getSelectedId();

    return taskResource === selectedResource || store.isChildOf(taskResource, selectedResource);
  }

  gantt.templates.grid_row_class = function (start, end, task) {
    const css = [];

    if (gantt.hasChild(task.id)) {
      css.push("folder_row");
    }

    if (task.$virtual) {
      css.push("group_row");
    }

    if (shouldHighlightTask(task)) {
      css.push("highlighted_resource");
    }

    return css.join(" ");
  };

  gantt.templates.task_row_class = function (start, end, task) {
    if (shouldHighlightTask(task)) {
      return "highlighted_resource";
    }
    return "";
  };

  gantt.templates.timeline_cell_class = function (task, date) {
    if (!gantt.isWorkTime({ date: date, task: task }))
      return "week_end";
    return "";
  };

  function getAllocatedValue(tasks, resource) {
    let result = 0;
    tasks.forEach(function (item) {
      const assignments = gantt.getResourceAssignments(resource.id, item.id);
      assignments.forEach(function (assignment) {
        result += Number(assignment.value);
      });
    });
    return result;
  }

  const cap = {};

  function getCapacity(date, resource) {
    /* it is sample function your could to define your own function for get Capability of resources in day */
    if (gantt.$resourcesStore.hasChild(resource.id)) {
      return -1;
    }

    let val = date.valueOf();
    if (!cap[val + resource.id]) {
      cap[val + resource.id] = [0, 1, 2, 3][Math.floor(Math.random() * 100) % 4];
    }
    return cap[val + resource.id] * 8;
  }

  gantt.templates.histogram_cell_class = function (start_date, end_date, resource, tasks) {
    if (getAllocatedValue(tasks, resource) > getCapacity(start_date, resource)) {
      return "column_overload"
    }
  };

  gantt.templates.histogram_cell_label = function (start_date, end_date, resource, tasks) {
    if (tasks.length && !gantt.$resourcesStore.hasChild(resource.id)) {
      return getAllocatedValue(tasks, resource) + "/" + getCapacity(start_date, resource);
    } else {
      if (!gantt.$resourcesStore.hasChild(resource.id)) {
        return '-';
      }
      return '';
    }
  };
  gantt.templates.histogram_cell_allocated = function (start_date, end_date, resource, tasks) {
    return getAllocatedValue(tasks, resource);
  };

  gantt.templates.histogram_cell_capacity = function (start_date, end_date, resource, tasks) {
    if (!gantt.isWorkTime(start_date)) {
      return 0;
    }
    return getCapacity(start_date, resource);
  };

  function shouldHighlightResource(resource) {
    const selectedTaskId = gantt.getState().selected_task;
    if (gantt.isTaskExists(selectedTaskId)) {
      const selectedTask = gantt.getTask(selectedTaskId);
      const selectedResource = selectedTask[gantt.config.resource_property];

      return resource.id === selectedResource || gantt.$resourcesStore.isChildOf(selectedResource, resource.id);
    }
    return false;
  }

  //#endregion

  // class name for the elements inside the resource timeline (to add the styles)
  gantt.templates.resource_cell_class = function (start_date, end_date, resource, tasks) {
    const css = [];
    css.push("resource_marker");
    if (tasks.length <= 1) {
      css.push("workday_ok");
    } else {
      css.push("workday_over");
    }
    return css.join(" ");
  };

  // values in the resource timeline cells
  gantt.templates.resource_cell_value = function (start_date, end_date, resource, tasks) {
    let result = 0;
    tasks.forEach(function (item) {
      const assignments = gantt.getResourceAssignments(resource.id, item.id);
      assignments.forEach(function (assignment) {
        const task = gantt.getTask(assignment.task_id);
        result += assignment.value * 1;
      });
    });

    if (result % 1) {
      result = Math.round(result * 10) / 10;
    }
    return "<div>" + result + "</div>";
  };

  gantt.config.resource_render_empty_cells = true;

  function getResourceAssignments(resourceId) {
    let assignments;
    const store = gantt.getDatastore(gantt.config.resource_store);
    if (store.hasChild(resourceId)) {
      assignments = [];
      store.getChildren(resourceId).forEach(function (childId) {
        assignments = assignments.concat(gantt.getResourceAssignments(childId));
      });
    } else {
      assignments = gantt.getResourceAssignments(resourceId);
    }
    return assignments;
  }

  const resourceConfig = {
    columns: [
      {
        name: "name", label: t('name'), tree: true, template: function (resource) {
          return resource.text;
        }, resize: true
      },
      {
        name: "progress", label: t('complete'), align: "center", template: function (resource) {
          const store = gantt.getDatastore(gantt.config.resource_store);
          let totalToDo = 0,
            totalDone = 0;

          const assignments = getResourceAssignments(resource.id);

          assignments.forEach(function (assignment) {
            const task = gantt.getTask(assignment.task_id);
            totalToDo += task.duration;
            totalDone += task.duration * (task.progress || 0);
          });

          let completion = 0;
          if (totalToDo) {
            completion = (totalDone / totalToDo) * 100;
          }

          return Math.floor(completion) + "%";
        }, resize: true
      },
      {
        name: "workload", label: t('workload'), align: "center", template: function (resource) {

          let totalDuration = 0;

          const assignments = getResourceAssignments(resource.id);
          assignments.forEach(function (assignment) {
            const task = gantt.getTask(assignment.task_id);
            totalDuration += Number(assignment.value) * task.duration;
          });

          return (totalDuration || 0) + "h";

        }, resize: true
      },
    ]
  };

  const resourceTemplates = {
    grid_row_class: function (start, end, resource) {
      const css = [];
      if (gantt.$resourcesStore.hasChild(resource.id)) {
        css.push("folder_row");
        css.push("group_row");
      }
      if (shouldHighlightResource(resource)) {
        css.push("highlighted_resource");
      }
      return css.join(" ");
    },
    task_row_class: function (start, end, resource) {
      const css = [];
      if (shouldHighlightResource(resource)) {
        css.push("highlighted_resource");
      }
      if (gantt.$resourcesStore.hasChild(resource.id)) {
        css.push("group_row");
      }

      return css.join(" ");
    }
  };

  const toggleGroups = (input) => {
    gantt.$groupMode = true;
    if (gantt.$groupMode) {
      var groups = gantt.$resourcesStore.getItems().map(function (item) {
        var group = gantt.copy(item);
        group.group_id = group.id;
        group.id = gantt.uid();
        return group;
      });

      gantt.groupBy({
        groups,
        relation_property: gantt.config.resource_property,
        group_id: "group_id",
        group_text: "text",
        delimiter: ", ",
        default_group_label: "Unassigned"
      });
    } else {
      gantt.groupBy(false);
    }
  };

  const gridAndChartAndResourceLayout = {
    css: "gantt_container",
    rows: [
      {
        width: 300,
        minWidth: 300,
        cols: [
          { view: "grid", group: "grids", scrollY: "scrollVer" },
          { resizer: true, width: 1 },
          { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
          { view: "scrollbar", id: "scrollVer", group: "vertical" }
        ],
        gravity: 2
      },
      { resizer: true, width: 1, next: "resources" },
      {
        height: 35,
        cols: [
          { html: "<label>Resource<select class='resource-select'></select>", css: "resource-select-panel", group: "grids" },
          { resizer: true, width: 1 },
          {
            html: "<label class='active'>Resource diagram <input checked type='radio' name='resource-mode' value='diagram'></label>" +
              "<label>Resource histogram <input type='radio' name='resource-mode' value='histogram'></label>" +
              "<label>Resource task <input type='radio' name='resource-mode' value='tasks'></label>", css: "resource-controls"
          }
        ]
      },
      {
        config: resourceConfig,
        cols: [
          { view: "resourceGrid", group: "grids", scrollY: "resourceVScroll" },
          { resizer: true, width: 1 },
          { view: "resourceTimeline", scrollX: "scrollHor", scrollY: "resourceVScroll" },
          { view: "scrollbar", id: "resourceVScroll", group: "vertical" }
        ],
        gravity: 1
      },
      { view: "scrollbar", id: "scrollHor" }
    ]
  };

  const gridAndChartAndResourceLayout_histogram = {
    css: "gantt_container",
    rows: [
      {
        width: 300,
        minWidth: 300,
        cols: [
          { view: "grid", group: "grids", scrollY: "scrollVer" },
          { resizer: true, width: 1 },
          { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
          { view: "scrollbar", id: "scrollVer", group: "vertical" }
        ],
        gravity: 2
      },
      { resizer: true, width: 1, next: "resources" },
      {
        height: 35,
        cols: [
          { html: "<label>Resource<select class='resource-select'></select>", css: "resource-select-panel", group: "grids" },
          { resizer: true, width: 1 },
          { html: "" }
          // { html:"<label class='active'>Resource diagram <input type='radio' name='resource-mode' value='diagram'></label>" +
          // "<label>Resource histogram <input type='radio' checked name='resource-mode' value='histogram'></label>" +
          // "<label>Resource task <input type='radio' name='resource-mode' value='tasks'></label>", css:"resource-controls"}
        ]
      },
      {
        gravity: 1,
        id: "resources",
        config: resourceConfig,
        templates: resourceTemplates,
        cols: [
          { view: "resourceGrid", group: "grids", scrollY: "resourceVScroll" },
          { resizer: true, width: 1 },
          { view: "resourceHistogram", capacity: 24, scrollX: "scrollHor", scrollY: "resourceVScroll" },
          { view: "scrollbar", id: "resourceVScroll", group: "vertical" }
        ]
      },
      { view: "scrollbar", id: "scrollHor" }
    ]
  };

  const gridColumnConfig = () => {
    gantt.serverList("priority", [
      { key: 'hight', label: 'High' },
      { key: 'normal', label: 'Normal' },
      { key: 'low', label: 'Low' },
    ]);
    gantt.serverList('taskType', [
      { key: 'new', label: 'New' },
      { key: 'demolish', label: 'Demolish' },
      { key: 'temporary', label: 'Temporary' },
    ])
    const textEditor = { type: 'text', map_to: 'text' }
    const dateEditor = {
      type: 'date',
      map_to: 'start_date',
    }
    const durationEditor = {
      type: 'number',
      map_to: 'duration',
      min: 0,
      max: 100,
    }
    const priority = {
      type: 'select',
      map_to: 'priority',
      options: gantt.serverList('priority'),
    }
    const taskType = {
      type: 'select',
      map_to: 'taskType',
      options: gantt.serverList('taskType'),
    }

    const progressEditor = {
      map_to: 'progress',
      type: 'select',
      options: [
        { key: 0, label: 'Not started' },
        { key: 0.1, label: '10%' },
        { key: 0.2, label: '20%' },
        { key: 0.3, label: '30%' },
        { key: 0.4, label: '40%' },
        { key: 0.5, label: '50%' },
        { key: 0.6, label: '60%' },
        { key: 0.7, label: '70%' },
        { key: 0.8, label: '80%' },
        { key: 0.9, label: '90%' },
        { key: 1, label: 'Complete' },
      ],
    }

    window.clickGridButton = function (id, action) {
      switch (action) {
        case 'edit':
          let task = gantt.getTask(id);
          if (!task?.$group_id) {
            gantt.showLightbox(id)
          }
          break
        case 'add':
          gantt.createTask(null, id)
          break
        case 'delete':
          gantt.confirm({
            title: gantt.locale.labels.confirm_deleting_title,
            text: gantt.locale.labels.confirm_deleting,
            callback: function (res) {
              if (res) {
                gantt.deleteTask(id)
              }
            },
          })
          break
      }
    }
    const colHeader =
      '<div class="gantt_grid_head_cell gantt_grid_head_add" onclick="gantt.createTask()"></div>'
    const colContent = function (task) {
      return `<i class="fa gantt_button_grid gantt_grid_add fa-plus" onclick="clickGridButton('${task.id}', 'add')"></i>`
      // <i class="fa gantt_button_grid gantt_grid_add fa-ellipsis-v" onclick="clickGridButton('${task.id}', 'add')"></i>`
      //XDTWIN-3108
      //`<i class="fa gantt_button_grid gantt_grid_edit fa-pencil" onclick="clickGridButton('${task.id}', 'edit')"></i>
      //<i class="fa gantt_button_grid gantt_grid_delete fa-times" onclick="clickGridButton('${task.id}', 'delete')"></i>`
    }

    const formatter = gantt.ext.formatters.durationFormatter({
      enter: 'day',
      store: 'hour',
      format: 'auto',
    })

    let columnsConfig = [
      {
        hide: false,
        name: 'wbs',
        align: "center",
        label: t('wbs'),
        width: 50,
        template: gantt.getWBSCode,
        resize: true,
      },
      {
        hide: false,
        name: 'text',
        label: t('task-name'),
        width: '*',
        tree: true,
        editor: textEditor,
        width: 120,
        resize: true,
      },
      {
        name: 'start_date',
        label: t('start-time'),
        align: 'center',
        editor: dateEditor,
        width: 100,
        resize: true,
        hide: true,
      },
      {
        hide: true,
        name: 'end_date',
        label: t('end'),
        align: 'center',
        width: 100,
        template: function (task) {
          return gantt.templates.date_grid(task.end_date, task)
        },
        resize: true,
      },
      {
        name: 'duration',
        label: t('duration'),
        align: 'center',
        editor: durationEditor,
        width: 80,
        resize: true,
        hide: true,
        template: function (task) {
          return formatter.format(task.duration)
        },
      },
      {
        hide: true,
        name: 'priority',
        label: t('priority'),
        width: 90,
        align: 'center',
        resize: true,
        template: function (item) {
          if (item.priority) {
            const priority = gantt.serverList('priority').find(function (option) {
              return option.key === item.priority
            })
            if (priority) {
              return priority.label
            }
          }
          return 'normal'
        },
        editor: priority,
      },
      {
        hide: true,
        name: 'taskType',
        label: t('task-type'),
        width: 90,
        align: 'center',
        resize: true,
        template: function (item) {
          if (item.taskType) {
            const type = gantt.serverList('taskType').find(function (option) {
              return option.key === item.taskType
            })
            if (type) {
              return type.label
            }
          }
          return 'New'
        },
        editor: taskType,
      },
      {
        hide: true,
        name: "owner",
        align: "center",
        width: 75,
        label: "Owner",
        template: function (task) {
          if (task.type === gantt.config.types.project) {
            return "";
          }
          const resources = gantt.getTaskResources(task.id);
          if (!resources.length) {
            return "Unassigned";
          } else if (resources.length === 1) {
            return resources[0].text;
          } else {
            return resources.map(function (resource) {
              return "<div class='owner-label' title='" + resource.text + "'>" + resource.text.substr(0, 1) + "</div>";
            }).join("");
          }
        }, resize: true
      },
      {
        hide: true,
        name: 'progress',
        label: t('gantt.progress'),
        width: 80,
        align: 'center',
        resize: true,
        template: function (item) {
          if (item.progress >= 1) return 'Complete'
          if (item.progress === 0) return 'Not started'
          return Math.round(item.progress * 100) + '%'
        },
        editor: progressEditor,
      },
      //{ name: 'add', label: '', width: 44 },
      {
        hide: false,
        name: 'buttons',
        label: colHeader,
        width: 50,
        //resize: true,
        align: 'center',
        template: colContent,
      },
    ]

    if (!checkingFeatureRole('feature_4d_gantt_edit')) {
      columnsConfig = columnsConfig.filter(col => col.name !== 'buttons')
    }
    // default columns definition
    gantt.config.columns = columnsConfig

    // custom sort wbs column
    gantt.config.columns[0].sort = function (a, b) {
      return custom_sort_wbs_function(a, b);
    };
  }

  const custom_sort_wbs_function = (a, b) => {
    let task1 = gantt.getTask(a.id);
    let task2 = gantt.getTask(b.id);
    return +gantt.getWBSCode(task1) - +gantt.getWBSCode(task2)
  }

  const handleGetNewTask = () => {
    const { data, links } = projectGanttStore.projectGanttData[0]
    return { data, links }
  }

  const lightboxConfig = () => {
    gantt.locale.labels.section_priority = t('priority')
    gantt.locale.labels.section_taskType = t('type')
    gantt.locale.labels['section_parent'] = t('gantt.parent-task')
    gantt.locale.labels['section_progress'] = t('gantt.progress')
    gantt.locale.labels['section_categories'] = t('gantt.categories')
    gantt.locale.labels.section_split = t('gantt.display')

    gantt.serverList('customTypeOptions', [
      { key: 'task', label: 'Task' },
      { key: 'project', label: 'Project' },
      { key: 'milestone', label: 'Milestone' },
    ])

    // custom add text field name
    gantt.locale.labels.section_name = t('name');
    gantt.form_blocks["text_field"] = {
      render: function (sns) {
        return "<div class='dhx_cal_ltext'>" +
          "<input name='text' type='text' style='width: 100%; margin-bottom: 5px'></div>";
      },
      set_value: function (node, value, ev) {
        node.querySelector("[name='text']").value = value || "";
      },
      get_value: function (node, ev) {
        return node.querySelector("[name='text']").value;
      },
      focus: function (node) {
        const input = node.querySelector("[name='text']");
        input.focus();
      }
    };

    const defaultLightboxConfiguration = [
      {
        name: "name",
        map_to: "text",
        type: "text_field",
        focus: true,
        height: 38,
      },
      {
        name: 'description',
        map_to: 'description',
        type: 'textarea',
        height: 100
      },
      {
        name: 'categories',
        height: 38,
        map_to: 'type',
        type: 'select',
        options: gantt.serverList('customTypeOptions'),
      },
      {
        name: 'priority',
        height: 38,
        map_to: 'priority',
        type: 'select',
        options: gantt.serverList('priority'),
      },
      {
        name: 'parent',
        type: 'parent',
        height: 38,
        allow_root: 'true',
        root_label: 'No parent',
        filter: function (id, task) {
          return true
        },
      },
      {
        name: 'taskType',
        height: 38,
        map_to: 'taskType',
        type: 'select',
        options: gantt.serverList('taskType'),
      },
      {
        name: 'highlightColor',
        height: 38,
        type: 'template',
        map_to: 'highlight_color',
      },
      {
        name: 'template',
        height: 38,
        type: 'template',
        map_to: 'my_template',
      },
      {
        name: "resources",
        type: "resources",
        map_to: "auto",
        default_value: 8
      }
    ]

    gantt.config.lightbox.sections = [
      ...defaultLightboxConfiguration,
      {
        name: 'progress',
        height: 38,
        map_to: 'progress',
        type: 'select',
        options: [
          { key: 0, label: 'Not started' },
          { key: 0.1, label: '10%' },
          { key: 0.2, label: '20%' },
          { key: 0.3, label: '30%' },
          { key: 0.4, label: '40%' },
          { key: 0.5, label: '50%' },
          { key: 0.6, label: '60%' },
          { key: 0.7, label: '70%' },
          { key: 0.8, label: '80%' },
          { key: 0.9, label: '90%' },
          { key: 1, label: 'Complete' },
        ],
      },
      {
        name: 'time',
        type: 'time',
        height: 40,
        map_to: 'auto',
        time_format: ["%d", "%m", "%Y", "%H:%i"]
      },
    ]

    gantt.config.min_task_grid_row_height = 10;
    gantt.config.open_split_tasks = true;
    gantt.config.lightbox['project_sections'] = [
      ...defaultLightboxConfiguration,
      {
        name: "split", height: 38, type: "checkbox", map_to: "render", options: [
          { key: "split", label: t('gantt.split-task') }
        ]
      },
      {
        name: 'progress',
        height: 38,
        map_to: 'progress',
        type: 'select',
        options: [
          { key: 0, label: 'Not started' },
          { key: 0.1, label: '10%' },
          { key: 0.2, label: '20%' },
          { key: 0.3, label: '30%' },
          { key: 0.4, label: '40%' },
          { key: 0.5, label: '50%' },
          { key: 0.6, label: '60%' },
          { key: 0.7, label: '70%' },
          { key: 0.8, label: '80%' },
          { key: 0.9, label: '90%' },
          { key: 1, label: 'Complete' },
        ],
      },
      {
        name: 'time',
        type: 'time',
        height: 40,
        map_to: 'auto',
        time_format: ['%d', '%m', '%Y', '%H:%i'],
        readonly: true,
      },
    ]
    gantt.config.lightbox['milestone_sections'] = [
      ...defaultLightboxConfiguration,
      {
        name: 'time',
        type: 'time',
        height: 40,
        map_to: 'auto',
        time_format: ['%d', '%m', '%Y', '%H:%i'],
        single_date: true,
        align: 'left',
      },
    ]

    gantt.locale.labels.section_template = t('gantt.gantt-definition')
    gantt.locale.labels.section_highlightColor = t('gantt.color')
    gantt.locale.labels.section_resources = "Owners";

    const handleOpenModalLinkedToDatatree = (task) => {
      if (!task) return;

      // Set selectedNodeInDataTree if not already set and task has dataTree
      if (!projectGanttStore.selectedNodeInDataTree && task?.dataTree) {
        projectGanttStore.setSelectedNodeInDataTree(task?.dataTree);
      }

      projectGanttStore.setIsOpenModalSelectTreeData({
        open: true,
        taskId: task.id,
      });
    };

    const handleOpenModalLinkedToSavedQuery = (task) => {
      if (!task) return;

      // Set selectedSavedQuery if not already set and task has savedQuery
      if (!projectGanttStore.selectedSavedQuery && task?.savedQuery) {
        projectGanttStore.setSelectedSavedQuery(task?.savedQuery);
      }

      projectGanttStore.setIsOpenModalLinkSavedQuery(true);
    };

    const highlightColorComponent = task => {
      task.highlight_color = `<div class='color_selector_wrapper'>
      <input id="highlight_enable" type="checkbox" ${projectGanttStore.highlightEnable === true || projectGanttStore.highlightEnable === undefined ? 'checked' : " "} /><label for="highlight_enable">${t('gantt.highlight')}</label>&nbsp;
      <input id="color_task_enable" type="checkbox" ${projectGanttStore.colorTaskEnable === true ? 'checked' : " "} /><label for="color_task_enable">Gantt</label>&nbsp;
      <input id="highlight" type="color" value="${projectGanttStore.currentColorPicker || '#ff0000'}">

      <input type="range" id="highlight_alpha" min="0" max="1" step="0.1" value="${projectGanttStore.currentAlpha || '0.5'
        }"/>
      <input id="highlight_alpha_value" value="${projectGanttStore.currentAlpha || '0.5'
        }" class='alpha_value'/>
      </div>
      `
    }

    gantt.attachEvent(
      'onBeforeLightbox',
      function (id) {
        let cTask = gantt.getTask(id)
        if (cTask) {
          // fill default value for new task
          if (projectGanttStore.isCreateNewTask && projectGanttStore.ganttDefaults) {
            if (projectGanttStore.ganttDefaults?.type && cTask.parent !== 0) cTask.type = projectGanttStore.ganttDefaults.type;
            if (projectGanttStore.ganttDefaults?.taskType) cTask.taskType = projectGanttStore.ganttDefaults.taskType;
            if (projectGanttStore.ganttDefaults?.priority) cTask.priority = projectGanttStore.ganttDefaults.priority;
            cTask.progress = projectGanttStore.ganttDefaults?.progress === 0 ? "0" : Number(projectGanttStore.ganttDefaults?.progress);
            projectGanttStore.setHighlightEnable(projectGanttStore.ganttDefaults?.highlightEnable !== undefined ? projectGanttStore.ganttDefaults.highlightEnable : true)
            projectGanttStore.setColorTaskEnable(projectGanttStore.ganttDefaults?.colorTaskEnable !== undefined ? projectGanttStore.ganttDefaults.colorTaskEnable : true)
            projectGanttStore.setCurrentColorPicker(projectGanttStore.ganttDefaults?.highlightColor !== undefined ? projectGanttStore.ganttDefaults.highlightColor : '#ff0000')
            projectGanttStore.setCurrentAlpha(projectGanttStore.ganttDefaults?.highlightAlpha !== undefined ? projectGanttStore.ganttDefaults.highlightAlpha : 0.5)
          } else {
            cTask.progress = cTask?.progress === 0 ? '0' : cTask?.progress;
            cTask?.highlightColor && projectGanttStore.setCurrentColorPicker(cTask?.highlightColor)
            cTask?.highlightAlpha && projectGanttStore.setCurrentAlpha(cTask?.highlightAlpha)
            projectGanttStore.setHighlightEnable(cTask?.highlightEnable !== undefined ? cTask?.highlightEnable : true)
            projectGanttStore.setColorTaskEnable(cTask?.colorTaskEnable !== undefined ? cTask?.colorTaskEnable : false)
          }

          highlightColorComponent(cTask)
          cTask.my_template = `<div class="flex">
          <div>
            <button id='tree_select_btn' >${t('data-tree')}</button>
            <button id='saved_query_select_btn' >${t('gantt.saved-query')}</button>
          </div>
          <div class="righ">          
            <button style="border:0; padding:0" id='btn_sketch_single' title="${t('single')}">
              <div class="sketchIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M155 1985 c-51 -18 -127 -99 -143 -152 -17 -57 -17 -1608 0 -1665 15 -51 77 -121 131 -147 43 -21 53 -21 857 -21 804 0 814 0 857 21 54 26 116 96 131 147 9 29 12 255 12 838 l0 799 -24 50 c-28 61 -91 120 -143 134 -25 7 -304 11 -838 11 -660 -1 -807 -3 -840 -15z m1657 -45 c53 -15 92 -52 117 -110 21 -49 21 -50 19 -842 -3 -782 -3 -794 -24 -833 -11 -22 -40 -53 -63 -70 l-43 -30 -818 0 -818 0 -43 30 c-23 17 -52 48 -63 70 -21 39 -21 51 -24 825 -2 573 0 796 9 827 15 56 64 111 114 128 33 12 177 14 820 15 507 0 793 -4 817 -10z" />
                    <path d="M910 1691 c-90 -29 -175 -105 -213 -189 -16 -35 -21 -67 -21 -127 0 -60 5 -92 21 -127 40 -88 136 -170 226 -192 l27 -6 0 -238 c0 -199 3 -241 16 -260 19 -28 49 -28 68 0 13 19 16 61 16 260 l0 238 28 6 c89 22 185 104 225 192 30 66 30 188 0 254 -29 63 -96 134 -158 166 -39 20 -68 26 -130 28 -44 2 -91 0 -105 -5z m185 -105 c54 -25 100 -71 122 -123 17 -42 17 -134 0 -176 -35 -83 -130 -147 -217 -147 -87 0 -182 64 -217 147 -7 18 -13 57 -13 88 0 31 6 70 13 88 35 83 130 147 217 147 26 0 66 -10 95 -24z" />
                    <path d="M934 1497 c-52 -45 -23 -121 44 -115 50 4 80 45 63 86 -11 26 -46 52 -71 52 -5 0 -21 -10 -36 -23z" />
                    <path d="M697 834 c-167 -30 -313 -95 -364 -163 -30 -39 -41 -108 -23 -151 18 -43 73 -88 154 -126 98 -46 205 -71 383 -91 144 -16 162 -16 306 0 187 21 287 46 393 97 90 44 137 89 155 149 10 35 9 43 -17 90 -50 93 -186 161 -395 197 -107 18 -136 14 -145 -22 -10 -38 13 -54 96 -65 81 -12 226 -51 271 -74 47 -25 99 -76 99 -97 0 -44 -93 -103 -220 -139 -90 -26 -272 -49 -390 -49 -284 0 -584 88 -606 177 -6 23 -1 33 35 65 24 20 68 47 99 59 54 20 191 52 275 64 49 8 70 44 42 75 -21 23 -40 24 -148 4z" />
                  </g>
                </svg>
              </div>
            </button>
            <button style="border:0; padding:0" id='btn_sketch_line' title="${t('line')}">
              <div class="sketchIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M155 1985 c-51 -18 -127 -99 -143 -152 -17 -57 -17 -1608 0 -1665 15 -51 77 -121 131 -147 43 -21 53 -21 857 -21 804 0 814 0 857 21 54 26 116 96 131 147 9 29 12 255 12 838 l0 799 -24 50 c-28 61 -91 120 -143 134 -25 7 -304 11 -838 11 -660 -1 -807 -3 -840 -15z m1657 -45 c53 -15 92 -52 117 -110 21 -49 21 -50 19 -842 -3 -782 -3 -794 -24 -833 -11 -22 -40 -53 -63 -70 l-43 -30 -818 0 -818 0 -43 30 c-23 17 -52 48 -63 70 -21 39 -21 51 -24 825 -2 573 0 796 9 827 15 56 64 111 114 128 33 12 177 14 820 15 507 0 793 -4 817 -10z" />
                    <path d="M1296 1734 c-13 -13 -16 -46 -16 -202 l0 -187 -313 -313 -312 -312 -187 0 c-156 0 -189 -3 -202 -16 -24 -23 -24 -415 0 -438 23 -24 415 -24 438 0 13 13 16 46 16 202 l0 187 313 313 312 312 187 0 c156 0 189 3 202 16 24 23 24 415 0 438 -13 14 -47 16 -219 16 -172 0 -206 -2 -219 -16z m362 -216 l3 -138 -146 0 -145 0 0 133 c0 74 3 137 7 141 4 3 68 5 142 4 l136 -3 3 -137z m-1037 -1033 l0 -140 -138 0 -138 0 -3 129 c-1 72 0 136 2 143 4 11 36 13 141 11 l136 -3 0 -140z" />
                  </g>
                </svg>
              </div>
            </button>
            <button style="border:0; padding:0" id='btn_sketch_area' title="${t('area')}">
              <div class="sketchIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                      <path d="M155 1985 c-51 -18 -127 -99 -143 -152 -17 -57 -17 -1608 0 -1665 15 -51 77 -121 131 -147 43 -21 53 -21 857 -21 804 0 814 0 857 21 54 26 116 96 131 147 9 29 12 255 12 838 l0 799 -24 50 c-28 61 -91 120 -143 134 -25 7 -304 11 -838 11 -660 -1 -807 -3 -840 -15z m1657 -45 c53 -15 92 -52 117 -110 21 -49 21 -50 19 -842 -3 -782 -3 -794 -24 -833 -11 -22 -40 -53 -63 -70 l-43 -30 -818 0 -818 0 -43 30 c-23 17 -52 48 -63 70 -21 39 -21 51 -24 825 -2 573 0 796 9 827 15 56 64 111 114 128 33 12 177 14 820 15 507 0 793 -4 817 -10z" />
                      <path d="M266 1734 c-13 -13 -16 -43 -16 -173 0 -117 3 -161 13 -169 7 -6 37 -12 67 -14 l55 -3 3 -371 2 -372 -54 -4 c-86 -7 -86 -7 -86 -189 0 -130 3 -160 16 -173 13 -13 43 -16 173 -16 182 0 185 1 182 80 -1 27 3 51 8 55 9 5 658 137 720 147 20 3 24 -2 26 -30 5 -60 11 -62 189 -62 113 0 166 4 174 12 8 8 12 61 12 173 0 112 -4 165 -12 173 -7 7 -38 12 -70 12 l-58 0 0 190 0 190 58 0 c32 0 63 5 70 12 17 17 17 329 0 346 -8 8 -61 12 -174 12 -178 0 -184 -2 -189 -62 -2 -28 -6 -33 -26 -30 -62 10 -711 142 -720 147 -5 4 -9 28 -8 55 3 79 0 80 -182 80 -130 0 -160 -3 -173 -16z m264 -169 l0 -95 -95 0 -95 0 0 88 c0 49 3 92 7 95 3 4 46 7 95 7 l88 0 0 -95z m842 -260 c-3 -90 10 -109 83 -115 l60 -5 0 -185 0 -185 -60 -5 c-73 -6 -86 -25 -83 -115 l2 -60 -373 -75 c-205 -42 -374 -73 -377 -69 -2 4 -4 31 -3 59 1 28 -3 56 -9 62 -7 7 -39 14 -72 16 l-60 4 2 372 3 371 60 3 c33 2 64 7 69 12 5 5 8 32 7 60 -1 28 1 55 3 59 3 4 172 -27 377 -69 l373 -75 -2 -60z m283 70 l0 -90 -92 -3 -93 -3 0 96 0 96 93 -3 92 -3 0 -90z m0 -750 l0 -90 -92 -3 -93 -3 0 96 0 96 93 -3 92 -3 0 -90z m-1125 -190 l0 -96 -92 3 -93 3 -3 93 -3 92 96 0 95 0 0 -95z" />
                    </g>
                  </svg>
              </div>
            </button>
            <button style="border:0; padding:0" id='btn_sketch_volume' title="${t('volume')}">
              <div class="sketchIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="200.000000pt" height="200.000000pt" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M155 1985 c-51 -18 -127 -99 -143 -152 -17 -57 -17 -1608 0 -1665 15 -51 77 -121 131 -147 43 -21 53 -21 857 -21 804 0 814 0 857 21 54 26 116 96 131 147 9 29 12 255 12 838 l0 799 -24 50 c-28 61 -91 120 -143 134 -25 7 -304 11 -838 11 -660 -1 -807 -3 -840 -15z m1657 -45 c53 -15 92 -52 117 -110 21 -49 21 -50 19 -842 -3 -782 -3 -794 -24 -833 -11 -22 -40 -53 -63 -70 l-43 -30 -818 0 -818 0 -43 30 c-23 17 -52 48 -63 70 -21 39 -21 51 -24 825 -2 573 0 796 9 827 15 56 64 111 114 128 33 12 177 14 820 15 507 0 793 -4 817 -10z" />
                    <path d="M655 1632 c-165 -65 -315 -126 -333 -136 -21 -11 -41 -33 -53 -59 -17 -39 -19 -71 -19 -436 0 -381 1 -396 21 -438 27 -55 25 -54 394 -199 273 -106 303 -116 349 -111 49 4 621 221 673 254 12 8 31 34 43 57 19 40 20 60 20 434 0 428 -3 452 -59 491 -14 10 -166 73 -338 140 -230 89 -322 121 -355 121 -31 0 -126 -33 -343 -118z m645 -83 c143 -56 260 -105 260 -108 0 -13 -554 -222 -574 -217 -42 11 -546 211 -546 217 0 3 55 28 123 54 67 26 190 74 272 106 102 40 159 58 178 54 15 -2 144 -50 287 -106z m-637 -302 l287 -112 0 -387 c0 -214 -4 -388 -8 -388 -5 0 -134 49 -287 109 -181 71 -286 117 -297 131 -17 20 -18 54 -18 397 0 353 1 375 18 369 9 -4 146 -57 305 -119z m997 -251 c0 -341 -2 -376 -18 -395 -11 -14 -120 -63 -305 -135 l-287 -114 0 392 0 391 298 117 c163 64 300 117 305 117 4 1 7 -167 7 -373z" />
                  </g>
                </svg>
              </div>
            </button>
          </div>
        </div>
        `

          // fill data for case open lightbox from sketch drawer
          if (projectGanttStore.sketchDrawer.open && projectGanttStore.sketchDrawer.task) {
            cTask = {
              ...cTask,
              ...projectGanttStore.sketchDrawer.task
            }
            projectGanttStore.setHighlightEnable(projectGanttStore.sketchDrawer.task.highlightEnable !== undefined ? projectGanttStore.sketchDrawer.task.highlightEnable : true)
            projectGanttStore.setColorTaskEnable(projectGanttStore.sketchDrawer.task.colorTaskEnable !== undefined ? projectGanttStore.sketchDrawer.task.colorTaskEnable : true)
            projectGanttStore.setCurrentColorPicker(projectGanttStore.sketchDrawer.task.highlightColor !== undefined ? projectGanttStore.sketchDrawer.task.highlightColor : '#ff0000')
            projectGanttStore.setCurrentAlpha(projectGanttStore.sketchDrawer.task.highlightAlpha !== undefined ? projectGanttStore.sketchDrawer.task.highlightAlpha : 0.5)
            if (projectGanttStore.sketchDrawer.sketchIds.length > 0) {
              projectGanttStore.setSelectedNodeInDataTree([
                ...projectGanttStore.selectedNodeInDataTree,
                ...projectGanttStore.sketchDrawer.sketchIds
              ])
            }

            // after open lighbox then reset sketch drawer
            projectGanttStore.setSketchDrawer({ task: '', isNewTask: true, sketchIds: [], open: false, selectedTask: false, drawSketchType: '' })
          } else {
            const { data } = handleGetNewTask()
            const selectedTask = data?.find(task => task.id === id)
            if (selectedTask) {
              projectGanttStore.setSelectedNodeInDataTree(
                selectedTask?.dataTree ?? []
              )
              projectGanttStore.setSelectedSavedQuery(
                selectedTask?.savedQuery ?? []
              )
            }
          }
        }
        return true
      },
      { id: 'beforeLightbox' }
    )

    gantt.attachEvent(
      'onLightbox',
      function (id) {
        ///const cTask = gantt.getTask(id)
        projectGanttStore.setIsLightboxOpen(true)

        const button = document.getElementById('tree_select_btn')
        if (!button?.getEventListeners()?.click) {
          button.addEventListener('click', function () {
            const { data } = handleGetNewTask()
            if (data?.length > 0) {
              const selectedTask = data.find(task => task.id === id)
              if (!selectedTask) {
                handleOpenModalLinkedToDatatree({ dataTree: [] })
                return true
              }
              handleOpenModalLinkedToDatatree(selectedTask)
            } else {
              handleOpenModalLinkedToDatatree({ dataTree: [] })
            }
          })
        }

        const buttonLinkToSaveQuery = document.getElementById(
          'saved_query_select_btn'
        )
        if (!buttonLinkToSaveQuery?.getEventListeners()?.click) {
          buttonLinkToSaveQuery.addEventListener('click', function () {
            projectGanttStore.setIsOpenModalLinkSavedQuery(true)
            const { data } = handleGetNewTask()
            if (data?.length > 0) {
              const selectedTask = data.find(task => task.id === id)
              if (!selectedTask) {
                handleOpenModalLinkedToSavedQuery({ savedQuery: [] })
                return true
              }
              handleOpenModalLinkedToSavedQuery(selectedTask)
            } else {
              handleOpenModalLinkedToSavedQuery({ savedQuery: [] })
            }
          })
        }

        // Action click button drawer sketch single
        const buttonSketchSingle = document.getElementById('btn_sketch_single')
        if (!buttonSketchSingle?.getEventListeners()?.click) {
          buttonSketchSingle.addEventListener('click', function () {
            let cTask = gantt.getTask(id)
            cTask = {
              ...cTask,
              text: gantt.getLightboxSection('name')?.getValue(),
              description: gantt.getLightboxSection('description')?.getValue(),
              type: gantt.getLightboxSection('categories')?.getValue(),
              priority: gantt.getLightboxSection('priority')?.getValue(),
              parent: gantt.getLightboxSection('parent')?.getValue(),
              taskType: gantt.getLightboxSection('taskType')?.getValue(),
              highlightEnable: projectGanttStore.highlightEnable,
              colorTaskEnable: projectGanttStore.colorTaskEnable === undefined ? false : projectGanttStore.colorTaskEnable,
              highlightColor: projectGanttStore.currentColorPicker || '#ff0000',
              highlightAlpha: projectGanttStore.currentAlpha || '0.5',
              render: gantt.getLightboxSection('split')?.getValue(),
              progress: gantt.getLightboxSection('progress')?.getValue(),
              start_date: gantt.getLightboxSection('time')?.getValue()?.start_date,
              end_date: gantt.getLightboxSection('time')?.getValue()?.end_date,
            }
            //Save new task before activate Sketch tool, so that new task is shown in the Sketch task list (XDTWIN-3615)
            if (cTask.$new) {
              delete cTask.$new;
              gantt.addTask(cTask, cTask.parent)
            }
            projectGanttStore.setSketchDrawer({ task: cTask, isNewTask: cTask.$new ? true : false, sketchIds: [], open: true, drawSketchType: '' })
            // hide lightbox and drawer gantt
            gantt.hideLightbox();

            // show sketch point
            projectStore.setCurrentHelpfeature('add_sketch')
            sketchingStore.setCurrentSketchId(false)
            sketchingStore.setSketchMode('point')
            sketchingStore.setSketchToolVisible(false)
            sketchingStore.setDrawMode(true)
            sketchingStore.setSketchProps({
              color: { color: '#ffffff', alpha: 0.5 },
              name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'point')
            })
            sketchingStore.setSketchProps({ extrudedHeight: 1 })
            sketchingStore.setSketchProps({ width: 1 })
            sketchingStore.setSketchProps({ rotation: 0 })
            projectGanttStore.setIsShowGanttPanel(false)
          })
        }

        // Action click button drawer sketch line
        const buttonSketchLine = document.getElementById('btn_sketch_line')
        if (!buttonSketchLine?.getEventListeners()?.click) {
          buttonSketchLine.addEventListener('click', function () {
            let cTask = gantt.getTask(id)
            cTask = {
              ...cTask,
              text: gantt.getLightboxSection('name')?.getValue(),
              description: gantt.getLightboxSection('description')?.getValue(),
              type: gantt.getLightboxSection('categories')?.getValue(),
              priority: gantt.getLightboxSection('priority')?.getValue(),
              parent: gantt.getLightboxSection('parent')?.getValue(),
              taskType: gantt.getLightboxSection('taskType')?.getValue(),
              highlightEnable: projectGanttStore.highlightEnable,
              colorTaskEnable: projectGanttStore.colorTaskEnable === undefined ? false : projectGanttStore.colorTaskEnable,
              highlightColor: projectGanttStore.currentColorPicker || '#ff0000',
              highlightAlpha: projectGanttStore.currentAlpha || '0.5',
              render: gantt.getLightboxSection('split')?.getValue(),
              progress: gantt.getLightboxSection('progress')?.getValue(),
              start_date: gantt.getLightboxSection('time')?.getValue()?.start_date,
              end_date: gantt.getLightboxSection('time')?.getValue()?.end_date,
            }
            //Save new task before activate Sketch tool, so that new task is shown in the Sketch task list (XDTWIN-3615)
            if (cTask.$new) {
              delete cTask.$new;
              gantt.addTask(cTask, cTask.parent)
            }
            projectGanttStore.setSketchDrawer({ task: cTask, isNewTask: cTask.$new ? true : false, sketchIds: [], open: true, drawSketchType: '' })
            // hide lightbox and drawer gantt
            gantt.hideLightbox();

            // show sketch line
            projectStore.setCurrentHelpfeature('add_sketch')
            sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
            sketchingStore.setSketchProps({ width: 0.5 })
            sketchingStore.setSketchProps({
              color: { color: '#ffffff', alpha: 0.5 },
              name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'line')
            })
            sketchingStore.setCurrentSketchId(false)
            sketchingStore.setSketchMode('line')
            sketchingStore.setSketchToolVisible(false)
            sketchingStore.setDrawMode(true)
            projectGanttStore.setIsShowGanttPanel(false)
          })
        }

        // Action click button drawer sketch area
        const buttonSketchArea = document.getElementById('btn_sketch_area')
        if (!buttonSketchArea?.getEventListeners()?.click) {
          buttonSketchArea.addEventListener('click', function () {
            let cTask = gantt.getTask(id)
            cTask = {
              ...cTask,
              text: gantt.getLightboxSection('name')?.getValue(),
              description: gantt.getLightboxSection('description')?.getValue(),
              type: gantt.getLightboxSection('categories')?.getValue(),
              priority: gantt.getLightboxSection('priority')?.getValue(),
              parent: gantt.getLightboxSection('parent')?.getValue(),
              taskType: gantt.getLightboxSection('taskType')?.getValue(),
              highlightEnable: projectGanttStore.highlightEnable,
              colorTaskEnable: projectGanttStore.colorTaskEnable === undefined ? false : projectGanttStore.colorTaskEnable,
              highlightColor: projectGanttStore.currentColorPicker || '#ff0000',
              highlightAlpha: projectGanttStore.currentAlpha || '0.5',
              render: gantt.getLightboxSection('split')?.getValue(),
              progress: gantt.getLightboxSection('progress')?.getValue(),
              start_date: gantt.getLightboxSection('time')?.getValue()?.start_date,
              end_date: gantt.getLightboxSection('time')?.getValue()?.end_date,
            }
            //Save new task before activate Sketch tool, so that new task is shown in the Sketch task list (XDTWIN-3615)
            if (cTask.$new) {
              delete cTask.$new;
              gantt.addTask(cTask, cTask.parent)
            }
            projectGanttStore.setSketchDrawer({ task: cTask, isNewTask: cTask.$new ? true : false, sketchIds: [], open: true, drawSketchType: '' })
            // hide lightbox and drawer gantt
            gantt.hideLightbox();

            // show sketch area
            projectStore.setCurrentHelpfeature('add_sketch')
            sketchingStore.setSketchProps({ height: 0 })
            sketchingStore.setSketchProps({ extrudedHeight: 0 })
            sketchingStore.setSketchProps({ width: 0 })
            sketchingStore.setSketchProps({ readonlyHeight: true })
            sketchingStore.setSketchProps({
              color: { color: '#ffffff', alpha: 0.5 },
              name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'area')
            })
            sketchingStore.setCurrentSketchId(false)
            sketchingStore.setSketchMode('area')
            sketchingStore.setSketchToolVisible(false)
            sketchingStore.setDrawMode(true)
            projectGanttStore.setIsShowGanttPanel(false)
          })
        }

        // Action click button drawer sketch volume
        const buttonSketchVolume = document.getElementById('btn_sketch_volume')
        if (!buttonSketchVolume?.getEventListeners()?.click) {
          buttonSketchVolume.addEventListener('click', function () {
            let cTask = gantt.getTask(id)
            cTask = {
              ...cTask,
              text: gantt.getLightboxSection('name')?.getValue(),
              description: gantt.getLightboxSection('description')?.getValue(),
              type: gantt.getLightboxSection('categories')?.getValue(),
              priority: gantt.getLightboxSection('priority')?.getValue(),
              parent: gantt.getLightboxSection('parent')?.getValue(),
              taskType: gantt.getLightboxSection('taskType')?.getValue(),
              highlightEnable: projectGanttStore.highlightEnable,
              colorTaskEnable: projectGanttStore.colorTaskEnable === undefined ? false : projectGanttStore.colorTaskEnable,
              highlightColor: projectGanttStore.currentColorPicker || '#ff0000',
              highlightAlpha: projectGanttStore.currentAlpha || '0.5',
              render: gantt.getLightboxSection('split')?.getValue(),
              progress: gantt.getLightboxSection('progress')?.getValue(),
              start_date: gantt.getLightboxSection('time')?.getValue()?.start_date,
              end_date: gantt.getLightboxSection('time')?.getValue()?.end_date,
            }
            //Save new task before activate Sketch tool, so that new task is shown in the Sketch task list (XDTWIN-3615)
            if (cTask.$new) {
              delete cTask.$new;
              gantt.addTask(cTask, cTask.parent)
            }
            projectGanttStore.setSketchDrawer({ task: cTask, isNewTask: cTask.$new ? true : false, sketchIds: [], open: true, drawSketchType: '' })
            // hide lightbox and drawer gantt
            gantt.hideLightbox();

            // show sketch volume
            projectStore.setCurrentHelpfeature('add_sketch')
            sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
            sketchingStore.setSketchProps({ width: 0 })
            sketchingStore.setSketchProps({ readonlyHeight: false })
            sketchingStore.setSketchProps({
              color: { color: '#ffffff', alpha: 0.5 },
              name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'area', true)
            })
            sketchingStore.setCurrentSketchId(false)
            sketchingStore.setSketchMode('area')
            sketchingStore.setSketchToolVisible(false)
            sketchingStore.setDrawMode(true)
            projectGanttStore.setIsShowGanttPanel(false)
          })
        }

        const enableHighlightCheckbox = document.getElementById('highlight_enable')
        if (!enableHighlightCheckbox?.getEventListeners()?.change) {
          enableHighlightCheckbox.addEventListener('change', function (e) {
            projectGanttStore.setHighlightEnable(e.target.checked)
          })
        }

        const isColorTaskCheckbox = document.getElementById('color_task_enable')
        if (!isColorTaskCheckbox?.getEventListeners()?.change) {
          isColorTaskCheckbox.addEventListener('change', function (e) {
            projectGanttStore.setColorTaskEnable(e.target.checked)
          })
        }

        const colorInput = document.getElementById('highlight')
        if (!colorInput?.getEventListeners()?.change) {
          colorInput.addEventListener('change', function (e) {
            projectGanttStore.setCurrentColorPicker(e.target.value)
          })
        }

        const alphaSlider = document.getElementById('highlight_alpha')
        const alphaInput = document.getElementById('highlight_alpha_value')

        if (!alphaSlider?.getEventListeners()?.input) {
          alphaSlider.addEventListener('input', function (e) {
            alphaInput.value = e.target.value
            projectGanttStore.setCurrentAlpha(e.target.value)
          })
        }

        if (!alphaInput?.getEventListeners()?.change) {
          alphaInput.addEventListener('change', function (e) {
            alphaSlider.value = e.target.value
            projectGanttStore.setCurrentAlpha(e.target.value)
          })
        }
      },
      { id: 'openLightbox' }
    )

    gantt.attachEvent(
      'onLightboxCancel',
      function (id) {
        projectGanttStore.setSelectedNodeInDataTree([])
        projectGanttStore.setSelectedSavedQuery([])
        projectGanttStore.setCurrentColorPicker('')
        projectGanttStore.setCurrentAlpha('0.5')
        projectGanttStore.setColorTaskEnable()
        projectGanttStore.setGanttDefaults({ ...projectGanttStore.ganttDefaults, userId: projectStore.projectDetail.currentUser._id, selectedTaskId: null })
      },
      { id: 'cancelLightbox' }
    )

    gantt.attachEvent(
      'onAfterLightbox',
      function () {
        projectGanttStore.setIsCreateNewTask(false);
        isDeletedOCreatedNew = false;
        projectGanttStore.setIsLightboxOpen(false)
      },
      { id: 'afterLightbox' }
    )

    gantt.attachEvent(
      'onLightboxSave',
      function (id, task, is_new) {
        if (gantt.config.readonly) {
          message.error('You cannot edit this task')
          return false
        }
        return true
      },
      { id: 'lightboxSave' }
    )

    gantt.attachEvent(
      'onLightboxDelete',
      function (id) {
        isDeletedOCreatedNew = true;
        if (gantt.config.readonly) {
          message.error('You cannot edit this task')
          return false
        }
        return true
      },
      { id: 'lightboxDelete' }
    )
  }

  const taskConfig = () => {
    // event when click expand task rememeber Sub-task open / close status
    gantt.attachEvent("onTaskOpened", function (id) {
      const collapseTasks = projectGanttStore.ganttDefaults.settings.collapseTasks || [];
      if (collapseTasks.includes(id)) {
        const updatedTasks = collapseTasks.filter(x => x !== id);
        projectGanttStore.setGanttDefaults({
          ...projectGanttStore.ganttDefaults,
          settings: {
            ...projectGanttStore.ganttDefaults?.settings,
            collapseTasks: updatedTasks
          }
        });
      }
    });
    // event when click expand task rememeber Sub-task open / close status
    gantt.attachEvent("onTaskClosed", function (id) {
      const collapseTasks = projectGanttStore.ganttDefaults.settings.collapseTasks || [];
      if (!collapseTasks.includes(id)) {
        projectGanttStore.setGanttDefaults({
          ...projectGanttStore.ganttDefaults,
          settings: {
            ...projectGanttStore.ganttDefaults?.settings,
            collapseTasks: [...collapseTasks, id]
          }
        });
      }
    });

    gantt.attachEvent(
      'onTaskDblClick',
      function (id, e) {
        let task = gantt.getTask(id);
        if (!task?.$group_id) {
          gantt.showLightbox(id)
        }
        return true
      },
      { id: 'taskDblClick' }
    )

    // event when after click on task drag and drop update start_date end_date range
    gantt.attachEvent("onAfterTaskDrag", function (id, mode, e) {
      const modes = gantt.config.drag_mode;
      if (mode === modes.move) {
        const soonest = new Date(getStartSoonest(tasks.data))
        const latest = new Date(getEndDateLatest(tasks.data))
        gantt.config.start_date = gantt.date.add(soonest, -365, 'day')
        gantt.config.end_date = gantt.date.add(latest, 365, 'day')
        gantt.render();
      }
    });

    gantt.attachEvent("onTaskCreated", function (task) {
      projectGanttStore.setIsCreateNewTask(true);
      isDeletedOCreatedNew = true;
      return true;
    });

    gantt.attachEvent('onBeforeParse', function () {
      previousUndoStack = gantt.getUndoStack()
      previousRedoStack = gantt.getRedoStack()
    },
      { id: 'beforeParse' }
    )

    gantt.attachEvent("onParse", () => {
      const scrollPositionx = gantt.getScrollState();
      // Restore undo/redo stacks
      const currentUndoStack = gantt.getUndoStack() || []
      previousUndoStack.forEach(function (el) {
        let exists = currentUndoStack.some(element => {
          return element.commands.map(x => x.value.updatedAt).join() === el.commands.map(x => x.value.updatedAt).join();
        });
        if (!exists) {
          currentUndoStack.push(el);
        }
      });

      const currentRedoStack = gantt.getRedoStack() || []
      previousRedoStack.forEach(function (el) {
        let exists = currentRedoStack.some(element => {
          return element.commands.map(x => x.value.updatedAt).join() === el.commands.map(x => x.value.updatedAt).join();
        });
        if (!exists) {
          currentRedoStack.push(el);
        }
      })

      // Handle sketch drawer task
      if (projectGanttStore.sketchDrawer.drawSketchType) {
        const { task, sketchIds } = projectGanttStore.sketchDrawer;
        if (sketchIds.length > 0) {
          if (!task.dataTree) {
            task.dataTree = [];
          }
          task.dataTree.push(...sketchIds);
          gantt.updateTask(task.id, task);
        }
        gantt.selectTask(task.id);

        setTimeout(() => {
          const scrollPosition = projectGanttStore.ganttDefaults?.settings?.scrollPosition;
          if (scrollPosition) {
            gantt.scrollTo(scrollPosition.x, scrollPosition.y);
          } else {
            const selectedTask = gantt.getTask(task.id);
            if (selectedTask) {
              const { left, top } = gantt.getTaskPosition(selectedTask, selectedTask.planned_start, selectedTask.planned_end);
              gantt.scrollTo(left - 100, top);
            }
          }
        }, 1);

        projectGanttStore.setSketchDrawer({
          task: '',
          isNewTask: true,
          sketchIds: [],
          open: false,
          drawSketchType: '',
        });
      } else {
        // Handle selected task from defaults
        if (projectGanttStore.ganttDefaults?.selectedTaskId) {
          const { data } = handleGetNewTask();
          const selectedTask = data.find(task => task.id === projectGanttStore.ganttDefaults.selectedTaskId);
          if (selectedTask) {
            gantt.selectTask(selectedTask.id);

            setTimeout(() => {
              const selectedTaskGantt = gantt.getTask(selectedTask.id);

              if (selectedTaskGantt) {
                const scrollPosition = projectGanttStore.ganttDefaults?.settings?.scrollPosition;
                const { left, top } = gantt.getTaskPosition(
                  selectedTaskGantt,
                  selectedTaskGantt.planned_start,
                  selectedTaskGantt.planned_end
                );

                gantt.scrollTo(
                  scrollPosition?.x ?? left - 100,
                  scrollPosition?.y ?? top
                );
              }
            }, 1);
          } else {
            setTimeout(() => {
              const scrollPosition = projectGanttStore.ganttDefaults?.settings?.scrollPosition;
              gantt.scrollTo(
                scrollPosition?.x ?? scrollPositionx.x,
                scrollPosition?.y ?? scrollPositionx.y
              );
            }, 1);
          }
        } else {
          setTimeout(() => {
            const scrollPosition = projectGanttStore.ganttDefaults?.settings?.scrollPosition;
            gantt.scrollTo(
              scrollPosition?.x ?? scrollPositionx.x,
              scrollPosition?.y ?? scrollPositionx.y
            );
          }, 1);
        }
      }
    });

    gantt.attachEvent("onTaskSelected", function (id) {
      const task = gantt.getTask(id)
      if (task?.$new !== true) { // set default selected task it saved in drawer gantt closed
        projectGanttStore.setGanttDefaults({ ...projectGanttStore.ganttDefaults, userId: projectStore.projectDetail.currentUser._id, selectedTaskId: task.id })
      }
    });

    // To load task types when loading tasks
    gantt.attachEvent(
      'onTaskLoading',
      function (task) {
        if (task.$custom_data) {
          if (task.$custom_data.Summary === '1') {
            task.type = 'project'
          }
          if (task.$custom_data.Milestone === '1') {
            task.type = 'milestone'
          }
          // delete task.$custom_data;
        }

        task.planned_start = gantt.date.parseDate(task.planned_start, 'xml_date')
        task.planned_end = gantt.date.parseDate(task.planned_end, 'xml_date')

        return true
      },
      { id: 'taskLoading' }
    )

    gantt.attachEvent("onBeforeTaskDisplay", function (id, task) {
      if (!projectGanttStore.filterTask) {
        return true;
      }
      return filterLogic(task);
    });


    gantt.attachEvent("onGanttReady", function () {
      gantt.config.buttons_left = ["gantt_save_btn", "gantt_cancel_btn"];
      gantt.config.buttons_right = ["gantt_delete_btn"];

      const resourcesStore = gantt.getDatastore(gantt.config.resource_store);
      if (!resourcesStore) {
        return;
      }

      let filterValue = projectGanttStore.ganttDefaults?.settings?.resourceUser || undefined;
      function selectResource() {
        filterValue = this.value;
        projectGanttStore.setGanttDefaults({ ...projectGanttStore.ganttDefaults, settings: { ...projectGanttStore.ganttDefaults?.settings, resourceUser: filterValue } })
        gantt.render();
      }

      resourcesStore.attachEvent("onFilterItem", function (id, resource) {
        if (!filterValue) {
          return true;
        }
        if (id === filterValue) {
          if (resource.parent) {
            const parentItem = resourcesStore.getItem(resource.parent);
            if (!parentItem.$open) {
              resourcesStore.open(resource.parent);
            }
          }
          return true;
        } else if (resourcesStore.isChildOf(id, filterValue)) {
          return true;
        } else {
          return false;
        }
      });

      function updateSelect(options) {
        if (!gantt.$container) {
          filterValue = null;
          return;
        }
        const select = gantt.$container.querySelector(".resource-select");
        if (!select) {
          return;
        }
        const html = [];
        html.push("<option value=''>All</option>");
        options.forEach(function (option) {
          html.push("<option value='" + option.id + "'>" + option.text + "</option>");
        });
        select.innerHTML = html.join("");
        select.value = filterValue || "";
      }

      resourcesStore.attachEvent("onParse", function () {
        updateSelect(resourcesStore.getItems());
      });

      const select = gantt.$container.querySelector(".resource-select");
      if (select) {
        select.onchange = selectResource;
      }
    });
  }

  const pluginsConfig = () => {
    gantt.plugins({
      marker: true,
      drag_timeline: true,
      tooltip: true,
      critical_path: true,
      auto_scheduling: true,
      undo: true,
      export_api: true,
      grouping: true
    });
  }

  const baselineConfig = () => {
    gantt.addTaskLayer({
      id: 'baseline',
      renderer: {
        render: function draw_planned(task) {
          if (task.planned_start && task.planned_end) {
            const sizes = gantt.getTaskPosition(task, task.planned_start, task.planned_end);
            const el = document.createElement('div')
            el.className = 'baseline'
            el.style.left = sizes.left + 'px'
            el.style.width = sizes.width + 'px'
            el.style.top = sizes.top + gantt.config.bar_height + 13 + 'px'
            return el
          }
          return false
        },
        // define getRectangle in order to hook layer with the smart rendering
        getRectangle: function (task, view) {
          if (task.planned_start && task.planned_end) {
            return gantt.getTaskPosition(task, task.planned_start, task.planned_end);
          }
          return null
        },
      },
    })

    gantt.templates.task_class = function (start, end, task) {
      if (task.planned_end) {
        var classes = ['has-baseline'];
        if (end.getTime() > task.planned_end.getTime()) {
          classes.push('overdue');
        }
        return classes.join(' ');
      }
    };

    gantt.templates.rightside_text = function (start, end, task) {
      if (task.planned_end) {
        if (end.getTime() > task.planned_end.getTime()) {
          var overdue = Math.ceil(Math.abs((end.getTime() - task.planned_end.getTime()) / (24 * 60 * 60 * 1000)));
          var text = "<b>Overdue: " + overdue + " days</b>";
          return text;
        }
      }
    };
  }

  const dragExtendTimeline = () => {
    gantt.attachEvent("onMouseMove", function (id, e) {
      if (!gantt.getState().drag_id && e.buttons === 1) {
        const left_date = gantt.dateFromPos(gantt.getScrollState().x);
        const right_date = gantt.dateFromPos(gantt.getScrollState().x + (gantt.$task?.offsetWidth || 0) - 1);
        if (left_date && +left_date <= +gantt.config.start_date) {
          gantt.config.start_date = gantt.date.add(gantt.config.start_date, -1, time_steps[getCurrentZoomLevel()]);
          gantt.render();
        }
        if (right_date && +gantt.config.end_date < +gantt.date.add(right_date, 1, time_steps[getCurrentZoomLevel()])) {
          gantt.config.end_date = gantt.date.add(gantt.config.end_date, 1, time_steps[getCurrentZoomLevel()]);
          gantt.render();
        }
      }
    });
  }

  const getStartSoonest = (taskList = []) => {
    return taskList.reduce(function (a, b) {
      return new Date(a.start_date) < new Date(b.start_date) ? a : b
    }).start_date
  }

  const getEndDateLatest = (taskList = []) => {
    return taskList.reduce(function (a, b) {
      return new Date(a.end_date) > new Date(b.end_date) ? a : b
    }).end_date
  }

  const markerConfig = (date, label) => {
    if (!date || !label) return false

    const dateToStr = gantt.date.date_to_str(gantt.config.task_date)
    let currentMarkerList = projectGanttStore.ganttMarkerList

    let markerRemovalList = []
    if (currentMarkerList?.length) {
      for (let i = 0; i < currentMarkerList.length; i++) {
        if (currentMarkerList[i]?.label === label && currentMarkerList[i]?.id) {
          const markerId = currentMarkerList[i].id
          if (gantt.getMarker(currentMarkerList[i].id)) {
            gantt.getMarker(markerId).start_date = date
            gantt.getMarker(markerId).title = dateToStr(date)
            gantt.getMarker(markerId).css = `${currentMarkerList[i]?.label} ${(!projectGanttStore.isActive4dPlayer ||
              (projectGanttStore.isActive4dPlayer &&
                projectGanttStore.playerMode === 'dragGantt')) &&
              'hidden'
              }`
            gantt.updateMarker(markerId)
          } else {
            markerRemovalList.push(currentMarkerList[i].id)
          }
        }
      }
      if (markerRemovalList?.length) {
        markerRemovalList.forEach(
          rMarker =>
          (currentMarkerList = currentMarkerList.filter(
            cMarker => cMarker.id !== rMarker
          ))
        )
      }
    }

    if ((currentMarkerList.length < 3 || !currentMarkerList?.some(marker => marker?.label === label)) && gantt.addMarker) {
      const markerId = gantt.addMarker({
        start_date: date, //a Date object that sets the marker's date
        css: `${label} + ${(projectGanttStore.isActive4dPlayer ||
          (projectGanttStore.isActive4dPlayer &&
            projectGanttStore.playerMode === 'dragGantt')) &&
          'hidden'
          }`, //a CSS class applied to the marker
        text: label === 'now' ? '4D' : label, //the marker title
        title: dateToStr(date), // the marker's tooltip
      })
      currentMarkerList.push({ id: markerId, label })
    }

    projectGanttStore.setGanttMarkerList(currentMarkerList)
  }

  const resizeRowConfig = () => {
    gantt.attachEvent(
      'onRowResize',
      function (id, item, currentHeight) {
        isResizeRow.current = true
      },
      { id: 'rowResize' }
    )

    gantt.attachEvent(
      'onAfterRowResize',
      function (id, item, oldHeight, newHeight) {
        isResizeRow.current = false
      },
      { id: 'afterRowResize' }
    )
  }

  const weekendConfig = () => {
    const daysStyle = date => {
      const dateToStr = gantt.date.date_to_str('%D')
      if (dateToStr(date) === 'Sun' || dateToStr(date) === 'Sat') return 'week_end'
      return ''
    }
    gantt.templates.scale_cell_class = function (date) {
      return daysStyle(date)
    }
    gantt.templates.timeline_cell_class = function (item, date) {
      return daysStyle(date)
    }
  }

  const contextMenuConfig = () => {
    const cmenu = new dhx.ContextMenu(null, { css: 'dhx_widget--bg_gray' })
    cmenu.data.parse([])

    gantt.attachEvent(
      'onContextMenu',
      function (taskId, linkId, event) {
        event.preventDefault()

        const target = event.target || event.srcElement
        const column_id =
          target.getAttribute('column_id') || cmenu.data.removeAll()
        cmenu.data.parse([])

        addColumnsConfig()
        if (column_id) {
          addColumnToggle(column_id)
        }

        cmenu.showAt(event)

        return false
      },
      { id: 'contextMenu' }
    )

    cmenu.events.on('click', function (id, e) {
      const parts = (id + '').split('#')
      const is_toggle = parts[0] == 'toggle',
        column_id = parts[1] || id

      const column = gantt.getGridColumn(column_id)

      if (column) {
        const visible = !column.hide
        column.hide = visible
        gantt.render()
        projectGanttStore.setGridColumnList(
          projectGanttStore.gridColumnList.map(col =>
            col.name === column.name ? { ...col, hide: visible } : col
          )
        )
      }
      return true
    })

    function addColumnToggle(column_name) {
      const column = gantt.getGridColumn(column_name)
      const label = getColumnLabel(column)

      //add prefix to distinguish from the same item in 'show columns' menu
      const item_id = 'toggle#' + column_name
      cmenu.data.add({
        id: item_id,
        value: "Hide '" + label + "'",
      })
    }

    function addColumnsConfig() {
      let newMenuItems = {
        value: 'Show columns:',
        items: [],
      }
      const columns = gantt.config.columns

      for (let i = 0; i < columns.length; i++) {
        const checked = !columns[i].hide ?? false,
          itemLabel = getColumnLabel(columns[i]),
          itemId = columns[i].name
        newMenuItems.items.push({
          id: itemId,
          html: `<input type="checkbox" name="${itemLabel}" ${checked ? 'checked' : ''
            }><span class='checkbox-babel'>${itemLabel === 'buttons' ? t('action') : itemLabel
            }</span></input>`,
        })
      }
      cmenu.data.add([newMenuItems])
    }

    function getColumnLabel(column) {
      if (column == null) return ''

      if (column.name === 'buttons') return column.name

      const locale = gantt.locale.labels
      let text =
        column.label !== undefined
          ? column.label
          : locale['column_' + column.name]

      text = text || column.name
      return text
    }

    return cmenu
  }

  function startReRender() {
    if (viewer?._cesiumWidget) {
      if (viewer.scene.requestRenderMode) {
        viewer.scene.requestRender()
      }
    }
  }

  //#region Group
  const showGroups = (listname) => {
    if (listname) {
      gantt.groupBy({
        groups: gantt.serverList(listname),
        relation_property: listname,
        group_id: "key",
        group_text: "label"
      });
    } else {
      gantt.groupBy(false);
    }
  }

  useEffect(() => {
    if (ganttReady && projectGanttStore.groupType) {
      let type = projectGanttStore.groupType
      if (type === 'task') {
        type = ''
      }
      showGroups(type)
    }
  }, [ganttReady, projectGanttStore.groupType])
  //#endregion group

  const filterLogic = (task, match) => {
    match = match || false;
    // check children
    gantt.eachTask(function (child) {
      if (filterLogic(child)) {
        match = true;
      }
    }, task.id);

    // check task
    if (task.text.toLowerCase().indexOf(projectGanttStore.filterTask.toLowerCase()) > -1) {
      match = true;
    }
    return match;
  }

  useEffect(() => {
    if (ganttReady) {
      gantt.render()
    }
  }, [ganttReady, projectGanttStore.filterTask]);

  useEffect(() => {
    if (
      projectGanttStore.isActive4dPlayer &&
      projectGanttStore.currentViewingTime &&
      projectGanttStore.currentViewingTime.toDate &&
      viewer &&
      projectGanttStore.projectGanttData
    ) {
      let listKeyModelShow = []
      let listAllModelLinkedToGantt = []
      let listKeysavedQueryShow = []
      let listAllsavedQueryLinkedToGantt = []

      const currentTime = moment(projectGanttStore.currentViewingTime)
      projectGanttStore.projectGanttData.map(ganttData => {
        if (ganttData?.data?.length > 0) {
          ganttData.data.forEach(task => {
            [listKeyModelShow, listAllModelLinkedToGantt] = preProcessingData(
              task,
              currentTime,
              listKeyModelShow,
              listAllModelLinkedToGantt,
            )
          })
        }
      })

      const savedQueryData = simplifiedGanttDataToGetSavedQuery(
        projectGanttStore.projectGanttData[0]
      )
      if (savedQueryData?.length > 0) {
        savedQueryData.forEach(task => {
          ;[listKeysavedQueryShow, listAllsavedQueryLinkedToGantt] =
            preProcessingData(
              task,
              currentTime,
              listKeysavedQueryShow,
              listAllsavedQueryLinkedToGantt,
              'savedQuery'
            )
        })
      }

      let showedModels = listKeyModelShow.map(data => ({
        id: data.id,
        isHighlight: data.isHighlight,
        highlightColor: data?.highlightColor,
        highlightAlpha: data?.highlightAlpha,
      }))
      let highlightSketchs = []
      const _visibleSketch = sketchingStore.visibleSketches.map(item => {
        let isExist = showedModels.find(visible => item.sketchId === visible.id)
        if (isExist) {
          item.isVisible = true
          if (isExist.isHighlight) {
            highlightSketchs.push({
              id: item.sketchId,
              highlightColor: isExist.highlightColor,
              highlightAlpha: isExist.highlightAlpha,
            })
          }
        } else {
          if (listAllModelLinkedToGantt.some(m => m === item.sketchId)) {
            item.isVisible = false
          }
        }
        return item
      })
      sketchingStore.setVisibleSketches(_visibleSketch)
      projectGanttStore.setListHighlightSketch(highlightSketchs)

      const _visibleModel = projectStore.visibleTilesets.map(item => {
        let isExist = showedModels.find(visible => item?.modelId === visible.id)
        if (isExist) {
          item.isVisible = true
          if (isExist.isHighlight) {
            applyColorChanges(
              item?.modelId,
              projectStore.tileViews,
              isExist.highlightColor,
              isExist.highlightAlpha,
              viewer
            )
          } else {
            applyColorChanges(
              item?.modelId,
              projectStore.tileViews,
              '#ffffff',
              1,
              viewer
            )
          }
        } else {
          if (listAllModelLinkedToGantt.some(m => m === item?.modelId)) {
            item.isVisible = false
          }
          applyColorChanges(
            item?.modelId,
            projectStore.tileViews,
            '#ffffff',
            1,
            viewer
          )
        }
        return item
      })
      projectStore.setVisibleTilesets(_visibleModel)
      projectGanttStore.setShowedModels(showedModels)

      if (objectQueryStore?.listObjectsQuery?.length) {
        if (listKeysavedQueryShow?.length > 0 || listAllsavedQueryLinkedToGantt?.length > 0) {
          objectQueryStore.listObjectsQuery.forEach(sq => {
            const isExist = listKeysavedQueryShow.find(visible => sq.id === visible.id);
            const payload = {
              id: sq.id,
              src: sq?.src,
              name: sq?.name,
              queryParameters: sq?.queryParameters,
              isShow: true
            };

            if (isExist) {
              if (isExist.isHighlight) {
                payload.highlightColor = isExist.highlightColor
                payload.highlightAlpha = isExist.highlightAlpha
                payload.isHighlight = true
                applySavedQueryHighlight(payload, isExist.highlightColor, isExist.highlightAlpha, viewer)
              } else {
                payload.highlightColor = '#ffffff'
                payload.highlightAlpha = 1
                payload.isHighlight = false
                applySavedQueryHighlight(payload, '#ffffff', 1, viewer)
              }
            } else {
              payload.isShow = false
              payload.highlightColor = '#ffffff'
              payload.highlightAlpha = 1
              payload.isHighlight = false
              applySavedQueryHighlight(payload, '#ffffff', 1, viewer)
            }
          });
        }
      }

      startReRender()
    }
  }, [
    projectGanttStore.currentViewingTime,
    projectGanttStore.isActive4dPlayer,
    projectGanttStore.projectGanttData,
  ])

  useEffect(() => {
    if (ganttReady) {
      const dragGantt = document.getElementById('drag-gantt-line')
      const markers = projectGanttStore.ganttMarkerList

      if (projectGanttStore.isActive4dPlayer) {
        isPaused = projectGanttStore.playerMode === 'dragDate' ? true : false
        if (dragGantt) {
          dragGantt.className =
            projectGanttStore.playerMode === 'dragDate'
              ? 'drag-gantt-line hidden'
              : 'drag-gantt-line'
        }

        markers.forEach(marker => {
          const ganttMarker = gantt.getMarker(marker?.id)
          if (ganttMarker) {
            ganttMarker.css =
              projectGanttStore.playerMode === 'dragGantt'
                ? 'hidden'
                : marker?.label
            gantt.updateMarker(marker.id)
          }
        })
      }

      // Enable Drag timeline if playmode is dragGantt
      if (projectGanttStore.playerMode === 'dragGantt') {
        gantt.config.drag_timeline = {
          ignore: ".gantt_task_line, .gantt_task_link",
          useKey: false
        }
      } else {
        gantt.config.drag_timeline = null
      }
    }
  }, [
    projectGanttStore.isPlay4d,
    projectGanttStore.playerMode,
    projectGanttStore.ganttMarkerList,
    projectGanttStore.isActive4dPlayer,
    projectGanttStore.todayMarker,
    ganttReady,
  ])

  useEffect(() => {
    if (ganttReady && projectGanttStore.currentViewingTime) {
      const pickedDate = projectGanttStore.currentViewingTime.toDate()
      dateShow.setDateShow(pickedDate)
      if (projectGanttStore.playerMode === 'dragDate') {
        updateMarkerTime(false)
        if (!isDragging) {
          // const currentZoom =
          //   ACTUAL_DATE_ZOOMINGS[getCurrentZoomLevel()] || 'day'
          // const dateDiff = projectGanttStore.currentViewingTime.diff(
          //   moment(gantt.config.end_date),
          //   currentZoom + 's'
          // )

          // if (pickedDate > gantt.config.end_date || Math.abs(dateDiff) <= 2) {
          //   gantt.config.end_date = gantt.date.add(pickedDate, 1 , currentZoom)
          // }
          // if (gantt.config.start_date > pickedDate) {
          //   gantt.config.start_date = gantt.date.add(
          //     pickedDate,
          //     -1,
          //     currentZoom
          //   )
          // }
          // gantt.render()



          //usage
          showDate(pickedDate)
        }
      }
    }
  }, [
    projectGanttStore.isActive4dPlayer,
    projectGanttStore.currentViewingTime,
    isDragging,
    projectGanttStore.playerMode,
    ganttReady,
  ])


  const showDate = (date) => {
    // Calculate the horizontal offset to center the date in view
    if (gantt.$container && gantt.$grid_data) {
      const containerRect = gantt.$container.getBoundingClientRect();
      const gridDataRect = gantt.$grid_data.getBoundingClientRect();
      const additionalWidth = (containerRect.width - gridDataRect.width) / 2;

      const position = gantt.posFromDate(date) - additionalWidth;
      gantt.scrollTo(position);
    }
  }

  useEffect(() => {
    if (
      ganttReady &&
      projectGanttStore.isPlay4d &&
      projectGanttStore.currentViewingTime &&
      projectGanttStore.playerMode === 'dragGantt'
    ) {
      isPaused = true
      const pickedDate = projectGanttStore.currentViewingTime.toDate()
      const currentZoom = ACTUAL_DATE_ZOOMINGS[getCurrentZoomLevel()] || 'day'
      const right_date = gantt.dateFromPos(
        gantt.getScrollState().x + (gantt.$task?.offsetWidth || 0) - 1
      )
      const updateDate = handleAddDateTime(right_date, 1, currentZoom)
      if (right_date && +gantt.config.end_date < +updateDate) {
        gantt.config.end_date = updateDate
        gantt.render()
      }

      showDate(pickedDate)

      calculateMiddleDate(false)
    }
  }, [
    projectGanttStore.isPlay4d,
    projectGanttStore.playerMode,
    ganttReady,
    projectGanttStore.currentViewingTime,
  ])

  useEffect(() => {
    if (projectGanttStore.backOrForwardCount !== 0) {
      if (
        ganttReady &&
        projectGanttStore.currentViewingTime &&
        projectGanttStore.playerMode === 'dragGantt'
      ) {
        isPaused = true
        const updateEndDate = async () => {
          return new Promise(resolve => {
            const pickedDate = projectGanttStore.currentViewingTime.toDate()
            const currentZoom =
              ACTUAL_DATE_ZOOMINGS[getCurrentZoomLevel()] || 'day'
            const right_date = gantt.dateFromPos(
              gantt.getScrollState().x + (gantt.$task?.offsetWidth || 0) - 1
            )
            const updateDate = handleAddDateTime(right_date, 1, currentZoom)
            if (right_date && +gantt.config.end_date < +updateDate) {
              gantt.config.end_date = updateDate
              gantt.render()
            }

            showDate(pickedDate)

            calculateMiddleDate(false)

            resolve()
          })
        }

        const handleBackOrForward = async () => {
          await updateEndDate()
          isPaused = false
        }
        handleBackOrForward()
      }
    }
  }, [projectGanttStore.backOrForwardCount])

  // Function to calculate the middle date and time
  const calculateMiddleDate = useCallback((isUpdateTime = true) => {
    if (!gantt.$task?.offsetWidth) {
      return;
    }

    const dragLine = document.getElementById('drag-gantt-line');
    let xPos = dragLine?.offsetLeft - gantt.$task.offsetLeft + gantt.$task.scrollLeft;

    if (!xPos) {
      xPos = gantt.$task.offsetWidth / 2;
    }

    if (dragLine) {
      const dateFromPos = gantt.dateFromPos(xPos);
      if (isUpdateTime && projectGanttStore.playerMode === 'dragGantt') {
        handleSetTime(dateFromPos);

        const scrollPosition = gantt.getScrollState();
        projectGanttStore.setGanttDefaults({ ...projectGanttStore.ganttDefaults, settings: { ...projectGanttStore.ganttDefaults?.settings, scrollPosition: { x: scrollPosition.x, y: scrollPosition.y }, currentViewingTime: moment(dateFromPos) } })
      }
    } else {
      const line = document.createElement('div');
      line.className = `drag-gantt-line ${projectGanttStore.playerMode === 'dragDate' ? 'hidden' : ''}`; // Simplified conditional class
      line.id = 'drag-gantt-line';
      gantt.$task.insertAdjacentElement('afterend', line);

      // effect show date for turn on/off show grid
      if (projectGanttStore.ganttDefaults?.settings?.currentViewingTime) {
        const pickedDate = moment(projectGanttStore.ganttDefaults?.settings?.currentViewingTime).toDate()
        const currentZoom = ACTUAL_DATE_ZOOMINGS[getCurrentZoomLevel()] || 'day'
        const right_date = gantt.dateFromPos(gantt.getScrollState().x + (gantt.$task?.offsetWidth || 0) - 1)
        const updateDate = handleAddDateTime(right_date, 1, currentZoom)
        if (right_date && +gantt.config.end_date < +updateDate) {
          gantt.config.end_date = updateDate
          gantt.render()
        }
        showDate(pickedDate)
      }

    }
  }, []);

  const createTodayMarker = () => {
    const dateToStr = gantt.date.date_to_str(gantt.config.task_date)
    let currentMarkerList = projectGanttStore.todayMarker
    let markerRemovalList = []
    if (currentMarkerList?.length) {
      // check if this marker is already existing -> remove it
      // and then create a new marker
      for (let i = 0; i < currentMarkerList.length; i++) {
        let markerId = gantt.getMarker(currentMarkerList[i])
        if (markerId) {
          gantt.deleteMarker(currentMarkerList[i])
          markerRemovalList.push(currentMarkerList[i])
        }
      }
      if (markerRemovalList?.length) {
        markerRemovalList.forEach(
          rMarker =>
          (currentMarkerList = currentMarkerList.filter(
            cMarker => cMarker !== rMarker
          ))
        )
      }
    }
    const markerId = gantt.addMarker({
      start_date: new Date(), //a Date object that sets the marker's date
      css: `today`, //a CSS class applied to the marker
      text: t('today'), //the marker title
      title: dateToStr(new Date()), // the marker's tooltip
    })
    currentMarkerList.push(markerId)
    projectGanttStore.setTodayMarker(currentMarkerList)
  }

  const calculateMiddlePosition = useCallback(() => {
    if (gantt.$task?.offsetWidth) {
      let xPos =
        document.getElementById('drag-gantt-line')?.offsetLeft -
        gantt.$task?.offsetLeft +
        gantt.$task?.scrollLeft

      if (!xPos) xPos = gantt.$task.offsetWidth / 2

      return xPos
    }
    return undefined
  }, [])

  const handleGetColumn = () => {
    const cols = COLUMNS.map(c => gantt.getGridColumn(c))?.filter(c => c)
    if (cols?.length) {
      projectGanttStore.setGridColumnList(cols)
    }
  }

  useEffect(() => {
    if (projectGanttStore.gridColumnList?.length) {
      projectGanttStore.gridColumnList.forEach(col => {
        const column = gantt.getGridColumn(col?.name)
        if (column) {
          column.hide = col?.hide
          gantt.render()
        } else {
          if (!column) return
          const uniqColumns = uniqBy(
            [...projectGanttStore.gridColumnList, col],
            'name'
          )
          gantt.config.columns = uniqColumns
          ///gantt.init(ganttContainer)
          gantt.render()
        }
      })
    }
  }, [projectGanttStore.gridColumnList])

  useEffect(() => {
    if (ganttReady) {
      if (projectGanttStore.isShowBaseline) {
        // Adjust bar and row heights for baseline view
        gantt.config.bar_height = 20
        gantt.config.row_height = 44
        gantt.config.baselines.render_mode = "separateRow";
        baselineConfig()
        gantt.render()
      } else {
        // Reset bar and row heights for normal view
        gantt.removeTaskLayer('baseline')
        gantt.config.bar_height = 'full'
        gantt.config.row_height = 35
        gantt.config.baselines.render_mode = "";

        if (projectGanttStore.isCondensed) {
          gantt.config.row_height = 24;
          gantt.config.scale_height = 50;
        }
      }

      gantt.batchUpdate(function () {
        gantt.eachTask(function (task) {
          // function to recalculate row height
          gantt.adjustTaskHeightForBaselines(task)
        });
      });

      gantt.render()
    }
  }, [projectGanttStore.isShowBaseline, projectGanttStore.isCondensed, ganttReady])

  function upload(fileProps, callback) {
    const { file, type } = fileProps
    const importMethod = type === 'ms_project' ? 'importFromMSProject' : 'importFromPrimaveraP6'
    gantt[importMethod]({
      data: file,
      // To load task types from the file
      taskProperties: ['Summary', 'Milestone'],
      callback: function (project) {
        if (project) {
          // if (project.config.duration_unit) {
          //   gantt.config.duration_unit = project.config.duration_unit
          // }
          const { data, links } = project.data
          const { _id } = projectGanttStore.projectGanttData[0]
          if (data && _id) {
            if (importMethod === 'importFromPrimaveraP6') {
              data.sort(function (task1, task2) {
                if (+task1.id && +task2.id) {
                  return +task1.id - +task2.id
                } else {
                  return task1.id.localeCompare(task2.id)
                }
              })
            }
            projectGanttStore
              .importProject({
                projectId: projectStore.projectDetail._id,
                ganttId: _id,
                ganttData: project.data,
              })
              .then(() => {
                message.success(t('import-project-data-success'))
                gantt.clearAll()
                const soonest = new Date(getStartSoonest(data))
                gantt.config.start_date = gantt.date.add(soonest, -1, 'day')

                const latest = new Date(
                  data.reduce(function (a, b) {
                    return new Date(a.$raw.Finish) > new Date(b.$raw.Finish)
                      ? a
                      : b
                  }).$raw.Finish
                )
                gantt.config.end_date = gantt.date.add(latest, 1, 'day')
                createTodayMarker()
                updateMarkerTime()
                projectGanttStore
                  .getProject4dGantt(projectStore.projectDetail.id)
                  .catch(() => { })
              })
              .catch(() => {
                message.warn(t('failed-to-import-project-data'))
              })
          }
        } else {
          message.warn(t('failed-to-import-project-data'))
        }

        if (callback) callback(project)
      },
    })
  }

  useEffect(() => {
    if (projectGanttStore.importFile) {
      upload(projectGanttStore.importFile, () => {
        projectGanttStore.setIsUploading(false)
        projectGanttStore.setImportFile(undefined)
        projectGanttStore.setIsOpenModalExportnImportProject({
          type: 'import',
          open: false,
        })
      })
    }
  }, [projectGanttStore.importFile])

  window.addEventListener('click', function (e) {
    if (document.getElementById('gantt_here') && !document.getElementById('gantt_here').contains(e.target)) {
      // close the inline editor and save the changes:
      //gantt.ext.inlineEditors.save()
      // close the inline editor and don't save the changes
      gantt.ext.inlineEditors.hide()
    }
  })

  useEffect(() => {
    if (!gantt) return
    if (!gantt.i18n.getLocale('vi')) {
      gantt.i18n.addLocale("vi", vi);
    }
    gantt.i18n.setLocale(commonStore.language)
    gantt.render()
  }, [commonStore.language])

  const tooltipConfig = () => {
    gantt.templates.tooltip_text = function (start, end, event) {
      return `<b>${t('Task')}:</b> ` + event.text + `<br/><b>${t('start-date')}:</b> ` + gantt.templates.tooltip_date_format(start) + `<br/><b>${t('end-date')}:</b> ` + gantt.templates.tooltip_date_format(end);
    };
  }
  useEffect(() => {
    if (ganttContainer && !ganttReady) {
      gantt.i18n.addLocale("vi", vi);
      gantt.i18n.setLocale(commonStore.language);
      gantt.setSkin("material");
      gridColumnConfig()
      lightboxConfig()
      pluginsConfig()
      resizeRowConfig()
      weekendConfig()
      contextMenuConfig()
      tooltipConfig()
      taskConfig()
      // additional config
      gantt.config.date_format = '%Y-%m-%d %H:%i'
      gantt.config.duration_unit = "hour";
      gantt.config.grid_elastic_columns = true;
      gantt.config.drag_timeline = projectGanttStore.playerMode === 'dragGantt' ? {
        ignore: ".gantt_task_line, .gantt_task_link",
        useKey: false
      } : null
      if (tasks.data.length) {
        const soonest = new Date(getStartSoonest(tasks.data))
        const latest = new Date(getEndDateLatest(tasks.data))
        gantt.config.start_date = gantt.date.add(soonest, -365, 'day')
        gantt.config.end_date = gantt.date.add(latest, 365, 'day')
        gantt.config.show_tasks_outside_timescale = true;
      }

      // reordering tasks within the whole gantt
      // gantt.config.order_branch = 'marker' // branch ordering
      // gantt.config.order_branch_free = true

      gantt.config.reorder_grid_columns = true
      gantt.config.sort = true // grid sorting
      gantt.config.wide_form = false
      gantt.config.autofit = true
      gantt.config.grid_width = 500
      gantt.config.grid_resize = true
      gantt.config.fit_tasks = true
      gantt.config.resize_rows = true // resize rows
      gantt.config.work_time = true
      gantt.config.min_column_width = 80

      gantt.config.undo = true
      gantt.config.redo = true
      gantt.config.undo_steps = 10;
      gantt.config.show_empty_state = true
      gantt.config.touch = true
      //gantt.config.static_background = true
      gantt.config.smart_rendering = true
      gantt.config.auto_types = true

      gantt.config.resources = true;
      gantt.config.resource_store = "resource";
      gantt.config.resource_property = "owner";
      gantt.config.order_branch = true;
      gantt.config.open_tree_initially = true;
      // disable snap drag resize task, custom by time_step
      gantt.config.round_dnd_dates = false;

      if (!checkingFeatureRole('feature_4d_gantt_edit')) {
        gantt.config.readonly = true
        projectGanttStore.setIsReadonlyMode(true)
      } else {
        gantt.config.readonly = false
        projectGanttStore.setIsReadonlyMode(false)
      }
      //
      // create instance of gantt

      gantt.init(ganttContainer)
      setGanttReady(true)

      initGanttDataProcessor()
      gantt.parse(tasks)

      //
      handleGetColumn()
      createTodayMarker()

      // add resize event
      getBorderOfGridToResize()
      dragExtendTimeline()
      intervalID = requestAnimationFrame(getMarkerToDragAndDrop)
      intervalID2 = requestAnimationFrame(handleCalculateDragGantt)
    }
  }, [ganttReady])

  useEffect(() => {
    return () => {
      if (dataProcessor) {
        dataProcessor.destructor()
        dataProcessor = null
        gantt.clearAll()
        //gantt.detachAllEvents()
        gantt.detachEvent('rowResize')
        gantt.detachEvent('afterRowResize')
        gantt.detachEvent('mouseMove')
        gantt.detachEvent('openLightbox')
        gantt.detachEvent('cancelLightbox')
        gantt.detachEvent('afterLightbox')
        gantt.detachEvent('beforeLightbox')
        gantt.detachEvent('afterZoom')
        gantt.detachEvent('ganttRender')
        gantt.detachEvent('beforeParse')
        gantt.detachEvent('onParse')
        gantt.detachEvent('taskDblClick')
        gantt.detachEvent('lightboxSave')
        gantt.detachEvent('lightboxDelete')
        gantt.detachEvent('contextMenu')
        gantt.detachEvent('taskLoading')
        gantt.detachEvent('onBeforeTaskDisplay')
        gantt.detachEvent('onTaskSelected')
        gantt.detachEvent('onBeforeParse')
        gantt.detachEvent('onGanttReady')
      }
      previousUndoStack = []
      previousRedoStack = []
      cachedSettings = {}
      lastMiddlePosition = 0
      //projectGanttStore.setListHighlightSketch([])
      if (viewer) {
        onComponentDidMount(viewer)
        onClearAlignmentCachingObject(viewer)
      }
      cancelAnimationFrame(intervalID)
      cancelAnimationFrame(intervalID2)
      setGanttReady(false)
      setIsDragging(false)
    }
  }, [projectGanttStore.isShowGanttPanel])

  useEffect(() => {
    if (ganttContainer && ganttReady) {
      const undoStack = gantt.getUndoStack()
      if (undoStack?.length > 0) projectGanttStore.setIsActiveUndo(true)
      else projectGanttStore.setIsActiveUndo(false)

      const redoStack = gantt.getRedoStack()
      if (redoStack?.length > 0) projectGanttStore.setIsActiveRedo(true)
      else projectGanttStore.setIsActiveRedo(false)
      gantt.parse(tasks)

      const taskId = projectGanttStore.sketchDrawer.task?.id;
      if (projectGanttStore.sketchDrawer.open && taskId) {
        gantt.showLightbox(taskId);
      }
    }
  }, [ganttReady, tasks])

  useEffect(() => {
    gantt.config.highlight_critical_path = projectGanttStore.isShowCriticalPath
    gantt.render()
  }, [projectGanttStore.isShowCriticalPath])

  useEffect(() => {
    gantt.config.auto_scheduling = projectGanttStore.isAutoScheduling
    gantt.config.auto_scheduling_strict = projectGanttStore.isAutoScheduling
    gantt.config.auto_scheduling_compatibility = projectGanttStore.isAutoScheduling
    if (projectGanttStore.isAutoScheduling) {
      gantt.autoSchedule();
    }
    gantt.render()
  }, [projectGanttStore.isAutoScheduling])

  useEffect(() => {
    if (projectGanttStore.undoCount !== 0) {
      gantt.undo()
    }
  }, [projectGanttStore.undoCount])

  useEffect(() => {
    if (projectGanttStore.redoCount !== 0) {
      gantt.redo()
    }
  }, [projectGanttStore.redoCount])

  // effect click draw sketch from toolbar gantt
  useEffect(() => {
    if (projectGanttStore.sketchDrawer.drawSketchType) {
      const taskId = gantt.getSelectedId();
      if (!taskId) {
        Modal.warning({
          title: t('gantt.please-select-task-first'),
          content: '',
        });
        projectGanttStore.setSketchDrawer({ task: '', isNewTask: true, sketchIds: [], open: false, drawSketchType: '' })
      } else {
        // hide drawer gantt
        projectGanttStore.setIsShowGanttPanel(false)

        const scrollPosition = gantt.getScrollState();
        projectGanttStore.setGanttDefaults({ ...projectGanttStore.ganttDefaults, settings: { ...projectGanttStore.ganttDefaults?.settings, scrollPosition: { x: scrollPosition.x, y: scrollPosition.y } } })

        let cTask = gantt.getTask(taskId)
        projectGanttStore.setSketchDrawer({ ...projectGanttStore.sketchDrawer, isNewTask: false, task: cTask })

        // show sketch point
        projectStore.setCurrentHelpfeature('add_sketch')
        sketchingStore.setCurrentSketchId(false)
        sketchingStore.setSketchToolVisible(false)
        sketchingStore.setDrawMode(true)
        if (projectGanttStore.sketchDrawer.drawSketchType === 'single') {
          sketchingStore.setSketchMode('point')
          sketchingStore.setSketchProps({ extrudedHeight: 1 })
          sketchingStore.setSketchProps({ width: 1 })
          sketchingStore.setSketchProps({ rotation: 0 })
          sketchingStore.setSketchProps({
            color: { color: '#ffffff', alpha: 0.5 },
            name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'point')
          })
        }

        if (projectGanttStore.sketchDrawer.drawSketchType === 'line') {
          sketchingStore.setSketchMode('line')
          sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
          sketchingStore.setSketchProps({ width: 0.5 })
          sketchingStore.setSketchProps({
            color: { color: '#ffffff', alpha: 0.5 },
            name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'line')
          })
        }

        if (projectGanttStore.sketchDrawer.drawSketchType === 'area') {
          sketchingStore.setSketchMode('area')
          sketchingStore.setSketchProps({ height: 0 })
          sketchingStore.setSketchProps({ extrudedHeight: 0 })
          sketchingStore.setSketchProps({ width: 0 })
          sketchingStore.setSketchProps({ readonlyHeight: true })
          sketchingStore.setSketchProps({
            color: { color: '#ffffff', alpha: 0.5 },
            name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'area')
          })
        }

        if (projectGanttStore.sketchDrawer.drawSketchType === 'volume') {
          sketchingStore.setSketchMode('area')
          sketchingStore.setSketchProps({ extrudedHeight: 0.5 })
          sketchingStore.setSketchProps({ width: 0 })
          sketchingStore.setSketchProps({ readonlyHeight: false })
          sketchingStore.setSketchProps({
            color: { color: '#ffffff', alpha: 0.5 },
            name: Utils.generateSketchNameByType(sketchingStore.arrSketches, 'area', true)
          })
        }
      }
    }
  }, [projectGanttStore.sketchDrawer.drawSketchType])

  useEffect(() => {
    if (projectGanttStore.selectObjectModel.type) {
      const taskId = gantt.getSelectedId();
      if (!taskId) {
        Modal.warning({
          title: t('gantt.please-select-task-first'),
          content: '',
        });
        projectGanttStore.setSelectObjectModel({ task: '', type: '', listExist: [], open: false })
      } else {
        // hide drawer gantt
        projectGanttStore.setIsShowGanttPanel(false)

        projectStore.setCleanMode(true)

        let cTask = gantt.getTask(taskId)
        const scrollPosition = gantt.getScrollState();
        projectGanttStore.setGanttDefaults({ ...projectGanttStore.ganttDefaults, settings: { ...projectGanttStore.ganttDefaults?.settings, scrollPosition: { x: scrollPosition.x, y: scrollPosition.y } } })

        const filteredListExist = cTask.gantttask3dobjects?.filter(x => x.type === projectGanttStore.selectObjectModel.type);
        projectGanttStore.setSelectObjectModel({
          ...projectGanttStore.selectObjectModel,
          task: cTask,
          listExist: filteredListExist.length > 0 ? filteredListExist : [],
          open: true,
        });
      }
    }
  }, [projectGanttStore.selectObjectModel.type])

  useEffect(() => {
    return () => {
      cancelAnimationFrame(intervalID)
      cancelAnimationFrame(intervalID2)
    }
  }, [])

  const handleMouseDown = e => {
    e.preventDefault()
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback((e) => {
    let offsetRight = e.clientX - document.body.offsetLeft - 24;
    let minDrawerWidth = 345;
    if (offsetRight > minDrawerWidth) {
      gantt.config.grid_width = offsetRight;
      gantt.render();
    }
    if (offsetRight > window.innerWidth) {
      gantt.config.grid_width = window.innerWidth;
      gantt.render();
    }
  }, []);

  const getBorderOfGridToResize = () => {
    const gridWrapper = document.getElementsByClassName(
      'gantt_layout_cell_border_right'
    )
    if (!gridWrapper[1]) return

    let resizeComponent = document.createElement('div')
    resizeComponent.className =
      'gantt_layout_cell gantt_resizer gantt_resizer_x gantt_layout_cell_border_right'
    resizeComponent.style.height = '100%'
    let resizeChild = document.createElement('div')
    resizeChild.className =
      'gantt_layout_content gantt_resizer_x gantt_grid_resize_wrap'
    resizeComponent.appendChild(resizeChild)

    resizeComponent.addEventListener('mousedown', handleMouseDown)

    gridWrapper[1].insertAdjacentElement('afterend', resizeComponent)
  }

  const handleMouseDownToDragMarker = e => {
    e.preventDefault()
    document.addEventListener('mouseup', handleMouseUpToDragMarker, true)
    document.addEventListener('mousemove', handleMouseMoveToDragMarker, true)
    setIsDragging(true)
  }

  const handleMouseUpToDragMarker = () => {
    document.removeEventListener('mouseup', handleMouseUpToDragMarker, true)
    document.removeEventListener('mousemove', handleMouseMoveToDragMarker, true)
    setIsDragging(false)
  }

  function getDateShow() {
    return dateShow
  }

  const getDistanceBetweenElements = (xPos, next, previous) => {
    let distanceToPrevious, distanceToNext
    if (previous) {
      const previousRect = previous.getBoundingClientRect()
      const previousPosition = previousRect.left + previousRect.width / 2
      distanceToPrevious = xPos - previousPosition
    }
    if (next) {
      const nextRect = next.getBoundingClientRect()
      const nextPosition = nextRect.left + nextRect.width / 2
      distanceToNext = xPos - nextPosition
    }

    const keyFilter = getCurrentZoomLevel()

    if (distanceToNext > 0) {
      getDateShow().updateDateShow(1, keyFilter)
      updateMarkerTime(true)
      return
    }
    if (distanceToPrevious < 0) {
      getDateShow().updateDateShow(-1, keyFilter)
      updateMarkerTime(true)
    }
  }

  const handleMouseMoveToDragMarker = useCallback(
    (e) => {
      const nextMarkerDom = document.getElementsByClassName('next')?.[0];
      const previousMarkerDom = document.getElementsByClassName('previous')?.[0];
      const mouseX = e.clientX;
      getDistanceBetweenElements(mouseX, nextMarkerDom, previousMarkerDom);
    },
    [getDateShow]
  );

  const getMarkerToDragAndDrop = () => {
    const markers = document.getElementsByClassName('now')
    if (!markers[0]) {
      intervalID = requestAnimationFrame(getMarkerToDragAndDrop)
      return
    }
    if (!markers[0]?.getEventListeners()?.mousedown) {
      markers[0].addEventListener(
        'mousedown',
        handleMouseDownToDragMarker,
        true
      )
    }
    intervalID = requestAnimationFrame(getMarkerToDragAndDrop)
    // if(isMobile || isTablet){
    //   if (!markers[0]?.getEventListeners()?.touchstart) {
    //     markers[0].addEventListener(
    //       'touchstart',
    //       handleTouchStartToDragMarker
    //     )
    //   }
    // }
  }

  const handleCalculateDragGantt = () => {
    if (!isPaused) {
      const newMiddlePosition = calculateMiddlePosition()

      if (newMiddlePosition !== lastMiddlePosition) {
        const handleDebounceCalculateMiddleDate = debounce(() => {
          calculateMiddleDate()
        }, 300)
        handleDebounceCalculateMiddleDate()
        lastMiddlePosition = newMiddlePosition // Cập nhật vị trí cuối cùng
      }
    }
    intervalID2 = requestAnimationFrame(handleCalculateDragGantt)
  }

  const getCurrentZoomLevel = () => {
    let key = ''
    const listDomLabel = document.getElementsByClassName('lable-zoom-gantt')
    const dayFilters = [
      { value: 'Hours', label: t('hours') },
      { value: 'Days', label: t('days') },
      { value: 'Weeks', label: t('weeks') },
      { value: 'Months', label: t('months') },
      { value: 'Quarters', label: t('quarters') },
      { value: 'Years', label: t('years') },
    ]
    if (listDomLabel.length > 0) {
      const arrayDomLabel = Array.from(listDomLabel)
      arrayDomLabel.forEach(item => {
        const textContent = removeDuplicateText(item?.textContent)
        const keyFilter = dayFilters.find(item => removeDuplicateText(item.label) === textContent)?.value;
        if (keyFilter) {
          key = keyFilter;
        }
      })
    }
    return key
  }

  const handleSetTime = value => {
    projectGanttStore.setCurrentViewingTime(value)
  }

  const debounceCalculate = debounce(function (value) {
    handleSetTime(value)
  }, 200)

  const updateMarkerTime = isDragging => {
    const keyFilter = getCurrentZoomLevel()
    const now = dateShow.getNowTime()
    const next = dateShow.getNextTime(now, keyFilter)
    const previous = dateShow.getPreviousTime(now, keyFilter)

    markerConfig(now, 'now')
    isDragging && debounceCalculate(now)
    markerConfig(previous, 'previous')
    markerConfig(next, 'next')
  }

  return (
    <GanttContainer>
      <div
        id="gantt_here"
        ref={input => {
          ganttContainer = input
        }}
        style={{ width: '100%', height: 'calc(100vh - 145px)' }}></div>
    </GanttContainer>
  )
}

export default inject(
  'projectGanttStore',
  'projectStore',
  'sketchingStore',
  'adminStore',
  'objectQueryStore',
  'commonStore',
)(observer(Gantt))
