import { ColumnWidthOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, message, Skeleton, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import CustomAttributes from '../../../layout/CustomAttributes/CustomAttributes'
import { AttributeWrapper, HeaderDrawerWrapper } from './style'
import { HelpButton } from '@/components/elements'
import { toJS } from 'mobx'

const DrawerCustomAttribute = ({ projectStore, projectSettingStore }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const isTabletOrMobile = isMobile || isTablet
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = touch.clientX - (target && target.offsetRight ? target.offsetRight : 0);
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if (newWidth > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let offsetRight = e.clientX - document.body.offsetLeft;
        let minDrawerWidth = 360;
        if (offsetRight > minDrawerWidth) {
            setDrawerWidth(offsetRight);
        }
        if (offsetRight > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);
    //#endregion

    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize)

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])

    useEffect(() => {
        if (projectStore.showCustomAttributeDrawer) {
            setDrawerWidth(isTabletOrMobile ? 360 : window.innerWidth < 360 ? window.innerWidth : (window.innerWidth / 2) < 360 ? 360 : window.innerWidth / 2)

            const loadInitData = async () => {
                setLoading(true)
                await projectStore.getProjectCustomAttributes(projectStore.projectDetail.id).then(res => {
                    //
                })
                    .catch(err => {
                        console.log(err)
                        setLoading(false)
                    })
                setLoading(false)
            }
            loadInitData()
        }
    }, [projectStore.showCustomAttributeDrawer])

    /**
         * Save new project custom attribute
         * @param {*} data
         */
    const handleAddAttribute = (data) => {
        projectStore.setLoadingProgress(true)
        projectStore.createProjectCustomAttributes(data).then(res => {
            projectStore.setLoadingProgress(false)
            message.success(t('add-attribute-successfully'))
            projectStore.setVisibleModalProjectAtt(false)
        })
            .catch(err => {
                projectStore.setLoadingProgress(false)
                message.error(t('has-an-error-please-try-again'))
            })
    }

    /**
    * Update project custom attribute
    * @param {*} attribute id, data
    */
    const handleUpdateAttribute = (id, data) => {
        projectStore.setLoadingProgress(true)
        projectStore.updateProjectAttributes(id, data).then(res => {
            projectStore.setLoadingProgress(false)
            message.success(t('update-attribute-successfully'))
            let index = projectStore.prjCustomAttributeList.findIndex(c => c._id === res.id);
            if (index !== -1) {
                let _prjCustomAttributeList = [...projectStore.prjCustomAttributeList]
                _prjCustomAttributeList[index] = res
                projectStore.setProjectCustomAttributes(_prjCustomAttributeList)
            }
            projectStore.setVisibleModalProjectAtt(false)
            projectStore.setCurrentProjectAttribute(false)
        })
            .catch(err => {
                projectStore.setLoadingProgress(false)
                message.error(t('has-an-error-please-try-again'))
            })
    }


    /**
    * Delete project custom attribute
    * @param {*} attribute id 
    */
    const handleDeleteAttribute = (id) => {
        projectStore.setLoadingProgress(true)
        projectStore.deleteProjectCustomAttributes(id).then(res => {
            projectStore.setLoadingProgress(false)
            message.success(t('delete-attribute-successfully'))
            let index = projectStore.prjCustomAttributeList.findIndex(c => c._id === res.id)
            if (index !== -1) {
                let _prjCustomAttributeList = [...projectStore.prjCustomAttributeList]
                _prjCustomAttributeList.splice(index, 1)
                projectStore.setProjectCustomAttributes(_prjCustomAttributeList)
            }
            projectStore.setVisibleModalProjectAtt(false)
            projectStore.setCurrentProjectAttribute(false)
        })
            .catch(err => {
                projectStore.setLoadingProgress(false)
                message.error(t('has-an-error-please-try-again'))
            })
    }

    const onCloseCustomAttribute = () => {
        setLoading(false)
        setDrawerWidth(0)
        projectStore.setShowCustomAttributeDrawer(false);
        projectSettingStore.toggleProjectSettingDrawer(true); //Show again project setting drawer    
    };

    return (
        <Drawer
            title={
                <div className="help-btn-wrapper">
                    {t('attributes')}
                    <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div>
                            <HelpButton helppage={"attributes"} hovered={'black'}/>
                        </div>
                    </Tooltip>
                </div>
            }
            placement="left"
            width={drawerWidth}
            closable={true}
            onClose={e => onCloseCustomAttribute()}
            visible={projectStore.showCustomAttributeDrawer}
            maskClosable={false}
            mask={false}
            destroyOnClose={true}
            forceRender
            bodyStyle={{ marginRight: 10 }}
            className={projectStore.showCustomAttributeDrawer ? " attribute-drawer custom-wraper-splitPanel " : " attribute-drawer "}
            footer={false}
        >
            <AttributeWrapper>
                {isTabletOrMobile ? (
                    <div className="splitpanel-mobile-left">
                        <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                            <ColumnWidthOutlined />
                        </Button>
                    </div>
                ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-left" />)
                }
                <Skeleton loading={loading} active>
                    <CustomAttributes
                        zIndex={9999}
                        customAttributeDatas={projectStore.prjCustomAttributeList.filter(c => !c.isDeleted)}
                        txtEditModal={t('edit-project-custom-attribute')}
                        txtAddMoal={t('add-new-project-custom-attribute')}
                        isProjectCustomAtt={true}
                        handleUpdateAttribute={handleUpdateAttribute}
                        handleAddAttribute={handleAddAttribute}
                        handleDeleteAttribute={handleDeleteAttribute}
                        visibleModal={projectStore.visibleModalProjectAtt}
                        setVisibleModalAttribute={e => projectStore.setVisibleModalProjectAtt(e)}
                        setCurrentEditAttribute={e => projectStore.setCurrentProjectAttribute(e)}
                        currentAttribute={projectStore.currentProjectAttribute}
                    >
                    </CustomAttributes>
                </Skeleton>
            </AttributeWrapper>
        </Drawer>
    )
}

export default inject(
    'projectStore',
    'projectSettingStore',
)(observer(DrawerCustomAttribute))
