import { action, decorate, observable } from 'mobx';

var MA = function (range, bufferMaxSize, reserveRange) {
    this.buffer = []; // FIFO queue
    this.bufferMaxSize = bufferMaxSize || 20;
    this.avgRange = range || 10
    this.keepBuffer = []
    this.keepBufferSize = reserveRange || this.bufferMaxSize * 2;
    this.sumSin = 0;
    this.sumCos = 0;
    this.avg = 0;
}
MA.prototype = {
    /**
     * Add new value to buffer (FIFO queue)
     *
     * @param {integer|float} value
     * @returns {integer|float}
     * @access private
     */
    __push: function (value) {
        this.sumSin += Math.sin(value);

        this.sumCos += Math.cos(value);
        if (this.buffer.length >= this.avgRange) {
            const old = this.buffer[this.buffer.length - this.avgRange];
            this.sumSin -= Math.sin(old);
            this.sumCos -= Math.cos(old);
        }
        var removed = (this.buffer.length === this.bufferMaxSize)
            ? this.buffer.shift()
            : 0;
        // if (this.buffer[this.buffer.length - 1] && (Math.sign(this.buffer[this.buffer.length - 1]) * Math.sign(value) < 0)) {
        //   this.buffer = [];
        // }

        var removed2 = (this.keepBuffer.length === this.keepBufferSize)
            ? this.keepBuffer.shift()
            : 0;
        this.buffer.push(value);
        this.keepBuffer.push(value);
        return removed;
    },

    __avg: function (arr, idx, range) {
        if (arr.length > idx && (idx + 1 - range) > -1) {

            const sum = this.__sum(arr.slice(Math.max(arr.length - range, 1)))
            this.avg = Math.atan2(this.sumSin / range, this.sumCos / range);

            return this.avg;
            //return sum / range;
        }
        else {
            this.avg = Math.atan2(this.sumSin / arr.length, this.sumCos / arr.length);
            return this.avg;
            return arr[idx]
        }
    },

    __sum: function (arr) {
        var len = arr.length;
        var num = 0;
        while (len--) num += Number(arr[len]);
        return num;
    },

    /**
     * Smooth value from stream
     *
     * @param {integer|float} nextValue
     * @returns {integer|float}
     * @access public
     */
    next: function (nextValue, type) {
        var self = this;
        // push new value to the end, and remove oldest one
        var removed = this.__push(nextValue);
        // smooth value using all values from buffer
        // var result = this.buffer.reduce(function (last, current) {
        //   return self.smoothing * current + (1 - self.smoothing) * last;
        // }, removed);
        var result = this.__avg(this.buffer, this.buffer.length - 1, this.avgRange);

        if (type === 'heading') {
            // if (nextValue < -3 || nextValue > 3) {
            //   result = this.buffer[this.buffer.length - 1];
            // }
        }

        // replace smoothed value
        //this.buffer[this.buffer.length - 1] = result;
        this.count++;
        return result;
    },
};


var LPF = function (smoothing) {
    this.smoothing = smoothing || 0.5; // must be smaller than 1
    this.buffer = []; // FIFO queue
    this.bufferMaxSize = 20;
    this.count = 0;
    this.sumSin = 0;
    this.sumCos = 0;
    this.avg = 0;
};

LPF.prototype = {

    /**
     * Init buffer with array of values
     * 
     * @param {array} values
     * @returns {array}
     * @access public
     */
    init: function (values) {
        for (var i = 0; i < values.length; i++) {
            this.__push(values[i]);
        }
        return this.buffer;
    },

    /**
     * Add new value to buffer (FIFO queue)
     *
     * @param {integer|float} value
     * @returns {integer|float}
     * @access private
     */
    __push: function (value) {
        var removed = (this.buffer.length === this.bufferMaxSize)
            ? this.buffer.shift()
            : 0;

        this.buffer.push(value);
        return removed;
    },

    /**
     * Smooth value from stream
     *
     * @param {integer|float} nextValue
     * @returns {integer|float}
     * @access public
     */
    next: function (nextValue, type) {
        var self = this;
        // push new value to the end, and remove oldest one
        var removed = this.__push(nextValue);
        // smooth value using all values from buffer
        var result = this.buffer.reduce(function (last, current) {
            return self.smoothing * current + (1 - self.smoothing) * last;
        }, removed);

        if (type === 'heading') {
            if (nextValue < -3 || nextValue > 3) {
                result = this.buffer.reduce(function (last, current) {
                    return current;
                }, removed);
            }
        }

        // replace smoothed value
        this.buffer[this.buffer.length - 1] = result;
        this.count++;
        return result;
    },

    /**
     * Smooth array of values
     *
     * @param {array} values
     * @returns {undefined}
     * @access public
     */
    smoothArray: function (values) {
        var value = values[0];
        for (var i = 1; i < values.length; i++) {
            var currentValue = values[i];
            value += (currentValue - value) * this.smoothing;
            values[i] = Math.round(value);
        }
        return values;
    },
};
//#endregion

class ProjectSettingStore {
    isLoading = false

    openSensorSetting = false
    openCameraSetting = false
    defaultSystemProjectSetting = {
        showMap: true,
        showAntialiasing: true,
        showRefPoint: false,
        enableShadows: false,
        showHUD: false,
        requestRender: true,
        backgroundColorSetting: '#80664d',
        showAmbientOcclusion: true,
        showInspectorTool: false,
        showBoudingVolume: false,
        enableHighAccuracy: true,
        globeBehind: false,
        sensorFrequences: 30,
        nearValue: 0,
        nearDistance: 200,
        farDistance: 2000,
        fading: 1000,
        smoothFilterType: 'na',
        renderResolution: {
            useBrowserRecommendedResolution: true,
            eyeDomeLightingStrength: 1,
            eyeDomeLightingRadius: 1,
            resolutionScale: 1,
            maximumScreenSpaceError: 16,
            maxCountOfVisibleTiles: 200,
            maximumAttenuation: 5,
            shadowDarkness: 0.5,
            shadowDistance: 1000,
            shadowAccuracy: 4096,
            softShadows: false,
            msaaSamples: 4,
            fxaa: true,
        },
        maHead: new MA(this.sampleRange, 60),
        filterHead: new LPF(0.5),
        sampleRange: 8,
        openCVData: {
            id: 0,
            angle: -1000,
            vAngle: -1000,
            apply: false,
            extra: {
                hessianThreshold: 400,
                nOctaves: 4,
                nOctaveLayers: 4,
                extended: 0,
                upright: 1,
                ratio_thresh: 0.8,
                angle_filter: 0,
                distance_filter: 80,
                pixel_size: 16,
                key_rotation: 80,
                pixel_diff: 50,
                fov: 60,
                fovy: 33.5,
                image_filter: 4
            },
            hc: 0, // horizontal calibration
            vc: 0
        },
        ifcSetting: {
            terrainTileSize: 500,
            maxLevel: -1,
            maxTrianglesInFile: 2000000,
            scaleGeometricError: 1,
            combineObject: true,
            IFCLargeSegments: 16,
            IFCSmallSegments: 5,
            XMLAccuracy: 0.005,
            faceterDevSurface: 0.5,
            faceterDevNormal: 15,
            faceterGridRatio: 1,
            faceterMaxEdgeLength: 20,
            faceterMaxGrid: 20,
            faceterMinUGrid: 2,
            faceterMinVGrid: 2,
            // compression: {
            //     compressionLevel: 7,
            //     posQuantizationBits: 16,
            //     normalsQuantizationBits: 8,
            //     texcoordsQuantizationBits: 8,
            //     colorQuantizationBits: 8,
            //     genericQuantizationBit: 16
            // },
            compression: undefined, //edit 3/22023 default compression off
            pointCloudEngine: 'xdEngine',
            pointCloudsCompression: false,
            importer: 'ellipsoid',
            ifcimporter: 'rdf',
            importFilter :'none'
        },
        ambientOccSetting: {
            intensity: 1.0,
            bias: 0.4,
            lengthCap: 0.3,
            stepSize: 2,
            frustumLength: 100.0,
            blurStepSize: 2
        },
        underGroundSetting: {
            enabled: true,
            nearDistance: 1000,
            farDistance: 100000,
            nearAlpha: 0.1,
            farAlpha: 0.7,
        },
        show_quick_guide: true,
        fogSetting: {
            enabled: false,
            color: '#ffffff',
            alpha: 0.6,
            distance: [100.0, 0.0, 20000.0, 1.0]
        },
        lightSetting: {
            color: '#fffffa',
            intensitySunLight: 2.0,
            intensitySkyLight: 5.0,
            improvedAtmosphere : false,
            activeLighting : false,
            advanced : false
        },
        importerSetting: "speed",
        is2D : false
    }
    systemProjectSetting = {
        ...this.defaultSystemProjectSetting
    }
    visibleTreeData = {}

    set2D = v => {
        this.systemProjectSetting.is2D = v
    }


    setOpenSensorSetting = (stt) => {
        this.openSensorSetting = stt
    }

    setOpenCameraSetting = (stt) => {
        this.openCameraSetting = stt
    }


    setSampleRange = b => {
        if (b) {
            this.systemProjectSetting.maHead = new MA(b, 60)
            this.systemProjectSetting.filterHead = new LPF(b)
            this.systemProjectSetting.sampleRange = b
        }
    }

    setOpenCVData = data => {
        Object.assign(this.systemProjectSetting.openCVData, data)
    }

    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading
    }

    toggleProjectSettingDrawer = state => {
        this.showProjectSettingDrawer = state
    }

    setRenderResolution = (key, value) => {
        this.systemProjectSetting.renderResolution[key] = value
    }
    clearSystemProjectSetting = () => {
        this.systemProjectSetting = { ...this.defaultSystemProjectSetting }
    }
    setSystemProjectSetting = data => {
        if (data) {
            this.systemProjectSetting = { ...this.systemProjectSetting, ...data }
        }

    }

    setdefaultSystemProjectSetting = data => {
        if (data) {
            this.defaultSystemProjectSetting = { ...this.defaultSystemProjectSetting, ...data }
        }

    }


    assignSystemProjectSetting = (data) => {
        Object.assign(this.systemProjectSetting, data)
    }

    setAssignObjSystemProjectSetting = (key, value) => {
        this.systemProjectSetting[key] = value
    }

    assignSetting = (v) => {
        this.systemProjectSetting = v
    }

    setIfcSetting = v => {
        Object.assign(this.systemProjectSetting.ifcSetting, v)
    }

    setAmbientOccSetting = v => {
        Object.assign(this.systemProjectSetting.ambientOccSetting, v)
    }

    setUndergroundSetting = v => {
        Object.assign(this.systemProjectSetting.underGroundSetting, v)
    }

    setFogSetting = v => {
        Object.assign(this.systemProjectSetting.fogSetting, v)
    }

    setLightSetting = v => {
        Object.assign(this.systemProjectSetting.lightSetting, v)
    }

    setImporterSetting = v => {
        this.systemProjectSetting.importerSetting = v
    }
    getParamSystemSetting = (projectStore, projectSettingStore, usersStore) => {
        let _projectSetting = projectStore.projectDetail?.metadata?.projectSetting || []
        let _navigationInstructions = projectStore.projectDetail?.metadata?.navigationInstructions || []
        const {navigationStyles} = projectStore;
        if (_projectSetting.length > 0) {
          const newSetting = {
            userid: usersStore?.currentUser?._id,
            visibleTreeData: this.visibleTreeData,
            projectSetting: {
              ...projectSettingStore.systemProjectSetting,
            },
          }
          _projectSetting.map((elm, index) => {
            if (elm.userid === usersStore.currentUser._id) {
              _projectSetting.splice(index, 1, newSetting)
            }
          })
        } else {
          _projectSetting.push({
            userid: usersStore.currentUser._id,
            visibleTreeData: this.visibleTreeData,
            projectSetting: {
              ...this.defaultSystemProjectSetting,
            },
          })
        }
        if(_navigationInstructions?.length > 0){
            let _navstyle = {
                type: navigationStyles?.type,
                control: navigationStyles?.control,
                distanceLimit: navigationStyles?.distanceLimit,
                allowUnderground: navigationStyles?.allowUnderground,
              }
              _navigationInstructions.map((elm, index) => {
                if(index===0){
                    _navigationInstructions[index] = {
                        ..._navigationInstructions[index],
                        ..._navstyle
                    }
                }
                if (elm.userId === usersStore.currentUser._id) {
                    _navigationInstructions.splice(index, 1,{
                        ..._navstyle,
                        userId: usersStore?.currentUser?._id
                    })
                }
            })
        }else{
            let _navstyle = {
              type: navigationStyles?.type,
              control: navigationStyles?.control,
              distanceLimit: navigationStyles?.distanceLimit,
              allowUnderground: navigationStyles?.allowUnderground,
              userId: usersStore?.currentUser?._id
            }
            _navigationInstructions.push(_navstyle)
        }

        return {
            ...projectStore.projectDetail.metadata,
            projectSetting: _projectSetting,
            navigationInstructions: _navigationInstructions,
            ifcSetting : projectSettingStore?.systemProjectSetting?.ifcSetting,
            sensorSetting : {
                sensorFrequences : projectSettingStore?.systemProjectSetting?.sensorFrequences,
                sampleRange : projectSettingStore?.systemProjectSetting?.sampleRange,
                smoothFilterType : projectSettingStore?.systemProjectSetting?.smoothFilterType
            }
        }
    }

    setVisibleTreeData = (data) => {
        this.visibleTreeData = data
    }

}

decorate(ProjectSettingStore, {
    isLoading: observable,
    setLoadingProgress: action,
    toggleProjectSettingDrawer: action,
    showProjectSettingDrawer: observable,
    systemProjectSetting: observable,
    setSystemProjectSetting: action,
    assignSystemProjectSetting: action,
    setAssignObjSystemProjectSetting: action,
    setOpenCVData: action,
    setSampleRange: action,
    setRenderResolution: action,
    getParamSystemSetting: action,
    setIfcSetting: action,
    setAmbientOccSetting: action,
    setUndergroundSetting: action,
    setFogSetting: action,
    setImporterSetting: action,
    assignSetting: action,
    openSensorSetting: observable,
    openCameraSetting: observable,
    setOpenSensorSetting: action,
    setOpenCameraSetting: action,
    defaultSystemProjectSetting: observable,
    clearSystemProjectSetting: action,
    setdefaultSystemProjectSetting: action,
    visibleTreeData:observable ,
    setVisibleTreeData: action,
    setLightSetting: action,
    set2D : action
})

export default new ProjectSettingStore()