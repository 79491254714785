import React from 'react'
import { inject, observer } from 'mobx-react'
import { Layout } from 'antd'
import './PageTemplate.css'

import ToolbarLeft from '../Toolbar/ToolbarLeft'
import ToolbarTop from '../Toolbar/ToolbarTop'
import ToolbarTopRight from '../Toolbar/ToolbarTopRight'
import ToolbarBottom from '../Toolbar/ToolbarBottom'
import ToolbarRight from '../Toolbar/ToolbarRight'
import DrawerCityDemo from '../MainDrawer'
import { LoadingSpinner } from '@/components/elements'
import projectTeamsStore from '../../../stores/projectTeamsStore'
import adminStore from '../../../stores/adminStore'
import licenseStore from '../../../stores/licenseStore'
import ManageOrganizationPage from '../../pages/ManageOrganizationPage'
import UserSettingsPage from '../../pages/UserSettingsPage'
import { useLocation } from 'react-router'
import LicenseDrawer from '../../pages/LicenseDrawer/LicenseDrawer'

const { Content } = Layout

const DefaultTemplate = ({ commonStore, projectStore, capturesStore, usersStore, organizationStore, feedbackStore, dashboardStore, children, viewer }) => {
  let location = useLocation()
  const checkLoading = () =>
    !!(
      commonStore.isLoading ||
      projectStore.isLoading ||
      projectTeamsStore.isLoading ||
      capturesStore.isLoading ||
      usersStore.isLoading ||
      organizationStore.isLoading ||
      feedbackStore.isLoading ||
      dashboardStore.isLoading ||
      adminStore.isLoading ||
      licenseStore.isLoading
    )

  return (
    <Layout>
      <Layout style={{
        background: '#eee',
        padding: 0,
      }}>
        <ToolbarTop currentPage={commonStore.currentPage} viewer={viewer?.current?.cesiumElement} />
        <ToolbarTopRight currentPage={commonStore.currentPage} viewer={viewer?.current?.cesiumElement} />
        <ToolbarLeft currentPage={commonStore.currentPage} />
        <ToolbarRight currentPage={commonStore.currentPage} />
        <ToolbarBottom currentPage={commonStore.currentPage} viewer={viewer} />
        <DrawerCityDemo />
        <ManageOrganizationPage currentPage={commonStore.currentPage} />
        <UserSettingsPage currentPage={commonStore.currentPage} />
        <LicenseDrawer />
        <Content>{children}</Content>
      </Layout>
      {
        (checkLoading() && location.search !== '?topicreport')
          ? <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
          : null
      }
    </Layout>
  )
}

export default inject('commonStore', 'projectStore', 'capturesStore', 'usersStore', 'organizationStore', 'feedbackStore', 'dashboardStore')(observer(DefaultTemplate))
