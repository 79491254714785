import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Switch, Upload, message, notification } from 'antd'
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { apiUrl } from '@/config'
import validator from '@/validator'
import { UploadButton } from '@/components/elements'
const { TextArea } = Input
const { Option } = Select;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-arounds;
  .ant-btn {
    margin-right: 8px;
    margin-bottom: 12px;
    padding: 0 14px;
  }
`

const ProjectSettingsForm = props => {
  const {
    projectStore, commonStore, match, projectDetail, history
  } = props
  const { t } = useTranslation();
  const [uploadFile, setUploadFile] = useState(undefined)
  const projectId = match.params.projectId

  const CustomForm = props => {
    const [form] = Form.useForm();
    const [projectionList, setProjectionList] = useState([])

    let timeout;
    let currentValue;
    const fetchSearchProjection = (value, callback) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      currentValue = value;

      const searchProjection = () => {
        projectStore.getSearchProjection(value)
          .then(() => {
            if (currentValue === value) {
              const  results = projectStore.projectionList;
              const data = [];
              if (typeof results !== 'undefined') {
                results.forEach(r => {
                  data.push(r);
                });
              }
              callback(data);
            }
          });
      }
      timeout = setTimeout(searchProjection, 300);
    }

    const handleSearch = value => {
      if (value) {
        fetchSearchProjection(value, data => {
          setProjectionList(data)
        });
      } else {
        setProjectionList([]);
      }
    }

    const handleChange = (value) => {
      const pr = projectionList.filter(d => d.code === value);
      projectStore.setProjectionDetail(pr)
    }

    const handleSubmit = values => {
      const submitValues = {
        name: values.name,
        desc: values.desc,
        elevationSystem: values.elevationSystem,
        isPublic: values.isPublic,
        defaultStartupMode: "normal" || values.defaultStartupMode,
      }

      if (projectStore.projectionDetail.length > 0) {
        submitValues.coordinateSystem = values.coordinateSystem

        let _tilesetData = projectDetail.tilesetData
        let _coordinateSystem = {
          code: projectStore.projectionDetail[0].code,
          name: projectStore.projectionDetail[0].name,
          proj4: projectStore.projectionDetail[0].proj4,
          wkt: projectStore.projectionDetail[0].wkt,
          unit: projectStore.projectionDetail[0].unit.split(' ')[0]
        }
        submitValues.tilesetData = { ..._tilesetData, coordinateSystem: _coordinateSystem }
      }

      projectStore.setLoadingProgress(true)
      axios({
        method: 'PUT',
        url: `${apiUrl}/projects/${projectId}`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
        data: submitValues,
      }).then(() => {
        if (uploadFile) {
          const formData = new FormData()
          formData.append('files', uploadFile)
          formData.append('ref', 'project')
          formData.append('refId', projectId)
          formData.append('field', 'thumbnail')
          axios({
            method: 'POST',
            url: `${apiUrl}/upload`,
            headers: {
              Authorization: `Bearer ${commonStore.token}`,
            },
            data: formData,
          }).then(() => {
            projectStore.setLoadingProgress(false)
            message.success(t('project-settings-updated-successfully'))
          }).catch(error => {
            console.log(error)
            projectStore.setLoadingProgress(false)
            message.error(t('upload-file-error'))
          })
        } else {
          projectStore.setLoadingProgress(false)
          message.success(t('project-settings-updated-successfully'))
        }
      }).catch(error => {
        projectStore.setLoadingProgress(false)
        if (error.data && error.data.message) {
          notification.open({
            message: t('an-error-occurred-update-project'),
            description: t(error.data.message),
            icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
          })
        } else {
          message.error(t('something-went-wrong'))
        }
        fetchSearchProjection(projectStore.projectionDetail[0].code, data => {
          setProjectionList(data)
          handleChange(projectStore.projectionDetail[0].code)
        });
      })
    }

    const handleUploadCover = file => {
      setUploadFile(file)
      const reader = new FileReader();
      reader.addEventListener('load', () => projectStore.updateProjectCover(reader.result));
      reader.readAsDataURL(file);
      return false
    }

    const handleClickWorkflow = () => {
      history.push(`/project/${match.params.projectId}/workflows`)
    }

    const handleClickFeedbackEditor = () => {
      history.push(`/project/${match.params.projectId}/feedback-editor`)
    }

    useEffect(() => {
      if (projectDetail.tilesetData && projectDetail.tilesetData.coordinateSystem) {
        fetchSearchProjection(projectDetail.tilesetData.coordinateSystem.code, data => {
          setProjectionList(data)
          handleChange(projectDetail.tilesetData.coordinateSystem.code)
        });
      } else {
        setProjectionList([]);
      }
      return () => {
        setProjectionList([]);
      }
    }, [])

    const options = projectionList.map(d => <Option key={d.code}>{'EPSG:' + d.code + ' ' + d.name}</Option>);
    const handleCancel = () => {
      history.push('/projects')
    }
    return (
      <>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          {/*<Avatar/>*/}
          <p>{t('project-image')}</p>
          <Form.Item name="thumbnail" valuePropName="fileList">
            <Upload
              showUploadList={false}
              name="avatar"
              className={`avatar-uploader ${projectDetail.thumbnail ? 'has-image' : 'no-image'} input-upload-image-project`}
              beforeUpload={file => handleUploadCover(file)}
              listType="picture-card"
            >
              {
                projectDetail.thumbnail
                  ? projectDetail.thumbnail.base64
                    ? <img src={projectDetail.thumbnail.base64} alt="" className='input-upload-image-project' />
                    : <img src={projectDetail.thumbnail.url} alt="" className='input-upload-image-project' />
                  : <UploadButton />
              }
            </Upload>
          </Form.Item>
          <Form.Item
            label={t('project-name')}
            name="name"
            initialValue={projectDetail.name}
            rules={[
              {
                required: true,
                message: t('please-input-project-name'),
              },
              {
                validator: validator.validateEmptyString,
              },
            ]}
          >
            <Input placeholder={t('input-project-name')} />
          </Form.Item>
          <Form.Item
            label={t('coordinate-system')}
            name="coordinateSystem"
            initialValue={(projectDetail.tilesetData && projectDetail.tilesetData.coordinateSystem) ? projectDetail.tilesetData.coordinateSystem.code : ''}
            rules={[
              {
                required: true,
                message: t('please-choose-coordinate-system'),
              },
              { validator: validator.validateEmptyString },
            ]}
          >
            <Select
              showSearch
              placeholder={t('enter-country-code-or-name-coordinate-system')}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={val => handleSearch(val)}
              onChange={val => handleChange(val)}
              notFoundContent={null}
            >
              {options}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('elevation-system')}
            name="elevationSystem"
            initialValue={projectDetail.elevationSystem ? projectDetail.elevationSystem : "None"}
            rules={[
              {
                required: false,
                message: t('please-choose-elevation-system'),
              },
              { validator: validator.validateEmptyString },
            ]}
          >
            <Select className='selectdrp'>
              <Option value="None">None</Option>
              <Option value="N2000">N2000</Option>
              <Option value="N60">N60</Option>
              <Option value="EGM96">EGM96</Option>
              <Option value="EGM2008">EGM2008</Option>
              <Option value="NN2000">NN2000</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('description')}
            name="desc"
            initialValue={projectDetail.desc}
            rules={[
              { validator: validator.validateEmptyString },
            ]}
          >
            <TextArea rows={5} placeholder={t('input-description')} />
          </Form.Item>
          <Form.Item
            label={t('public')}
            name="isPublic"
            valuePropName="checked"
            initialValue={projectDetail.isPublic ? projectDetail.isPublic : false}
          >
            <Switch />
          </Form.Item>
          {/* <Form.Item
            label={t('default-startup-mode')}
            name="defaultStartupMode"
            initialValue={projectDetail.defaultStartupMode ? projectDetail.defaultStartupMode : 'normal'}
            rules={[
              { validator: validator.validateEmptyString },
            ]}
          >
            <Select className='selectdrp'>
              <Option value="normal">Normal</Option>
              <Option value="gps">GPS</Option>
              <Option value="gps_compass">GPS + Compass</Option>
              <Option value="ar_with_3d">AR with 3D</Option>
              <Option value="ar_with_camera">AR with Camera</Option>
            </Select>
          </Form.Item> */}
          <ButtonGroup>
            <Button type={'default'} onClick={handleCancel} >{t('commons.cancel')}</Button>
            <Button type={'primary'} htmlType={'submit'}>{t('commons.save')}</Button>
            {/* <Button onClick={handleClickWorkflow}>Workflows</Button>
            <Button onClick={handleClickFeedbackEditor}>Feedback Editor</Button> */}
          </ButtonGroup>
        </Form>
      </>
    )
  }

  return (
    <Fragment>
      <CustomForm />
    </Fragment>
  )

}

export default withRouter(inject('projectStore', 'commonStore')(observer(ProjectSettingsForm)))