import styled from 'styled-components'
import Container from '../../layout/Container'
import {  Modal } from 'antd'

//#region Style for Toolbox
export const ToolboxWrapper = styled.ul`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  // width: 20%;
  // min-width: 200px;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
`
export const ToolboxList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  li {
    border-radius: 4px;
    border: 1px solid #E3E5E5;
    margin-bottom: 3px;
    padding: 5px 10px 5px 35px;
    position: relative;
    @media screen and (min-width: 1367px) {
      width: 100%;
      margin-right: 1%;
    }
    @media screen and (max-width: 1366px) {
      width: 100%;    
    }
    &:hover {
      cursor: pointer;
    }
    .img {
      width: 35px;
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
export const ToolboxFieldsTitle = styled.div`
  font-size: 14px;
  line-height: 1.6;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
`
//#endregion

//#region style FeedbackEditorPage
export const FeedbackEditorWraper = styled.div`
  background: #FFFFFF;
  padding: 60px 15px 15px;
  margin-top: 55px;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }
  .ant-table-body {
    overflow-x: auto !important;
  }
`

export const PageHeading = styled.h1`
  font-size: 24px;
  color: #ff8d4f;
  text-align: center;
  margin-bottom: 60px;
`

export const FormCreatorWrapper = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .splitpanel-left {
    top: 0;
    right: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
  }

  .splitpanel-mobile-left {
    top: 0;
    right: 0; 
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-left: -15px;
    padding: 0 !important;
  }
}
`
//#endregion

//#region style Editor
export const EditorWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  // width: 100%;
  margin: 0 1.25%;
  ${props => props.minimal? '':'max-height: calc(100vh - 140px);'}
  overflow-y: auto;
  margin-bottom: 15px;
`
export const EditorHeading = styled.div`
 margin-bottom: 15px;
  h1 {
    color: ${props => props.theme.solidColor};
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;
  }
  p {
    color: #919699;
    font-size: 14px;
    margin-bottom: 0;

  }
  .ant-btn {
    margin-right: 8px;
  }
  .ant-btn:last-child {
    margin-right: 0px;
  }
  .align-right {
    text-align: right;
  }  
`
export const EditorMain = styled.div`
  height: 100%; 
`
export const EmptyWrapper = styled.div`
  background: #F9F9F9;
  border: 1px dashed #C4C4C4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 28px 72px;
  color: #919699;
  text-align: center;
`
//#endregion

//#region style Element
export const ElemWrapper = styled.div`
  background: #F9F9F9;
  border: 1px dashed #C4C4C4;
  border-radius: 4px;
  padding: 0 15px 15px 15px;
  margin-bottom: 10px;
  position: relative;
  user-select: none;
  > label {
    display: block;
    font-size: 12px;
    color: #020202;
    padding: 10px 0;
    user-select: none;
  }
  > p {
    font-size: 12px;
    color: #999;
    user-select: none;
    white-space: pre-line;
  }
  &:hover {
    border: 1px solid ${props => props.theme.solidColor};
    transition: all ease .3s;
  }
  .smiley-button > img:first-child {
    padding-left: 0 !important;
  }
`
export const ElemButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 15px;
  bottom: 25px;
`
export const ElemButton = styled.div`
  width: 22px;
  height: 22px;
  display: block;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }
  img {
    height: 11px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
export const ElemElement = styled.div`
  background: #FFFFFF;
  border: 1px solid #C6CACC;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 35px 8px 15px;
  position: relative;
  color: #919699;
  font-size: 14px;
  min-height: 40px;
  word-break: break-all;
  user-select: none;
  .img {
    display: block;
    content: '';
    position: absolute;
    opacity: .4;
    right: 15px;
    bottom: 10px;
  }
  .prefix {
    display: block;
    content: '';
    position: absolute;
    opacity: .4;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
  }
  &.multiLine {
    min-height: 79px;
  }
  &.currency {
    padding-left: 55px;
  }
`
export const ElemTableSkeleton = styled.div`
  overflow-x: auto;
  table {
    width: 100%;
    td {
      border: 1px solid #d9d9d9;
      background-color: #fff;
      font-size: 13px;
      height: 30px;
      input {
        appearance: none;
        border: none;
        &:focus {
          outline: none !important;
          box-shadow: 0 0 10px rgba(0, 0, 0, .3) !important;
        }
      }
    }
  }
`
//#endregion

//#region style FormProperties
export const FormPropertiesWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px 15px 0;
  // width: 33%;
  // min-width: 300px
  ${props => props.minimal? '':'max-height: calc(100vh - 140px);'}
  overflow-y: auto;
  .custom-input-number {
    width: 100% !important;   
  }
`
export const FormPropertiesHeading = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .ant-checkbox-wrapper {
    margin-top: 5px;
    margin-bottom: 5px;
    .ant-checkbox + span {
      padding-right: 0;
    }
  }
`
export const FormPropertiesTitle = styled.div`
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin: 5px 0;
  padding-right: 10px;
`
//#endregion

//#region style Common

//#endregion


export const ToolDecidim = styled.div`
  padding: 15px;
  .modal-decidim .ant-btn {
    margin-left: 8px !important;
  }
  .ant-btn-green {
    color: white !important;
    border-color: #28a745;
    background: #28a745;
    &:hover, &:focus {      
      border-color: #398439;
      background: #449d44;
      box-shadow: 0 2px 10px rgba(254, 81, 150, 0.5);
    }
    &[disabled] {
      opacity: .5;
      &:hover {
        box-shadow: none !important;
      }
    }
  }
`
export const HeaderFeedbackDrawerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-outer {
        padding-right: 30px;
    }
`

export const ModalFeedbackName = styled(Modal)`
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`