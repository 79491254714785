import styled from 'styled-components'

export const DrawerHeading = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  p {
    margin-bottom: 0;
    margin-right: 30px;
  }
  .title {
    color:#fff;
    margin-right: 5px;
  }
`
export const CardViewWrapper = styled.div`
.ant-avatar{
    background: none;
  }
  .ant-card {
    background-color: transparent;
    border: none;
    .ant-card-body {
      padding: 10px;
    }
    .ant-card-cover {
      position: relative;
      .anticon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: auto;
        color: white;
        font-size: 12px;
        opacity: 0;
        transition: ease .3s;
        color: #F26524;
        &:hover {
          transition: ease .3s;
          opacity: 1;
          color: #F26524;
        }
      }
      .save-action{
        position: absolute;
        top: 10px;
        right: 30px;
        width: auto;
        color: white;
        font-size: 16px;
        opacity: 0;
        transition: ease .3s;
        color: #F26524;
        &:hover {
          transition: ease .3s;
          opacity: 1;
          color: #F26524;
        }
      }
      &:hover {
        cursor: pointer;
        .anticon {
          transition: ease .3s;
          opacity: 1;
        }
        .save-action {
          transition: ease .3s;
          opacity: 1;
        }
      }
    }
    .ant-card-meta-title {
      color: white;
      font-size: 12px;
      text-align: center;
    }
  }
`
export const PageWrapper = styled.div`
  .ant-tag{
    margin-bottom: 5px;
    cursor: pointer;
  }
  .ant-tag:last-child{
    margin-right: 0
  }

  .ant-typography {
    cursor: pointer;
    &.selected {
      cursor: pointer;      
      color: #fff;
      background-color: #F26524;
      padding: 4px 7px;
      font-size: 12px;
      line-height: 20px;    
      border-radius: 4px;
    }
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 10px 10px;   
  }
  
  .ant-select-dropdown {
    z-index: 999999;
  }

  .ant-drawer-content {
      background-color: rgba(38, 38, 38, 0.75);

      .ant-drawer-wrapper-body {
        overflow: hidden;

        .ant-drawer-header {
          border-top: 1px solid #444;
          border-bottom: 1px solid #444;
          background-color: transparent;
        }
      }
  }

  .ant-table{
    background-color: unset;
    border: 1px solid #fff;
    border-bottom: none;
    .ant-table-cell {
      color:#fff
    }
    .ant-table-tbody tr:hover td {
       background-color: rgba(38, 38, 38, 0.5) !important;
    }
    .ant-table-cell {
      background-color: unset;
    }
  }
`

export const SelectObjectModelFlatDialogStyle = styled.div`
  background-color: rgba(222, 222, 222, 0.75);
  padding: 10px;
  position: absolute;
  top: 45px;
  left: 45px;
  border-radius: 2px;
  @media screen and (max-width: 768px) {
    padding: 8px;
  }
`