import { BuildOutlined, DownOutlined, InboxOutlined, InfoCircleOutlined, LoadingOutlined, PlusOutlined, QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Empty, Form, Input, InputNumber, Layout, message, Modal, notification, Popconfirm, Progress, Row, Select, Spin, Steps, Tooltip, Tree, Typography, Skeleton, Table } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import axios from 'axios';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid';
import { ReactComponent as DataAddFolderIcon } from '@/assets/svgs/data-add-folder.svg';
import { ReactComponent as DataAddIcon } from '@/assets/svgs/data-add.svg';
import { ReactComponent as DeleteIcon } from '@/assets/svgs/delete.svg';
import { ReactComponent as ElementLibrary } from '@/assets/svgs/elementLibrary.svg';
import { ReactComponent as RenameIcon } from '@/assets/svgs/rename.svg';
import { ReactComponent as ResourceNewFolderIcon } from '@/assets/svgs/resourcefolder-b0.svg';
import { getMimeOfFile } from '@/lib/projectLib';
import TreeUtils from '@/tree-utils';
import validator from '@/validator';
import { apiUrl } from '@/config';
import ModalAddElement from './ModalAddElement';
import ModalAddFolder from './ModalAddFolder';
import { AttributeWrapper, Container, IconWrapper, StepContainer, TreeDataFolder, TreeNodeTitle, UploadWrapper } from './style';
import './style.css';
import _ from 'lodash';
import { isMobile, isTablet } from 'react-device-detect';
import { UploadButton, SVGIcon } from '@/components/elements'

const { Option } = Select;
const { Content } = Layout;
const { Text } = Typography
const { Step } = Steps
const { TextArea } = Input;


const OrganizationSketchTab = ({ projectStore, adminStore, commonStore, organizationStore, sketchingStore }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [imageUrl, setimageUrl] = useState(null);
    const [currentLogoUpload, setCurrentLogoUpload] = useState();
    const [modelUpload, setModelUpload] = useState();
    const [step, setStep] = useState(0)
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false);
    const loadingIcon = <LoadingOutlined style={{ fontSize: 12 }} spin />
    const ASSET_URL = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/`
    const { defaultTreeData } = adminStore
    const organizationId = organizationStore.paramsOrganizationDetail
    const keyExpands = []

    useEffect(() => {
        if (adminStore.organizationActiveTab === 'library') {
            let fetchData = async () => {
                organizationStore.setLoadingProgress(true)
                setLoading(true)
                await organizationStore.getSketchElements('organization', false, organizationId).then(res => {
                    organizationStore.setLoadingProgress(false)
                    setLoading(false)
                    if (res.data) {
                        organizationStore.setCurrentSketchElement(res.data)
                    } 
                    // else {
                    //     let pTree = traversalTree(defaultTreeData, null);
                    //     setTreeData(pTree)
                    //     organizationStore.setCurrentSketchElement({
                    //         data: {
                    //             expandedKeys,
                    //             selectedKeys,
                    //             treeData: pTree
                    //         }
                    //     })
                    // }
                })
                    .catch(err => {
                        organizationStore.setLoadingProgress(false)
                        setLoading(false)
                        // let pTree = traversalTree(defaultTreeData, null);
                        // setTreeData(pTree)
                        // organizationStore.setCurrentSketchElement({
                        //     data: {
                        //         expandedKeys,
                        //         selectedKeys,
                        //         treeData: pTree
                        //     }
                        // })
                    })
            }
            fetchData()
        }
        return () => {
            organizationStore.setSettings(false)
            adminStore.setProgressUploadFile(0)
            organizationStore.setShowAddResourceModel(false)
            organizationStore.setShowAddFolderModal(false);
            organizationStore.setShowAddElement(false);
            organizationStore.setFolderEdit()
            organizationStore.setElementEdit()
            organizationStore.setCurrentSketchElement({})
            organizationStore.setSelectedNode()
            setTreeData([])
            setExpandedKeys([])
            setAutoExpandParent(true)
            setSelectedKeys([])
            setimageUrl()
            setCurrentLogoUpload()
            setModelUpload()
            setStep(0)
            setFileList([])
            setLoading(false)
        }

    }, [adminStore.organizationActiveTab])

    useEffect(() => {
        if (organizationStore.selectedNode) {
            organizationStore.setSettings(true)
            let info = organizationStore.selectedNode
            if (info.image) {
                setimageUrl(info.image.url)
            }
            if (info.model) {
                setModelUpload(info.model)
            }
            form.setFieldsValue({
                title: info.title,
                description: info.description,
                type: info.type,
                identifier: info.identifier,
                minLength: info.minLength ? info.minLength : 0,
                minWidth: info.minWidth ? info.minWidth : 0,
                minHeight: info.minHeight ? info.minHeight : 0,
                maxLength: info.maxLength ? info.maxLength : 0,
                maxWidth: info.maxWidth ? info.maxWidth : 0,
                maxHeight: info.maxHeight ? info.maxHeight : 0,
                defaultLength: info.defaultLength ? info.defaultLength : 0,
                defaultWidth: info.defaultWidth ? info.defaultWidth : 0,
                defaultHeight: info.defaultHeight ? info.defaultHeight : 0,
            });
        }
    }, [organizationStore.selectedNode])

    useEffect(() => {
        if (organizationStore.currentSketchElement.data) {
            setExpandedKeys(sketchingStore.setExistExpandedKeys(organizationStore.currentSketchElement.data?.treeData, organizationStore.currentSketchElement.data?.expandedKeys))
            setSelectedKeys(organizationStore.currentSketchElement.data?.selectedKeys)
            organizationStore.setSelectedNode(organizationStore.currentSketchElement.data?.selectedNode)
            if (organizationStore.currentSketchElement?.data?.selectedNode) {
                organizationStore.setSettings(true)
                let info = organizationStore.currentSketchElement.data.selectedNode
                setSelectedKeys([info.key])
                if (info.image) {
                    setimageUrl(info.image.url)
                }
                if (info.model) {
                    setModelUpload(info.model)
                }
                form.setFieldsValue({
                    title: info.title,
                    description: info.description,
                    type: info.type,
                    identifier: info.identifier,
                    minLength: info.minLength ? info.minLength : 0,
                    minWidth: info.minWidth ? info.minWidth : 0,
                    minHeight: info.minHeight ? info.minHeight : 0,
                    maxLength: info.maxLength ? info.maxLength : 0,
                    maxWidth: info.maxWidth ? info.maxWidth : 0,
                    maxHeight: info.maxHeight ? info.maxHeight : 0,
                    defaultLength: info.defaultLength ? info.defaultLength : 0,
                    defaultWidth: info.defaultWidth ? info.defaultWidth : 0,
                    defaultHeight: info.defaultHeight ? info.defaultHeight : 0,
                });
            }
            let pTree = traversalTree(organizationStore.currentSketchElement.data?.treeData, null);
            setTreeData(pTree)
        }
    }, [organizationStore.currentSketchElement])

    const getParentKeys = (treeData, key) => {
        if (treeData) {
            const node = TreeUtils.searchTreeNode(treeData, 'key', key);
            if (node) {
                keyExpands.push(node.key)
            }
            if (node?.parentKey) getParentKeys(treeData, node.parentKey)
        }
        return keyExpands
    }

    const onCancelModel = () => {
        organizationStore.setShowAddResourceModel(false)
    }

    const uploadMesh = (files) => {
        let fileUploadSize = parseFloat(files.size) / 1024 / 1024
        const process = async () => {
            setStep(1)
            adminStore.setProgressUploadFile(0);
            const presignedS3Url = await projectStore.generatePreSignedPutUrl({ fileName: files.name })
            var config = {
                method: 'put',
                url: presignedS3Url.url,
                headers: {
                    'Content-Type': getMimeOfFile(files.name)
                },
                data: files,
                onUploadProgress: event => adminStore.setProgressUploadFile(Number(((100 * event.loaded) / event.total).toFixed(2)))
            };
            let ext = presignedS3Url.file.split('.').pop()
            files.hash = presignedS3Url.file.split('.').shift()
            files.ext = `.${ext}`
            files.url = `${ASSET_URL}${presignedS3Url.file}`
            files.id = `${ASSET_URL}${presignedS3Url.file}`

            axios(config)
                .then(async (f) => {
                    setStep(2)
                    adminStore.setProgressUploadFile(0)
                    let file = files
                    let newModel = {
                        name: file.name ? file.name : 'Unnamed',
                        src: file.url,
                        hash: file.hash,
                        data: {
                            ext: file.ext,
                            id: file.id,
                            size: file.size,
                        }
                    }
                    setModelUpload(newModel)
                    message.success(t('upload-model-successfully'))
                }).catch(err => {
                    notification.open({
                        message: t('an-error-occurred-when-upload-file'),
                        description: t('something-went-wrong-when-uploading-file'),
                        icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                        duration: 0,
                    })
                    setStep(0)
                    setFileList([])
                })
                .finally(() => {
                    onCancelModel()
                    setStep(3)
                });
        }

        const clearData = () => {
            setStep(0)
            setFileList([])
        }
        if (fileUploadSize <= Number.MAX_VALUE) {
            process()
        } else {
            clearData()
            message.error(t('file-size-is-too-large'))
        }
        return false
    }

    const onClickHandlerAddModel3D = () => {
        organizationStore.setShowAddResourceModel(true)
    }

    const handleChangeFile = e => {
        setFileList(e.fileList)
    }

    const updateSketchElement = () => {
        let data = {
            expandedKeys,
            selectedKeys,
            treeData,
            selectedNode: organizationStore.selectedNode
        }
        if (organizationStore.currentSketchElement.id) {
            organizationStore.setLoadingProgress(true)
            organizationStore.updateSketchElement(organizationStore.currentSketchElement.id, { data }).then(res => {
                organizationStore.setLoadingProgress(false)
                message.success(t('update-element-library-successfully'))
            })
                .catch(err => {
                    organizationStore.setLoadingProgress(false)
                    message.error(t('an-error-occurred'))
                })
        } else {
            let payload = {
                type: 'organization',
                organization: organizationId,
                data
            }
            organizationStore.setLoadingProgress(true)
            organizationStore.createSketchElements(payload).then(res => {
                message.success(t('create-org-sketch-library'))
                organizationStore.setLoadingProgress(false)
            })
                .catch(err => {
                    organizationStore.setLoadingProgress(false)
                    message.error(t('an-error-occurred'))
                })
        }

    }

    const onDrop = info => {
        setimageUrl()
        setCurrentLogoUpload()
        setModelUpload()
        const canDrag = (isSketchTypeDrop(info.dragNode) || isFolder(info.dragNode));
        const canDrop = (isSketchTypeDrop(info.node) || isFolder(info.node));
        if ((canDrag && isFolder(info.node)) || (canDrag && info.dropToGap && canDrop)) {
            const dropKey = info.node.key;
            const dragKey = info.dragNode.key;
            const dropPos = info.node.pos.split('-');
            const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
            const dropNode = info.node

            const loop = (data, key, callback) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].key === key) {
                        return callback(data[i], i, data);
                    }
                    if (data[i].children) {
                        loop(data[i].children, key, callback);
                    }
                }
            };
            const data = [...organizationStore.currentSketchElement.data.treeData];

            // Find dragObject
            let dragObj;
            loop(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
                dragObj = item;
            });

            if (!info.dropToGap) {
                // Drop on the content
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    item.children.unshift(dragObj);
                });
            } else if (
                (info.node.props.children || []).length > 0 && // Has children
                info.node.props.expanded && // Is expanded
                dropPosition === 1 // On the bottom gap
            ) {
                loop(data, dropKey, item => {
                    item.children = item.children || [];
                    item.children.unshift(dragObj);
                });
            } else {
                let ar;
                let i;
                loop(data, dropKey, (item, index, arr) => {
                    ar = arr;
                    i = index;
                });
                if (dropPosition === -1) {
                    ar.splice(i, 0, dragObj);
                } else {
                    ar.splice(i + 1, 0, dragObj);
                }
            }
            let newParentKey = dropNode.type === "FOLDER" ? dropNode.key : dropNode.parentKey
            let newNode = {
                ...info.dragNode,
                parentKey: newParentKey
            }
            organizationStore.setSelectedNode(newNode)
            setSelectedKeys([newNode.id])
            let param = {
                treeData: data,
                selectedNode: newNode
            }
            param.expandedKeys = expandedKeys
            if (newParentKey) {
                let isExist = expandedKeys.find(c => c === newParentKey)
                if (!isExist) {
                    param.expandedKeys = [...expandedKeys, newParentKey]
                    setExpandedKeys(...expandedKeys, newParentKey)
                }
            }
            param.selectedKeys = [newNode.id]
            organizationStore.setLoadingProgress(true);
            organizationStore.updateSketchElement(organizationStore.currentSketchElement.id, { data: param }).then(res => {
                message.success(t('update-treeData-successfully'))
            }).catch(err => {
                message.error(t('update-treeData-failed'))
            }).finally(() => {
                organizationStore.setLoadingProgress(false);
            })
        }
    }

    const handleDetete = async () => {
        const { treeData } = organizationStore.currentSketchElement.data;
        if (organizationStore.selectedNode) {
            const { parentKey, key } = organizationStore.selectedNode;
            removeTreeNode(toJS(treeData), parentKey, key)
        } else {
            return message.error(t('please-select-one-node'))
        }
    }

    const removeTreeNode = async (data, parentKey, key) => {
        if (parentKey) {
            const node = TreeUtils.searchTreeNode(data, 'key', parentKey);
            if (node) {
                const index = node.children.findIndex(child => child.key === key);
                if (index >= 0) {
                    node.children.splice(index, 1);
                }
            }
        } else {
            if (data.length > 0) {
                const index = data.findIndex(item => item.key === key);
                if (index >= 0) {
                    data.splice(index, 1);
                }
            }
        }
        let deleteParam = {
            expandedKeys,
            treeData: data,
        }
        organizationStore.setLoadingProgress(true)
        organizationStore.updateSketchElement(organizationStore.currentSketchElement.id, { data: deleteParam }).then(res => {
            organizationStore.setLoadingProgress(false)
            message.success(t('delete-successfully'))
        })
            .catch(err => {
                organizationStore.setLoadingProgress(false)
                message.error(t('an-error-occurred'))
            })
    }

    const updateElementSettings = (child, data) => {
        if (data?.length) {
            if (organizationStore.selectedNode) {
                let toNode;
                if (organizationStore.selectedNode.parentKey) {
                    const parrentNode = TreeUtils.searchTreeNode(data, 'key', organizationStore.selectedNode.parentKey);
                    toNode = parrentNode.children;
                } else {
                    toNode = data;
                }
                const existed = toNode.find(item => item.title === child.title);
                if (existed && existed.key !== organizationStore.selectedNode.key) return message.error(t('element-already-existed'))
                const node = toNode.find(child => child.key === organizationStore.selectedNode.key);
                node.title = child.title;
                node.type = child.type;
                node.identifier = child.identifier;
                node.image = child.image;
                node.model = child.model;
                node.description = child.description;
                node.minLength = child.minLength;
                node.minWidth = child.minWidth;
                node.minHeight = child.minHeight;
                node.maxLength = child.maxLength;
                node.maxWidth = child.maxWidth;
                node.maxHeight = child.maxHeight;
                node.defaultLength = child.defaultLength;
                node.defaultWidth = child.defaultWidth;
                node.defaultHeight = child.defaultHeight;
            }
        } else {
            data = [child]
        }
        let _param = {
            ...organizationStore.currentSketchElement.data,
            treeData: data,
            expandedKeys: _.uniqBy(expandedKeys),
            selectedKeys
        }
        let newElement = {
            title: child.title,
            type: child.type,
            identifier: child.identifier,
            image: child.image,
            model: child.model,
            description: child.description,
            minLength: child.minLength,
            minWidth: child.minWidth,
            minHeight: child.minHeight,
            maxLength: child.maxLength,
            maxWidth: child.maxWidth,
            maxHeight: child.maxHeight,
            defaultLength: child.defaultLength,
            defaultWidth: child.defaultWidth,
            defaultHeight: child.defaultHeight,
        }
        _param.selectedNode = {
            ...organizationStore.selectedNode,
            ...newElement
        }
        _param.selectedKeys = [organizationStore.selectedNode.key]
        organizationStore.setLoadingProgress(true)
        organizationStore.updateSketchElement(organizationStore.currentSketchElement.id, { data: _param }).then(res => {
            organizationStore.setSelectedNode({
                ...organizationStore.selectedNode,
                ...newElement
            })
            message.success(t('update-element-successfully'))
            organizationStore.setElementEdit();
        }).catch(err => {
            console.log(err)
            message.error(t('update-element-failed'))
        }).finally(() => {
            organizationStore.setLoadingProgress(false)
        })
    }

    const showModalAddFolder = () => {
        organizationStore.setShowAddFolderModal(true);
    }
    const showModalAddElement = () => {
        organizationStore.setShowAddElement(true);
    }

    const clickEditElement = () => {
        if (isFolder(organizationStore.selectedNode)) {
            organizationStore.setFolderEdit(organizationStore.selectedNode);
            organizationStore.setShowAddFolderModal(true);
            // } else if (isSketchType(organizationStore.selectedNode)) {
        } else {
            organizationStore.setElementEdit(organizationStore.selectedNode);
            organizationStore.setShowAddElement(true);
        }
    }

    const openSettingElementForm = (info) => {
        organizationStore.setSettings(true)
        if (info.image) {
            setimageUrl(info.image.url)
        }
        if (info.model) {
            setModelUpload(info.model)
        }
        form.setFieldsValue({
            title: info.title,
            description: info.description,
            type: info.type,
            identifier: info.identifier,
            minLength: info.minLength ? info.minLength : 0,
            minWidth: info.minWidth ? info.minWidth : 0,
            minHeight: info.minHeight ? info.minHeight : 0,
            maxLength: info.maxLength ? info.maxLength : 0,
            maxWidth: info.maxWidth ? info.maxWidth : 0,
            maxHeight: info.maxHeight ? info.maxHeight : 0,
            defaultLength: info.defaultLength ? info.defaultLength : 0,
            defaultWidth: info.defaultWidth ? info.defaultWidth : 0,
            defaultHeight: info.defaultHeight ? info.defaultHeight : 0,
        });
    }

    const handleSaveSettingElement = () => {
        form.validateFields().then(values => {
            const data = organizationStore.currentSketchElement?.data?.treeData;
            const newNode = {
                title: values.title,
                minLength: values.minLength ? values.minLength : 0,
                minWidth: values.minWidth ? values.minWidth : 0,
                minHeight: values.minHeight ? values.minHeight : 0,
                maxLength: values.maxLength ? values.maxLength : 0,
                maxWidth: values.maxWidth ? values.maxWidth : 0,
                maxHeight: values.maxHeight ? values.maxHeight : 0,
                defaultLength: values.defaultLength ? values.defaultLength : 0,
                defaultWidth: values.defaultWidth ? values.defaultWidth : 0,
                defaultHeight: values.defaultHeight ? values.defaultHeight : 0,
                identifier: values.identifier,
                description: values.description,
                id: organizationStore.selectedNode?.id,
                key: organizationStore.selectedNode?.key,
                type: values.type,
                image: imageUrl && currentLogoUpload ? currentLogoUpload : organizationStore.selectedNode?.image,
                model: modelUpload && fileList.length > 0 ? toJS(modelUpload) : organizationStore.selectedNode?.model,
                parentKey: organizationStore.selectedNode?.type === 'tree' ? organizationStore.selectedNode?.parentKey : null,
                children: []
            }
            if (organizationStore.selectedNode?.type === "FOLDER") {
                newNode.type = "FOLDER"
            }
            updateElementSettings(newNode, toJS(data));
        })
    }

    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(_.uniqBy(expandedKeysValue));
        setAutoExpandParent(false);
    };


    const onSelect = (selectedKeysValue, info) => {
        setimageUrl()
        setCurrentLogoUpload()
        setModelUpload()
        setSelectedKeys(selectedKeysValue);
        if (selectedKeysValue.length) {
            organizationStore.setSelectedNode(info.node);
            openSettingElementForm(info.node)
        } else {
            organizationStore.setSelectedNode();
            form.resetFields()
            organizationStore.setSettings(false)
        }
    };

    const traversalTree = (data, parentKey) => {
        return data.map(item => {
            if (item.children) {
                return {
                    ...item,
                    icon: ({ expanded }) => setNodeIcon(data, item, expanded),
                    children: traversalTree(item.children, item.key),
                    parentKey: parentKey,
                    id: item.id ? item.id : uuid()
                };
            }
            return {
                ...item,
                icon: ({ expanded }) => setNodeIcon(data, item, expanded),
                parentKey: parentKey,
                id: item.id ? item.id : uuid()
            };
        });
    }
    const isFolder = (node) => {
        return node && node.type === 'FOLDER';
    }

    const isSketchType = (node) => {
        return node && (!node.type || node.type !== 'FOLDER') && node.model;
    }

    const isSketchTypeDrop = (node) => {
        return node && (!node.type || node.type !== 'FOLDER');
    }

    const setNodeIcon = (data, item, expanded) => {
        if (isFolder(item)) {
            return (
                <>
                    <SVGIcon
                        content={<ResourceNewFolderIcon />}
                        width={20}
                        height={20}
                    />{' '}
                </>
            )
        }

        if (isSketchType(item)) {
            return (
                <>
                    <SVGIcon
                        content={<ElementLibrary />}
                        width={20}
                        height={20}
                    />{' '}
                </>
            )
        }
    }
    const titleRender = (nodeData) => {
        let hide = false;
        return <TreeNodeTitle hide={hide}>{nodeData.title}</TreeNodeTitle>
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length > 1) {
            e.fileList.shift()
        }
        return e && e.fileList;
    };

    const propsUpload = {
        name: 'file',
        multiple: false,
        listType: "picture",
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                message.error(t('you-can-only-upload-jpg-png-file'));
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error(t('image-must-smaller-than-2MB'));
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
            } else if (status === 'error') {
                message.error(t('the-file-upload-failed', { filename: info.file.name }));
            }
        },

        onRemove() {
            setCurrentLogoUpload();
            setimageUrl()
        }
        ,
        customRequest: ({ onSuccess, onError, file, onProgress }, event) => {
            const formData = new FormData()
            formData.append('files', file, file.name);
            axios({
                async: true,
                crossDomain: true,
                processData: false,
                contentType: false,
                mimeType: 'multipart/form-data',
                method: 'POST',
                url: `${apiUrl}/upload`,
                headers: {
                    Authorization: `Bearer ${commonStore.token}`,
                },
                onUploadProgress: event => {
                    onProgress({ percent: (event.loaded / event.total) * 100 }, file);
                },
                data: formData,

            }).then(res => {
                onSuccess(res => normFile(res.data))
                message.success(t('upload-image-successfully'));
                setCurrentLogoUpload(res.data[0]);
                setimageUrl(res.data[0].url);
            })
                .catch(err => message.error(t('has-an-error-please-try-again')))
        }
        ,

    };

    const beforeUpload = (files) => {
        let fileType = files.name.slice(files.name.length - 4)
        fileType = fileType ? fileType.toLowerCase() : ''
        if (fileType == "gltf" || fileType == ".glb") {
            uploadMesh(files)
        } else {

            return false
        }
    }

    const checkVisibleTreeNode = () => {
        return organizationStore.selectedNode && organizationStore.settings && organizationStore.currentSketchElement.id
    }

    const onBlurFillter = (status, key) => {
        if (status === "length") {

            if (key === 'min') {
                form.setFieldsValue({
                    minLength: form.getFieldValue().minLength,
                    defaultLength: form.getFieldValue().minLength,
                })
                if (form.getFieldValue().minLength > form.getFieldValue().maxLength) {
                    form.setFieldsValue({
                        maxLength: form.getFieldValue().minLength
                    })
                }
            }
            if (key === 'default') {
                form.setFieldsValue({
                    defaultLength: form.getFieldValue().defaultLength,
                    maxLength: form.getFieldValue().defaultLength
                })
                if (form.getFieldValue().defaultLength < form.getFieldValue().minLength) {
                    form.setFieldsValue({
                        minLength: form.getFieldValue().defaultLength
                    })
                }
            }
            if (key === 'max') {
                form.setFieldsValue({
                    defaultLength: form.getFieldValue().maxLength,
                    maxLength: form.getFieldValue().maxLength
                })
                if (form.getFieldValue().maxLength < form.getFieldValue().minLength) {
                    form.setFieldsValue({
                        minLength: form.getFieldValue().maxLength
                    })
                }
            }
        } else if (status == 'width') {
            if (key === 'min') {
                form.setFieldsValue({
                    minWidth: form.getFieldValue().minWidth,
                    defaultWidth: form.getFieldValue().minWidth,
                })
                if (form.getFieldValue().minWidth > form.getFieldValue().maxWidth) {
                    form.setFieldsValue({
                        maxWidth: form.getFieldValue().minWidth
                    })
                }
            }
            if (key === 'default') {
                form.setFieldsValue({
                    defaultWidth: form.getFieldValue().defaultWidth,
                    maxWidth: form.getFieldValue().defaultWidth
                })
                if (form.getFieldValue().defaultWidth < form.getFieldValue().minWidth) {
                    form.setFieldsValue({
                        minWidth: form.getFieldValue().defaultWidth
                    })
                }
            }
            if (key === 'max') {
                form.setFieldsValue({
                    defaultWidth: form.getFieldValue().maxWidth,
                    maxWidth: form.getFieldValue().maxWidth
                })
                if (form.getFieldValue().maxWidth < form.getFieldValue().minWidth) {
                    form.setFieldsValue({
                        minWidth: form.getFieldValue().maxWidth
                    })
                }
            }
        } else {
            if (key === 'min') {
                form.setFieldsValue({
                    minHeight: form.getFieldValue().minHeight,
                    defaultHeight: form.getFieldValue().minHeight,
                })
                if (form.getFieldValue().minHeight > form.getFieldValue().maxHeight) {
                    form.setFieldsValue({
                        maxHeight: form.getFieldValue().minHeight
                    })
                }
            }
            if (key === 'default') {
                form.setFieldsValue({
                    defaultHeight: form.getFieldValue().defaultHeight,
                    maxHeight: form.getFieldValue().defaultHeight
                })
                if (form.getFieldValue().defaultHeight < form.getFieldValue().minHeight) {
                    form.setFieldsValue({
                        minHeight: form.getFieldValue().defaultHeight
                    })
                }
            }
            if (key === 'max') {
                form.setFieldsValue({
                    defaultHeight: form.getFieldValue().maxHeight,
                    maxHeight: form.getFieldValue().maxHeight
                })
                if (form.getFieldValue().maxHeight < form.getFieldValue().minHeight) {
                    form.setFieldsValue({
                        minHeight: form.getFieldValue().maxHeight
                    })
                }
            }
        }
    }

    const columns = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            render: text => <span className='status-table'>{text}</span>,
        },
        {
            title: t('x-length'),
            dataIndex: 'keyX',
            key: 'x',
        },
        {
            title: t('y-width'),
            dataIndex: 'keyY',
            key: 'y',
        },
        {
            title: t('z-height'),
            dataIndex: 'keyZ',
            key: 'z',
        },
    ];

    const data = [
        {
            key: 'min_dimensions',
            name: t('min'),
            keyX: <Form.Item
                className='ant-form-item-table'
                name="minLength">
                <InputNumber
                    onBlur={() => onBlurFillter("length", 'min')}
                    min={0}
                    max={1000000}
                    step={0.1}
                />
            </Form.Item>,
            keyY: <Form.Item
                className='ant-form-item-table'
                name="minWidth">
                <InputNumber
                    onBlur={() => onBlurFillter("width", 'min')}
                    min={0}
                    max={1000000}
                    step={0.1}
                />
            </Form.Item>,
            keyZ: <Form.Item
                className='ant-form-item-table'
                name="minHeight">
                <InputNumber
                    onBlur={() => onBlurFillter("height", 'min')}
                    min={0}
                    max={1000000}
                    step={0.1}
                />
            </Form.Item>,
        },
        {
            key: 'default_dimensions',
            name: t('default'),
            keyX: <Form.Item
                className='ant-form-item-table'
                name="defaultLength">
                <InputNumber
                    min={0}
                    onBlur={() => onBlurFillter("length", 'default')}
                    max={1000000}
                    step={0.1}
                />
            </Form.Item>,
            keyY: <Form.Item
                className='ant-form-item-table'
                name="defaultWidth">
                <InputNumber
                    min={0}
                    onBlur={() => onBlurFillter("width", 'default')}
                    max={1000000}
                    step={0.1}
                />
            </Form.Item>,
            keyZ: <Form.Item
                className='ant-form-item-table'
                name="defaultHeight">
                <InputNumber
                    min={0}
                    onBlur={() => onBlurFillter("height", 'default')}
                    max={1000000}
                    step={0.1}
                />
            </Form.Item>,
        },
        {
            key: 'max_dimensions',
            name: t('max'),
            keyX: <Form.Item
                className='ant-form-item-table'
                name="maxLength">
                <InputNumber
                    min={0}
                    onBlur={() => onBlurFillter("length", 'max')}
                    max={1000000}
                    step={0.1}
                />
            </Form.Item>,
            keyY: <Form.Item
                className='ant-form-item-table'
                name="maxWidth">
                <InputNumber
                    min={0}
                    onBlur={() => onBlurFillter("width", 'max')}
                    max={1000000}
                    step={0.1}
                />
            </Form.Item>,
            keyZ: <Form.Item
                className='ant-form-item-table'
                name="maxHeight">
                <InputNumber
                    min={0}
                    onBlur={() => onBlurFillter("height", 'max')}
                    max={1000000}
                    step={0.1}
                />
            </Form.Item>,
        },
    ];
    return (
        <Skeleton loading={loading} active>
            <Content style={{ overflow: 'initial' }}>
                <div style={{ background: '#fff', textAlign: 'center' }}>
                    <Layout>
                        <Content>
                            <Row type="flex" justify="flex-end" align="middle" style={{ padding: "10px " }}     >

                                <Col span={24}>

                                    <Row justify='end' className="control-btn-group"    >
                                        {
                                            organizationStore.currentSketchElement.id && (
                                                <>
                                                    <IconWrapper>
                                                        <Tooltip
                                                            title={t('add-folder')}
                                                            overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}
                                                            >
                                                            <Avatar
                                                                shape="square"
                                                                icon={<SVGIcon content={<DataAddFolderIcon />} width={32} height={32} />}
                                                                onClick={showModalAddFolder}
                                                            />
                                                        </Tooltip>
                                                    </IconWrapper>
                                                    <IconWrapper>
                                                        <Tooltip 
                                                            title={t('add-element')}
                                                            overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}
                                                        >
                                                            <Avatar
                                                                shape="square"
                                                                icon={<SVGIcon content={<DataAddIcon />} width={32} height={32} />}
                                                                onClick={showModalAddElement}
                                                            />
                                                        </Tooltip>
                                                    </IconWrapper>
                                                </>
                                            )
                                        }
                                        {
                                            organizationStore.selectedNode && organizationStore.currentSketchElement.id && (
                                                <>
                                                    <IconWrapper>
                                                        <Tooltip
                                                            title={t('rename')}
                                                            overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                            <Avatar
                                                                shape="square"
                                                                icon={<SVGIcon content={<RenameIcon />} width={32} height={32} />}
                                                                onClick={clickEditElement}
                                                            />
                                                        </Tooltip>
                                                    </IconWrapper>
                                                    <IconWrapper>
                                                        <Popconfirm
                                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                            onConfirm={() => handleDetete()}
                                                            placement="bottomLeft"
                                                            okText={t('commons.ok')}
                                                            cancelText={t('commons.cancel')}
                                                            okButtonProps={{ danger: true }}
                                                            title={
                                                                <span>
                                                                    {t('are-you-sure-you-want-to-delete')}
                                                                </span>
                                                            }>
                                                            <Tooltip
                                                                title={t('commons.delete')}
                                                                overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                                <Avatar
                                                                    shape="square"
                                                                    icon={<SVGIcon content={<DeleteIcon />} width={32} height={32} />}
                                                                />
                                                            </Tooltip>
                                                        </Popconfirm>
                                                    </IconWrapper>
                                                </>
                                            )
                                        }
                                        {
                                            !organizationStore.currentSketchElement.id && (
                                                <Button
                                                    style={{
                                                        float: "right",
                                                        marginRight: 10
                                                    }}
                                                    type="primary"
                                                    onClick={updateSketchElement}
                                                >
                                                    <SaveOutlined />{t('commons.create')}
                                                </Button>
                                            )
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Content>
                        <Container >
                            {treeData && treeData.length ? (
                                <Row  >
                                    <Col span={checkVisibleTreeNode() ? 12 : 12}><TreeDataFolder>
                                        <Tree
                                            className="draggable-tree treeData-panel"
                                            showIcon
                                            draggable
                                            blockNode
                                            switcherIcon={<DownOutlined />}
                                            treeData={treeData}
                                            onSelect={onSelect}
                                            expandedKeys={expandedKeys}
                                            selectedKeys={selectedKeys}
                                            onExpand={onExpand}
                                            defaultExpandParent={false}
                                            onDrop={onDrop}
                                            titleRender={(nodeData) => titleRender(nodeData)}
                                        />
                                    </TreeDataFolder></Col>
                                    {
                                        organizationStore.selectedNode && organizationStore.settings && organizationStore.currentSketchElement.id && (
                                            <Col style={isTabletOrMobile ? '' : { padding: " 0 30px" }} span={isTabletOrMobile ? 24 : 12}>
                                                <AttributeWrapper>
                                                    <Row justify='space-between' >
                                                        <Col className='title'>{t('properties')}</Col>
                                                        <Col>
                                                            <Row justify='end'>
                                                                <Button icon={<SaveOutlined />} onClick={handleSaveSettingElement} className='ml-5' type='primary'>{t('commons.save')}</Button>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Form form={form} name="edit-element" layout="vertical">
                                                            <Form.Item
                                                                label={t('name')} name="title"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('please-input-the-title-of-element'),
                                                                    },
                                                                    { validator: validator.validateEmptyString },

                                                                ]}>
                                                                <Input
                                                                    placeholder={t('input-element-name')} />
                                                            </Form.Item>
                                                            {
                                                                !isFolder(organizationStore.selectedNode) && (
                                                                    <>
                                                                        <Form.Item
                                                                            label={t('description')} name="description"
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                    message: t('please-input-the-description-of-element'),
                                                                                },
                                                                                { validator: validator.validateEmptyString },
                                                                            ]}>
                                                                            <TextArea
                                                                                rows={3}
                                                                                placeholder={t('input-elemnet-description')} />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label={t('type')} name="type"
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                    message: t('please-input-the-type-of-element'),
                                                                                },
                                                                                { validator: validator.validateEmptyString },
                                                                            ]}>
                                                                            <Input
                                                                                placeholder={t('input-elemnet-type')} />
                                                                        </Form.Item>
                                                                        <Row className='title'>{t('3d-model')}</Row>
                                                                        <span>
                                                                            <Button icon={<PlusOutlined />} onClick={() => onClickHandlerAddModel3D()}>
                                                                                {modelUpload ? t('edit-3d-model') : t('add-3d-model')}
                                                                            </Button>
                                                                            {<Text style={{ paddingLeft: "5px" }} mark> {modelUpload ? t('model-3d-is-set') : t('no-3d-model-set')}</Text>}
                                                                        </span>
                                                                        <Row className='title'>{t('image')}</Row>
                                                                        <Form.Item>
                                                                            <Dragger {...propsUpload}>
                                                                                {
                                                                                    imageUrl ? (
                                                                                        <img
                                                                                            src={imageUrl}
                                                                                            style={{
                                                                                                minHeight: '150px',
                                                                                                maxHeight: "150px",
                                                                                                maxWidth: '150px',
                                                                                                width: '100%',
                                                                                                objectFit: "cover"
                                                                                            }}
                                                                                        />
                                                                                    ) : <UploadButton />
                                                                                }
                                                                            </Dragger>
                                                                        </Form.Item>

                                                                        <Row className='title'>{t('dimensions')}</Row>
                                                                        <Row>
                                                                            <Col span={isTabletOrMobile ? 24 : 8}>
                                                                                <Table size='small' className='ant-row-table' columns={columns} dataSource={data} pagination={false} />
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className='title'>{t('identifier')}</Row>
                                                                        <Form.Item
                                                                            name="identifier"
                                                                            rules={[
                                                                                {
                                                                                    required: false,
                                                                                    message: t('please-input-the-identifier-of-element'),
                                                                                },
                                                                                { validator: validator.validateEmptyString },
                                                                            ]}>
                                                                            <Input
                                                                                placeholder={t('input-element-identifier')} />
                                                                        </Form.Item>
                                                                        <Modal
                                                                            width={500}
                                                                            style={{ top: 30 }}
                                                                            onCancel={onCancelModel}
                                                                            visible={organizationStore.showAddResourceModel}
                                                                            title={t('add-sketch-model')}
                                                                            icon={<BuildOutlined />}
                                                                            iconType="build"
                                                                            zIndex={9999}
                                                                            footer={false}
                                                                            maskClosable={false}
                                                                        >
                                                                            <UploadWrapper>
                                                                                <Dragger
                                                                                    name={'file'}
                                                                                    multiple={false}
                                                                                    maxCount={1}
                                                                                    showUploadList={false}
                                                                                    onChange={e => handleChangeFile(e)}
                                                                                    beforeUpload={beforeUpload}
                                                                                >
                                                                                    <p className="ant-upload-drag-icon">
                                                                                        <InboxOutlined />
                                                                                    </p>
                                                                                    <p className="ant-upload-text">
                                                                                        {t('click-or-drag-file-to-this-area-to-upload')}
                                                                                    </p>
                                                                                    <p className="ant-upload-hint">
                                                                                        {t('file-support')}: .gltf,.glb
                                                                                    </p>
                                                                                </Dragger>
                                                                                <StepContainer>
                                                                                    <Steps
                                                                                        direction="vertical"
                                                                                        size="small"
                                                                                        current={step}
                                                                                        progressDot={true}>
                                                                                        <Step
                                                                                            title={t('select-file-from-computer')}
                                                                                            description={fileList.length > 0 ? fileList[0].name : ""}
                                                                                        />
                                                                                        <Step
                                                                                            title={
                                                                                                <div>
                                                                                                    {t('upload-to-server')} &nbsp;{' '}
                                                                                                    {step === 1 ? <Spin indicator={loadingIcon} /> : ''}
                                                                                                </div>
                                                                                            }
                                                                                            description={step === 1 ? <>
                                                                                                <div className='colorStyle'>{t('the-file-is-currently-being-uploaded')}</div>
                                                                                                <Progress percent={adminStore.progressUploadFile} />
                                                                                            </> : step === 2 ? t('the-file-is-already-uploaded') : t('file-will-be-uploaded-to-our-server')}
                                                                                        />
                                                                                        <Step title={t("finish")} description={<div className='colorStyle'>{t('save-model-to-sketch-type')}</div>} />
                                                                                    </Steps>
                                                                                </StepContainer>
                                                                            </UploadWrapper>
                                                                        </Modal>
                                                                    </>
                                                                )
                                                            }
                                                        </Form>
                                                    </Row>
                                                </AttributeWrapper>
                                            </Col>
                                        )
                                    }
                                </Row>

                            ) : <Empty description={<span>{t('no-data')}</span>} />}
                        </Container>
                        <ModalAddElement expandedKeys={expandedKeys} getParentKeys={getParentKeys} />
                        <ModalAddFolder expandedKeys={expandedKeys} getParentKeys={getParentKeys} />
                    </Layout>
                </div>
            </Content>
        </Skeleton>
    )
}
export default withRouter(inject('organizationStore', 'adminStore', 'projectStore', 'commonStore', 'sketchingStore')(observer(OrganizationSketchTab)))