import { observable, action, decorate } from 'mobx'
import { message } from 'antd'
// Request
import { ProjectRequest, ProjectTeamsRequest } from '@/requests'
// Stores
// import usersStore from './usersStore'

class ProjectTeamsStore {
  teamList = []
  showAddTeamMemberModal = false
  isLoading = false
  projectRoles = []
  showProjectTeamsDrawer = false

  clearTeamList = () => {
    this.teamList = []
  }

  setTeamList = data => {
    this.teamList = data
  }
  setAppLoading = state => {
    this.isLoading = state
  }

  toggleShowAddTeamMemberModal = state => {
    this.showAddTeamMemberModal = state
  }

  getProjectRoles = async () => {
    return ProjectRequest.getProjectRoles()
      .then(response => {
        let result = []
        let roleType = ['project_owner', 'project_manager', 'project_collaborator', 'project_viewer']
        if (Object.keys(response.data).length > 0) {
          let data = Object.keys(response.data).map((key) => {
            return {
              id: key,
              roleName: response.data[key].roleName,
              role: response.data[key].role,
              description: response.data[key].description
            }
          })
          roleType.map(_type => {
            let isExist = data.find(res => res.role === _type)
            if (!isExist) return
            result.push(isExist)
          })
          let roles = {}
          result.forEach(entry => {
            roles[entry.id] = {
              role: entry.role,
              roleName: entry.roleName,
              description: entry.description

            }
          });
          this.projectRoles = roles
        } else {
          this.projectRoles = response.data
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  clearProjectRoles = () => {
    this.projectRoles = []
  }

  sendInvite = async (email, role, projectId) => {
    let submitValues = {
      email: email.toLowerCase(),
      projectrole: role,
      project: projectId,
      inviteStatus: 'waiting_accept',
    }
    return new Promise((resolve, reject) => {
      ProjectRequest.sendProjectInvite(submitValues).then((res) => {
        resolve(res);
      }).catch(err => {
        console.log(err);
        reject(err)
      })
    })
  }

  isFetchingPrjTeam =  false;
  getProjectTeams = async (projectId) => {
    if(this.isFetchingPrjTeam) return ;
    this.isFetchingPrjTeam = true
    return ProjectTeamsRequest.getAllProjectTeams(projectId)
      .then(response => {
        this.teamList = response.data
        this.isFetchingPrjTeam = false
      })
      .catch(error => {
        console.log(error)
        this.isFetchingPrjTeam = false
      })
  }

  /**
     * set show project teams drawer
     * @param {*} state Type boolean
     */
  setShowProjectTeamsDrawer = state => {
    this.showProjectTeamsDrawer = state
  }

}

decorate(ProjectTeamsStore, {
  // Observables
  teamList: observable,
  showAddTeamMemberModal: observable,
  isLoading: observable,
  projectRoles: observable,
  // Actions
  toggleShowAddTeamMemberModal: action,
  sendInvite: action,
  setAppLoading: action,
  clearTeamList: action,
  setShowProjectTeamsDrawer: action,
  showProjectTeamsDrawer: observable,
  clearProjectRoles: action,
  setTeamList : action,
  getProjectTeams: action,
  getProjectRoles: action,
  isFetchingPrjTeam : observable
})

export default new ProjectTeamsStore()