import { Button, Form, Input, message, Modal, Select, Tooltip } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import validator from '@/validator'
import { HelpButton } from '@/components/elements'
const { TextArea } = Input
const { Option } = Select;

const ModalAddProject = ({ projectStore, organizationStore, usersStore, history, commonStore }) => {
  const { t } = useTranslation();
  moment.locale(`${commonStore.language}`)
  const [form] = Form.useForm();
  const AddForm = props => {
    const [projectionList, setProjectionList] = useState([])
    const [listOrganizations, setListOrganizations] = useState([])
    const [orgSelected, setOrgSelected] = useState()
    const [projectTemplate, setProjectTemplate] = useState()
    const [flag, setFlag] = useState(false)

    useEffect(() => {
      if (orgSelected) {
        form.resetFields(['template'])
        organizationStore.clearOrganizationTemplate()
        setFlag(false)
        organizationStore.setLoadingProgress(true)
        organizationStore.getOrganizationTemplates({ organization: orgSelected }).then(res => {
        })
          .catch(err => console.log(err))
          .finally(() => {
            organizationStore.setLoadingProgress(false)
            setFlag(true)
          })
      }
    }, [orgSelected])

    useEffect(() => {
      if (projectStore.showAddProjectModal) {
        projectStore.setLoadingProgress(true)
        organizationStore.getOrganizationUserBelongCreateNewProject().then(res => {  
          projectStore.setLoadingProgress(false)
          setListOrganizations(res) 
        })
        .catch(err => {
          projectStore.setLoadingProgress(false)
          console.log(err)
          if (err.status === 401) {
            return history.push("/auth/login")
          }
        })
      }
      return () => {
        organizationStore.clearOrgUserBelongList()
        projectStore.setDataTemplateChecked({})
      }
    }, [])



    useEffect(() => {
      if (projectStore.showAddProjectModal) {
        form.resetFields()
        fetchSearchProjection('4326', data => {
          setProjectionList(data)
          const pr = data.filter(d => d.code === '4326');
          projectStore.setProjectionDetail(pr)
        });
      }
    }, [projectStore.showAddProjectModal])


    let timeout;
    let currentValue;
    const fetchSearchProjection = (value, callback) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      currentValue = value;

      const searchProjection = () => {
        projectStore.getSearchProjection(value)
          .then(() => {
            if (currentValue === value) {
              const  results = projectStore.projectionList;
              const data = [];
              if (typeof results !== 'undefined') {
                results.forEach(r => {
                  data.push(r);
                });
              }
              callback(data);
            }
          });
      }
      timeout = setTimeout(searchProjection, 300);
    }

    const handleSearch = value => {
      if (value) {
        fetchSearchProjection(value, data => {
          setProjectionList(data)
        });
      } else {
        setProjectionList([]);
      }
    }

    const handleChange = (value) => {
      const pr = projectionList.filter(d => d.code === value);
      projectStore.setProjectionDetail(pr)
    }

    const options = projectionList.map(d => <Option key={d.code}>{'EPSG:' + d.name}</Option>);
    return (
      <Form form={form} name="add-project" layout="vertical">
        <Form.Item label={t('title')} name="name" rules={[
          {
            required: true,
            message: t('please-input-the-title-of-project'),
          },
          { validator: validator.validateEmptyString },
        ]}>
          <Input
            placeholder={t('input-project-title')} />
        </Form.Item>
        <Form.Item label={t('organization')} name="organization" rules={[
          {
            required: true,
            message: t('please-select-an-organization-of-project'),
          },
        ]}
        >
          <Select
            placeholder={t('please-select-an-organization')}
            allowClear={true}
            onChange={v => setOrgSelected(v)}
          >
            {
              listOrganizations ? listOrganizations.map((v, i) => {
                return (
                  <Option key={v.id} value={v.id} style={{ textTransform: 'uppercase' }} >{v.name}</Option>
                )
              }) : <Option>{t('no-organization-found')}</Option>
            }
          </Select>
        </Form.Item>
        {
          flag && orgSelected && (
            <Form.Item label={t('project-template')} name="template" rules={[
              {
                required: false,
                message: t('please-select-an-project-template'),
              },
            ]}
            >
              <Select
                showSearch
                placeholder={t('please-select-an-template')}
                allowClear={true}
                onChange={v => setProjectTemplate(v)}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  organizationStore.organizationTemplates ? organizationStore.organizationTemplates.map((v, i) => {
                    return (
                      <Option key={v.id} value={v.id} >{v.name}</Option>
                    )
                  }) : <Option>{t('no-template-found')}</Option>
                }
              </Select>
            </Form.Item>
          )
        }
        {
          (!projectTemplate || !orgSelected) && (
            <>
              <Form.Item
                label={t('coordinate-system')}
                name="coordinateSystem"
                initialValue={'4326'}
                rules={[
                  {
                    required: true,
                    message: t('please-choose-coordinate-system'),
                  },
                  { validator: validator.validateEmptyString },
                ]}>
                <Select
                  showSearch
                  placeholder={t('enter-country-code-or-name-coordinate-system')}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={val => handleSearch(val)}
                  onChange={val => handleChange(val)}
                  notFoundContent={null}
                >
                  {options}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('elevation-system')}
                initialValue={'None'}
                name="elevationSystem" rules={[
                  {
                    required: false,
                    message: t('please-choose-elevation-system'),
                  },
                  { validator: validator.validateEmptyString },
                ]}>
                <Select className='selectdrp'>
                  <Option value="None">None</Option>
                  <Option value="N2000">N2000</Option>
                  <Option value="N60">N60</Option>
                  <Option value="EGM96">EGM96</Option>
                  <Option value="EGM2008">EGM2008</Option>
                  <Option value="NN2000">NN2000</Option>
                </Select>
              </Form.Item>
            </>
          )
        }
        <Form.Item label={t('description')} name="desc" rules={[
          { validator: validator.validateEmptyString },
        ]} >
          <TextArea
            rows={5}
            placeholder={t('input-description')}
            type="textarea" />
        </Form.Item>
      </Form>
    )
  }

  const onCancel = () => {
    projectStore.setShowAddProjectModal(false)
    projectStore.setProjectionDetail([])
  }

  const onCreate = () => {
    form.validateFields().then(values => {
      if (values.template) {
        const payload = {
          desc: values?.desc,
          organization: values?.organization,
          name: values?.name,
          project: values?.template
        }
        projectStore.setLoadingProgress(true)
        // projectStore.createProjectTemplate(payload.project, payload)
        projectStore.duplicateProject(payload.project, { ...payload})
          .then(res => {
            if (res?.error) {
              projectStore.setLoadingProgress(false)
              message.error(t(res?.error))
              return
            }
            projectStore.setLoadingProgress(false)
            projectStore.setShowAddProjectModal(false)
            window.location.replace(`/project/${res.id}`)
            message.success(t('project-created-successfully'))
          })
      } else {
        var v = Object.assign(values, {
          coordinateSystem: projectStore.projectionDetail.length > 0 ? projectStore.projectionDetail[0].code : '4326'
        })
        v.tilesetData = {
          coordinateSystem:
            projectStore.projectionDetail.length > 0 ?
              {
                code: projectStore.projectionDetail[0].code,
                name: projectStore.projectionDetail[0].name,
                proj4: projectStore.projectionDetail[0].proj4,
                wkt: projectStore.projectionDetail[0].wkt,
                unit: projectStore.projectionDetail[0].unit.split(' ')[0]
              }
              :
              false
        }
        projectStore.setLoadingProgress(true)
        projectStore.createProject({ ...v, ...projectStore.dataTemplateChecked })
          .then(res => {
            if (res?.data?.error) {
              projectStore.setLoadingProgress(false)
              message.error(t(res.data.error))
              return
            }
            message.success(t('project-created-successfully'))
            window.location.replace(`/project/${res.data.project.id}`)
          })
      }
    });
  }

  return (
    <Modal
      visible={projectStore.showAddProjectModal}
      title={
        <div className="help-btn-wrapper">
            {t('create-a-new-project')}
            <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
              <div>
                <HelpButton helppage={'create_new_project'} hovered={'black'}/>
              </div>
            </Tooltip>
        </div>
      }
      okText={t('commons.create')}
      cancelText={t('commons.cancel')}
      onCancel={onCancel}
      onOk={onCreate}
      maskClosable={false}
      footer={
        <div style={{ textAlign: 'right' }}  >
          <Button key="back" onClick={onCancel}>
            {t('commons.cancel')}
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={projectStore.isAppLoading}
            onClick={onCreate}>
            {t('create-new-project')}
          </Button>
        </div>
      }>
      <AddForm />
    </Modal>
  )
}

export default withRouter(inject('projectStore', 'usersStore', 'organizationStore', 'commonStore')(observer(ModalAddProject)))
