import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from 'antd'
import { LocationWrapper } from './style'
import { Color, PinBuilder, ScreenSpaceEventType, HorizontalOrigin, VerticalOrigin, ScreenSpaceEventHandler, CallbackProperty, Cartographic } from 'cesium'
import { Label, Entity, LabelCollection } from 'resium'
import uuid from 'uuid'
import { clickPoint, getCurrentCamera } from '@/helper'
import { Trans, useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect'

const LocationControl = ({ feedbackStore, projectStore, viewer }) => {
    const { t } = useTranslation();
    const [locationView, setLocationView] = useState(null)
    const [pointPosition, setPointPosition] = useState(false)
    const [customLine] = useState(new CustomLine())
    const [previewPoint, setPreviewPoint] = useState([])
    function CustomLine() {
        this.positions = [];
        this.markers = [];
    }

    //Function add point click
    CustomLine.prototype.addPoint = function (resultClick) {
        var that = this;
        var pickedObject = resultClick.pickedObject
        var position = resultClick.position
        var n = this.positions.length;
        this.positions[n] = {
            pickedObject: pickedObject,
            position: position
        };

        var marker = viewer.current.cesiumElement.entities.add({
            position: new CallbackProperty(function () {
                if (that.positions && that.positions[n] && that.positions[n].position) {
                    return that.positions[n].position;
                }
            }, false),
            point: {
                pixelSize: 5,
                color: Color.RED,
                outlineColor: Color.WHITE,
                outlineWidth: 2,
            },
        })
        this.markers.push(marker);
    };

    //function remove point and line
    CustomLine.prototype.removeAll = function () {
        if (this.markers && this.markers.length > 0) {
            for (var i = 0; i < this.markers.length; i++) {
                if (viewer && viewer.current && viewer.current.cesiumElement)
                    viewer.current.cesiumElement.entities.remove(this.markers[i]);
            }
            this.markers = [];
            this.positions = [];
        }
    };
    const labelDefault = {
        font: '12px monospace',
        showBackground: true,
        horizontalOrigin: HorizontalOrigin.CENTER,
        verticalOrigin: VerticalOrigin.TOP,
        disableDepthTestDistance: Number.POSITIVE_INFINITY
    }

    /**
     * Effect when pointPosition change
     */
    useEffect(() => {
        if (pointPosition) {
            setLocationView({
                label: (
                    <Label
                        {...labelDefault}
                        key={uuid()}
                        position={pointPosition.location}
                    //text={topicStore.viewingTopicForm.name}
                    />
                ),
                pin: (
                    <Entity
                        key={uuid()}
                        name="Blank red pin"
                        position={pointPosition.location}
                        billboard={{
                            image: new PinBuilder().fromColor(Color.RED, 24).toDataURL(),
                            verticalOrigin: VerticalOrigin.BOTTOM,
                            disableDepthTestDistance: Number.POSITIVE_INFINITY
                        }}
                    />
                ),
            })
            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
    }, [pointPosition])


    /**
     * Function cancel save location and close location control
     */
    const handleCancel3DLocation = () => {
        if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        feedbackStore.setShowAddLocationControl(false)
        feedbackStore.setlocationControlData(false)
        projectStore.setCleanMode(false)
        setPointPosition(false)
        feedbackStore.setShowFeedbackEditorDrawer(true)
        feedbackStore.setIsEditingLocationControl(false)
    }

    /**
     * Function save new location for feedback editor
     * @param {*} e 
     */
    const handleClickSaveLocation = (e) => {
        e.preventDefault()
        const _location3D = getCurrentCameraData(locationView.pin.props.position)
        feedbackStore.setSelectedFeedbackEditor({
            ...feedbackStore.selectedFeedbackEditor,
            viewLocation: _location3D
        })
        feedbackStore.setShowAddLocationControl(false)
        projectStore.setCleanMode(false)
        feedbackStore.setShowFeedbackEditorDrawer(true)
        feedbackStore.setlocationControlData(false)
        feedbackStore.setIsEditingLocationControl(false)
    }
    const getCurrentCameraData = (position) => {
        let camData = getCurrentCamera(viewer)
        let _location3D = {
            location: position,
            camData: camData
        }
        return _location3D

    }
    async function add3DLocation(posObj) {
        var cartesian = posObj.position
        let _location3D = {
            ...feedbackStore.selectedFeedbackEditor.viewLocation,
            location: cartesian
        }
        setPointPosition(_location3D)
    }
    useEffect(() => {
        if (!feedbackStore.showAddLocationControl) {
            setLocationView(null)
            feedbackStore.setShowAddLocationControl(false)
            feedbackStore.setlocationControlData(false)
            if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
        }
    }, [feedbackStore.shownFeedbackAnswer])

    /**   
     * Effect when location control show
     */
    useEffect(() => {
        var handler = new ScreenSpaceEventHandler(
            viewer.current.cesiumElement.canvas
        )
        if (feedbackStore.showAddLocationControl) {

            if (feedbackStore.selectedFeedbackEditor && feedbackStore.selectedFeedbackEditor.viewLocation && feedbackStore.selectedFeedbackEditor.viewLocation.location) {
                setPointPosition(feedbackStore.selectedFeedbackEditor.viewLocation)
            }
            handler.setInputAction(function (event) {
                try {

                    let resultClick = clickPoint(viewer.current.cesiumElement, event.endPosition)

                    if (resultClick && resultClick.position) {
                        const cartographic = Cartographic.fromCartesian(
                            resultClick.position
                          );
                        // if (cartographic.height >= -0.1) {
                            var n = customLine.positions.length;
                            if (n === 0) {
                                customLine.addPoint(resultClick);
                            } else {
                                customLine.positions[n - 1].position = resultClick.position;
                                customLine.positions[n - 1].pickedObject = resultClick.pickedObject
                            }
                            setPreviewPoint(resultClick.position)
                        // }
                    }
                } catch (error) { }
            }, ScreenSpaceEventType.MOUSE_MOVE)
            handler.setInputAction(function (movement) {
                if (!viewer.current.cesiumElement.scene) return
                if(isMobile || isTablet) {
                    let resultClick = clickPoint(viewer.current.cesiumElement, movement.position)
                    if (resultClick && resultClick.position) {
                        var n = customLine.positions.length;
                        if (n === 0) {
                            customLine.addPoint(resultClick);
                        } else {
                            customLine.positions[n - 1].position = resultClick.position;
                            customLine.positions[n - 1].pickedObject = resultClick.pickedObject
                        }
                        setPreviewPoint(resultClick.position)
                    }
                }
                if (customLine.positions && customLine.positions.length) {

                    let resultClick = customLine.positions[customLine.positions.length - 1]
                    if (resultClick && resultClick.position) {
                        add3DLocation(resultClick)
                        if (feedbackStore.shownFeedbackAnswer && feedbackStore.shownFeedbackAnswer.show) {
                            let camData = getCurrentCamera(viewer)
                            camData.position = resultClick.position
                            feedbackStore.setlocationControlData(camData)
                        }
                    }

                }
            }, ScreenSpaceEventType.LEFT_CLICK)
        }
        return () => {
            if (handler) {
                handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
                handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
            }
            setLocationView(null)
            customLine.removeAll()
            feedbackStore.setShowAddLocationControl(false)
            feedbackStore.setlocationControlData(false)
        }
    }, [])

    return (
        <>
            {feedbackStore.showAddLocationControl && !(feedbackStore.shownFeedbackAnswer && feedbackStore.shownFeedbackAnswer.show) ?
                <> <LocationWrapper>
                    <div>{t('feedback-editor-location')}</div>
                    <div>
                        <Button loading={feedbackStore.isLoading} onClick={(e) => handleClickSaveLocation(e)} type="primary" disabled={pointPosition ? false : true}>
                            {t('commons.save')}
                        </Button>
                        <Button onClick={handleCancel3DLocation} style={{ marginLeft: 8 }}>{t('commons.cancel')}</Button>
                    </div>
                </LocationWrapper>
                    <LabelCollection>{locationView ? locationView.label : ""}</LabelCollection>
                </> : ''
            }
            {locationView ? locationView.pin : ""}
        </>
    )
}
export default inject('feedbackStore', 'projectStore')(observer(LocationControl))