import { CloseCircleOutlined, ColumnWidthOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, Drawer, Skeleton, Checkbox, Divider, Row, Col, Input, Select, Spin, Empty, Form , notification} from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import { ProjectLinkPageWrapper, SearchWrapper, CustomSearch, CustomButton, SpinDrawer } from './CustomStyled'

import { SVGIcon } from '@/components/elements';
import { ReactComponent as ResourceIfcIcon } from '../../../../assets/svgs/resource-ifc-v2.svg'
import { ReactComponent as ResourceXmlIcon } from '../../../../assets/svgs/resource-xml-v2.svg'
import { ReactComponent as ResourceWmsIcon } from '../../../../assets/svgs/resource-wms.svg'
import { ReactComponent as Resource3DIcon } from '../../../../assets/svgs/resource-3d-v2.svg'
import { ReactComponent as Resource3DTilesIcon } from '../../../../assets/svgs/resource-3dtiles.svg'
import { ReactComponent as ResourcePointCloudIcon } from '../../../../assets/svgs/resource-point-cloud.svg'

import DocFile from '../../../../assets/form-elems/Resource DOC.png'
import JPGFile from '../../../../assets/form-elems/Resource JPG.png'
import PDFFile from '../../../../assets/form-elems/Resource PDF.png'
import PNGFile from '../../../../assets/form-elems/Resource PNG.png'
import PPTFile from '../../../../assets/form-elems/Resource PPT.png'
import TIFFile from '../../../../assets/form-elems/Resource TIFF.png'
import TXTFile from '../../../../assets/form-elems/Resource TXT.png'
import XLSFile from '../../../../assets/form-elems/Resource XLS.png'
import GenericFile from '../../../../assets/form-elems/generic-doc.png'

import { ReactComponent as DWG } from '../../../../assets/svgs/dwg.svg'
import { ReactComponent as PDF } from '../../../../assets/svgs/pdf.svg'
import { ReactComponent as DXF } from '../../../../assets/svgs/dxf.svg'
import { ReactComponent as IMG } from '../../../../assets/svgs/img.svg'
import { ReactComponent as GENERIC } from '../../../../assets/svgs/generic.svg'

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const AddProjectLink = ({ projectStore, commonStore, usersStore, onCancel , organizationStore }) => {
    const { t } = useTranslation();
    moment.locale(`${commonStore.language}`)
    const { projectId } = useParams();
    const [checkedList, setCheckedList] = useState([]);
    const [listOption, setListOption] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [searchValue, setSearchValue] = useState('')
    const [listProjects, setListProjects] = useState([])
    const [prjSelected, setPrjSelected] = useState('');
    const [treeLoading, setTreeLoading] = useState(false);
    const ref = useRef()
    //#region event for mouse and touch to resize drawer panel
    const setModelIcon = (item) => {
        switch (item.type) {
            case 'landxmlBackground':
            case 'landxml':
                return <ResourceXmlIcon />
            case 'ifc':
                return <ResourceIfcIcon />
            case 'model3d':
            case 'kmz':
                return <Resource3DIcon />
            case 'cloudpoint':
                return <ResourcePointCloudIcon />
            case 'e57':
                return <ResourcePointCloudIcon />
            case 'cad':
                return <Resource3DTilesIcon />
        }
        switch (item.sourceType) {
            case 'WMS':
            case 'WFS':
                return <ResourceWmsIcon />
            case 'City3DDB':
                return <ResourceWmsIcon />
            case 'external':
                return <Resource3DTilesIcon />
        }
        const ext = item && item.modelId && item.ext && item.ext.toLowerCase()

        switch (ext) {
            case '.doc':
            case '.docx':
                return <img src={DocFile} />;
            case '.jpg':
            case '.jpeg':
                return <img src={JPGFile} />;
            case '.pdf':
                return <img src={PDFFile} />
            case '.png':
                return <img src={PNGFile} />
            case '.ppt':
            case '.pptx':
                return <img src={PPTFile} />
            case '.tiff':
                return <img src={TIFFile} />
            case '.txt':
                return <img src={TXTFile} />
            case '.xls':
            case '.xlsx':
                return <img src={XLSFile} />
            default:
                return <img src={GenericFile} />
        }
        let isImage = false
        if (ext && ext.match(/.(jpg|jpeg|png|gif|svg)$/i))
            isImage = true
        if (isImage) {
            return <IMG />
        }
        switch (ext) {
            case '.DWG':
                return <DWG />
            case '.PDF':
                return <PDF />
            case '.DXF':
                return <DXF />
            default:
                return <GENERIC />
        }
    }
    useEffect(() => {
        onCloseProjectLink()
        if (projectStore.currentAddTab === 'ProjectLink') {
            setTreeLoading(true)
            projectStore.getAllProjects().then(() => {
                setTreeLoading(false)
            }).catch(() => setTreeLoading(false))
        }
    }, [projectStore.currentAddTab])
    
    const getListOption = () => {
        let listCheckBox = [];
        projectStore.listModelProject.map(item => {
            if (!item.isDeleted) {
                listCheckBox.push({
                    ...item,
                    label: item.title,
                    value: item.id
                })
            }
        })
        return listCheckBox
    }

    useEffect(() => {
        if (projectStore.listModelProject.length > 0) {
            let listCheckBox = getListOption();
            setListOption(listCheckBox)
        } else {
            setListOption([])
        }
    }, [projectStore.listModelProject])

    const onCloseProjectLink = () => {
        projectStore.setListModelProject([])
        setListOption([]);
        setIndeterminate(false);
        setCheckAll(false);
        setSearchValue('');
        setListProjects([]);
        setPrjSelected('');
        setTreeLoading(false);
    };

    const onChangeCheckBoxGroup = (list) => {
        setIndeterminate(!!list.length && list.length < listOption.length);
        setCheckAll(list.length === listOption.length);
    };

    const onChangeCheckBox = (e) => {
        let checked = e.target.value;
        if (checkedList.includes(checked)) {
            setCheckedList(checkedList.filter(x => x !== checked))
        } else {
            setCheckedList([...checkedList, checked])
        }
    };


    const onCheckAllChange = (e) => {
        const values = listOption.map(record => record.value)
        setCheckedList(e.target.checked ? values : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const clearSearch = () => {
        setSearchValue('')
    }
    const onChangeSearch = (e) => {
        setSearchValue(e.target.value)
    }

    const getListModel = (id) => {
        if (!id) {
            setCheckedList([]);
            setCheckAll(false);
            setIndeterminate(false);
            setListOption([])
            return
        }
        setTreeLoading(true)
        projectStore.getListModelProject(id).then(() => {
            setTreeLoading(false)
        }).catch(() => setTreeLoading(false))
    }

    useEffect(() => {
        let listCheckBox = getListOption();
        if (listCheckBox.length === 0) return;
        setListOption(listCheckBox.filter(el => el?.label?.toLowerCase()?.includes(searchValue?.toLowerCase())));
        if (checkedList.length > 0) {
            setIndeterminate(true);
            setCheckAll(false)
        } else if (checkedList.length === listCheckBox.length) {
            setIndeterminate(false);
            setCheckAll(true)
        }
    }, [searchValue])

    useEffect(() => {
        const getListProjectOwner = () => {
            if (projectStore?.projectList?.length < 1) return;
            const values = projectStore.listProjectLink.map(record => record?.link?.id)
            let projectList = toJS(projectStore.projectList)
                .filter(item => item.project !== null)
                .filter(item => item?.projectrole?.role === 'project_owner')
                .filter(item => item?.user?._id === item?.project?.user)
                .filter(item => (!item.project.isOrganizationTemplate && !item.project.isSystemTemplate))
                .filter(item => !values.includes(item?.project?._id))
                .filter(item => projectId !== item?.project?._id)
            return projectList
        }
        setListProjects(getListProjectOwner())
    }, [projectStore.projectList])

    const hanldeSubmit = async () => {
        if (prjSelected) {
            let projectLink = listProjects.filter(x => x?.project?._id === prjSelected)[0]
            let params = {
                link: prjSelected,
                model3ds: checkedList,
                title: projectLink?.project?.name,
                project: projectId,
                visibleData: [{
                    userId: usersStore.currentUser?.id,
                    isVisibleClip: true,
                    isVisible: true,
                    isVisible4D: true
                }]
            }
            projectStore.setLoadingProgress(true)
            await projectStore.createProjectLink(params).then(() => {
                projectStore.setLoadingProgress(false)
                projectStore.updateProjectStorage(projectId)
                onCloseProjectLink()
                onCancel()
            })
        }
    }

    const searchProjectlink = (value) => {
        if (value && value.includes('http') && value.includes('project/')) {
            let project = value.split('project/')[1]
            if (project) {
                let projectId = project.split('/')[0];
                if (projectId && listProjects.find(x => x?.project?._id === projectId)) {
                    setPrjSelected(projectId)
                    if (ref.current)
                        ref.current.blur()
                    return
                }
            }
            notification.open({
                message: t('invalid-url'),
                icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                duration: 1.5,
            })
        }
    }
    return (
        <Form>
            <SpinDrawer >
                <Spin tip={t('loading-model')} spinning={treeLoading}>
                    <ProjectLinkPageWrapper>
                        <div>
                            <>
                                <Row style={{ width: '100%' }}>
                                    <Col span={24}>
                                        <Select
                                            ref={ref}
                                            placeholder={t('select-a-project')}
                                            allowClear={true}
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={v => setPrjSelected(v)}
                                            value={prjSelected}
                                            style={{ width: 'calc(100% - 65px)' }}
                                            onSearch={searchProjectlink}
                                        >
                                            {
                                                listProjects ? listProjects.map((v) => {
                                                    return (
                                                        <Option key={v?.project?._id} value={v?.project?._id} >{v?.project?.name}</Option>
                                                    )
                                                }) : <Option>{t('no-project-found')}</Option>
                                            }
                                        </Select>
                                        <Button type="primary" onClick={() => getListModel(prjSelected)}>
                                           {t('load')}
                                        </Button>
                                    </Col>
                                </Row>

                                <Divider />
                                <SearchWrapper>
                                    <CustomSearch>
                                        <Input
                                            value={searchValue}
                                            onChange={onChangeSearch}
                                            placeholder={t('search-for-an-entry')}
                                            prefix={<SearchOutlined />}></Input>
                                    </CustomSearch>
                                    {searchValue && (
                                        <CustomButton onClick={() => clearSearch()}>
                                            <CloseOutlined />
                                        </CustomButton>
                                    )}
                                </SearchWrapper>
                                <Divider />
                                {(listOption && listOption.length) ? (<Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} style={{ fontWeight: "bold" }}>
                                    {`${t('check-all')}`}
                                </Checkbox>) : null}

                                {(!listOption || !listOption.length) ? (<Empty description={<span>{t('no-data')}</span>} />) : null}
                                <Row style={{ overflowY: "scroll", maxHeight: 500 }}>
                                    <CheckboxGroup value={checkedList} onChange={onChangeCheckBoxGroup} style={{ marginLeft: 24 }} >
                                        {listOption?.map(item => {
                                            return <Col span={24} key={item.value}>
                                                <Checkbox value={item.value} onChange={onChangeCheckBox}> <SVGIcon
                                                    content={setModelIcon(item)}
                                                    width={20}
                                                    height={20}
                                                />{' '}{item.label}</Checkbox>
                                            </Col>
                                        })}
                                    </CheckboxGroup>
                                </Row>
                            </>
                        </div>
                    </ProjectLinkPageWrapper>
                </Spin>
            </SpinDrawer>
            <Row justify='end'>
                <Button style={{ marginRight: 8 }} icon={<CloseCircleOutlined />} onClick={() => {
                    onCloseProjectLink()
                    onCancel()
                }}>
                    {t('commons.cancel')}
                </Button>
                <Button style={{ marginRight: 8 }} icon={projectStore.currentAddTab === 'ProjectLink' ? <PlusOutlined /> : <EditOutlined />} type="primary" onClick={() => hanldeSubmit()}>
                    {t('commons.save')}
                </Button>
            </Row>
        </Form>
    )
}

export default inject(
    'projectStore',
    'usersStore',
    'projectSettingStore',
    'commonStore',
    'organizationStore'
)(observer(AddProjectLink))
