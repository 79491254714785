import React from 'react'
import styled from 'styled-components'

import singleLine from '../../../assets/form-elems/singleLine@2x.png'
import multiLine from '../../../assets/form-elems/multiLine@2x.png'
import email from '../../../assets/form-elems/email@2x.png'
import dropdown from '../../../assets/form-elems/dropdown@2x.png'
import radioGroup from '../../../assets/form-elems/radioButton@2x.png'
import checkboxGroup from '../../../assets/form-elems/checkbox@2x.png'
import smileyButton from '../../../assets/form-elems/smiley@2x.png'
import smiley1 from '../../../assets/form-elems/smiley1.png'
import smiley2 from '../../../assets/form-elems/smiley2.png'
import smiley3 from '../../../assets/form-elems/smiley3.png'
import smiley4 from '../../../assets/form-elems/smiley4.png'
import smiley5 from '../../../assets/form-elems/smiley5.png'
import iframeicon from '../../../assets/form-elems/iframe_outline_icon_11.png'
import imageicon from '../../../assets/form-elems/image-icon-11.png'
import urlicon from '../../../assets/form-elems/url-icon-11.png'
import readonlyicon from '../../../assets/form-elems/readonly-icon-11.png'

export const basicFields = [
    {       
        dynamicFieldId: null, // generated automatically when answer feedback (id of answer)
        name: null, // generated automatically when create control
        title: 'Single line',
        className: 'singleLine',
        icon: {
            component: singleLine,
            width: 10,
            height: 4,
        },
        isRequired: false,
        description: '',
        placeHolder: 'Write something...',
        minLength: 0,
        maxLength: 0,
        order: 0,
        value: null
    },
    {      
        dynamicFieldId: null,
        name: null,
        title: 'Multi line',
        className: 'multiLine',
        icon: {
            component: multiLine,
            width: 11,
            height: 7,
        },
        isRequired: false,
        description: '',
        placeHolder: 'Write something...',
        minLength: 0,
        maxLength: 0,
        order: 1,
        value: null
    },
    {       
        dynamicFieldId: null,
        name: null,
        title: 'Email',
        className: 'email',
        icon: {
            component: email,
            width: 12,
            height: 10,
        },
        isRequired: false,
        description: '',
        placeHolder: 'Input email',
        order: 2,
        value: null
    },
    {       
        dynamicFieldId: null,
        name: null,
        choices: ["item1", "item2", "item3", "item4"],
        title: 'Dropdown',
        className: 'dropdown',
        icon: {
            component: dropdown,
            width: 8,
            height: 4,
        },
        isRequired: false,
        description: '',
        placeHolder: 'Select item',
        order: 3,
        value: null
    },
    {       
        dynamicFieldId: null,
        name: null,
        choices: ['item1', 'item2', 'item3'],
        title: 'Radio Group',
        className: 'radioGroup',
        icon: {
            component: radioGroup,
            width: 14,
            height: 14,
        },
        isRequired: false,
        description: '',
        direction: 'horizontal',
        order: 4,
        value: null
    },
    {        
        dynamicFieldId: null,
        name: null,
        choices: ['item1', 'item2', 'item3'],
        title: 'Checkbox Group',
        className: 'checkboxGroup',
        icon: {
            component: checkboxGroup,
            width: 11.5,
            height: 9,
        },
        isRequired: false,
        description: '',
        direction: 'horizontal',
        order: 5,
        value: null
    },
    {       
        dynamicFieldId: null,
        name: null,
        choices: [smiley1, smiley2, smiley3, smiley4, smiley5],
        title: 'Smiley ',
        className: 'smileyButton',
        icon: {
            component: smileyButton,
            width: 14,
            height: 14,
        },
        isRequired: false,
        description: '',
        direction: 'horizontal',
        order: 6,
        value: null
    },
    {      
        dynamicFieldId: null,
        name: null,
        title: 'Readonly line',
        className: 'textReadonly',
        icon: {
            component: readonlyicon,
            width: 11,
            height: 11,
        },
        isReadonly: true,
        description: '',
        order: 7,
        value: null
    },
    {      
        dynamicFieldId: null,
        name: null,
        title: 'Link Url',
        className: 'urlReadonly',
        icon: {
            component: urlicon,
            width: 14,
            height: 14,
        },
        isReadonly: true,
        description: '',
        order: 8,
        value: null
    },
    {      
        dynamicFieldId: null,
        name: null,
        title: 'Image',
        className: 'image',
        icon: {
            component: imageicon,
            width: 11,
            height: 11,
        },
        isReadonly: true,
        description: '',
        order: 9,
        value: null,
        uploadId: null
    },
    {      
        dynamicFieldId: null,
        name: null,
        title: 'Iframe',
        className: 'Iframe',
        icon: {
            component: iframeicon,
            width: 11,
            height: 11,
        },
        isReadonly: true,
        description: '',
        order: 10,
        value: null,
        uploadId: null
    },
]

