import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Layout,
  message,
  Row,
  Table,
} from 'antd'
import en from 'antd/lib/date-picker/locale/en_US'
import es from 'antd/lib/date-picker/locale/es_ES'
import fi from 'antd/lib/date-picker/locale/fi_FI'
import sv from 'antd/lib/date-picker/locale/sv_SE'
import vi from 'antd/lib/date-picker/locale/vi_VN'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import AdminTemplate from '@/components/layout/AdminTemplate'
import { CustomTopTitle, CustomContentAdminWrapper, HeaderAdmin } from '../style'
import { CustomButton, FilterWrapper, LicenseTypeWrapper } from './style'
import settings from '@/siteConfig'

const CheckboxGroup = Checkbox.Group
const firstDayOfTheMonth = moment().startOf('month').toString()
const lastDayOfTheMonth = moment().endOf('month').toString()

const IFCEngineUsage = props => {
  const { commonStore, organizationStore, adminStore } = props
  const { t } = useTranslation()
  moment.locale(`${commonStore.language}`)
  const [isLoadingTableUsers, setisLoadingTableUsers] = useState(
    organizationStore.isLoading
  )
  const [form] = Form.useForm()
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(true)
  const history = useHistory()
  const { queryParam } = useParams()
  const dateFormat = 'YYYY-MM-DDTHH:mm:ss'
  const defaultCheckedList = adminStore.licenseTypes.map(c => c.id)
  const [checkedList, setCheckedList] = useState([])
  const isMobile = useMediaQuery({query: '(max-width: 600px)'})
  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? defaultCheckedList : [])
    form.setFieldsValue({
      type: e.target.checked ? defaultCheckedList : [],
    })
    setIndeterminate(false)
    setCheckAll(e.target.checked)
    adminStore.setSelectedFilter(
      'type',
      e.target.checked ? defaultCheckedList : []
    )
  }

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    if (queryStringParams.startDate || queryStringParams.endDate) {
      let _startDate = moment(queryStringParams.startDate).isValid()
        ? queryStringParams.startDate
        : undefined
      let _endDate = moment(queryStringParams.endDate).isValid()
        ? queryStringParams.endDate
        : undefined
      adminStore.setSelectedFilter('startDate', _startDate)
      adminStore.setSelectedFilter('endDate', _endDate)
      form.setFieldsValue({
        startDate: moment(queryStringParams.startDate).isValid()
          ? moment(queryStringParams.startDate)
          : undefined,
        endDate: moment(queryStringParams.endDate).isValid()
          ? moment(queryStringParams.endDate)
          : undefined,
      })
    }
    if (!queryStringParams.startDate && !queryStringParams.endDate) {
      form.setFieldsValue({
        startDate: moment(firstDayOfTheMonth),
        endDate: moment(lastDayOfTheMonth),
      })
      adminStore.setSelectedFilter(
        'startDate',
        moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
      )
      adminStore.setSelectedFilter(
        'endDate',
        moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss')
      )
    }
    commonStore.setCurrentPage('admin/ifcengineusage')
    adminStore.clearPaginationParams()
    let fetch = async () => {
      setisLoadingTableUsers(true)
      let _filterLicenseTypeOption
      await adminStore
        .getLicenseTypes()
        .then(res => {
          _filterLicenseTypeOption = res.data.map(elm => {
            return {
              id: elm.id,
              name: elm.name,
              type: elm.type,
              description: elm.description,
              checked: true,
            }
          })
        })
        .catch(err => {
          console.log(err)
          setisLoadingTableUsers(false)
        })
      await adminStore
        .getIFCEngineUsage({
          ...adminStore.selectedFilter,
          type: _filterLicenseTypeOption.map(c => c.id),
        })
        .then(res => {
          setisLoadingTableUsers(false)
          adminStore.setFilterLicenseTypeOption(_filterLicenseTypeOption || [])
          setCheckedList(_filterLicenseTypeOption.map(c => c.id))
          adminStore.setSelectedFilter(
            'type',
            _filterLicenseTypeOption.map(c => c.id)
          )
          form.setFieldsValue({
            type: _filterLicenseTypeOption.map(c => c.id),
          })
        })
        .catch(err => {
          console.log(err)
          setisLoadingTableUsers(false)
        })
    }
    fetch()
    return () => {
      adminStore.setFilterLicenseTypeOption([])
      adminStore.clearPaginationParams()
      adminStore.clearStatus()
    }
  }, [])

  const handeClearFilter = () => {
    setCheckAll(true)
    let param = {
      startDate: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
      type: defaultCheckedList,
    }
    adminStore.setSelectedFilter(
      'startDate',
      moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
    )
    adminStore.setSelectedFilter(
      'endDate',
      moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss')
    )
    setCheckedList(defaultCheckedList)
    // adminStore.clearSelectedFilter()
    form.resetFields()
    form.setFieldsValue({
      startDate: moment(firstDayOfTheMonth),
      endDate: moment(lastDayOfTheMonth),
      type: defaultCheckedList,
    })
    let v = adminStore.filterLicenseTypeOption.map(elm => {
      return {
        ...elm,
        checked: true,
      }
    })
    adminStore.setSelectedFilter('type', defaultCheckedList)
    adminStore.setFilterLicenseTypeOption(v)
    setisLoadingTableUsers(true)
    adminStore
      .getIFCEngineUsage(param)
      .then(res => {
        setisLoadingTableUsers(false)
      })
      .catch(err => {
        console.log(err)
        setisLoadingTableUsers(false)
      })
    return history.push(`/admin/ifcengineusage`)
  }

  const onChangeCheckbox = list => {
    setCheckedList(list)
    adminStore.setSelectedFilter('type', list)
    setIndeterminate(!!list.length && list.length < defaultCheckedList.length)
    setCheckAll(list.length === defaultCheckedList.length)
  }

  const selectCheckBox = (e, index) => {
    let data = adminStore.filterLicenseTypeOption
    data[`${index}`].checked = e.target.checked
    adminStore.setFilterLicenseTypeOption(data)
  }

  const handeApplyFilter = () => {
    form.validateFields().then(values => {
      if (
        !moment(adminStore.selectedFilter.startDate).isBefore(
          adminStore.selectedFilter.endDate
        )
      ) {
        message.error(t('end-date-must-be-greated-than-start-date'))
      } else {
        setisLoadingTableUsers(true)
        adminStore
          .getIFCEngineUsage(adminStore.selectedFilter)
          .then(res => {
            setisLoadingTableUsers(false)
          })
          .catch(() => setisLoadingTableUsers(false))
        return history.push(
          `/admin/ifcengineusage${
            adminStore.selectedFilter.startDate ||
            adminStore.selectedFilter.endDate
              ? '/'
              : ''
          }${
            adminStore.selectedFilter.startDate
              ? 'startDate=' + adminStore.selectedFilter.startDate
              : ''
          }${
            adminStore.selectedFilter.startDate &&
            adminStore.selectedFilter.endDate
              ? '&'
              : ''
          }${
            adminStore.selectedFilter.endDate
              ? 'endDate=' + adminStore.selectedFilter.endDate
              : ''
          }`
        )
      }
    })
  }

  const columns = [
    {
      title: t('month'),
      dataIndex: 'month',
      key: 'month',
      render: (record, obj) => {
        return (
          <span>
            {record ? `${t(record)}${obj.year ? ',' + obj.year : ''}` : ''}
          </span>
        )
      },
    },
    {
      title: t('total-users'),
      dataIndex: 'user',
      key: 'user',
      render: record => {
        return record ? record.length : 0
      },
    },
  ]

  return (
    <AdminTemplate title="IFCEngineUsage">
      <CustomContentAdminWrapper>
        <Row
          style={{
           
            overflow: 'initial',
            backgroundColor: '#fff',
          }}>
          <HeaderAdmin style={{width: '100%'}}>
            <Row style={{ width: '100%' }} justify="space-between" align="middle">
              <Col xs={24} sm={12}>
                <CustomTopTitle>
                  <div className="title">{t('ifc-engine-usage')}</div>
                  <div style={{ fontSize: '1.3rem', color: '#787e8f', textAlign: 'left' }}>
                    {t('set-the-conditions-to-apply-to-filter-the-entries')}
                  </div>
                </CustomTopTitle>
              </Col>
              <Col xs={24} sm={12}>
                <Row justify={isMobile?'start':"end"}>
                  <CustomButton
                    onClick={handeClearFilter}
                    style={{ marginRight: 5 }}>
                    {t('clear-all')}
                  </CustomButton>
                  <CustomButton onClick={handeApplyFilter} type="primary">
                    {t('apply')}
                  </CustomButton>
                </Row>
              </Col>
            </Row>
          </HeaderAdmin>
          <FilterWrapper>
            <Form style={{}} layout="horizontal" form={form}>
              <Row>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('please-select-start-date'),
                    },
                  ]}
                  label={t('start-date')}
                  name="startDate">
                  <DatePicker
                    locale={{
                      ...(commonStore.language === 'fi'
                        ? fi
                        : commonStore.language === 'sv'
                        ? sv
                        : commonStore.language === 'es'
                        ? es
                        : commonStore.language === 'vi'
                        ? vi
                        : en),
                      lang: {
                        ...(commonStore.language === 'fi'
                          ? fi
                          : commonStore.language === 'sv'
                          ? sv
                          : commonStore.language === 'es'
                          ? es
                          : commonStore.language === 'vi'
                          ? vi
                          : en
                        ).lang,
                        now: t('now'),
                        ok: t('commons.ok'),
                      },
                    }}
                    showTime
                    format={dateFormat}
                    onChange={(date, dateString) =>
                      adminStore.setSelectedFilter('startDate', dateString)
                    }
                  />
                </Form.Item>
                <Form.Item
                  style={{ marginLeft: isMobile?'0':'20px' }}
                  rules={[
                    {
                      required: true,
                      message: t('please-select-end-date'),
                    },
                  ]}
                  label={t('end-date')}
                  name="endDate">
                  <DatePicker
                    locale={{
                      ...(commonStore.language === 'fi'
                        ? fi
                        : commonStore.language === 'sv'
                        ? sv
                        : commonStore.language === 'es'
                        ? es
                        : commonStore.language === 'vi'
                        ? vi
                        : en),
                      lang: {
                        ...(commonStore.language === 'fi'
                          ? fi
                          : commonStore.language === 'sv'
                          ? sv
                          : commonStore.language === 'es'
                          ? es
                          : commonStore.language === 'vi'
                          ? vi
                          : en
                        ).lang,
                        now: t('now'),
                        ok: t('commons.ok'),
                      },
                    }}
                    showTime
                    format={dateFormat}
                    onChange={(date, dateString) =>
                      adminStore.setSelectedFilter('endDate', dateString)
                    }
                  />
                </Form.Item>
              </Row>
              <Row>
                <Col span={24}>{t('license-types')}</Col>
                <LicenseTypeWrapper>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}>
                    {t('select-all')}
                  </Checkbox>
                  <Divider />
                  <Form.Item name="type">
                    <CheckboxGroup
                      value={checkedList}
                      key="license-type"
                      onChange={onChangeCheckbox}>
                      {adminStore.filterLicenseTypeOption.map((type, index) => (
                        <Col span={6} key={type.id} className="filter-item">
                          <Checkbox
                            value={type.id}
                            key={type.id}
                            checked={type.checked}
                            onChange={e => selectCheckBox(e, index)}>
                            {type.name ? type.name : t('no-name')}
                          </Checkbox>
                        </Col>
                      ))}
                    </CheckboxGroup>
                  </Form.Item>
                </LicenseTypeWrapper>
              </Row>
            </Form>
          </FilterWrapper>
        </Row>
        <div style={{ padding: '24px 0', background: '#fff', textAlign: 'center' }}>
          <Table
            rowKey={record => `${record.month}-${record.year}`}
            size="default"
            bordered
            scroll={{ x: 1000 }}
            loading={isLoadingTableUsers}
            columns={columns}
            dataSource={adminStore.IFCEngineUsages}
            pagination={{
              showSizeChanger: true,
              pageSizeOptions: settings.adminPageSizeOptions,
              locale: { items_per_page: '' },
            }}
            locale={{ emptyText: t('no-data') }}
            onRow={record => ({
              onClick: event => {},
            })}
          />
        </div>
      </CustomContentAdminWrapper>
    </AdminTemplate>
  )
}

export default inject(
  'organizationStore',
  'commonStore',
  'adminStore'
)(observer(IFCEngineUsage))
