import React, { Fragment, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Drawer, Button, Select, Tooltip, Row, Col } from 'antd'
import {
  HomeOutlined,
  LogoutOutlined,
  SettingOutlined,
  LoginOutlined,
  BulbOutlined
} from '@ant-design/icons';
import CityDemoPanel from './CityDemoPanel'
import GpsArPanel from './GpsArPanel'
import { StyleToolbarUser } from './CustomStyled'
import { assetUrl } from '@/config'
// import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';
import { renderTermsPolicyHref } from '@/lib/projectLib';

const { Option } = Select

const MainDrawer = ({ commonStore, usersStore, history, projectStore, adminStore }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({})

  function handleChange(value) {
    commonStore.setCurrentMainDrawerPanel(value)
  }

  const DrawerHeader = () => {
    return (
      <div className="ant-drawer-title">
      {
        checkingFeatureRole("feature_projects") && 
        <Tooltip 
          title={t('commons.home')}
          overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
          <Button
            type="primary"
            icon={<HomeOutlined />}
            size={commonStore.buttonSize}
            onClick={() => {
              commonStore.setShowMainDrawer(false)
              history.push('/')
            }}
          />
        </Tooltip>
      }
        
        {/* <Tooltip title={'Portal ideas'}>
          <Button
            icon={<BulbOutlined/>}
            size={commonStore.buttonSize}
            style={{
              marginLeft: 10,
              backgroundColor: '#faad14',
              color: 'white',
              border: '1px solid transparent',
            }}
            onClick={() => {
              window.open('https://openbimtool.ideas.aha.io/', '_blank')
              window.focus()
            }}
          />
        </Tooltip> */}
        {/* <Select
          size={commonStore.buttonSize}
          defaultValue={commonStore.currentMainDrawerPanel}
          style={{ marginLeft: 10, width: 180 }}
          onChange={handleChange}>
          <Option value="cityDemo">City Demo</Option>
          <Option value="gpsDemo">Mobile GPS/AR Demo</Option>
          <Option value="toolDemo">Other Tools</Option>
        </Select> */}
      </div>
    )
  }

  const onClickLink = () => {
    commonStore.setShowMainDrawer(false)
  }

  const SelectedPanel = ({ currentPanel }) => {
    switch (currentPanel) {
      case 'cityDemo':
        return <CityDemoPanel />
      case 'gpsDemo':
        return <GpsArPanel />
      default:
        return ''
    }
  }
  const getData = () => {
    fetch(`${assetUrl}maintananceInfo.json`
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson)
      });
  }
  useEffect(() => {
    if (commonStore.showMainDrawer) {
      getData()
    }
  }, [commonStore.showMainDrawer])

  const handleLogout = () => {
    usersStore.userLogout()
      .then(() => {
        history.push(`/auth/login`)
        commonStore.setShowMainDrawer(false)
      })
  }

  const handleClickSetting = () => {
    commonStore.setShowMainDrawer(false)
    commonStore.setShowUserSettingPageDrawer(true)
  }

  const checkingFeatureRole = (feature) => {
    if(commonStore.currentPage && commonStore.currentPage !== 'projectDetail') return true
    if (!feature) return false
    return adminStore.checkingFeatureRole(projectStore, feature)
  }

  return (
    <React.Fragment>
      <Drawer
        id="mainDrawer"
        title={<DrawerHeader />}
        placement="left"
        closable={true}
        onClose={onClickLink}
        visible={commonStore.showMainDrawer}
        width={361}>
        {/* <SelectedPanel currentPanel={commonStore.currentMainDrawerPanel} /> */}
        {
          usersStore.currentUser.username ?
            <Fragment>
              <StyleToolbarUser>
                <Row>
                  {checkingFeatureRole("feature_profile") &&
                    <>
                    <Col span={10} className="user-bar uname" onClick={handleClickSetting}>
                      {usersStore.currentUser.username}
                    </Col>
                    <Col span={4} className="user-bar user-setting">
                      <SettingOutlined className="info" onClick={handleClickSetting} />
                    </Col>
                  </>}
                  { checkingFeatureRole("feature_login_and_logout") && <Col span={10} className="user-bar logout">
                    {usersStore.currentUser && usersStore.currentUser.provider === 'cognito' ?
                      <a style={{ color: "#F26524" }} href={`${process.env.REACT_APP_COGNITO_DOMAIN}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_URL}`} rel="noopener noreferrer" ><LogoutOutlined /> {t('log-out')}</a> :
                      <a onClick={() => handleLogout()} rel="noopener noreferrer" style={{ color: "#F26524" }}><LogoutOutlined /> {t('log-out')}</a>
                    }
                  </Col>}
                </Row>,
              </StyleToolbarUser>
            </Fragment> :
            <Fragment>
              { checkingFeatureRole("feature_login_and_logout") && <div
                style={{ width: '100%' }}
                onClick={() => history.push(`/auth/login`)}
                className={'sticky'}>
                <LoginOutlined /> {t('login')}
              </div>}
            </Fragment>
        }
        <Row style={{ padding: '0 0 10px 0' }}>
          <a href={renderTermsPolicyHref(data,'policy')} target='_blank' style={{ color: '#ff8d4f' }}>{t('privacy-policy')}</a>
        </Row>
        <Row style={{ padding: '0 0 10px 0' }}>
          <a href={renderTermsPolicyHref(data,'terms')} target='_blank' style={{ color: '#ff8d4f' }}>{t('terms-and-conditions')}</a>
        </Row>
      </Drawer>
    </React.Fragment>
  )
}

export default withRouter(inject('commonStore', 'usersStore', 'projectStore', 'adminStore')(observer(MainDrawer)))
