import { message } from 'antd';
import { action, decorate, observable } from 'mobx';
import { LicenseRequest, OrganizationRequest, ProjectRequest, IFCEngineRequest } from '@/requests';

class LicenseStore {
    isLoading = false
    licenseTypes = []

    setLoadingProgress = state => {
        this.isLoading = state
    }

    async getLicenseTypes() {
        return new Promise((resolve, reject) => {
            LicenseRequest.getLicenseType()
                .then(response => {
                    this.licenseTypes = response.data
                    this.setLoadingProgress(false)
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    this.setLoadingProgress(false)
                    reject(error)
                })
        })
    }

    async createLicense(info) {
        return new Promise((resolve, reject) => {
            LicenseRequest.createLicense(info).then(res => {
                resolve(res);
            })
                .catch(err => {
                    reject(err)
                })
        })

    }

    setLicenseTypes = payload => {
        this.licenseTypes = payload
    }

    clearLicenseType = () => {
        this.licenseTypes = []
    }

    async getQuotaLicenseOrganization(orgID) {
        return new Promise((resolve, reject) => {
            LicenseRequest.getQuotaLicenseOrganization(orgID)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    async getOrgLicenses(id) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrgLicenses(id).then((response) => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }
    async createIfcEngineUsage(body) {
        return new Promise((resolve, reject) => {
            IFCEngineRequest.create(body).then((response) => {
                resolve(response.data);
            }).catch(err => {
                console.log(err);
                reject(err)
            })
        })
    }

}

decorate(LicenseStore, {
    isLoading: observable,
    licenseTypes: observable,
    setLoadingProgress: action,
    setLicenseTypes: action,
    clearLicenseType: action,
    getLicenseTypes: action,
    getQuotaLicenseOrganization: action,
    getOrgLicenses: action,
    createIfcEngineUsage: action
})

export default new LicenseStore();