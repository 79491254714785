import {
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  DatePicker,
  Checkbox,
} from 'antd'
import en from 'antd/lib/date-picker/locale/en_US'
import es from 'antd/lib/date-picker/locale/es_ES'
import fi from 'antd/lib/date-picker/locale/fi_FI'
import sv from 'antd/lib/date-picker/locale/sv_SE'
import vi from 'antd/lib/date-picker/locale/vi_VN'
import { inject, observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import uuid from 'uuid'
import AdminTemplate from '../../../layout/AdminTemplate'
import '../style.css'
import { LoadingSpinner } from '@/components/elements'
import { CustomContentAdminWrapper, HeaderAdmin } from '../style'
import moment from 'moment'
const { Content } = Layout
const { Option } = Select
const dateFormat = 'YYYY-MM-DD hh:mm:ss'

const EditLicenses = ({ organizationStore, commonStore, adminStore }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [isLoadingFormEdit, setisLoadingFormEdit] = useState(false)
  const { licensesID } = useParams()
  const isMobile = useMediaQuery({ query: '(max-width: 400px)' })

  useEffect(() => {
    const populateLicenseFormData = async () => {
      adminStore.clearStatus()
      setisLoadingFormEdit(true)
      commonStore.setCurrentPage('admin/licenses')
      adminStore.setRiderect(true)
      organizationStore.getOrganization()
      adminStore.getLicenseTypes()
      if (licensesID !== 'new') {
        adminStore.setEditMode(true)
        await adminStore
          .getLicenseById(licensesID)
          .then(res => {
            setisLoadingFormEdit(false)
            let _value = res.data
            form.setFieldsValue({
              numberProject: _value?.numberProject,
              storageQuota: _value?.storageQuota,
              timeLimit: _value?.timeLimit,
              licenceKey: _value?.licenceKey,
              licensetype: _value?.licensetype?.id,
              isActive: _value?.isActive,
              customOrganizationLogo: _value?.customOrganizationLogo,
              organization: _value?.organization?.id,
              description: _value?.description,
              numberUser: _value?.numberUser,
            })
            if (_value.activated) {
              let _activated = moment.utc(_value.activated).local().format()
              form.setFieldsValue({
                activated: moment(_activated, 'YYYY-MM-DD'),
              })
            }
            if (_value.expiration) {
              let _expiration = moment.utc(_value.expiration).local().format()
              form.setFieldsValue({
                expiration: moment(_expiration, 'YYYY-MM-DD'),
              })
            }
          })
          .catch(() => setisLoadingFormEdit(false))
      } else {
        let _licenceKey = uuid()
        adminStore.setEditMode(false)
        adminStore.setCreateMode(true)
        setisLoadingFormEdit(false)
        form.resetFields()
        form.setFieldsValue({
          licenceKey: _licenceKey,
          isActive: false,
          customOrganizationLogo : false
        })
      }
    }
    populateLicenseFormData()
    return () => {
      adminStore.clearLicenseSelected()
    }
  }, [])

  const handleDeleteLicense = async id => {
    setisLoadingFormEdit(true)
    adminStore
      .deleteLicense(id)
      .then(() => {
        adminStore.setRiderect(false)
        setisLoadingFormEdit(false)
        message.success(t('delete-license-successfully'))
        history.push(`/admin/licenses/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`)
      })
      .catch(() => {
        // adminStore.setRiderect(false);
        setisLoadingFormEdit(false)
        message.error(t('delete-license-failed'))
      })
  }

  const createLicense = info => {
    setisLoadingFormEdit(true)
    adminStore
      .createLicense(info)
      .then(() => {
        message.success(t('create-new-license-successfully'))
        history.push(`/admin/licenses/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`)
      })
      .catch(() => {
        message.error(t('create-new-license-failed'))
      })
      .finally(() => {
        adminStore.setRiderect(false)
        setisLoadingFormEdit(false)
      })
  }

  const updateLicense = async (id, info) => {
    setisLoadingFormEdit(true)
    adminStore
      .updateLicense(id, info)
      .then(() => {
        message.success(t('license-updated-successfully'))
        setisLoadingFormEdit(false)
        organizationStore.setRiderect(false)
        history.push(`/admin/licenses/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`)
      })
      .catch(() => {
        setisLoadingFormEdit(false)
        message.error(t('an-error-occurred'))
      })
  }

  const handleSaveLicenses = payload => {
    if (licensesID === 'new') {
      // return createLicense(payload);
    } else {
      let currentLicense = adminStore.licenseSelected.id
      return updateLicense(currentLicense, payload)
    }
  }

  const resetFormLicense = () => {
    form.resetFields()
    history.push(`/admin/licenses/_limit=${adminStore.currentLimit}&_page=${adminStore.currentPage}`)
  }

  const showConfirmDeleteLicense = () => {
    Modal.confirm({
      title: t('do-you-want-to-delete-this-license'),
      content: `${t('please-confirm-delete')} :  "${
        adminStore.licenseSelected.licenceKey
      }" `,
      icon: <ExclamationCircleOutlined />,
      okText: t('commons.delete'),
      cancelText: t('commons.cancel'),
      okButtonProps: {
        type: 'default',
      },
      okButtonProps: {
        type: 'primary',
      },
      onOk() {
        handleDeleteLicense(adminStore.licenseSelected.id)
      },
      onCancel() {
        console.log('')
      },
    })
  }

  const customSpin = (
    <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
  )
  return (
    <AdminTemplate title={t('licenses-detail')}>
      <Spin spinning={isLoadingFormEdit} indicator={customSpin}>
        <CustomContentAdminWrapper>
          <div style={{  background: '#fff', textAlign: 'center' }}>
            <Layout>
              <Form
                form={form}
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={value => handleSaveLicenses(value)}>
                <HeaderAdmin style={{backgroundColor: '#f0f2f5'}}>
                <Content>
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    style={{ padding: '10px ' }}>
                    <div style={{ float: 'left', fontSize: 20 }}>
                      {' '}
                      {adminStore.createMode
                        ? t('new-license')
                        : t('edit-license')}
                    </div>
                    <Row>
                      <Col span={24} className="control-btn-group">
                        <Button
                          style={{
                            float: 'right',
                            
                          }}
                          htmlType="submit"
                          type="primary"
                          loading={adminStore.isLoading}>
                          <SaveOutlined />
                          {!isMobile && t('commons.save')}
                        </Button>
                        {!adminStore.createMode ? (
                          <Fragment>
                            <Button
                              style={{
                                float: 'right',
                                marginRight: 10,
                              }}
                              type="danger"
                              onClick={showConfirmDeleteLicense}>
                              <DeleteOutlined />
                              {!isMobile && t('commons.delete')}
                            </Button>
                          </Fragment>
                        ) : null}
                        <Button
                          style={{
                            float: 'right',
                            marginRight: 10,
                          }}
                          onClick={() => resetFormLicense()}>
                          <CloseCircleOutlined />
                          {!isMobile && t('commons.cancel')}
                        </Button>
                        <Modal
                          title={t('please-confirm-delete')}
                          style={{ top: 120, textAlign: 'center' }}
                          visible={modalVisible}
                          onOk={() => setModalVisible(false)}
                          onCancel={() => setModalVisible(false)}>
                          <p>
                            <InfoCircleOutlined style={{ fontSize: '30px' }} />
                          </p>
                          <p>
                            {t('are-you-sure-you-want-to-delete-this-record')}
                          </p>
                        </Modal>
                      </Col>
                    </Row>
                  </Row>
                </Content>
                </HeaderAdmin>
                <Content>
                  <div style={{ padding: '20px 0', background: '#fff' }}>
                    <Row type="flex">
                      <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                        <div
                          className="content-left"
                          style={{
                            background: '#fff',
                            padding: '20px 0',
                            marginRight: '30px',
                          }}>
                          <Row gutter={[24, 8]}>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <Form.Item
                                label={t('licence-key')}
                                name="licenceKey"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-license-key'),
                                  },
                                ]}>
                                <Input
                                  placeholder={t('input-your-licenses-key')}
                                  type="text"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <Form.Item
                                label={t('licenses-type')}
                                name="licensetype"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-select-licenses-type'),
                                  },
                                ]}>
                                <Select
                                  notFoundContent=""
                                  placeholder={t('please-select-licenses-type')}
                                  allowClear={true}
                                  showArrow={true}
                                  showSearch={true}
                                  filterOption={(input, option) =>
                                    option.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  optionFilterProp="children">
                                  {adminStore.licenseTypes ? (
                                    adminStore.licenseTypes.map((v, i) => {
                                      return (
                                        <Option key={v.id} value={v.id}>
                                          {v.name}
                                        </Option>
                                      )
                                    })
                                  ) : (
                                    <Option>
                                      {t('no-organization-found')}
                                    </Option>
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <Form.Item
                                label={t('description')}
                                name="description"
                                rules={[
                                  {
                                    required: false,
                                    message: t(
                                      'please-input-licenses-description'
                                    ),
                                  },
                                ]}>
                                <Input
                                  placeholder={t(
                                    'input-your-licenses-description'
                                  )}
                                  type="text"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <Form.Item
                                label={t('number-project')}
                                name="numberProject"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-total-project'),
                                  },
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <Form.Item
                                label={t('storage-quota')}
                                name="storageQuota"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-storage-quota'),
                                  },
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <Form.Item
                                label={t('time-limit')}
                                name="timeLimit"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-time-limit'),
                                  },
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <Form.Item
                                label={t('number-user')}
                                name="numberUser"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-input-total-user'),
                                  },
                                ]}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  min={0}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <Form.Item
                                label={t('activation')}
                                name="activated"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-select-activation-date'),
                                  },
                                ]}>
                                <DatePicker
                                  locale={{
                                    ...(commonStore.language === 'fi'
                                      ? fi
                                      : commonStore.language === 'sv'
                                      ? sv
                                      : commonStore.language === 'es'
                                      ? es
                                      : commonStore.language === 'vi'
                                      ? vi
                                      : en),
                                    lang: {
                                      ...(commonStore.language === 'fi'
                                        ? fi
                                        : commonStore.language === 'sv'
                                        ? sv
                                        : commonStore.language === 'es'
                                        ? es
                                        : commonStore.language === 'vi'
                                        ? vi
                                        : en
                                      ).lang,
                                      now: t('now'),
                                      ok: t('commons.ok'),
                                    },
                                  }}
                                  style={{ width: '100%' }}
                                  inputReadOnly={true}
                                  placeholder={t('select-time')}
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={24}
                              lg={12}
                              xl={12}
                              xxl={12}>
                              <Form.Item
                                label={t('expiration')}
                                name="expiration"
                                rules={[
                                  {
                                    required: true,
                                    message: t('please-select-expiration-date'),
                                  },
                                ]}>
                                <DatePicker
                                  locale={{
                                    ...(commonStore.language === 'fi'
                                      ? fi
                                      : commonStore.language === 'sv'
                                      ? sv
                                      : commonStore.language === 'es'
                                      ? es
                                      : commonStore.language === 'vi'
                                      ? vi
                                      : en),
                                    lang: {
                                      ...(commonStore.language === 'fi'
                                        ? fi
                                        : commonStore.language === 'sv'
                                        ? sv
                                        : commonStore.language === 'es'
                                        ? es
                                        : commonStore.language === 'vi'
                                        ? vi
                                        : en
                                      ).lang,
                                      now: t('now'),
                                      ok: t('commons.ok'),
                                    },
                                  }}
                                  style={{ width: '100%' }}
                                  inputReadOnly={true}
                                  placeholder={t('select-time')}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row >
                            <Col span={24}>
                              <Form.Item
                                label={t('active')}
                                name="isActive"
                                valuePropName="checked"
                                className="checkbox-status">
                                <Switch
                                  checkedChildren={t('active')}
                                  unCheckedChildren={t('non-active')}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                label={t('custom-organization-logo')}
                                name="customOrganizationLogo"
                                valuePropName="checked"
                                className="checkbox-status">
                                <Checkbox
                                  checkedChildren={t('active')}
                                  unCheckedChildren={t('non-active')}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <div style={{ paddingTop: '20px' }}>
                          <Form.Item
                            label={t('organization')}
                            name="organization"
                            rules={[
                              {
                                required: false,
                                message: t('please-select-an-organization'),
                              },
                            ]}>
                            <Select
                              notFoundContent=""
                              placeholder={t('please-select-an-organization')}
                              allowClear={true}
                              showArrow={true}
                              showSearch={true}
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              optionFilterProp="children">
                              {organizationStore.organizations ? (
                                organizationStore.organizations.map((v, i) => {
                                  return (
                                    <Option key={v.key} value={v.id}>
                                      {v.name}
                                    </Option>
                                  )
                                })
                              ) : (
                                <Option>{t('no-organization-found')}</Option>
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Content>
              </Form>
            </Layout>
          </div>
        </CustomContentAdminWrapper>
      </Spin>
    </AdminTemplate>
  )
}

export default inject(
  'organizationStore',
  'commonStore',
  'adminStore'
)(observer(EditLicenses))
