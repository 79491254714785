import { BuildOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Upload, message, Modal, Select } from 'antd'
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import validator from '@/validator'
import { ButtonGroup, ModalStyle } from './CustomStyled';
import { getMimeOfFile } from '@/lib/projectLib';
const { Option } = Select;

const ModalAddEditGEOID = ({ projectStore, adminStore, setReload }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isLoading, setLoadingProgress] = useState(false);
    const [uploadFileS3, setUploadFileS3] = useState(undefined)
    const ASSET_URL = `https://${process.env.REACT_APP_AWS_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/`

    useEffect(() => {
        if (adminStore.showAddEditGEOID) {
            form.resetFields();
        }
    }, [adminStore.showAddEditGEOID])

    const onCancel = () => {
        form.resetFields()
        adminStore.setShowAddEditGEOID(false)
        setReload(false)
    }

    const handleSubmit = v => {
        if (adminStore.currentGEOID.isEdit && form.getFieldValue('ext') !== "" && form.getFieldValue("file") === undefined) {
            if (`.${adminStore.currentGEOID.ext}` !== form.getFieldValue('ext').toLowerCase()) {
                form.setFields([
                    {
                        name: 'ext',
                        errors: ['file-format-is-not-valid'],
                    },
                ]);
                return
            }
        }

        form.validateFields().then(async values => {
            setLoadingProgress(true)
            let submitValues = {
                name: values.name,
                geoidCode: values.geoidCode,
                epsgCode: values.epsgCode,
                type: 'custom'
            }

            if (values.file && values.file.length > 0) {
                let file = values.file[0].originFileObj
                var config = {
                    method: 'put',
                    url: uploadFileS3.url,
                    headers: {
                        'Content-Type': getMimeOfFile(file.name)
                    },
                    data: file
                };
                let res = await axios(config)
                if (res.status == 200) {
                    submitValues.fileName = uploadFileS3.file.replace(/^.*[\\\/]/, '')
                    submitValues.ext = uploadFileS3.file.split('.').pop()
                    submitValues.url = `${ASSET_URL}${uploadFileS3.file}`
                    submitValues.size = file.size
                }
            }

            if (adminStore.currentGEOID.isEdit) {
                adminStore.updateGEOID(adminStore.currentGEOID._id, submitValues)
                    .then(() => {
                        message.success(t('update-geoid-successfully'))
                    })
                    .catch(() => {
                        message.error(t('update-geoid-failed'))
                    })
                    .finally(() => {
                        setLoadingProgress(false)
                        form.resetFields()
                        adminStore.setShowAddEditGEOID(false)
                        setReload(true)
                    })
            } else {
                adminStore.createGEOID(submitValues)
                    .then(() => {
                        message.success(t('create-new-geoid-successfully'))
                    })
                    .catch(() => {
                        message.error(t('create-new-geoid-failed'))
                    })
                    .finally(() => {
                        setLoadingProgress(false)
                        form.resetFields()
                        adminStore.setShowAddEditGEOID(false)
                        setReload(true)
                    })
            }
        })
    }

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        if (e.fileList.length > 1) {
            e.fileList.splice(0, 1)
        }
        return e && e.fileList;
    };

    const dummyRequest = async ({ file, onSuccess, onError }) => {
        let fileType = file.name.slice(file.name.length - 4)
        fileType = fileType ? fileType.toLowerCase() : ''
        if (fileType == ".tif" || fileType == ".asc" || fileType == ".gtx") {
            let fileformat = form.getFieldValue('ext').toLowerCase()
            if (fileformat === "") {
                form.setFieldsValue({ ext: fileType })
                const presignedS3Url = await projectStore.generatePreSignedPutUrl({ fileName: file.name, lambdaCppPath: 'LambdaCpp/PROJ' })
                setUploadFileS3(presignedS3Url)
                onSuccess("ok");
            } else if (fileformat !== "" && fileformat == fileType) {
                const presignedS3Url = await projectStore.generatePreSignedPutUrl({ fileName: file.name, lambdaCppPath: 'LambdaCpp/PROJ' })
                setUploadFileS3(presignedS3Url)
                onSuccess("ok");
            } else {
                setUploadFileS3()
                onError("error")
            }
        } else {
            setUploadFileS3()
            onError("error")
        }
    }

    const fileProps = {
        name: 'file',
        multiple: false,
        showUploadList: true,
        onRemove(data) {
            console.log({ data });
        },
        customRequest: dummyRequest,
        onChange(info) {
            const { status } = info.file;
            if (status === 'error') {
                form.setFieldsValue({ file: null })
                message.error(`${info.file.name} ${t('file-upload-failed')}`);
            }
        }
    };

    return (
        <>
            <Modal
                centered={true}
                onCancel={onCancel}
                visible={adminStore.showAddEditGEOID}
                title={t(adminStore.currentGEOID.headerTitle)}
                icon={<BuildOutlined />}
                iconType="build"
                zIndex={9999}
                footer={false}
                maskClosable={false}
                closable={isLoading === false}
            >
                <ModalStyle>
                    <Form form={form} layout="vertical" onFinish={handleSubmit}>
                        <Form.Item
                            label={t('name')}
                            name="name"
                            initialValue={adminStore.currentGEOID.name ? adminStore.currentGEOID.name : ''}
                            rules={[
                                {
                                    required: true,
                                    message: t('name-is-required'),
                                },
                                {
                                    validator: validator.validateEmptyString,
                                },
                            ]}
                        >
                            <Input placeholder={t('input-name')} />
                        </Form.Item>
                        <Form.Item
                            label={t('geoid-code')}
                            name="geoidCode"
                            initialValue={adminStore.currentGEOID.geoidCode ? adminStore.currentGEOID.geoidCode : ''}
                            rules={[
                                {
                                    required: true,
                                    message: t('geoid-code-is-required'),
                                },
                                {
                                    validator: validator.validateEmptyString,
                                },
                            ]}
                        >
                            <Input placeholder={t('input-geoid-code')} />
                        </Form.Item>
                        <Form.Item
                            label={t('epsg-code')}
                            name="epsgCode"
                            initialValue={adminStore.currentGEOID.epsgCode ? adminStore.currentGEOID.epsgCode : ''}
                            rules={[
                                {
                                    required: true,
                                    message: t('epsg-code-is-required'),
                                },
                                {
                                    validator: validator.validateEmptyString,
                                },
                            ]}
                        >
                            <Input placeholder={t('input-epsg-code')} />
                        </Form.Item>
                        <Form.Item
                            label={t('file-format')}
                            name="ext"
                            initialValue={adminStore.currentGEOID.ext ? `.${adminStore.currentGEOID.ext}` : ''}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Select
                                className='selectdrp'
                                onChange={(value) => {
                                    if (form.getFieldValue("file") && form.getFieldValue("file").length > 0 && value !== "") {
                                        let fileName = form.getFieldValue("file")[0].name.toLowerCase()
                                        let ext = fileName.substr(fileName.lastIndexOf('.'), fileName.length)
                                        if (ext !== value) {
                                            form.setFieldsValue({ file: undefined })
                                        }
                                    }
                                }}
                            >
                                <Option value=""></Option>
                                <Option value=".asc">ASC</Option>
                                <Option value=".gtx">GTX</Option>
                                <Option value=".tif">TIF</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item className="inlinelayout" label={t('file')}>
                            <Form.Item
                                name="file"
                                valuePropName='fileList'
                                getValueFromEvent={normFile}
                                rules={[
                                    {
                                        required: (adminStore.currentGEOID.isEdit === false),
                                        message: t('file-is-required'),
                                    },
                                ]}
                            >
                                <Upload disabled={isLoading} accept=".gtx,.tif,.asc" {...fileProps}>
                                    <Button icon={<UploadOutlined />}>{t('click-to-upload')}</Button>
                                </Upload>
                            </Form.Item>
                        </Form.Item>
                        <ButtonGroup>
                            <Button disabled={isLoading} type={'default'} onClick={onCancel} >{t('commons.cancel')}</Button>
                            <Button loading={isLoading} type={'primary'} htmlType={'submit'}>{t(adminStore.currentGEOID.okText)}</Button>
                        </ButtonGroup>
                    </Form>
                </ModalStyle>
            </Modal>
        </>
    )

}

export default withRouter(inject('projectStore', 'adminStore')(observer(ModalAddEditGEOID)))
