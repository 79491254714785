
import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import React, {useEffect, useState} from 'react';
import { Redirect, Route } from 'react-router-dom';
import commonStore from '../../../stores/commonStore';
import usersStore from '../../../stores/usersStore';
import { LoadingSpinner } from '@/components/elements'
import { useTranslation } from 'react-i18next';
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [userRole, setUserRole] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    if(!usersStore.currentUser?.role?.type){
      const fetchUserData = async () =>{
        await usersStore.getCurrentUser().catch(err=>{
          setIsLoading(false)
        })
      }
      setIsLoading(true)
      fetchUserData().then(() => {
        setUserRole(usersStore.currentUser?.role?.type)
        setIsLoading(false)
      })
    } else setUserRole(usersStore.currentUser?.role?.type)
  }, [])
  
  // const userInfo = usersStore.currentUser; 
  // const userRole = userInfo?.role?.type;
  const routeRoles = rest?.meta?.roles;  
  const isValidToken = localStorage.getItem('jwt') || sessionStorage.getItem('jwt');  
  return (
    <Route {...rest} render={props => {
      if (!isValidToken) {
        return <Redirect push to="/auth/login" />        
      }
      else if (routeRoles && userRole && routeRoles.indexOf(userRole) === -1) {
        message.error(t('you-do-not-have-permission'));
        return <Redirect to={{ pathname: '/' }} />
      }else {
        return isLoading? <LoadingSpinner theme={commonStore.appTheme} type={'page'} /> :  <Component {...props} />
      }
    }} />
  )
}

export default inject("usersStore", "commonStore")(observer(ProtectedRoute));
