import * as turf from "@turf/turf";
import { message } from 'antd'
import { createClippingPlaneHole, getInverseTransform } from "@/lib/projectLib";
import { ClippingPlaneCollection, Color } from "cesium";

export const HiddenAreaUtils = {
  drawArea: (viewer, hideArea, clearOldData) => {
    if (clearOldData) {
      if (viewer.scene && !viewer.scene.isDestroyed() && viewer.scene.primitives) {
        for (let i = 0; i < viewer.scene.primitives.length; i++) {
          let primitive = viewer.scene.primitives.get(i)
          if (primitive.clippingPlanes) {
            primitive.clippingPlanes.removeAll();
          }
        }
      }
      if (viewer.scene.globe.clippingPlanes) {
        viewer.scene.globe.clippingPlanes.enabled = !1
        viewer.scene.globe.clippingPlanes.removeAll()
      }
    }

    let polyarr = hideArea.points.map(item => [item[0], item[1]])
    if (hideArea.points[0][0] !== hideArea.points[hideArea.points.length - 1][0] && hideArea.points[0][1] !== hideArea.points[hideArea.points.length - 1][1]) {
      polyarr.push([hideArea.points[0][0], hideArea.points[0][1]]) //if not closed polygon then add closed point
    }

    let kinkedPoly = turf.polygon([polyarr]);
    let unkinkedPoly = turf.unkinkPolygon(kinkedPoly);
    if (unkinkedPoly.features.length > 1) { //check polygon interaction if features > 1
      message.info('Please draw polyline that doesn\'\t intersect itself', 5)
    } else {
      let _planes = createClippingPlaneHole(hideArea.points);
      // cut terrain
      if (hideArea.isCutTerrain) {
        //polylineCutToolStore.setHideArea({ isCutTerrain: hideArea.isCutTerrain })
        viewer.scene.globe.depthTestAgainstTerrain = true;
        let clippingPlanesCollec = new ClippingPlaneCollection({
          planes: _planes,
          edgeWidth: hideArea.edgeWidth,
          edgeColor: new Color.fromCssColorString(hideArea.edgeColor),
          unionClippingRegions: false,
          enabled: true,
        });
        if (viewer.scene.globe.clippingPlanes) {
          viewer.scene.globe.clippingPlanes._planes = _planes;
          viewer.scene.globe.clippingPlanes.edgeWidth = hideArea.edgeWidth ? hideArea.edgeWidth : 1;
          viewer.scene.globe.clippingPlanes.edgeColor = new Color.fromCssColorString(hideArea.edgeColor ? hideArea.edgeColor : "#000000");
          viewer.scene.globe.clippingPlanes.unionClippingRegions = false;
          viewer.scene.globe.clippingPlanes.enabled = true;
        } else {
          viewer.scene.globe.clippingPlanes = clippingPlanesCollec
        }
      }

      // cut 3dtile
      let primitives = viewer.scene.primitives._primitives.filter(function (value, index, arr) {
        return value.isCesium3DTileset;
      });

      const createClippingPlane = (_cesium3DTileset) => {
        let clippingPlanesCollec = new ClippingPlaneCollection({
          planes: _planes,
          modelMatrix: getInverseTransform(_cesium3DTileset),
          edgeWidth: hideArea.edgeWidth ? hideArea.edgeWidth : 1,
          edgeColor: new Color.fromCssColorString(hideArea.edgeColor ? hideArea.edgeColor : "#000000"),
          unionClippingRegions: false,
        });
        if (_cesium3DTileset.clippingPlanes) {
          _cesium3DTileset.clippingPlanes._planes = _planes;
          _cesium3DTileset.clippingPlanes.modelMatrix = getInverseTransform(_cesium3DTileset);
          _cesium3DTileset.clippingPlanes.edgeWidth = hideArea.edgeWidth ? hideArea.edgeWidth : 1;
          _cesium3DTileset.clippingPlanes.edgeColor = new Color.fromCssColorString(hideArea.edgeColor ? hideArea.edgeColor : "#000000");
          _cesium3DTileset.clippingPlanes.unionClippingRegions = false;
        } else {
          _cesium3DTileset.clippingPlanes = clippingPlanesCollec
        }
      }
      for (const [i, _tileset] of primitives.entries()) {
        if (_tileset?.isCesium3DTileset) {
          try {
            if (hideArea.visibilitySource && hideArea.visibilitySource.includes(_tileset.id)) {
              createClippingPlane(_tileset);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    }

    if (viewer.scene.requestRenderMode) { viewer.scene.requestRender(); }
  },
  cancelState: (viewer, polylineCutToolStore, isTemporarilyHide) => {
    // disable or enable draw mode
    if (polylineCutToolStore.drawEditor.currentEditEntity) {
      polylineCutToolStore.drawEditor.currentEditEntity.show = isTemporarilyHide
    }
    // temporarily hide polyline cutting.
    viewer.entities.values.map(item => {
      if (item.polyline || item.point) {
        item.show = isTemporarilyHide
      }
    })
    // remove and clear some entities
    if (viewer.scene && !viewer.scene.isDestroyed() && viewer.scene.primitives) {
      for (var i = 0; i < viewer.scene.primitives.length; i++) {
        var primitive = viewer.scene.primitives.get(i)
        if (primitive.clippingPlanes) {
          primitive.clippingPlanes.removeAll();
        }
      }
    }
    if (viewer.scene.globe.clippingPlanes) {
      viewer.scene.globe.clippingPlanes.enabled = !1
      viewer.scene.globe.clippingPlanes.removeAll()
    }
  }
}