import { DeleteOutlined, EditOutlined, PlusOutlined , SearchOutlined, CloseOutlined} from '@ant-design/icons'
import { Button, Col, message, Popconfirm, Row, Table , Form , Input} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import axios from 'axios'
import { apiUrl } from '@/config'
import { Link, useHistory, useRouteMatch , useParams} from 'react-router-dom'
import AdminTemplate from '@/components/layout/AdminTemplate'
import {
  CustomTopTitle,
  CustomContentAdminWrapper,
  PaginationAdmin,
  HeaderAdmin,
} from '../style'
import { CustomButton, CustomSearch, SearchLicenseWrapper } from './style'
import {  useTranslation } from 'react-i18next'
import { toJS } from 'mobx'
import settings from '@/siteConfig'
var cancelToken
const LicenseType = props => {
  const { commonStore, organizationStore, adminStore } = props
  const { t } = useTranslation()
  const { path, url } = useRouteMatch()
  const [isLoadingTableLicenseType, setisLoadingTableLicenseType] = useState(
    organizationStore.isLoading
  )
  const history = useHistory()
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const [searchValue, setSearchValue] = useState();
  const [_limit, setLimit] = useState();
  const [_page, setPage] = useState();
  const [fetchURL, setFetchURL] = useState(true);
  const [form] = Form.useForm();
  const { queryParam } = useParams()

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    setLimit(parseInt(queryStringParams._limit))
    setPage(parseInt(queryStringParams._page))
    if (fetchURL && _page && _limit) {
      setisLoadingTableLicenseType(true)
      adminStore.setCurrentPage(_page)
      adminStore.setCurrentLimit(_limit)
      adminStore
        .customGetLicenseTypes(
          _limit,
          _page,
          searchValue ? searchValue.trim() : searchValue
        )
        .then(() => {
          setisLoadingTableLicenseType(false)
        })
        .catch(() => {
          setisLoadingTableLicenseType(false)
          adminStore.clearCustomListLicenseTypes()
        })
    }
  }, [queryParam, _page, _limit, fetchURL])

  useEffect(() => {
    commonStore.setCurrentPage('admin/licensetypes')
    adminStore.clearStatus()
    const queryStringParams = queryString.parse(queryParam)
    if (queryStringParams.search) {
      setSearchValue(queryStringParams.search)
      form.setFieldsValue({
        searchLicense: queryStringParams.search,
      })
    }
  }, [])

  useEffect(() => {
    const queryStringParams = queryString.parse(queryParam)
    if (!queryStringParams.search && !fetchURL) {
    setisLoadingTableLicenseType(true)
      setSearchValue('')
      form.setFieldsValue({
        searchLicense: ''
      })
      handleSearchLicenseType('')
    }
  }, [queryParam])

  const handleRedirectEditLicenseForm = () => {
    adminStore.setRiderect(true)
    adminStore.setEditMode(true)
    adminStore.setCreateMode(false)
  }

  const handleDeleteLicense = async id => {
    setisLoadingTableLicenseType(true)
    adminStore
      .deleteLicenseType(id)
      .then(() => {
        adminStore.setRiderect(false)
        adminStore.customListLicenseTypes.data = adminStore.customListLicenseTypes.data.filter(
          x => x.id !== id
        )
        message.success(t('delete-license-successfully'))
      })
      .catch(() => {
        message.error(t('delete-license-failed'))
      })
      .finally(() => {
        setisLoadingTableLicenseType(false)
      })
  }

  const handleModeCreateOrganization = () => {
    adminStore.setRiderect(true)
    adminStore.setCreateMode(true)
    adminStore.setEditMode(false)
    return history.push('/admin/licensetype/new')
  }

  const columns = [
    {
      title: t('name'),
      key: 'name',
      dataIndex: 'name',
      render: (record, obj) => {
        return (
          <span style={{ textTransform: 'uppercase' }}>
            {' '}
            {record ? record : t('no-name')}
          </span>
        )
      },
    },
    {
      title: t('projects'),
      key: 'numberProject',
      //width: 50,
      dataIndex: 'numberProject',
      render: (record, obj) => {
        if (record === 9999) {
          return <span>{t('unlimited')}</span>
        }

        return <span> {record ? record : 0}</span>
      },
    },
    {
      title: t('storage-quota'),
      key: 'storageQuota',
      //width: 50,
      dataIndex: 'storageQuota',
      render: (record, obj) => {
        if (record === 9999) {
          return <span>{t('unlimited')}</span>
        }

        return <span> {record ? record : 0}</span>
      },
    },
    {
      title: t('users'),
      key: 'numberUser',
      //width: 50,
      dataIndex: 'numberUser',
      render: (record, obj) => {
        if (record === 9999) {
          return <span>{t('unlimited')}</span>
        }

        return <span> {record ? record : 0}</span>
      },
    },
    {
      title: t('time-limit'),
      key: 'timeLimit',
      //width: 50,
      dataIndex: 'timeLimit',
      render: (record, obj) => {
        if (record === 9999) {
          return <span>{t('unlimited')}</span>
        }

        return <span> {record ? record : 0}</span>
      },
    },
    {
      title: t('action'),
      dataIndex: 'id',
      key: 'event',
      fixed: 'right',
      align: 'center',
      width: 120,
      render: record => {
        return (
          <Row type="flex" justify="center" key="action">
            <Link
              onClick={() => handleRedirectEditLicenseForm()}
              to={`/admin/licensetype/${record}`}>
              <Button style={{ margin: 2, padding: '3px 7px' }}>
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              placement="top"
              title={t('are-you-sure-delete')}
              onConfirm={() => handleDeleteLicense(record)}
              okText={t('commons.yes')}
              cancelText={t('commons.no')}>
              <Button type="danger" style={{ margin: 2, padding: '3px 7px' }}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Row>
        )
      },
    },
  ]


  const handleSearchLicenseType = async e => {
    const search = e ? e.trim() : e
    setFetchURL(false)
    adminStore.setCurrentPage(1)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    if (search === '') {
      setSearchValue()
      history.push(`/admin/licensetypes/_limit=${_limit ? _limit : 10}&_page=${1}`)
    } else {
      setSearchValue(search)
      history.push(
        `/admin/licensetypes/_limit=${_limit ? _limit : 10}&_page=${1}&search=${encodeURIComponent(e)}`
      )
    }
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.')
    }
    cancelToken = axios.CancelToken.source()
    try {
      setisLoadingTableLicenseType(true)
      const response = await axios.get(
        `${apiUrl}/licensetypePaginate?_limit=${_limit}&_page=${1}${search && search !== '' ? '&search=' + search : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${commonStore.token}`,
          },
          cancelToken: cancelToken.token,
        }
      )
      if (response) {
        setisLoadingTableLicenseType(false)
        adminStore.setCustomListLicenseTypes(response.data)
      }
    } catch (error) {
      if (error) {
        setisLoadingTableLicenseType(false)
        adminStore.clearCustomListLicenseTypes()
        console.log(error)
      }
    }
  }

  const clearSearch = () => {
    setSearchValue('')
    setFetchURL(true)
    form.resetFields()
    setLimit(_limit ? _limit : 10)
    setPage(1)
    adminStore.setCurrentPage(1)
    adminStore.setCurrentLimit(_limit ? _limit : 10)
    return history.push(
      `/admin/licensetypes/_limit=${_limit ? _limit : 10}&_page=${1}`
    )
  }

  const debounceCalculate = debounce(function (value) {
    handleSearchLicenseType(value)
  }, 500)

  const onChange = e => {
    setFetchURL(false)
    debounceCalculate(e.target.value)
  }

  const handleChangePagination = (current, pageSize) => {
    setLimit(pageSize)
    setPage(current)
    setFetchURL(true)
    adminStore.setCurrentPage(current)
    adminStore.setCurrentLimit(pageSize)
    if (searchValue) {
      return history.push(
        `/admin/licensetypes/_limit=${pageSize}&_page=${current}&search=${searchValue.trim()}`
      )
    } else {
      return history.push(`/admin/licensetypes/_limit=${pageSize}&_page=${current}`)
    }
  }

  return (
    <AdminTemplate title={t('license-types')}>
      <CustomContentAdminWrapper
        nodata={adminStore.customListLicenseTypes?.count ? false : true}>
        <HeaderAdmin>
          <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Col xs={24} sm={12}>
              <CustomTopTitle>
                <div className="title">{t('license-types')}</div>
                <div className="count">
                  {t('total-records-found', {
                    length: adminStore.customListLicenseTypes?.count || 0,
                  })}
                </div>
              </CustomTopTitle>
            </Col>
            <Col xs={24} sm={12}>
              <Button
                style={{ float: isMobile?'left':'right' }}
                type="primary"
                onClick={() => handleModeCreateOrganization()}>
                <PlusOutlined style={{ marginRight: '5px' }} />
                {t('new-license')}
              </Button>
            </Col>
            <Col span={24}>
              <Form form={form} layout="vertical">
                <SearchLicenseWrapper>
                  <CustomSearch>
                    <Form.Item name="searchLicense">
                      <Input
                        onChange={onChange}
                        placeholder={t('search-for-an-entry')}
                        prefix={<SearchOutlined />}></Input>
                    </Form.Item>
                  </CustomSearch>
                  {searchValue && (
                    <CustomButton onClick={() => clearSearch()}>
                      <CloseOutlined />
                    </CustomButton>
                  )}
                </SearchLicenseWrapper>
              </Form>
            </Col>
          </Row>
        </HeaderAdmin>
        <PaginationAdmin>
          <Table
            rowKey={record => record.id}
            size="default"
            bordered
            scroll={{ x: 1000 }}
            loading={isLoadingTableLicenseType}
            columns={columns}
            dataSource={adminStore.customListLicenseTypes?.data || []}
            pagination={{
              defaultPageSize: _limit,
              showSizeChanger: true,
              current: _page,
              pageSizeOptions: settings.adminPageSizeOptions,
              onChange: (page, pageSize) =>
                handleChangePagination(page, pageSize),
              total: adminStore.customListLicenseTypes?.count || 0,
              locale: { items_per_page: '' },
              position: ['topRight'],
            }}
            locale={{ emptyText: t('no-data') }}
            onRow={(record, rowIndex) => { }}
          />
        </PaginationAdmin>
      </CustomContentAdminWrapper>
    </AdminTemplate>
  )
}

export default inject(
  'organizationStore',
  'commonStore',
  'adminStore'
)(observer(LicenseType))
