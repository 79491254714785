import {
  Cartesian2,
  Cartesian3, Cartographic, Color, Ellipsoid,
  EllipsoidGeodesic, GridMaterialProperty, HorizontalOrigin, Math as CesiumMath, Math as MathCesium, PinBuilder, PointPrimitive,
  PolylineOutlineMaterialProperty, SceneMode, ScreenSpaceEventType, VerticalOrigin, CallbackProperty
} from 'cesium'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Entity, Label, LabelCollection, PolylineGraphics } from 'resium'
import uuid from 'uuid'
import { useTranslation } from 'react-i18next';
import { clickPoint, distOnLocal, getRefPoint, getVerticalDistance } from '@/helper'
import { isMobile, isTablet } from 'react-device-detect';

var ellipsoid = Ellipsoid.WGS84
var geodesic = new EllipsoidGeodesic()

const MeasureDistance = ({ viewer, projectStore, handler }) => {
  const [points, setPoints] = useState([])
  const [labels, setLabels] = useState([])

  const { t } = useTranslation();
  const labelDefault = {
    font: '12px monospace',
    showBackground: true,
    horizontalOrigin: HorizontalOrigin.CENTER,
    verticalOrigin: VerticalOrigin.CENTER,
    pixelOffset: new Cartesian2(0, 0),
    fillColor: Color.WHITE,
  }
  function CustomLine() {
    this.positions = [];
    this.markers = [];
    var that = this;

    this.line = viewer.current.cesiumElement.entities.add({
      polyline: {
        positions: new CallbackProperty(function () {
          return that.positions.map(x => x.position);
        }, false),
        width: 2,
        material: Color.WHITE,
        depthFailMaterial: new GridMaterialProperty({
          color: Color.RED
        }),
        destroyPrimitives: false
      }
    });
  }

  //Function add point click
  CustomLine.prototype.addPoint = function (resultClick) {
    var that = this;
    var pickedObject = resultClick.pickedObject
    var position = resultClick.position
    var n = this.positions.length;
    this.positions[n] = {
      pickedObject: pickedObject,
      position: position
    };

    var marker = viewer.current.cesiumElement.entities.add({
      position: new CallbackProperty(function () {
        if (that.positions && that.positions[n] && that.positions[n].position) {
          return that.positions[n].position;
        }
      }, false),
      point: {
        pixelSize: 5,
        color: Color.RED,
        outlineColor: Color.WHITE,
        outlineWidth: 2,
      },
    })
    this.markers.push(marker);
  };

  //function remove point and line
  CustomLine.prototype.removeAll = function () {
    if (this.markers && this.markers.length > 0) {
      for (var i = 0; i < this.markers.length; i++) {
        if (viewer && viewer.current && viewer.current.cesiumElement)
          viewer.current.cesiumElement.entities.remove(this.markers[i]);
      }
      this.markers = [];
      this.positions = [];
    }
  };
  const pinBuilder = new PinBuilder()
  const [customLine] = useState(new CustomLine())
  const pinDefault = {
    image: pinBuilder.fromColor(Color.RED, 24).toDataURL(),
    verticalOrigin: VerticalOrigin.BOTTOM,
    disableDepthTestDistance: Number.POSITIVE_INFINITY,
  }
  var point1 = false
  var point2 = false
  var step = 0
  var point1GeoPosition, point2GeoPosition
  var point1Click = false
  var point2Click = false
  var epsgCode = ''

  function clearAll() {
    customLine.removeAll();
    projectStore.setPolylines([])
    setPoints([])
    setLabels([])
    point1 = false
    point2 = false
    step = 0
    point1GeoPosition = undefined
    point2GeoPosition = undefined
    point1Click = false
    point2Click = false
    epsgCode = ''
  }

  const getPointClickAndModel = (cartesian, feature) => {
    var cartographic = Cartographic.fromCartesian(cartesian)
    var picklong = cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN
    var picklat = cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN
    const coordinateSystemCode = (projectStore.projectDetail.tilesetData.coordinateSystem && projectStore.projectDetail.tilesetData.coordinateSystem.code) || projectStore.projectDetail.coordinateSystem
    if (projectStore.projectDetail.tilesetData && projectStore.projectDetail.tilesetData.coordinateSystem) {
      epsgCode = coordinateSystemCode ? coordinateSystemCode : '4326'
    } else {
      epsgCode = '4326'
    }

    if (feature && feature.content) {
      const model = projectStore.findModelByUrl(feature.content.tileset?.resource?.url)
      if (model && (model.type === 'ifc' || model.type === 'landxml' || model.type === 'cad')) {

        var refPoint = projectStore?.projectDetail?.tilesetData?.RefPoint
        if (!refPoint) {
          refPoint = [cartesian.x, cartesian.y, cartesian.z]
        }

        let projectElevation = projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'
        if (model.elevationSystem) projectElevation = model.elevationSystem

        let _obj = {
          reflat: refPoint[0],
          reflong: refPoint[1],
          refheight: refPoint[2],
          picklat,
          picklong,
          pickheight: cartographic.height, //_sampleheight
          epsgCode,
          projectElevation: projectElevation ? projectElevation : 'None',
          cartesian: cartesian,
          cartographic: cartographic
        }
        return _obj
      } else {
        return { cartesian: cartesian, cartographic: cartographic, data: { tilesetTransform: getRefPoint(feature.content.tileset.root.transform), modelCenter: Cartesian3.fromArray(model.crs.ModelCenter ? model.crs.ModelCenter : [0, 0, 0]) } }
      }
    } else {
      return { cartesian: cartesian, cartographic: cartographic }
    }
  }

  const CalPointClick = async (viewer, resultClick, isPreview) => {
    if (resultClick && resultClick.position) {
      var cartesian = resultClick.position
      // await viewer.scene.clampToHeightMostDetailed([cartesian]).then(function (result) {
      //   return result
      // })
      if (step === 2) {
        step = 0
        point1 = false
        point2 = false
        point1Click = false
        point2Click = false
      }
      if (viewer.scene.mode !== SceneMode.MORPHING) {
        if (step === 0 && cartesian) {
          setPoints(points => [
            ...points,
            <Entity
              key={uuid()}
              position={
                new Cartesian3(cartesian.x, cartesian.y, cartesian.z)
              }
              billboard={pinDefault}
            />,
          ])
          point1 = new PointPrimitive()
          point1.position = new Cartesian3(
            cartesian.x,
            cartesian.y,
            cartesian.z
          )
          if (!isPreview)
            point1Click = getPointClickAndModel(cartesian, resultClick.pickedObject)
          step = 1
        } else if (step === 1 && cartesian) {
          point2 = new PointPrimitive()
          point2.position = new Cartesian3(
            cartesian.x,
            cartesian.y,
            cartesian.z
          )
          if (!isPreview) {
            point2Click = getPointClickAndModel(cartesian, resultClick.pickedObject)
            if (point1.position.z < point2.position.z) {
              let temp = point1
              point1 = point2
              point2 = temp
            }
          }
          else {
            customLine.removeAll();
          }
          point1GeoPosition = Cartographic.fromCartesian(point1.position)
          point2GeoPosition = Cartographic.fromCartesian(point2.position)

          var pl1Positions = [
            new Cartesian3.fromRadians(
              point1GeoPosition.longitude,
              point1GeoPosition.latitude,
              point1GeoPosition.height
            ),
            new Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point2GeoPosition.height
            ),
          ]
          var pl2Positions = [
            new Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point2GeoPosition.height
            ),
            new Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point1GeoPosition.height
            ),
          ]
          var pl3Positions = [
            new Cartesian3.fromRadians(
              point1GeoPosition.longitude,
              point1GeoPosition.latitude,
              point1GeoPosition.height
            ),
            new Cartesian3.fromRadians(
              point2GeoPosition.longitude,
              point2GeoPosition.latitude,
              point1GeoPosition.height
            ),
          ]
          if (isPreview) {
            let _polys = projectStore.polylines
            _polys = _polys.filter(elm => elm.key !== 'flag-line')
            _polys.push(
              <Entity key={"flag-line"}>
                <PolylineGraphics
                  positions={pl1Positions}
                  width={1}
                  material={
                    new GridMaterialProperty({
                      color: Color.YELLOW,
                    })
                  }
                  depthFailMaterial={
                    new GridMaterialProperty({
                      color: Color.RED,
                    })
                  }
                  destroyPrimitives={false}
                />
              </Entity>
            )
            projectStore.setPolylines(_polys)
          } else {
            setPoints(points => [
              ...points,
              <Entity
                key={uuid()}
                position={
                  new Cartesian3(cartesian.x, cartesian.y, cartesian.z)
                }
                billboard={pinDefault}
              />,
            ])
            let _polys = projectStore.polylines
            _polys.push(
              <Entity key={uuid()}>
                <PolylineGraphics
                  positions={pl1Positions}
                  width={1}
                  material={
                    new GridMaterialProperty({
                      color: Color.YELLOW,
                    })
                  }
                  depthFailMaterial={
                    new GridMaterialProperty({
                      color: Color.RED,
                    })
                  }
                  destroyPrimitives={false}
                />
              </Entity>,
              <Entity key={uuid()}>
                <PolylineGraphics
                  positions={pl2Positions}
                  width={1}
                  material={
                    new PolylineOutlineMaterialProperty({
                      color: Color.YELLOW,
                    })
                  }
                  depthFailMaterial={
                    new PolylineOutlineMaterialProperty({
                      color: Color.RED,
                    })
                  }
                  destroyPrimitives={false}
                />
              </Entity>,
              <Entity key={uuid()}>
                <PolylineGraphics
                  positions={pl3Positions}
                  width={1}
                  material={
                    new PolylineOutlineMaterialProperty({
                      color: Color.YELLOW,
                    })
                  }
                  depthFailMaterial={
                    new PolylineOutlineMaterialProperty({
                      color: Color.RED,
                    })
                  }
                  destroyPrimitives={false}
                />
              </Entity>
            )
            projectStore.setPolylines(_polys)

            var labelZ
            if (point2GeoPosition.height >= point1GeoPosition.height) {
              labelZ = point1GeoPosition.height + (point2GeoPosition.height - point1GeoPosition.height) / 2.0
            } else {
              labelZ = point2GeoPosition.height + (point1GeoPosition.height - point2GeoPosition.height) / 2.0
            }

            let projectElevation = projectStore.projectDetail.elevationSystem ? projectStore.projectDetail.elevationSystem : 'None'

            let _pointElevation
            if ((point1Click && point1Click.reflat && point2Click && point2Click.reflat)) {
              projectStore.setLoadingProgress(true)
              try {
                _pointElevation = await projectStore.getHeightTwoPointsWGS84ToLocal(point1Click.reflat, point1Click.reflong, point1Click.refheight, point2Click.reflat, point2Click.reflong, point2Click.refheight,
                  point1Click.picklat, point1Click.picklong, point1Click.pickheight, point2Click.picklat, point2Click.picklong, point2Click.pickheight, epsgCode, projectElevation)
                projectStore.setLoadingProgress(false)
              } catch (error) {
                projectStore.setLoadingProgress(false)
              }
            } else {
              if (point1Click && point2Click) {
                let _p1 = point1Click.cartesian ? [point1Click.cartesian.y, point1Click.cartesian.x, point1Click.cartographic.height] : [point1Click.cartesian.y, point1Click.cartesian.x, point1Click.cartographic.height]
                let _p2 = point2Click.cartesian ? [point2Click.cartesian.y, point2Click.cartesian.x, point2Click.cartographic.height] : [point2Click.cartesian.y, point2Click.cartesian.x, point1Click.cartographic.height]

                _pointElevation = {
                  Status: 'OK',
                  Points: [..._p1, ..._p2]
                }
              }
            }

            if (_pointElevation && _pointElevation.Status === 'OK') {
              point1.pointElevation = [_pointElevation.Points[0], _pointElevation.Points[1], _pointElevation.Points[2]]
              point2.pointElevation = [_pointElevation.Points[3], _pointElevation.Points[4], _pointElevation.Points[5]]
              addDistanceLabel(point1, point2, labelZ)
            }
            step = 2
          }
        }
        if (viewer.scene.requestRenderMode) { viewer.scene.requestRender(); }
      }
    }
  }

  useEffect(() => {
    if (isMobile || isTablet) {
      handler.setInputAction(function (movement) {
        let resultClick = clickPoint(viewer.current.cesiumElement, movement.position)
        if (!resultClick) return;
        if (step === 2) {
          customLine.removeAll();
          customLine.addPoint(resultClick);
        }
        if (step === 0) {
          customLine.removeAll();
          customLine.addPoint(resultClick);
        }
        if (step === 1) {
          var n = customLine.positions.length;
          if (n < 2) {
            customLine.addPoint(resultClick);
          } else {
            customLine.positions[1].position = resultClick.position;
          }
        }
        if (customLine.positions && customLine.positions.length) {
          if (customLine.positions && customLine.positions.length) {
            resultClick = customLine.positions[customLine.positions.length - 1]
            CalPointClick(viewer.current.cesiumElement, resultClick)
          }
        }
      }, ScreenSpaceEventType.LEFT_CLICK);
    } else {
      handler.setInputAction(function (movement) {
        if (!viewer.current.cesiumElement.scene) return
        let resultClick
        if (customLine.positions && customLine.positions.length) {
          resultClick = customLine.positions[customLine.positions.length - 1]
          CalPointClick(viewer.current.cesiumElement, resultClick)
        }
      }, ScreenSpaceEventType.LEFT_CLICK)
      handler.setInputAction(function (event) {
        let resultClick = clickPoint(viewer.current.cesiumElement, event.endPosition)

        if (resultClick && resultClick.position) {
          const cartographic = Cartographic.fromCartesian(
            resultClick.position
          );
          //if (cartographic.height >= -0.1) {
            if (step === 2) {
              customLine.removeAll()
              customLine.addPoint(resultClick);
            }
            if (step === 0) {
              customLine.removeAll()
              customLine.addPoint(resultClick);
            }
            if (step === 1) {
              var n = customLine.positions.length;
              if (n < 2) {
                customLine.addPoint(resultClick);
              } else {
                customLine.positions[1].position = resultClick.position;
              }
            }
          //}
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);
    }
    return () => {
      if (handler) handler.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
      if (handler) handler.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
      clearAll()
    }
  }, [])
  function addDistanceLabel(point1, point2, height) {

    var point1GeoPosition = Cartographic.fromCartesian(point1.position)
    var point2GeoPosition = Cartographic.fromCartesian(point2.position)

    point1.cartographic = ellipsoid.cartesianToCartographic(point1.position)
    point1.longitude = parseFloat(MathCesium.toDegrees(point1.position.x))
    point1.latitude = parseFloat(MathCesium.toDegrees(point1.position.y))
    point1.geoPosition = point1GeoPosition

    point2.cartographic = ellipsoid.cartesianToCartographic(point2.position)
    point2.longitude = parseFloat(MathCesium.toDegrees(point2.position.x))
    point2.latitude = parseFloat(MathCesium.toDegrees(point2.position.y))
    point2.geoPosition = point2GeoPosition

    setLabels(labels => [
      ...labels,
      <Label
        {...labelDefault}
        key={uuid()}
        position={getMidpoint(point1, point2, point1.geoPosition.height)}
        text={getHorizontalDistance(point1, point2, true)}
        disableDepthTestDistance={Number.POSITIVE_INFINITY}
        pixelOffset={new Cartesian2(0, -20)}
      />,
    ])

    setLabels(labels => [
      ...labels,
      <Label
        {...labelDefault}
        key={uuid()}
        position={getMidpoint(point1, point2, height)}
        text={getDistance(
          point1,
          point2,
          true
        )}
        disableDepthTestDistance={Number.POSITIVE_INFINITY}
      />,
    ])
    setLabels(labels => [
      ...labels,
      <Label
        {...labelDefault}
        key={uuid()}
        position={getMidpoint(point2, point2, height)}
        text={getVerticalDistance(point1, point2, true, true)}
        disableDepthTestDistance={Number.POSITIVE_INFINITY}
      />,
    ])

    setLabels(labels => [
      ...labels,
      <Label
        {...labelDefault}
        key={uuid()}
        position={getMidpoint(point1, point2, point1.geoPosition.height)}
        text={getSlope(
          point1,
          point2
        )}
        disableDepthTestDistance={Number.POSITIVE_INFINITY}
        pixelOffset={new Cartesian2(0, -60)}
      />,
    ])
    if (viewer.current.cesiumElement.scene.requestRenderMode) { viewer.current.cesiumElement.scene.requestRender(); }
  }

  function getSlope(
    point1,
    point2
  ) {
    var dx = point1.pointElevation[1] - point2.pointElevation[1];
    var dy = point1.pointElevation[0] - point2.pointElevation[0];
    var _distancexy = Math.sqrt(dx * dx + dy * dy)

    var arrheight = [point1.pointElevation[2], point2.pointElevation[2]]
    var _height = (
      Math.max.apply(Math, arrheight) - Math.min.apply(Math, arrheight)
    )

    var slopeInAB = ''
    if (_height > _distancexy) {
      slopeInAB = `${t('slope-in-a-b')} ` + ((1 / _distancexy) * _height).toFixed(3) + ':1'
    } else {
      slopeInAB = `${t('slope-in-a-b')} 1:` + ((1 / _height) * _distancexy).toFixed(3)
    }
    return slopeInAB
  }

  function getHorizontalDistance(point1, point2, iSString) {
    var point1GeoPosition = Cartographic.fromCartesian(point1.position)
    var point2GeoPosition = Cartographic.fromCartesian(point2.position)
    var p1 = new Cartesian3.fromRadians(
      point1GeoPosition.longitude,
      point1GeoPosition.latitude,
      point1GeoPosition.height
    );
    var p2 = new Cartesian3.fromRadians(
      point2GeoPosition.longitude,
      point2GeoPosition.latitude,
      point1GeoPosition.height
    )

    var dx = p1.x - p2.x;
    var dy = p1.y - p2.y;
    var dz = p1.z - p2.z;
    var meters = Math.sqrt(dx * dx + dy * dy + dz * dz);

    if (iSString) {
      return meters.toFixed(3) + ' m'
    }
    return meters
  }

  function getDistance(point1, point2, iSString) {
    var meters
    if ((['4326', '4756'].includes(epsgCode) || projectStore.projectDetail.tilesetData.coordinateSystem.unit === 'degree') && point1Click.data) {
      meters = distOnLocal(point1Click.data.tilesetTransform, point1Click.data.modelCenter, point1Click.cartesian, point2Click.cartesian)
      if (iSString) {
        return meters.toFixed(3) + ' m'
      }
      return meters
    } else {
      var dx = point1.position.x - point2.position.x;
      var dy = point1.position.y - point2.position.y;
      var dz = point1.position.z - point2.position.z;
      meters = Math.sqrt(dx * dx + dy * dy + dz * dz);

      if (iSString) {
        return meters.toFixed(3) + ' m'
      }
      return meters
    }
  }

  function getMidpoint(point1, point2, height) {
    var scratch = new Cartographic()
    geodesic.setEndPoints(point1.cartographic, point2.cartographic)
    var midpointCartographic = geodesic.interpolateUsingFraction(0.5, scratch)
    return Cartesian3.fromRadians(
      midpointCartographic.longitude,
      midpointCartographic.latitude,
      height
    )
  }

  return (
    <>
      {points.map(p => p)}
      {projectStore.polylines.length > 0 ? projectStore.polylines.map(p => p) : ''}
      <LabelCollection> {labels.map(l => l)}</LabelCollection>
    </>
  )
}
export default inject('projectStore')(observer(MeasureDistance))
